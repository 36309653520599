import { createNamespacedHelpers } from 'vuex';
import { USER_PASSWORD_VALIDATION_RULES } from './config';

const { mapGetters: WLCProductMapGetters } = createNamespacedHelpers('WLCProduct');

const userPasswordValidationRulesMixin = {
  computed: {
    ...WLCProductMapGetters(['isWLCProduct'])
  },
  methods: {
    /**
     * Функция возвращает строку с правилами валидации для поля ввода пароля, подмешивая туда
     * переданные в inputedRules правила. Правила валидации зависят от сборки - WLC или ONE
     * Используется в v-validate полей для пароля (создание нового юзера, изменение текущего пароля и тд)
     *
     * @example
     * v-validate="mixRulesForUserPasswordCheck('required')"
     *
     * @param {string} [inputedRules] - необязательный параметр
     * @returns {string} - строка с правилами для валидатора, подаваемая на вход v-validate
     */
    mixRulesForUserPasswordCheck(inputedRules) {
      let rulesForPasswordCheck = [];
      let inputedRulesAsArr = [];
      if (inputedRules) {
        inputedRulesAsArr = inputedRules.split('|');
      }
      if (this.isWLCProduct) {
        rulesForPasswordCheck = [...USER_PASSWORD_VALIDATION_RULES.WLC];
      } else {
        rulesForPasswordCheck = [...USER_PASSWORD_VALIDATION_RULES.ONE];
      }
      const result = inputedRulesAsArr.concat(rulesForPasswordCheck).join('|');
      return result;
    }
  }
};

export default userPasswordValidationRulesMixin;
