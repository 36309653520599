import { createNamespacedHelpers } from 'vuex';
/**
 * Миксин содержит различне computed используемые для отображение тех или иных настроек WLAN
 * (например в зависимости от сборки )
 * Используется только в разделах с созданием/редактированием WLAN
 *
 * @mixin
 *
 */

const { mapGetters: WLCProductMapGetters } = createNamespacedHelpers('WLCProduct');

const wlanSettingsVisibilityMixin = {
  computed: {
    ...WLCProductMapGetters(['isWLCProduct']),
    /**
     * Услиовие для отображения правой панели с настройками во вкладке
     * "Дополнительно" - "Управление траффиком данных" в настройках WLAN
     * В настоящее время отображается только если отображается свитчер proxy ARP
     * @returns {boolean}
     */
    showDataTrafficManagementTabRightColumn() {
      return this.showProxyARPSwitcher;
    },
    /**
     * Услиовие для отображения (или же нет) свитчера Proxy ARP.
     * Требование в каких сборках его отображать изменяется уже не в первый раз, поэтому оставлю этот
     * computed, чтобы если что можно было с лёгкостью поменять обратно и отображать например только в WLC
     * В настоящее время отображаем для всех сборок - и WLC и ONE сборки
     * @returns {boolean}
     */
    showProxyARPSwitcher() {
      return true;
    },
    /**
     * Услиовие для отображения (или же нет) селектора интерфейсов в настройках WLAN в зависимости от сборки
     * На вкладке Дополнитльно - Управление трафиком данных, под свитчером Централизованная коммутация
     * В настоящее время не отображаем для WLC сборки
     * @returns {boolean}
     */
    showInterfaceSelectorInCentralSwitchingSettingsDependingOnBuild() {
      // Не показываем в WLC сборке
      if (this.isWLCProduct) {
        return false;
      }
      return true;
    },
    /**
     * Услиовие для отображения (или же нет) селектора выбора Hotspot в настройках WLAN в зависимости от сборки
     * На вкладке Безопасность - Слой 2
     * В настоящее время не отображаем для WLC сборки
     *
     * @returns {boolean}
     */
    isHotspotSelectorShowingDependingOnTheBuild() {
      return !this.isWLCProduct;
    },
    /**
     * Услиовие для отображения (или же нет) блока настроек NAT в настройках WLAN в зависимости от сборки
     * На вкладке Дополнительно - Управление траффиком данных  в блоке Локальная коммутация
     * В настоящее время не отображаем для WLC сборки
     *
     * @returns {boolean}
     */
    isNATBlockShowingDependingOnTheBuild() {
      return !this.isWLCProduct;
    },
    /**
     * Услиовие для отображения (или же нет) блока настроек Тунелирование в настройках WLAN в зависимости от сборки
     * На вкладке Дополнительно - Управление траффиком данных
     * В настоящее время не отображаем для WLC сборки
     *
     * @returns {boolean}
     */
    isTunnelingBlockShowingDependingOnTheBuild() {
      return !this.isWLCProduct;
    },
    /**
     * Услиовие для отображения (или же нет) свитчера настройки Генерировать NAS ID в настройках WLAN в зависимости от сборки
     * На вкладке Дополнительно - Настройки роуминга - Свитчер Быстрый роуминг (IEEE 802.11r) - Свитчер генирировать NAS ID
     * В настоящее время не отображаем ни в каких сборках
     *
     * @returns {boolean}
     */
    isGenerateNASIDSettingShowingDependingOnTheBuild() {
      return false;
    },
    /**
     * Услиовие для отображения (или же нет) свитчера "Локальная генерация PMK" в настройках WLAN в зависимости от сборки
     * На вкладке Дополнительно - Настройки роуминга - Свитчер Быстрый роуминг (IEEE 802.11r) - Свитчер Локальная генерация PMK
     * В настоящее время отображаем только в WLC сборке
     *
     * @returns {boolean}
     */
    isLocalPMKGenerationShowingDependingOnTheBuild() {
      return this.isWLCProduct;
    },
    /**
     * Услиовие для отображения (или же нет) свитчера "Экспериментальные улучшения роуминга" (и всех его дочерних свитчеров) в настройках WLAN в зависимости от сборки
     * На вкладке Дополнительно - Настройки роуминга - Свитчер Быстрый роуминг (IEEE 802.11r) - свитчер Экспериментальные улучшения роуминга и его дочерние свитчеры
     * В настоящее время отображаем только в WLC сборке
     *
     * @returns {boolean}
     */
    isExperimentalFTImprovementsAndChildrenShowingDependingOnTheBuild() {
      return this.isWLCProduct;
    },
    /**
     * Услиовие для отображения (или же нет) свитчера "Центральная аутентификация" в настройках WLAN в зависимости от сборки
     * На вкладке Безопасность - AAA - Свитчер "Центральная аутентификация"
     * В настоящее время отображаем только в WLC сборке
     *
     * @returns {boolean}
     */
    isCentralAuthShowingDependingOnTheBuild() {
      return this.isWLCProduct;
    },
    /**
     * Услиовие для отображения (или же нет) поля "NAS IP" в настройках WLAN в зависимости от сборки
     * На вкладке Безопасность - AAA - поле "NAS IP"
     * В настоящее время отображаем только в WLC сборке
     *
     * @returns {boolean}
     */
    isNASIPShowingDependingOnTheBuild() {
      return this.isWLCProduct;
    }
  }
};

export default wlanSettingsVisibilityMixin;
