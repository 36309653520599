<template>
  <section v-if="isControllerData">
    <div class="h4 m-0 flex-column">
      <span>{{ isCurrentControllerData ? currentControllerData.name : $t('controller.newController') }}</span>
    </div>
    <div v-if="isCurrentControllerData">
      <span
        class="badge text-sm badge-light"
        :class="{
          'badge-success': currentControllerData.status === 'connected',
          'badge-danger': currentControllerData.status === 'error',
          'badge-default': currentControllerData.status === 'disconnected' || currentControllerData.status === 'empty',
          'badge-primary': currentControllerData.status === 'provision'
        }"
      >
        {{ currentControllerData.status }}
      </span>
    </div>

    <ul class="list-unstyled" v-if="isCurrentControllerData">
      <li v-if="controllerData.id">
        <small>ID:</small>
        <small class="text-muted">{{ controllerData.id }}</small>
      </li>
      <li v-if="controllerData.mac">
        <small>MAC:</small>
        <small class="text-muted">{{ helpers.getFormatMac(controllerData.mac) }}</small>
      </li>
      <li v-if="controllerData.serial">
        <small>{{ $t('general.serialNumber') }}:</small>
        <small class="text-muted">{{ controllerData.serial }}</small>
      </li>
      <li v-if="controllerData.vendor">
        <small>{{ $t('controller.vendor') }}:</small>
        <small class="text-muted">{{ controllerData.vendor }}</small>
      </li>
      <li v-if="controllerData.fw_version">
        <small>{{ $t('controller.FWversion') }}:</small>
        <small class="text-muted">{{ controllerData.fw_version }}</small>
      </li>
    </ul>

    <small class="text-muted" v-if="!isDisable && isCurrentControllerData">{{ $t('general.editingMode') }}</small>

    <div class="form-group mt-1">
      <label for="name">{{ $t('general.name') }}</label>
      <input
        type="text"
        name="name"
        id="name"
        :disabled="isDisable"
        v-validate="'required|max:32|min:2'"
        :class="{ input: true, 'is-danger': errors.has('name') || !controllerData.name }"
        :placeholder="this.$t('general.name')"
        class="form-control"
        v-model="controllerData.name"
      />
      <span v-show="errors.has('name')" class="help is-danger location-danger">{{ errors.first('name') }}</span>
    </div>

    <div class="form-group">
      <label for="description">{{ $t('general.description') }}</label>
      <input
        type="text"
        name="Description"
        id="description"
        :disabled="isDisable"
        v-validate="'max:140'"
        data-vv-validate-on="input"
        :class="{ input: true, 'is-danger': errors.has('description') }"
        :placeholder="this.$t('general.description')"
        class="form-control"
        v-model="controllerData.description"
      />
      <span v-show="errors.has('description')" class="help is-danger location-danger">
        {{ errors.first('description') }}
      </span>
    </div>

    <div class="form-group">
      <label for="ip_address">IP</label>
      <input
        type="text"
        name="ip_address"
        id="ip_address"
        :disabled="isDisable"
        v-validate="'required|ipv4'"
        data-vv-validate-on="input"
        :class="{ input: true, 'is-danger': errors.has('ip_address') || !controllerData.ip_addr }"
        placeholder="123.123.123.123"
        class="form-control"
        v-model="controllerData.ip_addr"
      />
      <span v-show="errors.has('ip_address')" class="help is-danger location-danger">
        {{ errors.first('ip_address') }}
      </span>
    </div>

    <div class="form-group mb-0 mt-2">
      <Switch-component
        v-model="controllerData.enable"
        :disabled="isDisable"
        :label="$t('controller.active')"
        :id="'controller-active'"
      />
    </div>

    <div v-if="controllerData.access">
      <h6 class="mt-2">{{ $t('controller.accessSettings') }}</h6>

      <div class="form-group">
        <label for="username">{{ $t('controller.username') }}</label>
        <input
          type="text"
          name="username"
          id="username"
          :disabled="isDisable"
          v-validate="'required|max:140'"
          data-vv-validate-on="input"
          :class="{ input: true, 'is-danger': errors.has('username') || !controllerData.access.username }"
          :placeholder="this.$t('controller.username')"
          class="form-control"
          v-model="controllerData.access.username"
        />
        <span v-show="errors.has('username')" class="help is-danger location-danger">
          {{ errors.first('username') }}
        </span>
      </div>

      <div class="form-group">
        <label for="password">{{ $t('controller.password') }}</label>
        <input
          type="text"
          name="ssh_key"
          id="password"
          :disabled="isDisable"
          v-validate="'max:140'"
          data-vv-validate-on="input"
          :class="{ input: true, 'is-danger': errors.has('password') }"
          :placeholder="this.$t('controller.password')"
          class="form-control"
          v-model="controllerData.access.password"
        />
        <span v-show="errors.has('password')" class="help is-danger location-danger">
          {{ errors.first('password') }}
        </span>
      </div>

      <div class="form-group">
        <label for="ssh_key">{{ $t('controller.SSHkey') }}</label>
        <input
          type="text"
          name="ssh_key"
          id="ssh_key"
          :disabled="isDisable"
          v-validate="'max:140'"
          data-vv-validate-on="input"
          :class="{ input: true, 'is-danger': errors.has('ssh_key') }"
          :placeholder="this.$t('controller.SSHkey')"
          class="form-control"
          v-model="controllerData.access.key"
        />
        <span v-show="errors.has('ssh_key')" class="help is-danger location-danger">{{ errors.first('ssh_key') }}</span>
      </div>
    </div>
  </section>
</template>

<script>
import Vue from 'vue';
import commonService from '../../services/commonService';
import helpers from '../../helpers';
import SwitchComponent from '../Universal/Switch-component.vue';

export default {
  name: 'ControllerConfig',
  inject: ['$validator'],
  components: {
    SwitchComponent
  },
  props: {
    controllerData: {
      type: Object
    },
    currentControllerData: {
      type: Object
    },
    isNewController: {
      type: Boolean
    },
    isDisable: {
      type: Boolean
    },
    errorFromModal: {
      type: Array
    }
  },
  data() {
    return {};
  },
  computed: {
    commonService() {
      return commonService;
    },
    helpers() {
      return helpers;
    },
    isCurrentControllerData() {
      return Object.keys(this.currentControllerData).length;
    },
    isControllerData() {
      return Object.keys(this.controllerData).length;
    }
  },
  methods: {},
  created() {}
};
</script>

<style lang="scss" scoped></style>
