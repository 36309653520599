<template>
  <i class="fa fa-remove fa-lg  text-danger user-action"
    @click.stop="$emit('on-delete-click', $event)"
  ></i>
</template>

<script>
/**
 * компонент - ячейка delete.
 * компонент локальный: используется только в ApsTable.vue
 * @component
 */

export default {
  name: 'DeleteCell',
  components: {},
  props: {
  },
  methods: {
  }
};
</script>

<style lang="css" scoped>
</style>
