<template>
  <div v-if="props.row.radar"
    class="cpe-interface-state__badge"
  >
    <span class="badge badge-light badge-success">
      <i class="fa fa-check"></i>
    </span>
  </div>
  <span v-else>-</span>
</template>

<script>
/**
 * компонент - ячейка radar.
 * компонент локальный: используется только в ApsTable.vue
 * @component
 */

export default {
  name: 'RadarCell',
  components: {},
  props: {
    props: {
      type: Object,
      required: true
    }
  },
  methods: {
  }
};
</script>

<style lang="css" scoped>
.cpe-interface-state__badge {
  display: flex;
}
</style>
