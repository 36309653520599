<template>
  <div class="menu-section" :class="[isChild ? 'child' : 'parent']">

    <div v-if="item.role === 'section'"
      class="menu-item role-section color"
      :class="[item.selected && 'selected']"
    >
      <i v-if="item.icon"
        class="icon"
        :class="[item.icon, item.selected && 'selected']"
      />
      <h3 class="heading">
        {{ item.title | capitalize }}
      </h3>
    </div>

    <router-link v-if="item.role === 'link' && item.to"
      class="menu-item role-link color selectable"
      :class="[item.selected && 'selected']"
      :to="item.to"
      @click.native="handleLinkClick"
    >
      <i v-if="item.icon"
        class="icon"
        :class="[item.icon, item.selected && 'selected']"
      />
      <span class="text-content">
        {{ item.title | capitalize }}
      </span>
    </router-link>

    <template v-if="item.children && item.children.length">
      <MenuSection v-for="child in item.children"
        style="padding-left: 16px;"
        :key="child.index"
        :item="child"
        :isChild="true"
        @close="handleLinkClick"
      />
    </template>
  </div>
</template>

<script>
export default {
  name: 'MenuSection',
  components: {
  },
  props: {
    item: {
      type: Object,
      required: true
    },
    isChild: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
    };
  },
  computed: {
  },
  methods: {
    handleLinkClick() {
      this.$emit('close');
    }
  },
  mounted() {
  }
};
</script>

<style lang="css" scoped>

.menu-section.parent{
  padding: 0 12px 0 12px;
}

.role-section {
  display: flex;
  align-items: center;
  gap: 4px;
  margin: 0 0 8px 0;
}

.heading {
  font-size: 16px;
  font-weight: 400;
  line-height: 1.2;
  margin: 0;
}
.selectable {
  position: relative;
  z-index: 1;
}

.selectable.selected::before {
  content: '';
  display: inline-block;
  width: 100%;
  height: 100%;
  background-color: var(--brand-success);
  opacity: .5;
  border-radius: 8px;
  position: absolute;
  left: 0;
  top: 0;
  z-index: -1;
}

.selectable.selected::after {
  content: '';
  display: inline-block;
  width: 4px;
  height: 60%;
  background-color: var(--brand-success);
  opacity: 1;
  border-radius: 8px;
  position: absolute;
  left: 0%;
  top: 50%;
  transform: translateY(-50%);
  z-index: -1;
}

a:hover,
a:focus {
  text-decoration: none;
}

.icon.selected {
  color: var(--brand-success);
}

.menu-item {
  padding: 2px 8px;
  width: 100%;
  display: flex;
  align-items: center;
  gap: 4px;
  position: relative;
  z-index: 1;
  transition: var(--transition);
}

.menu-item:hover>.text-content {
  opacity: var(--opacity);
}

.menu-item.color {
  color: var(--sidebar-font-color);
}

.role-link.color {
  color: color-mix(in srgb, var(--sidebar-font-color) 80%, transparent);
}

.text-content {
  color: inherit;
  flex: 1 1 auto;
  text-align: start;

  transition: var(--transition);

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
