/* eslint-disable no-unused-vars */

/**
 * Локальные валидаторы vee-validate для компонента VLANForm.
 */

const getVLANFormValidators = (i18nInstance) => ({
  uniqueVlanName: (forbiddenVlanNames) => ({
    getMessage: field => {
      const message = i18nInstance.t('branchProfile.inputError.uniqueVlanName');
      return message;
    },
    validate: async value => {
      // название должно быть уникальным в пределах Профиля филиала
      // проверка из статического списка
      const isUniqueVLANName = forbiddenVlanNames
        .map(value => value.toLocaleLowerCase())
        .includes(value.toLocaleLowerCase());

      return !isUniqueVLANName;
    }
  }),
  vlanNameMask: () => ({
    getMessage: field => {
      const message = i18nInstance.t('branchProfile.inputError.vlanNameMask');
      return message;
    },
    validate: value => {
      const customValidationRegex = /^[a-zA-Zа-яА-Я0-9!"#$%&'()*+,-./:;<=>?@[\\\]^_`{|}~]+$/;
      // Проверяем, чтобы не было пробелов в начале и в конце
      if (value.trim() !== value) {
        return false;
      }
      // Проверяем, чтобы строка не содержала несколько слов, разделенных пробелом
      if (/\s/.test(value)) {
        return false;
      }
      // Проверяем соответствие регулярному выражению
      return customValidationRegex.test(value);
    }
  }),
  uniqueVlanId: (forbiddenVlanIds) => ({
    getMessage: field => {
      const message = i18nInstance.t('branchProfile.inputError.uniqueVlanId');
      return message;
    },
    validate: async value => {
      // название должно быть уникальным в пределах Профиля филиала
      // проверка из статического списка
      const isUniqueVlanId = forbiddenVlanIds
        .map(value => String(value))
        .includes(String(value));

      return !isUniqueVlanId;
    }
  })
});

export {
  getVLANFormValidators
};
