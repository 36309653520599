/* eslint-disable no-restricted-syntax */
/**
 * Выполняет один или несколько навигационных охранников и перенаправляет пользователя
 * в случае отрицательного результата.
 *
 * @param {Function|Array<Function>} guards - Функция или массив функций-охранников для выполнения.
 * @param {Object|undefined} redirectRoute - Маршрут для перенаправления, если оценка охранников завершается неудачей. Если не задан, будет использован предыдущий маршрут - from.
 * @param {Object} to - Маршрут, на который осуществляется попытка перехода.
 * @param {Object} from - Маршрут, с которого осуществляется попытка перехода.
 * @param {Function} next - Функция для продолжения навигации.
 * @returns {void}
 *
 * @example
 * Пример с одним охранником
 * const singleGuard = () => true; // или false
 * const routerGuard = executeRouterGuards(singleGuard, { name: 'FallbackRoute' });
 *
 * @example
 * Пример с несколькими охранниками
 * const guards = [
 *   () => true,
 *   () => false,
 *   () => true,
 * ];
 * const routerGuard = executeRouterGuards(guards, { name: 'FallbackRoute' });
 *
 * @example
 * Пример без заданного маршрута перенаправления (используется предыдущий маршрут)
 * const singleGuard = () => false;
 * const routerGuard = executeRouterGuards(singleGuard);
 *
 * @info
 * в vue-router v4 не нужна вся эта хрень. Там просто можно в beforeEnter передать массив из guards.
 * Но в v3, которая у нас, так нельзя. Эта функция - эмулятор такого поведения
 * мигрировать vue-router с v3 до v4 нельзя, потому что v4 для vue3
 * https://router.vuejs.org/guide/advanced/navigation-guards.html#Per-Route-Guard
 */
const executeRouterGuards = (guards, redirectRoute) => (to, from, next) => {
  const arrayOfGuards = Array.isArray(guards) ? guards : [guards];

  let result = true;

  for (const guard of arrayOfGuards) {
    const guardResult = guard(to, from);

    if (!guardResult) {
      result = false;
      break;
    }
  }

  if (!result) {
    next(redirectRoute || from);
  } else {
    next();
  }
};

export default executeRouterGuards;
