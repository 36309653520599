<template>
  <Loader-spinner v-if="isLoading" :centered="true"></Loader-spinner>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  /**
 * Компонент Vue, отображающий индикатор статуса загрузки API.
 *
 * Используется как единый компонент для всех api-запросов в статусе isLoading для показа главного лоадера,
 * зависит от isLoading геттера apiStatus Vuex модуля
 * !!! нигде больше не используется кроме как в App
 *
 * @component
 * @name ApiLoadingStatusIndicator
  */

  name: 'ApiLoadingStatusIndicator',
  computed: {
    ...mapGetters({
      isLoading: 'isLoading'
    })
  }
};
</script>
