<template>
  <v-popover
    trigger="hover"
    placement="top-center"
    popoverClass="last-error-popover"
    :popperOptions="{
      modifiers: {
        preventOverflow: {
          boundariesElement: 'window'
        }
      }
    }"
  >
    <i class="fa fa-warning text-danger" @click.stop="handleClick"></i>

    <template slot="popover">
      <div class="error-status small">
        <p class="text-danger mb-h"><b>Last error</b></p>
        <ul class="list-unstyled mb-h last-error-popover-content">
          <li v-if="errorObj.type">
            <b>Type:</b>
            {{ errorObj.type }}
          </li>
          <li v-if="errorObj.module">
            <b>Module:</b>
            {{ errorObj.module }}
          </li>

          <li v-if="errorObj.module_id">
            <b>Module ID:</b>
            {{ errorObj.module_id }}
          </li>
          <li v-if="errorObj.description" class="mt-q">
            {{ `${$t('aps.errorCaptionInCpeEditPanel')}: ${errorObj.description}`}}
          </li>
          <li v-if="errorObj.recommendation" class="mt-q">
            {{ `${$t('aps.recommendationCaptionInCpeEditPanel')}: ${errorObj.recommendation}` }}
          </li>
          <li v-else class="mt-q">
            {{ `${$t('aps.recommendationCaptionInCpeEditPanel')}: ${$t('general.no').toLowerCase()}`}}
          </li>
          <li class='mt-q'>
            {{ $t('aps.clickToWatchEventsCaptionInTooltip') }}
          </li>
        </ul>
      </div>
    </template>
  </v-popover>
</template>

<script>
/**
 * компонент - тултип последней ошибки столбца статуса.
 * компонент локальный: используется только в ApsTable.vue
 * @component
 */

export default {
  name: 'LastErrorTooltip',
  components: {},
  props: {
    errorObj: {
      type: Object,
      required: true
    }
  },
  methods: {
    handleClick(event) {
      this.$emit('on-show-more-events', event);
    }
  }
};
</script>

<style lang="css">
.last-error-popover {
  border: none !important;
  padding: 0 !important;
  margin: 0 !important;
}

.last-error-popover-content {
  display: flex;
  flex-direction: column;
  text-align: left;
}
</style>
