import Dashboard from '../views/Dashboard.vue';
import settingsService from '../services/settingsService';

const DashboardRoute = {
  path: 'dashboard',
  name: 'Dashboard',
  component: Dashboard,
  beforeEnter(to, from, next) {
    settingsService.getLocations().then(
      () => {
        next();
      },
      () => {
        console.warn('Error loading Locations');
      }
    );
  }
};

export default DashboardRoute;
