import { v4 as uuidv4 } from 'uuid';

function showMainSectionsMonitoringGeneral({ $can }) {
  return ($can('read', { route: 'system' }))
}

function showMainSectionsMonitoringWireless({ $can }) {
  return $can('read', { route: 'Statcpes' })
    || $can('read', { route: 'Sessions' })
    || $can('read', { route: 'Clients' })
    || $can('read', { route: 'Statclients' })
    || $can('read', { route: 'ClientRF' })
    || $can('read', { route: 'WifiAnalyzer' });
}

function showMainSectionsMonitoring({ thisInstance, $can }) {
  if (thisInstance.isPortalOnlyModeEnabled) {
    return false;
  }

  return showMainSectionsMonitoringGeneral({ $can }) || showMainSectionsMonitoringWireless({ $can });
}

/**
 * Описание ролей:
 * роли нужны для построения иерархичности + для специфичного отображения элементов в соответствии с ролью.
 *
 * root - это начало категории. Может быть ссылкой без вложенных элементов, так и не ссылкой с вложенными элементами.
 * section - подраздел. Не является ссылкой. Имеет вложенные элементы children, которые являются ссылкой (обычно role: link). Используется как группировка раздела ссылок и отображается как заголовок.
 * link - конечный элемент пути - ссылка. Не имеет children
 * @param {'root' | 'section' | 'link'} role
 */

const getMonitoring = ({ $t, thisInstance, $can }) => (
  {
    id: uuidv4(),
    name: 'monitoring',
    title: $t('sidebarNew.monitoring'),
    icon: 'fa fa-eye fa-fw fa-lg',
    to: false,
    selected: false,
    role: 'root',
    isShow: showMainSectionsMonitoring({ thisInstance, $can }),
    children: [
      // В настоящее время страница /db-state не отображается ни в одной из сборок
      // https://wimarksystems.atlassian.net/wiki/spaces/Wimark/pages/357793940/FT23.13+GUI+Wimark+WLC+FrontEnd  пункт 6
      // Возможно когда-нибудь, если не появится задач на то, чтобы сделать ее обратно видимой, ее следует будет удалить (12.07.2024)
      // {
      //   id: uuidv4(),
      //   name: 'general',
      //   title: $t('sidebarNew.general'),
      //   icon: 'fa fa-info fa-fw fa-lg',
      //   to: false,
      //   selected: false,
      //   role: 'section',
      //   isShow: showMainSectionsMonitoringGeneral({ $can }),
      //   children: [
      //     {
      //       id: uuidv4(),
      //       name: 'db-state',
      //       title: $t('sidebarNew.system'),
      //       icon: '',
      //       to: '/monitor/db-state',
      //       selected: false,
      //       role: 'link',
      //       isShow: $can('read', { route: 'system' }),
      //       children: []
      //     }
      //   ]
      // },
      {
        id: uuidv4(),
        name: 'wireless',
        title: $t('sidebarNew.wireless'),
        icon: 'fa fa-wifi fa-fw fa-lg',
        to: false,
        selected: false,
        role: 'section',
        isShow: showMainSectionsMonitoringWireless({ $can }),
        children: [
          {
            id: uuidv4(),
            name: 'statcpes',
            title: $t('sidebarNew.apStatistics'),
            icon: '',
            to: '/statistics/statcpes',
            selected: false,
            role: 'link',
            isShow: $can('read', { route: 'Statcpes' }),
            children: []
          },
          {
            id: uuidv4(),
            name: 'troubleshooter',
            title: $t('sidebarNew.troubleshooter'),
            icon: '',
            to: '/monitor/sessions',
            selected: false,
            role: 'link',
            isShow: $can('read', { route: 'Sessions' }),
            children: []
          },
          {
            id: uuidv4(),
            name: 'clients',
            title: $t('sidebarNew.clients'),
            icon: '',
            to: '/clients',
            selected: false,
            role: 'link',
            isShow: $can('read', { route: 'Clients' }),
            children: []
          },
          {
            id: uuidv4(),
            name: 'statclients',
            title: $t('sidebarNew.clientsStatistic'),
            icon: '',
            to: '/statistics/statclients',
            selected: false,
            role: 'link',
            isShow: $can('read', { route: 'Statclients' }),
            children: []
          },
          {
            id: uuidv4(),
            name: 'client-rf',
            title: $t('clientsRF.clientRFSidebar'),
            icon: '',
            to: '/monitor/client-rf',
            selected: false,
            role: 'link',
            isShow: $can('read', { route: 'ClientRF' }),
            children: []
          },
          {
            id: uuidv4(),
            name: 'wifi-analyzer',
            title: $t('sidebarNew.radioStatistics'),
            icon: '',
            to: '/monitor/wifi-analyzer',
            selected: false,
            role: 'link',
            isShow: $can('read', { route: 'WifiAnalyzer' }),
            children: []
          }
        ]
      }
    ]
  }
);

export default getMonitoring;
