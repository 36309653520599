<template>
  <button
    type="button"
    class="theme-switcher color"
    @click="changeTheme"
  >
    <i class="fa fa-moon-o fa-lg" v-if="isDarkTheme"></i>
    <i class="fa fa-sun-o fa-lg" v-if="isLightTheme"></i>
  </button>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';

const {
  mapGetters: themeMapGetters,
  mapActions: themeMapActions
} = createNamespacedHelpers('theme');

export default {
  name: 'ThemeSwitcher',
  data() {
    return {};
  },
  computed: {
    ...themeMapGetters(['isLightTheme', 'isDarkTheme'])
  },
  methods: {
    ...themeMapActions(['toggleTheme']),
    changeTheme() {
      this.toggleTheme();

      // todo: пока легаси с кривым бутстрапом сохраняется, для корректной смены темы необходима перезагрузка.
      // Потом убрать, как слезем с бутстрапа
      window.location.reload();
    }
  }
};
</script>

<style lang="css" scoped>
.theme-switcher {
  background: transparent;
  padding: 5px;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: var(--transition);
}

.theme-switcher:hover {
  opacity: var(--opacity);
}

.color {
  color: var(--header-font-color);
}
</style>
