/**
 * Модуль Vuex для управления флагами функциональных возможностей на сайте.
 *
 * @module featureFlags
 * @namespace
 *
 * @description
 * Этот модуль содержит состояния (булевые флаги), которые управляют включением
 * и отключением различных разделов и фич на сайте.
 * Флаги могут быть использованы для управления условной отрисовкой компонентов,
 * активации или деактивации определенных функций и т. д.
 *
 * @see {@link https://v3.vuex.vuejs.org/ru/guide/modules.html | Документация по модулям Vuex}
 */

import {
  CONTENT_PORTAL,
  CONTENT_PORTAL_PAID_INTERNET,
  CONTENT_ANALYTICS,
  CONTENT_AUTH_FREEMIUM,
  CONTENT_NEW_MENU,
  CONTENT_HIDE_LOCATIONS,
  CONTENT_PORTAL_ONLY_MODE,
  EXPERIMENTAL_MODE,
  CISCO_MODE
} from '@/config';

const featureFlags = {
  modules: {
    /**
     * Модуль для управления состоянием разделов контента на сайте.
     *
     * Этот модуль содержит состояния (булевые флаги) для включения и отключения различных
     * разделов контента на сайте
     *
     * @namespace contentSections
     */
    contentSections: {
      state: () => ({
        contentPortalStatus: CONTENT_PORTAL,
        contentPortalPaidInternetStatus: CONTENT_PORTAL_PAID_INTERNET,
        contentAnalyticsStatus: CONTENT_ANALYTICS,
        contentAuthFreemiumStatus: CONTENT_AUTH_FREEMIUM,
        contentNewMenuStatus: CONTENT_NEW_MENU,
        hideLocationsStatus: CONTENT_HIDE_LOCATIONS
      }),
      getters: {
        /**
         * Проверяет, включен ли раздел "Портал".
         * @returns {boolean}
         */
        isContentPortalEnabled: (state) => state.contentPortalStatus,

        /**
         * Проверяет, включен ли подраздел "Платный интернет" в разделе "Портал".
         * @returns {boolean}
         */
        isContentPortalPaidInternetEnabled: (state) => state.contentPortalPaidInternetStatus,

        /**
         * Проверяет, включен ли раздел "Аналитика".
         * @returns {boolean}
         */
        isContentAnalyticsEnabled: (state) => state.contentAnalyticsStatus,

        /**
         * Проверяет, включны ли функции с бесплатной авторизацией по звонку и пейволом на определенных разделах
         * @returns {boolean}
         */
        isContentAuthFreemiumEnabled: (state) => state.contentAuthFreemiumStatus,

        /**
         * Проверяет, включено ли новое меню вместо старого.
         * @returns {boolean}
         */
        isContentNewMenuEnabled: (state) => state.contentNewMenuStatus,

        /**
         * Проверяет, скрыты ли локации.
         * @returns {boolean}
         */
        areLocationsHidden: (state) => state.hideLocationsStatus
      }
    },

    /**
     * Модуль для управления режимами работы сайта.
     *
     * Этот модуль содержит состояния (булевые флаги) для активации и деактивации
     * различных режимов работы сайта
     *
     * @namespace mode
     */
    mode: {
      state: () => ({
        portalOnlyModeStatus: CONTENT_PORTAL_ONLY_MODE,
        experimentalModeStatus: EXPERIMENTAL_MODE,
        ciscoModeStatus: CISCO_MODE
      }),
      getters: {
        /**
         * Проверяет, включен ли режим "Только портал".
         * @returns {boolean}
         */
        isPortalOnlyModeEnabled: (state) => state.portalOnlyModeStatus,

        /**
         * Проверяет, включен ли экспериментальный режим.
         * @returns {boolean}
         */
        isExperimentalModeEnabled: (state) => state.experimentalModeStatus,

        /**
         * Проверяет, включен ли cisco режим.
         * @returns {boolean}
         */
        isCiscoModeEnabled: (state) => state.ciscoModeStatus
      }
    }
  },
  namespaced: true
};

export default featureFlags;
