/**
 * Утилиты для работы с графиками
 */

export default class ChartUtils {
  /**
   * Конвертирует timestamp из вида прилетаюшего с бэка в вид для отображения на графике
   *
   * @param timestampFromAPI - таймстамп, который прилетает с бэка
   * @returns {number} - таймстамп в виде, нужном для построения графика
   */
  static convertTimestampForUseInChartItem(timestampFromAPI) {
    const time = new Date(timestampFromAPI * 1000);
    return Date.UTC(
      time.getFullYear(),
      time.getMonth(),
      time.getDate(),
      time.getHours(),
      time.getMinutes(),
      time.getSeconds()
    );
  }
}
