<template>
  <div class="w-100">
    <highcharts :options="eventsChartOptions"></highcharts>
  </div>
</template>

<script>
import eventsSummaryChartService from '@/services/eventsSummaryChartService';
import eventsSummaryChartOptions from './eventsSummaryChartOptions';
import { EVENT_TYPES, VIEW_TYPES } from './config';
import { ChartUtils } from '@/utils/';


export default {
  name: 'EventsSummaryChart',
  props: {
    /**
     * Пропс для установки режима работы компонента
     *
     * В настоящее время работает в двух местах /dashboard и /monitor/events
     * Соответсвенно может иметь два значения dashboard и events
     *
     * В /dashboard используются данные о локации и with child отсюда
     * $store.getters.dashboardBlocksLocationSettingsForGetStatistic
     *
     * В /monitor/events отсюда
     * $store.getters.getDefaultLocationForRequests
     * $store.getters.getDefaultWithChildsForRequests
     */
    viewType: {
      type: String,
      validator(value) {
        return [VIEW_TYPES.DASHBOARD, VIEW_TYPES.EVENTS].includes(value);
      },
      default: VIEW_TYPES.EVENTS
    }
  },
  data() {
    return {
      seriesERROR: [],
      seriesWARNING: [],
      seriesINFO: []
    };
  },
  computed: {
    eventsChartOptions() {
      return {
        ...eventsSummaryChartOptions,
        series: [
          {
            name: this.$i18n.t('dashboard.ERROR'),
            lineWidth: 5,
            data: this.seriesERROR,
            color: '#f86c6b'
          },
          {
            name: this.$i18n.t('dashboard.WARNING'),
            data: this.seriesWARNING,
            color: '#f8cb00'
          },
          {
            name: this.$i18n.t('dashboard.INFO'),
            data: this.seriesINFO,
            color: '#63c2de'
          }
        ]
      }
    },
    locationIdForEventsDataReq() {
      if (this.viewType === VIEW_TYPES.DASHBOARD) {
        return this.$store.getters.dashboardBlocksLocationSettingsForGetStatistic.locationId;
      }
      if (this.viewType === VIEW_TYPES.EVENTS) {
        return this.$store.getters.getDefaultLocationForRequests(this);
      }
      return this.$store.getters.getDefaultLocationForRequests(this);
    },
    withChildsForEventsDataReq() {
      if (this.viewType === VIEW_TYPES.DASHBOARD) {
        return this.$store.getters.dashboardBlocksLocationSettingsForGetStatistic.with_child;
      }
      if (this.viewType === VIEW_TYPES.EVENTS) {
        return this.$store.getters.getDefaultWithChildsForRequests(this);
      }
      return this.$store.getters.getDefaultWithChildsForRequests(this);
    }
  },
  created() {
    this.getEventsSummaryFromAPI();
  },
  watch: {
    locationIdForEventsDataReq() {
      this.getEventsSummaryFromAPI();
    },
    withChildsForEventsDataReq() {
      this.getEventsSummaryFromAPI();
    }
  },
  methods: {
    /**
     * Очищает все серии данных на графике
     */
    clearAllDataSeriesOnTheChart() {
      this.seriesERROR = [];
      this.seriesWARNING = [];
      this.seriesINFO = [];
    },
    /**
     * Наполняет серии данных( по типам событий ) на графике
     *
     * @param {Array} summaryList данные о событиях, полученые с бэка
     */
    addEventsSummaryDataToChart(summaryList) {
      this.clearAllDataSeriesOnTheChart();
      summaryList.by_level.forEach((event) => {
        const date = ChartUtils.convertTimestampForUseInChartItem(event.timestamp);
        if (event.type === EVENT_TYPES.INFO) {
          this.seriesINFO.push([date, event.total]);
        } else if (event.type === EVENT_TYPES.WARNING) {
          this.seriesWARNING.push([date, event.total]);
        } else if (event.type === EVENT_TYPES.ERROR) {
          this.seriesERROR.push([date, event.total]);
        }
      });
    },
    /**
     * Подгружаем данные о событиях из API
     */
    async getEventsSummaryFromAPI() {
      const summaryList = await eventsSummaryChartService.fetchSummaryEventsData(
        this.locationIdForEventsDataReq,
        this.withChildsForEventsDataReq
      );
      this.addEventsSummaryDataToChart(summaryList);
    }
  }
};
</script>

<style scoped></style>
