<template>
  <BranchProfileForm
    :formConfig="formConfig"
    :forbiddenProfileNames="forbiddenProfileNames"
    @submit="handleSubmit"
  >
    <template v-slot:control-buttons>
      <slot name="form-control-buttons"></slot>
    </template>
  </BranchProfileForm>
</template>

<script>
/**
 * компонент настроенной формы BranchProfileForm через generateFormConfig для изменения существующего branch profile.
 * ! Компонент локальный, используется только в ChangeBranchProfileModal.vue
 * @component
 */

/* eslint-disable camelcase */
import {
  BranchProfileForm,
  generateFormConfig
} from '../../features';

export default {
  name: 'ChangeBranchProfileForm',
  components: {
    BranchProfileForm
  },
  props: {
    forbiddenProfileNames: {
      type: Array,
      default: () => []
    },
    branchProfileForChanging: {
      type: Object,
      required: true
    },
    radiusGroupsNames: {
      type: Array,
      default: () => []
    },
    redirectRulesNames: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
    };
  },
  computed: {
  },
  methods: {
    handleSubmit(formValues) {
      this.$emit('submit', formValues);
    }
  },
  created() {
    // задаем дефолтные значения для каждого поля формы
    const {
      created_at,
      updated_at,
      name,
      description,
      native_vlan_id,
      fallback_radio_shut_enabled,
      arp_caching_enabled,
      efficient_sw_upgrade_enabled,
      ip_overlap_enabled,
      pmk_propagation_enabled,
      radius_group,
      web_auth_profile,
      branch_profile_vlans
    } = this.branchProfileForChanging;

    const branchProfileVlansValue = branch_profile_vlans.map(rawVlanData => {
      const {
        name,
        vlan_id,
        acl_ingress,
        acl_egress
      } = rawVlanData;

      return {
        name,
        vlan_id,
        acl_ingress,
        acl_egress
      };
    });

    const formConfig = generateFormConfig({
      i18nInstance: this.$i18n,
      initialValues: {
        general: {
          profileName: {
            isDisabled: true,
            defaultValue: name
          },
          profileDescription: {
            defaultValue: description
          },
          nativeVlan: {
            defaultValue: native_vlan_id
          },
          fallbackRadioShutdown: {
            defaultValue: fallback_radio_shut_enabled
          },
          arpCaching: {
            defaultValue: arp_caching_enabled
          },
          efficientSoftwareUpgrade: {
            defaultValue: efficient_sw_upgrade_enabled
          },
          ipOverlap: {
            defaultValue: ip_overlap_enabled
          },
          pmkPropagation: {
            defaultValue: pmk_propagation_enabled
          }
        },
        authentication: {
          radiusGroup: {
            defaultValue: radius_group,
            selectOptions: this.radiusGroupsNames.map(name => ({
              display: name, data: name
            }))
          }
        },
        vlan: {
          branchProfileVlans: {
            defaultValue: branchProfileVlansValue
          }
        },
        policy: {
          webAuthentication: {
            defaultValue: web_auth_profile,
            selectOptions: this.redirectRulesNames.map(name => ({
              display: name, data: name
            }))
          }
        }
      }
    });

    this.formConfig = formConfig;
  }
};
</script>

<style lang="css" scoped>
</style>
