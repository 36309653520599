<template>
  <Transition name="fade">
    <div class="menu-right-side" v-show="isOpen">
      <div class="content-wrapper">
        <MenuSection v-for="item in items"
          :key="item.index"
          :item="item"
          @close="$emit('close')"
        />
      </div>
    </div>
  </Transition>
</template>

<script>
import MenuSection from './MenuSection.vue';

export default {
  name: 'MenuRightSide',
  components: {
    MenuSection
  },
  props: {
    isOpen: {
      type: Boolean,
      default: false
    },
    items: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
    };
  },
  computed: {
  },
  methods: {
  }
};
</script>

<style lang="css" scoped>
.menu-right-side {
  position: absolute;
  top: 0;
  left: 100%;
  height: 100%;
  min-width: 220px;
  width: max-content;
  padding: 24px 4px;
  box-shadow: var(--box-shadow);
  background-color: var(--sidebar-bg-color);
  color: var(--sidebar-font-color);
  border-left: 1px dotted var(--sidebar-accent-color);
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;

  overflow: hidden;
  cursor: default;
}

.content-wrapper {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 18px 0px;
  height: 100%;
}
</style>
