/**
 * Модуль Vuex для управления навигационным меню.
 *
 * @module navigation
 * @namespace
 *
 * Здесь размещаются стейты, геттеры, мутации и экшны, специфические для локализации.
 * Для использования в компонентах и других модулях Vuex.
 *
 * doc о модулях vuex:
 * https://v3.vuex.vuejs.org/ru/guide/modules.html
 *
 */

const DEFAULT_ROOT_VISIBILITY_STATE = {
  administration: false,
  configuration: false,
  dashboard: false,
  licensing: false,
  monitoring: false,
  NMS: false,
  troubleshooting: false
};

const navigation = {
  state: () => ({
    // какой root-раздел меню открыт/закрыт
    // ! в один момент может быть открыт только 1 root-раздел
    navVisibility: DEFAULT_ROOT_VISIBILITY_STATE
  }),
  getters: {
    navVisibility: (state) => state.navVisibility,
    currentNavVisible: (state) => {
      const names = Object.keys(state.navVisibility);

      const result = names.filter(name => {
        return state.navVisibility[name];
      });

      if (result.length > 1) {
        throw new Error('it is not possible to have multiple visible root navs');
      }

      return result.length ? result[0] : null;
    }
  },
  mutations: {
    setNavVisibility(state, newState) {
      state.navVisibility = newState;
    }
  },
  actions: {
    toggleNavVisibility({ getters, commit }, name) {
      const isVisible = !getters.navVisibility[name];

      const newState = {
        ...DEFAULT_ROOT_VISIBILITY_STATE,
        [name]: isVisible
      };

      commit('setNavVisibility', newState);
    },
    openNavVisibility({ commit }, name) {
      const newState = {
        ...DEFAULT_ROOT_VISIBILITY_STATE,
        [name]: true
      };

      commit('setNavVisibility', newState);
    },
    closeNavVisibility({ commit }, name) {
      const newState = {
        ...DEFAULT_ROOT_VISIBILITY_STATE,
        [name]: false
      };

      commit('setNavVisibility', newState);
    }
  },
  namespaced: true
};

export default navigation;
