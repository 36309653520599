/* eslint-disable no-param-reassign */
/* eslint-disable no-restricted-syntax */

// трансформация конфига под currentPath
// помечает текущую страницу как selected + все родители от корня
// добавляет родителю ссылку на ребенка в next для currentPath
function transformConfigListForCurrentRoute({ config, currentPath }) {
  function findEntityAndMarkAncestors(node, currentPath) {
    if (node.to === currentPath) {
      node.selected = true; // Пометить текущую сущность как выбранную
      node.next = {};
      return true;
    }

    if (node.children && node.children.length > 0) {
      for (const child of node.children) {
        if (findEntityAndMarkAncestors(child, currentPath)) {
          // Пометить предка текущей сущности как выбранного
          node.selected = true;
          node.next = child;

          return true;
        }
      }
    }

    return false;
  }

  const resultArr = [];

  config.forEach(item => {
    findEntityAndMarkAncestors(item, currentPath);
    resultArr.push(item);
  });

  // рекурсивная фильтрация
  // оставляет только те узлы, в которых isShow: true
  // если у ноды isShow: false и у нее были children, они также не попадут в результат и, как следствие, не будут отрисованы
  const filterResult = (items) => {
    return items.reduce((acc, item) => {
      if (!item.isShow) {
        return acc;
      }

      if (item.children && item.children.length > 0) {
        item.children = filterResult(item.children);
      }

      acc.push(item);
      return acc;
    }, []);
  };

  return filterResult(resultArr);
}

export {
  transformConfigListForCurrentRoute
};
