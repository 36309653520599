<template>
  <span v-if="props.formattedRow[props.column.field]"
    class="connection-time"
  >
    {{ props.formattedRow[props.column.field] }}
  </span>
  <span v-else>-</span>
</template>

<script>
/**
 * компонент - ячейка ip.
 * компонент локальный: используется только в ApsTable.vue
 * @component
 */

export default {
  name: 'IPCell',
  components: {},
  props: {
    props: {
      type: Object,
      required: true
    }
  },
  methods: {
  }
};
</script>

<style lang="css" scoped>
.connection-time {
  font-size: 0.7em;
}
</style>
