/**
 * Модуль Vuex для централизованной обработки состояния API запросов.
 *
 * @module apiStatus
 * @withoutNamespace
 *
 * @description
 * Этот модуль используется для централизованного управления состоянием запросов к API в приложении.
 * Он предоставляет возможность установки статуса загрузки, успеха и ошибки, а также
 * хранения текста ошибки запроса. Также, модуль может использоваться для отображения
 * глобальных элементов интерфейса, таких как лоадер и попапы ошибок.
 *
 * Документация о модулях Vuex:
 * https://v3.vuex.vuejs.org/ru/guide/modules.html
 *
 * Как вызвать state, getters, mutations этого модуля внутри другого vuex-модуля:
 * https://v3.vuex.vuejs.org/ru/guide/modules.html#%D0%B4%D0%BE%D1%81%D1%82%D1%83%D0%BF-%D0%BA-%D0%B3%D0%BB%D0%BE%D0%B1%D0%B0%D0%BB%D1%8C%D0%BD%D0%BE%D0%BC%D1%83-%D1%81%D0%BE%D0%B4%D0%B5%D1%80%D0%B6%D0%B8%D0%BC%D0%BE%D0%BC%D1%83-%D0%B2-%D0%BC%D0%BE%D0%B4%D1%83%D0%BB%D1%8F%D1%85-%D1%81%D0%BE-%D1%81%D0%B2%D0%BE%D0%B8%D0%BC-%D0%BF%D1%80%D0%BE%D1%81%D1%82%D1%80%D0%B0%D0%BD%D1%81%D1%82%D0%B2%D0%BE%D0%BC-%D0%B8%D0%BC%D0%B5%D0%BD
 *
 * @example
 * * Примеры использования в других модулях Vuex:
 *
 * * Установить статус Loading внутри другого vuex-модуля, чтобы изменить состояние в главном хранилище
 * commit('setLoading', null, { root: true });
 *
 * * Установить статус Success внутри другого vuex-модуля, чтобы изменить состояние в главном хранилище
 * commit('setSuccess', null, { root: true });
 *
 * * Установить статус Error внутри другого vuex-модуля с сообщением об ошибке, чтобы изменить состояние в главном хранилище
 * commit('setError', 'fetching en locale failed', { root: true });
 *
 * @example
 * * Получить errorMessage, isIdle, isLoading, isSuccess, isError внутри компонента Vue:
 * import { mapState, mapGetters } from 'vuex';
 *
 * export default {
 *   computed: {
 *     ...mapState({
 *       errorMessage: (state) => state.apiStatus.errorMessage
 *     }),
 *     ...mapGetters({
 *       isIdle: 'isIdle',
 *       isLoading: 'isLoading',
 *       isSuccess: 'isSuccess',
 *       isError: 'isError',
 *     })
 *   },
 * };
 *
 */

import { STATUSES } from './config';

const {
  idle, loading, success, error
} = STATUSES;

const apiStatus = {
  state: () => ({
    status: idle, // idle, loading, success, error
    successMessage: null, // Текст успеха запроса
    errorMessage: null, // Текст ошибки запроса
    statusTimeoutDuration: 2000 // Продолжительность времени до сброса статуса (в миллисекундах)

  }),
  getters: {
    isIdle: (state) => state.status === idle,
    isLoading: (state) => state.status === loading,
    isSuccess: (state) => state.status === success,
    isError: (state) => state.status === error
  },
  mutations: {
    setLoading(state) {
      state.status = loading;
    },
    setSuccess(state, successMessage) {
      state.status = success;

      if (successMessage) {
        state.successMessage = successMessage;
      }

      // Устанавливаем таймер для сброса статуса
      setTimeout(() => {
        state.status = idle;
        state.successMessage = null;
        state.errorMessage = null;
      }, state.statusTimeoutDuration);
    },
    setError(state, errorMessage) {
      state.status = error;
      state.errorMessage = errorMessage;

      // Устанавливаем таймер для сброса статуса
      setTimeout(() => {
        state.status = idle;
        state.successMessage = null;
        state.errorMessage = null;
      }, state.statusTimeoutDuration);
    }
  }
};

export default apiStatus;
