import wimarkLogoLight from './logo/wimark_logo_light.svg';
import wimarkLogoDark from './logo/wimark_logo_dark.png';
import qtechkLogoLight from './logo/qtech_logo_light.png';
import qtechkLogoDark from './logo/qtech_logo_dark.png';
import beelineLogoLight from './logo/beeline_logo_light.png';
import beelineLogoDark from './logo/beeline_logo_dark.png';
import mtsLogo from './logo/mts_logo.svg';
import wiflyLogo from './logo/wifly_logo.png';
import bulatLogoLight from './logo/bulat_logo_light.png';
import bulatLogoDark from './logo/bulat_logo_dark.png';
import welcomeIntroMain from './images/welcome-intro-main.webp';
import aquariusLogoLight from './logo/aquarius_logo_light.svg';
import aquariusLogoDark from './logo/aquarius_logo_dark.svg';

export {
  wimarkLogoLight,
  wimarkLogoDark,
  qtechkLogoLight,
  qtechkLogoDark,
  beelineLogoLight,
  beelineLogoDark,
  mtsLogo,
  wiflyLogo,
  bulatLogoLight,
  bulatLogoDark,
  welcomeIntroMain,
  aquariusLogoLight,
  aquariusLogoDark
};
