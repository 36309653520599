<template>
  <li class="menu-dropdown-button"
    :class="[ item.selected && 'selected', isCollapsed ? 'collapsed' : 'opened']"
    ref="refEl"
  >
    <router-link v-if="item.to"
      class="menu-item color"
      :to="item.to"
      :title="item.title"
    >
      <i v-if="item.icon" :class="['icon', item.icon, item.selected && 'selected']" />
      <span v-if="!isCollapsed" class="text-content">
        {{ item.title | capitalize }}
      </span>
    </router-link>

    <div v-else
      class="menu-item color"
      @click="handleMenuItemClick"
    >
      <i v-if="item.icon" :class="['icon', item.icon, item.selected && 'selected']" />
      <span v-if="!isCollapsed" class="text-content">
        {{ item.title | capitalize }}
      </span>
      <i v-if="withChevron"
        class="fa fa-lg chevron"
        :class="[isMenuRightSideOpen ? 'fa-angle-double-right active' : 'fa-angle-right initial']"
      />
    </div>

    <MenuRightSide v-if="item.children && item.children.length"
      :isOpen="isMenuRightSideOpen"
      :items="item.children"
      @close="handleMenuRightSideClose"
    />

  </li>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';

import MenuRightSide from './MenuRightSide.vue';

const { mapGetters: navigationMapGetters } = createNamespacedHelpers('navigation');
const { mapActions: navigationMapActions } = createNamespacedHelpers('navigation');

export default {
  name: 'MenuItem',
  components: {
    MenuRightSide
  },
  props: {
    item: {
      type: Object,
      required: true
    },
    isCollapsed: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {};
  },
  computed: {
    ...navigationMapGetters(['currentNavVisible']),
    isMenuRightSideOpen() {
      return this.currentNavVisible === this.item.name;
    },
    withChevron() {
      return !this.isCollapsed && this.item?.children.length > 0;
    }
  },
  watch: {
  },
  methods: {
    ...navigationMapActions(['toggleNavVisibility', 'closeNavVisibility']),

    handleMenuRightSideClose() {
      this.closeNavVisibility(this.item.name);
    },
    handleMenuItemClick() {
      this.toggleNavVisibility(this.item.name);
    },
    handleOutsideClick(event) {
      // игнорируем клик, если он пришел с root-crumb, который должен открывать меню.
      // если не игнорировать, то меню откроется и сразу закроется.
      if (event.ingnoreInHandleOutsideClick) {
        return;
      }

      if (!this.isMenuRightSideOpen) {
        return;
      }

      const { refEl } = this.$refs;
      const container = refEl;

      if (container && !event.composedPath().includes(container)) {
        this.closeNavVisibility(this.item.name);
      }
    }
  },
  mounted() {
    window.addEventListener('click', this.handleOutsideClick);
  },
  destroyed() {
    window.removeEventListener('click', this.handleOutsideClick);
  }
};
</script>

<style lang="css" scoped>
.menu-dropdown-button {
  display: flex;
  align-items: center;
  cursor: pointer;
  color: var(--sidebar-font-color);
}

.menu-dropdown-button.selected.opened .menu-item::before {
  content: '';
  display: inline-block;
  width: 100%;
  height: 100%;
  background-color: var(--brand-success);
  opacity: .5;
  border-radius: 8px;
  position: absolute;
  left: 0;
  top: 0;
  z-index: -1;
}

.menu-dropdown-button.selected.opened .menu-item::after {
  content: '';
  display: inline-block;
  width: 4px;
  height: 60%;
  background-color: var(--brand-success);
  opacity: 1;
  border-radius: 8px;
  position: absolute;
  left: 0%;
  top: 50%;
  transform: translateY(-50%);
  z-index: -1;
}

a:hover, a:focus {
  text-decoration: none;
}

.icon.selected {
  color: var(--brand-success);
}

.menu-item {
  padding: 4px 8px;
  width: 100%;
  display: flex;
  align-items: center;
  gap: 4px;
  position: relative;
  z-index: 1;
  transition: var(--transition);
}

.menu-item:hover > .text-content {
  opacity: var(--opacity);
}

.menu-item.color {
  color: var(--sidebar-font-color);
}

.text-content {
  color: inherit;
  flex: 1 1 auto;
  text-align: start;

  transition: var(--transition);
}

.chevron {
  transition: var(--transition);
}

.chevron.initial {
  transform: translateX(-10%);
}

.chevron.active {
  transform: translateX(10%);
}
</style>
