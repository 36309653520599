<template>
  <button type="button"
    v-if="isFullscreenModeEnabled"
    class="fullscreen-button"
    :class="[colorClass]"
    @click="toggleFullScreen"
    v-tooltip.bottom="$t(`general.fullscreen.${isFullscreenModeActive ? 'collapse' : 'expand'}`)"
  >
    <i class="fa fa-lg" :class="[isFullscreenModeActive ? 'fa-compress' : 'fa-expand']"></i>
  </button>
</template>

<script>

export default {
  name: 'FullscreenModeSwitcher',
  data() {
    return {
      isFullscreenModeActive: false
    };
  },
  methods: {
    expandFullscreen() {
      const elem = document.documentElement;

      if (elem.requestFullscreen) {
        elem.requestFullscreen();
        // safari
      } else if (elem.webkitRequestFullscreen) {
        elem.webkitRequestFullscreen();
        // ie11
      } else if (elem.msRequestFullscreen) {
        elem.msRequestFullscreen();
      }
    },
    collapseFullscreen() {
      if (document.exitFullscreen) {
        document.exitFullscreen();
        // safari
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen();
        // ie11
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen();
      }
    },
    toggleFullScreen() {
      if (this.isFullscreenModeActive) {
        this.collapseFullscreen();
      } else {
        this.expandFullscreen();
      }
    },
    handleFullscreenChange() {
      this.isFullscreenModeActive = Boolean(document.fullscreenElement || document.webkitFullscreenElement || document.mozFullScreenElement);
    }
  },
  computed: {
    colorClass() {
      return 'color';
    },
    isFullscreenModeEnabled() {
      return document.fullscreenEnabled || document.webkitFullscreenEnabled;
    }
  },
  mounted() {
    document.documentElement.addEventListener('fullscreenchange', this.handleFullscreenChange);
    // safari
    document.documentElement.addEventListener('webkitfullscreenchange', this.handleFullscreenChange);
  },
  beforeDestroy() {
    document.documentElement.removeEventListener('fullscreenchange', this.handleFullscreenChange);
    // safari
    document.documentElement.removeEventListener('webkitfullscreenchange', this.handleFullscreenChange);
  }
};
</script>

<style lang="css" scoped>
.fullscreen-button {
  width: 30px;
  height: 30px;
  background: transparent;
  padding: 5px;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: var(--transition);
}

.fullscreen-button.color {
  color: var(--header-font-color);
}

.fullscreen-button:hover {
  opacity: var(--opacity);
}
</style>
