import { LOCALES } from '@/config';
import LOCALES_FILE from '@/locales';

/**
 * Получает локаль по умолчанию (fallback) на основе имени локали.
 *
 * @param {string} localeName - Имя локали для поиска локали по умолчанию.
 * @returns {Object} - Локаль по умолчанию.
 * @example
 * * Получить локаль по умолчанию для английской локали
 * const fallbackLocale = getFallbackLocale(LOCALES.en);
 * console.log('Локаль по умолчанию:', fallbackLocale);
 */
const getFallbackLocale = (localeName) => {
  switch (localeName) {
    case LOCALES.en:
      return LOCALES_FILE.en;

    case LOCALES.ru:
    default:
      return LOCALES_FILE.ru;
  }
};

export {
  getFallbackLocale
};
