/**
 * Миксин для преобразования текста ошибок, прилетающих с бэка в русский/английский человекопонятный текст
 *
 * Используется только в компонентах семейства AUTHENTICATION (экран логина, экран сброса пароля)
 *
 * @mixin
 *
 */
import { AUTHENTICATION_ERRORS_BACKEND_FORMAT } from './config';

const authenticationErrorsShowingWithI18nMixin = {
  methods: {
    /**
     * Принимает на вход текст ошибки, прилетающий с бэка
     * Возвращает или человекопонятный текст ошибки для отображения в UI
     * Или если не смогли понять что за ошибка или не смогли найти перевод - исходный прилетающий с бэка текст ошибки
     *
     * @param errorTextFromBackend {string}
     * @returns {string}
     */
    getAuthenticationErrorTextForDisplayWithI18n(errorTextFromBackend) {
      const errorType = Object.keys(AUTHENTICATION_ERRORS_BACKEND_FORMAT).find(
        (key) => AUTHENTICATION_ERRORS_BACKEND_FORMAT[key].includes(errorTextFromBackend)
      );
      if (!errorType) {
        return errorTextFromBackend;
      }
      const isI18nTextForError = this.$te(`login.errors.${errorType}`);
      const errorTextForDisplaying = isI18nTextForError ? this.$t(`login.errors.${errorType}`) : errorTextFromBackend;
      return errorTextForDisplaying;
    }
  }
};

export default authenticationErrorsShowingWithI18nMixin;
