<template>
  <modal v-model="isOpen" class="modal-danger" effect="fade/zoom">
    <h4 slot="modal-title" class="modal-title">{{ $t('general.confirmDeletingTitle') }}</h4>

    <p class="confirmation-message">
      {{ $t('general.confirmDeletingText') }}
      <span class="target-text">{{ targetText }}</span>
    </p>

    <slot name="additional" />

    <div slot="modal-footer" class="modal-footer">
      <WButton secondary @click="handleClose">
        {{ $t('general.cancel') }}
      </WButton>
      <WButton danger outline @click="handleDelete">
        {{ $t('general.delete') }}
      </WButton>
    </div>
  </modal>
</template>

<script>
import Modal from './Modal.vue';

/**
* @deprecated Этот компонент устарел и будет удален в следующих версиях.
* ! Warning: Следует использовать WModalContent или WModalWindow для модальных окон.
*/

export default {
  name: 'DeleteDialog',
  components: { Modal },
  props: {
    isOpen: { type: Boolean, required: true },
    handleClose: { type: Function, default: () => {} },
    handleDelete: { type: Function, default: () => {} },
    targetText: { type: String, default: '' }
  }
};
</script>

<style lang="scss" scoped>
.confirmation-message {
  margin: 0;
}

.target-text {
  font-weight: 700;
}
</style>
