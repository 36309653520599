import { v4 as uuidv4 } from 'uuid';
import RouteGroups from '@/access-control/RouteGroups';

function showMainSectionsConfigurationWirelessSetup({ thisInstance, $can }) {
  if (thisInstance.isPortalOnlyModeEnabled) {
    return false;
  }
  if (thisInstance.isWLCProduct) {
    return false;
  }

  return $can('read', { route: 'Wlans' });
}

// todo здесь точно условие && а не || ?
function showMainSectionsConfigurationWireless({ thisInstance, $can }) {
  if (thisInstance.isPortalOnlyModeEnabled) {
    return false;
  }

  return $can('read', { route: 'Hotspot' })
    && $can('read', { route: 'Aps' })
    && $can('read', { route: 'externaAPs' });
}

function showMainSectionsConfigurationTagsAndProfiles({ thisInstance, $can }) {
  if (thisInstance.isPortalOnlyModeEnabled) {
    return false;
  }

  return $can('read', { route: 'Templates' })
    || $can('read', { route: 'Wlans' });
}

function showMainSectionsConfigurationSecurity({ thisInstance, $can }) {
  if (thisInstance.isPortalOnlyModeEnabled) {
    return false;
  }

  return $can('read', { route: 'Radius' })
    || $can('read', { route: 'Firewall' })
    || $can('read', { route: 'GuestControl' });
}

function showMainSectionsConfigurationRadioConfiguration({ thisInstance, $can }) {
  if (thisInstance.isPortalOnlyModeEnabled) {
    return false;
  }

  return $can('read', { route: 'RRM' });
}

function showMainSectionsConfigurationGeneral({ thisInstance, $can }) {
  if (thisInstance.isPortalOnlyModeEnabled) {
    return false;
  }

  return $can('read', { route: 'Rules' })
    || ($can('read', { route: 'SNMPMonitoringConfig' }) && thisInstance.isExperimentalModeEnabled);
}

function showMainSectionsConfigurationPortal({ thisInstance, $can }) {
  const isNotAdvertisingUserRole = !thisInstance.isAdvertisingRole;

  if (!(thisInstance.isContentPortalEnabled && thisInstance.$canSomeInGroup('read', RouteGroups.PORTAL))) {
    return false;
  }

  return $can('read', { route: 'PortalDashboard' })
    || $can('read', { route: 'PortalProfilesNew' })
    || $can('read', { route: 'PortalPreview' })
    || $can('read', { route: 'PortalPagesNew' })
    || $can('read', { route: 'PortalHotelVouchers' })
    || $can('read', { route: 'PortalAdsNew' })
    || $can('read', { route: 'PortalAccessServers' })
    || ($can('read', { route: 'UserAccounts' }) && isNotAdvertisingUserRole)
    || ($can('read', { route: 'PortalSessions' }) && isNotAdvertisingUserRole)
    || ($can('read', { route: 'PortalStats' }) && isNotAdvertisingUserRole)
    || ($can('read', { route: 'PortalBirthdays' }) && isNotAdvertisingUserRole)
    || ($can('read', { route: 'PortalAdsStats' }) && isNotAdvertisingUserRole)
    || ($can('read', { route: 'PortalLocationsStat' }) && isNotAdvertisingUserRole)
    || ($can('read', { route: 'Vouchers' }) && (thisInstance.isContentPortalPaidInternetEnabled && thisInstance.$canSomeInGroup('read', RouteGroups.PORTAL_PAID_INTERNET)))
    || ($can('read', { route: 'Tariffs' }) && (thisInstance.isContentPortalPaidInternetEnabled && thisInstance.$canSomeInGroup('read', RouteGroups.PORTAL_PAID_INTERNET)))
    || ($can('read', { route: 'PaymentSystems' }) && (thisInstance.isContentPortalPaidInternetEnabled && thisInstance.$canSomeInGroup('read', RouteGroups.PORTAL_PAID_INTERNET)));
}

function showMainSectionsConfiguration({ thisInstance, $can }) {
  return showMainSectionsConfigurationGeneral({ thisInstance, $can })
    || showMainSectionsConfigurationRadioConfiguration({ thisInstance, $can })
    || showMainSectionsConfigurationSecurity({ thisInstance, $can })
    || showMainSectionsConfigurationTagsAndProfiles({ thisInstance, $can })
    || showMainSectionsConfigurationWireless({ thisInstance, $can })
    || showMainSectionsConfigurationWirelessSetup({ thisInstance, $can })
    || showMainSectionsConfigurationPortal({ thisInstance, $can });
}

/**
 * Описание ролей:
 * роли нужны для построения иерархичности + для специфичного отображения элементов в соответствии с ролью.
 *
 * root - это начало категории. Может быть ссылкой без вложенных элементов, так и не ссылкой с вложенными элементами.
 * section - подраздел. Не является ссылкой. Имеет вложенные элементы children, которые являются ссылкой (обычно role: link). Используется как группировка раздела ссылок и отображается как заголовок.
 * link - конечный элемент пути - ссылка. Не имеет children
 * @param {'root' | 'section' | 'link'} role
 */

const getConfiguration = ({ $t, thisInstance, $can }) => (
  {
    id: uuidv4(),
    name: 'configuration',
    title: $t('sidebarNew.configuration'),
    icon: 'fa fa-wrench fa-fw fa-lg',
    to: false,
    selected: false,
    role: 'root',
    isShow: showMainSectionsConfiguration({ thisInstance, $can }),
    children: [
      // Portal
      {
        id: uuidv4(),
        name: 'portal',
        title: $t('sidebarNew.portalSection'),
        icon: 'fa fa-tv fa-fw fa-lg',
        to: false,
        selected: false,
        role: 'section',
        isShow: showMainSectionsConfigurationPortal({ thisInstance, $can }),
        children: [
          {
            id: uuidv4(),
            name: 'portalDashboard',
            title: $t('sidebarNew.portalDashboard'),
            icon: '',
            to: '/portal/dashboard',
            selected: false,
            role: 'link',
            isShow: $can('read', { route: 'PortalDashboard' }),
            children: []
          },
          {
            id: uuidv4(),
            name: 'portalProfiles',
            title: $t('sidebarNew.profiles'),
            icon: '',
            to: '/portal/profiles',
            selected: false,
            role: 'link',
            isShow: $can('read', { route: 'PortalProfilesNew' }),
            children: []
          },
          {
            id: uuidv4(),
            name: 'portalPreview',
            title: $t('sidebarNew.preview'),
            icon: '',
            to: '/portal/preview',
            selected: false,
            role: 'link',
            isShow: $can('read', { route: 'PortalPreview' }),
            children: []
          },
          {
            id: uuidv4(),
            name: 'portalPages',
            title: $t('sidebarNew.pages'),
            icon: '',
            to: '/portal/pages',
            selected: false,
            role: 'link',
            isShow: $can('read', { route: 'PortalPagesNew' }),
            children: []
          },
          {
            id: uuidv4(),
            name: 'portalhotelVouchers',
            title: $t('sidebarNew.hotelVouchers'),
            icon: '',
            to: '/portal/hotel-vouchers',
            selected: false,
            role: 'link',
            isShow: $can('read', { route: 'PortalHotelVouchers' }),
            children: []
          },
          {
            id: uuidv4(),
            name: 'portalAds',
            title: $t('sidebarNew.ads'),
            icon: '',
            to: '/portal/ads',
            selected: false,
            role: 'link',
            isShow: $can('read', { route: 'PortalAdsNew' }),
            children: []
          },
          {
            id: uuidv4(),
            name: 'portalAccessServers',
            title: $t('sidebarNew.PortalAccessServers'),
            icon: '',
            to: '/portal/access-servers',
            selected: false,
            role: 'link',
            isShow: $can('read', { route: 'PortalAccessServers' }),
            children: []
          },
          {
            id: uuidv4(),
            name: 'portalAccounts',
            title: $t('sidebarNew.accounts'),
            icon: '',
            to: '/portal/accounts',
            selected: false,
            role: 'link',
            isShow: ($can('read', { route: 'UserAccounts' }) && !thisInstance.isAdvertisingRole),
            children: []
          },
          {
            id: uuidv4(),
            name: 'portalSessions',
            title: $t('sidebarNew.sessions'),
            icon: '',
            to: '/portal/sessions',
            selected: false,
            role: 'link',
            isShow: ($can('read', { route: 'PortalSessions' }) && !thisInstance.isAdvertisingRole),
            children: []
          },
          {
            id: uuidv4(),
            name: 'portalStatistics',
            title: $t('sidebarNew.statistics'),
            icon: '',
            to: '/portal/statistics',
            selected: false,
            role: 'link',
            isShow: ($can('read', { route: 'PortalStats' }) && !thisInstance.isAdvertisingRole),
            children: []
          },
          {
            id: uuidv4(),
            name: 'portalBirthdays',
            title: $t('sidebarNew.birthdays'),
            icon: '',
            to: '/portal/birthdays',
            selected: false,
            role: 'link',
            isShow: ($can('read', { route: 'PortalBirthdays' }) && !thisInstance.isAdvertisingRole),
            children: []
          },
          {
            id: uuidv4(),
            name: 'portalStatisticsAds',
            title: $t('sidebarNew.adsStatistic'),
            icon: '',
            to: '/portal/statistics-ads',
            selected: false,
            role: 'link',
            isShow: ($can('read', { route: 'PortalAdsStats' }) && !thisInstance.isAdvertisingRole),
            children: []
          },
          {
            id: uuidv4(),
            name: 'portalStatisticsLocation',
            title: $t('sidebarNew.locationsStatistics'),
            icon: '',
            to: '/portal/statistics-location',
            selected: false,
            role: 'link',
            isShow: ($can('read', { route: 'PortalLocationsStat' }) && !thisInstance.isAdvertisingRole) && thisInstance.areLocationsVisible,
            children: []
          },
          {
            id: uuidv4(),
            name: 'portalVouchers',
            title: $t('sidebarNew.vouchers'),
            icon: '',
            to: '/portal/vouchers',
            selected: false,
            role: 'link',
            isShow: ($can('read', { route: 'Vouchers' }) && (thisInstance.isContentPortalPaidInternetEnabled && thisInstance.$canSomeInGroup('read', RouteGroups.PORTAL_PAID_INTERNET))),
            children: []
          },
          {
            id: uuidv4(),
            name: 'portalTariffs',
            title: $t('sidebarNew.tariffs'),
            icon: '',
            to: '/portal/tariffs',
            selected: false,
            role: 'link',
            isShow: ($can('read', { route: 'Tariffs' }) && (thisInstance.isContentPortalPaidInternetEnabled && thisInstance.$canSomeInGroup('read', RouteGroups.PORTAL_PAID_INTERNET))),
            children: []
          },
          {
            id: uuidv4(),
            name: 'portalPaymentSystems',
            title: $t('sidebarNew.paymentSystems'),
            icon: '',
            to: '/portal/payment-systems',
            selected: false,
            role: 'link',
            isShow: ($can('read', { route: 'PaymentSystems' }) && (thisInstance.isContentPortalPaidInternetEnabled && thisInstance.$canSomeInGroup('read', RouteGroups.PORTAL_PAID_INTERNET))),
            children: []
          }
        ]
      },
      // Radio Configuration
      {
        id: uuidv4(),
        name: 'radioConfiguration',
        title: $t('sidebarNew.radioConfiguration'),
        icon: 'fa fa-signal fa-fw fa-lg',
        to: false,
        selected: false,
        role: 'section',
        isShow: showMainSectionsConfigurationRadioConfiguration({ thisInstance, $can }),
        children: [
          {
            id: uuidv4(),
            name: 'rrm',
            title: $t('sidebarNew.rrm'),
            icon: '',
            to: '/rrm',
            selected: false,
            role: 'link',
            isShow: $can('read', { route: 'RRM' }),
            children: []
          }
        ]
      },
      // Security
      {
        id: uuidv4(),
        name: 'security',
        title: $t('sidebarNew.security'),
        icon: 'fa fa-shield fa-fw fa-lg',
        to: false,
        selected: false,
        role: 'section',
        isShow: showMainSectionsConfigurationSecurity({ thisInstance, $can }),
        children: [
          {
            id: uuidv4(),
            name: 'AAA',
            title: $t('sidebarNew.aaa'),
            icon: '',
            to: '/radius',
            selected: false,
            role: 'link',
            isShow: $can('read', { route: 'Radius' }),
            children: []
          },
          {
            id: uuidv4(),
            name: 'acl',
            title: $t('sidebarNew.acl'),
            icon: '',
            to: '/firewall',
            selected: false,
            role: 'link',
            isShow: $can('read', { route: 'Firewall' }),
            children: []
          },
          {
            id: uuidv4(),
            name: 'guestControl',
            title: $t('sidebarNew.webAuth'),
            icon: '',
            to: '/GuestControl',
            selected: false,
            role: 'link',
            isShow: $can('read', { route: 'GuestControl' }),
            children: []
          }
        ]
      },
      // Tags & Profiles
      {
        id: uuidv4(),
        name: 'tagsAndProfiles',
        title: $t('sidebarNew.tagsAndProfiles'),
        icon: 'fa fa-tag fa-fw fa-lg',
        to: false,
        selected: false,
        role: 'section',
        isShow: showMainSectionsConfigurationTagsAndProfiles({ thisInstance, $can }),
        children: [
          {
            id: uuidv4(),
            name: 'templates',
            title: $t('sidebarNew.template'),
            icon: '',
            to: '/templates',
            selected: false,
            role: 'link',
            isShow: $can('read', { route: 'Templates' }),
            children: []
          },
          {
            id: uuidv4(),
            name: 'wlans',
            title: $t('sidebarNew.wlans'),
            icon: '',
            to: '/wlans',
            selected: false,
            role: 'link',
            isShow: $can('read', { route: 'Wlans' }),
            children: []
          },
          {
            id: uuidv4(),
            name: 'apjoin',
            title: $t('apjoin.pageName'),
            icon: '',
            to: '/apjoin',
            selected: false,
            role: 'link',
            isShow: $can('read', { route: 'APJoinPage' }) && thisInstance.isWLCProduct,
            children: []
          },
          {
            id: uuidv4(),
            name: 'branch-profile',
            title: $t('branchProfile.pageName'),
            icon: '',
            to: '/branch-profile',
            selected: false,
            role: 'link',
            isShow: $can('read', { route: 'BranchProfilePage' }) && thisInstance.isWLCProduct,
            children: []
          }
        ]
      },
      // Wireless
      {
        id: uuidv4(),
        name: 'wireless',
        title: $t('sidebarNew.wireless'),
        icon: 'fa fa-wifi fa-fw fa-lg',
        to: false,
        selected: false,
        role: 'section',
        isShow: showMainSectionsConfigurationWireless({ thisInstance, $can }),
        children: [
          {
            id: uuidv4(),
            name: 'hotspotOpenRoaming',
            title: $t('sidebarNew.hotspotOpenRoaming'),
            icon: '',
            to: '/hotspot',
            selected: false,
            role: 'link',
            isShow: $can('read', { route: 'Hotspot' }) && !thisInstance.isWLCProduct,
            children: []
          },
          {
            id: uuidv4(),
            name: 'aps',
            title: $t('sidebarNew.aps'),
            icon: '',
            to: '/aps',
            selected: false,
            role: 'link',
            isShow: $can('read', { route: 'Aps' }),
            children: []
          },
          {
            id: uuidv4(),
            name: 'externalAPs',
            title: $t('sidebarNew.externalAPs'),
            icon: '',
            to: '/external-aps',
            selected: false,
            role: 'link',
            isShow: $can('read', { route: 'ExternalAPs' }) && thisInstance.isExperimentalModeEnabled,
            children: []
          }
        ]
      },
      // Wireless Setup
      {
        id: uuidv4(),
        name: 'wirelessSetup',
        title: $t('sidebarNew.wirelessSetup'),
        icon: 'fa fa-signal fa-fw fa-lg',
        to: false,
        selected: false,
        role: 'section',
        isShow: showMainSectionsConfigurationWirelessSetup({ thisInstance, $can }),
        children: [
          {
            id: uuidv4(),
            name: 'wlanWizard',
            title: $t('sidebarNew.wlanWizard'),
            icon: '',
            to: '/wlanWizardV2',
            selected: false,
            role: 'link',
            isShow: thisInstance.isAdminRole,
            children: []
          }
        ]
      },
      {
        id: uuidv4(),
        name: 'rules',
        title: $t('sidebarNew.rules'),
        icon: '',
        to: '/monitor/rules',
        selected: false,
        role: 'link',
        isShow: $can('read', { route: 'Rules' }) && !thisInstance.isPortalOnlyModeEnabled,
        children: []
      },
      {
        id: uuidv4(),
        name: 'SNMPMonitoringConfig',
        title: $t('sidebarNew.SNMPMonitoringConfig'),
        icon: '',
        to: '/monitor/snmp-monitoring-config',
        selected: false,
        role: 'link',
        isShow: $can('read', { route: 'SNMPMonitoringConfig' }) && thisInstance.isExperimentalModeEnabled,
        children: []
      }
    ]
  }
);

export default getConfiguration;
