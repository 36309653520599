import { v4 as uuidv4 } from 'uuid';

function showMainSectionsLicensingGeneral({ thisInstance, $can }) {
  return $can('read', { route: 'System' })
    && (thisInstance.isAdminRole || thisInstance.isOperatorRole);
}

/**
 * Описание ролей:
 * роли нужны для построения иерархичности + для специфичного отображения элементов в соответствии с ролью.
 *
 * root - это начало категории. Может быть ссылкой без вложенных элементов, так и не ссылкой с вложенными элементами.
 * section - подраздел. Не является ссылкой. Имеет вложенные элементы children, которые являются ссылкой (обычно role: link). Используется как группировка раздела ссылок и отображается как заголовок.
 * link - конечный элемент пути - ссылка. Не имеет children
 * @param {'root' | 'section' | 'link'} role
 */

const getLicensing = ({ $t, thisInstance, $can }) => (
  {
    id: uuidv4(),
    name: 'licensing',
    title: $t('sidebarNew.licensing'),
    icon: 'fa fa-copyright fa-fw fa-lg',
    to: '/system',
    selected: false,
    role: 'root',
    isShow: showMainSectionsLicensingGeneral({ thisInstance, $can }),
    children: []
  }
);

export default getLicensing;
