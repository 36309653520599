<template>
  <div class="footer-container">
    <ul class="list-container">
      <li>
        <span>
          {{ $t('header.total') }}:
          {{ totalOperationsAmount }}
        </span>
      </li>
      <li>
        <span>
          {{ $t('header.pending') }}:
          {{ pendingOperationsAmount }}
        </span>
      </li>
      <li>
        <span>
          {{ $t('header.success') }}:
          {{ successOperationsAmount }}
        </span>
      </li>
      <li>
        <span>
          {{ $t('header.errors') }}:
          {{ errorOperationsAmount }}
        </span>
      </li>
    </ul>
    <div class="button-container">
      <WButton outline rounded sm
        customClass="c-p-btn"
        @click="handleResetFilterClick"
        v-tooltip.left="$t('header.tooltipShowAll')"
        >
          <i class="fa fa-eraser"></i>
        </WButton>
      <WButton warning outline rounded sm
        customClass="c-p-btn"
        @click="handleErrorFilterClick"
        v-tooltip.left="$t('header.tooltipShowErrorsOnly')"
      >
        <i class="fa fa-warning"></i>
      </WButton>
      <WButton danger outline rounded sm
        customClass="c-p-btn"
        v-if="totalOperationsAmount"
        @click="handleDeleteAllFilterClick"
        v-tooltip.left="$t('header.tooltipDeleteAll')"
      >
        <i class="fa fa-close"></i>
      </WButton>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ControlPanel',
  data() {
    return {
    };
  },
  props: {
    totalOperationsAmount: {
      type: Number,
      required: true
    },
    pendingOperationsAmount: {
      type: Number,
      required: true
    },
    successOperationsAmount: {
      type: Number,
      required: true
    },
    errorOperationsAmount: {
      type: Number,
      required: true
    }
  },
  computed: {
  },
  methods: {
    handleResetFilterClick(event) {
      this.$emit('resetFilter', event);
    },
    handleErrorFilterClick(event) {
      this.$emit('errorFilter', event);
    },
    handleDeleteAllFilterClick(event) {
      this.$emit('deleteAllFilter', event);
    }
  }
};
</script>

<style lang="css" scoped>

ul {
  list-style-type: none;
  padding: 0;
}

.footer-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.list-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.button-container {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  gap: 10px;
}

.c-p-btn {
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.info {
  color: var(--brand-info);
}
</style>
