// web workers проекта
// функции-креаторы можно импортировать в компоненты или vuex и использовать
// структура и работа основана на библиотеке: https://github.com/GoogleChromeLabs/worker-plugin

/**
 * Создает веб-воркер для выполнения работы с интервалом.
 * @function createIntervalWorker
 * @returns {Worker} - Новый экземпляр веб-воркера для работы с интервалом.
 */
const createIntervalWorker = () => new Worker('./intervalWorker.js', { type: 'module' });

/**
 * Создает веб-воркер для выполнения работы с таймаутом.
 * @function createTimeoutWorker
 * @returns {Worker} - Новый экземпляр веб-воркера для работы с таймаутом.
 */
const createTimeoutWorker = () => new Worker('./timeoutWorker.js', { type: 'module' });

export {
  createIntervalWorker,
  createTimeoutWorker
};
