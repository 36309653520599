<template>
  <button type="button"
    class="wifi-wizard-button"
    :class="[colorClass]"
    @click="goToWiFiWizard"
    v-tooltip.top="$t('header.wifiWizard')"
  >
    <i class="fa fa-wifi fa-lg wifi-icon"></i>
    <i class="fa fa-gear gear-icon"></i>
  </button>
</template>

<script>
export default {
  name: 'GoToWifiWizardButton',
  methods: {
    goToWiFiWizard() {
      const DESTINATION_ROUTE_NAME = 'wlanWizardV2';

      if (this.$route.name === DESTINATION_ROUTE_NAME) {
        return;
      }

      this.$router.push({ name: DESTINATION_ROUTE_NAME });
    }
  },
  computed: {
    colorClass() {
      return 'color';
    }
  }
};
</script>

<style>
.wifi-wizard-button {
  width: 30px;
  height: 30px;
  background: transparent;
  padding: 5px;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: var(--transition);

  position: relative;
}

.wifi-wizard-button.color {
  color: var(--header-font-color);
}

.wifi-wizard-button:hover {
  opacity: var(--opacity);
}

.wifi-icon {
  position: relative;
  right: 10%;
  bottom: 5%;
}

.gear-icon {
  position: absolute;
  right: 20%;
  bottom: 25%;

  font-size: 0.6em !important;
  line-height: 0.4em !important;
}
</style>
