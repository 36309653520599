import VueNotifications from 'vue-notifications';
import Vue from 'vue';
import hotspotPollingService from './hotspotPollingService';
// import { LocaleMaster } from '@/utils';
import { API_URL } from '@/config';

export default {
  //todo убрать отсюда закомменченные захардкоженные константы:
  // ACCESS_NETWORK_TYPE, VENUE_GROUP, LANGUAGE EAP_METHODS, EAP_TYPE, IPV4_TYPE, IPV6_TYPE, IP_PROTOCOLS, CONN_CAP_STATUS, LINK_STATUS OPER_CLASSES
  // если ничего не сломается

  // LANGUAGE: [
  //   {
  //     id: 'afr',
  //     name: LocaleMaster.isCachedLocaleRU() ? 'Африкаанс' : 'Afrikaans'
  //   },
  //   {
  //     id: 'ara',
  //     name: LocaleMaster.isCachedLocaleRU() ? 'Арабский' : 'Arabic'
  //   },
  //   {
  //     id: 'aze',
  //     name: LocaleMaster.isCachedLocaleRU() ? 'Азербайджанский' : 'Azeri'
  //   },
  //   {
  //     id: 'bel',
  //     name: LocaleMaster.isCachedLocaleRU() ? 'Белорусский' : 'Belarusian'
  //   },
  //   {
  //     id: 'bul',
  //     name: LocaleMaster.isCachedLocaleRU() ? 'Болгарский' : 'Bulgarian'
  //   },
  //   {
  //     id: 'cat',
  //     name: LocaleMaster.isCachedLocaleRU() ? 'Каталонский' : 'Catalan'
  //   },
  //   {
  //     id: 'cze',
  //     name: LocaleMaster.isCachedLocaleRU() ? 'Чешский' : 'Czech'
  //   },
  //   {
  //     id: 'wel',
  //     name: LocaleMaster.isCachedLocaleRU() ? 'Валлийский' : 'Welsh'
  //   },
  //   {
  //     id: 'dan',
  //     name: LocaleMaster.isCachedLocaleRU() ? 'Датский' : 'Danish'
  //   },
  //   {
  //     id: 'ger',
  //     name: LocaleMaster.isCachedLocaleRU() ? 'Немецкий' : 'German'
  //   },
  //   {
  //     id: 'div',
  //     name: LocaleMaster.isCachedLocaleRU() ? 'Дивехи' : 'Divehi'
  //   },
  //   {
  //     id: 'gre',
  //     name: LocaleMaster.isCachedLocaleRU() ? 'Греческий' : 'Greek'
  //   },
  //   {
  //     id: 'eng',
  //     name: LocaleMaster.isCachedLocaleRU() ? 'Английский' : 'English'
  //   },
  //   {
  //     id: 'epo',
  //     name: LocaleMaster.isCachedLocaleRU() ? 'Эсперанто' : 'Esperanto'
  //   },
  //   {
  //     id: 'spa',
  //     name: LocaleMaster.isCachedLocaleRU() ? 'Испанский' : 'Spanish'
  //   },
  //   {
  //     id: 'est',
  //     name: LocaleMaster.isCachedLocaleRU() ? 'Эстонский' : 'Estonian'
  //   },
  //   {
  //     id: 'eus',
  //     name: LocaleMaster.isCachedLocaleRU() ? 'Баскский' : 'Basque'
  //   },
  //   {
  //     id: 'fas',
  //     name: LocaleMaster.isCachedLocaleRU() ? 'Фарси' : 'Farsi'
  //   },
  //   {
  //     id: 'fin',
  //     name: LocaleMaster.isCachedLocaleRU() ? 'Финский' : 'Finnish'
  //   },
  //   {
  //     id: 'fao',
  //     name: LocaleMaster.isCachedLocaleRU() ? 'Фарерский' : 'Faroese'
  //   },
  //   {
  //     id: 'fre',
  //     name: LocaleMaster.isCachedLocaleRU() ? 'Французский' : 'French'
  //   },
  //   {
  //     id: 'glg',
  //     name: LocaleMaster.isCachedLocaleRU() ? 'Галисийский' : 'Galician'
  //   },
  //   {
  //     id: 'guj',
  //     name: LocaleMaster.isCachedLocaleRU() ? 'Гуджарати' : 'Gujarati'
  //   },
  //   {
  //     id: 'heb',
  //     name: LocaleMaster.isCachedLocaleRU() ? 'Иврит' : 'Hebrew'
  //   },
  //   {
  //     id: 'hin',
  //     name: LocaleMaster.isCachedLocaleRU() ? 'Хинди' : 'Hindi'
  //   },
  //   {
  //     id: 'hrv',
  //     name: LocaleMaster.isCachedLocaleRU() ? 'Хорватский' : 'Croatian'
  //   },
  //   {
  //     id: 'hun',
  //     name: LocaleMaster.isCachedLocaleRU() ? 'Венгерский' : 'Hungarian'
  //   },
  //   {
  //     id: 'hye',
  //     name: LocaleMaster.isCachedLocaleRU() ? 'Армянский' : 'Armenian'
  //   },
  //   {
  //     id: 'ind',
  //     name: LocaleMaster.isCachedLocaleRU() ? 'Индонезийский' : 'Indonesian'
  //   },
  //   {
  //     id: 'isl',
  //     name: LocaleMaster.isCachedLocaleRU() ? 'Исландский' : 'Icelandic'
  //   },
  //   {
  //     id: 'ita',
  //     name: LocaleMaster.isCachedLocaleRU() ? 'Итальянский' : 'Italian'
  //   },
  //   {
  //     id: 'jpn',
  //     name: LocaleMaster.isCachedLocaleRU() ? 'Японский' : 'Japanese'
  //   },
  //   {
  //     id: 'kat',
  //     name: LocaleMaster.isCachedLocaleRU() ? 'Грузинский' : 'Georgian'
  //   },
  //   {
  //     id: 'kaz',
  //     name: LocaleMaster.isCachedLocaleRU() ? 'Казахский' : 'Kazakh'
  //   },
  //   {
  //     id: 'kan',
  //     name: LocaleMaster.isCachedLocaleRU() ? 'Каннада' : 'Kannada'
  //   },
  //   {
  //     id: 'kor',
  //     name: LocaleMaster.isCachedLocaleRU() ? 'Корейский' : 'Korean'
  //   },
  //   {
  //     id: 'kok',
  //     name: LocaleMaster.isCachedLocaleRU() ? 'Конкани' : 'Konkani'
  //   },
  //   {
  //     id: 'kir',
  //     name: LocaleMaster.isCachedLocaleRU() ? 'Кыргызский' : 'Kyrgyz'
  //   },
  //   {
  //     id: 'lit',
  //     name: LocaleMaster.isCachedLocaleRU() ? 'Литовский' : 'Lithuanian'
  //   },
  //   {
  //     id: 'lav',
  //     name: LocaleMaster.isCachedLocaleRU() ? 'Латышский' : 'Latvian'
  //   },
  //   {
  //     id: 'mri',
  //     name: LocaleMaster.isCachedLocaleRU() ? 'Маори' : 'Maori'
  //   },
  //   {
  //     id: 'mkd',
  //     name: LocaleMaster.isCachedLocaleRU() ? 'Македонский' : 'FYRO Macedonian'
  //   },
  //   {
  //     id: 'mon',
  //     name: LocaleMaster.isCachedLocaleRU() ? 'Монгольский' : 'Mongolian'
  //   },
  //   {
  //     id: 'mar',
  //     name: LocaleMaster.isCachedLocaleRU() ? 'Маратхи' : 'Marathi'
  //   },
  //   {
  //     id: 'msa',
  //     name: LocaleMaster.isCachedLocaleRU() ? 'Малайский' : 'Malay'
  //   },
  //   {
  //     id: 'mlt',
  //     name: LocaleMaster.isCachedLocaleRU() ? 'Мальтийский' : 'Maltese'
  //   },
  //   {
  //     id: 'nid',
  //     name: LocaleMaster.isCachedLocaleRU() ? 'Голландский' : 'Dutch'
  //   },
  //   {
  //     id: 'nso',
  //     name: LocaleMaster.isCachedLocaleRU() ? 'Северный сото' : 'Northern Sotho'
  //   },
  //   {
  //     id: 'pan',
  //     name: LocaleMaster.isCachedLocaleRU() ? 'Панджаби' : 'Punjabi'
  //   },
  //   {
  //     id: 'pol',
  //     name: LocaleMaster.isCachedLocaleRU() ? 'Польский' : 'Polish'
  //   },
  //   {
  //     id: 'pus',
  //     name: LocaleMaster.isCachedLocaleRU() ? 'Пушту' : 'Pashto'
  //   },
  //   {
  //     id: 'por',
  //     name: LocaleMaster.isCachedLocaleRU() ? 'Португальский' : 'Portuguese'
  //   },
  //   {
  //     id: 'que',
  //     name: LocaleMaster.isCachedLocaleRU() ? 'Кечуа' : 'Quechua'
  //   },
  //   {
  //     id: 'ron',
  //     name: LocaleMaster.isCachedLocaleRU() ? 'Румынский' : 'Romanian'
  //   },
  //   {
  //     id: 'rus',
  //     name: LocaleMaster.isCachedLocaleRU() ? 'Русский' : 'Russian'
  //   },
  //   {
  //     id: 'san',
  //     name: LocaleMaster.isCachedLocaleRU() ? 'Санскрит' : 'Sanskrit'
  //   },
  //   {
  //     id: 'sme',
  //     name: LocaleMaster.isCachedLocaleRU() ? 'Саамский' : 'Sami'
  //   },
  //   {
  //     id: 'slk',
  //     name: LocaleMaster.isCachedLocaleRU() ? 'Словацкий' : 'Slovak'
  //   },
  //   {
  //     id: 'slv',
  //     name: LocaleMaster.isCachedLocaleRU() ? 'Словенский' : 'Slovenian'
  //   },
  //   {
  //     id: 'sqi',
  //     name: LocaleMaster.isCachedLocaleRU() ? 'Албанский' : 'Albanian'
  //   },
  //   {
  //     id: 'swe',
  //     name: LocaleMaster.isCachedLocaleRU() ? 'Шведский' : 'Swedish'
  //   },
  //   {
  //     id: 'swa',
  //     name: LocaleMaster.isCachedLocaleRU() ? 'Суахили' : 'Swahili'
  //   },
  //   {
  //     id: 'syr',
  //     name: LocaleMaster.isCachedLocaleRU() ? 'Сирийский' : 'Syriac'
  //   },
  //   {
  //     id: 'tam',
  //     name: LocaleMaster.isCachedLocaleRU() ? 'Тамильский' : 'Tamil'
  //   },
  //   {
  //     id: 'tel',
  //     name: LocaleMaster.isCachedLocaleRU() ? 'Телугу' : 'Telugu'
  //   },
  //   {
  //     id: 'tha',
  //     name: LocaleMaster.isCachedLocaleRU() ? 'Тайский' : 'Thai'
  //   },
  //   {
  //     id: 'tgl',
  //     name: LocaleMaster.isCachedLocaleRU() ? 'Тагальский' : 'Tagalog'
  //   },
  //   {
  //     id: 'tsn',
  //     name: LocaleMaster.isCachedLocaleRU() ? 'Тсвана' : 'Tswana'
  //   },
  //   {
  //     id: 'tur',
  //     name: LocaleMaster.isCachedLocaleRU() ? 'Турецкий' : 'Turkish'
  //   },
  //   {
  //     id: 'tat',
  //     name: LocaleMaster.isCachedLocaleRU() ? 'Татарский' : 'Tatar'
  //   },
  //   {
  //     id: 'tso',
  //     name: LocaleMaster.isCachedLocaleRU() ? 'Цонга' : 'Tsonga'
  //   },
  //   {
  //     id: 'ukr',
  //     name: LocaleMaster.isCachedLocaleRU() ? 'Украинский' : 'Ukrainian'
  //   },
  //   {
  //     id: 'urd',
  //     name: LocaleMaster.isCachedLocaleRU() ? 'Урду' : 'Urdu'
  //   },
  //   {
  //     id: 'uzb',
  //     name: LocaleMaster.isCachedLocaleRU() ? 'Узбекский' : 'Uzbek'
  //   },
  //   {
  //     id: 'vie',
  //     name: LocaleMaster.isCachedLocaleRU() ? 'Вьетнамский' : 'Vietnamese'
  //   },
  //   {
  //     id: 'xho',
  //     name: LocaleMaster.isCachedLocaleRU() ? 'Коса' : 'Xhosa'
  //   },
  //   {
  //     id: 'zho',
  //     name: LocaleMaster.isCachedLocaleRU() ? 'Китайский' : 'Chinese'
  //   },
  //   {
  //     id: 'zul',
  //     name: LocaleMaster.isCachedLocaleRU() ? 'Зулу' : 'Zulu'
  //   }
  // ],
  //
  // EAP_METHODS: {
  //   13: {
  //     name: 'EAP-TLS'
  //   },
  //   18: {
  //     name: 'EAP-SIM'
  //   },
  //   21: {
  //     name: 'EAP-TTLS'
  //   },
  //   23: {
  //     name: 'EAP-AKA'
  //   },
  //   50: {
  //     name: "EAP-AKA'"
  //   }
  // },
  //
  // EAP_TYPE: {
  //   2: {
  //     name: LocaleMaster.isCachedLocaleRU() ? 'Внутренняя аутентификация без EAP' : 'Non-EAP Inner Authentication',
  //     subtype: {
  //       1: {
  //         name: 'PAP'
  //       },
  //       2: {
  //         name: 'CHAP'
  //       },
  //       3: {
  //         name: 'MSCHAP'
  //       },
  //       4: {
  //         name: 'MSCHAPv2'
  //       }
  //     }
  //   },
  //   3: {
  //     name: LocaleMaster.isCachedLocaleRU() ? 'EAP метод внутренней аутентификации' : 'Inner Authentication EAP Method',
  //     subtype: {
  //       0: {
  //         name: LocaleMaster.isCachedLocaleRU() ? 'Нет' : 'None'
  //       }
  //     }
  //   },
  //   5: {
  //     name: LocaleMaster.isCachedLocaleRU() ? 'Учетные данные' : 'Credential',
  //     subtype: {
  //       1: {
  //         name: LocaleMaster.isCachedLocaleRU() ? 'SIM карта' : 'SIM'
  //       },
  //       2: {
  //         name: LocaleMaster.isCachedLocaleRU() ? 'USIM карта' : 'USIM'
  //       },
  //       3: {
  //         name: LocaleMaster.isCachedLocaleRU() ? 'NFC' : 'NFC Secure Element'
  //       },
  //       4: {
  //         name: LocaleMaster.isCachedLocaleRU() ? 'Аппаратный токен' : 'Hardware Token'
  //       },
  //       5: {
  //         name: LocaleMaster.isCachedLocaleRU() ? 'Программный токен' : 'Softoken'
  //       },
  //       6: {
  //         name: LocaleMaster.isCachedLocaleRU() ? 'Сертификат' : 'Certificate'
  //       },
  //       7: {
  //         name: LocaleMaster.isCachedLocaleRU() ? 'Имя пользователя/Пароль' : 'Username/Password'
  //       },
  //       8: {
  //         name: LocaleMaster.isCachedLocaleRU() ? 'Анонимно' : 'Anonymous'
  //       },
  //       9: {
  //         name: LocaleMaster.isCachedLocaleRU() ? 'Специфичный для производителя' : 'Vendor Specific'
  //       }
  //     }
  //   }
  // },
  //
  // IPV4_TYPE: {
  //   '-1': {
  //     name: LocaleMaster.isCachedLocaleRU() ? 'Нет' : 'None'
  //   },
  //   0: {
  //     name: LocaleMaster.isCachedLocaleRU() ? 'Тип IPv4 недоступен' : 'Address type not available'
  //   },
  //   1: {
  //     name: LocaleMaster.isCachedLocaleRU() ? 'Доступен публичный IPv4-адрес' : 'Public IPv4 address available'
  //   },
  //   2: {
  //     name: LocaleMaster.isCachedLocaleRU() ? 'Доступен IPv4-адрес с ограничением по портам' : 'Port-restricted IPv4 address available'
  //   },
  //   3: {
  //     name: LocaleMaster.isCachedLocaleRU() ? 'Доступен частный IPv4-адрес c одинарным NAT' : 'Single NATed private IPv4 address available'
  //   },
  //   4: {
  //     name: LocaleMaster.isCachedLocaleRU() ? 'Доступен частный IPv4-адрес с двойным NAT' : 'Double NATed private IPv4 address available'
  //   },
  //   5: {
  //     name: LocaleMaster.isCachedLocaleRU() ? 'Доступны IPv4-адрес с ограничением по портам и одиночный NAT-адрес IPv4' : 'Port-restricted IPv4 address and single NATed IPv4 address available'
  //   },
  //   6: {
  //     name: LocaleMaster.isCachedLocaleRU() ? 'Доступны IPv4-адрес с ограничением по портам и двойым NAT': 'Port-restricted IPv4 address and double NATed IPv4 address available'
  //   },
  //   7: {
  //     name: LocaleMaster.isCachedLocaleRU() ? 'Доступность типа адреса не известна' : 'Availability of the address type is not known'
  //   }
  // },
  //
  // IPV6_TYPE: {
  //   '-1': {
  //     name: LocaleMaster.isCachedLocaleRU() ? 'Нет' : 'None'
  //   },
  //   0: {
  //     name: LocaleMaster.isCachedLocaleRU() ? 'Тип адреса недоступен' : 'Address type not available'
  //   },
  //   1: {
  //     name: LocaleMaster.isCachedLocaleRU() ? 'Тип адреса доступен' : 'Address type available'
  //   },
  //   2: {
  //     name: LocaleMaster.isCachedLocaleRU() ? 'Доступность типа адреса не известна' : 'Availability of the address type not known'
  //   }
  // },
  //
  // IP_PROTOCOLS: {
  //   1: {
  //     name: 'ICMP'
  //   },
  //   6: {
  //     name: 'TCP'
  //   },
  //   17: {
  //     name: 'UDP'
  //   }
  // },
  //
  // CONN_CAP_STATUS: {
  //   0: {
  //     name: LocaleMaster.isCachedLocaleRU() ? 'Закрытый' : 'Closed'
  //   },
  //   1: {
  //     name: LocaleMaster.isCachedLocaleRU() ? 'Открытый' : 'Open'
  //   },
  //   2: {
  //     name: LocaleMaster.isCachedLocaleRU() ? 'Неизвестно' : 'Unknown'
  //   }
  // },
  //
  // LINK_STATUS: {
  //   0: {
  //     name: 'None'
  //   },
  //   1: {
  //     name: 'Link up'
  //   },
  //   2: {
  //     name: 'Link down'
  //   },
  //   3: {
  //     name: 'Link in test state'
  //   }
  // },
  //
  // OPER_CLASSES: {
  //   81: {
  //     name: '1-13'
  //   },
  //   82: {
  //     name: '14'
  //   },
  //   83: {
  //     name: '1-9, HT40+'
  //   },
  //   84: {
  //     name: '5-13, HT40-'
  //   },
  //   115: {
  //     name: '36-48, HT20'
  //   },
  //   116: {
  //     name: '36-44, HT40+'
  //   },
  //   117: {
  //     name: '40-48, HT40-'
  //   },
  //   118: {
  //     name: '52-64, HT20'
  //   },
  //   119: {
  //     name: '52-60, HT40+'
  //   },
  //   120: {
  //     name: '56-64, HT40-'
  //   },
  //   121: {
  //     name: '100-140, HT20'
  //   },
  //   122: {
  //     name: '100-132, HT40+'
  //   },
  //   123: {
  //     name: '104-136, HT40-'
  //   },
  //   124: {
  //     name: '149-161, HT20'
  //   },
  //   125: {
  //     name: '165-169, HT20'
  //   },
  //   126: {
  //     name: '149-157, HT40+'
  //   },
  //   127: {
  //     name: '153-161, HT40'
  //   },
  //   128: {
  //     name: 'VHT80'
  //   },
  //   129: {
  //     name: 'VHT160'
  //   },
  //   130: {
  //     name: 'VHT80+80'
  //   }
  // },

  // getHotspots(ctx) {
  //   ctx.$store.commit('toggleLoadingHotspots');
  //
  //   Vue.axios.post(`${API_URL || ''}/api/hs20profiles`, { action: 'R' }).then(
  //     (response) => {
  //       if (response.data.status === 'success') {
  //         ctx.$store.commit('setHotspotsList', response.data.data.itemslist);
  //         ctx.$store.commit('toggleLoadingHotspots');
  //       } else if (response.data.status === 'error') {
  //         VueNotifications.error({ message: response.data.description });
  //         ctx.$store.commit('toggleLoadingHotspots');
  //       }
  //     },
  //     (err) => {
  //       // VueNotifications.error({ message: err });
  //
  //       // show errors in the console instead of the alert window
  //       if (
  //         Object.prototype.hasOwnProperty.call(window, 'isShowAxiosErrorInConsole') &&
  //         typeof window.isShowAxiosErrorInConsole === 'function' &&
  //         window.isShowAxiosErrorInConsole(err)
  //       ) {
  //         window.showAxiosErrInConsole(err);
  //       } else {
  //         VueNotifications.error({ message: err });
  //       }
  //
  //       ctx.$store.commit('toggleLoadingHotspots');
  //       ctx.error = err;
  //     }
  //   );
  // },

  getHotspots(ctx, useLocationFilter= false) {
    ctx.$store.commit('toggleLoadingHotspots');

    const isUseLocationFilters = useLocationFilter;

    const params = {
      action: 'R',
      location: ctx.$store.getters.getDefaultLocationForRequests(ctx), with_childs: ctx.$store.getters.getDefaultWithChildsForRequests(ctx)
    }
    if (ctx.filters?.location && isUseLocationFilters) {
      if (ctx.filters.location === 'All locations') {
        params.location = ctx.$store.state.requestBaseUserLocationIfNoSelected ? ctx.userBaseLocation : undefined;
        params.with_childs = true;
      } else {
        params.location = ctx.filters.location;
        params.with_childs = ctx.filters.with_childs;
      }
    }

    Vue.axios.post(`${API_URL || ''}/api/hs20profiles`, params).then(
      (response) => {
        if (response.data.status === 'success') {
          ctx.$store.commit('setHotspotsList', response.data.data.itemslist);
          ctx.$store.commit('toggleLoadingHotspots');
        } else if (response.data.status === 'error') {
          VueNotifications.error({ message: response.data.description });
          ctx.$store.commit('toggleLoadingHotspots');
        }
      },
      (err) => {
        // VueNotifications.error({ message: err });

        // show errors in the console instead of the alert window
        if (
          Object.prototype.hasOwnProperty.call(window, 'isShowAxiosErrorInConsole') &&
          typeof window.isShowAxiosErrorInConsole === 'function' &&
          window.isShowAxiosErrorInConsole(err)
        ) {
          window.showAxiosErrInConsole(err);
        } else {
          VueNotifications.error({ message: err });
        }

        ctx.$store.commit('toggleLoadingHotspots');
        ctx.error = err;
      }
    );
  },

  createHotspot(ctx) {
    Vue.axios.post(`${API_URL || ''}/api/hs20profiles`, { action: 'C', items: { 0: ctx.newHotspotData } }).then(
      (response) => {
        if (response.data.status === 'success') {
          hotspotPollingService.hadleOperationResponse(response.data.data.items);
          this.getHotspots(ctx);
          ctx.$parent.nextStep();
        } else if (response.data.status === 'error') {
          VueNotifications.error({ message: response.data.description });
        }
      },
      (err) => {
        // VueNotifications.error({ message: err });

        // show errors in the console instead of the alert window
        if (
          Object.prototype.hasOwnProperty.call(window, 'isShowAxiosErrorInConsole') &&
          typeof window.isShowAxiosErrorInConsole === 'function' &&
          window.isShowAxiosErrorInConsole(err)
        ) {
          window.showAxiosErrInConsole(err);
        } else {
          VueNotifications.error({ message: err });
        }

        this.error = err;
      }
    );
  },

  updateHotspot(ctx) {
    ctx.$store.commit('toggleMuteHotspotEdit', true);
    const currentHotspotData = JSON.parse(JSON.stringify(ctx.updatedHotspot));

    const updateHotspotQuery = { action: 'U', items: { [currentHotspotData.id]: currentHotspotData } };
    Vue.axios.post(`${API_URL || ''}/api/hs20profiles`, updateHotspotQuery).then(
      (response) => {
        if (response.data.status === 'success') {
          this.getHotspots(ctx);
          hotspotPollingService.hadleOperationResponse(response.data.data.items);
        } else if (response.data.status === 'error') {
          VueNotifications.error({ message: response.data.description });
        }
        ctx.disableEditMode();
        ctx.$store.commit('toggleMuteHotspotEdit', false);
      },
      (err) => {
        // VueNotifications.error({ message: err });

        // show errors in the console instead of the alert window
        if (
          Object.prototype.hasOwnProperty.call(window, 'isShowAxiosErrorInConsole') &&
          typeof window.isShowAxiosErrorInConsole === 'function' &&
          window.isShowAxiosErrorInConsole(err)
        ) {
          window.showAxiosErrInConsole(err);
        } else {
          VueNotifications.error({ message: err });
        }

        ctx.$store.commit('toggleMuteHotspotEdit', false);
        ctx.error = err;
      }
    );
  },

  deleteHotspot(ctx) {
    const deleteHotspotQuery = { action: 'D', items: { [ctx.updatedHotspot.id]: {} } };
    Vue.axios.post(`${API_URL || ''}/api/hs20profiles`, deleteHotspotQuery).then(
      (response) => {
        if (response.data.status === 'success') {
          ctx.$store.commit('setHotspotEditId', null);
          ctx.deletingHotspotModal = false;
          hotspotPollingService.hadleOperationResponse(response.data.data.items);
          this.getHotspots(ctx);
        } else if (response.data.status === 'error') {
          VueNotifications.error({ message: response.data.description });
        }
      },
      (err) => {
        // VueNotifications.error({ message: err });

        // show errors in the console instead of the alert window
        if (
          Object.prototype.hasOwnProperty.call(window, 'isShowAxiosErrorInConsole') &&
          typeof window.isShowAxiosErrorInConsole === 'function' &&
          window.isShowAxiosErrorInConsole(err)
        ) {
          window.showAxiosErrInConsole(err);
        } else {
          VueNotifications.error({ message: err });
        }

        ctx.error = err;
      }
    );
  }
};
