<template>
  <VLANForm
    :formConfig="formConfig"
    :forbiddenVlanNames="forbiddenVlanNames"
    :forbiddenVlanIds="forbiddenVlanIds"
    @submit="handleSubmit"
  >
    <template v-slot:control-buttons>
      <slot name="form-control-buttons"></slot>
    </template>
    </VLANForm>
</template>

<script>
/**
 * компонент настроенной формы CreateVLANForm через generateFormConfig для создания нового VLAN в рамках branch profile.
 * ! Компонент локальный, используется только в VLANTabContent.vue
 * @component
 */

import {
  VLANForm,
  generateFormConfig
} from '../../features';

export default {
  name: 'CreateVLANForm',
  components: {
    VLANForm
  },
  props: {
    forbiddenVlanNames: {
      type: Array,
      default: () => []
    },
    forbiddenVlanIds: {
      type: Array,
      default: () => []
    },
    suggestedVlanNames: {
      type: Array,
      default: () => []
    },
    suggestedAclNames: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
    };
  },
  computed: {
  },
  methods: {
    handleSubmit(formValues) {
      this.$emit('submit', formValues);
    }
  },
  created() {
    const vlanNameSuggestedOptions = this.suggestedVlanNames.map(vlanName => ({ display: vlanName, data: vlanName }));
    const aclValueSuggestedOptions = this.suggestedAclNames.map(aclName => ({ display: aclName, data: aclName }));

    const formConfig = generateFormConfig({
      i18nInstance: this.$i18n,
      initialValues: {
        general: {
          vlanName: {
            selectOptions: vlanNameSuggestedOptions
          },
          aclValue: {
            selectOptions: aclValueSuggestedOptions
          },
          aclIngressVlaue: {
            selectOptions: aclValueSuggestedOptions
          },
          aclEgressValue: {
            selectOptions: aclValueSuggestedOptions
          }
        }
      }
    });

    this.formConfig = formConfig;
  }
};
</script>

<style lang="css" scoped></style>
