<template>
  <header class="w-header-wrapper">
    <div class="logo-wrapper">
      <slot name="logo"></slot>
    </div>
    <div class="content-wrapper">
      <div class="left-content-wrapper">
        <slot name="left-content"></slot>
      </div>
      <div class="right-content-wrapper">
        <slot name="right-content"></slot>
      </div>
    </div>
  </header>
</template>

<script>
export default {
  name: 'HeaderWrapper',
  props: {
  },
  data() {
    return {
    };
  },
  methods: {
  },
  watch: {
  },
  created() {
  },
  mounted() {
  }
};
</script>

<style lang="css" scoped>
.w-header-wrapper {
  max-height: 80px;
  min-height: 60px;
  width: 100%;
  display: flex;
  background-color: var(--header-bg-color);
  color: var(--header-font-color);
  border-bottom: 1px solid var(--header-accent-color);
}

.logo-wrapper {
  flex: 0 0 230px;
}

.content-wrapper {
  display: flex;
  flex: 1 0 auto;
  justify-content: space-between;
  align-items: center;

  padding: 24px;
}
</style>
