import Vue from 'vue';
import VueNotifications from 'vue-notifications';
import controllerPollingService from './controllerPollingService';
import { API_URL } from '@/config';

export default {
  getControllers(ctx, spinner) {
    if (spinner) {
      ctx.$store.commit('toggleLoadingControllers');
    }
    Vue.axios.post(`${API_URL || ''}/api/controllers`, { action: 'R', location: ctx.$store.getters.getDefaultLocationForRequests(ctx), with_childs: ctx.$store.getters.getDefaultWithChildsForRequests(ctx)  }).then(
      (response) => {
        if (response.data.status === 'success') {
          ctx.$store.commit('setControllersList', response.data.data.itemslist);
        } else if (response.data.status === 'error') {
          VueNotifications.error({ message: response.data.description });
        }
        if (spinner) {
          ctx.$store.commit('toggleLoadingControllers');
        }
      },
      (err) => {
        // VueNotifications.error({ message: err });

        // show errors in the console instead of the alert window
        if (
          Object.prototype.hasOwnProperty.call(window, 'isShowAxiosErrorInConsole') &&
          typeof window.isShowAxiosErrorInConsole === 'function' &&
          window.isShowAxiosErrorInConsole(err)
        ) {
          window.showAxiosErrInConsole(err);
        } else {
          VueNotifications.error({ message: err });
        }

        ctx.error = err;
        if (spinner) {
          ctx.$store.commit('toggleLoadingControllers');
        }
      }
    );
  },

  addController(ctx) {
    ctx.$store.commit('toggleMuteControllerEdit', true);
    Vue.axios
      .post(`${API_URL || ''}/api/controllers`, { action: 'C', items: { 0: ctx.newControllerData } })
      .then(
        (response) => {
          if (response.data.status === 'success') {
            ctx.$store.commit('toggleMuteControllerEdit', false);
            ctx.resetNewControllerData();
            controllerPollingService.hadleOperationResponse(response.data.data.items);
          } else {
            ctx.$store.commit('toggleMuteControllerEdit', false);
            VueNotifications.error({ message: response.data.description });
          }
          ctx.wizardModal = false;
          this.getControllers(ctx);
        },
        (err) => {
          ctx.$store.commit('toggleMuteControllerEdit', false);
          VueNotifications.error({ message: err });
          ctx.error = err;
        }
      );
  },

  updateController(ctx) {
    ctx.$store.commit('toggleMuteControllerEdit', true);
    const updateControllerQuery = {
      action: 'U',
      items: { [ctx.updatedController.id]: ctx.updatedController }
    };
    Vue.axios.post(`${API_URL || ''}/api/controllers`, updateControllerQuery).then(
      (response) => {
        if (response.data.status === 'success') {
          controllerPollingService.hadleOperationResponse(response.data.data.items);
        } else {
          VueNotifications.error({ message: response.data.description });
        }
        ctx.$store.commit('toggleMuteControllerEdit', false);
        ctx.disableEditMode();
        this.getControllers(ctx);
      },
      (err) => {
        ctx.$store.commit('toggleMuteControllerEdit', false);
        // VueNotifications.error({ message: err });

        // show errors in the console instead of the alert window
        if (
          Object.prototype.hasOwnProperty.call(window, 'isShowAxiosErrorInConsole') &&
          typeof window.isShowAxiosErrorInConsole === 'function' &&
          window.isShowAxiosErrorInConsole(err)
        ) {
          window.showAxiosErrInConsole(err);
        } else {
          VueNotifications.error({ message: err });
        }

        ctx.error = err;
      }
    );
  },

  deleteController(ctx) {
    ctx.$store.commit('toggleMuteControllerEdit', true);
    const updateControllerQuery = { action: 'D', items: { [ctx.updatedController.id]: {} } };
    Vue.axios.post(`${API_URL || ''}/api/controllers`, updateControllerQuery).then(
      (response) => {
        if (response.data.status === 'success') {
          controllerPollingService.hadleOperationResponse(response.data.data.items);
        } else {
          VueNotifications.error({ message: response.data.description });
        }
        ctx.$store.commit('toggleMuteControllerEdit', false);
        ctx.disableEditMode();
        this.getControllers(ctx);
      },
      (err) => {
        ctx.$store.commit('toggleMuteControllerEdit', false);
        // VueNotifications.error({ message: err });

        // show errors in the console instead of the alert window
        if (
          Object.prototype.hasOwnProperty.call(window, 'isShowAxiosErrorInConsole') &&
          typeof window.isShowAxiosErrorInConsole === 'function' &&
          window.isShowAxiosErrorInConsole(err)
        ) {
          window.showAxiosErrInConsole(err);
        } else {
          VueNotifications.error({ message: err });
        }

        ctx.error = err;
      }
    );
  }
};
