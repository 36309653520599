import Vue from 'vue';
import VueNotifications from 'vue-notifications';
import { API_URL } from '@/config';

// const itemsForTest = {
//   items: {
//     location: 'c47eca8c-a439-46b8-b5cc-b1fd4c683e69',
//     with_childs: true,
//     data: {
//       wlans: [],
//       cpes: [],
//       users: []
//     }
//   }
// }

export default {
  getConfig(ctx) {
    ctx.exportConfigForShow = '';
    const params = {
      action: 'R',
      location: ctx.selectedLocation ? ctx.selectedLocation.id : '',
      with_childs: ctx.with_childs,
    };
    // mocked data
    // console.log(itemsForTest)
    // ctx.exportConfigForShow = JSON.stringify(itemsForTest.items, null, '  ');
    // ctx.downloadConfigAsFile();

    ctx.$store.commit('loadingConfigForSaveInFileBegin');
    Vue.axios.post(`${API_URL || ''}/api/config/backup`, params).then(
      (response) => {
        if (response.data.status === 'success') {
          // console.log('response', response)
          if (Object.keys(response.data.data.items).length > 0) {
            ctx.exportConfigForShow = JSON.stringify(response.data.data.items, null, '  ');
          }
        } else if (response.data.status === 'error') {
          VueNotifications.error({ message: response.data.description });
        }
        ctx.$store.commit('loadingConfigForSaveInFileEnd');
      },
      (err) => {
        ctx.$store.commit('loadingConfigForSaveInFileEnd');
        // VueNotifications.error({ message: err });

        // show errors in the console instead of the alert window
        if (
          Object.prototype.hasOwnProperty.call(window, 'isShowAxiosErrorInConsole') &&
          typeof window.isShowAxiosErrorInConsole === 'function' &&
          window.isShowAxiosErrorInConsole(err)
        ) {
          window.showAxiosErrInConsole(err);
        } else {
          VueNotifications.error({ message: err });
        }
      }
    );
  },
  restoreConfig(ctx, config) {
    const configForSend = config;
    // console.log(configForSend);
    const params = {
      action: 'R',
      items: { ...configForSend }
    };

    ctx.$store.commit('uploadingConfigToAPIBegin');
    Vue.axios.post(`${API_URL || ''}/api/config/restore`, params).then(
      (response) => {
        if (response.data.status === 'success') {
          // console.log('response', response)
          VueNotifications.success({ message: ctx.$t('configExportImport.configSuccessfullyImported') });
          ctx.clearSelectedFile();
        } else if (response.data.status === 'error') {
          VueNotifications.error({ message: response.data.description });
        }
        ctx.$store.commit('uploadingConfigToAPIEnd');
      },
      (err) => {
        ctx.$store.commit('uploadingConfigToAPIEnd');
        // VueNotifications.error({ message: err });

        // show errors in the console instead of the alert window
        if (
          Object.prototype.hasOwnProperty.call(window, 'isShowAxiosErrorInConsole') &&
          typeof window.isShowAxiosErrorInConsole === 'function' &&
          window.isShowAxiosErrorInConsole(err)
        ) {
          window.showAxiosErrInConsole(err);
        } else {
          VueNotifications.error({ message: err });
        }
      }
    );
  }
};
