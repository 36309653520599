<template>
  <div class="w-100" :class="{'closed-folding-category': !show && !openWhenSeraching}">
    <div class="col-12" @click="show = !show">
      <component
        v-if="!secondLevel"
        :is="tag"
        class="d-flex justify-content-between align-items-baseline category"
        :class="{ category_bg: !show }"
        role="button"
      >
        {{ title }}
        <i v-if="show && !openWhenSeraching" class="icon-arrow-down" />
        <i v-else-if="!openWhenSeraching" class="icon-arrow-left" />
      </component>
      <component
        v-else
        :is="tag"
        class="d-flex justify-content-start align-items-baseline category"
        :class="{ category_bg: !show, category_second_level: secondLevel === true }"
        role="button"
      >
        <i v-if="show && !openWhenSeraching" class="icon-arrow-down" />
        <i v-else-if="!openWhenSeraching" class="icon-arrow-right" />
        <span class="pl-1">
          {{ title }}
        </span>
      </component>
    </div>
    <transition-group name="interface" tag="div">
      <template v-if="show || openWhenSeraching">
        <slot />
      </template>
    </transition-group>
  </div>
</template>

<script>
export default {
  name: 'FoldingCategory',
  props: {
    tag: { type: String, default: 'div' },
    title: { type: String, default: '' },
    opened: { type: Boolean, default: false },
    openWhenSeraching: { type: Boolean, default: false },
    secondLevel: { type: Boolean, default: false }
  },
  data() {
    return {
      show: this.opened
    };
  }
};
</script>
<style lang="scss" scoped>
.category {
  background-color: rgba(0, 0, 0, 0.03);
  // border-radius: 2px;
  margin-top: 0.25rem;
  margin-bottom: 0.25rem;
  padding: 1rem 0.5rem;
  transition: all 0.4s ease-in;
}
.category:last-child {
  margin-bottom: 0;
  // border-bottom-right-radius: 0;
  // border-bottom-left-radius: 0;
}
.category_bg {
  background-color: rgba(0, 0, 0, 0.07);
  transition: all 0.4s ease-in;
}
.category_second_level {
  margin-left: 0.3em;
  padding-left: 0.5em;
  /*margin-right: 0.5em;*/
}
</style>
