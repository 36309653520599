<template>
  <div class="row h-100">
    <div class="col-lg-12 h-100 position-relative">
      <loader-spinner
        class="card h-100"
        centered
        v-if="iswlanWizardGoingToLastStepAfterClickSkipButtonInProgess"
      ></loader-spinner>
      <div v-else class="h-100">
        <div class="card-block">
          <span class="text-muted step-caption-in-wlan-wizard">{{ $t('wlans.dhcpOption82') }}</span>
          <form action="" >
            <div class="mt-1">
              <!--                          dhcp option 82-->
              <Switch-component
                v-model="dhcpOption82Enabled"
                :label="$t('wlans.dhcpOption82Enable')"
                @input="dhcpOption82EnableSwitcherHandler"
                id="option82-switcher"
              />
<!--              <info class="ml-h" :content="$t('wlans.dhcpOption82InfoHint')" />-->
              <div>
                <!--                              <div class="ml-1 mb-h h6">{{$t('general.format')}}:</div>-->
                <!--                            dhcp option 82  Rid Type-->
                <Option82RidTypeSelector
                  @selectOption82RidType="selectOption82RidTypeHandler"
                  @selectOption82CidType="selectOption82CidTypeHandler"
                  :updated-wlan="newWlanData"
                  :is-disable="!dhcpOption82Enabled">
                </Option82RidTypeSelector>
              </div>

<!--              <transition name="wsettings">-->
<!--                <div v-if="newWlanData.dhcpOption82Enabled">-->
<!--                  <div class="row ml-1">-->
<!--                    <div class="form-group col-sm-12">-->
<!--                      <label for="dhcp_option82_circuit_id">-->
<!--                        {{ $t('wlans.dhcpOption82CircuitId') }}-->
<!--                        &lt;!&ndash;                                  <small class="ml-q">({{ $t('wlans.dhcpOption82CircuitIdFormat') }})</small>&ndash;&gt;-->
<!--                      </label>-->
<!--                      <input-->
<!--                        type="text"-->
<!--                        id="dhcp_option82_circuit_id"-->
<!--                        name="dhcp_option82_circuit_id"-->
<!--                        :placeholder="$t('wlans.dhcpOption82CircuitIdFormat')"-->
<!--                        class="form-control"-->
<!--                        v-validate="'required'"-->
<!--                        v-model="newWlanData.dhcpOption82CircuitId"-->
<!--                        :data-vv-as="$t('wlans.dhcpOption82CircuitId')"-->
<!--                      />-->
<!--                      <span v-show="errors.has('dhcp_option82_circuit_id')" class="help is-danger">-->
<!--                        {{ errors.first('dhcp_option82_circuit_id') }}-->
<!--                      </span>-->
<!--                      <div>-->
<!--                        <small class=" ">-->
<!--                          {{ $t('wlans.dhcpOption82CircuitIdFormatCaption') }}:&nbsp;{{-->
<!--                            $t('wlans.dhcpOption82CircuitIdFormat')-->
<!--                          }}-->
<!--                        </small>-->
<!--                      </div>-->
<!--                      &lt;!&ndash;                                <div><small class=" ">&ndash;&gt;-->
<!--                      &lt;!&ndash;                                  {{$t('wlans.dhcpOption82CircuitIdExampleCaption')}}:&nbsp;{{$t('wlans.dhcpOption82CircuitIdExample')}}&ndash;&gt;-->
<!--                      &lt;!&ndash;                                </small></div>&ndash;&gt;-->
<!--                    </div>-->
<!--                  </div>-->
<!--                </div>-->
<!--              </transition>-->
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import Info from '../../../components/Universal/info-icon.vue';
import SwitchComponent from '../../../../components/Universal/Switch-component.vue';
import Info from '../../../../components/Universal/info-icon.vue';
import Option82RidTypeSelector from '../../../../components/WLANs/Option82RidTypeSelector.vue';

const OPTION_82_STATES = {ENABLE: true, DISABLE: false};

export default {
  name: 'DHCPOption82Tab',
  components: { SwitchComponent, Info, Option82RidTypeSelector },
  data() {
    return {
      // enableSaveChanges: true,
      // dhcpOption82CircuitId: '',
      dhcpOption82Enabled: false,
      newWlanData: {
        security: {
          data: {
            suites: [],
            psk: ''
          }
        }
      }
      // showWlanPass: false,
      // updatedWlanRadiusAuthentication: [],
      // updatedWlanRadiusAccounting: []
    };
  },
  computed: {
    iswlanWizardGoingToLastStepAfterClickSkipButtonInProgess() {
      return this.$store.state.wlanWizardGoingToLastStepAfterClickSkipButtonInProgess;
    },
    option82States() {
      return OPTION_82_STATES;
    },
  },
  methods: {
    selectOption82RidTypeHandler(ridType){
      // console.log(ridType)
      // this.newWlanData.option82rid_type = ridType;
      this.$set(this.newWlanData, 'option82rid_type', ridType);
    },
    selectOption82CidTypeHandler(cidType) {
      this.$set(this.newWlanData, 'option82cid_type', cidType);
    },
    dhcpOption82EnableSwitcherHandler(switcherState) {
      // console.log('here!')
      // console.log(switcherState)
      if (switcherState === true) {
        // this.newWlanData.option82state = this.option82States.ENABLE;
        this.$set(this.newWlanData, 'option82state', this.option82States.ENABLE);
      } else {
        // this.newWlanData.option82state = this.option82States.DISABLE;
        this.$set(this.newWlanData, 'option82state', this.option82States.DISABLE);
      }
    },
    lastStepButtonClickHandler(event) {
      this.setNewWlanData(event, true);
    },
    // enableSave() {
    //   this.enableSaveChanges = true;
    // },
    // setNewWlanData(event, toLastStepClicked = false) {
    //   this.$store.commit('setNewWlanData', this.newWlanData);
    //   this.$validator.validateAll().then(
    //     (result) => {
    //       if (result) {
    //         if (this.newWlanData.speed_download.value === '') {
    //           this.newWlanData.speed_download.value = 0;
    //         }
    //         if (this.newWlanData.speed_upload.value === '') {
    //           this.newWlanData.speed_upload.value = 0;
    //         }
    //         // this.$parent.nextStep();
    //         if (toLastStepClicked === false) {
    //           this.$parent.nextStep();
    //         } else {
    //           // this.$parent.nextStep(true);
    //           this.$parent.lastStep();
    //         }
    //       }
    //     },
    //     () => {
    //       console.warn('Validation failed');
    //     }
    //   );
    // }
    validateAndSetNewWlanData(currentTab, nextTab) {
      this.setNewWlanData(currentTab, nextTab);
    },

    setNewWlanData(currentTab, nextTab) {
      this.$store.commit('setNewWlanData', this.newWlanData);
      this.$validator.validateAll().then(
        (result) => {
          if (result) {
            // if (this.newWlanData.speed_download.value === '') {
            //   this.newWlanData.speed_download.value = 0;
            // }
            // if (this.newWlanData.speed_upload.value === '') {
            //   this.newWlanData.speed_upload.value = 0;
            // }
            // this.$parent.nextStep();
            this.$emit('dataIsValid', { nextTab: nextTab, currentTab: currentTab });
          } else {
            this.$emit('dataNoValid', { nextTab: nextTab, currentTab: currentTab });
          }
        },
        () => {
          this.$emit('dataNoValid', { nextTab: nextTab, currentTab: currentTab });
          console.warn('Validation failed');
        }
      );
    }
  },
  created() {
    // radiusService.getRadius(this);
    // this.radiusList = this.$store.state.radiusList;
    this.newWlanData = JSON.parse(JSON.stringify(this.$store.state.newWlanData));

    // console.log(JSON.parse(JSON.stringify(this.newWlanData)))
    if (this.newWlanData.option82state === this.option82States.DISABLE) {
      this.dhcpOption82Enabled = false;
    } else if (this.newWlanData.option82state === this.option82States.ENABLE) {
      this.dhcpOption82Enabled = true;
    } else {
      this.dhcpOption82Enabled = false;
    }

    // проверяем не пришли ли мы сюда после нажатия кнопки Пропустить
    if (
      Object.prototype.hasOwnProperty.call(this.$route, 'params') &&
      Object.prototype.hasOwnProperty.call(this.$route.params, 'lastStepClicked') &&
      this.$route.params.lastStepClicked === true
    ) {
      this.lastStepButtonClickHandler();
    }
  }
};
</script>
<style lang="scss">
.input.is-danger .multiselect__tags {
  border-color: #ff3860;
}

.input-group {
  z-index: 1;
}
</style>
<style scoped>
.buttons-block-warpper {
  position: absolute;
  bottom: 1.25rem;
  width: 100%;
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}
.skip-button-in-wlan-wizard {
  font-size: 0.75rem;
}
</style>
