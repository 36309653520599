/**
 * Модуль Vuex для отслеживания активности пользователя и авторазлогирования по таймауту в случае бездействия.
 *
 * работает на последовательных вызовах саб-модулей throttlerStage -> mainStage -> expirationStage.
 * Последовательность вызовов жестко закреплена.
 *
 * @module autologout
 * @namespace
 *
 * Здесь размещаются стейты, геттеры, мутации и экшны, специфические для логики авторазлогирования.
 * Для использования в компонентах и других модулях Vuex.
 *
 * doc о модулях vuex:
 * https://v3.vuex.vuejs.org/ru/guide/modules.html
 */

import {
  throttlerStage,
  mainStage,
  expirationStage
} from './modules';

import {
  LOGOUT_AFTER_USER_INACTIVITY_MODE
} from '@/config';

const autologout = {
  /**
 * @typedef {Object} State - Состояние модуля Vuex.
 * @property {boolean} isEnabled - Включен ли режим авторазлогирования.
 * @property {boolean} isUserActive - Показывает, активен ли пользователь.
 * @property {boolean} isUserSessionEnded - Показывает, завершилась ли сессия пользователя.
 * @property {Object} callbacks - Колбэки событий.
 * @property {Function|null} callbacks.defaultUserEventHandler - Колбэк для обработки действий пользователя по умолчанию.
 * @property {Function|null} callbacks.visibilityChangeEventHandler - Колбэк для обработки изменения видимости страницы.
 * @property {Function|null} callbacks.onEndUserSession - Колбэк, вызываемый при завершении сессии пользователя.
 * @property {HTMLElement|null} rootEl - HTML-элемент, на который вешаются слушатели событий.
 */
  state: () => ({
    isEnabled: LOGOUT_AFTER_USER_INACTIVITY_MODE.isEnabled,

    isUserActive: undefined,
    isUserSessionEnded: false,

    callbacks: {
      defaultUserEventHandler: null,
      visibilityChangeEventHandler: null,
      onEndUserSession: null
    },

    rootEl: null
  }),
  getters: {
    /**
     * Проверяет активен ли пользователь.
     * @returns {boolean}
     */
    isUserActive: (state) => state.isUserActive,

    /**
     * Проверяет, завершилась ли все 3 стадии (throttlerStage, mainStage, expirationStage) и как следствие сессия пользователя.
     * @returns {boolean}
     */
    isUserSessionEnded: (state) => state.isUserSessionEnded
  },
  mutations: {
    /**
     * Устанавливает состояние активности пользователя.
     *
     * @param {State} state - Состояние модуля Vuex.
     * @param {boolean} newState - Новое состояние активности пользователя.
     */
    setIsUserActive(state, newState) {
      state.isUserActive = newState;
    },

    /**
     * Устанавливает флаг - завершилась ли все 3 стадии (throttlerStage, mainStage, expirationStage) и как следствие сессия пользователя.
     *
     * @param {State} state - Состояние модуля Vuex.
     * @param {boolean} newValue - Новое значение флага.
     */
    setIsUserSessionEnded(state, newValue) {
      state.isUserSessionEnded = newValue;
    },

    /**
     * Устанавливает колбэки.
     *
     * @param {State} state - Состояние модуля Vuex.
     * @param {Object} payload - Параметры для установки колбэка.
     * @param {string} payload.name - Имя колбэка.
     * @param {Function|null} payload.callback - Функция-колбэк.
     */
    setCallback(state, { name, callback }) {
      state.callbacks[name] = callback;
    },

    /**
     * Устанавливает HTML-элемент, на который будут вешаться эвент лисенеры.
     *
     * @param {State} state - Состояние модуля Vuex.
     */
    setRootEl(state) {
      const rootEl = document.getElementById('app');

      if (!rootEl) {
        throw new Error('there is no html element with id "app" in html document');
      }

      state.rootEl = rootEl;
    }
  },
  actions: {
    /**
     * Создает и устанавливает колбэки в стейт.
     * !!! Напрямую в компонентах не используется
     *
     * @param {Object} context - Контекст хранилища Vuex.
     * @param {function} context.commit - Функция для вызова мутаций.
     * @param {function} context.getters - Функция для получения геттеров.
     * @param {function} context.dispatch - Функция для вызова других экшнов.
    */
    _createOnUserActivityCallbacks({ state, commit, dispatch }) {
      return new Promise((resolve) => {
        if (!state.callbacks.defaultUserEventHandler) {
          const defaultCallback = () => dispatch('_startThrottlerStage');

          commit('setCallback', { name: 'defaultUserEventHandler', callback: defaultCallback });
        }

        if (!state.callbacks.visibilityChangeEventHandler) {
          const visibilityChangeCallback = () => {
            if (document.visibilityState !== 'visible') {
              dispatch('_startThrottlerStage');
            }
          };

          commit('setCallback', { name: 'visibilityChangeEventHandler', callback: visibilityChangeCallback });
        }

        return resolve();
      });
    },

    /**
     * Инициирует модуль: ставит слушатели на события и запускает стадию 1 - throttlerStage.
     * !!! Напрямую в компонентах не используется
     *
     * @param {Object} context - Контекст хранилища Vuex.
     * @param {function} context.commit - Функция для вызова мутаций.
     * @param {function} context.getters - Функция для получения геттеров.
     * @param {function} context.dispatch - Функция для вызова других экшнов.
    */
    async _startUserActivityTracking({ state, commit, dispatch }) {
      commit('setRootEl');

      // init throttleStage
      dispatch('_startThrottlerStage');

      await dispatch('_createOnUserActivityCallbacks');

      const defaultCallback = state.callbacks.defaultUserEventHandler;
      const visibilityChangeCallback = state.callbacks.visibilityChangeEventHandler;

      const { rootEl } = state;

      rootEl.addEventListener('mousemove', defaultCallback);
      rootEl.addEventListener('wheel', defaultCallback);
      rootEl.addEventListener('scroll', defaultCallback);
      rootEl.addEventListener('keydown', defaultCallback);
      rootEl.addEventListener('resize', defaultCallback);
      document.addEventListener('visibilitychange', visibilityChangeCallback);
    },

    /**
     * Убирает слушателей с событий.
     * !!! Напрямую в компонентах не используется
     *
     * @param {Object} context - Контекст хранилища Vuex.
     * @param {function} context.commit - Функция для вызова мутаций.
     * @param {function} context.getters - Функция для получения геттеров.
     * @param {function} context.dispatch - Функция для вызова других экшнов.
    */
    _stopUserActivityTracking({ state, commit }) {
      const defaultCallback = state.callbacks.defaultUserEventHandler;
      const visibilityChangeCallback = state.callbacks.visibilityChangeEventHandler;

      const { rootEl } = state;

      rootEl.removeEventListener('mousemove', defaultCallback);
      rootEl.removeEventListener('wheel', defaultCallback);
      rootEl.removeEventListener('scroll', defaultCallback);
      rootEl.removeEventListener('keydown', defaultCallback);
      rootEl.removeEventListener('resize', defaultCallback);
      document.removeEventListener('visibilitychange', visibilityChangeCallback);

      commit('setCallback', { name: 'defaultUserEventHandler', callback: null });
      commit('setCallback', { name: 'visibilityChangeEventHandler', callback: null });
    },
    /**
     * Подписка на модуль autologout из компонента/миксина.
     * Инициирует работу модуля, если он доступен в сборке.
     * tip: Вызывать на mount-событиях компонента.
     *
     * @param {Object} context - Контекст хранилища Vuex.
     * @param {function} context.commit - Функция для вызова мутаций.
     * @param {function} context.getters - Функция для получения геттеров.
     * @param {function} context.dispatch - Функция для вызова других экшнов.
     * @param {object} payload - Объект с параметрами.
     * @param {Function} payload.onEndUserSession -  Коллбэк, который будет при завершении сессии пользователя.
     *
    */
    subscribeOnUserActivityTracking({ state, commit, dispatch }, { onEndUserSession }) {
      const { isEnabled } = state;
      if (!isEnabled) {
        return;
      }

      dispatch('_startUserActivityTracking');
      commit('setCallback', { name: 'onEndUserSession', callback: onEndUserSession });
    },

    /**
     * Отписка от autologout из компонента/миксина.
     * Останавливает работу модуля, если он доступен в сборке.
     * tip: Вызывать на destroy-событиях компонента.
     *
     * @param {Object} context - Контекст хранилища Vuex.
     * @param {function} context.commit - Функция для вызова мутаций.
     * @param {function} context.getters - Функция для получения геттеров.
     * @param {function} context.dispatch - Функция для вызова других экшнов.
    */
    unsubscribeFromUserActivityTracking({ state, commit, dispatch }) {
      const { isEnabled } = state;
      if (!isEnabled) {
        return;
      }

      // reset all to default
      commit('setIsUserActive', false);

      dispatch('_resetThrottlerStage');
      dispatch('_resetMainStage');
      dispatch('_resetExpirationStage');

      dispatch('_stopUserActivityTracking');

      commit('setIsUserSessionEnded', true);
    },

    /**
     * Завершает сессию юзера, останавливает работу autologout и выполняет колбэк onEndUserSession.
     * tip: Вызывать внутри компонента, когда нужно по пользовательскому событию принудительно закончить сессию пользователя.
     *
     * @param {Object} context - Контекст хранилища Vuex.
     * @param {function} context.commit - Функция для вызова мутаций.
     * @param {function} context.getters - Функция для получения геттеров.
     * @param {function} context.dispatch - Функция для вызова других экшнов.
    */
    endUserSession({ state, commit, dispatch }) {
      const { isEnabled, callbacks } = state;
      if (!isEnabled) {
        return;
      }

      dispatch('unsubscribeFromUserActivityTracking');
      callbacks.onEndUserSession();
      commit('setCallback', { name: 'onEndUserSession', callback: null });
    },

    /**
     * Перезапускает отслеживание активности пользователя.
     * tip: вызывать, когда нужно перезапустить заново все стадии принудительно (например, из компонента по клику по кнопке).
     *
     * @param {Object} context - Контекст хранилища Vuex.
     * @param {function} context.commit - Функция для вызова мутаций.
     * @param {function} context.getters - Функция для получения геттеров.
     * @param {function} context.dispatch - Функция для вызова других экшнов.
    */
    restartUserActivityTracking({ state, dispatch }) {
      const { isEnabled } = state;
      if (!isEnabled) {
        return;
      }

      dispatch('_startThrottlerStage');
    }
  },
  modules: {
    throttlerStage,
    mainStage,
    expirationStage
  },
  namespaced: true
};

export default autologout;
