/* eslint-disable no-param-reassign */
import { WMM_CATEGORIES_AND_CONFIGURATION } from '../../../../components/WLANs/WMMconfig';

/**
 * Возвращает объект с категориями для использования в modifyEmptyValuesInWMMToDefaultValuesBeforeSendToAPI
 *
 * @return {Object}
 */
function getCategoriesList() {
  const result = {};
  for (const key in WMM_CATEGORIES_AND_CONFIGURATION) {
    result[`${WMM_CATEGORIES_AND_CONFIGURATION[key].nameForDispaly}`] = key;
  }
  return result;
}

/**
 * Изменяет в переданном объекте wlan пустые значения настроек в WMM на дефолтные значения,
 * Нужно для того чтобы  не передавать на бэк пустые значения
 *
 * @param wlanObject
 */
function modifyEmptyValuesInWMMToDefaultValuesBeforeSendToAPI(wlanObject) {
  const CATEGORIES = getCategoriesList();
  for (const category of Object.values(CATEGORIES)) {
    if (!wlanObject.wmm.categories[category]) {
      // Если нужной категории нет совсем то создаем ее и наполняем дефолтными значениями всех полей
      wlanObject.wmm.categories[category] = {};
      for (const fieldConfigKey of Object.keys(WMM_CATEGORIES_AND_CONFIGURATION[category]['configuration'])) {
        wlanObject.wmm.categories[category][fieldConfigKey] =
          WMM_CATEGORIES_AND_CONFIGURATION[category]['configuration'][fieldConfigKey]['defaultValue'];
      }
    } else {
      // А если нужная категория есть, то смотрим нет ли в ней пустых полей, если такие есть
      // наполняем их дефолтными значениями
      for (const fieldConfigKey of Object.keys(WMM_CATEGORIES_AND_CONFIGURATION[category]['configuration'])) {
        if (!wlanObject.wmm.categories[category][fieldConfigKey]) {
          wlanObject.wmm.categories[category][fieldConfigKey] =
            WMM_CATEGORIES_AND_CONFIGURATION[category]['configuration'][fieldConfigKey]['defaultValue'];
        }
      }
    }
  }
}

/**
 * Конвертирует в переданном объекте wlan значения полей типа integer во float,
 * Полю нужна конертация если type: 'float' для поля в wmmCategoriesAndConfiguration.js
 * Нужно для того чтобы передавать для этого поля дробные числа с нужным количеством знаков после запятой,
 * если все таки сюда как то прорвались какие-то другие через валидацию
 *
 * @param wlanObject
 */
function modifyIntegerValuesInWMMToFloatValuesForNecessaryFieldsBeforeSendToAPI(wlanObject) {
  const CATEGORIES = getCategoriesList();
  for (const category of Object.values(CATEGORIES)) {
    for (const fieldConfigKey of Object.keys(WMM_CATEGORIES_AND_CONFIGURATION[category]['configuration'])) {
      const fieldConfigObject = WMM_CATEGORIES_AND_CONFIGURATION[category]['configuration'][fieldConfigKey];
      if (fieldConfigObject.type === 'float') {
        const fieldValueForConvert = wlanObject.wmm.categories[category][fieldConfigKey];
        wlanObject.wmm.categories[category][fieldConfigKey] = parseFloat(fieldValueForConvert.toFixed(fieldConfigObject.decimal));
      }
    }
  }
}

const beforeSendToAPIWMMlModifiers = [
  modifyEmptyValuesInWMMToDefaultValuesBeforeSendToAPI,
  modifyIntegerValuesInWMMToFloatValuesForNecessaryFieldsBeforeSendToAPI
];

export { beforeSendToAPIWMMlModifiers };
