<template>
  <div>
    <div v-if="isCPELogsFromCpeCommandRequestExecuting" class="spinner-wrapper">
      <loader-spinner centered></loader-spinner>
    </div>
    <div v-else>
      <div class="form-group mt-2 mt-2 d-flex flex-column align-items-start justify-content-start">
        <div v-if="cpeName">
          <h5>
            {{ $t('aps.logFromCpe') }} &nbsp;
            <b class="font-weight-bold">{{ cpeName }}</b>
          </h5>
        </div>
      </div>
      <transition name="fade">
        <div v-if="requestError" class="mt-h">
          <Alert v-if="requestError" closable class="alert-danger" @alert-closed="closeRequestErrorArea">
            <template #text>
              {{ requestError }}
            </template>
          </Alert>
        </div>
      </transition>
      <div
        v-if="getCPELogFromCpeCommandResult"
        class="form-group mt-2 mb-0 d-flex flex-column align-items-left justify-content-start"
      >
        <div class="w-100">
          <textarea
            id="textarea-for-text-log-from-cpe"
            readonly
            class="w-100 cpe-log-textarea"
            v-model="getCPELogFromCpeCommandResult"
          ></textarea>
        </div>
        <transition name="fade">
          <div v-if="downloadFileError" class="mt-h">
            <Alert v-if="downloadFileError" closable class="alert-danger" @alert-closed="closeDownloadFileErrorArea">
              <template #text>
                {{ downloadFileError }}
              </template>
            </Alert>
          </div>
        </transition>
        <div class="mt-h">
          <WButton info md
            @click="downloadLogAsFileButtonHandler"
            :disabled="downloadButtonDisabled"
          >
            <i class="fa fa-download mr-h"></i>
            {{ $t('configExportImport.downloadButtonCaption') }}
          </WButton>
        </div>
      </div>
      <div v-else class="form-group mt-2 mb-0 d-flex flex-column align-items-center justify-content-center">
        <div>{{ $t('general.noDataToDisplay') }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import VueNotifications from 'vue-notifications';
import cpeService from '../../services/cpeService';
import Alert from '../Universal/alert/alert.vue';

export default {
  name: 'GetLogFromCPE',
  components: { Alert },
  data() {
    return {
      getCPELogFromCpeCommandResult: '',
      downloadFileError: '',
      requestError: ''
    };
  },
  methods: {
    closeDownloadFileErrorArea() {
      this.downloadFileError = '';
    },
    closeRequestErrorArea() {
      this.requestError = '';
    },
    saveTofileErrorShowMsg() {
      this.downloadFileError = this.$t('aps.cpeConfigShowSaveToFileError');
      // VueNotifications.error({ message: `${this.$t('aps.cpeConfigShowSaveToFileError')}` });
    },
    downloadLogAsFileButtonHandler() {
      this.downloadLogAsFile();
    },
    downloadLogAsFile() {
      try {
        this.makeFileAndDownloadIt(this.getCPELogFromCpeCommandResult, `${this.downloadFileName}`, 'text/plain');
      } catch (e) {
        this.saveTofileErrorShowMsg();
      }
    },

    makeFileAndDownloadIt(content, filename, contentType) {
      const a = document.createElement('a');
      const file = new Blob([content], { type: contentType });
      a.href = URL.createObjectURL(file);
      a.download = filename;
      a.click();
      URL.revokeObjectURL(a.href);
    },

    async getCPELogFromCpeCommand() {
      await cpeService.getCPELogFromCpeCommand(this, [this.cpeId]);
      if (this.getCPELogFromCpeCommandResult) {
        // если есть результат - крутим textarea вниз чтообы видеть последние строчки лога
        const textarea = document.getElementById('textarea-for-text-log-from-cpe');
        if (textarea) {
          textarea.scrollTop = textarea.scrollHeight;
        }
      }
    }
  },
  async created() {
    this.getCPELogFromCpeCommand();
  },
  computed: {
    downloadButtonDisabled() {
      if (this.getCPELogFromCpeCommandResult === '') {
        return true;
      }
      return false;
    },
    isCPELogsFromCpeCommandRequestExecuting() {
      return this.$store.state.getCPELogsFromCpeCommandRequestExecuting;
    },
    downloadFileName() {
      const cpeName = this.cpeName ? this.cpeName : this.cpeId;
      try {
        return `${cpeName}_log$${moment(new Date()).format('DD.MM.YYYY_H:mm:ss')}.txt`;
      } catch (e) {
        return `${cpeName}_log.txt`;
      }
    }
  },
  watch: {
    // isCPELogsFromCpeCommandRequestExecuting:{
    //   handler(val, oldVal) {
    //     console.log('val', val)
    //     console.log('oldVal', oldVal)
    //   }
    // }
  },
  props: {
    cpeId: {
      type: String,
      required: true
    },
    cpeName: {
      type: String,
      required: false,
      default: ''
    }
  }
};
</script>

<style scoped>
.cpe-log-textarea {
  height: 400px !important;
  white-space: pre;
  overflow-wrap: normal;
  overflow-x: scroll;
}
.spinner-wrapper {
  min-height: 400px;
  position: relative;
}
</style>
