<template>
  <APProfileForm
    :formConfig="formConfig"
    :forbiddenProfileNames="forbiddenProfileNames"
    @submit="handleSubmit"
  >
    <template v-slot:control-buttons>
      <slot name="form-control-buttons"></slot>
    </template>
  </APProfileForm>
</template>

<script>
/* eslint-disable camelcase */

/**
 * компонент настроенной формы APProfileForm через generateFormConfig для редактирования существующего профия ap join.
 * ! Компонент локальный, используется только в ChangeAPProfileModal.vue
 * @component
 */

import {
  APProfileForm,
  generateFormConfig
} from '../../features';

export default {
  name: 'ChangeAPProfileForm',
  components: {
    APProfileForm
  },
  props: {
    forbiddenProfileNames: {
      type: Array,
      default: () => []
    },
    apProfileForChanging: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
    };
  },
  computed: {
  },
  methods: {
    handleSubmit(formValues) {
      this.$emit('submit', formValues);
    }
  },
  created() {
    // задаем дефолтные значения для каждого поля формы
    const {
      name,
      description,
      led_enabled,
      mss_wan_enabled,
      mss_wan_size,
      lldp_enabled,
      ntp_server,
      timezone,
      offset_hh,
      offset_mm,
      primary_controller,
      secondary_controller,
      tertiary_controller,
      ssh_enabled,
      ssh_port,
      username,
      stats_enabled,
      stats_report_period,
      log_ip,
      log_facility,
      log_level,
      heartbeat_timeout,
      count,
      interval,
      trust_dscp_enabled
    } = this.apProfileForChanging;

    const formConfig = generateFormConfig({
      i18nInstance: this.$i18n,
      initialValues: {
        general: {
          profileName: {
            isDisabled: true,
            defaultValue: name
          },
          profileDescription: {
            defaultValue: description
          },
          ledState: {
            defaultValue: led_enabled
          },
          mssState: {
            defaultValue: mss_wan_enabled
          },
          mssSizeOfWanInterface: {
            defaultValue: mss_wan_size
          },
          lldpCdpSupport: {
            defaultValue: lldp_enabled
          },
          ipAdressOfNTPServer: {
            defaultValue: ntp_server
          },
          timezone: {
            defaultValue: timezone
          },
          offsetHH: {
            defaultValue: offset_hh
          },
          offsetMM: {
            defaultValue: offset_mm
          },
          primaryController: {
            defaultValue: primary_controller
          },
          secondaryController: {
            defaultValue: secondary_controller
          },
          tertiaryController: {
            defaultValue: tertiary_controller
          }
        },
        management: {
          sshSupport: {
            defaultValue: ssh_enabled
          },
          sshPort: {
            defaultValue: ssh_port
          },
          username: {
            defaultValue: username
          },
          // необязательный для заполнения в форме редактирования, если ранее был подключен ssh_enabled
          password: {
            isRequired: !ssh_enabled
          },
          // необязательный для заполнения в форме редактирования, если ранее был подключен ssh_enabled
          secretPassword: {
            isRequired: !ssh_enabled
          }
        },
        statistics: {
          sendApStatistic: {
            defaultValue: stats_enabled
          },
          apStatiscticReportPeriod: {
            defaultValue: stats_report_period
          }
        },
        syslog: {
          facilityValue: {
            defaultValue: log_facility
          },
          ipAdressOfRemoteServerForSyslogMessages: {
            defaultValue: log_ip
          },
          logLevel: {
            defaultValue: log_level
          }
        },
        timers: {
          heartbeatTimeout: {
            defaultValue: heartbeat_timeout
          },
          configurationRetransmissions: {
            defaultValue: count
          },
          acceptanceInterval: {
            defaultValue: interval
          }
        },
        qualityOfService: {
          trustDscpUpstream: {
            defaultValue: trust_dscp_enabled
          }
        }
      }
    });

    this.formConfig = formConfig;
  }
};
</script>

<style lang="css" scoped>
</style>
