<template>
  <div>
    <AuthenticationFormTemplate
      v-show="!passwordSetSuccessfully"
      :submit="handleSubmit"
      :subheadingText="$t('login.resetPasswordNewPasswordCaption')"
      :successText="successText"
      :errorText="errorText"
    >
      <template v-slot:fields>
        <WInput md
          ref="newPassword"
          id="newPassword"
          name="newPassword"
          customClass="auth-input"
          :type="isPasswordHidden ? 'password' : 'text'"
          v-model.trim="credentials.newPassword"
          :placeholder="$t('login.resetPasswordNewPassword')"
          data-vv-validate-on="input"
          v-validate="mixRulesForUserPasswordCheck('required')"
          :disabled="isLoading"
          :data-vv-as="$t('login.password')"
          :errorText="newPasswordInputError"
        >
          <template v-slot:icon-left>
            <button type="button"
              class="password-icon-button"
              @click="togglePasswordVisibility"
            >
              <i class="fa" :class="isPasswordHidden ? 'fa-eye-slash' : 'fa-eye'"></i>
            </button>
          </template>
          <template v-slot:icon-right>
            <button type="button"
              v-show="credentials.newPassword"
              class="password-icon-button"
              @click="clearNewPasswordValue"
            >
              <i class="fa fa-remove"></i>
            </button>
          </template>
        </WInput>

        <WInput md
          id="newPasswordConfirm"
          name="newPasswordConfirm"
          customClass="auth-input"
          :type="isPasswordHidden ? 'password' : 'text'"
          v-model.trim="credentials.newPasswordConfirm"
          :placeholder="$t('login.resetPasswordNewPasswordConfirm')"
          data-vv-validate-on="input"
          v-validate="'required|confirmed:newPassword'"
          :disabled="isLoading"
          :data-vv-as="$t('login.newPasswordConfirmationField')"
          :errorText="newPasswordConfirmInputError"
        >
          <template v-slot:icon-left>
            <button type="button"
              class="password-icon-button"
              @click="togglePasswordVisibility"
            >
              <i class="fa" :class="isPasswordHidden ? 'fa-eye-slash' : 'fa-eye'"></i>
            </button>
          </template>
          <template v-slot:icon-right>
            <button type="button"
              v-show="credentials.newPasswordConfirm"
              class="password-icon-button"
              @click="clearConfirmPasswordValue"
            >
              <i class="fa fa-remove"></i>
            </button>
          </template>
        </WInput>
      </template>

      <template v-slot:subfields>
          <WButton link sm
            @click="goToLoginPage"
            :disabled="isLoading"
          >
            {{ $t('login.goToLoginButton') }}
          </WButton>
        </template>

      <template v-slot:buttons>
        <WButton primary fullWidth
          type="submit"
          :disabled="isLoading || errors.any()"
        >
          {{ isLoading ? $t('general.state.loading') : $t('login.resetPasswordNewPasswordSubmitButton') }}
        </WButton>
      </template>
    </AuthenticationFormTemplate>

    <div v-show="passwordSetSuccessfully">
      <h5 class="final-status-text">{{ $t('login.passwordSuccessfullySet') }}</h5>
      <WButton primary fullWidth @click="goToLoginPage">
        {{ $t('login.goToLoginButton') }}
      </WButton>
    </div>
  </div>

</template>

<script>
import { mapFields } from 'vee-validate';

import authApi from '@/services/auth';
import AuthenticationFormTemplate from './AuthenticationFormTemplate.vue';

import { userPasswordValidationRulesMixin } from '@/mixins';

export default {
  name: 'ResetPasswordForm',
  mixins: [userPasswordValidationRulesMixin],
  components: {
    AuthenticationFormTemplate
  },
  props: {
  },
  data() {
    return {
      credentials: {
        newPassword: '',
        newPasswordConfirm: '',
        token: '',
        username: ''
      },
      passwordSetSuccessfully: false,
      isLoading: false,
      errorText: '',
      successText: '',
      isPasswordHidden: true
    };
  },
  computed: {
    ...mapFields({
      newPasswordInputField: 'newPassword',
      newPasswordConfirmInputField: 'newPasswordConfirm'
    }),
    isNewPasswordInputTouched() {
      return this.newPasswordInputField.touched;
    },
    isNewPasswordConfirmInputTouched() {
      return this.newPasswordConfirmInputField.touched;
    },
    newPasswordInputError() {
      const errorText = this.errors.first('newPassword');

      return this.isNewPasswordInputTouched && errorText ? errorText : '';
    },
    newPasswordConfirmInputError() {
      const errorText = this.$t('login.resetPasswordNewPasswordConfirmationError');

      return this.isNewPasswordConfirmInputTouched && this.errors.first('newPasswordConfirm') ? errorText : '';
    }
  },
  methods: {
    togglePasswordVisibility() {
      this.isPasswordHidden = !this.isPasswordHidden;
    },
    clearNewPasswordValue() {
      this.credentials.newPassword = '';
    },
    clearConfirmPasswordValue() {
      this.credentials.newPasswordConfirm = '';
    },
    goToLoginPage() {
      this.$router.push({ name: 'Login' });
    },
    async handleSubmit() {
      const isFormValid = await this.$validator.validateAll();

      if (!isFormValid) {
        return;
      }

      authApi.setNewPassword({
        username: this.credentials.username,
        token: this.credentials.token,
        newPassword: this.credentials.newPassword
      }, {
        onLoading: () => {
          this.isLoading = true;
          this.successText = '';
          this.errorText = '';
        },
        onSuccess: () => {
          this.isLoading = false;
          this.credentials.newPassword = '';
          this.credentials.newPasswordConfirm = '';
          this.passwordSetSuccessfully = true;

          this.successText = this.$t('login.resetPasswordNewPasswordSuccessMessage');
        },
        onError: (error) => {
          this.errorText = error;
          if (error === 'token expired') {
            this.errorText = this.$t('login.resetPasswordNewPasswordTokenExpiredMessage');
          }
          this.isLoading = false;
        }
      });
    }
  },
  watch: {
  },
  mounted() {
    // данные из роута
    this.credentials.token = this.$route.query.token;
    this.credentials.username = this.$route.query.username;
  }
};
</script>

<style lang="css" scoped>

.final-status-text {
  margin: 0;
  margin-bottom: 24px;
  text-align: center;
  color: var(--brand-success);
}
</style>
