<template>
  <WDropdown
    position="bottom-left"
    @close="handleCloseDropdown"
    @open="handleOpenDropdown"
    :isOpen="isDropdownOpen"
  >
  <template v-slot:button-inner-content>
    <div class="button-content-wrapper avatar-wrapper">
      <img :src="currentUserData.photo" class="avatar" alt="avatar" />
    </div>
  </template>
  <template v-slot:inner-content>
    <WCard :class="['dropdown-content-wrapper', colorClass]">
      <ul class="content-list">
        <li>
          <div class="user-profile">
            <div class="avatar-wrapper">
              <img :src="currentUserData.photo" class="avatar" alt="avatar" />
            </div>
            <div>
              <p>{{ currentUserData.username }}</p>
              <p class="info">
                {{ $t(`general.role.${currentUserData.role}`) }}</p>
            </div>
          </div>
        </li>
        <li>
          <router-link class="content"
            :class="[colorClass]"
            routerLink
            :to="'/settings'"
            @click.native="handleCloseDropdown"
          >
            <i class="fa fa-wrench mr"></i>
            {{ $t('header.settings') }}
          </router-link>
        </li>
        <li>
          <button class="content"
            :class="[colorClass]"
            @click="logout"
          >
            <i class="fa fa-lock mr"></i>
            {{ $t('header.logout') }}
          </button>
        </li>
      </ul>
    </WCard>
  </template>
  </WDropdown>
</template>

<script>
import authApi from '@/services/auth';

export default {
  name: 'UserSettings',
  components: {
  },
  data() {
    return {
      isDropdownOpen: false
    };
  },
  computed: {
    colorClass() {
      return 'light';
    },
    currentUserData() {
      // todo refactor получения данных пользователя
      this.updatedUserData = JSON.parse(JSON.stringify(this.$store.getters.loadUserData));
      this.$store.commit('loadInHeaderLocationSelectBlockDataFromLocalStorage');

      const currentUserData = this.$store.state.userData;

      if (!currentUserData.photo) {
        currentUserData.photo = '/static/img/avatars/user.svg';
      }

      return currentUserData;
    }
  },
  methods: {
    handleOpenDropdown() {
      this.isDropdownOpen = true;
    },
    handleCloseDropdown() {
      this.isDropdownOpen = false;
    },
    logout() {
      this.handleCloseDropdown();
      authApi.logout(() => { this.$router.push('/pages/login'); });
    }
  }
};
</script>

<style lang="css" scoped>
ul {
  list-style-type: none;
  padding: 0;
}

p {
  margin: 0;
}

.button-content-wrapper {
  display: flex;
  align-items: center;
  position: relative;
  transition: var(--transition);
  border: 2px solid var(--header-font-color);
}

.avatar-wrapper {
  width: 30px;
  height: 30px;
  border-radius: 50%;
}
.avatar {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: inherit;
}

.user-profile {
  display: flex;
  align-items: center;
  gap: 16px;
}

.dropdown-content-wrapper {
  min-width: 240px;
  gap: 20px;
}

.dropdown-content-wrapper.light {
  background-color: var(--header-bg-color);
}

.content-list > li {
  font-weight: bold;
  padding: 16px 0;
}
.content-list > li:not(:first-of-type) {
  border-top: 1px solid var(--header-accent-color);
}

.content {
  background-color: transparent;
  border: none;

  font-weight: inherit;
  transition: var(--transition);
}

.mr:first-of-type {
  margin-right: 4px;
}

.content:hover {
  text-decoration: none;
}
.content.light {
  color: var(--header-font-color);
}

.content.light:hover {
  color: var( --brand-info);
}

.info {
  color: var(--brand-info);
}
</style>
