<template>
  <WButton @click="logoutAsSubordinate">
    {{ $t('users.logoutAsSubordinateForButton') }} {{ bossUserUsername }}
  </WButton>
</template>

<script>
import { AuthTokenMaster } from '@/utils';

export default {
  name: 'ContinueAsSubbordinate',
  methods: {
    logoutAsSubordinate(event) {
      // todo refactor

      event.stopPropagation();
      const bossUserData = JSON.parse(localStorage.getItem('bossUserData'));
      // console.log(bossUserData);
      // вместо /userChange может быть любое имя, нужно чтобы dashboard(/) перезагрузился
      // (чтобы подгрузилось всякое, что грузится при входе в систему),
      // даже если юзер уже находится на dashboard(/), а потом перекидываем его на список юзеров
      this.$router.push('/userchange', () => {
        AuthTokenMaster.setAccessToken(bossUserData.token);

        this.axios.defaults.headers.common.Authorization = `Bearer ${bossUserData.token}`;
        this.$store.commit('setUserData', bossUserData);
        this.$store.commit('setBaseLocation', bossUserData.base_location);
        console.warn(`Login as ${bossUserData.username}`);
        localStorage.removeItem('bossUserData');
        this.$store.commit('subordinateLoginSetFalse');
        this.$router.push('/', () => {
          this.$router.push('/settings/users');
        });
      });
    }
  },
  computed: {
    colorClass() {
      return 'color';
    },
    bossUserUsername() {
      // todo refactor
      const bossUser = JSON.parse(localStorage.getItem('bossUserData'));
      const bossUserName = bossUser.username;
      return bossUserName;
    }
  }
};
</script>

<style>
.wifi-wizard-button {
  width: 30px;
  height: 30px;
  background: transparent;
  padding: 5px;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: var(--transition);

  position: relative;
}

.wifi-wizard-button.color {
  color: var(--header-font-color);
}

.wifi-wizard-button:hover {
  opacity: var(--opacity);
}

.wifi-icon {
  position: relative;
  right: 10%;
  bottom: 5%;
}

.gear-icon {
  position: absolute;
  right: 20%;
  bottom: 25%;

  font-size: 0.6em !important;
  line-height: 0.4em !important;
}
</style>
