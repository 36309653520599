export const setEnviroment = () => {

  // todo: отрефакторить это и удалить файл
  // дальше  для задачки WNE-2257 флаг и функция чтобы выводить сообщения о не 2xx ошибках в консоль,
  // вместо вывода в отдельное всплывающее окошко-алерат (нужно также чтобы эта функция вызывлась
  // в зависимости результата функции isShowAxiosErrorInConsole во всех обработчиках ошибок axios'a)
  window.SHOW_NON_2XX_API_ERRORS_IN_CONSOLE = true;
  window.showAxiosErrInConsole = function (err) {
    try {
      console.error('----- AXIOS API CALL ERROR -----');
      // console.log(err.request);
      // if (err.hasOwnProperty('response')) {
      //   if (err.response.hasOwnProperty('config') && err.response.config.hasOwnProperty('url')) {
      //     console.error('URL: ' + err.response.config.url);
      //   }
      // }
      // if (err.hasOwnProperty('request') && err.request.responseURL !== '') {
      //   console.error('URL: ' + err.request.responseURL);
      // }
      if (Object.prototype.hasOwnProperty.call(err, 'request') && err.request.responseURL !== '') {
        console.error(`URL: ${err.request.responseURL}`);
      }
      console.error(err);
      console.error('--------------------------------');
    } catch (e) {
      console.error(err);
    }
  };
  window.isShowAxiosErrorInConsole = function (err) {
    try {
      // проверяем конкретную  ошибку. Смотрим есть ли все нужные функции для вывода в консоль в window,
      // смотрим  на флаг SHOW_NON_2XX_API_ERRORS_IN_CONSOLE,
      // проверяем чтобы код ошибки не начанался на 2хх
      // const firstDigitOfErrorStatusCode = err.request.status.toString()[0] || '0';
      const errorStatusCode = err.request.status.toString();
      let firstDigitOfErrorStatusCode = '0';
      if (errorStatusCode.length !== 0) {
        firstDigitOfErrorStatusCode = errorStatusCode[0];
      }
      // if (window.hasOwnProperty('SHOW_NON_2XX_API_ERRORS_IN_CONSOLE') &&
      //   window.SHOW_NON_2XX_API_ERRORS_IN_CONSOLE === true && firstDigitOfErrorStatusCode !== '2' &&
      //   window.hasOwnProperty('showAxiosErrInConsole') && (typeof window.showAxiosErrInConsole === 'function')) {
      //   return true;
      // }
      if (
        Object.prototype.hasOwnProperty.call(window, 'SHOW_NON_2XX_API_ERRORS_IN_CONSOLE') &&
        window.SHOW_NON_2XX_API_ERRORS_IN_CONSOLE === true &&
        firstDigitOfErrorStatusCode !== '2' &&
        Object.prototype.hasOwnProperty.call(window, 'showAxiosErrInConsole') &&
        typeof window.showAxiosErrInConsole === 'function'
      ) {
        return true;
      }
      return false;
    } catch (e) {
      return false;
    }
  };

  console.log(process.env);
};
// export default setEnviroment;
