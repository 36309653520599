<template>
  <div class="loading-wrapper">
    <div ref="loadingContainer" class="loading__container loading-spinner-component-background">
      <div class="loading" :style="styleWithTopMargin">
        <div class="radio-wifi">
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'loaderSpinner',
  props: {
    centered: {
      type: Boolean,
      default: false
    },
    topmargin: {
      type: String
    },
    scale: {
      type: Number,
      default: 0.4
    }
  },
  computed: {
    styleWithTopMargin() {
      if (this.centered === true) {
        return '';
      }
      let top = '0px';
      if (this.topmargin === undefined) {
        top = '20px';
      } else {
        top = `${this.topmargin}`;
      }
      return `position: absolute; top: ${top};`;
    }
  },
  watch: {
    scale() {
      const container = this.$refs.loadingContainer;
      if (this.scale && container) {
        container.style.setProperty('--loading-ratio', this.scale);
      }
    }
  },
  mounted() {
    const container = this.$refs.loadingContainer;
    if (this.scale && container) {
      container.style.setProperty('--loading-ratio', this.scale);
    }
  }
};
</script>

<style lang="scss" scoped>
.loading__container {
  /* --loading-ratio: 0.4; */
}
@keyframes radio-wifi {
  0% {
    opacity: 0;
  }
  20% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}
.loading {
  width: calc(200px * var(--loading-ratio));
  height: calc(200px * var(--loading-ratio));
  display: inline-block;
  overflow: hidden;
  /* background: #ffffff; */
  z-index: 99999;
}
.loading__container {
  width: 100%;
  height: 100%;

  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  justify-content: center;
  align-items: center;
  z-index: 99999;
}
.from-top {
  position: absolute;
  top: 50px;
}
.radio-wifi div {
  position: absolute;

  animation: radio-wifi 1s linear infinite;
  box-sizing: border-box !important;
}
.radio-wifi div:nth-child(1) {
  width: calc(44px * var(--loading-ratio));
  height: calc(44px * var(--loading-ratio));
  left: calc(34px * var(--loading-ratio));
  top: calc(128px * var(--loading-ratio));
  border-radius: 50%;
  background: #36323d;
}
.radio-wifi div:nth-child(2) {
  width: calc(66px * var(--loading-ratio));
  height: calc(66px * var(--loading-ratio));
  left: calc(56px * var(--loading-ratio));
  top: calc(84px * var(--loading-ratio));
  border-radius: 0 calc(66px * var(--loading-ratio)) 0 0;
  border: calc(20px * var(--loading-ratio)) solid #f57e20;
  background: none;
  animation-delay: 0.1s;
  border-bottom: 0;
  border-left: 0;
}
.radio-wifi div:nth-child(3) {
  width: calc(110px * var(--loading-ratio));
  height: calc(110px * var(--loading-ratio));
  left: calc(56px * var(--loading-ratio));
  top: calc(40px * var(--loading-ratio));
  border-radius: 0 calc(110px * var(--loading-ratio)) 0 0;
  border: calc(20px * var(--loading-ratio)) solid #36323d;
  background: none;
  animation-delay: 0.2s;
  border-bottom: 0;
  border-left: 0;
}

.radio-wifi {
  width: 100%;
  height: 100%;
  transform: translateZ(0) scale(1);
  backface-visibility: hidden;
  transform-origin: 0 0;
}

.loaded_hiding .loading__container {
  transition: 0.4s opacity;
  opacity: 0;
}

.loaded .loading__container {
  display: block;
}
.loading-wrapper {
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.58);
}
</style>
<style lang="scss" scoped>
.loading-spinner-component-background {
  background-color: rgba(255, 255, 255, 0.58);
}
</style>
