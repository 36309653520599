<template>
  <div>
    <WCard class="table-card">
      <BranchProfileTable
        :items="branchProfiles"
        :totalItems="totalBranchProfilesCount"
        :perPage="limit"
        :selectedBranchProfileNames="selectedBranchProfileNames"
        :sortState="sortParams"
        :isDataLoading="false"
        :isDisabled="isLoading"
        :isOneColMode="false"
        :isSelectionRowsModeEnabled="true"
        @on-cell-click="handleCellClick"
        @on-selected-rows-change="handleSelectRow"
        @on-sort-change="handleSortChange"
        @on-page-change="handlePageChange"
        @on-per-page-change="handlePerPageChange"
      >
        <template v-slot:table-menu>
          <div class="control-panel">
            <div class="control-panel__container left">
              <WButton info
                :disabled="isLoading"
                @click="openCreateBranchProfileModal"
              >
                {{ $t('general.add') }}
              </WButton>
              <WButton info
                :disabled="isLoading || selectedBranchProfileNames.length !== 1"
                @click="openCloneBranchProfileModal"
              >
                {{ $t('general.clone') }}
              </WButton>
              <WButton info
                :disabled="isLoading || !selectedBranchProfileNames.length"
                @click="openDeleteBranchProfileModal"
              >
                {{ $t('general.delete') }}
              </WButton>
            </div>
            <div class="control-panel__container right">
            </div>
          </div>
        </template>
      </BranchProfileTable>
    </WCard>

    <CreateBranchProfileModal
      :forbiddenProfileNames="forbiddenProfileNames"
      :radiusGroupsNames="radiusGroupsNames"
      :redirectRulesNames="redirectRulesNames"
      :isOpen="isCreateBranchProfileModalOpen"
      @submit="createBranchProfile"
      @close="closeCreateBranchProfileModal"
    />

    <CloneBranchProfileModal
      :branchProfileForCloning="selectedBranchProfileForCloning"
      :forbiddenProfileNames="forbiddenProfileNames"
      :radiusGroupsNames="radiusGroupsNames"
      :redirectRulesNames="redirectRulesNames"
      :isOpen="isCloneBranchProfileModalOpen"
      @submit="createBranchProfile"
      @close="closeCloneBranchProfileModal"
    />

    <ChangeBranchProfileModal
      :branchProfileForChanging="selectedBranchProfileForChanging"
      :forbiddenProfileNames="forbiddenProfileNames"
      :radiusGroupsNames="radiusGroupsNames"
      :redirectRulesNames="redirectRulesNames"
      :isOpen="isChangeBranchProfileModalOpen"
      @submit="updateBranchProfile"
      @close="closeChangeBranchProfileModal"
    />

    <DeleteBranchProfileModal
      :branchProfileNamesToDelete="branchProfileNamesToDelete"
      :isOpen="isDeleteBranchProfileModalOpen"
      @close="closeDeleteBranchProfileModal"
      @confirm="handleDeleteBranchProfiles"
    />
  </div>
</template>

<script>
/**
 * компонент страницы настройки профиля филиала (Branch Profile) (WLC)
 * https://wimarksystems.atlassian.net/wiki/spaces/Wimark/pages/263356419/FT23.8.1+Front
 * @component
 */

import {
  BranchProfileTable,
  CreateBranchProfileModal,
  ChangeBranchProfileModal,
  CloneBranchProfileModal,
  DeleteBranchProfileModal
} from './components';

import branchProfileApi from './api';

/* eslint-disable camelcase */

export default {
  name: 'BranchProfilePage',
  components: {
    BranchProfileTable,
    CreateBranchProfileModal,
    ChangeBranchProfileModal,
    CloneBranchProfileModal,
    DeleteBranchProfileModal
  },
  props: {
  },
  data() {
    return {
      branchProfiles: [],
      radiusGroupsNames: [],
      redirectRulesNames: [],
      // айдишки для контролирования стейта при выборе через чекбокс
      selectedBranchProfileNames: [],
      selectedBranchProfileForChanging: {},
      selectedBranchProfileForCloning: {},
      totalBranchProfilesCount: 0,
      offset: 0,
      limit: 10,
      sortParams: {
        field: 'name',
        type: 'asc'
      },
      isLoading: false,
      isCreateBranchProfileModalOpen: false,
      isChangeBranchProfileModalOpen: false,
      isCloneBranchProfileModalOpen: false,
      isDeleteBranchProfileModalOpen: false
    };
  },
  computed: {
    forbiddenProfileNames() {
      return this.branchProfiles.map(branchProfile => branchProfile.name);
    },
    branchProfileNamesToDelete() {
      if (!this.branchProfiles.length) {
        return [];
      }

      return this.branchProfiles
        .filter(branchProfile => this.selectedBranchProfileNames.includes(branchProfile.name))
        .map(branchProfile => branchProfile.name);
    },
    branchProfileIdsToDelete() {
      if (!this.branchProfiles.length) {
        return [];
      }

      return this.branchProfiles
        .filter(branchProfile => this.selectedBranchProfileNames.includes(branchProfile.name))
        .map(branchProfile => branchProfile.id);
    }
  },
  methods: {
    handleCellClick(rowData) {
      const { nativeData: { name } } = rowData;

      const [selectedBranchProfileForChanging] = this.branchProfiles
        .filter(branchProfile => branchProfile.name === name);

      this.selectedBranchProfileForChanging = selectedBranchProfileForChanging;

      this.openChangeBranchProfileModal();
    },
    handleSelectRow(selectedRowData) {
      const selectedBranchProfileNames = selectedRowData.map(rowData => rowData.nativeData.name);

      if (selectedBranchProfileNames.length === 1) {
        const [selectedBranchProfileForCloning] = this.branchProfiles
          .filter(apJoinProfile => apJoinProfile.name === selectedBranchProfileNames[0]);

        this.selectedBranchProfileForCloning = selectedBranchProfileForCloning;
      }

      if (selectedBranchProfileNames.length !== 1) {
        this.selectedBranchProfileForCloning = {};
      }

      this.selectedBranchProfileNames = selectedBranchProfileNames;
    },
    handleSortChange(sortChangeData) {
      const { params } = sortChangeData;
      const { field, type } = params[0];

      this.sortParams = { field, type };

      this.getBranchProfiles();
    },
    handlePageChange(paginationParams) {
      const { currentPage, currentPerPage } = paginationParams;

      const newOffsetValue = currentPage * currentPerPage - currentPerPage;

      this.offset = newOffsetValue;
      this.limit = currentPerPage;

      if (newOffsetValue >= this.totalBranchProfilesCount) {
        this.offset = 0;
      }

      this.getBranchProfiles();
    },
    handlePerPageChange(paginationParams) {
      const { currentPage, currentPerPage } = paginationParams;

      const newOffsetValue = currentPage * currentPerPage - currentPerPage;

      this.offset = newOffsetValue;
      this.limit = currentPerPage;

      if (newOffsetValue >= this.totalBranchProfilesCount) {
        this.offset = 0;
      }

      this.getBranchProfiles();
    },
    getRadiusGroups() {
      branchProfileApi.getRadiusGroups({
        onLoading: () => {
          this.isLoading = true;
        },
        onSuccess: (radiusGroups) => {
          this.isLoading = false;
          this.radiusGroupsNames = radiusGroups.map(radiusGroup => radiusGroup.name);
        },
        onError: (error) => {
          this.isLoading = false;
        }
      });
    },
    getRedirectRules() {
      branchProfileApi.getRedirectRules({
        onLoading: () => {
          this.isLoading = true;
        },
        onSuccess: (redirectRules) => {
          this.isLoading = false;
          this.redirectRulesNames = redirectRules.map(redirectRule => redirectRule.name);
        },
        onError: (error) => {
          this.isLoading = false;
        }
      });
    },
    handleDeleteBranchProfiles() {
      const branchProfilesToDelete = this.branchProfileIdsToDelete;

      branchProfileApi.deleteBranchProfiles({
        branchProfilesToDelete
      }, {
        onLoading: () => {
          this.isLoading = true;
        },
        onSuccess: (data) => {
          this.isLoading = false;
          this.closeDeleteBranchProfileModal();
          this.getBranchProfiles();
        },
        onError: (error) => {
          this.isLoading = false;
        },
        successMessage: this.$t('branchProfile.responseMessage.deleteBranchProfiles.success'),
        errorMessage: this.$t('branchProfile.responseMessage.deleteBranchProfiles.error')
      });
    },
    updateBranchProfile(formValues) {
      const { id } = this.selectedBranchProfileForChanging;

      branchProfileApi.createBranchProfile({
        id,
        name: formValues['general.profileName'],
        description: formValues['general.profileDescription'],
        native_vlan_id: formValues['general.nativeVlan'],
        fallback_radio_shut_enabled: formValues['general.fallbackRadioShutdown'],
        arp_caching_enabled: formValues['general.arpCaching'],
        efficient_sw_upgrade_enabled: formValues['general.efficientSoftwareUpgrade'],
        ip_overlap_enabled: formValues['general.ipOverlap'],
        pmk_propagation_enabled: formValues['general.pmkPropagation'],
        radius_group: formValues['authentication.radiusGroup'],
        branch_profile_vlans: formValues['vlan.branchProfileVlans'],
        web_auth_profile: formValues['policy.webAuthentication']
      }, {
        onLoading: () => {
          this.isLoading = true;
        },
        onSuccess: (data) => {
          this.isLoading = false;
          this.closeChangeBranchProfileModal();
          this.getBranchProfiles();
        },
        onError: () => {
          this.isLoading = false;
        },
        successMessage: this.$t('branchProfile.responseMessage.updateBranchProfile.success'),
        errorMessage: this.$t('branchProfile.responseMessage.updateBranchProfile.error')
      });
    },
    createBranchProfile(formValues) {
      branchProfileApi.createBranchProfile({
        name: formValues['general.profileName'],
        description: formValues['general.profileDescription'],
        native_vlan_id: formValues['general.nativeVlan'],
        fallback_radio_shut_enabled: formValues['general.fallbackRadioShutdown'],
        arp_caching_enabled: formValues['general.arpCaching'],
        efficient_sw_upgrade_enabled: formValues['general.efficientSoftwareUpgrade'],
        ip_overlap_enabled: formValues['general.ipOverlap'],
        pmk_propagation_enabled: formValues['general.pmkPropagation'],
        radius_group: formValues['authentication.radiusGroup'],
        branch_profile_vlans: formValues['vlan.branchProfileVlans'],
        web_auth_profile: formValues['policy.webAuthentication']
      }, {
        onLoading: () => {
          this.isLoading = true;
        },
        onSuccess: (data) => {
          this.isLoading = false;
          this.closeCreateBranchProfileModal();
          this.closeCloneBranchProfileModal();
          this.getBranchProfiles();
        },
        onError: () => {
          this.isLoading = false;
        },
        successMessage: this.$t('branchProfile.responseMessage.createBranchProfile.success'),
        errorMessage: this.$t('branchProfile.responseMessage.createBranchProfile.error')
      });
    },
    getBranchProfiles() {
      branchProfileApi.getBranchProfiles({
        limit: this.limit,
        offset: this.offset,
        // // если надо будет реализовать фильтрацию, то использовать filterByName, filterById
        // filterByName: undefined,
        // filterById: undefined,
        sortOrderBy: this.sortParams.type,
        sortValueBy: this.sortParams.field
      }, {
        onLoading: () => {
          this.isLoading = true;
        },
        onSuccess: (data) => {
          this.isLoading = false;

          const {
            total,
            limit,
            offset,
            branch_profiles
          } = data;

          this.branchProfiles = branch_profiles;
          this.totalBranchProfilesCount = total;
          this.offset = offset;
          this.limit = limit;

          this.isLoading = false;

          this.selectedBranchProfileNames = [];
        },
        onError: (error) => {
          this.isLoading = false;
        }
      });
    },
    openCreateBranchProfileModal() {
      this.isCreateBranchProfileModalOpen = true;
    },
    closeCreateBranchProfileModal() {
      this.isCreateBranchProfileModalOpen = false;
    },
    openChangeBranchProfileModal() {
      this.isChangeBranchProfileModalOpen = true;
    },
    closeChangeBranchProfileModal() {
      this.isChangeBranchProfileModalOpen = false;
      this.selectedBranchProfileForChanging = {};
    },
    openCloneBranchProfileModal() {
      this.isCloneBranchProfileModalOpen = true;
    },
    closeCloneBranchProfileModal() {
      this.isCloneBranchProfileModalOpen = false;
    },
    openDeleteBranchProfileModal() {
      this.isDeleteBranchProfileModalOpen = true;
    },
    closeDeleteBranchProfileModal() {
      this.isDeleteBranchProfileModalOpen = false;
    }
  },
  mounted() {
    this.getBranchProfiles();
    this.getRadiusGroups();
    this.getRedirectRules();
  }
};
</script>

<style lang="css" scoped>
.table-card {
  background-color: var(--table-accent);
}

.control-panel {
  display: flex;
  gap: 24px;
  padding: 8px 0;
}

.control-panel__container {
  display: flex;
  gap: 8px;
}

.control-panel__container.right {
  justify-content: flex-end;
  flex: 1 1 auto;
}
</style>
