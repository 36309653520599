<template>
  <BranchProfileForm
    :formConfig="formConfig"
    :forbiddenProfileNames="forbiddenProfileNames"
    @submit="handleSubmit"
  >
    <template v-slot:control-buttons>
      <slot name="form-control-buttons"></slot>
    </template>
  </BranchProfileForm>
</template>

<script>
/**
 * компонент настроенной формы BranchProfileForm через generateFormConfig для создания нового branch profile.
 * ! Компонент локальный, используется только в CreateBranchProfileModal.vue
 * @component
 */

import {
  BranchProfileForm,
  generateFormConfig
} from '../../features';

export default {
  name: 'CreateBranchProfileForm',
  components: {
    BranchProfileForm
  },
  props: {
    forbiddenProfileNames: {
      type: Array,
      default: () => []
    },
    radiusGroupsNames: {
      type: Array,
      default: () => []
    },
    redirectRulesNames: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
    };
  },
  computed: {
  },
  methods: {
    handleSubmit(formValues) {
      this.$emit('submit', formValues);
    }
  },
  created() {
    // задаем дефолтные значения для каждого поля
    const formConfig = generateFormConfig({
      i18nInstance: this.$i18n,
      initialValues: {
        authentication: {
          radiusGroup: {
            selectOptions: this.radiusGroupsNames.map(name => ({
              display: name, data: name
            }))
          }
        },
        policy: {
          webAuthentication: {
            selectOptions: this.redirectRulesNames.map(name => ({
              display: name, data: name
            }))
          }
        }
      }
    });

    this.formConfig = formConfig;
  }
};
</script>

<style lang="css" scoped>
</style>
