<template>
  <div class="w-100 mt-1">
    <div class="w-100 d-flex flex-row justify-content-between">
      <div class="w-50 d-flex flex-column">
        <div class="mb-1 h6">Agent Remote ID (RID)</div>
        <!--option 82  Rid Type-->
        <div v-for="ridType of option82RidTypesAsArray" :key="ridType">
          <Switch-component
            :class="{ 'disable-turned-on-switcher-in-wired-config': isDisable }"
            @input="dhcpOption82RidTypeSwitcherHandler(ridType)"
            :disabled="isDisable || dhcpOption82RidTypeSwitcherState[ridType]"
            class="ml-1"
            v-model="dhcpOption82RidTypeSwitcherState[ridType]"
            :label="option82RidTypes[ridType]"
            :id="`rid-type-switcher-${ridType}`"
          />
        </div>
      </div>
      <div class="w-50 d-flex flex-column">
        <div class="mb-1 h6">Agent Circuit ID (CID)</div>
        <!--option 82  Cid Type-->
        <div v-for="cidType of option82CidTypesAsArray" :key="cidType">
          <Switch-component
            :class="{ 'disable-turned-on-switcher-in-wired-config': isDisable }"
            @input="dhcpOption82CidTypeSwitcherHandler(cidType)"
            :disabled="isDisable || dhcpOption82CidTypeSwitcherState[cidType]"
            class="ml-1"
            v-model="dhcpOption82CidTypeSwitcherState[cidType]"
            :label="option82CidTypes[cidType]"
            :id="`cid-type-switcher-${cidType}`"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SwitchComponent from '../Universal/Switch-component.vue';

const OPTION_82_RID_TYPES = {
  // ключ для всей логики и передачи на бэк, а значение для вывода подписей рядом с селекторами в UI
  'APMAC:SSID': 'APMAC:SSID',
  // 'WLAN:IFNAME': 'WLAN:IFNAME', // not working for now
  'VLAN:SSID': 'VLAN:SSID',
  'BSSID:HOSTNAME': 'BSSID:APNAME',
  // 'APMAC:SITEID': 'APMAC:SITEID', // not working for now
  'APMAC': 'APMAC'
};

const OPTION_82_CID_TYPES = {
  // ключ для всей логики и передачи на бэк, а значение для вывода подписей рядом с селекторами в UI
  '': 'Empty (0000)',
  'SSID': 'SSID',
  'IFNAME': 'IFNAME'
};

export default {
  name: 'Option82RidTypeSelector',
  components: { SwitchComponent },
  data() {
    return {
      dhcpOption82RidTypeSwitcherState: {}, // тут храним состояние вкл/выкл свитчеров для выбора option82rid_type
      dhcpOption82CidTypeSwitcherState: {} // а тут для выбора option82cid_type
    };
  },
  created() {
    this.allSwitchersStatesInit();
  },
  methods: {
    allSwitchersStatesInit() {
      this.dhcpOption82RidTypeSwitcherStateInit(this.updatedWlanObjectFromPropsAsCopy);
      this.dhcpOption82CidTypeSwitcherStateInit(this.updatedWlanObjectFromPropsAsCopy);
    },
    dhcpOption82RidTypeSwitcherHandler(ridType) {
      // console.log(ridType)
      this.dhcpOption82RidTypeSetEventEmit(ridType);
      this.dhcpOption82RidTypeSwitcherStateSet(ridType);
    },
    dhcpOption82CidTypeSwitcherHandler(cidType) {
      this.dhcpOption82CidTypeSetEventEmit(cidType);
      this.dhcpOption82CidTypeSwitcherStateSet(cidType);
    },
    dhcpOption82RidTypeSetEventEmit(ridType) {
      this.$emit('selectOption82RidType', ridType);
    },
    dhcpOption82CidTypeSetEventEmit(cidType) {
      this.$emit('selectOption82CidType', cidType);
    },
    dhcpOption82RidTypeSwitcherStateSet(ridType) {
      this.dhcpOption82RidTypeSwitcherStateClear();
      this.$set(this.dhcpOption82RidTypeSwitcherState, ridType, true);
    },
    dhcpOption82CidTypeSwitcherStateSet(cidType) {
      this.dhcpOption82CidTypeSwitcherStateClear();
      // this.dhcpOption82CidTypeSwitcherState[cidType] = true;
      this.$set(this.dhcpOption82CidTypeSwitcherState, cidType, true);
    },
    dhcpOption82RidTypeSwitcherStateClear() {
      for (const ridTypeSwitcherState in this.dhcpOption82RidTypeSwitcherState) {
        // this.dhcpOption82RidTypeSwitcherState[ridTypeSwitcherState] = false;
        this.$set(this.dhcpOption82RidTypeSwitcherState, ridTypeSwitcherState, false);
      }
    },
    dhcpOption82CidTypeSwitcherStateClear() {
      for (const cidTypeSwitcherState in this.dhcpOption82CidTypeSwitcherState) {
        this.$set(this.dhcpOption82CidTypeSwitcherState, cidTypeSwitcherState, false);
      }
    },
    dhcpOption82RidTypeSwitcherStateInit(wlanObject) {
      for (const ridType of this.option82RidTypesAsArray) {
        // this.dhcpOption82RidTypeSwitcherState[ridType] = false;
        this.$set(this.dhcpOption82RidTypeSwitcherState, ridType, false);
      }
      this.dhcpOption82RidTypeSwitcherStateSet(wlanObject.option82rid_type);
    },
    dhcpOption82CidTypeSwitcherStateInit(wlanObject) {
      for (const cidType of this.option82CidTypesAsArray) {
        // this.dhcpOption82RidTypeSwitcherState[ridType] = false;
        this.$set(this.dhcpOption82CidTypeSwitcherState, cidType, false);
      }
      this.dhcpOption82CidTypeSwitcherStateSet(wlanObject.option82cid_type);
    }

  },
  props: {
    updatedWlan: { required: true },
    isDisable: { required: true }
  },
  computed: {
    selectedOption82RidTypeInUpdatedWlanProp() {
      return this.updatedWlanObjectFromPropsAsCopy.option82rid_type;
    },
    selectedOption82CidTypeInUpdatedWlanProp() {
      return this.updatedWlanObjectFromPropsAsCopy.option82cid_type;
    },
    option82RidTypes() {
      return OPTION_82_RID_TYPES;
    },
    option82RidTypesAsArray() {
      return Object.keys(this.option82RidTypes);
    },
    option82CidTypes() {
      return OPTION_82_CID_TYPES;
    },
    option82CidTypesAsArray() {
      return Object.keys(this.option82CidTypes);
    },
    updatedWlanObjectFromPropsAsCopy() {
      return JSON.parse(JSON.stringify(this.updatedWlan));
    }
  },
  watch: {
    selectedOption82RidTypeInUpdatedWlanProp() {
      this.dhcpOption82RidTypeSwitcherStateInit(this.updatedWlanObjectFromPropsAsCopy);
    },
    selectedOption82CidTypeInUpdatedWlanProp() {
      this.dhcpOption82CidTypeSwitcherStateInit(this.updatedWlanObjectFromPropsAsCopy)
    },
  }
};
</script>

<style>
.disable-turned-on-switcher-in-option82-rid-type-selector .switch-primary > .switch-input:checked ~ .switch-label {
  background-color: #94a0b2 !important;
}
</style>
