/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
/**
 * конфигурация таблицы RRMGroupsTable.vue
*/

/**
 * генератор колонок таблицы
 * https://xaksis.github.io/vue-good-table/guide/configuration/column-options.html#label
*/

import {
  getSelectedRowValue
} from './utils';

function generateTableColumns({ i18nInstance, hiddenColumnRules, additionalRules }) {
  const $t = i18nInstance.t.bind(i18nInstance);

  const { isOperatorRole } = additionalRules;

  return [
    {
      label: $t('general.name'),
      field: 'name',
      width: 'auto',
      type: 'text',
      hidden: hiddenColumnRules.name
    },
    {
      label: $t('RRM.timeout'),
      field: 'timeout',
      width: 'auto',
      type: 'text',
      hidden: hiddenColumnRules.timeout
    },
    {
      label: $t('RRM.powerForTable'),
      field: 'power',
      width: 'auto',
      type: 'text',
      hidden: hiddenColumnRules.power
    },
    {
      label: $t('RRM.tableRRMCPEs'),
      field: 'aps',
      width: 'auto',
      type: 'text',
      hidden: hiddenColumnRules.aps
    },
    {
      label: '',
      field: 'action',
      width: 'auto',
      type: 'text',
      sortable: false,
      hidden: hiddenColumnRules.action || isOperatorRole,
      visibilityDropdownSettings: {
        hidden: true
      }
    }
  ];
}

/**
 * генератор строк таблицы
 * https://xaksis.github.io/vue-good-table/guide/configuration/#rows
 * https://xaksis.github.io/vue-good-table/guide/advanced/grouped-table.html#customizing-header-row
*/
function generateTableRows({ rrmGroupsData, selectedRowRRMGroupIds }) {
  return rrmGroupsData.map(rrmGroup => {
    const {
      algo,
      base_location,
      cpes,
      force,
      id,
      name,
      tags
    } = rrmGroup;

    return {
      // nativeData - хранит неформатированные данные. Нужны, чтобы удобно эмитить их по событию для дальнейших манипуляций.
      nativeData: rrmGroup,
      name,
      timeout: algo?.data?.timeout,
      power: algo?.data?.manage_power,
      aps: cpes,
      action: null,
      // можно ли выбирать строку чекбоксом
      // vgtDisabled: false,
      // выбрана ли строка в чекбоксе исходя из стейта, который контролирует выбранные строки
      vgtSelected: getSelectedRowValue(id, selectedRowRRMGroupIds)
    };
  });
}

export {
  generateTableColumns,
  generateTableRows
};
