<template>
  <div class="row h-100">
    <div class="col-lg-12 h-100 position-relative">
      <loader-spinner
        class="card h-100"
        centered
        v-if="iswlanWizardGoingToLastStepAfterClickSkipButtonInProgess"
      ></loader-spinner>
      <div v-else class="h-100">
        <div class="card-block">
          <div class="custom-tabs-wrapper-in-wlans-wizard-advanced-step">
            <ul class="nav nav-tabs row m-0" role="tablist">
              <li class="nav-item">
                <a
                  class="nav-link"
                  role="tab"
                  :class="{ active: advancedTab === 'dataTrafficManagementTab', 'tab-has-error': validatorErrorsInTabs['dataTrafficManagementTab'] === true }"
                  @click="setAdvancedTabTo('dataTrafficManagementTab')"
                >
                  {{ $t('wlans.dataTrafficManagement') }}
                </a>
              </li>
              <li class="nav-item">
                <a
                  class="nav-link"
                  role="tab"
                  :class="{ active: advancedTab === 'DHCPOption82Tab', 'tab-has-error': validatorErrorsInTabs['DHCPOption82Tab'] === true }"
                  @click="setAdvancedTabTo('DHCPOption82Tab')"
                >
                  {{ $t('wlans.dhcpOption82') }}
                </a>
              </li>
              <li class="nav-item">
                <a
                  class="nav-link"
                  role="tab"
                  :class="{ active: advancedTab === 'speedControlTab', 'tab-has-error': validatorErrorsInTabs['speedControlTab'] === true }"
                  @click="setAdvancedTabTo('speedControlTab')"
                >
                  {{ $t('wlans.speedControl') }}
                </a>
              </li>
              <li class="nav-item">
                <a
                  class="nav-link"
                  role="tab"
                  :class="{ active: advancedTab === 'mobilitySettingsTab', 'tab-has-error': validatorErrorsInTabs['mobilitySettingsTab'] === true  }"
                  @click="setAdvancedTabTo('mobilitySettingsTab')"
                >
                  {{ $t('wlans.mobility') }}
                </a>
              </li>
              <li class="nav-item">
                <a
                  class="nav-link"
                  role="tab"
                  :class="{ active: advancedTab === 'WMMTab', 'tab-has-error': validatorErrorsInTabs['WMMTab'] === true  }"
                  @click="setAdvancedTabTo('WMMTab')"
                >
                  {{ $t('wlans.WMM') }}
                </a>
              </li>
            </ul>
          </div>

          <form action="">
            <DataTrafficManagementTab
              @dataNoValid="dataIsNoValidHandler"
              @dataIsValid="dataIsValidHandler"
              ref="dataTrafficManagementTab"
              v-if="advancedTab === 'dataTrafficManagementTab'"
            ></DataTrafficManagementTab>
            <DHCPOption82Tab
              @dataNoValid="dataIsNoValidHandler"
              @dataIsValid="dataIsValidHandler"
              ref="DHCPOption82Tab"
              v-if="advancedTab === 'DHCPOption82Tab'"
            ></DHCPOption82Tab>
            <SpeedControlTab
              ref="speedControlTab"
              @dataNoValid="dataIsNoValidHandler"
              @dataIsValid="dataIsValidHandler"
              v-if="advancedTab === 'speedControlTab'"
            ></SpeedControlTab>
            <MobilitySettingsTab
              ref="mobilitySettingsTab"
              @dataNoValid="dataIsNoValidHandler"
              @dataIsValid="dataIsValidHandler"
              v-if="advancedTab === 'mobilitySettingsTab'"
            ></MobilitySettingsTab>
            <WMMTab
              ref="WMMTab"
              @dataNoValid="dataIsNoValidHandler"
              @dataIsValid="dataIsValidHandler"
              v-if="advancedTab === 'WMMTab'"
            ></WMMTab>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DataTrafficManagementTab from './advancedTabSubTabs/DataTrafficManagementTab.vue';
import SpeedControlTab from './advancedTabSubTabs/SpeedControlTab.vue';
import MobilitySettingsTab from './advancedTabSubTabs/MobilitySettingsTab.vue';
import WMMTab from './advancedTabSubTabs/WMMTab.vue';
import DHCPOption82Tab from './advancedTabSubTabs/DHCPOption82Tab.vue';

export default {
  name: 'AdvancedTab',
  components: { DataTrafficManagementTab, SpeedControlTab, MobilitySettingsTab, WMMTab, DHCPOption82Tab },
  data() {
    return {
      advancedTab: this.selectedSubTab,
      validatorErrorsInTabs: {
        dataTrafficManagementTab: false,
        DHCPOption82Tab: false,
        speedControlTab: false,
        mobilitySettingsTab: false,
        WMMTab: false
      }
    };
  },
  props: {
    selectedSubTab: {
      default: 'dataTrafficManagementTab',
      type: String
    }
  },
  watch: {},
  computed: {
    areValidatorErrors() {
      return Object.values(this.validatorErrorsInTabs).some((item)=>{
        return item === true;
      })
    },
    iswlanWizardGoingToLastStepAfterClickSkipButtonInProgess() {
      return this.$store.state.wlanWizardGoingToLastStepAfterClickSkipButtonInProgess;
    },
    window() {
      return window;
    }
  },
  methods: {
    setAdvancedTabTo(n) {
      const currentTabStep = this.advancedTab;
      if (currentTabStep === n) {
        console.log('here');
        return;
      }
      this.$refs[`${currentTabStep}`].validateAndSetNewWlanData(currentTabStep, n);
    },
    validateAndSetNewWlanData(currentTab, nextTab) {
      this.setNewWlanData(currentTab, nextTab);
    },

    setNewWlanData(currentTab, nextTab) {
      const currentTabStep = this.advancedTab;
      this.$refs[`${currentTabStep}`].validateAndSetNewWlanData(currentTabStep, undefined);
      // console.log(JSON.parse(JSON.stringify(this.validatorErrorsInTabs)))

      this.$nextTick(() => {
        setTimeout(() => {
          // console.log(JSON.parse(JSON.stringify(this.validatorErrorsInTabs)))
          if (!this.areValidatorErrors) {
            this.$emit('dataIsValid', {nextTab: nextTab, currentTab: currentTab});
          } else {
            this.$emit('dataNoValid', {nextTab: nextTab, currentTab: currentTab})
          }

        }, 100)

      })

    },
    setValidationErrorsForTab(n) {
      console.log('setValidationErrorsForTab', n);
      this.validatorErrorsInTabs[`${n}`] = true;
    },
    setNoValidationErrorsForTab(n) {
      this.validatorErrorsInTabs[`${n}`] = false;
    },
    dataIsValidHandler(dataObject) {
      console.log(dataObject);
      const nextTab = dataObject.nextTab;
      const currentTab = dataObject.currentTab;
      if (nextTab) {
        this.switchToTab(nextTab);
      }
      this.setNoValidationErrorsForTab(currentTab);
    },
    dataIsNoValidHandler(dataObject) {
      console.log(dataObject);
      const currentTab = dataObject.currentTab;
      this.setValidationErrorsForTab(currentTab);
    },
    switchToTab(n) {
      this.advancedTab = n;
      this.$emit('selectSubTab', n)
    }
  },

  created() {}
};
</script>
<style lang="scss"></style>
<style scoped>
    .tab-has-error{
    border: 0.5px solid #f86c6b !important;
  }
.custom-tabs-wrapper-in-wlans-wizard-advanced-step .nav.nav-tabs.row {
  border-radius: 0px 0px 0px 0px !important;
}
</style>
