/* eslint-disable consistent-return */

import store from '@/store';
import { AXIOS_INSTANCE, POST_DELETE_AP_PROFILE_URL } from '../config';

// doc https://wimarksystems.atlassian.net/wiki/spaces/Wimark/pages/256016456/FT23.7.5+Backend#%D0%A1%D1%82%D1%80%D1%83%D0%BA%D1%82%D1%83%D1%80%D0%B0-%D1%82%D0%B5%D0%BB%D0%B0-%D0%B7%D0%B0%D0%BF%D1%80%D0%BE%D1%81%D0%B0

/**
 * @typedef {import('../types.js').DeleteAPsProfileRequestData} DeleteAPsProfileRequestData
 */

/**
 * Функция-фетчер для удаления одного/нескольких существующих ap join profile.
 *
 * @param {DeleteAPsProfileRequestData} requestParams - ids AP Join Profile для удаления.
 * @returns {Promise<Object>} - Обещание с данными ответа.
 * @throws {Error} - Ошибка при выполнении запроса.
 */
const deleteAPsProfileFetcher = async (requestParams) => {
  const { apProfilesIdsToDelete } = requestParams;
  const payload = {
    ids: apProfilesIdsToDelete
  };

  try {
    const response = await AXIOS_INSTANCE.post(POST_DELETE_AP_PROFILE_URL, payload);

    const { data } = response;

    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};

/**
 * фукнция-обработчик фетчера deleteAPsProfileFetcher.
 * выполняет побочные действия и обрабатывает тело запроса.
 *
 * @param {DeleteAPsProfileRequestData} requestParams - ids AP Join Profile для удаления.
 * @param {Object} [options] - Дополнительные опции запроса.
 * @param {Function} [options.onLoading] - Callback при начале выполнения запроса.
 * @param {Function} [options.onSuccess] - Callback при успешном выполнении запроса.
 * @param {Function} [options.onError] - Callback при ошибке выполнения запроса.
 * @param {string} [options.successMessage] - Сообщение об успешном выполнении запроса.
 * @param {string} [options.errorMessage] - Сообщение об ошибке выполнении запроса.
 * @returns {Promise<Object>} - Обещание с данными ответа.
 * @throws {Error} - Ошибка при выполнении запроса.
 */
const deleteAPsProfile = async (requestParams, options = {}) => {
  const {
    onLoading,
    onSuccess,
    onError,
    successMessage,
    errorMessage
  } = options;

  try {
    if (onLoading) {
      onLoading();
    }

    store.commit('setLoading');

    const response = await deleteAPsProfileFetcher(requestParams);

    store.commit('setSuccess', successMessage);
    onSuccess();
    return Promise.resolve();
  } catch (error) {
    if (onError) {
      onError(error);
    }
    store.commit('setError', errorMessage);
  }
};

export default deleteAPsProfile;
