/**
 * Calculates ad campaign status - active, planned or done
 * Intended to work with date as number, e.g. in seconds
 * @param {number} now Current timestamp
 * @param {number} adStart Ad campaign start date
 * @param {number} adEnd Ad campaign end date
 * @return {string}
 */
const getAdStatus = (now, adStart, adEnd) => {
  if (now > adEnd) {
    return 'done';
  }
  if (now < adStart) {
    return 'planned';
  }
  return 'active';
};

export default getAdStatus;
