<template>
  <WDropdown
    @close="handleCloseDropdown"
    @open="handleOpenDropdown"
    :isOpen="isDropdownOpen"
  >
    <template v-slot:button-inner-content>
      <WInput
        v-model="query"
        :placeholder="$t('general.selectLocation')"
        id="location-selector"
        :customClass="['location-selector', isLoadingClass]"
        :disabled="isLoading"
        autocomplete="one-time-code"
      >
      </WInput>
      <button
        v-show="selectedLocationID || query"
        class="reset-button"
        @click.stop="reset"
        :disabled="isLoading"
      >
        <i class="fa fa-close"></i>
      </button>
    </template>
    <template v-slot:inner-content>
      <div
        class="list-wrapper"
        :class="[isLoadingClass]"
      >
      <div class="checkbox-wrapper">
        <WCheckbox
          :label="$t('general.withChild')"
          name="withChildsCheckbox"
          id="withChildsCheckbox"
          :checked="withChilds"
          @change="withChildsHandleChange"
          :disabled="isLoading"
        />
      </div>
      <div class="muted" v-if="!locationsList.length">
        {{ $t('general.noResultForSearch') }}
      </div>
        <WList
          v-if="locationsList.length"
          :fixedItems="{ maxItems: 6 }"
        >
          <li v-for="location in locationsList"
            :key="location.id"
            @click="selectLocation(location)"
            class="list-item"
            :class="[selectedLocationObject.id === location.id && 'selected']"
            >
            {{ location.name }}
          </li>
        </WList>
      </div>
    </template>
  </WDropdown>
</template>

<script>
import locationService from '@/services/locationService';
import { debounceMixin } from '@/mixins';

export default {
  name: 'LocationSelector',
  mixins: [debounceMixin],
  data() {
    return {
      isLoading: false,
      isDropdownOpen: false,
      query: '',
      locationsList: [],
      // объект выбранной локации
      selectedLocationObject: {},
      // выбранной локации айдишка
      // todo - можно вычислять через computed от return this.selectedLocationObject.id, но я оставил, чтобы вдруг ничего не сломалось
      selectedLocationID: '',
      // массив из айдишек выбранной локации и ее дочерних локаций
      selectedLocationAndChildsLocationsIDs: [],
      withChilds: true
    };
  },
  watch: {
    // сохранение выбора настроек каждый раз при изменении любого из параметров
    // todo refactor
    headerLocationSelectBlockDataForSaving: {
      deep: true,
      handler() {
        this.headerLocationSelectBlockDataSave();
      }
    },
    selectedLocationObject(newSelectedLocationObject) {
      const { name } = newSelectedLocationObject;

      if (!name) {
        this.query = '';

        return;
      }

      this.query = name;
    },
    query(newQueryValue) {
      this.debounce(async () => {
        const responseWithListLocations = await this.requestLocations(newQueryValue);
        this.locationsList = responseWithListLocations;
      }, 1000);
    }
  },
  computed: {
    isLoadingClass() {
      return this.isLoading && 'loading';
    },
    // это просто какая-то удобная структура для операций headerLocationSelectBlockDataSave
    // todo refactor
    headerLocationSelectBlockDataForSaving() {
      return {
        withChilds: this.withChilds,
        selectedLocationID: this.selectedLocationID,
        selectedLocationAndChildsLocationsIDs: this.selectedLocationAndChildsLocationsIDs,
        selectedLocationObject: this.selectedLocationObject
      };
    }
  },
  methods: {
    handleOpenDropdown() {
      this.isDropdownOpen = true;
    },
    handleCloseDropdown() {
      this.isDropdownOpen = false;
    },

    // первоначальный запрос данных из кеша
    // todo refactor
    initHeaderLocationSelectBlockData() {
      const {
        withChilds,
        selectedLocationID,
        selectedLocationAndChildsLocationsIDs,
        selectedLocationObject
      } = this.$store.state.inHeaderLocationSelectBlockData;

      this.withChilds = withChilds;
      this.selectedLocationID = selectedLocationID;
      this.selectedLocationAndChildsLocationsIDs = selectedLocationAndChildsLocationsIDs;
      this.selectedLocationObject = selectedLocationObject;
    },
    // сохранение данных, чтобы на страницах применились настройки для выбранной точки
    // todo сохраняет в localStorage - отрефакторить и удалить из стора это
    headerLocationSelectBlockDataSave() {
      this.$store.commit(
        'saveInHeaderLocationSelectBlockDataFToStoreAndLocalStorage',
        this.headerLocationSelectBlockDataForSaving
      );

      // а еще сохраняем для отображения на дашборде выбраной локации
      // todo refactor
      this.$store.commit('saveDashboardBlocksLocationSettingsForGetStatisticToStoreAndLocalStorage', {
        loctionId: this.headerLocationSelectBlockDataForSaving.selectedLocationID,
        with_child: this.headerLocationSelectBlockDataForSaving.withChilds
      });
    },
    async requestLocations(searchQuery) {
      this.isLoading = true;

      // todo refactor requestLocationsWithSortForUseInHeaderLocationSelector как api-модуль
      const response = await locationService.requestLocationsWithSortForUseInHeaderLocationSelector(searchQuery);
      this.isLoading = false;

      return response;
    },
    selectLocation(location) {
      // если клик по уже ранее выбранному полю. Чтобы не делать одно и то же.
      if (this.selectedLocationObject.id === location.id) {
        return;
      }

      this.selectedLocationObject = location;
      this.selectedLocationID = location.id;

      // обновляет локации
      this.refreshSelectedLocationAndChildsLocationsIDs();
      // хак какой-то внутри проекта, чтобы перерисовать интерфейс
      // todo refactor
      this.setInHeaderLocationSelectBlockDataChangedTimestamp();

      this.handleCloseDropdown();
    },
    refreshSelectedLocationAndChildsLocationsIDs() {
      this.selectedLocationAndChildsLocationsIDs = this.$store.getters.getParentLocationIdAndChildLocationsIDsConcatenatedArrayByLocationId(this.selectedLocationID);
    },
    // хак какой-то внутри проекта, чтобы перерисовать интерфейс
    // todo refactor
    setInHeaderLocationSelectBlockDataChangedTimestamp() {
      this.$store.commit('setInHeaderLocationSelectBlockDataChangedTimestamp');
    },
    withChildsHandleChange() {
      this.withChilds = !this.withChilds;

      this.refreshSelectedLocationAndChildsLocationsIDs();
      this.setInHeaderLocationSelectBlockDataChangedTimestamp();
    },
    reset() {
      this.query = '';
      this.selectedLocationObject = {};
      this.selectedLocationID = '';
      this.selectedLocationAndChildsLocationsIDs = [];
      this.withChilds = true;

      this.handleCloseDropdown();

      this.refreshSelectedLocationAndChildsLocationsIDs();
      this.setInHeaderLocationSelectBlockDataChangedTimestamp();
    }
  },
  created() {
    this.initHeaderLocationSelectBlockData();
  },
  async mounted() {
    this.locationsList = await this.requestLocations('');
  }
};
</script>

<style lang="css" scoped>

.list-wrapper {
  min-width: 260px;
  padding: 20px 24px;
  background-color: var(--header-bg-color);
  color: var(--header-font-color);
  box-shadow: var(--box-shadow);
  border-radius: 4px;

  position: relative;
}

.list-wrapper.loading::after {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  cursor: progress;
}

.checkbox-wrapper {
  transition: var(--transition);
  margin-bottom: 8px;
}

.checkbox-wrapper:hover {
  opacity: var(--opacity);
}

.list-item {
  padding: 0;
  cursor: pointer;
  transition: var(--transition);
}

.list-item:hover {
  opacity: var(--opacity);
}
.list-item:not(:last-of-type) {
  margin-bottom: 8px;
}

.list-item.selected {
  position: relative;
}

.list-item.selected::before {
  content: '';
  display: inline-block;
  width: 100%;
  height: 100%;
  background-color: var(--brand-success);
  opacity: .5;
  border-radius: 4px;
  position: absolute;
}

.muted {
  color: var(--gray-light);
}

.reset-button {
  position: absolute;
  top: 50%;
  right: 0%;
  transform: translate(0%, -50%);

  color: var(--header-font-color);
  background: transparent;
  border: none;

  transition: var(--transition);
}

.reset-button:hover {
  color: var(--brand-danger);
}
</style>
<style lang="css">
/* кастомные стили хедера для WInput */
.location-selector {
  background-color: var(--header-bg-color) !important;
  border-radius: 8px !important;
  color: var(--header-font-color) !important;
  border-color: var(--header-font-color) !important;
}

.location-selector.loading {
  cursor: progress !important;
}

.location-selector::placeholder {
  color: var(--header-accent-color) !important;
}
</style>
