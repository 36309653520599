<template>
  <form
    class="form-with-tabs"
    novalidate
    @submit.prevent="handleSubmit"
  >
    <div class="form-with-tabs__content">
      <Tabs
        :tabs="formConfig"
        :currentTab="currentTab"
        :tabsWithErrors="tabsWithErrors"
        @on-tab-change="handleTabClick"
      >
        <template v-for="(item, index) in formConfig">
          <FormTab v-show="currentTab === index"
            :key="item.id"
            :tabContent="item"
            :formState="formState"
            :tabsWithErrors="tabsWithErrors"
            :highOrderCurrentTab="currentTab"
          >
            <template v-slot:tab-content="{ tabContent, formState }">
              <slot name="tab-content"
                :tabContent="tabContent"
                :formState="formState"
                :handleInputFunc="handleInput"
                :currentTabName="formConfig[currentTab].name"
              >
              </slot>
            </template>
          </FormTab>
        </template>
      </Tabs>
    </div>
    <div class="form-with-tabs__footer">
      <slot v-if="withCustomControlButtons" name="control-buttons">
      </slot>
      <div v-else>
        <WButton type="submit">
          {{ $t('general.submit') }}
        </WButton>
      </div>
    </div>
  </form>
</template>

<script>
/**
 * UI-компонент формы с табами.
 * для работы необходимо передать formConfig prop (дока в utils).
 * ! Используется во всем проекте. Регистрируется глобально (в конкретном компоненте регистрировать не нужно).
 *
 * Основан на работе UI-компонента Tabs и локального компонента табов - FormTab
 * @component
 *
 * @example
 * <FormWithTabs
    :formConfig="formConfig"
    @submit="handleSubmit"
  >
    <template v-slot:tab-content="{ tabContent, formState, handleInputFunc, currentTabName }">
      <!-- содержимое каждого таба -->
      <APProfileFormTabContent
        :tabContent="tabContent"
        :formState="formState"
        :currentTabName="currentTabName"
        @update:inputValue="handleInputFunc"
      />
    </template>
    <!-- кнопка сабмит -->
    <template v-slot:control-buttons>
      <WButton type="reset" info>
        Reset data
      </WButton>
      <WButton type="submit" success>
        Send data
      </WButton>
    </template>
  </FormWithTabs>
 */

import {
  FormTab
} from './components';

import {
  createFormState
} from './utils';

export default {
  name: 'FormWithTabs',
  components: {
    FormTab
  },
  props: {
    formConfig: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      formState: {},
      currentTab: 0,
      tabsWithErrors: []
    };
  },
  computed: {
    withCustomControlButtons() {
      return Boolean(this.$slots?.['control-buttons']?.[0]);
    }
  },
  watch: {
    'errors.items': {
      handler(newVal, oldVal) {
        this.checkIfThisTabError(newVal);
      }
    }
  },
  methods: {
    checkIfThisTabError(errors) {
      // получить все поля включая из вложенных скоупов vee-validate
      // https://vee-validate.logaretm.com/v2/examples/scopes.html
      function getAllFieldNames(fields) {
        return Object.keys(fields).reduce((acc, key) => {
          if (key.startsWith('$')) {
            // Рекурсивно собираем ключи вложенных свойств
            const nestedFieldNames = getAllFieldNames(fields[key]);
            return acc.concat(nestedFieldNames);
          }

          return acc.concat(key);
        }, []);
      }

      const veeFieldNames = getAllFieldNames(this.veeFields);

      const errorInputsFieldNames = errors
        .map(err => err.field)
        .filter(name => veeFieldNames.includes(name));

      if (!errorInputsFieldNames.length) {
        this.tabsWithErrors = [];

        return;
      }

      // 1. Разделяем каждую строку на подмассивы, исключая последнее слово
      const splitArrays = errorInputsFieldNames.map(item => {
        const parts = item.split('.');
        parts.pop(); // Исключаем последнее слово, тк это name самого инпута
        return parts;
      });

      // 2. Создаем плоский массив
      const flatArray = splitArrays.flat();

      // 3. Удаляем дубликаты
      const uniqueArray = [...new Set(flatArray)];

      this.tabsWithErrors = uniqueArray;
    },
    handleTabClick(num) {
      this.currentTab = num;
    },
    handleInput({ inputName, value }) {
      this.formState = {
        ...this.formState,
        [inputName]: value
      };
    },
    async validateForm() {
      // vee-validate не может провалидировать все поля разом включая те, которые в скоупах
      // по умолчанию this.$validator.validateAll() валидирует только те поля, которые без скоупа
      // this.$validator.validateAll('scopeName') - валидирует все поля конкретного скоупа
      // необходимо получить все скоупы и провалидировать все поля независимо от скоупов

      function getAllVeeValidateScopes(veeValidateValidator) {
        // Извлекаем все уникальные скоупы из vee-validator fields
        const allFieldsScopes = veeValidateValidator.fields.items.map(field => field.scope);

        const scopes = Array.from(new Set(allFieldsScopes));
        return scopes;
      }

      const allVeeValidateScopes = getAllVeeValidateScopes(this.$validator);

      // Создаем массив промисов для валидации всех скоупов
      const validationPromises = allVeeValidateScopes.map(scopeName => {
        return this.$validator.validateAll(scopeName);
      });

      const validationResults = await Promise.all(validationPromises);

      // Проверяем, все ли промисы вернули true
      const isFormValid = validationResults.every(result => result);

      return isFormValid;
    },
    async handleSubmit() {
      const isFormValid = await this.validateForm();

      if (!isFormValid) {
        return;
      }

      this.$emit('submit', this.formState);
    }
  },
  created() {
    const formState = createFormState(this.formConfig);
    this.formState = formState;
  }
};
</script>

<style lang="css" scoped>
  .form-with-tabs {
    flex: 1 0 100%;
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  .form-with-tabs__content {
    flex: 1 0 auto;
  }

  .form-with-tabs__footer {
    flex: 0 0 auto;
  }
</style>
