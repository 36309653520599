import store from '@/store';
import { AuthTokenMaster } from '@/utils';

/**
 * Переключает в состояние isAuthenticated модуля vuex - auth.
 * Если статус равен true, также устанавливает переданный токен доступа.
 * Если статус равен false, очищает токены аутентификации.
 *
 * @param {Object} options - Параметры функции.
 * @param {boolean} options.status - Новый статус для установки в isAuthenticated.
 * @param {string|undefined} options.token - Токен доступа для установки (только если статус true).
 * @returns {void} - Функция не возвращает значения.
 */
const setIsAuthenticatedStatus = ({ status, token }) => {
  store.commit('auth/setIsAuthenticatedStatus', status);

  if (status) {
    AuthTokenMaster.setAccessToken(token);
  }

  if (!status) {
    AuthTokenMaster.clearTokens();
  }
};

export {
  setIsAuthenticatedStatus
};
