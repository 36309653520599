// миксин, позволяющий обернуть выполнение любой функции в debounce (добавить ей задержку выполнения).

const debounceMixin = {
  data() {
    return {
      debounceTimer: null
    };
  },
  methods: {
    debounce(callback, delay) {
      clearTimeout(this.debounceTimer);
      this.debounceTimer = setTimeout(callback, delay);
    }
  }
};

export default debounceMixin;
