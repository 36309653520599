<template>
  <Teleport :to="'#portal-target'">
    <Transition name="fade">
      <div class="w-modal-wrapper"
        :class="[viewTypeClass, highOrderedModal && 'high-ordered']"
        v-if="isOpen"
        v-bind="$attrs"
        role="dialog"
        @click="handleClick"
      >
        <div class="w-modal-card"
          @click.stop
        >
          <slot></slot>
        </div>
      </div>
    </Transition>
  </Teleport>
</template>

<script>

/**
* UI-компонент WModalWindow.
* Используется во всем проекте. Регистрируется глобально (в конкретном компоненте регистрировать не нужно).
*
* @summary
* Этот компонент отвечает только за:
* 1. правильное расположение модалки с помощью телепорта
* 2. правильное позиционирование
* 3. фон
* 4. анимацию
* 5. закрытие по клику на фон
* 6. Эмит сообщения о закрытии модалки при autologout юзера
*
* По парадигме атомарного дизайна этот компонент является атомом.
* Рекомендуется использовать его как строительную основу для более специфичных компонентов. (см пример - WModalContent)
*
* Поэтому используйте этот компонент тогда, когда вам нужно сделать кастомную модалку с нестандартным содержанием контента:
* @example
* Пример: компонент модалки с кастомным нестандартным содержанием
* ! (В подавляющем большинстве вам подойдет WModalContent. Даже в этом подойдет WModalContent. Пример просто для демонстрации использования).
* <template>
    <WModalWindow info
      @close="$emit('close', $event)"
      v-bind="$attrs"
    >
      <div class="my-specific-content">
        <h4>See our video</h4>
        <video></video>
        <button>okay</button>
      </div>
    </WModalWindow>
  </template>
*
* ! Если вам нужна стандартная модалка (99% случаев), то используйте соседний компонент <WModalContent/>.
* В нем уже есть предустановленные и настроенные слоты header, body, footer, которые позволят создать стандартную модалку.
*
* @typedef {Object} WModalWindow
* @prop {boolean} isOpen - Флаг, указывающий на открытие/закрытие модального окна.
* @prop {boolean} highOrderedModal - Флаг, указывающий, что текущее окно приоритетнее других: должно быть открыто поверх всех остальных.
* @prop {boolean} outsideContentClickClosing - Флаг, указывающий, что при клике вне контентной части модальное окно будет закрываться.
* @prop {boolean} [primary=true] - Флаг, указывающий на использование стиля "primary".
* @prop {boolean} [secondary=false] - Флаг, указывающий на использование стиля "secondary".
* @prop {boolean} [info=false] - Флаг, указывающий на использование стиля "info".
* @prop {boolean} [success=false] - Флаг, указывающий на использование стиля "success".
* @prop {boolean} [warning=false] - Флаг, указывающий на использование стиля "warning".
* @prop {boolean} [danger=false] - Флаг, указывающий на использование стиля "danger".
*
* @event close - Событие, срабатывающее при закрытии модального окна.
*/

import { createNamespacedHelpers } from 'vuex';

const {
  mapGetters: autologoutGetters
} = createNamespacedHelpers('autologout');

export default {
  name: 'WModalWindow',
  inheritAttrs: false, // Отключаем наследование атрибутов по умолчанию
  props: {
    isOpen: {
      type: Boolean,
      required: true
    },
    highOrderedModal: {
      type: Boolean,
      default: false
    },
    outsideContentClickClosing: {
      type: Boolean,
      default: false
    },
    // view types
    primary: {
      type: Boolean,
      default: true
    },
    secondary: {
      type: Boolean,
      default: false
    },
    info: {
      type: Boolean,
      default: false
    },
    success: {
      type: Boolean,
      default: false
    },
    warning: {
      type: Boolean,
      default: false
    },
    danger: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...autologoutGetters(['isUserSessionEnded']),
    viewTypeClass() {
      if (this.secondary) {
        return 'secondary';
      }

      if (this.info) {
        return 'info';
      }

      if (this.success) {
        return 'success';
      }

      if (this.warning) {
        return 'warning';
      }

      if (this.danger) {
        return 'danger';
      }

      if (this.primary) {
        return 'primary';
      }

      return '';
    }
  },
  watch: {
    /**
     * Если сессия юзера закончилась, модалка должна закрыться
     */
    isUserSessionEnded(newValue) {
      if (newValue) {
        this.emitCloseEvent();
      }
    }
  },
  methods: {
    handleClick(event) {
      if (!this.outsideContentClickClosing) {
        return;
      }

      this.emitCloseEvent(event);
    },
    emitCloseEvent(event) {
      this.$emit('close', event);
    }
  }
};
</script>

<style lang="css" scoped>
  .w-modal-wrapper {
    position: fixed;
    inset: 0;
  }

  .w-modal-wrapper::before {
    content: '';
    display: block;
    position: absolute;
    inset: 0;
    background-color: var(--modal-wrapper-bg-color);
    opacity: .5;
  }

  .w-modal-card {
    max-width: 800px;
    width: auto;
    margin: 30px auto;

    position: relative;
    overflow: hidden;

    background-color: var(--modal-content-bg-color);
    border: 1px solid;
    border-radius: var(--modal-border-radius);
  }

  /* type classes */
  .primary .w-modal-card {
    border-color: var(--modal-primary);
  }

  .secondary .w-modal-card {
    border-color: var(--modal-secondary);
  }

  .info .w-modal-card {
    border-color: var(--modal-info);
  }

  .success .w-modal-card {
    border-color: var(--modal-success);
  }

  .warning .w-modal-card {
    border-color: var(--modal-warning);
  }

  .danger .w-modal-card {
    border-color: var(--modal-danger);
  }

  .high-ordered {
    z-index: 10000;
  }
</style>
