/* eslint-disable consistent-return */
import store from '@/store';
import { AXIOS_INSTANCE, POST_CHANGE_PASSWORD_URL } from '../config';

/**
 * Функция-фетчер для отправки запроса на смену пароля юзера.
 *
 * @param {Object} credentials - данные для аутентификации.
 * @param {string} credentials.username - логин.
 * @param {string} credentials.oldPassword - текущий пароль.
 * @param {string} credentials.newPassword - новый пароль.
 * @returns {Promise<Object>} - Обещание с данными ответа.
 * @throws {Error} - Ошибка при выполнении запроса.
 */

const changePasswordFetcher = async (credentials) => {
  if (!credentials) {
    throw new Error('credentials must be set');
  }

  const updateUserPasswordQuery = {
    username: credentials.username,
    oldpassword: credentials.oldPassword,
    password: credentials.newPassword
  };

  try {
    const response = await AXIOS_INSTANCE.post(POST_CHANGE_PASSWORD_URL, updateUserPasswordQuery);

    const { data } = response;

    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};

/**
 * фукнция-обработчик фетчера changePasswordFetcher.
 * выполняет побочные действия и обрабатывает тело запроса.
 *
 * @param {Object} credentials - данные для аутентификации.
 * @param {string} credentials.username - логин.
 * @param {string} credentials.oldPassword - текущий пароль.
 * @param {string} credentials.newPassword - новый пароль.
 * @param {Object} [options] - Дополнительные опции запроса.
 * @param {Function} [options.onLoading] - Callback при начале выполнения запроса.
 * @param {Function} [options.onSuccess] - Callback при успешном выполнении запроса.
 * @param {Function} [options.onError] - Callback при ошибке выполнения запроса.
 * @returns {Promise<Object>} - Обещание с данными ответа.
 * @throws {Error} - Ошибка при выполнении запроса.
 */
const changePassword = async (credentials, options) => {
  const {
    onLoading,
    onSuccess,
    onError
  } = options;

  try {
    if (onLoading) {
      onLoading();
    }

    store.commit('setLoading');

    const response = await changePasswordFetcher(credentials);

    const { data, status, description } = response;

    if (status === 'error') {
      throw new Error(description);
    }

    if (status === 'success' && onSuccess) {
      onSuccess(data);
    }

    if (status === 'success') {
      store.commit('setSuccess');

      return Promise.resolve(data);
    }
  } catch (error) {
    if (onError) {
      onError(error.message);
    }
    store.commit('setError', error.message);
  }
};

export default changePassword;
