import { executeRouterGuards, isExperimentalModeGuard } from './navigationGuards';

const Statistics = () => import(/* webpackChunkName: "Statistics" */ '../views/Statistics/Statistics');
const Statsystem = () => import(/* webpackChunkName: "Statsystem" */ '../views/Statistics/Statsystem');
const Statclients = () => import(/* webpackChunkName: "Statclients" */ '../views/Statistics/Statclients/Statclients.vue');
const Statcpes = () => import(/* webpackChunkName: "Statcpes" */ '../views/Statistics/Statcpes/Statcpes.vue');
const Statwlans = () => import(/* webpackChunkName: "Statwlans" */ '../views/Statistics/Statwlans');
const Statexport = () => import(/* webpackChunkName: "Statexport" */ '../views/Statistics/Statexport');
const Statreports = () => import(/* webpackChunkName: "Statreports" */ '../views/Statistics/Statreports');
const SNMPStat = () => import(/* webpackChunkName: "SNMPStat" */ '../views/Statistics/SNMPStat');

const StatisticsRoute = {
  path: 'statistics',
  name: 'Statistics',
  redirect: 'statistics/statcpes',
  component: Statistics,
  children: [
    {
      path: 'statsystem',
      name: 'Statsystem',
      component: Statsystem
    },
    {
      path: 'statcpes',
      name: 'Statcpes',
      component: Statcpes
    },
    {
      path: 'statwlans',
      name: 'Statwlans',
      component: Statwlans
    },
    {
      path: 'statclients',
      name: 'Statclients',
      component: Statclients
    },
    {
      path: 'statexport',
      name: 'Statexport',
      component: Statexport
    },
    {
      path: 'statreports',
      name: 'Statreports',
      component: Statreports
    },
    {
      path: 'snmpstat',
      name: 'SNMPStat',
      component: SNMPStat,
      beforeEnter: executeRouterGuards(isExperimentalModeGuard, { name: 'Dashboard' })
    }
  ]
};

export default StatisticsRoute;
