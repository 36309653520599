<template>
  <div class="row h-100">
    <div class="col-lg-12 h-100 position-relative">
      <loader-spinner
        class="card h-100"
        centered
        v-if="iswlanWizardGoingToLastStepAfterClickSkipButtonInProgess"
      ></loader-spinner>
      <div v-else class=" h-100">
        <div class="card-block">
<!--          <div class="h4 m-0">-->
<!--            {{ $t('general.step6') }}-->
<!--            <button-->
<!--              type="button"-->
<!--              class="btn btn-sm float-right btn-outline-info skip-button-in-wlan-wizard new-modern-style-btn-other-btn"-->
<!--              @click="lastStepButtonClickHandler"-->
<!--            >-->
<!--              {{ $t('wlans.wizardSkipButton') }}-->
<!--              <info :content="$t('wlans.wizardSkipButtonInfo')" />-->
<!--            </button>-->
<!--          </div>-->
          <span class="text-muted step-caption-in-wlan-wizard">{{ $t('wlans.wizardSpeedControlSettings') }}</span>
          <form action="">
            <div class="mt-1">
              <div class="form-group">
                <label for="speed_download">{{ $t('wlans.speedDownload') }} ({{ $t('wlans.speedDownloadKbs')}})</label>
                <input
                  type="text"
                  id="speed_download"
                  :data-vv-as="$t('wlans.speedDownload')"
                  v-validate="'between: 8,10000000'"
                  name="speed_download"
                  :placeholder="$t('wlans.speedDownloadPlaceholder')"
                  class="form-control"
                  v-model.number="newWlanData.speed_download.value"
                  :class="{ input: true, 'is-danger': errors.has('speed_download') }"
                />
                <span v-show="errors.has('speed_download')" class="help is-danger">
                  {{ errors.first('speed_download') }}
                </span>
              </div>

              <div class="form-group">
                <label for="speed_upload">{{ $t('wlans.speedUpload') }} ({{ $t('wlans.speedUploadKbs') }})</label>
                <input
                  type="text"
                  :class="{ input: true, 'is-danger': errors.has('speed_upload') }"
                  :data-vv-as="$t('wlans.speedUpload')"
                  v-validate="'between: 8,10000000'"
                  id="speed_upload"
                  name="speed_upload"
                  :placeholder="$t('wlans.speedUploadPlaceholder')"
                  class="form-control"
                  v-model.number="newWlanData.speed_upload.value"
                />
                <span v-show="errors.has('speed_upload')" class="help is-danger">
                  {{ errors.first('speed_upload') }}
                </span>
              </div>
            </div>
            <!--            <button-->
            <!--              type="button"-->
            <!--              class="btn btn-outline-success float-right"-->
            <!--              @click="setNewWlanData"-->
            <!--            >-->
            <!--              {{ $t('general.next') }}-->
            <!--            </button>-->
            <!--            <button type="button" class="btn btn-outline-secondary float-left"-->
            <!--                    @click="this.$parent.prevStep">-->
            <!--              {{ $t('general.back') }}-->
            <!--            </button>-->
          </form>
        </div>
<!--        <div class="buttons-block-warpper">-->
<!--          <button type="button" class="btn btn-outline-success float-right" @click="setNewWlanData">-->
<!--            {{ $t('general.next') }}-->
<!--          </button>-->
<!--          &lt;!&ndash;          <button type="button" class="btn btn-outline-info float-right mr-h"&ndash;&gt;-->
<!--          &lt;!&ndash;                  @click="lastStepButtonClickHandler">&ndash;&gt;-->
<!--          &lt;!&ndash;            {{ $t('wlans.wizardSkipButton') }}&ndash;&gt;-->
<!--          &lt;!&ndash;            <info :content="$t('wlans.wizardSkipButtonInfo')"/>&ndash;&gt;-->
<!--          &lt;!&ndash;          </button>&ndash;&gt;-->
<!--          <button type="button" class="btn btn-outline-secondary float-left" @click="this.$parent.prevStep">-->
<!--            {{ $t('general.back') }}-->
<!--          </button>-->
<!--        </div>-->
      </div>
    </div>
  </div>
</template>

<script>
import Info from '../../../../components/Universal/info-icon.vue';

export default {
  name: 'SpeedControlTab',
  components: { Info },
  data() {
    return {
      // enableSaveChanges: true,
      newWlanData: {
        security: {
          data: {
            suites: [],
            psk: ''
          }
        }
      }
      // showWlanPass: false,
      // updatedWlanRadiusAuthentication: [],
      // updatedWlanRadiusAccounting: []
    };
  },
  computed: {
    iswlanWizardGoingToLastStepAfterClickSkipButtonInProgess() {
      return this.$store.state.wlanWizardGoingToLastStepAfterClickSkipButtonInProgess;
    }
  },
  methods: {
    lastStepButtonClickHandler(event) {
      this.setNewWlanData(event, true);
    },
    // enableSave() {
    //   this.enableSaveChanges = true;
    // },
    // setNewWlanData(event, toLastStepClicked = false) {
    //   this.$store.commit('setNewWlanData', this.newWlanData);
    //   this.$validator.validateAll().then(
    //     (result) => {
    //       if (result) {
    //         if (this.newWlanData.speed_download.value === '') {
    //           this.newWlanData.speed_download.value = 0;
    //         }
    //         if (this.newWlanData.speed_upload.value === '') {
    //           this.newWlanData.speed_upload.value = 0;
    //         }
    //         // this.$parent.nextStep();
    //         if (toLastStepClicked === false) {
    //           this.$parent.nextStep();
    //         } else {
    //           // this.$parent.nextStep(true);
    //           this.$parent.lastStep();
    //         }
    //       }
    //     },
    //     () => {
    //       console.warn('Validation failed');
    //     }
    //   );
    // }
    validateAndSetNewWlanData(currentTab, nextTab){
      this.setNewWlanData(currentTab, nextTab)
    },

    setNewWlanData(currentTab, nextTab) {
      this.$store.commit('setNewWlanData', this.newWlanData);
      this.$validator.validateAll().then(
        (result) => {
          if (result) {
            // this.$parent.nextStep();
            this.$emit('dataIsValid', { nextTab: nextTab, currentTab:  currentTab})
          } else {
            this.$emit('dataNoValid', { nextTab: nextTab, currentTab:  currentTab })
          }
        },
        () => {
          this.$emit('dataNoValid', { nextTab: nextTab, currentTab:  currentTab })
          console.warn('Validation failed');
        }
      );
    }
  },
  created() {
    // radiusService.getRadius(this);
    // this.radiusList = this.$store.state.radiusList;
    this.newWlanData = JSON.parse(JSON.stringify(this.$store.state.newWlanData));

    // проверяем не пришли ли мы сюда после нажатия кнопки Пропустить
    if (
      Object.prototype.hasOwnProperty.call(this.$route, 'params') &&
      Object.prototype.hasOwnProperty.call(this.$route.params, 'lastStepClicked') &&
      this.$route.params.lastStepClicked === true
    ) {
      this.lastStepButtonClickHandler();
    }
  }
};
</script>
<style lang="scss">
.input.is-danger .multiselect__tags {
  border-color: #ff3860;
}

.input-group {
  z-index: 1;
}
</style>
<style scoped>
.buttons-block-warpper {
  position: absolute;
  bottom: 1.25rem;
  width: 100%;
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}
.skip-button-in-wlan-wizard {
  font-size: 0.75rem;
}
</style>
