<template>
  <div class="card category-profit">
    <div class="card-body">
      <div class="h1 text-right mb-4" :class="iconClasses">
        <slot name="icon"></slot>
      </div>
      <slot name="body">
        <div class="card-summary m-0">
          <div class="h4 mb-0 mt-2 value--big">
            <span>{{ data }}</span>
          </div>
          <small class="text-uppercase value--title font-weight-bold text-muted">
            {{ dataCaption }}
          </small>
          <div class="h4 mb-0 mt-h value--big text-muted-light">
            <small></small>
          </div>
        </div>
      </slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'stats-card',
  props: {
    data: { type: [String, Number], default: '—' },
    dataCaption: { type: String },
    iconClasses: { type: [Array, String], default: () => ['text-muted-light'] }
  }
};
</script>

<style lang="scss" scoped>
.category-profit {
  flex-grow: 1;
  margin-top: 20px;
}
.card.card-new {
  border-top: 4px solid #81c784;
}

.card.card-returned {
  border-top: 4px solid rgba(255, 143, 7, 0.8);
}

.card.card-unique {
  border-top: 4px solid rgb(124, 181, 236);
}

.card.card-default {
  border-top: 4px solid rgba(183, 183, 183, 0.5);
}

.value {
  font-size: 1.7rem;

  &--big {
    font-size: 2.4rem;
  }

  &--large {
    font-size: 2rem;
  }

  &--title {
    font-size: 0.9rem;
  }
}

.text-muted {
  color: #94a0b2 !important;
}

.text-muted-light {
  color: #bcc5d0 !important;
}
</style>
