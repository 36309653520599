import { v4 as uuidv4 } from 'uuid';

function showMainSectionsTroubleshootingGeneral({ thisInstance, $can }) {
  if (thisInstance.isPortalOnlyModeEnabled) {
    return false;
  }

  return $can('read', { route: 'Events' });
}

/**
 * Описание ролей:
 * роли нужны для построения иерархичности + для специфичного отображения элементов в соответствии с ролью.
 *
 * root - это начало категории. Может быть ссылкой без вложенных элементов, так и не ссылкой с вложенными элементами.
 * section - подраздел. Не является ссылкой. Имеет вложенные элементы children, которые являются ссылкой (обычно role: link). Используется как группировка раздела ссылок и отображается как заголовок.
 * link - конечный элемент пути - ссылка. Не имеет children
 * @param {'root' | 'section' | 'link'} role
 */

const getTroubleshooting = ({ $t, thisInstance, $can }) => (
  {
    id: uuidv4(),
    name: 'troubleshooting',
    title: $t('sidebarNew.troubleshooting'),
    icon: 'fa fa-bug fa-fw fa-lg',
    to: false,
    selected: false,
    role: 'root',
    isShow: showMainSectionsTroubleshootingGeneral({ thisInstance, $can }),
    children: [
      {
        id: uuidv4(),
        name: 'events',
        title: $t('sidebarNew.events'),
        icon: '',
        to: '/monitor/events',
        selected: false,
        role: 'link',
        isShow: $can('read', { route: 'Events' }),
        children: []
      },
      {
        id: uuidv4(),
        name: 'logs',
        title: $t('sidebarNew.logs'),
        icon: '',
        to: '/monitor/logs',
        selected: false,
        role: 'link',
        isShow: $can('read', { route: 'Events' }),
        children: []
      },
      {
        id: uuidv4(),
        name: 'reactiveLogs',
        title: $t('sidebarNew.reactiveLogs'),
        icon: '',
        to: '/monitor/reactive-logs',
        selected: false,
        role: 'link',
        isShow: $can('read', { route: 'Events' }),
        children: []
      }
    ]
  }
);

export default getTroubleshooting;
