// ! этот миксин используется в компонентах, где нужно:
// синхронизироваться с логикой SearchBar.vue
// и/или синхронизировать стейт локального инпута с searchQuery роутинга

// содержит в себе логику по взаимодействию с поисковым запросом из searchQuery

// необходимо:
// 1. примешать миксин к компоненту
// 2. в хуке created/mounted подписать компонент через колбэк на начальное значение через вызов subscribeOnSearchBarInit.
// 3. в хуке mounted подписать компонент через колбэк на обновление состояния через subscribeOnSearchBarUpdate

// 4 (optional) если нужно синхронизировать какой-то локальный инпут-стейт с searchQuery
// то используй методы сброса и обновления searchQuery параметра: resetSearchBarQuery, setSearchBarQuery

// пример использования:

/*
...
mounted() {
    * init callback
    function initFilterQueryHandler(newSearchBarQuery) {
      if (newSearchBarQuery) {
        * load component data with filter by newSearchBarQuery:
        this.fetchMyData({ filterQuery: newSearchBarQuery });
      } else {
        * to do smth else...
        * ex. load component data without any filters
        this.fetchMyData();
      }
    }

    * update callback with searchQuery param
    function updateFilterQueryHandler(newSearchBarQuery) {
      * do somth with new searchQuery param
      * ex. reload component data with new filter by newSearchBarQuery
      this.fetchMyData({ filterQuery: newSearchBarQuery });
    }

    * subscribe callbacks
    this.subscribeOnSearchBarInit(initFilterQueryHandler);
    this.subscribeOnSearchBarUpdate(updateFilterQueryHandler);
}
*/

const searchBarMixin = {
  data() {
    return {
    };
  },
  methods: {
    // Удаляет параметр searchQuery из адресной строки
    resetSearchBarQuery() {
      const { searchQuery } = this.$route.query;

      if (!searchQuery) {
        return;
      }

      const currentRoute = this.$route;

      const query = { ...currentRoute.query };

      delete query.searchQuery;

      this.$router.push({ query });
    },
    // обновляет параметр searchQuery в адресной строке, если он поменялся
    setSearchBarQuery(newQuery) {
      const { searchQuery } = this.$route.query;

      if (searchQuery === newQuery) {
        return;
      }

      this.$router.push({ query: { searchQuery: newQuery } });
    },
    // подписка на изначальное значение searchQuery при инициализации компонента
    // нужен чтобы синхронизировать начальные значения компонента с searchQuery
    // срабатывает 1 раз и уничтожается
    // вызывать в mounted/created хуке компонента
    subscribeOnSearchBarInit(callback) {
      this.$once('initSearchBarQuery', callback);
    },
    // подписка на обновления searchQuery
    // нужен чтобы обновлять значения компонента при изменении searchQuery
    // вызывать в mounted хуке компонента
    subscribeOnSearchBarUpdate(callback) {
      this.$on('updateSearchBarQuery', callback); // Подписываемся на событие

      // Отписываемся от события при уничтожении компонента
      this.$once('hook:beforeDestroy', () => {
        this.$off('updateSearchBarQuery', callback);
      });
    }
  },
  watch: {
    '$route.query.searchQuery': {
      handler(newSearchQuery) {
        this.$emit('updateSearchBarQuery', newSearchQuery);
      }
    }
  },
  created() {
    const { searchQuery } = this.$route.query;

    this.$nextTick(() => {
      this.$emit('initSearchBarQuery', searchQuery);
    });
  }
};

export default searchBarMixin;
