import Vue from 'vue';
import VueNotifications from 'vue-notifications';
import firewallPollingService from './firewallPollingService';
import { API_URL } from '@/config';

export default {
  // getFirewalls(ctx) {
  //   ctx.$store.commit('toggleLoadingFirewalls');
  //   Vue.axios.post(`${API_URL || ''}/api/network/l2chains`, { action: 'R' }).then(
  //     (response) => {
  //       if (response.data.status === 'success') {
  //         ctx.$store.commit('setFirewallsList', response.data.data.itemslist);
  //       } else if (response.data.status === 'error') {
  //         VueNotifications.error({ message: response.data.description });
  //       }
  //       // ctx.clearFields();
  //       ctx.$store.commit('toggleLoadingFirewalls');
  //     },
  //     (err) => {
  //       // VueNotifications.error({ message: err });
  //
  //       // show errors in the console instead of the alert window
  //       if (
  //         Object.prototype.hasOwnProperty.call(window, 'isShowAxiosErrorInConsole') &&
  //         typeof window.isShowAxiosErrorInConsole === 'function' &&
  //         window.isShowAxiosErrorInConsole(err)
  //       ) {
  //         window.showAxiosErrInConsole(err);
  //       } else {
  //         VueNotifications.error({ message: err });
  //       }
  //
  //       ctx.$store.commit('toggleLoadingFirewalls');
  //       ctx.error = err;
  //     }
  //   );
  // },

  getFirewalls(ctx, useLocationFilter= false) {
    ctx.$store.commit('toggleLoadingFirewalls');

    const isUseLocationFilters = useLocationFilter;

    const params = {
      action: 'R',
      location: ctx.$store.getters.getDefaultLocationForRequests(ctx), with_childs: ctx.$store.getters.getDefaultWithChildsForRequests(ctx)
    }
    if (ctx.filters?.location && isUseLocationFilters) {
      if (ctx.filters.location === 'All locations') {
        params.location = ctx.$store.state.requestBaseUserLocationIfNoSelected ? ctx.userBaseLocation : undefined;
        params.with_childs = true;
      } else {
        params.location = ctx.filters.location;
        params.with_childs = ctx.filters.with_childs;
      }
    }

    Vue.axios.post(`${API_URL || ''}/api/network/l2chains`, params).then(
      (response) => {
        if (response.data.status === 'success') {
          ctx.$store.commit('setFirewallsList', response.data.data.itemslist);
        } else if (response.data.status === 'error') {
          VueNotifications.error({ message: response.data.description });
        }
        // ctx.clearFields();
        ctx.$store.commit('toggleLoadingFirewalls');
      },
      (err) => {
        // VueNotifications.error({ message: err });

        // show errors in the console instead of the alert window
        if (
          Object.prototype.hasOwnProperty.call(window, 'isShowAxiosErrorInConsole') &&
          typeof window.isShowAxiosErrorInConsole === 'function' &&
          window.isShowAxiosErrorInConsole(err)
        ) {
          window.showAxiosErrInConsole(err);
        } else {
          VueNotifications.error({ message: err });
        }

        ctx.$store.commit('toggleLoadingFirewalls');
        ctx.error = err;
      }
    );
  },

  updateFirewall(ctx) {
    ctx.updatedFirewall.rules.forEach((rule) => {
      if (!(rule.ip_protocol === 'tcp' || rule.ip_protocol === 'udp')) {
        rule.ip_destination_port = [];
        rule.ip_source_port = [];
      }
      if (rule.protocol !== 'ipv4') {
        rule.ip_destination = [];
        rule.ip_source = [];
      }
    });

    const updateFirewallQuery = {
      action: 'U',
      items: { [ctx.updatedFirewall.id]: ctx.updatedFirewall }
    };
    Vue.axios.post(`${API_URL || ''}/api/network/l2chains`, updateFirewallQuery).then(
      (response) => {
        if (response.data.status === 'success') {
          firewallPollingService.hadleOperationResponse(response.data.data.items);
          ctx.refreshFirewallsList();
        } else if (response.data.status === 'error') {
          VueNotifications.error({ message: response.data.description });
        }
        ctx.disableEditMode();
        ctx.clearFields();
      },
      (err) => {
        // VueNotifications.error({ message: err });

        // show errors in the console instead of the alert window
        if (
          Object.prototype.hasOwnProperty.call(window, 'isShowAxiosErrorInConsole') &&
          typeof window.isShowAxiosErrorInConsole === 'function' &&
          window.isShowAxiosErrorInConsole(err)
        ) {
          window.showAxiosErrInConsole(err);
        } else {
          VueNotifications.error({ message: err });
        }

        ctx.error = err;
      }
    );
  },
  deleteFirewall(ctx) {
    const deleteWlanQuery = { action: 'D', items: { [ctx.firewallIdToDelete]: {} } };
    Vue.axios.post(`${API_URL || ''}/api/network/l2chains`, deleteWlanQuery).then(
      (response) => {
        if (response.data.status === 'success') {
          ctx.templateForDelete = '';
          firewallPollingService.hadleOperationResponse(response.data.data.items);
          ctx.refreshFirewallsList();
          ctx.clearFields();
        } else if (response.data.status === 'error') {
          VueNotifications.error({ message: response.data.description });
        }
        ctx.$store.commit('setFirewallEditId', false);
        ctx.deletingFirewallModal = false;
      },
      (err) => {
        // VueNotifications.error({ message: err });

        // show errors in the console instead of the alert window
        if (
          Object.prototype.hasOwnProperty.call(window, 'isShowAxiosErrorInConsole') &&
          typeof window.isShowAxiosErrorInConsole === 'function' &&
          window.isShowAxiosErrorInConsole(err)
        ) {
          window.showAxiosErrInConsole(err);
        } else {
          VueNotifications.error({ message: err });
        }

        ctx.error = err;
      }
    );
  }
};
