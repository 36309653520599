<template>
  <div class="row" v-if="this.$store.state.userData.role !== 'operator'">
    <!--    <loader-spinner centered v-if="iswlanWizardGoingToLastStepAfterClickSkipButtonInProgess"></loader-spinner>-->
    <div class=" card-in-new-wlan-wizard mb-1 ml-1" >
      <div class="card card-inverse card-success card-in-new-wlan-wizard">
        <div class="card-block d-flex flex-column justify-content-center align-items-center">
          <div class="h1 text-muted text-left mb-2">
            <img class="img img-responsive wizard-wlan-icon" :src="'/static/img/wifi1.svg'" alt="" />
          </div>
          <div class="h4 mb-0">{{ $t('wlans.wlanWizardV2') }}</div>
        </div>
      </div>
    </div>
    <div class="col-lg-7 mb-1">
      <div class="card d-flex flex-column justify-content-start align-items-start">
        <div class="custom-tabs-wrapper-in-wlans-wizard">
          <ul class="nav nav-tabs row m-0" role="tablist">
            <li class="nav-item">
              <a
                class="nav-link"
                role="tab"
                :class="{ active: currentTabStep === 'generalTab' }"
                @click="setCurrentTabToStepN('generalTab')"
              >
                {{ $t('wlans.tabGeneral') }}
              </a>
            </li>
            <li class="nav-item">
              <a
                class="nav-link"
                role="tab"
                :class="{ active: currentTabStep === 'securityTab' }"
                @click="setCurrentTabToStepN('securityTab')"
              >
                {{ $t('wlans.tabSecurity') }}
              </a>
            </li>
            <li class="nav-item">
              <a
                class="nav-link"
                role="tab"
                :class="{ active: currentTabStep === 'advancedTab' }"
                @click="setCurrentTabToStepN('advancedTab')"
              >
                {{ $t('wlans.tabAdvanced') }}
              </a>
            </li>
<!--            <li class="nav-item">-->
<!--              <a-->
<!--                class="nav-link"-->
<!--                role="tab"-->
<!--                :class="{ active: currentTabStep === 'stepDHCPOption82' }"-->
<!--                @click="setCurrentTabToStepN('stepDHCPOption82')"-->
<!--              >-->
<!--                {{ $t('wlans.dhcpOption82') }}-->
<!--              </a>-->
<!--            </li>-->
<!--            <li class="nav-item">-->
<!--              <a-->
<!--                class="nav-link"-->
<!--                role="tab"-->
<!--                :class="{ active: currentTabStep === 'step6' }"-->
<!--                @click="setCurrentTabToStepN('step6')"-->
<!--              >-->
<!--                {{ $t('wlans.wizardSpeedControlSettings') }}-->
<!--              </a>-->
<!--            </li>-->

<!--            <li class="nav-item">-->
<!--              <a-->
<!--                class="nav-link"-->
<!--                role="tab"-->
<!--                :class="{ active: currentTabStep === 'step8' }"-->
<!--                @click="setCurrentTabToStepN('step8')"-->
<!--              >-->
<!--                {{ $t('wlans.wizardMobility') }}-->
<!--              </a>-->
<!--            </li>-->
<!--            <li class="nav-item">-->
<!--              <a-->
<!--                class="nav-link"-->
<!--                role="tab"-->
<!--                :class="{ active: currentTabStep === 'step9' }"-->
<!--                @click="setCurrentTabToStepN('step9')"-->
<!--              >-->
<!--                {{ $t('wlans.wizardWMM') }}-->
<!--              </a>-->
<!--            </li>-->
          </ul>
        </div>
        <div class="w-100 d-flex justify-content-start">
          <div class="w-75" :class="{'w-100': currentTabStep === 'advancedTab'}">
            <!--              Step 1-->
            <GeneralTab ref="generalTab" @dataNoValid="dataIsNoValidHandler"  @dataIsValid="dataIsValidHandler" v-if="currentTabStep === 'generalTab'"></GeneralTab>
            <SecurityTab ref="securityTab" @addNewRedirectClicked="newRedirectAddClickHandler" @addRadiusEditClicked ='editRadiusAddClickHandler' @addNewRadiusClicked="newRadiusAddClickHandler" @dataNoValid="dataIsNoValidHandler" @dataIsValid="dataIsValidHandler" v-if="currentTabStep === 'securityTab'"></SecurityTab>
            <AdvancedTab ref="advancedTab" :selectedSubTab="selectedSubTabForAdvancedTab" @selectSubTab="selectSubTabHandlerForAdvancedTab"  @dataNoValid="dataIsNoValidHandler" @dataIsValid="dataIsValidHandler" v-if="currentTabStep === 'advancedTab'"></AdvancedTab>
<!--            <StepDHCPOption82 ref="stepDHCPOption82" @dataNoValid="dataIsNoValidHandler" @dataIsValid="dataIsValidHandler" v-if="currentTabStep === 'stepDHCPOption82'"></StepDHCPOption82>-->
<!--            <Step6 ref="step6" @dataNoValid="dataIsNoValidHandler" @dataIsValid="dataIsValidHandler" v-if="currentTabStep === 'step6'"></Step6>-->
<!--            <Step8 ref="step8" @dataNoValid="dataIsNoValidHandler" @dataIsValid="dataIsValidHandler" v-if="currentTabStep === 'step8'"></Step8>-->
<!--            <Step9 ref="step9" @dataNoValid="dataIsNoValidHandler" @dataIsValid="dataIsValidHandler" v-if="currentTabStep === 'step9'"></Step9>-->
          </div>
        </div>
        <div class="w-100">
          <WButton success
            customClass="float-right mr-2 mb-2"
            @click="createNewWlanButtonClickHandler"
          >
            {{ $t('general.create') }}
          </WButton>
        </div>


      </div>
    </div>
  </div>
</template>

<script>
import VueNotifications from 'vue-notifications';
import { API_URL } from '@/config';
import GeneralTab from './GeneralTab.vue';
import SecurityTab from './SecurityTab.vue';
import AdvancedTab from './AdvancedTab.vue';
// import Step4 from './Step4.vue';
// import Step5 from './Step5.vue';
// import Step6 from './Step6.vue';
// import Step7 from './Step7.vue';
// import Step8 from './Step8.vue';
// import Step9 from './Step9.vue';
// import StepDHCPOption82 from './StepDHCPOption82.vue';
import wlansPollingService from '../../../services/wlansPollingService';
import { copyAndModifyWLANObjectBeforeSendToAPI } from '../helpers';




export default {
  name: 'WlanWizardV2',
  components: {
    GeneralTab,
    SecurityTab,
    AdvancedTab,
    // Step4,
    // Step5,
    // Step6,
    // Step7,
    // Step8,
    // Step9,
    // StepDHCPOption82
  },
  data() {
    return {
      currentTabStep: 'generalTab',
      selectedSubTabForAdvancedTab: 'dataTrafficManagementTab',
      validatorErrorsInTabs: {
        generalTab: false,
        securityTab: false,
        advancedTab: false,
        // step4: false,
        // step5: false,
        // stepDHCPOption82: false,
        // step6: false,
        // step7: false,
        // step8: false,
        // step9: false
      }
    };
  },
  computed: {
    areValidatorErrors() {
      return Object.values(this.validatorErrorsInTabs).some((item)=>{
        return item === true;
      })
    },


    // iswlanWizardGoingToLastStepAfterClickSkipButtonInProgess() {
    //   return this.$store.state.wlanWizardGoingToLastStepAfterClickSkipButtonInProgess;
    // },
    // currentStepIndex() {
    //   if (this.$route.name === 'StepLast') {
    //     return 'StepLast';
    //   }
    //   return this.$route.name.replace('step', '');
    // },
    // progressWidth() {
    //   let width;
    //   const currentStepRoute = this.$route.name;
    //
    //   switch (currentStepRoute) {
    //     case 'step1':
    //       width = 10;
    //       break;
    //     case 'step2':
    //       width = 20;
    //       break;
    //     case 'step3':
    //       width = 30;
    //       break;
    //     case 'step4':
    //       width = 40;
    //       break;
    //     case 'step5':
    //       width = 50;
    //       break;
    //     case 'step6':
    //       width = 60;
    //       break;
    //     case 'step7':
    //       width = 70;
    //       break;
    //     case 'step8':
    //       width = 80;
    //       break;
    //     case 'step9':
    //       width = 90;
    //       break;
    //     case 'StepLast':
    //       width = 100;
    //       break;
    //     default:
    //       width = 100;
    //   }
    //   return width;
    // }
  },
  methods: {
    selectSubTabHandlerForAdvancedTab(subTabName){
      // console.log(subTabName)
      this.selectedSubTabForAdvancedTab = subTabName
    },
    newRadiusAddClickHandler() {
      this.$store.commit('setStepNameForReturningToCreateWLANWizard', this.currentTabStep)
      this.$store.commit('setStepNameForAdvancedTabForReturningToCreateWLANWizard', this.selectedSubTabForAdvancedTab)

      this.$router.push({name: 'radius-wizard'})
    },
    editRadiusAddClickHandler(emitedPayload){
      this.$store.commit('setStepNameForReturningToCreateWLANWizard', this.currentTabStep)
      this.$store.commit('setStepNameForAdvancedTabForReturningToCreateWLANWizard', this.selectedSubTabForAdvancedTab)
      const radiusName = emitedPayload.radiusName;
      this.$store.commit('setReturnToWlanWizard', true);
      this.$router.push({name: 'Radius', params: {radiusName: radiusName} });
    },
    newRedirectAddClickHandler() {
      this.$store.commit('setStepNameForReturningToCreateWLANWizard', this.currentTabStep)
      this.$store.commit('setStepNameForAdvancedTabForReturningToCreateWLANWizard', this.selectedSubTabForAdvancedTab)

      this.$router.push({name: 'GuestControl-wizard'})
    },
    createNewWlanButtonClickHandler() {
      this.$refs[`${this.currentTabStep}`].validateAndSetNewWlanData(this.currentTabStep, this.currentTabStep)
      setTimeout(() => {
        if (!this.areValidatorErrors) {
          this.confirmNewWlanAndCreate()
        }
      }, 200)
    },
    confirmNewWlanAndCreate() {
      // const transformedQuery = { ... JSON.parse(JSON.stringify(this.$store.state.newWlanData)) };
      const transformedQuery = copyAndModifyWLANObjectBeforeSendToAPI(this.$store.state.newWlanData);
      // console.log(transformedQuery)
      transformedQuery.vlan = parseInt(transformedQuery.vlan);
      if (!transformedQuery.vlan) {
       transformedQuery.vlan = 0;
      }
      // open wlan case
      if (!transformedQuery.security.type) {
        transformedQuery.security = null;
      } else if (transformedQuery.security.type.indexOf('personal') !== -1) {
        delete transformedQuery.security.data.radius_auth;
      } else if (transformedQuery.security.type.indexOf('enterprise') !== -1) {
        delete transformedQuery.security.data.psk;
      }
      this.axios.post(`${API_URL || ''}/api/wlans`, { action: 'C', items: { 0: transformedQuery } }).then(
        (response) => {
          if (response.data.status === 'error') {
            VueNotifications.error({ message: response.data.description });
          }
          this.$store.commit('clearNewWlanData');
          this.$store.commit('setNewWlanWizardSecurityTab', 'L2');
          this.$router.push({ name: 'Wlans' });
          wlansPollingService.hadleOperationResponse(response.data.data.items);
        },
        (err) => {
          VueNotifications.error({ message: err });
          this.error = err;
        }
      );
    },
    dataIsValidHandler(dataObject){
      const nextTab = dataObject.nextTab
      const currentTab = dataObject.currentTab
      this.switchToTab(nextTab)
      this.setNoValidationErrorsForTab(currentTab);
    },
    dataIsNoValidHandler(dataObject){
      const currentTab = dataObject.currentTab
      this.setValidationErrorsForTab(currentTab);
    },
    switchToTab(n) {
      this.currentTabStep = n;
    },
    setValidationErrorsForTab(n){
      console.log('setValidationErrorsForTab', n)
      this.validatorErrorsInTabs[`${n}`] = true;
    },
    setNoValidationErrorsForTab(n){
      this.validatorErrorsInTabs[`${n}`] = false;
    },
    setCurrentTabToStepN(n) {
      const currentTabStep = this.currentTabStep
      if (currentTabStep === n) {
        console.log('here')
        return;
      }
      this.$refs[`${currentTabStep}`].validateAndSetNewWlanData(currentTabStep, n)

    },

    // getStepBlockWidthClass(currentStepIndex) {
    //   return 'col-lg-5';
    // },

    // lastStep() {
    //   this.$store.commit('wlanWizardGoingToLastStepAfterClickSkipButtonStart');
    //   this.$router.push({ name: 'StepLast', params: { lastStepClicked: true } });
    // },
    completeCreation() {
      this.$router.push({ path: '/wlans' });
    }
  },
  created() {
    if (this.$store.state.stepNameForReturningToCreateWLANWizard) {
      this.switchToTab(this.$store.state.stepNameForReturningToCreateWLANWizard);
    }
    if (this.$store.state.stepNameForAdvancedTabForReturningToCreateWLANWizard) {
      this.selectedSubTabForAdvancedTab = this.$store.state.stepNameForAdvancedTabForReturningToCreateWLANWizard;
    }
    this.$store.commit('clearReturnToWlanWizard');
    this.$store.commit('clearStepNameForReturningToCreateWLANWizard');
    this.$store.commit('clearStepNameForAdvancedTabForReturningToCreateWLANWizard');
  }
};
</script>
<style lang="scss">
  .card-in-new-wlan-wizard{
    width: 220px !important;
    height: 220px !important;
  }
.custom-tabs-wrapper-in-wlans-wizard {
  border-bottom: 0px !important;
  width: 100%;
}
.custom-tabs-wrapper-in-wlans-wizard .nav-link {
  /*font-size: 0.85em;*/
  border: 1px solid  rgba(128,128,128, 0.1) !important;
  margin-left: 0.01em !important;
}
.custom-tabs-wrapper-in-wlans-wizard .nav-link:hover {
  background-color: rgba(128,128,128, 0.3) !important;
}
.custom-tabs-wrapper-in-wlans-wizard .nav-item .active {
  /*background-color: rgba(128,128,128, 0.05)   !important;*/
  box-shadow: 2px 2px 3px rgba(128,128,128, 0.5) !important;
}
.custom-tabs-wrapper-in-wlans-wizard .nav-item {
  /*background-color: rgba(128,128,128, 0.05)   !important*/
}
.custom-tabs-wrapper-in-wlans-wizard .nav.nav-tabs.row {
  border-radius: 0px 0px 0px 0px !important;
}
.custom-tabs-wrapper-in-wlans-wizard  ul.nav.nav-tabs li.nav-item a{
  border-radius: 12.5px 12.5px 0px 0px !important;
}

.wizard-wlan-icon {
  height: 80px;
}

.wlan-wizard-steps-wrapper {
  min-height: 655px !important;
}
</style>
