<template>
  <div v-if="props.row.location">
    <div class="aps-table-location-cell-container">
      <span class="aps-table-location-cell-wrapper">
        <b>{{ locationName(props.row.location) }}</b>
      </span>
    </div>
    <div class="aps-table-location-cell-container">
      <span class="aps-table-location-cell-wrapper" v-if="locationPartyGetField(props.row.location, 'name')">
        {{ locationPartyGetField(props.row.location, 'name') }}
      </span>
    </div>
    <div class="aps-table-location-cell-container">
      <span class="aps-table-location-cell-wrapper" v-if="locationPartyGetField(props.row.location, 'inn')">
        {{ $t('location.innCaptionForTable') }}
        {{ locationPartyGetField(props.row.location, 'inn') }}
      </span>
    </div>
  </div>
  <span v-else>-</span>
</template>

<script>
/**
 * компонент - ячейка ip.
 * компонент локальный: используется только в ApsTable.vue
 * @component
 */

import commonService from '@/services/commonService';

export default {
  name: 'LocationCell',
  components: {},
  props: {
    props: {
      type: Object,
      required: true
    }
  },
  methods: {
    locationPartyGetField(id, field) {
      const showLocationPartyFromId = commonService.showLocationPartyFromLocationId(id);
      return showLocationPartyFromId[field];
    },
    locationName(id) {
      const showLocationFromId = commonService.showLocationFromId(id);
      return showLocationFromId;
    }
  }
};
</script>

<style lang="css" scoped>
.aps-table-location-cell-container {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.aps-table-location-cell-wrapper {
  margin-top: 2px;
  margin-bottom: 2px;
  font-size: 0.8em;
}
</style>
