import { STORAGE_KEY_IS_MENU_COLLAPSED } from '../config';
import { ObjectStorage } from './storage';

/**
 * Класс для управления кэшированным состоянием навигационного меню.
 */

export default class MenuMaster {
  /**
   * Получает кэшированный стейт из LocalStorage.
   * @returns {boolean | undefined} - Кэшированный стейт, свернуто ли меню или нет.
   * @example
   * const isMenuCollapsedCachedState = MenuMaster.getCachedMenuState();
   * if (isMenuCollapsedCachedState) {
   *   console.log('меню свернуто:', isMenuCollapsedCachedState);
   * } else {
   *   console.log('Меню развернуто');
   * }
   */
  static getCachedMenuState() {
    const rawStringValue = ObjectStorage.getItem(STORAGE_KEY_IS_MENU_COLLAPSED);

    if (rawStringValue === 'true') {
      return true;
    }

    if (rawStringValue === 'false') {
      return false;
    }

    return undefined;
  }

  /**
   * Устанавливает кэшированную локаль в LocalStorage.
   * @param {boolean} newState - новый стейт меню: true - меню свернуто, false - меню развернуто.
   * @example
   * MenuMaster.setCachedMenuState(true);
   */
  static setCachedMenuState(newState) {
    ObjectStorage.setItem(STORAGE_KEY_IS_MENU_COLLAPSED, newState);
  }

  /**
   * Очищает кэшированный стейт из хранилища.
   * @example
   * MenuMaster.clearCachedMenuState();
   */
  static clearCachedMenuState() {
    ObjectStorage.removeItem(STORAGE_KEY_IS_MENU_COLLAPSED);
  }
}
