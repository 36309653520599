<template>
  <VLANForm
    :formConfig="formConfig"
    :forbiddenVlanNames="forbiddenVlanNames"
    :forbiddenVlanIds="forbiddenVlanIds"
    @submit="handleSubmit"
  >
    <template v-slot:control-buttons>
      <slot name="form-control-buttons"></slot>
    </template>
  </VLANForm>
</template>

<script>
/**
 * компонент настроенной формы ChangeVLANForm через generateFormConfig для редактирования VLAN в рамках branch profile.
 * ! Компонент локальный, используется только в VLANTabContent.vue
 * @component
 */

import {
  VLANForm,
  generateFormConfig
} from '../../features';

/* eslint-disable camelcase */
export default {
  name: 'ChangeVLANForm',
  components: {
    VLANForm
  },
  props: {
    forbiddenVlanNames: {
      type: Array,
      default: () => []
    },
    forbiddenVlanIds: {
      type: Array,
      default: () => []
    },
    suggestedVlanNames: {
      type: Array,
      default: () => []
    },
    suggestedAclNames: {
      type: Array,
      default: () => []
    },
    selectedVLANForChanging: {
      type: Object,
      required: true
    }
  },
  data() {
    return {};
  },
  computed: {
  },
  methods: {
    handleSubmit(formValues) {
      this.$emit('submit', formValues);
    }
  },
  created() {
    const vlanNameSuggestedOptions = this.suggestedVlanNames.map(vlanName => ({ display: vlanName, data: vlanName }));
    const aclValueSuggestedOptions = this.suggestedAclNames.map(aclName => ({ display: aclName, data: aclName }));

    const {
      name,
      vlan_id,
      acl_egress,
      acl_ingress
    } = this.selectedVLANForChanging;

    const aclTypeValue = acl_egress === acl_ingress ? 'bidirectional' : 'unidirectional';

    const formConfig = generateFormConfig({
      i18nInstance: this.$i18n,
      initialValues: {
        general: {
          vlanName: {
            isDisabled: true,
            defaultValue: name,
            selectOptions: vlanNameSuggestedOptions
          },
          vlanId: {
            isDisabled: true,
            defaultValue: vlan_id
          },
          aclType: {
            defaultValue: aclTypeValue
          },
          aclValue: {
            defaultValue: aclTypeValue === 'bidirectional' ? acl_ingress : '',
            selectOptions: aclValueSuggestedOptions
          },
          aclIngressVlaue: {
            defaultValue: aclTypeValue === 'unidirectional' ? acl_ingress : '',
            selectOptions: aclValueSuggestedOptions
          },
          aclEgressValue: {
            defaultValue: aclTypeValue === 'unidirectional' ? acl_egress : '',
            selectOptions: aclValueSuggestedOptions
          }
        }
      }
    });

    this.formConfig = formConfig;
  }
};
</script>

<style lang="css" scoped></style>
