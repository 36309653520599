import { v4 as uuidv4 } from 'uuid';

/**
 * @typedef {Object} FieldConfig
 * @property {boolean} isDisabled - Указывает, отключено ли поле.
 * @property {string|boolean|number} defaultValue - Значение по умолчанию для поля.
 * @property {boolean} isRequired - Указывает, обязательно ли для заполнения.
 * @property {SelectOption[]} [selectOptions] - Опции для поля ввода типа select.
 */

/**
 * @typedef {Object} DefaultValues
 * @property {Object} general - Общие настройки.
 * @property {FieldConfig} general.vlanName
 * @property {FieldConfig} general.vlanId
 * @property {FieldConfig} general.aclType
 * @property {FieldConfig} general.aclIngress
 * @property {FieldConfig} general.aclEgress
 */

/** @type {DefaultValues} */
const defaultValues = {
  general: {
    vlanName: {
      isDisabled: false,
      defaultValue: '',
      isRequired: true
    },
    vlanId: {
      isDisabled: false,
      defaultValue: '',
      isRequired: true
    },
    aclType: {
      isDisabled: false,
      defaultValue: 'unidirectional',
      isRequired: false
    },
    aclValue: {
      isDisabled: false,
      defaultValue: '',
      isRequired: false
    },
    aclIngressVlaue: {
      isDisabled: false,
      defaultValue: '',
      isRequired: false
    },
    aclEgressValue: {
      isDisabled: false,
      defaultValue: '',
      isRequired: false
    }
  }
};

/**
 * Глубокое слияние двух объектов.
 *
 * @template T
 * @param {T} target - Целевой объект.
 * @param {Partial<T>} source - Исходный объект.
 * @returns {T} - Слитый объект.
 */
function mergeDeep(target, source) {
  const result = { ...target };

  Object.entries(source).forEach(([key, value]) => {
    if (value instanceof Object && key in target) {
      result[key] = mergeDeep(target[key], value);
    } else {
      result[key] = value;
    }
  });
  return result;
}

/**
 * @typedef {Object} SelectOption
 * @property {string} display - Отображаемое значение опции.
 * @property {string} data - Данные опции.
 */

/**
 * @typedef {Object} Input
 * @property {string} id - Уникальный идентификатор для поля ввода.
 * @property {string} label - Метка для поля ввода.
 * @property {string} name - Имя поля ввода.
 * @property {string} inputType - Тип поля ввода.
 * @property {string} validateRules - Правила валидации для поля ввода.
 * @property {boolean} isDisabled - Отключено ли поле ввода.
 * @property {string} placeholder - Плейсхолдер для поля ввода.
 * @property {SelectOption[]} [selectOptions] - Опции для поля ввода типа select.
 * @property {string|number|boolean|undefined} defaultValue - Значение по умолчанию для поля ввода.
 * @property {string|number|boolean} [radioValue] - value для radio инпута.
 */

/**
 * @typedef {Object} ContentGroup
 * @property {string} label - Метка для группы содержимого.
 * @property {Input[]} inputs - Поля ввода в группе содержимого.
 */

/**
 * @typedef {Object} TabConfig
 * @property {string} id - Уникальный идентификатор для вкладки.
 * @property {string} label - Метка для вкладки.
 * @property {string} name - Имя вкладки.
 * @property {ContentGroup[]} content - Группы содержимого во вкладке.
 * @property {TabConfig[]} childrenTabs - Вложенные вкладки.
 */

/**
 * Генерирует конфигурацию формы на основе заданных параметров.
 *
 * @param {Object} params - Параметры для генерации конфигурации формы.
 * @param {Object} params.i18nInstance - Экземпляр i18n для локализации.
 * @param {Object} [params.initialValues] - Начальные значения и настройки полей формы.
 * @param {Object} [params.initialValues.general] - Начальные значения и настройки для общей вкладки.
 * @returns {TabConfig[]} Конфигурация вкладок формы.
 */

function generateFormConfig(params) {
  const {
    i18nInstance,
    initialValues = {}
  } = params;

  const $t = i18nInstance.t.bind(i18nInstance);
  const mergedValues = mergeDeep(defaultValues, initialValues);

  const {
    general: {
      vlanName,
      vlanId,
      aclType,
      aclValue,
      aclIngressVlaue,
      aclEgressValue
    }
  } = mergedValues;

  /**
  * @type {TabConfig[]}
  */
  const formConfig = [
    {
      id: uuidv4(),
      label: '',
      name: 'main',
      content: [
        {
          label: '',
          inputs: [
            {
              id: uuidv4(),
              label: $t('branchProfile.inputLabel.vlanName'),
              name: 'vlanName',
              inputType: 'textWithSelect',
              validateRules: `${vlanName.isRequired ? 'required|' : ''}uniqueVlanName|vlanNameMask|max:120`,
              isDisabled: vlanName.isDisabled,
              placeholder: '',
              defaultValue: vlanName.defaultValue,
              selectOptions: [
                ...vlanName.selectOptions
              ]
            },
            {
              id: uuidv4(),
              label: $t('branchProfile.inputLabel.vlanId'),
              name: 'vlanId',
              inputType: 'number',
              validateRules: `${vlanId.isRequired ? 'required|' : ''}integer|uniqueVlanId|between:1,4094`,
              isDisabled: vlanId.isDisabled,
              placeholder: '',
              defaultValue: vlanId.defaultValue
            }
          ]
        },
        {
          label: $t('branchProfile.inputLabel.aclType'),
          inputs: [
            {
              id: uuidv4(),
              label: $t('branchProfile.inputLabel.aclType.unidirectional'),
              name: 'aclType',
              inputType: 'radio',
              validateRules: `${aclType.isRequired ? 'required|' : ''}`,
              isDisabled: aclType.isDisabled,
              placeholder: '',
              defaultValue: aclType.defaultValue,
              radioValue: 'unidirectional'
            },
            {
              id: uuidv4(),
              label: $t('branchProfile.inputLabel.aclType.bidirectional'),
              name: 'aclType',
              inputType: 'radio',
              validateRules: `${aclType.isRequired ? 'required|' : ''}`,
              isDisabled: aclType.isDisabled,
              placeholder: '',
              defaultValue: aclType.defaultValue,
              radioValue: 'bidirectional'
            }
          ]
        },
        {
          label: '',
          inputs: [
            {
              id: uuidv4(),
              label: $t('branchProfile.inputLabel.aclValue'),
              name: 'aclValue',
              inputType: 'select',
              validateRules: `${aclValue.isRequired ? 'required|' : ''}`,
              isDisabled: aclValue.isDisabled,
              placeholder: '',
              defaultValue: aclValue.defaultValue,
              selectOptions: [
                ...aclValue.selectOptions
              ]
            },
            {
              id: uuidv4(),
              label: $t('branchProfile.inputLabel.aclIngressVlaue'),
              name: 'aclIngressVlaue',
              inputType: 'select',
              validateRules: `${aclIngressVlaue.isRequired ? 'required|' : ''}`,
              isDisabled: aclIngressVlaue.isDisabled,
              placeholder: '',
              defaultValue: aclIngressVlaue.defaultValue,
              selectOptions: [
                ...aclIngressVlaue.selectOptions
              ]
            },
            {
              id: uuidv4(),
              label: $t('branchProfile.inputLabel.aclEgressValue'),
              name: 'aclEgressValue',
              inputType: 'select',
              validateRules: `${aclEgressValue.isRequired ? 'required|' : ''}`,
              isDisabled: aclEgressValue.isDisabled,
              placeholder: '',
              defaultValue: aclEgressValue.defaultValue,
              selectOptions: [
                ...aclEgressValue.selectOptions
              ]
            }
          ]
        }
      ],
      childrenTabs: []
    }
  ];

  return formConfig;
}

export default generateFormConfig;
