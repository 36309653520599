import { STORAGE_KEY_SEARCHBAR_HISTORY } from '../config';
import { ObjectStorage } from './storage';

/**
 * Класс для управления кэшированием истории запросов в SearchBar.
 */

export default class SearchBarHistoryMaster {
  /**
   * Получает кэшированный стейт из LocalStorage.
   * @returns {Array} - Кэшированный массив { id: number|strung, value: string}[].
   * @example
   * const cachedHistory = SearchBarHistoryMaster.getCachedHistory();
   */
  static getCachedHistory() {
    return ObjectStorage.getItem(STORAGE_KEY_SEARCHBAR_HISTORY) ? JSON.parse(ObjectStorage.getItem(STORAGE_KEY_SEARCHBAR_HISTORY)) : [];
  }

  /**
   * Устанавливает кэшированный массив истории запросов в LocalStorage.
   * @param {Array} newState - Кэшированный массив { id: number|strung, value: string}[].
   * @example
   * SearchBarHistoryMaster.setCachedHistory([{ id: 1, value: 'foo' }, { id: 2, value: 'bar' }]);
   */
  static setCachedHistory(history) {
    ObjectStorage.setItem(STORAGE_KEY_SEARCHBAR_HISTORY, JSON.stringify(history));
  }

  /**
   * Очищает кэшированную историю запросов из хранилища.
   * @example
   * SearchBarHistoryMaster.clear();
   */
  static clear() {
    ObjectStorage.removeItem(STORAGE_KEY_SEARCHBAR_HISTORY);
  }
}
