/**
 * Оценивает, является ли текущий пользователь администратором на основе роли в хранилище.
 *
 * @function
 * @returns {boolean} - Возвращает true, если текущий пользователь администратор, иначе - false.
 *
 * @example
 * Пример использования в навигационном охраннике:
 *
 * const router = new VueRouter({
 *   routes: [
 *     {
 *       path: '/some-protected-route',
 *       component: SomeProtectedComponent,
 *       beforeEnter: (to, from, next) => {
 *         if (isAdminRoleGuard()) {
 *           * // Разрешить навигацию, так как текущий пользователь администратор
 *           next();
 *         } else {
 *           * // Если текущий пользователь не администратор, выполнить перенаправление
 *           next({ name: 'Dashboard' });
 *         }
 *       },
 *     },
 *   ],
 * });
 */

import store from '@/store';

const isAdminRoleGuard = () => {
  const isAdminRole = store.state.userData.role === 'admin';

  return isAdminRole;
};

export default isAdminRoleGuard;
