/**
 * Саб-модуль Vuex expirationStage.
 * !!! используется как модуль только в autologout
 *
 * @module expirationStage
 * @namespace
 *
 * Здесь размещаются стейты, геттеры, мутации и экшны, специфические для стадии.
 * Для использования в компонентах и других модулях Vuex.
 *
 * doc о модулях vuex:
 * https://v3.vuex.vuejs.org/ru/guide/modules.html
 */

import { createIntervalWorker } from '@/workers';

import {
  LOGOUT_AFTER_USER_INACTIVITY_MODE
} from '@/config';

const INITIAL_REMAINING_TIME_MS_VALUE = LOGOUT_AFTER_USER_INACTIVITY_MODE.timeout.expirationStage;
const TIMER_INTERVAL_STEP = 1000; // 1 sec in ms

const expirationStage = {
  state: () => ({
    expirationStage: {
      intervalWorker: null,
      remainingTime: INITIAL_REMAINING_TIME_MS_VALUE // счетчик таймера 1 мин,
    }
  }),
  getters: {
    /**
     * Проверяет, запущена ли expirationStage.
     * @returns {boolean}
     */
    isExpirationStage: (state) => Boolean(state.expirationStage.intervalWorker),
    /**
     * Возвращает оставшееся время expirationStage
     * @returns {boolean}
     */
    expirationStageRemainingTime: (state) => state.expirationStage.remainingTime
  },
  mutations: {
    /**
     * Устанавливает Worker с интервалом и останавливает старый, если он был.
     *
     * @param {State} state - Состояние модуля Vuex.
     * @param {Worker} newWorker - Новый Worker.
     */

    setExpirationStageWorker(state, newWorker) {
      if (state.expirationStage.intervalWorker) {
        state.expirationStage.intervalWorker.terminate();
      }
      state.expirationStage.intervalWorker = newWorker;
    },
    /**
     * Устанавливает оставшееся время интервала в expirationStage.
     *
     * @param {State} state - Состояние модуля Vuex.
     * @param {number} remainingTime - Новое значение оставшегося времени в ms.
     */
    setExpirationStageRemainingTime(state, remainingTime) {
      state.expirationStage.remainingTime = remainingTime;
    }
  },
  actions: {
    /**
     * создает инстанс вебворкера с интервалом.
     * !!! Вызывается только из _startMainStage
     * !!! Напрямую в компонентах не используется
     *
     * @param {Object} context - Контекст хранилища Vuex.
     * @param {function} context.commit - Функция для вызова мутаций.
     * @param {function} context.getters - Функция для получения геттеров.
     * @param {function} context.dispatch - Функция для вызова других экшнов.
    */
    _createIntervalWebWorker({ commit }) {
      const worker = createIntervalWorker();
      commit('setExpirationStageWorker', worker);
    },

    /**
     * Запускает expirationStage.
     * !!! Вызывается только из _startMainStage
     * !!! Напрямую в компонентах не используется
     *
     * @param {Object} context - Контекст хранилища Vuex.
     * @param {function} context.commit - Функция для вызова мутаций.
     * @param {function} context.getters - Функция для получения геттеров.
     * @param {function} context.dispatch - Функция для вызова других экшнов.
    */
    _startExpirationStage({ state, commit, dispatch }) {
      const { expirationStage } = state;

      dispatch('_createIntervalWebWorker');

      expirationStage.intervalWorker.postMessage({
        interval: TIMER_INTERVAL_STEP,
        remainingTime: state.expirationStage.remainingTime
      });

      expirationStage.intervalWorker.onmessage = (event) => {
        const { counter, isDone } = event.data;

        if (!isDone) {
          const msLeft = counter;
          commit('setExpirationStageRemainingTime', msLeft);
        } else {
          dispatch('_resetExpirationStage');
          dispatch('endUserSession');
        }
      };
    },

    /**
     * Останавливает и сбрасывает expirationStage.
     * !!! Напрямую в компонентах не используется
     *
     * @param {Object} context - Контекст хранилища Vuex.
     * @param {function} context.commit - Функция для вызова мутаций.
     * @param {function} context.getters - Функция для получения геттеров.
     * @param {function} context.dispatch - Функция для вызова других экшнов.
    */
    _resetExpirationStage({ state, commit }) {
      const { expirationStage } = state;

      // Остановка веб-воркера и сброс времени
      if (expirationStage.intervalWorker) {
        commit('setExpirationStageWorker', null);
      }

      if (expirationStage.remainingTime >= 0) {
        commit('setExpirationStageRemainingTime', INITIAL_REMAINING_TIME_MS_VALUE);
      }
    }
  }
};

export default expirationStage;
