import { DYNAMIC_VLAN_BOUNDARIES, VLAN_BOUNDARIES, VLAN_BOUNDARIES_FOR_WLC_WITH_AAA_OVERIDE_ON } from './config';

/**
 * Различные функции помогающие работать с вводимыми значениями VLAN
 * Используются в разлинчых местах для конвертации прилетающих в виде массива с бэка значений dynamic vlan в строку,
 * Для обратной конвертации при отправке на бэк
 * В валидаторе полей для ввода dynamic vlan
 */

/**
 * Функция для определения одиночное ли это  значение VLAN, например 2, или нет
 * используется только внутри VLANS.js
 *
 * @param inputedString
 * @returns {boolean}
 */
const isNumberEntered = (inputedString) => {
  const singleNumberInInputedRegexp = new RegExp('^\\d+$');
  return singleNumberInInputedRegexp.test(inputedString);
};

/**
 * Функция для определения несколько ли  это значений VLAN, разделеных запятой или нет. В этом случае значения VLAN должны разделяться запятыми, например, 2,6,4,10
 * используется только внутри VLANS.js
 *
 * @param inputedString
 * @returns {boolean}
 */
const isCommaSeparetedNumbersEntered = (inputedString) => {
  const commaSeparatedNumberInInputedRegexp = new RegExp('^\\d+(,\\d+)*$');
  return commaSeparatedNumberInInputedRegexp.test(inputedString);
};

/**
 * Функция для определения диапазон ли это значений VLAN. Указание диапазона VLAN осуществляется с помощью тире, например, 2-8
 * используется только внутри VLANS.js
 *
 * @param inputedString
 * @returns {boolean}
 */
const isDashSeparetedRangeEntered = (inputedString) => {
  const dashSeparetedNumberRangeInInputedRegexp = new RegExp('^[0-9]+-[0-9]+$');
  return dashSeparetedNumberRangeInInputedRegexp.test(inputedString);
};

/**
 * Функция конвертирует строку, введеную в виде  NUMBER в формат массива, для отсылки на бэк
 * используется только внутри VLANS.js
 * @param inputedString
 * @returns {Array}
 */
const convertVlanFieldInputedNumberToArray = (inputedString) => {
  const result = [];
  result.push(parseInt(inputedString, 0));
  return result;
};

/**
 * Функция конвертирует строку, введеную в виде  COMMA_SEPARATED_NUMBERS в формат массива, для отсылки на бэк
 * используется только внутри VLANS.js
 * @param inputedString
 * @returns {Array}
 */
const convertVlanFieldInputedCommaSeparetedNumbersToArray = (inputedString) => {
  const result = inputedString.split(',').map((vlan) => {
    return parseInt(vlan, 0);
  });
  return result;
};

/**
 * Функция конвертирует строку, введеную в виде  DASH_SEPARATED_RANGE в формат массива, для отсылки на бэк
 * используется только внутри VLANS.js
 *
 * @param inputedString
 * @returns {Array}
 */
const convertVlanFieldInputedDashSeparetedRangeToArray = (inputedString) => {
  const result = [];
  const inputedAsArr = inputedString.split('-');
  const min = parseInt(inputedAsArr[0], 0);
  const max = parseInt(inputedAsArr[1], 0);
  for (let i = min; i <= max; i++) {
    result.push(i);
  }
  return result;
};

/**
 * Все из VLANHelpers используется во вне этого файла
 */
const VLANHelpers = {
  /**
   * Типы введеного в поле для ввода VLAN
   * @type {{NUMBER: string, COMMA_SEPARATED_NUMBERS: string, DASH_SEPARATED_RANGE: string}}
   */
  VLAN_FIELD_ENTERED_TYPES: {
    // одиночное значение VLAN, например 2
    NUMBER: 'number',
    // Несколько значений VLAN, разделеных запятой, например, 2,6,4,10
    COMMA_SEPARATED_NUMBERS: 'commaSeparatedNumbers',
    // Диапазон значений VLAN. Указание диапазона VLAN осуществляется с помощью тире, например, 2-8
    DASH_SEPARATED_RANGE: 'dashSeparatedRange'
  },

  /**
   * Возвращает объект с минимальным и максимальным значением для dynamicVlan
   * Используется для валидации и формировании подсказок валидатора
   * @returns {{min: number, max: number}}
   */
  getdynamicVLANBoundaries() {
    return { min: DYNAMIC_VLAN_BOUNDARIES.min, max: DYNAMIC_VLAN_BOUNDARIES.max };
  },

  /**
   * Возвращает объект с минимальным и максимальным значением для Vlan
   * Используется для валидации
   * @returns {{min: number, max: number}}
   */
  getVLANBoundaries() {
    return { min: VLAN_BOUNDARIES.min, max: VLAN_BOUNDARIES.max };
  },

  /**
   * Возвращает объект с минимальным и максимальным значением для WLC сборки и включенного aaa override
   * Используется для валидации
   * @returns {{min: number, max: number}}
   */
  getVLANBoundariesForWLCWithAAAOverrideOn() {
    return {
      min: VLAN_BOUNDARIES_FOR_WLC_WITH_AAA_OVERIDE_ON.min,
      max: VLAN_BOUNDARIES_FOR_WLC_WITH_AAA_OVERIDE_ON.max
    };
  },

  /**
   * Функция, позволяющая проверить отдельно введеное число VLAN на соовтствие диапазону допустимых значений
   * @param vlan {string|number}
   * @returns {boolean}
   */
  isDynamicVLANNumberInBoundaries(vlan) {
    const { min, max } = this.getdynamicVLANBoundaries();
    return parseInt(vlan, 0) >= min && parseInt(vlan, 0) <= max;
  },

  /**
   * Функция для определения какого типа значение введено в поле для ввода VLAN

   * Введеное может быть быть 3 типов
   * - Одиночное значение VLAN, например 2 - VLAN_FIELD_ENTERED_TYPES.NUMBER
   * - Несколько значений VLAN. В этом случае значения VLAN должны разделяться запятыми, например, 2,6,4,10 - VLAN_FIELD_ENTERED_TYPES.COMMA_SEPARATED_NUMBERS
   * - Диапазон значений VLAN. Указание диапазона VLAN осуществляется с помощью тире, например, 2-8 - VLAN_FIELD_ENTERED_TYPES.DASH_SEPARATED_RANGE
   *
   * @param inputedString
   * @returns {string|undefined}
   */
  detectVlanFieldInputedType(inputedString) {
    if (isNumberEntered(inputedString)) {
      return this.VLAN_FIELD_ENTERED_TYPES.NUMBER;
    }
    if (isCommaSeparetedNumbersEntered(inputedString)) {
      return this.VLAN_FIELD_ENTERED_TYPES.COMMA_SEPARATED_NUMBERS;
    }
    if (isDashSeparetedRangeEntered(inputedString)) {
      return this.VLAN_FIELD_ENTERED_TYPES.DASH_SEPARATED_RANGE;
    }
    return undefined;
  },

  /**
   * Функция для конвертации данных из строки в массив перед отправкой на бэк в зависимости оттого какого типа значение введено в поле для ввода VLAN
   * @param inputedString
   * @returns {Array}
   */
  convertDynamicVlanStringToArrayFormat(inputedString) {
    if (isNumberEntered(inputedString)) {
      return convertVlanFieldInputedNumberToArray(inputedString);
    }
    if (isCommaSeparetedNumbersEntered(inputedString)) {
      return convertVlanFieldInputedCommaSeparetedNumbersToArray(inputedString);
    }
    if (isDashSeparetedRangeEntered(inputedString)) {
      return convertVlanFieldInputedDashSeparetedRangeToArray(inputedString);
    }
    return [];
  },

  /**
   * Функция для конвертации данных прилетающих с бэка в формате массива в строку, для отображения в поле в UI
   * @param vlansArray {Array}
   * @returns {String}
   */
  convertDynamicVlanArrayToStringFormat(vlansArray) {
    if (!vlansArray || !Array.isArray(vlansArray)) {
      return '';
    }
    if (vlansArray.length === 0) {
      return '';
    }
    if (vlansArray.length === 1) {
      return `${vlansArray[0]}`;
    }
    if (vlansArray.length > 1) {
      // тут пытаемся понять был ли введен юзером изначально дапазаон через тире.
      // считаем что если значения в массиве в правильной последовательности - от меньшего к большему
      // и без разрывов в этом промежутке то юзер вводил промежуток через тире
      const min = Math.min(...vlansArray);
      const max = Math.max(...vlansArray);
      // создаем эталонный массив, какой должен был получится если юзер вводил промежуток через тире
      const arrayForCompare = [];
      for (let i = min; i <= max; i++) {
        arrayForCompare.push(i);
      }
      // и срваниваем его с тем что на самом деле
      const arraysContentIsEqual = vlansArray.toString() === arrayForCompare.toString();
      if (arraysContentIsEqual) {
        // если совпадает, то считаем что юзер вводил диапазон через тире и будем отображать его
        return `${min}-${max}`;
      } else {
        // если нет, то выводим строку с VLAN через запятую
        return vlansArray.join();
      }
    }
    return '';
  }
};

export { VLANHelpers };
