import { createNamespacedHelpers } from 'vuex';
import authApi from '@/services/auth';

const {
  mapGetters: autologoutGetters,
  mapActions: autologoutActions
} = createNamespacedHelpers('autologout');

// это локальный миксин для компонента UserSessionExpirationModal. Используется только в нем.
// Предоставляет доступ к инетрфейсу vuex module - autologout,
// который позволяет отслеживать активность пользователя и триггерить авторазлогирование

const userAutologoutMixin = {
  computed: {
    ...autologoutGetters([
      'isExpirationStage',
      'expirationStageRemainingTime'
    ])
  },
  methods: {
    ...autologoutActions([
      'subscribeOnUserActivityTracking',
      'unsubscribeFromUserActivityTracking',
      'restartUserActivityTracking',
      'endUserSession'
    ])
  },
  mounted() {
    this.subscribeOnUserActivityTracking({
      onEndUserSession: () => authApi.logout(() => { this.$router.push('/pages/login'); })
    });
  },
  beforeDestroy() {
    this.unsubscribeFromUserActivityTracking();
  }
};

export default userAutologoutMixin;
