/**
 * Утилитарный класс для работы с локальным хранилищем (localStorage).
 */
export class ObjectStorage {
  /**
   * Устанавливает элемент в локальном хранилище.
   * @param {string} key - Ключ для элемента.
   * @param {string} value - Значение элемента.
   */
  static setItem(key, value) {
    localStorage.setItem(key, value);
  }

  /**
   * Получает элемент из локального хранилища по ключу.
   * @param {string} key - Ключ элемента, который нужно получить.
   * @returns {string | null} - Значение элемента или null, если элемент не найден.
   */
  static getItem(key) {
    return localStorage.getItem(key);
  }

  /**
   * Удаляет элемент из локального хранилища по ключу.
   * @param {string} key - Ключ элемента, который нужно удалить.
   */
  static removeItem(key) {
    localStorage.removeItem(key);
  }

  /**
   * Получает объект из локального хранилища по ключу и парсит его.
   * @param {string} key - Ключ объекта.
   * @returns {T | null} - Распарсенный объект или null, если объект не найден.
   */
  static getObject(key) {
    const objectString = this.getItem(key);

    if (!objectString) {
      return null;
    }

    return JSON.parse(objectString);
  }

  /**
   * Сохраняет объект в локальное хранилище по ключу после сериализации в JSON.
   * @param {string} key - Ключ объекта.
   * @param {T} value - Значение объекта для сохранения.
   */
  static setObject(key, value) {
    this.setItem(key, JSON.stringify(value));
  }
}
