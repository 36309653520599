<template>
  <div class="uptime">
    <i class="fa fa-gear fa-spin fa-spin--slow"></i>
    <small>{{ uptime | uptimeFilter }}</small>
  </div>
</template>

<script>
export default {
  name: 'UptimeIndicator',
  props: {
    uptime: {
      type: Number,
      required: true
    }
  },
  filters: {
    uptimeFilter(value) {
      return moment.duration(value, 'seconds').format(' h [h], mm [min]');
    }
  }
};
</script>

<style lang="css" scoped>
.uptime {
  display: flex;
  align-items: center;
  gap: 4px;
}
</style>
