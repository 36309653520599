<template>
  <span>
    {{ timeoutData }}
  </span>
</template>

<script>
/**
 * компонент - ячейка timeout.
 * компонент локальный: используется только в RRMGroupsTable.vue
 * @component
 */

export default {
  name: 'TimeoutCell',
  components: {},
  props: {
    props: {
      type: Object,
      required: true
    }
  },
  computed: {
    timeoutData() {
      return `${this.props.row.timeout} ${this.$t('RRM.tableRRMSeconds')}.`;
    }
  },
  methods: {
  }
};
</script>

<style lang="css" scoped>
</style>
