const WMM_PREFIX_FOR_FIELDS_VALIDATION = 'wmm-';
const WMM_CATEGORIES_AND_CONFIGURATION = {
  BK: {
    nameForDispaly: 'Background',
    configuration: {
      cli_cw_min: {
        nameForDispaly: 'Client cwMin',
        nameForValidation: `${WMM_PREFIX_FOR_FIELDS_VALIDATION}-BK-cli_cw_min`,
        type: 'int',
        validation: 'between:0,15',
        defaultValue: 4,
        isShowing: true
      },
      cli_cw_max: {
        nameForDispaly: 'Client cwMax',
        nameForValidation: `${WMM_PREFIX_FOR_FIELDS_VALIDATION}-BK-cli_cw_max`,
        type: 'int',
        validation: 'between:0,15',
        defaultValue: 10,
        isShowing: true
      },
      cli_aifs: {
        nameForDispaly: 'Client AIFS',
        nameForValidation: `${WMM_PREFIX_FOR_FIELDS_VALIDATION}-BK-cli_aifs`,
        type: 'int',
        validation: 'min_value:0',
        defaultValue: 7,
        isShowing: true
      },
      cli_txop: {
        nameForDispaly: 'TXOP Limit',
        nameForValidation: `${WMM_PREFIX_FOR_FIELDS_VALIDATION}-BK-cli_txop`,
        type: 'int',
        validation: 'min_value:0',
        defaultValue: 0,
        isShowing: true
      },
      ac_cw_min: {
        nameForDispaly: 'AC cwMin',
        nameForValidation: `${WMM_PREFIX_FOR_FIELDS_VALIDATION}-BK-ac_cw_min`,
        type: 'int',
        validation: 'wmmAcCwFiled',
        defaultValue: 15,
        isShowing: true
      },
      ac_cw_max: {
        nameForDispaly: 'AC cwMax',
        nameForValidation: `${WMM_PREFIX_FOR_FIELDS_VALIDATION}-BK-ac_cw_max`,
        type: 'int',
        validation: 'wmmAcCwFiled',
        defaultValue: 1023,
        isShowing: true
      },
      ac_aifs: {
        nameForDispaly: 'AC AIFS',
        nameForValidation: `${WMM_PREFIX_FOR_FIELDS_VALIDATION}-BK-ac_aifs`,
        type: 'int',
        validation: 'min_value:0',
        defaultValue: 7,
        isShowing: true
      },
      ac_burst: {
        nameForDispaly: 'Max Burst (msec)',
        nameForValidation: `${WMM_PREFIX_FOR_FIELDS_VALIDATION}-BK-ac_burst`,
        type: 'float',
        validation: 'min_value:0|decimal:1',
        decimal: '1',
        defaultValue: 0,
        isShowing: true
      },
      cli_acm: {
        nameForDispaly: 'ACM',
        nameForValidation: `${WMM_PREFIX_FOR_FIELDS_VALIDATION}-BK-cli_acm`,
        type: 'bool',
        defaultValue: false,
        isShowing: false // намерено скрываем настройку для всех сборок (https://wimarksystems.atlassian.net/wiki/spaces/Wimark/pages/357793940/FT23.13+GUI+Wimark+WLC+FrontEnd)
      },
    }
  },
  BE: {
    nameForDispaly: 'BestEffort',
    configuration: {
      cli_cw_min: {
        nameForDispaly: 'Client cwMin',
        nameForValidation: `${WMM_PREFIX_FOR_FIELDS_VALIDATION}-BE-cli_cw_min`,
        type: 'int',
        validation: 'between:0,15',
        defaultValue: 4,
        isShowing: true
      },
      cli_cw_max: {
        nameForDispaly: 'Client cwMax',
        nameForValidation: `${WMM_PREFIX_FOR_FIELDS_VALIDATION}-BE-cli_cw_max`,
        type: 'int',
        validation: 'between:0,15',
        defaultValue: 10,
        isShowing: true
      },
      cli_aifs: {
        nameForDispaly: 'Client AIFS',
        nameForValidation: `${WMM_PREFIX_FOR_FIELDS_VALIDATION}-BE-cli_aifs`,
        type: 'int',
        validation: 'min_value:0',
        defaultValue: 3,
        isShowing: true
      },
      cli_txop: {
        nameForDispaly: 'TXOP Limit',
        nameForValidation: `${WMM_PREFIX_FOR_FIELDS_VALIDATION}-BE-cli_txop`,
        type: 'int',
        validation: 'min_value:0',
        defaultValue: 0,
        isShowing: true
      },
      ac_cw_min: {
        nameForDispaly: 'AC cwMin',
        nameForValidation: `${WMM_PREFIX_FOR_FIELDS_VALIDATION}-BE-ac_cw_min`,
        type: 'int',
        validation: 'wmmAcCwFiled',
        defaultValue: 15,
        isShowing: true
      },
      ac_cw_max: {
        nameForDispaly: 'AC cwMax',
        nameForValidation: `${WMM_PREFIX_FOR_FIELDS_VALIDATION}-BE-ac_cw_max`,
        type: 'int',
        validation: 'wmmAcCwFiled',
        defaultValue: 63,
        isShowing: true
      },
      ac_aifs: {
        nameForDispaly: 'AC AIFS',
        nameForValidation: `${WMM_PREFIX_FOR_FIELDS_VALIDATION}-BE-ac_aifs`,
        type: 'int',
        validation: 'min_value:0',
        defaultValue: 3,
        isShowing: true
      },
      ac_burst: {
        nameForDispaly: 'Max Burst (msec)',
        nameForValidation: `${WMM_PREFIX_FOR_FIELDS_VALIDATION}-BE-ac_burst`,
        type: 'float',
        validation: 'min_value:0|decimal:1',
        decimal: '1',
        defaultValue: 0,
        isShowing: true
      },
      cli_acm: {
        nameForDispaly: 'ACM',
        nameForValidation: `${WMM_PREFIX_FOR_FIELDS_VALIDATION}-BE-cli_acm`,
        type: 'bool',
        defaultValue: false,
        isShowing: false // намерено скрываем настройку для всех сборок (https://wimarksystems.atlassian.net/wiki/spaces/Wimark/pages/357793940/FT23.13+GUI+Wimark+WLC+FrontEnd)
      }
    }
  },
  VI: {
    nameForDispaly: 'Video',
    configuration: {
      cli_cw_min: {
        nameForDispaly: 'Client cwMin',
        nameForValidation: `${WMM_PREFIX_FOR_FIELDS_VALIDATION}-VI-cli_cw_min`,
        type: 'int',
        validation: 'between:0,15',
        defaultValue: 3,
        isShowing: true
      },
      cli_cw_max: {
        nameForDispaly: 'Client cwMax',
        nameForValidation: `${WMM_PREFIX_FOR_FIELDS_VALIDATION}-VI-cli_cw_max`,
        type: 'int',
        validation: 'between:0,15',
        defaultValue: 4,
        isShowing: true
      },
      cli_aifs: {
        nameForDispaly: 'Client AIFS',
        nameForValidation: `${WMM_PREFIX_FOR_FIELDS_VALIDATION}-VI-cli_aifs`,
        type: 'int',
        validation: 'min_value:0',
        defaultValue: 2,
        isShowing: true
      },
      cli_txop: {
        nameForDispaly: 'TXOP Limit',
        nameForValidation: `${WMM_PREFIX_FOR_FIELDS_VALIDATION}-VI-cli_txop`,
        type: 'int',
        validation: 'min_value:0',
        defaultValue: 94,
        isShowing: true
      },
      ac_cw_min: {
        nameForDispaly: 'AC cwMin',
        nameForValidation: `${WMM_PREFIX_FOR_FIELDS_VALIDATION}-VI-ac_cw_min`,
        type: 'int',
        validation: 'wmmAcCwFiled',
        defaultValue: 7,
        isShowing: true
      },
      ac_cw_max: {
        nameForDispaly: 'AC cwMax',
        nameForValidation: `${WMM_PREFIX_FOR_FIELDS_VALIDATION}-VI-ac_cw_max`,
        type: 'int',
        validation: 'wmmAcCwFiled',
        defaultValue: 15,
        isShowing: true
      },
      ac_aifs: {
        nameForDispaly: 'AC AIFS',
        nameForValidation: `${WMM_PREFIX_FOR_FIELDS_VALIDATION}-VI-ac_aifs`,
        type: 'int',
        validation: 'min_value:0',
        defaultValue: 1,
        isShowing: true
      },
      ac_burst: {
        nameForDispaly: 'Max Burst (msec)',
        nameForValidation: `${WMM_PREFIX_FOR_FIELDS_VALIDATION}-VI-ac_burst`,
        type: 'float',
        validation: 'min_value:0|decimal:1',
        decimal: '1',
        defaultValue: 3.0,
        isShowing: true
      },
      cli_acm: {
        nameForDispaly: 'ACM',
        nameForValidation: `${WMM_PREFIX_FOR_FIELDS_VALIDATION}-VI-cli_acm`,
        type: 'bool',
        defaultValue: false,
        isShowing: false // намерено скрываем настройку для всех сборок (https://wimarksystems.atlassian.net/wiki/spaces/Wimark/pages/357793940/FT23.13+GUI+Wimark+WLC+FrontEnd)
      }
    }
  },
  VO: {
    nameForDispaly: 'Voice',
    configuration: {
      cli_cw_min: {
        nameForDispaly: 'Client cwMin',
        nameForValidation: `${WMM_PREFIX_FOR_FIELDS_VALIDATION}-VO-cli_cw_min`,
        type: 'int',
        validation: 'between:0,15',
        defaultValue: 2,
        isShowing: true
      },
      cli_cw_max: {
        nameForDispaly: 'Client cwMax',
        nameForValidation: `${WMM_PREFIX_FOR_FIELDS_VALIDATION}-VO-cli_cw_max`,
        type: 'int',
        validation: 'between:0,15',
        defaultValue: 3,
        isShowing: true
      },
      cli_aifs: {
        nameForDispaly: 'Client AIFS',
        nameForValidation: `${WMM_PREFIX_FOR_FIELDS_VALIDATION}-VO-cli_aifs`,
        type: 'int',
        validation: 'min_value:0',
        defaultValue: 2,
        isShowing: true
      },
      cli_txop: {
        nameForDispaly: 'TXOP Limit',
        nameForValidation: `${WMM_PREFIX_FOR_FIELDS_VALIDATION}-VO-cli_txop`,
        type: 'int',
        validation: 'min_value:0',
        defaultValue: 47,
        isShowing: true
      },
      ac_cw_min: {
        nameForDispaly: 'AC cwMin',
        nameForValidation: `${WMM_PREFIX_FOR_FIELDS_VALIDATION}-VO-ac_cw_min`,
        type: 'int',
        validation: 'wmmAcCwFiled',
        defaultValue: 3,
        isShowing: true
      },
      ac_cw_max: {
        nameForDispaly: 'AC cwMax',
        nameForValidation: `${WMM_PREFIX_FOR_FIELDS_VALIDATION}-VO-ac_cw_max`,
        type: 'int',
        validation: 'wmmAcCwFiled',
        defaultValue: 7,
        isShowing: true
      },
      ac_aifs: {
        nameForDispaly: 'AC AIFS',
        nameForValidation: `${WMM_PREFIX_FOR_FIELDS_VALIDATION}-VO-ac_aifs`,
        type: 'int',
        validation: 'min_value:0',
        defaultValue: 1,
        isShowing: true
      },
      ac_burst: {
        nameForDispaly: 'Max Burst (msec)',
        nameForValidation: `${WMM_PREFIX_FOR_FIELDS_VALIDATION}-VO-ac_burst`,
        type: 'float',
        validation: 'min_value:0|decimal:1',
        decimal: '1',
        defaultValue: 1.5,
        isShowing: true
      },
      cli_acm: {
        nameForDispaly: 'ACM',
        nameForValidation: `${WMM_PREFIX_FOR_FIELDS_VALIDATION}-VO-cli_acm`,
        type: 'bool',
        defaultValue: false,
        isShowing: false // намерено скрываем настройку для всех сборок (https://wimarksystems.atlassian.net/wiki/spaces/Wimark/pages/357793940/FT23.13+GUI+Wimark+WLC+FrontEnd)
      }
    }
  }
};
export { WMM_CATEGORIES_AND_CONFIGURATION, WMM_PREFIX_FOR_FIELDS_VALIDATION };
