// selected checkbox of a row or not
function getSelectedRowValue(currentVlanId, selectedVLANIds) {
  let value = false;

  selectedVLANIds.forEach(vlanId => {
    if (vlanId === currentVlanId) {
      value = true;
    }
  });

  return value;
}

export {
  getSelectedRowValue
};
