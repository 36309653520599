<template>
  <div class="logo-wrapper">
    <img :src="logoImgLink" alt="logo">
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import {
  wimarkLogoLight,
  wimarkLogoDark,
  qtechkLogoLight,
  qtechkLogoDark,
  beelineLogoLight,
  beelineLogoDark,
  mtsLogo,
  wiflyLogo,
  bulatLogoLight,
  bulatLogoDark,
  aquariusLogoLight,
  aquariusLogoDark
} from '@/assets';

const { mapGetters: brandingMapGetters } = createNamespacedHelpers('branding');
const { mapGetters: themeMapGetters } = createNamespacedHelpers('theme');

export default {
  name: 'AuthenticationLogo',
  props: {
  },
  data() {
    return {};
  },
  computed: {
    ...brandingMapGetters([
      'isDefaultBranding',
      'isBeelineBranding',
      'isQTECHBranding',
      'isAquariusBranding',
      'isMTSBranding',
      'isBulatBranding',
      'isWiflyBranding'
    ]),
    ...themeMapGetters(['isLightTheme', 'isDarkTheme']),
    logoImgLink() {
      // qtech logo
      if (this.isQTECHBranding && this.isLightTheme) {
        return qtechkLogoLight;
        // return qtechkLogoDark;
      }

      if (this.isQTECHBranding && this.isDarkTheme) {
        return qtechkLogoLight;
        // return qtechkLogoDark;
      }

      // Aquarius logo
      if (this.isAquariusBranding && this.isLightTheme) {
        return aquariusLogoLight;
      }

      if (this.isAquariusBranding && this.isDarkTheme) {
        return aquariusLogoLight;
      }

      // beeline logo
      if (this.isBeelineBranding && this.isLightTheme) {
        return beelineLogoLight;
        // return beelineLogoDark;
      }

      if (this.isBeelineBranding && this.isDarkTheme) {
        return beelineLogoLight;
        // return beelineLogoDark;
      }

      // mts logo
      if (this.isMTSBranding && this.isLightTheme) {
        return mtsLogo;
      }

      if (this.isMTSBranding && this.isDarkTheme) {
        return mtsLogo;
      }

      // bulat logo
      if (this.isBulatBranding && this.isLightTheme) {
        return bulatLogoLight;
        // return bulatLogoDark;
      }

      if (this.isBulatBranding && this.isDarkTheme) {
        return bulatLogoLight;
        // return bulatLogoDark;
      }

      // wifly logo
      if (this.isWiflyBranding && this.isLightTheme) {
        return wiflyLogo;
      }

      if (this.isWiflyBranding && this.isDarkTheme) {
        return wiflyLogo;
      }

      // default wimark logo
      if (this.isDefaultBranding && this.isLightTheme) {
        return wimarkLogoLight;
        // return wimarkLogoDark;
      }

      if (this.isDefaultBranding && this.isDarkTheme) {
        return wimarkLogoLight;
        // return wimarkLogoDark;
      }

      return wimarkLogoLight;
    }
  },
  methods: {
  },
  watch: {
  },
  created() {
  },
  mounted() {
  }
};
</script>

<style lang="css" scoped>
.logo-wrapper {
  max-width: 70%;
  max-height: 70px;
}

.logo-wrapper > img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: contain;
  aspect-ratio: 3/1;
}
</style>
