<template>
  <div class="authentication-wrapper">
    <div class="content-wrapper">
      <slot name="form-content"></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AuthenticationWrapper',
  components: {
  },
  props: {
  },
  data() {
    return {
    };
  },
  methods: {
  },
  watch: {
  },
  created() {
  },
  mounted() {
  }
};
</script>

<style lang="css" scoped>
.authentication-wrapper {
  width: 100vw;
  height: 100vh;
  position: relative;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.authentication-wrapper::before {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  background-image: url('~@/assets/images/welcome-intro-main.webp');
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  filter: brightness(0.7);
}

.content-wrapper {
  width: 530px;
}
</style>
