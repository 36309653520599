/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
/**
 * конфигурация таблицы VLANTable.vue
*/

/**
 * генератор колонок таблицы
 * https://xaksis.github.io/vue-good-table/guide/configuration/column-options.html#label
*/

import {
  getSelectedRowValue
} from './utils';

function generateTableColumns({ i18nInstance, hiddenColumnRules, additionalRules }) {
  const $t = i18nInstance.t.bind(i18nInstance);

  return [
    {
      label: $t('branchProfile.tableHeading.vlanId'),
      field: 'vlanId',
      width: 'auto',
      type: 'number',
      tdClass: '',
      hidden: hiddenColumnRules.vlanId
    },
    {
      label: $t('branchProfile.tableHeading.vlanName'),
      field: 'vlanName',
      width: 'auto',
      type: 'text',
      tdClass: '',
      hidden: hiddenColumnRules.vlanName
    },
    {
      label: $t('branchProfile.tableHeading.aclIngress'),
      field: 'aclIngress',
      width: 'auto',
      type: 'text',
      hidden: hiddenColumnRules.aclIngress
      // visibilityDropdownSettings: {
      //   hidden: true
      // }
    },
    {
      label: $t('branchProfile.tableHeading.aclEgress'),
      field: 'aclEgress',
      width: 'auto',
      type: 'text',
      hidden: hiddenColumnRules.aclEgress
      // visibilityDropdownSettings: {
      //   hidden: true
      // }
    }
  ];
}

/**
 * генератор строк таблицы
 * https://xaksis.github.io/vue-good-table/guide/configuration/#rows
 * https://xaksis.github.io/vue-good-table/guide/advanced/grouped-table.html#customizing-header-row
*/
function generateTableRows({ VLANsData, selectedVLANIds }) {
  return VLANsData.map(vlan => {
    const {
      name,
      vlan_id,
      acl_ingress,
      acl_egress
    } = vlan;

    return {
      // nativeData - хранит неформатированные данные. Нужны, чтобы удобно эмитить их по событию для дальнейших манипуляций.
      nativeData: vlan,
      vlanId: vlan_id,
      vlanName: name,
      aclIngress: acl_ingress,
      aclEgress: acl_egress,
      // можно ли выбирать строку чекбоксом
      // vgtDisabled: false,
      // выбрана ли строка в чекбоксе исходя из стейта, который контролирует выбранные строки
      vgtSelected: getSelectedRowValue(vlan_id, selectedVLANIds)
    };
  });
}

export {
  generateTableColumns,
  generateTableRows
};
