<template>
  <button
    type="button"
    class="toggle-button"
    @click="toggleSidebarMenu"
    v-tooltip.top="isMenuCollapsed ? $t('sidebarNew.expandMenu') : $t('sidebarNew.collapseMenu')"
  >
    <i
      class="fa icon-color"
      :class="[isMenuCollapsed ? 'fa-bars' : 'fa-arrow-left']"
    />
  </button>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';

const {
  mapActions: menuMapActions,
  mapGetters: menuMapGetters
} = createNamespacedHelpers('menu');

export default {
  name: 'SidebarMenuSwitcher',
  methods: {
    ...menuMapActions(['toggleMenu']),
    toggleSidebarMenu() {
      // todo: отрефакторить
      document.body.classList.toggle('sidebar-show-only-icons');
      this.$store.commit('toggleShowOnlyIcons');

      // новое меню переключается через vuex-модуль.
      // todo: когда совсем перейдем на новое меню, от кода выше можно будет избавиться
      this.toggleMenu();
    }
  },
  computed: {
    ...menuMapGetters(['isMenuCollapsed'])
  }
};
</script>

<style lang="css" scoped>
.toggle-button {
  background: transparent;
  padding: 5px;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: var(--transition);

  width: 30px;
  height: 30px;
  border: 1px solid var(--header-font-color);
  border-radius: 8px;
}

.toggle-button:hover {
  opacity: var(--opacity);
}

.icon-color {
  color: var(--header-font-color);
}
</style>
