const EVENT_TYPES = {
  INFO: 'INFO',
  WARNING: 'WARNING',
  ERROR: 'ERROR'
};

const VIEW_TYPES = {
  DASHBOARD: 'dashboard',
  EVENTS: 'events'
};

export { EVENT_TYPES, VIEW_TYPES };
