/**
 * Саб-модуль Vuex trottlerStage.
 * !!! используется как модуль только в autologout
 *
 * @module trottlerStage
 * @namespace
 *
 * Здесь размещаются стейты, геттеры, мутации и экшны, специфические для стадии.
 * Для использования в компонентах и других модулях Vuex.
 *
 * doc о модулях vuex:
 * https://v3.vuex.vuejs.org/ru/guide/modules.html
 */

import { createTimeoutWorker } from '@/workers';

const THROTTLER_TIMEOUT_MS_VALUE = 2000;

const throttlerStage = {
  state: () => ({
    throttlerStage: {
      timeoutWorker: null
    }
  }),
  getters: {
    /**
     * Проверяет, запущена ли throttlerStage.
     * @param {object} state - Состояние модуля Vuex.
     * @returns {boolean}
     */
    isThrottlerStage: (state) => Boolean(state.throttlerStage.timeoutWorker)
  },
  mutations: {
    /**
     * Устанавливает worker с таймаутом стадии троттлера и удаляет старый, если он был.
     * @param {object} state - Состояние модуля Vuex.
     * @param {Worker} newWorker - Новый worker с таймаутом.
     */
    setThrottlerStageTimeoutWorker(state, newWorker) {
      if (state.throttlerStage.timeoutWorker) {
        state.throttlerStage.timeoutWorker.terminate();
      }

      state.throttlerStage.timeoutWorker = newWorker;
    }
  },
  actions: {
    /**
     * создает инстанс вебворкера с таймаутом.
     * !!! Напрямую в компонентах не используется
     *
     * @param {Object} context - Контекст хранилища Vuex.
     * @param {function} context.commit - Функция для вызова мутаций.
     * @param {function} context.getters - Функция для получения геттеров.
     * @param {function} context.dispatch - Функция для вызова других экшнов.
    */
    _createThrottlerTimeoutWebWorker({ commit }) {
      const worker = createTimeoutWorker();
      commit('setThrottlerStageTimeoutWorker', worker);
    },
    /**
     * Запускает throttlerStage. (Оптимизационный, чтобы очень частые события не забивали колстэк).
     * после отрабатывания он запускает mainStage.
     * !!! Напрямую в компонентах не используется
     *
     * @param {Object} context - Контекст хранилища Vuex.
     * @param {function} context.commit - Функция для вызова мутаций.
     * @param {function} context.getters - Функция для получения геттеров.
     * @param {function} context.dispatch - Функция для вызова других экшнов.
    */
    _startThrottlerStage({
      state,
      getters,
      commit,
      dispatch
    }) {
      const {
        throttlerStage
      } = state;

      const { isThrottlerStage, isUserActive, isUserSessionEnded } = getters;

      if (isUserSessionEnded) {
        commit('setIsUserSessionEnded', false);
      }

      if (!isUserActive) {
        commit('setIsUserActive', true);
      }

      // если стадия была запущена раньше, то ничего не делаем, пока она не отработает.
      if (isThrottlerStage) {
        return;
      }

      // если была активна стадия mainStage, то сбрасываем ее.
      dispatch('_resetMainStage');

      // если была активна стадия expirationStage, то сбрасываем ее.
      dispatch('_resetExpirationStage');

      dispatch('_createThrottlerTimeoutWebWorker');

      throttlerStage.timeoutWorker.postMessage({
        timeout: THROTTLER_TIMEOUT_MS_VALUE
      });

      throttlerStage.timeoutWorker.onmessage = (event) => {
        const { isDone } = event.data;

        if (isDone) {
          dispatch('_resetThrottlerStage');
          dispatch('_startMainStage');
        }
      };
    },

    /**
     * Останавливает и сбрасывает throttlerStage.
     * !!! Напрямую в компонентах не используется
     *
     * @param {Object} context - Контекст хранилища Vuex.
     * @param {function} context.commit - Функция для вызова мутаций.
     * @param {function} context.getters - Функция для получения геттеров.
     * @param {function} context.dispatch - Функция для вызова других экшнов.
    */
    _resetThrottlerStage({ state, commit }) {
      const { throttlerStage } = state;

      if (throttlerStage.timeoutWorker) {
        commit('setThrottlerStageTimeoutWorker', null);
      }
    }
  }
};

export default throttlerStage;
