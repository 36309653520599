<template>
  <router-link
    class="logo-link"
    :class="[isCustomBrandingClass]"
    :to=logoRedirectLink>
    <img
      class="logo"
      :class=[isCustomBrandingClass]
      :src="logoImgLink"
      alt="logo"
    >
  </router-link>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import {
  wimarkLogoLight,
  wimarkLogoDark,
  qtechkLogoLight,
  qtechkLogoDark,
  beelineLogoLight,
  beelineLogoDark,
  mtsLogo,
  wiflyLogo,
  bulatLogoLight,
  bulatLogoDark,
  aquariusLogoLight,
  aquariusLogoDark

} from '@/assets';

const { mapGetters: brandingMapGetters } = createNamespacedHelpers('branding');
const { mapGetters: featureFlagsMapGetters } = createNamespacedHelpers('featureFlags');
const { mapGetters: themeMapGetters } = createNamespacedHelpers('theme');

const REDIRECT_LINK_DICT = {
  dashboard: '/dashboard',
  vouchers: '/portal/hotel-vouchers',
  ads: '/portal/ads',
  portal: '/portal/dashboard'
};

export default {
  name: 'HeaderLogo',
  props: {
  },
  data() {
    return {};
  },
  computed: {
    ...brandingMapGetters([
      'isDefaultBranding',
      'isBeelineBranding',
      'isQTECHBranding',
      'isAquariusBranding',
      'isMTSBranding',
      'isBulatBranding',
      'isWiflyBranding'
    ]),
    ...featureFlagsMapGetters(['isPortalOnlyModeEnabled', 'isExperimentalModeEnabled', 'areLocationsHidden']),
    ...themeMapGetters(['isLightTheme', 'isDarkTheme']),
    isCustomBrandingClass() {
      return this.isDefaultBranding ? '' : 'custom-brand';
    },
    logoRedirectLink() {
      const {
        dashboard, vouchers, ads, portal
      } = REDIRECT_LINK_DICT;

      const userRoleState = this.$store.state.userData.role;
      if (userRoleState === 'hotel_employee') {
        return vouchers;
      }
      if (userRoleState === 'advertising') {
        return ads;
      }
      if (this.isPortalOnlyModeEnabled) {
        return portal;
      }
      return dashboard;
    },
    logoImgLink() {
      // для кастомного лого
      if (this.customLogoForUserLocation && this.customLogoForUserLocation !== '') {
        return this.customLogoForUserLocation;
      }

      // qtech logo
      if (this.isQTECHBranding && this.isLightTheme) {
        // return qtechkLogoLight;
        return qtechkLogoDark;
      }

      if (this.isQTECHBranding && this.isDarkTheme) {
        return qtechkLogoDark;
      }

      // Aquarius logo
      if (this.isAquariusBranding && this.isLightTheme) {
        return aquariusLogoDark;
      }

      if (this.isAquariusBranding && this.isDarkTheme) {
        return aquariusLogoDark;
      }

      // beeline logo
      if (this.isBeelineBranding && this.isLightTheme) {
        // return beelineLogoLight;
        return beelineLogoDark;
      }

      if (this.isBeelineBranding && this.isDarkTheme) {
        return beelineLogoDark;
      }

      // mts logo
      if (this.isMTSBranding && this.isLightTheme) {
        return mtsLogo;
      }

      if (this.isMTSBranding && this.isDarkTheme) {
        return mtsLogo;
      }

      // bulat logo
      if (this.isBulatBranding && this.isLightTheme) {
        // return bulatLogoLight;
        return bulatLogoDark;
      }

      if (this.isBulatBranding && this.isDarkTheme) {
        return bulatLogoDark;
      }

      // wifly logo
      if (this.isWiflyBranding && this.isLightTheme) {
        return wiflyLogo;
      }

      if (this.isWiflyBranding && this.isDarkTheme) {
        return wiflyLogo;
      }

      // default wimark logo
      if (this.isDefaultBranding && this.isLightTheme) {
        // return wimarkLogoLight;
        return wimarkLogoDark;
      }

      if (this.isDefaultBranding && this.isDarkTheme) {
        return wimarkLogoDark;
      }

      return wimarkLogoDark;
    },
    // todo refactor
    // во время разработки тут в реальные данные подмешивались нужные для разрабокти,
    // тепреь просто прокидываются реальные данные о локациях
    locationsWithCustomLogo() {
      // const locations = JSON.parse(JSON.stringify(this.$store.state.locationsItems));
      // return locations;
      return this.$store.state.locationsItems;
    },
    customLogoForUserLocation() {
      // todo refactor
      if (localStorage.getItem('userData')) {
        const userBaseLocation = JSON.parse(localStorage.getItem('userData')).base_location;
        // console.log(userBaseLocation)
        if (this.locationsWithCustomLogo[userBaseLocation]) {
          if (this.locationsWithCustomLogo[userBaseLocation].custom_logo) {
            return this.locationsWithCustomLogo[userBaseLocation].custom_logo;
          }
          return '';
        }
        return '';
      }
      return '';
    }
  },
  methods: {
  },
  watch: {
  },
  created() {
  },
  mounted() {
  }
};
</script>

<style lang="css" scoped>
.logo-link {
  padding: 6px;
  position: relative;
  display: block;
  width: 100%;
  height: 100%;
  transition: var(--transition);
}

.logo-link:hover {
  opacity: var(--opacity);
}

.logo-link.custom-brand::after {
  content: 'Running on Wimark® Platform';
  color: var(--header-font-color);
  font-size: 12px;
  position: absolute;
  bottom: 6px;
  right: 6px;
}

.logo {
  width: 100%;
  height: 100%;
  aspect-ratio: 1/1;
  object-fit: contain;
  display: block;
}

.logo.custom-brand {
  height: 75%;
}
</style>
