<template>
  <li class="crumbs-list-item">
    <CrumbRoot v-if="item.role === 'root'"
      :item="item"
      @on-root-role-click="$emit('on-root-role-click', $event)"
    />
    <CrumbSection v-if="item.role === 'section'"
      :item="item"
    />
    <div v-if="item.role === 'link'"
      class="crumb link"
    >
      {{ item.title | capitalize }}
    </div>
  </li>
</template>

<script>
import {
  CrumbRoot,
  CrumbSection
} from './components';

export default {
  name: 'Crumb',
  components: {
    CrumbRoot,
    CrumbSection
  },
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  computed: {
  },
  methods: {
  }
};
</script>

<style lang="css" scoped>
.crumbs-list-item {
  display: flex;
  font-size: 16px;
  color: var(--breadcrumb-font-color);
}

.crumbs-list-item+.crumbs-list-item::before {
  display: inline-block;
  padding: 0 8px;
  content: "\203A";
}

</style>
