/* eslint-disable import/first */
/* eslint-disable import/order */

// let termsRequest = new XMLHttpRequest();
// termsRequest.open('GET', `${document.location.origin}/terms.json`, false)
// termsRequest.send(null);
// if (termsRequest.status === 200) {
//   // console.log(termsRequest.responseText);
//   sessionStorage.setItem('terms', JSON.stringify(JSON.parse(termsRequest.responseText)))
// }
//


import { setEnviroment } from './env-vars';
import { LocaleMaster } from '@/utils';

setEnviroment();

// import '../public/static/scss/style.scss';
import Vue from 'vue';
import VueI18n from 'vue-i18n';
import App from './App.vue';
import router from './router';
import store from './store';
import { LOCALES } from '@/config';
import { onStoreStateSubscriber } from '@/plugins';
import accessControl from './access-control';
import axios from 'axios';
import VueAxios from 'vue-axios';
import VueNotifications from 'vue-notifications';
import miniToastr from 'mini-toastr';
import VeeValidate, { Validator } from 'vee-validate';
import en from 'vee-validate/dist/locale/en';
import ru from 'vee-validate/dist/locale/ru';
import Validators from './validators';
import VueHighcharts from 'vue-highcharts';
import Highcharts from 'highcharts/highstock';
import highchartsMore from 'highcharts/highcharts-more';
import solidGauge from 'highcharts/modules/solid-gauge';
import heatmap from 'highcharts/modules/heatmap';
import VTooltip from 'v-tooltip';
import VueClipboard from 'vue-clipboard2';
import VueMasonry from 'vue-masonry-css';
import Teleport from 'vue2-teleport';

import leafletheat from 'leaflet.heat/dist/leaflet-heat';
import leafletfullscreen from 'leaflet-fullscreen/dist/Leaflet.fullscreen.min';
import leafletsearch from 'leaflet-search/dist/leaflet-search.min.css';
// fix for vue2leaflet
import { Icon } from 'leaflet';
import 'leaflet/dist/leaflet.css';

import { themeControl } from './theme-control';
import LoaderSpinner from './components/loaderSpinner.vue';
import LoaderSpinnerSmall from './components/loaderSpinnerSmall.vue';
import { TableDataDownloader } from './components';
import TextHighlight from 'vue-text-highlight';
import { AXIOS_INSTANCE } from './api';

import * as UIComponents from './components/UI';

// глобальная регистрация UI-компонентов
Object.entries(UIComponents).forEach(([name, component]) => {
  Vue.component(name, component);
});

import { locationVisibilityMixin } from '@/mixins';

Vue.component('text-highlight', TextHighlight);

themeControl();

delete Icon.Default.prototype._getIconUrl;
Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png')
});

miniToastr.init();
function toast({
  title, message, type, timeout, cb
}) {
  return miniToastr[type](message, title, timeout, cb);
}

const options = {
  success: toast,
  error: toast,
  info: toast,
  warn: toast
};

Vue.use(VueNotifications, options);
Vue.use(VueAxios, AXIOS_INSTANCE);
Vue.use(VTooltip, {
  defaultDelay: { show: 100, hide: 100 }
});
Vue.use(VueClipboard);
Vue.component('TableDataDownloader', TableDataDownloader);

Vue.use(VueMasonry);
Vue.use(accessControl);
Vue.use(VueHighcharts);
Vue.use(VueHighcharts, { Highcharts });
heatmap(Highcharts);
Vue.component('LoaderSpinner', LoaderSpinner);
Vue.component('LoaderSpinnerSmall', LoaderSpinnerSmall);
Vue.component('Teleport', Teleport);

// todo: инициацию moment отрефакторить. Например, убрать в плагин
import moment from 'moment';

window.moment = moment;
Vue.prototype.moment = moment;

const setMomentLocale = () => {
  if (LocaleMaster.isCachedLocaleEN()) {
    moment.locale('en-gb');
  } else {
    moment.locale(LocaleMaster.getCachedLocale());
  }
}
setMomentLocale();

// нужен. без этого импорта ломается логика на страницах (/statistics/statcpes/)
import momentFormat from 'moment-duration-format';

Vue.use(VeeValidate, {
  locale: store.getters['locale/locale'],
  fieldsBagName: 'veeFields',
  dictionary: {
    en,
    ru
  }
});

Vue.use(VueI18n);
const i18n = new VueI18n({
  locale: store.getters['locale/locale'],
  fallbackLocale: store.getters['locale/fallbackLocale'],
  messages: store.getters['locale/messages'],
  silentTranslationWarn: false // Установите true для отключения вывода предупреждений
});

// Custom Validators
const customValidationSet = () => {
  const validatorNames = Object.keys(Validators(i18n));
  validatorNames.forEach((name) => {
    VeeValidate.Validator.extend(name, Validators(i18n)[name]);
  });
};

const updateValidatorLocale = (newLocale) => {
  Vue.nextTick(() => {
    Validator.localize(newLocale);
    customValidationSet();
  });
};

const updatei18nLocale = (newLocale) => {
  i18n.locale = newLocale;
};

// todo: унести отсюда куда-нибудь
// info: from locale-switcherNew.vue setHighChartsLocale method
const setHighChartsLocale = (locale) => {
  // используется для переключения языка всех графиков highcharts
  // console.log(locale)
  try {
    if (locale === LOCALES.ru) {
      Highcharts.setOptions({
        lang: {
          loading: 'Загрузка...',
          months: [
            'Январь',
            'Февраль',
            'Март',
            'Апрель',
            'Май',
            'Июнь',
            'Июль',
            'Август',
            'Сентябрь',
            'Октябрь',
            'Ноябрь',
            'Декабрь'
          ],
          weekdays: ['Воскресенье', 'Понедельник', 'Вторник', 'Среда', 'Четверг', 'Пятница', 'Суббота'],
          shortMonths: ['Янв', 'Фев', 'Март', 'Апр', 'Май', 'Июнь', 'Июль', 'Авг', 'Сент', 'Окт', 'Нояб', 'Дек'],
          noData: 'Нет данных для отображения'
          // downloadPNG: 'Скачать PNG',
          // downloadJPEG: 'Скачать JPEG',
          // downloadPDF: 'Скачать PDF',
          // downloadSVG: 'Скачать SVG'
        }
      });
    } else {
      Highcharts.setOptions({
        lang: {
          loading: 'Loading....',
          months: [
            'January',
            'February',
            'March',
            'April',
            'May',
            'June',
            'July',
            'August',
            'September',
            'October',
            'November',
            'December'
          ],
          weekdays: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
          shortMonths: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
          noData: 'No data to display'
          // downloadPNG: 'Download PNG',
          // downloadJPEG: 'Download JPEG',
          // downloadPDF: 'Download PDF',
          // downloadSVG: 'Download SVG'
        }
      });
    }
  } catch (e) {
    console.log('setHighChartsLocale error', e);
    // console.warn(e);
  }
};
// todo: отрефакторить
// временный фикс - устанавливаем изначальную локализацию в графиках при маунте приложения
setHighChartsLocale(store.getters['locale/locale']);

onStoreStateSubscriber({
  storeGetterName: 'locale/locale',
  callbackSubscribers: [
    updatei18nLocale,
    setMomentLocale,
    setHighChartsLocale,
    updateValidatorLocale
  ]
});

customValidationSet();

Vue.mixin(locationVisibilityMixin);

// TODO вынести отсюда filter
function capitalize(string) {
  if (!string) return '';
  const tail = string.slice(1);
  const capitalized = string.charAt(0).toUpperCase() + tail;
  return capitalized;
}

Vue.filter('capitalize', capitalize);

const app = new Vue({
  el: '#app',
  router,
  store,
  i18n,
  template: '<App/>',
  components: { App }
});
export default app;

// todo: отрефакторить так, чтобы этот экспорт был не нужен
export {
  i18n
};
