/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
/**
 * конфигурация таблицы ApsTable.vue
*/

/**
 * генератор колонок таблицы
 * https://xaksis.github.io/vue-good-table/guide/configuration/column-options.html#label
*/

import {
  getFrequencyCellsData,
  getWiredCellData,
  getDisabledSelectionAbilityRowValue,
  getSelectedRowValue
} from './utils';

function generateTableColumns({ i18nInstance, hiddenColumnRules, additionalRules }) {
  const $t = i18nInstance.t.bind(i18nInstance);
  const { isBeelineBranding, isContentAnalyticsEnabled, areLocationsVisible } = additionalRules;

  return [
    {
      label: $t('aps.tableTab_name'),
      field: 'name',
      width: 'auto',
      type: 'text',
      tdClass: 'aps-table-td-wrapper',
      hidden: hiddenColumnRules.name
    },
    {
      label: $t('aps.tableTab_model'),
      field: 'model',
      width: 'auto',
      type: 'text',
      tdClass: 'aps-table-td-wrapper',
      hidden: hiddenColumnRules.model
    },
    {
      label: $t('aps.tableTab_status'),
      field: 'status',
      width: 'auto',
      type: 'text',
      hidden: hiddenColumnRules.status
    },
    {
      label: 'IP',
      field: 'ip',
      width: 'auto',
      type: 'text',
      hidden: hiddenColumnRules.ip
    },
    {
      label: $t('aps.tableTab_two'),
      field: 'two',
      width: 'auto',
      sortable: false,
      hidden: hiddenColumnRules.two
    },
    {
      label: $t('aps.tableTab_five'),
      field: 'five',
      width: 'auto',
      sortable: false,
      hidden: hiddenColumnRules.five
    },
    // !!! только для beeline сборки
    {
      label: $t('aps.tableTab_wired'),
      field: 'wired',
      width: 'auto',
      type: 'text',
      sortable: false,
      hidden: hiddenColumnRules.wired,
      visibilityDropdownSettings: {
        hidden: !isBeelineBranding
      }
    },
    // todo! нужна только для сборок с analytics
    {
      label: $t('aps.tableTab_radar'),
      field: 'radar',
      width: 'auto',
      type: 'boolean',
      hidden: hiddenColumnRules.radar,
      visibilityDropdownSettings: {
        hidden: !isContentAnalyticsEnabled
      }
    },
    {
      label: $t('aps.tableTab_location'),
      field: 'location',
      width: 'auto',
      type: 'text',
      hidden: hiddenColumnRules.location,
      visibilityDropdownSettings: {
        hidden: !areLocationsVisible
      }
    },
    {
      label: $t('location.locationDescription'),
      field: 'locationDescription',
      width: 'auto',
      type: 'text',
      hidden: hiddenColumnRules.locationDescription,
      visibilityDropdownSettings: {
        hidden: !areLocationsVisible
      }
    },
    {
      label: $t('aps.tableTab_tags'),
      field: 'tags',
      width: 'auto',
      type: 'text',
      sortable: false,
      hidden: hiddenColumnRules.tags
    },
    {
      label: $t('aps.tableTab_firstConnection'),
      field: 'firstConnection',
      width: 'auto',
      type: 'text',
      hidden: hiddenColumnRules.firstConnection
    },
    {
      label: $t('aps.tableTab_lastConnection'),
      field: 'lastConnection',
      width: 'auto',
      type: 'text',
      hidden: hiddenColumnRules.lastConnection
    },
    {
      label: $t('aps.tableTab_lastDisconnection'),
      field: 'lastDisconnection',
      width: 'auto',
      type: 'text',
      hidden: hiddenColumnRules.lastDisconnection
    },
    {
      label: '',
      field: 'delete',
      sortable: false,
      hidden: hiddenColumnRules.delete,
      // нельзя скрыть/показать этот столбец
      visibilityDropdownSettings: {
        hidden: true
      }
    }
  ];
}

/**
 * генератор строк таблицы
 * https://xaksis.github.io/vue-good-table/guide/configuration/#rows
 * https://xaksis.github.io/vue-good-table/guide/advanced/grouped-table.html#customizing-header-row
*/
function generateTableRows({ apsData, selectedRowApIds }) {
  return apsData.map(ap => {
    const {
      id,
      base_location,
      clients,
      config,
      config_not_send,
      config_status,
      connected,
      description,
      first_connection,
      last_connection,
      last_disconnection,
      last_error,
      latitude,
      longitude,
      model,
      name,
      rrm_group,
      state,
      tags
    } = ap;

    const { twoGhzCellData, fiveGhzCellData } = getFrequencyCellsData(ap);

    return {
      // nativeData - хранит неформатированные данные. Нужны, чтобы удобно эмитить их по событию для дальнейших манипуляций.
      nativeData: ap,
      name,
      model: model.name,
      status: config_status,
      ip: state?.network?.ipaddr,
      two: twoGhzCellData,
      five: fiveGhzCellData,
      wired: getWiredCellData(config?.wired, clients),
      radar: config?.lbs_config?.enabled,
      location: base_location,
      locationDescription: base_location,
      tags: Array.isArray(tags) ? tags : [],
      firstConnection: first_connection,
      lastConnection: last_connection,
      lastDisconnection: last_disconnection,
      // можно ли выбирать строку чекбоксом
      vgtDisabled: getDisabledSelectionAbilityRowValue(config_status),
      // выбрана ли строка в чекбоксе исходя из стейта, который контролирует выбранные строки
      vgtSelected: getSelectedRowValue(id, selectedRowApIds)
    };
  });
}

export {
  generateTableColumns,
  generateTableRows
};
