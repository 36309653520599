<template>
  <div class="qr-code-generator mb-1" v-if="areAllSettingsThere && isShowingQR" :class="{'qr-code-generator-right-align': rightAlgin}">
    <slot name="caption">
      <small>{{ $t('wlans.qrForWLANConnet') }}:</small>
    </slot>
    <div>
      <div v-if="!isQRGenerated" class="qr-code-generator-spinner-wrapper">
        <loader-spinner-small centered></loader-spinner-small>
      </div>
      <div v-else class="qr-code-generator-img-and-button-wrapper">
        <div v-if="isQRGenerated" class="qr-code-generator-img-wrapper">
          <!--        <img id="wlan-qr-code" class="qr-code-png" :src="qrCodeBase64" />-->
          <div
            @mouseenter="saveButtonOverlayHasHover = true"
            class="wlan-qr-code-svg"
            id="wlan-qr-code-svg"
            v-html="qrCodeBase64"
          ></div>
          <div
            @mouseleave="saveButtonOverlayHasHover = false"
            v-if="isQRGenerated && saveButtonOverlayHasHover"
            class="save-png color-brand wlan-qr-code-svg"
          >
            <WButton primary sm
              @click="saveQRAsPng('wlan-qr-code-svg', `${ssid}-QR-code`)"
              @mouseenter="saveButtonHasHover = true"
              @focus="saveButtonHasHover = true"
              @mouseleave="saveButtonHasHover = false"
              @blur="saveButtonHasHover = false"
            >
              <i class="fa fa-download">{{ $t('general.download') }}</i>
            </WButton>

            <!--            <p v-if="saveButtonHasHover" class="save-png__tooltip">-->
            <!--              {{ $t('wlans.qrSaveQRCode') }}-->
            <!--            </p>-->
          </div>
        </div>
        <!--      <button @click="saveQRAsPng('wlan-qr-code-svg', 'qr-code')">Сохарнить</button>-->
      </div>
    </div>
  </div>
</template>

<script>
import saveSvgAsPng from 'save-svg-as-png';

const qrcode = require('wifi-qr-code-generator');

export default {
  name: 'WlanQRCodeGenerator',
  data() {
    return {
      saveButtonOverlayHasHover: false,
      saveButtonHasHover: false,
      isQRGenerated: false,
      qrCodeBase64: '',
      isShowingQR: true
    };
  },
  props: {
    wlanObject: {
      type: Object,
      required: true
    },
    rightAlgin: {
      default: false,
      type: Boolean
    }
  },
  computed: {
    ssid() {
      return this.qrGeneratorSettingsObject.ssid;
    },
    password() {
      return this.qrGeneratorSettingsObject.password;
    },
    encryption() {
      return this.qrGeneratorSettingsObject.encryption;
    },
    hiddenSSID() {
      return this.qrGeneratorSettingsObject.hiddenSSID;
    },
    areAllSettingsThere() {
      // Проверяем объект который передается в QR генератор, есть ли в нем все необходимое

      if (this.encryption !== '' && this.ssid !== '' && this.hiddenSSID !== '') {
        return true;
      }
      return false;
    },
    qrGeneratorSettingsObject() {
      // тут формируем объект с настройками для генераци QR
      const wlanQRObject = {
        ssid: '',
        password: '',
        encryption: '',
        hiddenSSID: ''
      };
      const wlan = JSON.parse(JSON.stringify(this.wlanObject));

      // ssid
      if (wlan.hasOwnProperty('ssid') && wlan.ssid !== '') {
        wlanQRObject.ssid = wlan.ssid;
      }

      // password
      // console.log(wlan)
      if (
        wlan.hasOwnProperty('security') &&
        wlan.security.hasOwnProperty('data') &&
        wlan.security.data !== null &&
        wlan.security.data !== undefined &&
        wlan.security.data.hasOwnProperty('psk')
      ) {
        wlanQRObject.password = wlan.security.data.psk;
      }

      // hidden
      // console.log(wlan)
      if (wlan.hasOwnProperty('hidden')) {
        wlanQRObject.hiddenSSID = wlan.hidden;
      }

      // encryption
      if (wlan.hasOwnProperty('security') && wlan.security.hasOwnProperty('type')) {
        const wlanEncryptionType = String(wlan.security.type);
        if (wlanEncryptionType === 'open') {
          wlanQRObject.encryption = 'None';
        }
        if (wlanEncryptionType === 'wpa2personal' || wlanEncryptionType === 'wpa3personal' || wlanEncryptionType === 'wpa23personal') {
          wlanQRObject.encryption = 'WPA';
        }
      }
      // console.log(wlanQRObject);
      return wlanQRObject;
    }
  },
  created() {
    this.generateQR();
  },
  watch: {
    qrGeneratorSettingsObject() {
      // this.isQRGenerated = false;
      this.generateQR();
      this.redrawQR();
    }
  },
  methods: {
    saveQRAsPng(qrId, filename) {
      const qr = document.getElementById(qrId);
      saveSvgAsPng.saveSvgAsPng(qr.querySelector('svg'), `${filename}.png`, { scale: 10 });
    },
    redrawQR() {
      this.isShowingQR = false;
      this.$nextTick(() => {
        this.isShowingQR = true;
      });
    },
    generateQR() {
      this.isQRGenerated = false;
      this.saveButtonOverlayHasHover = false;
      if (!this.areAllSettingsThere) {
        // если нет всех необходимых для генерации QR полей, не отрисовываем ничего
        return;
      }
      const qr = qrcode.generateWifiQRCode({
        ssid: this.ssid,
        password: this.password,
        encryption: this.encryption,
        hiddenSSID: this.hiddenSSID,
        outputFormat: { type: 'svg' }
      });
      qr.then((data) => {
        this.qrCodeBase64 = data;
        this.isQRGenerated = true;
        // console.log(this.qrGeneratorSettingsObject);
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.qr-code-generator {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: flex-start;
  justify-content: flex-start;
}
.qr-code-generator-right-align{
  align-items: flex-end !important;
}
.qr-code-generator-spinner-wrapper {
  width: 100px;
  height: 100px;
  position: relative;
  border: rgba(148, 160, 178, 0.22) dotted 1px;
}
.qr-code-generator-img-wrapper {
  width: 100px;
  height: 100px;
  border: rgba(148, 160, 178, 0.22) dotted 1px;
  position: relative;
}
.qr-code-generator-img-and-button-wrapper {
  display: flex;
  align-items: flex-start;
}
.qr-code-png {
  width: 100%;
  height: auto;
}
.save-png {
  top: 0px;
  left: 0px;
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
}

.save-png__button {
  box-sizing: border-box;
  margin: 0;
  padding: 5px;
  width: 30px;
  height: 30px;

  color: inherit;

  background-color: transparent;
  border: none;

  &:hover,
  &:focus {
    opacity: 0.65;
  }
}

.save-png__icon {
  /*width: auto;*/
  /*height: 100%;*/
  width: 10px;
  height: auto;
}

.save-png__tooltip {
  position: absolute;
  top: 30px;
  left: 50%;

  margin: 0;
  padding: 5px;

  color: #444d58;

  background-color: white;
  text-align: center;
  border: 1px solid #94a0b2;

  transform: translateX(-50%);
}
</style>
