<template>
  <AuthenticationFormTemplate
    :submit="handleSubmit"
    :subheadingText="$t('login.resetPasswordHeaderCaption')"
    :successText="successText"
    :errorText="getAuthenticationErrorTextForDisplayWithI18n(errorText)"
  >
    <template v-slot:fields>
      <WInput md
        id="username"
        name="username"
        customClass="auth-input"
        v-model.trim="credentials.username"
        :placeholder="$t('login.username')"
        data-vv-validate-on="input"
        v-validate="'required'"
        :disabled="isLoading"
        autocomplete="on"
        :data-vv-as="$t('login.username')"
        :errorText="errors.first('username')"
      >
        <template v-slot:icon-left>
          <i class="fa fa-user"></i>
        </template>
      </WInput>
    </template>

    <template v-slot:subfields>
    </template>

    <template v-slot:buttons>
      <WButton primary fullWidth
        type="submit"
        :disabled="isLoading || errors.any()"
      >
        {{ isLoading ? $t('general.state.loading') : $t('login.resetPasswordPageSendButton') }}
      </WButton>
    </template>
  </AuthenticationFormTemplate>
</template>

<script>
import authApi from '@/services/auth';
import AuthenticationFormTemplate from './AuthenticationFormTemplate.vue';
import { authenticationErrorsShowingWithI18nMixin } from './mixins';

export default {
  name: 'RecoverPasswordForm',
  mixins: [authenticationErrorsShowingWithI18nMixin],
  components: {
    AuthenticationFormTemplate
  },
  props: {
  },
  data() {
    return {
      credentials: {
        username: ''
      },
      isLoading: false,
      errorText: '',
      successText: ''
    };
  },
  computed: {
  },
  methods: {
    async handleSubmit() {
      const isFormValid = await this.$validator.validateAll();

      if (!isFormValid) {
        return;
      }

      authApi.requestPasswordRecovery({
        username: this.credentials.username
      }, {
        onLoading: () => {
          this.isLoading = true;
          this.successText = '';
          this.errorText = '';
        },
        onSuccess: () => {
          this.isLoading = false;

          this.successText = this.$t('login.resetPasswordPageSuccessMessage');
          this.credentials.username = '';
          this.$validator.reset();
        },
        onError: (error) => {
          this.errorText = error;
          this.isLoading = false;
        }
      });
    }
  },
  watch: {
  },
  created() {
  },
  mounted() {
  }
};
</script>

<style lang="css" scoped>
</style>
