/**
 * Сервис для загрузки локализации.
 * @namespace
 */
import axios from 'axios';
import { LOCALES } from '@/config';
import LOCALES_FILE from '@/locales';

// todo: заменить значения GET_LOCALE_RU_URL, GET_LOCALE_EN_URL на настоящий эндпоинт, когда появится. И переключить isOnlineMode в true

/**
 * URL-адрес для загрузки локализации на русском.
 * @constant {string}
 */
const GET_LOCALE_RU_URL = 'https://jsonplaceholder.typicode.com/posts/1';

/**
 * URL-адрес для загрузки локализации на английском.
 * @constant {string}
 */
const GET_LOCALE_EN_URL = 'https://jsonplaceholder.typicode.com/posts/1';

/**
 * Флаг, указывающий, находится ли приложение в онлайн-режиме.
 * @constant {boolean}
 */
const isOnlineMode = false;

/**
 * Асинхронная функция для загрузки локализации в оффлайн-режиме.
 *
 * @param {string} localeName - Имя локали.
 * @returns {Object} - Локализация.
 * @throws {Error} - Ошибка, если локализация не найдена.
 */
const offlineLocaleFetcher = async (localeName) => {
  switch (localeName) {
    case LOCALES.en:
      return LOCALES_FILE.en;

    case LOCALES.ru:
    default:
      return LOCALES_FILE.ru;
  }
};

/**
 * Асинхронная функция для загрузки локализации в онлайн-режиме.
 *
 * @param {string} localeName - Имя локали.
 * @returns {Promise<Object>} - Обещание с локализацией.
 * @throws {Error} - Ошибка при загрузке локализации.
 */
const onlineLocaleFetcher = async (localeName) => {
  try {
    let URL;

    switch (localeName) {
      case LOCALES.en:
        URL = GET_LOCALE_EN_URL;
        break;

      case LOCALES.ru:
      default:
        URL = GET_LOCALE_RU_URL;
    }

    const response = await axios.get(URL);
    const localization = response.data;

    return Promise.resolve(localization);
  } catch (error) {
    return Promise.reject(error);
  }
};

const localeService = {
  /**
   * Асинхронно загружает локализацию на русском языке.
   *
   * @returns {Promise<Object>} - Обещание с локализацией.
   */
  fetchLocaleRU: async () => (
    isOnlineMode
      ? onlineLocaleFetcher(LOCALES.ru)
      : offlineLocaleFetcher(LOCALES.ru)
  ),

  /**
   * Асинхронно загружает локализацию на английском языке.
   *
   * @returns {Promise<Object>} - Обещание с локализацией.
   */
  fetchLocaleEN: async () => (
    isOnlineMode
      ? onlineLocaleFetcher(LOCALES.en)
      : offlineLocaleFetcher(LOCALES.en)
  )
};

export default localeService;
