<template>
  <WCard class="authentication-card">
    <div class="locale-wrapper">
      <LocaleSwitcher/>
    </div>

    <div class="heading-content-wrapper">
      <AuthenticationLogo/>
      <h1 class="heading">
        {{ heading }}
      </h1>
    </div>

    <slot></slot>
  </WCard>
</template>

<script>

import { LocaleSwitcher } from '@/components';
import AuthenticationLogo from './AuthenticationLogo.vue';

export default {
  name: 'AuthenticationCard',
  components: {
    AuthenticationLogo,
    LocaleSwitcher
  },
  props: {
    heading: {
      type: String,
      required: true
    }
  },
  data() {
    return {};
  },
  methods: {
  },
  watch: {
  },
  mounted() {
  },
  destroyed() {
  }
};
</script>

<style lang="css" scoped>

.authentication-card {
  background-color: var(--gray-lightest);
  color: var(--gray);
  padding: 50px 100px;
  position: relative;
  border-radius: 8px;
}

.locale-wrapper {
  position: absolute;
  right: 16px;
  top: 16px;
}

.heading-content-wrapper {
  margin-bottom: 24px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 14px;
}

.heading {
  position: relative;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 106%;
  letter-spacing: 1px;
  margin: 0;

  display: flex;
  align-items: center;
  gap: 4px;
}

.heading-content-wrapper > p {
  margin: 0;
  margin-top: 4px;
}
</style>
