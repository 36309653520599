import Vue from 'vue';
import VueNotifications from 'vue-notifications';
import helpers from '../helpers';
import store from '../store';
import operationsService from './operationsService';
import commonService from './commonService';
import { API_URL } from '@/config';

export default {
  hadleOperationResponse(items) {
    if (store.state.controllerOperations.indexOf(items) == -1) {
      store.commit('setControllerOperations', items);
    }
  },
  startPolling(ctx) {
    const queryIds = this.getPollingIds();
    let counter = 0;
    if (queryIds.length) {
      const pollIteration = setInterval(() => {
        Vue.axios.post(`${API_URL || ''}/api/operations`, { action: 'R', ids: queryIds }).then(
          (response) => {
            counter++;
            response.data.data.itemslist.forEach((operation) => {
              if (operation.status === 'success') {
                let isOperationActual = false;
                store.state.controllerOperations.forEach((storeControllerOperation) => {
                  if (storeControllerOperation.id === operation.id) {
                    isOperationActual = true;
                  }
                });

                store.commit('setControllerOperationStatus', {
                  model_id: operation.model_id,
                  status: operation.status,
                  id: operation.id
                });
                if (isOperationActual) {
                  this.showSuccessPopup(operation, ctx);
                }

                clearInterval(pollIteration);
              }
              if (operation.status === 'error') {
                let isOperationActual = false;

                store.state.controllerOperations.forEach((storeControllerOperation) => {
                  if (storeControllerOperation.id === operation.id) {
                    isOperationActual = true;
                  }
                });

                store.commit('setControllerOperationStatus', {
                  model_id: operation.model_id,
                  status: operation.status,
                  id: operation.id
                });
                // let operationModelName = commonService.radiusNamebyId(operation.model_id);
                VueNotifications.error({ message: ` ${operation.description}` });
              }
            });
            if (!store.state.controllerOperations.length) {
              if (ctx) {
                // ctx.$store.state.editRedirectId = '';
                ctx.refreshControllers('spinner');
              }
              console.warn('Stop polling');
              operationsService.getPedingOperations();
              clearInterval(pollIteration);
            }
          },
          (err) => {}
        );
      }, 1500);
    }
  },
  getPollingIds() {
    const pollingIds = [];
    store.state.controllerOperations.forEach((operation) => {
      if (!pollingIds.includes(operation.id)) {
        if (operation.status === 'pending') {
          pollingIds.push(operation.id);
        }
      }
    });

    return pollingIds;
  },
  showSuccessPopup(operation, ctx) {
    switch (operation.type) {
      case 'U':
        // VueNotifications.success({ message: 'Controller Updated Successfully' });
        VueNotifications.success({ message: ctx.$t('controller.notificationControllerUpdatedSuccessfully') });
        break;
      case 'C':
        // VueNotifications.success({ message: 'Controller Created Successfully' });
        VueNotifications.success({ message: ctx.$t('controller.notificationControllerCreatedSuccessfully') });
        break;
      case 'D':
        // VueNotifications.success({ message: 'Controller Deleted Successfully' });
        VueNotifications.success({ message: ctx.$t('controller.notificationControllerDeletedSuccessfully') });
        break;
      default:
        // VueNotifications.success({ message: 'Controller Successfully' });
        VueNotifications.success({ message: ctx.$t('controller.notificationControlleOperationSuccessfull') });
    }
  }
};
