import commonService from './services/commonService';
import helpers from './helpers';

const customFilters = {
  /* Generic location filter */
  locationFilter: (array, location) => {
    if (location === 'All locations') {
      return array;
    }
    return array.filter((item) => item.base_location === location);
  },

  // Filters for CPE
  cpeSearchQuery: (cpesArray, searchQuery) => {
    if (!cpesArray) {
      return [];
    }
    return cpesArray.filter((item) => {
      if (item.name && item.model.name && item.tags) {
        return (
          item.name.toLowerCase().indexOf(searchQuery.toLowerCase()) !== -1 ||
          item.model.name.toLowerCase().indexOf(searchQuery.toLowerCase()) !== -1 ||
          item.tags.indexOf(searchQuery) !== -1
        );
      }
      if (item.name && item.model && item.model.name) {
        return (
          item.name.toLowerCase().indexOf(searchQuery.toLowerCase()) !== -1 ||
          item.model.name.toLowerCase().indexOf(searchQuery.toLowerCase()) !== -1
        );
      }
      if (item.model && item.model.name) {
        return item.model.name.toLowerCase().indexOf(searchQuery.toLowerCase()) !== -1;
      }
      if (item.tags && item.tags.length && searchQuery) {
        return item.tags.indexOf(searchQuery) !== -1;
      }
      if (searchQuery) {
        return null;
      }
      return item;
    });
  },
  cpeInterfaceCountFilter: (cpesArray, interfacesCount) => {
    if (interfacesCount === null) {
      return cpesArray;
    }
    return cpesArray.filter((item) => {
      if (item.state.wifi) {
        return Object.keys(item.state.wifi).length.toString() == interfacesCount;
      }
      return false;
    });
  },
  cpeConnectionFilter: (cpesArray, connectState) => {
    if (connectState === null) {
      return cpesArray;
    }
    return cpesArray.filter((item) => item.connected === connectState);
  },
  // Filters for WLANs
  wlanSearchQuery: (wlansArray, searchQuery) => {
    if (!wlansArray) {
      return [];
    }
    return wlansArray.filter((item) => {
      if (item.ssid && item.tags) {
        return (
          item.ssid.toLowerCase().indexOf(searchQuery.toLowerCase()) !== -1 || item.tags.indexOf(searchQuery) !== -1
        );
      }
      if (item.ssid) {
        return item.ssid.toLowerCase().indexOf(searchQuery.toLowerCase()) !== -1;
      }
      return item;
    });
  },
  wlanStatusFilter: (wlansArray, statusState) => {
    if (statusState === '') {
      return wlansArray;
    }
    return wlansArray.filter((item) => item.status === statusState);
  },
  wlanSecurityFilter: (wlansArray, securityType) => {
    if (securityType === '') {
      return wlansArray;
    }
    const securityAlias = {
      0: 'open',
      1: 'wpa2personal',
      2: 'wpa2enterprise',
      3: 'wpapersonal',
      4: 'wpaenterprise'
    };
    return wlansArray.filter((item) => {
      if (item.security == null) {
        return item.security === securityAlias[securityType];
      }
      return item.security.type === securityAlias[securityType];
    });
  },
  // Filters for Radius
  radiusSearchQuery: (radiusArray, searchQuery) => {
    if (!radiusArray) {
      return [];
    }
    return radiusArray.filter(
      (item) => item.name.toLowerCase().indexOf(searchQuery.toLowerCase()) !== -1 ||
        item.hostname.toLowerCase().indexOf(searchQuery.toLowerCase()) !== -1
    );
  },
  radiusTypeFilters: (radiusArray, radiusType) => {
    if (radiusType === '') {
      return radiusArray;
    }
    return radiusArray.filter((item) => item.type === radiusType);
  },
  // Filters for Radar
  radarCreatedDateFilter: (radarArray, startTs, endTs) => {
    // console.log(startTs)
    // console.log(endTs)
    // console.log('created')
    if (!radarArray) {
      return [];
    }
    return radarArray.filter((item) => item.create_at >= startTs && item.create_at <= endTs);
  },
  radarUpdatedDateFilter: (radarArray, startTs, endTs) => {
    // console.log('updated')
    if (!radarArray) {
      return [];
    }
    return radarArray.filter((item) => item.last_at >= startTs && item.last_at <= endTs);
  },
  radarSearchQuery: (radarArray, searchQuery) => {
    if (!radarArray) {
      return [];
    }
    const query = searchQuery.toLowerCase();
    return radarArray.filter(
      (item) => item.name.toLowerCase().includes(query) || item.desc.toLowerCase().includes(query)
    );
  },
  radarStatusFilter: (radarArray, status) => {
    if (status === '') {
      return radarArray;
    }
    return radarArray.filter((item) => item.status === status);
  },
  radarTypeFilter: (radarArray, radarType) => {
    if (radarType === '') {
      return radarArray;
    }
    return radarArray.filter((item) => item.type === radarType);
  },
  // Filters for Clients
  clientSearchQuery: (clientsArray, searchQuery) => {
    if (!clientsArray) {
      return [];
    }
    return clientsArray.filter((item) => {
      const mac = helpers.getFormatMac(item.id);
      const cpeName = commonService.cpeNamebyId(item.cpe_id) || '';
      const wlanName = commonService.wlanNamebyId(item.wlan_id) || '';
      if (wlanName) {
        return (
          item.data.description.toLowerCase().indexOf(searchQuery.toLowerCase()) !== -1 ||
          mac.toLowerCase().indexOf(searchQuery.toLowerCase()) !== -1 ||
          cpeName.toLowerCase().indexOf(searchQuery.toLowerCase()) !== -1 ||
          wlanName.toLowerCase().indexOf(searchQuery.toLowerCase()) !== -1
        );
      }
      return (
        item.data.description.toLowerCase().indexOf(searchQuery.toLowerCase()) !== -1 ||
        mac.toLowerCase().indexOf(searchQuery.toLowerCase()) !== -1 ||
        cpeName.toLowerCase().indexOf(searchQuery.toLowerCase()) !== -1
      );
    });
  },

  clientStatSearchQuery: (clientsArray, searchQuery) => {
    if (!clientsArray) {
      return [];
    }
    return clientsArray.filter((item) => {
      const mac = helpers.getFormatMac(item.id);
      return (
        item.data.description.toLowerCase().indexOf(searchQuery.toLowerCase()) !== -1 ||
        mac.toLowerCase().indexOf(searchQuery.toLowerCase()) !== -1
      );
    });
  },

  clientTypeFilters: (clientsArray, clientType) => {
    if (clientType === '') {
      return clientsArray;
    }
    return clientsArray.filter((item) => item.Type === clientType);
  },

  clientStatusFilters: (clientsArray, clientStatus) => {
    if (clientStatus === '') {
      return clientsArray;
    }
    return clientsArray.filter((item) => item.State === clientStatus);
  },

  clientFrequencyFilters: (clientsArray, clientFrequency) => {
    if (clientFrequency === '') {
      return clientsArray;
    }
    return clientsArray.filter((item) => item.freq === clientFrequency);
  },

  // Filters for Events
  eventsTypeFilters(events, type) {
    if (type === '') {
      return events;
    }

    return events.filter((event) => event.level === type);
  },

  monitorEventsTypeFilters(events, type) {
    if (type === '') {
      return events;
    }

    return events.filter((event) => event.level === type);
  },

  cpesTypeFilters(events, type) {
    if (type === '') {
      return events;
    }

    return events.filter((event) => event.level === type);
  },

  clientsTypeFilters(events, type) {
    if (type === '') {
      return events;
    }

    return events.filter((event) => event.level === type);
  },
  // // Filter for system stat
  // locationSearchQuery(systemArray, searchQuery) {
  //   if (!systemArray) {
  //     return [];
  //   }
  //   return systemArray.filter((item) => item.location_name.toLowerCase().indexOf(searchQuery.toLowerCase()) !== -1);
  // },
  // Filter for system stat
  // Fix WNE-2078
  locationSearchQuery(systemArray, searchQuery) {
    if (!systemArray) {
      return [];
    }
    return systemArray.filter((item) => {
      if (item.location_name !== undefined) {
        return item.location_name.toLowerCase().indexOf(searchQuery.toLowerCase()) !== -1;
      }
    });
  },
  // Filter for map
  mapSearchQuery: (mapsArray, searchQuery) => {
    if (!mapsArray) {
      return [];
    }
    return mapsArray.filter((item) => item.name.toLowerCase().indexOf(searchQuery.toLowerCase()) !== -1);
  },

  // Filter fot templates
  templateSearchQuery: (templateArray, searchQuery) => {
    if (!templateArray) {
      return [];
    }
    return templateArray.filter((item) => {
      if (item.name) {
        return (
          item.name.toLowerCase().indexOf(searchQuery.toLowerCase()) !== -1 ||
          item.model_description.toLowerCase().indexOf(searchQuery.toLowerCase()) !== -1
        );
      }
    });
  },

  templateAutoFilter: (templateArray, autoConfig) => {
    if (autoConfig === '') {
      return templateArray;
    }
    const autoAlias = {
      1: true,
      0: false
    };
    return templateArray.filter((item) => item.is_auto == autoAlias[autoConfig]);
  }
};

export default customFilters;
