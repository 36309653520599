import {
  getDashboard,
  getMonitoring,
  getConfiguration,
  getAdministration,
  getLicensing,
  getTroubleshooting,
  getNms
} from './configSections';

import { transformConfigListForCurrentRoute } from './utils';

// формирует конфиг для меню и для SidebarNewStructure и BreadcrumbsNavigtion
const getMenuNavigationConfig = (options) => {
  const { i18nInstance, currentPath, thisInstance } = options;

  const $t = i18nInstance.t.bind(i18nInstance);
  const $can = thisInstance.$can.bind(thisInstance);

  const config = [
    // Dashboard
    getDashboard({ $t, thisInstance, $can }),
    // Monitoring
    getMonitoring({ $t, thisInstance, $can }),
    // Configuration
    getConfiguration({ $t, thisInstance, $can }),
    // Administration
    getAdministration({ $t, thisInstance, $can }),
    // Troubleshooting
    getTroubleshooting({ $t, thisInstance, $can }),
    // NMS
    getNms({ $t, thisInstance, $can }),
    // Licensing
    getLicensing({ $t, thisInstance, $can })
  ];

  // трансформация конфига под currentPath
  return transformConfigListForCurrentRoute({
    config,
    currentPath
  });
};

export {
  getMenuNavigationConfig
};
