<template>
  <p class="d-flex align-items-start">
    <i class="fa fa-exclamation-triangle text-danger mt-q mr-h" />
    <slot />
  </p>
</template>

<script>
export default {
  name: 'WarningLine'
};
</script>
