import Vue from 'vue';
import VueNotifications from 'vue-notifications';
import helpers from '@/helpers';
import { API_URL } from '@/config';

/** Коллбэк функция сложения
 *
 * @param {number} a
 * @param {number} b
 */
function sum(a, b) {
  let prev = a;
  prev += b;
  return prev;
}

export default {
  getRange(ctx, object, subtype) {
    // ctx.$store.commit('enableLoadingSummary');
    ctx.$store.commit('setRequestsStatusForPortalStatisticPage', { object, subtype, status: 'begin' });

    const params = {
      start: moment(ctx.drPickerDate.startDate).unix(),
      stop: moment(ctx.drPickerDate.endDate).unix(),
      accounts: [],
      profiles:
        ctx.selectedProfileInMultiselectForRequest && ctx.selectedProfileInMultiselectForRequest.length ?
          ctx.selectedProfileInMultiselectForRequest :
          [],
      // location_id: ctx.filters.location && ctx.filters.location.id ? ctx.filters.location.id : [],
      location: ctx.filters.location && ctx.filters.location.id ? ctx.filters.location.id : [],
      with_childs: !(ctx.filters && ctx.filters.hasOwnProperty('with_childs') && ctx.filters.with_childs === false),
      object,
      subtype
    };
    // далее прибавление по дню нужно  для задачки WNE-1830
    // из апи данные приходят с таймстампом определенного дня, но сами эти данные за вчерашний день,
    // относительного этого приходящего дня.
    // поэтому тут прибавляем по дню к началу и окончанию запрпашиваемого периода.
    // например:
    // хотим данные с  13 по 19 июля. Данные за 19 июля будут обсчитаны и доступны только 20, а за 13 - 14го.
    // В datepicker'e выбираем с 13 по 19 июля, тут прибавляем по дню и запрос улетает на данные с 14 по 20 июля.
    // Приходят данные с таймстампами с 14 по 20 июля (которые на самом деле содержат данные с 13 по 19)
    if (object === 'timeseries') {
      // params.start = params.start + (24 * 60 * 60); // плюс день
      // params.stop = params.stop + (24 * 60 * 60); // плюс день

      const startFormDatepicker = params.start;
      const stopFormDatepicker = params.stop;
      const tsForStart = new Date(startFormDatepicker * 1000);
      const tsForStop = new Date(stopFormDatepicker * 1000);
      // переводим даты к началу выбранных дней и концу выбранных дней
      tsForStart.setHours(0);
      tsForStart.setMinutes(0);
      tsForStart.setSeconds(0);
      tsForStop.setHours(23);
      tsForStop.setMinutes(59);
      tsForStop.setSeconds(59);
      // прибавляем по дню
      const startForRequest = moment(tsForStart).unix() + 24 * 60 * 60; // плюс один день
      const stopForRequest = moment(tsForStop).unix() + 24 * 60 * 60; // плюс один день
      // перезаписываем для запроса
      params.start = startForRequest;
      params.stop = stopForRequest;
    }
    // NOTE: обнуляемся
    ctx.setNewUsers(0);
    ctx.setNotNewUsers(0);
    ctx.setUniqClients(0);
    ctx.setTotalConnections(0);

    ctx.vendorsChartPie.series[0].data = [];
    ctx.osChartPie.series[0].data = [];
    ctx.localeChartPie.series[0].data = [];
    ctx.typeChartPie.series[0].data = [];
    ctx.authChartPie.series[0].data = [];
    ctx.authenChartPie.series[0].data = [];
    ctx.browserChartPie.series[0].data = [];
    ctx.firstVisitChartPie.series[0].data = [];

    ctx.vendorsChartBar.series = [];
    ctx.osChartBar.series = [];
    ctx.localeChartBar.series = [];
    ctx.typeChartBar.series = [];
    ctx.authChartBar.series = [];
    ctx.authenChartBar.series = [];

    Vue.axios.get(`${API_URL || ''}/api/portal/admin/range`, { params }).then(
      (response) => {
        // ctx.$store.commit('disableLoadingSummary');
        if (response.data.status === 'success') {
          ctx.$store.commit('setRequestsStatusForPortalStatisticPage', {
            object,
            subtype,
            status: 'end'
          });
          if (object === 'pie') {
            let { data } = response.data;
            // далее подсчитываем данные по всем профилям
            data = this.combineDataForPieCharts(data);
            const { values, counts } = data[0];
            // const allCounts = counts.reduce((prev, curr) => (prev += curr), 0);

            if (subtype === 'vendor') {
              this.fillPieSeries(ctx, 'vendorsChartPie', 'vendor', data[0]);
              return;
            }
            if (subtype === 'os') {
              this.fillPieSeries(ctx, 'osChartPie', 'os', data[0]);
              return;
            }
            if (subtype === 'type') {
              this.fillPieSeries(ctx, 'typeChartPie', 'type', data[0]);
              return;
            }
            if (subtype === 'locale') {
              this.fillPieSeries(ctx, 'localeChartPie', 'locale', data[0]);
              return;
            }
            if (subtype === 'auth') {
              this.fillPieSeries(ctx, 'authChartPie', 'auth', data[0]);
              return;
            }
            if (subtype === 'authen') {
              this.fillPieSeries(ctx, 'authenChartPie', 'authen', data[0]);
              return;
            }
            if (subtype === 'browser') {
              this.fillPieSeries(ctx, 'browserChartPie', 'browser', data[0]);
              return;
            }
            if (subtype === 'all_visit') {
              ctx.setTotalConnections(data[0].total);
              return;
            }
            if (subtype === 'unique_visit') {
              ctx.setUniqClients(data[0].total);
              return;
            }
            if (subtype === 'first_visit') {
              this.fillPieSeries(ctx, 'firstVisitChartPie', 'first_visit', data[0]);

              const notNewUsersArray = [];
              const newUsers = counts
                .filter((el, i) => {
                  if (values[i] === 'false') notNewUsersArray.push(el);
                  return values[i] === 'true';
                })
                .reduce(sum, 0);
              const notNewUsers = notNewUsersArray.reduce(sum, 0);
              ctx.setNewUsers(newUsers);
              ctx.setNotNewUsers(notNewUsers);
              return;
            }
          }
          if (object === 'timeseries') {
            let { data } = response.data;
            // data = this.cutNonInRangeTimestampsForTimeseries(data, params.start);
            data = this.changeDatesToOneDayBeforeDates(data);
            if (subtype === 'first_visit') {
              this.fillBarSeries(ctx, 'usersChartBar', 'first_visit', data);
            }
            if (subtype === 'vendor') {
              this.fillBarSeries(ctx, 'vendorsChartBar', 'vendor', data);
            }
            if (subtype === 'os') {
              this.fillBarSeries(ctx, 'osChartBar', 'os', data);
            }
            if (subtype === 'type') {
              this.fillBarSeries(ctx, 'typeChartBar', 'type', data);
            }
            if (subtype === 'locale') {
              this.fillBarSeries(ctx, 'localeChartBar', 'locale', data);
            }
            if (subtype === 'auth') {
              this.fillBarSeries(ctx, 'authChartBar', 'auth', data);
            }
            if (subtype === 'authen') {
              this.fillBarSeries(ctx, 'authenChartBar', 'authen', data);
            }
          }
        } else if (response.data.status === 'error') {
          VueNotifications.error({ message: response.data.description });
          // ctx.$store.commit('disableLoadingSummary');
          ctx.$store.commit('setRequestsStatusForPortalStatisticPage', {
            object,
            subtype,
            status: 'end'
          });
        }
      },
      (err) => {
        // VueNotifications.error({ message: err });

        // show errors in the console instead of the alert window
        if (
          Object.prototype.hasOwnProperty.call(window, 'isShowAxiosErrorInConsole') &&
          typeof window.isShowAxiosErrorInConsole === 'function' &&
          window.isShowAxiosErrorInConsole(err)
        ) {
          window.showAxiosErrInConsole(err);
        } else {
          VueNotifications.error({ message: err });
        }

        ctx.error = err;
        // ctx.$store.commit('disableLoadingSummary');
        ctx.$store.commit('setRequestsStatusForPortalStatisticPage', {
          object,
          subtype,
          status: 'end'
        });
      }
    );
  },
  getRangeForPreviousPeriod(ctx, object, subtype) {
    // ctx.$store.commit('enableLoadingSummary');
    ctx.$store.commit('setRequestsStatusForPortalStatisticPageForPreviousPeriod', {
      object,
      subtype,
      status: 'begin'
    });

    // берем подсчитанные старт и стоп для предидущего переода
    const previousPeriodstart = moment(ctx.drPickerDateForPreviousPeriod.startDate).unix();
    const previousPeriodstop = moment(ctx.drPickerDateForPreviousPeriod.endDate).unix();

    const params = {
      start: previousPeriodstart,
      stop: previousPeriodstop,
      accounts: [],
      profiles:
        ctx.selectedProfileInMultiselectForRequest && ctx.selectedProfileInMultiselectForRequest.length ?
          ctx.selectedProfileInMultiselectForRequest :
          [],
      // location_id: ctx.filters.location && ctx.filters.location.id ? ctx.filters.location.id : [],
      location: ctx.filters.location && ctx.filters.location.id ? ctx.filters.location.id : [],
      with_childs: !(ctx.filters && ctx.filters.hasOwnProperty('with_childs') && ctx.filters.with_childs === false),
      object,
      subtype
    };
    // // далее прибавление по дню нужно  для задачки WNE-1830
    // // из апи данные приходят с таймстампом определенного дня, но сами эти данные за вчерашний день,
    // // относительного этого приходящего дня.
    // // поэтому тут прибавляем по дню к началу и окончанию запрпашиваемого периода.
    // // например:
    // // хотим данные с  13 по 19 июля. Данные за 19 июля будут обсчитаны и доступны только 20, а за 13 - 14го.
    // // В datepicker'e выбираем с 13 по 19 июля, тут прибавляем по дню и запрос улетает на данные с 14 по 20 июля.
    // // Приходят данные с таймстампами с 14 по 20 июля (которые на самом деле содержат данные с 13 по 19)
    // if (object === 'timeseries') {
    //   // params.start = params.start + (24 * 60 * 60); // плюс день
    //   // params.stop = params.stop + (24 * 60 * 60); // плюс день
    //
    //   const startFormDatepicker = params.start;
    //   const stopFormDatepicker = params.stop;
    //   const tsForStart = new Date(startFormDatepicker * 1000);
    //   const tsForStop = new Date(stopFormDatepicker * 1000);
    //   // переводим даты к началу выбранных дней и концу выбранных дней
    //   tsForStart.setHours(0);
    //   tsForStart.setMinutes(0);
    //   tsForStart.setSeconds(0);
    //   tsForStop.setHours(23);
    //   tsForStop.setMinutes(59);
    //   tsForStop.setSeconds(59);
    //   // прибавляем по дню
    //   const startForRequest = moment(tsForStart).unix() + 24 * 60 * 60; // плюс один день
    //   const stopForRequest = moment(tsForStop).unix() + 24 * 60 * 60; // плюс один день
    //   // перезаписываем для запроса
    //   params.start = startForRequest;
    //   params.stop = stopForRequest;
    // }
    // NOTE: обнуляемся
    ctx.setNewUsersForPreviousPeriod(0);
    ctx.setNotNewUsersForPreviousPeriod(0);
    ctx.setUniqClientsForPreviousPeriod(0);
    ctx.setTotalConnectionsForPreviousPeriod(0);

    // ctx.vendorsChartPie.series[0].data = [];
    // ctx.osChartPie.series[0].data = [];
    // ctx.localeChartPie.series[0].data = [];
    // ctx.typeChartPie.series[0].data = [];
    // ctx.authChartPie.series[0].data = [];
    // ctx.authenChartPie.series[0].data = [];
    // ctx.browserChartPie.series[0].data = [];
    // ctx.firstVisitChartPie.series[0].data = [];
    //
    // ctx.vendorsChartBar.series = [];
    // ctx.osChartBar.series = [];
    // ctx.localeChartBar.series = [];
    // ctx.typeChartBar.series = [];
    // ctx.authChartBar.series = [];
    // ctx.authenChartBar.series = [];

    Vue.axios.get(`${API_URL || ''}/api/portal/admin/range`, { params }).then(
      (response) => {
        // ctx.$store.commit('disableLoadingSummary');
        if (response.data.status === 'success') {
          ctx.$store.commit('setRequestsStatusForPortalStatisticPageForPreviousPeriod', {
            object,
            subtype,
            status: 'end'
          });
          if (object === 'pie') {
            let { data } = response.data;
            // далее подсчитываем данные по всем профилям
            data = this.combineDataForPieCharts(data);
            const { values, counts } = data[0];
            // const allCounts = counts.reduce((prev, curr) => (prev += curr), 0);

            if (subtype === 'all_visit') {
              ctx.setTotalConnectionsForPreviousPeriod(data[0].total);
              return;
            }
            if (subtype === 'unique_visit') {
              ctx.setUniqClientsForPreviousPeriod(data[0].total);
              return;
            }
            if (subtype === 'first_visit') {
              const notNewUsersArray = [];
              const newUsers = counts
                .filter((el, i) => {
                  if (values[i] === 'false') notNewUsersArray.push(el);
                  return values[i] === 'true';
                })
                .reduce(sum, 0);
              const notNewUsers = notNewUsersArray.reduce(sum, 0);
              ctx.setNewUsersForPreviousPeriod(newUsers);
              ctx.setNotNewUsersForPreviousPeriod(notNewUsers);
            }
          }
          // if (object === 'timeseries') {
          //   let { data } = response.data;
          //   // data = this.cutNonInRangeTimestampsForTimeseries(data, params.start);
          //   data = this.changeDatesToOneDayBeforeDates(data);
          //   if (subtype === 'first_visit') {
          //     this.fillBarSeries(ctx, 'usersChartBar', 'first_visit', data);
          //   }
          //   if (subtype === 'vendor') {
          //     this.fillBarSeries(ctx, 'vendorsChartBar', 'vendor', data);
          //   }
          //   if (subtype === 'os') {
          //     this.fillBarSeries(ctx, 'osChartBar', 'os', data);
          //   }
          //   if (subtype === 'type') {
          //     this.fillBarSeries(ctx, 'typeChartBar', 'type', data);
          //   }
          //   if (subtype === 'locale') {
          //     this.fillBarSeries(ctx, 'localeChartBar', 'locale', data);
          //   }
          //   if (subtype === 'auth') {
          //     this.fillBarSeries(ctx, 'authChartBar', 'auth', data);
          //   }
          //   if (subtype === 'authen') {
          //     this.fillBarSeries(ctx, 'authenChartBar', 'authen', data);
          //   }
          // }
        } else if (response.data.status === 'error') {
          VueNotifications.error({ message: response.data.description });
          // ctx.$store.commit('disableLoadingSummary');
          ctx.$store.commit('setRequestsStatusForPortalStatisticPageForPreviousPeriod', {
            object,
            subtype,
            status: 'end'
          });
        }
      },
      (err) => {
        // VueNotifications.error({ message: err });

        // show errors in the console instead of the alert window
        if (
          Object.prototype.hasOwnProperty.call(window, 'isShowAxiosErrorInConsole') &&
          typeof window.isShowAxiosErrorInConsole === 'function' &&
          window.isShowAxiosErrorInConsole(err)
        ) {
          window.showAxiosErrInConsole(err);
        } else {
          VueNotifications.error({ message: err });
        }

        ctx.error = err;
        // ctx.$store.commit('disableLoadingSummary');
        ctx.$store.commit('setRequestsStatusForPortalStatisticPageForPreviousPeriod', {
          object,
          subtype,
          status: 'end'
        });
      }
    );
  },

  getVouchersStat(ctx, object, subtype) {
    ctx.$store.commit('enableLoadingSummary');
    ctx.$store.commit('loadingPortalVouchersStatBeging');

    const params = {
      start: moment(ctx.drPickerDate.startDate).unix(),
      stop: moment(ctx.drPickerDate.endDate).unix(),
      accounts: ctx.filters.accounts.id ? [ctx.filters.accounts.id] : [],
      tariffs: ctx.filters.tariffs.id ? [ctx.filters.tariffs.id] : [],
      location: ctx.$store.getters.getDefaultLocationForRequests(ctx),
      with_childs: ctx.$store.getters.getDefaultWithChildsForRequests(ctx),
      object,
      subtype
    };

    ctx.tariffsChartPie.series[0].data = [];

    Vue.axios.get(`${API_URL || ''}/api/portal/admin/range`, { params }).then(
      (response) => {
        ctx.$store.commit('disableLoadingSummary');
        if (response.data.status === 'success') {
          ctx.$store.commit('loadingPortalVouchersStatEnd');
          if (object === 'voucher') {
            const { data } = response.data;
            // same as allCounts
            const allTariffs = data?.map((el) => el.tariff);

            // same as values
            const uniqueTariffs = [...new Set(allTariffs)];

            // same as counts
            const counts = uniqueTariffs.map((uniq) => allTariffs.filter((tariff) => tariff === uniq).length);

            const tariffs = uniqueTariffs.map((el, i) => ({
              name: ctx.getTariffNameByID(el) || ctx.$t('portalStats.other'),
              y: (counts[i] / allTariffs.length) * 100,
              amount: counts[i]
            }));

            ctx.tariffsChartPie.series[0].data = tariffs;
            ctx.$store.commit('setPortalVouchersStatList', data);
            ctx.setVouchersAmount(data ? data.length : 0);
          }
        } else if (response.data.status === 'error') {
          VueNotifications.error({ message: response.data.description });
          ctx.$store.commit('loadingPortalVouchersStatEnd');
          ctx.$store.commit('disableLoadingSummary');
        }
      },
      (err) => {
        ctx.$store.commit('loadingPortalVouchersStatEnd');
        // VueNotifications.error({ message: err });

        // show errors in the console instead of the alert window
        if (
          Object.prototype.hasOwnProperty.call(window, 'isShowAxiosErrorInConsole') &&
          typeof window.isShowAxiosErrorInConsole === 'function' &&
          window.isShowAxiosErrorInConsole(err)
        ) {
          window.showAxiosErrInConsole(err);
        } else {
          VueNotifications.error({ message: err });
        }

        ctx.error = err;
        ctx.$store.commit('disableLoadingSummary');
      }
    );
  },
  getConnectionsDataForMarketerDashboard(ctx, object, subtype) {
    ctx.$store.commit('startRequestToLoadConnectionsDataForMarketerDashboard');
    //  const start = moment().startOf('week').valueOf();
    //  const stop = moment().endOf('week').valueOf();

    // теперь выводим за последние 7 дней, а не за текущую неделю
    const sevenDays = 7 * 24 * 60 * 60 * 1000;
    const start = moment().startOf('day').valueOf() - sevenDays;
    const stop = moment().endOf('day').valueOf();
    const params = {
      start: moment(start).unix(),
      stop: moment(stop).unix(),
      accounts: [],
      profiles: [],
      location: ctx.$store.getters.getDefaultLocationForRequests(ctx),
      with_childs: ctx.$store.getters.getDefaultWithChildsForRequests(ctx),
      object,
      subtype
    };
    // NOTE: обнуляемся
    ctx.setNewUsersForMarketerDashBoard(0);
    ctx.setNotNewUsersForMarketerDashBoard(0);
    ctx.setUniqClientsForMarketerDashBoard(0);
    ctx.setTotalConnectionsForMarketerDashBoard(0);

    Vue.axios.get(`${API_URL || ''}/api/portal/admin/range`, { params }).then(
      (response) => {
        if (response.data.status === 'success') {
          ctx.$store.commit('stopRequestToLoadConnectionsDataForMarketerDashboard');
          if (object === 'pie') {
            let { data } = response.data;
            // далее подсчитываем данные по всем профилям
            data = this.combineDataForPieCharts(data);
            if (data === undefined) {
              data = [{ values: [], counts: [], total: 0 }];
            }
            const { values, counts } = data[0];
            if (subtype === 'all_visit') {
              ctx.setTotalConnectionsForMarketerDashBoard(data[0].total);
              return;
            }
            if (subtype === 'unique_visit') {
              ctx.setUniqClientsForMarketerDashBoard(data[0].total);
              return;
            }
            if (subtype === 'first_visit') {
              const notNewUsersArray = [];
              const newUsers = counts
                .filter((el, i) => {
                  if (values[i] === 'false') notNewUsersArray.push(el);
                  return values[i] === 'true';
                })
                .reduce(sum, 0);
              const notNewUsers = notNewUsersArray.reduce(sum, 0);
              ctx.setNewUsersForMarketerDashBoard(newUsers);
              ctx.setNotNewUsersForMarketerDashBoard(notNewUsers);
            }
          }
          // if (object === 'timeseries') {
          //   const { data } = response.data;
          //   const { values, dates } = data;
          //   const keysVal = Object.keys(values);
          //   const xAxis = dates.map((el) => ctx.moment(el * 1000).format('DD-MM-YY'));
          //   // console.log(average, 'average');
          //
          //   // NOTE: считаем среднее число
          //   const valuesVal = Object.values(values);
          //   const averageData = [];
          //   for (let i = 0; i < valuesVal[0].length; i++) {
          //     let sum = 0;
          //     for (let j = 0; j < valuesVal.length; j++) {
          //       sum += valuesVal[j][i];
          //     }
          //     const av = sum / valuesVal.length;
          //     averageData.push(+av.toFixed(2));
          //   }
          //
          // }
        } else if (response.data.status === 'error') {
          VueNotifications.error({ message: response.data.description });
          ctx.$store.commit('stopRequestToLoadConnectionsDataForMarketerDashboard');
        }
      },
      (err) => {
        // VueNotifications.error({ message: err });

        // show errors in the console instead of the alert window
        if (
          Object.prototype.hasOwnProperty.call(window, 'isShowAxiosErrorInConsole') &&
          typeof window.isShowAxiosErrorInConsole === 'function' &&
          window.isShowAxiosErrorInConsole(err)
        ) {
          window.showAxiosErrInConsole(err);
        } else {
          VueNotifications.error({ message: err });
        }

        ctx.error = err;
        ctx.$store.commit('stopRequestToLoadConnectionsDataForMarketerDashboard');
      }
    );
  },
  // getConnectionsDataForPortalDashboard(ctx, object, subtype) {
  //   // ctx.$store.commit('enableLoadingSummary');
  //   ctx.$store.commit('setRequestsStatusForPortalDashboardPage', { object, subtype, status: 'begin' });
  //   // const start = moment().startOf('week').valueOf();
  //   // const stop = moment().endOf('week').valueOf();
  //
  //   // теперь выводим за последние 7 дней, а не за текущую неделю
  //   const sevenDays = 7 * 24 * 60 * 60 * 1000;
  //   const start = moment().startOf('day').valueOf() - sevenDays;
  //   const stop = moment().endOf('day').valueOf();
  //   const params = {
  //     start: moment(start).unix(),
  //     stop: moment(stop).unix(),
  //     accounts: [],
  //     profiles: [],
  //     object,
  //     subtype
  //   };
  //   // NOTE: обнуляемся
  //   ctx.setNewUsersForMarketerDashBoard(0);
  //   ctx.setNotNewUsersForMarketerDashBoard(0);
  //   ctx.setUniqClientsForMarketerDashBoard(0);
  //   ctx.setTotalConnectionsForMarketerDashBoard(0);
  //   ctx.authChartBar.series = [];
  //   ctx.authenChartBar.series = [];
  //
  //   Vue.axios.get(`${API_URL || ''}/api/portal/admin/range`, { params }).then(
  //     (response) => {
  //       // ctx.$store.commit('disableLoadingSummary');
  //       if (response.data.status === 'success') {
  //         ctx.$store.commit('setRequestsStatusForPortalDashboardPage', {
  //           object,
  //           subtype,
  //           status: 'end'
  //         });
  //         if (object === 'pie') {
  //           // console.log(response.data)
  //           let { data } = response.data;
  //           // далее подсчитываем данные по всем профилям
  //           data = this.combineDataForPieCharts(data);
  //           if (data === undefined || data.length === 0) {
  //             data = [
  //               {
  //                 values: [],
  //                 counts: [],
  //                 total: 0
  //               }
  //             ];
  //           }
  //           const { values, counts } = data[0];
  //           if (subtype === 'all_visit') {
  //             ctx.setTotalConnectionsForMarketerDashBoard(data[0].total);
  //             return;
  //           }
  //           if (subtype === 'unique_visit') {
  //             ctx.setUniqClientsForMarketerDashBoard(data[0].total);
  //             return;
  //           }
  //           if (subtype === 'first_visit') {
  //             const notNewUsersArray = [];
  //             const newUsers = counts
  //               .filter((el, i) => {
  //                 if (values[i] === 'false') notNewUsersArray.push(el);
  //                 return values[i] === 'true';
  //               })
  //               .reduce(sum, 0);
  //             const notNewUsers = notNewUsersArray.reduce(sum, 0);
  //             ctx.setNewUsersForMarketerDashBoard(newUsers);
  //             ctx.setNotNewUsersForMarketerDashBoard(notNewUsers);
  //           }
  //           if (subtype === 'authen') {
  //             // console.log(data)
  //             this.fillPieSeries(ctx, 'authenChartPie', 'authen', data[0]);
  //             return;
  //           }
  //           if (subtype === 'auth') {
  //             this.fillPieSeries(ctx, 'authChartPie', 'auth', data[0]);
  //             return;
  //           }
  //           if (subtype === 'os') {
  //             this.fillPieSeries(ctx, 'osChartPie', 'os', data[0]);
  //             return;
  //           }
  //         }
  //         if (object === 'timeseries') {
  //           const { data } = response.data;
  //           if (subtype === 'auth') {
  //             this.fillBarSeries(ctx, 'authChartBar', 'auth', data);
  //             // console.log(data);
  //           }
  //           if (subtype === 'authen') {
  //             this.fillBarSeries(ctx, 'authenChartBar', 'authen', data);
  //             // console.log(data);
  //           }
  //           if (subtype === 'os') {
  //             this.fillBarSeries(ctx, 'osChartBar', 'os', data);
  //           }
  //         }
  //       } else if (response.data.status === 'error') {
  //         VueNotifications.error({ message: response.data.description });
  //         // ctx.$store.commit('disableLoadingSummary');
  //         ctx.$store.commit('setRequestsStatusForPortalDashboardPage', {
  //           object,
  //           subtype,
  //           status: 'end'
  //         });
  //       }
  //     },
  //     (err) => {
  //       // VueNotifications.error({ message: err });
  //
  //       // show errors in the console instead of the alert window
  //       if (
  //         Object.prototype.hasOwnProperty.call(window, 'isShowAxiosErrorInConsole') &&
  //         typeof window.isShowAxiosErrorInConsole === 'function' &&
  //         window.isShowAxiosErrorInConsole(err)
  //       ) {
  //         window.showAxiosErrInConsole(err);
  //       } else {
  //         VueNotifications.error({ message: err });
  //       }
  //
  //       ctx.error = err;
  //       // ctx.$store.commit('disableLoadingSummary');
  //       ctx.$store.commit('setRequestsStatusForPortalDashboardPage', {
  //         object,
  //         subtype,
  //         status: 'end'
  //       });
  //     }
  //   );
  // },

  getConnectionsDataForPortalDashboard(ctx, object, subtype, useLocationFilter = false) {
    const isUseLocationFilters = useLocationFilter
    // ctx.$store.commit('enableLoadingSummary');
    ctx.$store.commit('setRequestsStatusForPortalDashboardPage', { object, subtype, status: 'begin' });
    // const start = moment().startOf('week').valueOf();
    // const stop = moment().endOf('week').valueOf();

    // теперь выводим за последние 7 дней, а не за текущую неделю
    const sevenDays = 7 * 24 * 60 * 60 * 1000;
    const start = moment().startOf('day').valueOf() - sevenDays;
    const stop = moment().endOf('day').valueOf();
    const params = {
      start: moment(start).unix(),
      stop: moment(stop).unix(),
      accounts: [],
      profiles: [],
      object,
      subtype
    };

    if (!isUseLocationFilters) {
      params.with_childs = ctx.$store.getters.getDefaultWithChildsForRequests(ctx)
    }
    if (isUseLocationFilters) {
      if (ctx.filters.location === 'All locations') {
        // params.location_id = ctx.$store.state.requestBaseUserLocationIfNoSelected ? ctx.userBaseLocation : undefined;
        params.location = ctx.$store.state.requestBaseUserLocationIfNoSelected ? ctx.userBaseLocation : undefined;
        params.with_childs = true;
      } else {
        // params.location_id = ctx.filters.location;
        params.location = ctx.filters.location;
        params.with_childs = ctx.filters.with_childs;
      };
    }

    // NOTE: обнуляемся
    ctx.setNewUsersForMarketerDashBoard(0);
    ctx.setNotNewUsersForMarketerDashBoard(0);
    ctx.setUniqClientsForMarketerDashBoard(0);
    ctx.setTotalConnectionsForMarketerDashBoard(0);
    ctx.authChartBar.series = [];
    ctx.authenChartBar.series = [];

    Vue.axios.get(`${API_URL || ''}/api/portal/admin/range`, { params }).then(
      (response) => {
        // ctx.$store.commit('disableLoadingSummary');
        if (response.data.status === 'success') {
          ctx.$store.commit('setRequestsStatusForPortalDashboardPage', {
            object,
            subtype,
            status: 'end'
          });
          if (object === 'pie') {
            // console.log(response.data)
            let { data } = response.data;
            // далее подсчитываем данные по всем профилям
            data = this.combineDataForPieCharts(data);
            if (data === undefined || data.length === 0) {
              data = [
                {
                  values: [],
                  counts: [],
                  total: 0
                }
              ];
            }
            const { values, counts } = data[0];
            if (subtype === 'all_visit') {
              ctx.setTotalConnectionsForMarketerDashBoard(data[0].total);
              return;
            }
            if (subtype === 'unique_visit') {
              ctx.setUniqClientsForMarketerDashBoard(data[0].total);
              return;
            }
            if (subtype === 'first_visit') {
              const notNewUsersArray = [];
              const newUsers = counts
                .filter((el, i) => {
                  if (values[i] === 'false') notNewUsersArray.push(el);
                  return values[i] === 'true';
                })
                .reduce(sum, 0);
              const notNewUsers = notNewUsersArray.reduce(sum, 0);
              ctx.setNewUsersForMarketerDashBoard(newUsers);
              ctx.setNotNewUsersForMarketerDashBoard(notNewUsers);
            }
            if (subtype === 'authen') {
              // console.log(data)
              this.fillPieSeries(ctx, 'authenChartPie', 'authen', data[0]);
              return;
            }
            if (subtype === 'auth') {
              this.fillPieSeries(ctx, 'authChartPie', 'auth', data[0]);
              return;
            }
            if (subtype === 'os') {
              this.fillPieSeries(ctx, 'osChartPie', 'os', data[0]);
              return;
            }
          }
          if (object === 'timeseries') {
            const { data } = response.data;
            if (subtype === 'auth') {
              this.fillBarSeries(ctx, 'authChartBar', 'auth', data);
              // console.log(data);
            }
            if (subtype === 'authen') {
              this.fillBarSeries(ctx, 'authenChartBar', 'authen', data);
              // console.log(data);
            }
            if (subtype === 'os') {
              this.fillBarSeries(ctx, 'osChartBar', 'os', data);
            }
          }
        } else if (response.data.status === 'error') {
          VueNotifications.error({ message: response.data.description });
          // ctx.$store.commit('disableLoadingSummary');
          ctx.$store.commit('setRequestsStatusForPortalDashboardPage', {
            object,
            subtype,
            status: 'end'
          });
        }
      },
      (err) => {
        // VueNotifications.error({ message: err });

        // show errors in the console instead of the alert window
        if (
          Object.prototype.hasOwnProperty.call(window, 'isShowAxiosErrorInConsole') &&
          typeof window.isShowAxiosErrorInConsole === 'function' &&
          window.isShowAxiosErrorInConsole(err)
        ) {
          window.showAxiosErrInConsole(err);
        } else {
          VueNotifications.error({ message: err });
        }

        ctx.error = err;
        // ctx.$store.commit('disableLoadingSummary');
        ctx.$store.commit('setRequestsStatusForPortalDashboardPage', {
          object,
          subtype,
          status: 'end'
        });
      }
    );
  },

  // getConnectionsDataForPortalDashboardForForPreviousPeriod(ctx, object, subtype) {
  //   // ctx.$store.commit('enableLoadingSummary');
  //   ctx.$store.commit('setRequestsStatusForPortalDashboardPageForPreviousPeriod', {
  //     object,
  //     subtype,
  //     status: 'begin'
  //   });
  //   // const start = moment().startOf('week').valueOf();
  //   // const stop = moment().endOf('week').valueOf();
  //
  //   // загружаем переод 7 дней перед текущим периодом в 7 дней (от 14 дней назад до 7 дней назад)
  //   const sevenDays = 7 * 24 * 60 * 60 * 1000;
  //   const start = moment().startOf('day').valueOf() - sevenDays - sevenDays;
  //   const stop = moment().startOf('day').valueOf() - sevenDays - 1;
  //   const params = {
  //     start: moment(start).unix(),
  //     stop: moment(stop).unix(),
  //     accounts: [],
  //     profiles: [],
  //     object,
  //     subtype
  //   };
  //   // NOTE: обнуляемся
  //   ctx.setNewUsersForMarketerDashBoardForPreviousPeriod(0);
  //   ctx.setNotNewUsersForMarketerDashBoardForPreviousPeriod(0);
  //   ctx.setUniqClientsForMarketerDashBoardForPreviousPeriod(0);
  //   ctx.setTotalConnectionsForMarketerDashBoardForPreviousPeriod(0);
  //   // ctx.authChartBarForPreviousPeriod.series = [];
  //   // ctx.authenChartBarForPreviousPeriod.series = [];
  //
  //   Vue.axios.get(`${API_URL || ''}/api/portal/admin/range`, { params }).then(
  //     (response) => {
  //       // ctx.$store.commit('disableLoadingSummary');
  //       if (response.data.status === 'success') {
  //         ctx.$store.commit('setRequestsStatusForPortalDashboardPageForPreviousPeriod', {
  //           object,
  //           subtype,
  //           status: 'end'
  //         });
  //         if (object === 'pie') {
  //           // console.log(response.data)
  //           let { data } = response.data;
  //           // далее подсчитываем данные по всем профилям
  //           data = this.combineDataForPieCharts(data);
  //           if (data === undefined || data.length === 0) {
  //             data = [
  //               {
  //                 values: [],
  //                 counts: [],
  //                 total: 0
  //               }
  //             ];
  //           }
  //           const { values, counts } = data[0];
  //           if (subtype === 'all_visit') {
  //             ctx.setTotalConnectionsForMarketerDashBoardForPreviousPeriod(data[0].total);
  //             return;
  //           }
  //           if (subtype === 'unique_visit') {
  //             ctx.setUniqClientsForMarketerDashBoardForPreviousPeriod(data[0].total);
  //             return;
  //           }
  //           if (subtype === 'first_visit') {
  //             const notNewUsersArray = [];
  //             const newUsers = counts
  //               .filter((el, i) => {
  //                 if (values[i] === 'false') notNewUsersArray.push(el);
  //                 return values[i] === 'true';
  //               })
  //               .reduce(sum, 0);
  //             const notNewUsers = notNewUsersArray.reduce(sum, 0);
  //             ctx.setNewUsersForMarketerDashBoardForPreviousPeriod(newUsers);
  //             ctx.setNotNewUsersForMarketerDashBoardForPreviousPeriod(notNewUsers);
  //           }
  //           if (subtype === 'authen') {
  //             // console.log(data)
  //             this.fillPieSeries(ctx, 'authenChartPieForPreviousPeriod', 'authen', data[0]);
  //             return;
  //           }
  //           if (subtype === 'auth') {
  //             this.fillPieSeries(ctx, 'authChartPieForPreviousPeriod', 'auth', data[0]);
  //           }
  //         }
  //       } else if (response.data.status === 'error') {
  //         VueNotifications.error({ message: response.data.description });
  //         // ctx.$store.commit('disableLoadingSummary');
  //         ctx.$store.commit('setRequestsStatusForPortalDashboardPageForPreviousPeriod', {
  //           object,
  //           subtype,
  //           status: 'end'
  //         });
  //       }
  //     },
  //     (err) => {
  //       // VueNotifications.error({ message: err });
  //
  //       // show errors in the console instead of the alert window
  //       if (
  //         Object.prototype.hasOwnProperty.call(window, 'isShowAxiosErrorInConsole') &&
  //         typeof window.isShowAxiosErrorInConsole === 'function' &&
  //         window.isShowAxiosErrorInConsole(err)
  //       ) {
  //         window.showAxiosErrInConsole(err);
  //       } else {
  //         VueNotifications.error({ message: err });
  //       }
  //
  //       ctx.error = err;
  //       // ctx.$store.commit('disableLoadingSummary');
  //       ctx.$store.commit('setRequestsStatusForPortalDashboardPageForPreviousPeriod', {
  //         object,
  //         subtype,
  //         status: 'end'
  //       });
  //     }
  //   );
  // },
  getConnectionsDataForPortalDashboardForForPreviousPeriod(ctx, object, subtype, useLocationFilter = false) {
     const isUseLocationFilters = useLocationFilter
    // ctx.$store.commit('enableLoadingSummary');
    ctx.$store.commit('setRequestsStatusForPortalDashboardPageForPreviousPeriod', {
      object,
      subtype,
      status: 'begin'
    });
    // const start = moment().startOf('week').valueOf();
    // const stop = moment().endOf('week').valueOf();

    // загружаем переод 7 дней перед текущим периодом в 7 дней (от 14 дней назад до 7 дней назад)
    const sevenDays = 7 * 24 * 60 * 60 * 1000;
    const start = moment().startOf('day').valueOf() - sevenDays - sevenDays;
    const stop = moment().startOf('day').valueOf() - sevenDays - 1;
    const params = {
      start: moment(start).unix(),
      stop: moment(stop).unix(),
      accounts: [],
      profiles: [],
      object,
      subtype
    };

    if (!isUseLocationFilters) {
      params.with_childs = ctx.$store.getters.getDefaultWithChildsForRequests(ctx)
    }

    if (isUseLocationFilters) {
      if (ctx.filters.location === 'All locations') {
        // params.location_id = ctx.$store.state.requestBaseUserLocationIfNoSelected ? ctx.userBaseLocation : undefined;
        params.location = ctx.$store.state.requestBaseUserLocationIfNoSelected ? ctx.userBaseLocation : undefined;
        params.with_childs = true;
      } else {
        // params.location_id = ctx.filters.location;
        params.location = ctx.filters.location;
        params.with_childs = ctx.filters.with_childs;
      };
    }
    // NOTE: обнуляемся
    ctx.setNewUsersForMarketerDashBoardForPreviousPeriod(0);
    ctx.setNotNewUsersForMarketerDashBoardForPreviousPeriod(0);
    ctx.setUniqClientsForMarketerDashBoardForPreviousPeriod(0);
    ctx.setTotalConnectionsForMarketerDashBoardForPreviousPeriod(0);
    // ctx.authChartBarForPreviousPeriod.series = [];
    // ctx.authenChartBarForPreviousPeriod.series = [];

    Vue.axios.get(`${API_URL || ''}/api/portal/admin/range`, { params }).then(
      (response) => {
        // ctx.$store.commit('disableLoadingSummary');
        if (response.data.status === 'success') {
          ctx.$store.commit('setRequestsStatusForPortalDashboardPageForPreviousPeriod', {
            object,
            subtype,
            status: 'end'
          });
          if (object === 'pie') {
            // console.log(response.data)
            let { data } = response.data;
            // далее подсчитываем данные по всем профилям
            data = this.combineDataForPieCharts(data);
            if (data === undefined || data.length === 0) {
              data = [
                {
                  values: [],
                  counts: [],
                  total: 0
                }
              ];
            }
            const { values, counts } = data[0];
            if (subtype === 'all_visit') {
              ctx.setTotalConnectionsForMarketerDashBoardForPreviousPeriod(data[0].total);
              return;
            }
            if (subtype === 'unique_visit') {
              ctx.setUniqClientsForMarketerDashBoardForPreviousPeriod(data[0].total);
              return;
            }
            if (subtype === 'first_visit') {
              const notNewUsersArray = [];
              const newUsers = counts
                .filter((el, i) => {
                  if (values[i] === 'false') notNewUsersArray.push(el);
                  return values[i] === 'true';
                })
                .reduce(sum, 0);
              const notNewUsers = notNewUsersArray.reduce(sum, 0);
              ctx.setNewUsersForMarketerDashBoardForPreviousPeriod(newUsers);
              ctx.setNotNewUsersForMarketerDashBoardForPreviousPeriod(notNewUsers);
            }
            if (subtype === 'authen') {
              // console.log(data)
              this.fillPieSeries(ctx, 'authenChartPieForPreviousPeriod', 'authen', data[0]);
              return;
            }
            if (subtype === 'auth') {
              this.fillPieSeries(ctx, 'authChartPieForPreviousPeriod', 'auth', data[0]);
            }
          }
        } else if (response.data.status === 'error') {
          VueNotifications.error({ message: response.data.description });
          // ctx.$store.commit('disableLoadingSummary');
          ctx.$store.commit('setRequestsStatusForPortalDashboardPageForPreviousPeriod', {
            object,
            subtype,
            status: 'end'
          });
        }
      },
      (err) => {
        // VueNotifications.error({ message: err });

        // show errors in the console instead of the alert window
        if (
          Object.prototype.hasOwnProperty.call(window, 'isShowAxiosErrorInConsole') &&
          typeof window.isShowAxiosErrorInConsole === 'function' &&
          window.isShowAxiosErrorInConsole(err)
        ) {
          window.showAxiosErrInConsole(err);
        } else {
          VueNotifications.error({ message: err });
        }

        ctx.error = err;
        // ctx.$store.commit('disableLoadingSummary');
        ctx.$store.commit('setRequestsStatusForPortalDashboardPageForPreviousPeriod', {
          object,
          subtype,
          status: 'end'
        });
      }
    );
  },


  /** Заполняем Bar графики данными
   *
   * @param {} ctx - vue context
   * @param {'vendorChartBar'|'osChartBar'|'typeChartBar'|'localeChartBar'|'authChartBar'|'authenChartBar'|'browserChartBar'} chart - bar
   * @param {'vendor'|'os'|'type'|'locale'|'auth'|'authen'|'browser'} subtypeName
   * @param {{}} data - response data
   */
  fillBarSeries(ctx, chart, subtypeName, data = { values: {}, dates: [] }) {
    const { values, dates } = data;
    if (Object.keys(values).length === 0) {
      ctx[chart].xAxis.categories = [];
      ctx[chart].series = [];
      return;
    }
    const valuesVal = Object.values(values);
    const averageData = [];
    for (let i = 0; i < valuesVal[0].length; i += 1) {
      let summary = 0;
      for (let j = 0; j < valuesVal.length; j += 1) {
        summary += valuesVal[j][i];
      }
      const av = summary / valuesVal.length;
      averageData.push(+av.toFixed(2));
    }
    const averageSpline = {
      type: 'spline',
      name: ctx.$t('portalStats.average'),
      data: averageData
    };

    const keysVal = Object.keys(values);
    const xAxis = dates.map((el) => ctx.moment(el * 1000).format('DD.MM.Y'));
    const yAxis = keysVal
      .map((el) => {
        let name = '';
        if (subtypeName === 'browser' || subtypeName === 'os' || subtypeName === 'vendor') {
          name = el || ctx.$t('portalStats.other');
        } else if (subtypeName === 'locale') {
          name = helpers.capitalize(el) || ctx.$t('portalStats.other');
        } else if (subtypeName === 'auth' || subtypeName === 'authen') {
          name = el ? ctx.$t(`portalStats.${subtypeName}.${el}`) : ctx.$t('portalStats.other');
        } else if (subtypeName === 'first_visit') {
          name = el === 'true' ? ctx.$t('portalStats.users.new') : ctx.$t('portalStats.users.repeat');
        } else {
          name = el ? ctx.$t(`portalStats.${el}`) : ctx.$t('portalStats.other');
        }
        return {
          // type: 'column',
          type: 'spline',
          name,
          data: values[el]
        };
      })
      .sort((a, b) => b.amount - a.amount);
    ctx[chart].xAxis.categories = xAxis;
    // ctx[chart].series = [...yAxis, averageSpline];
    // убираем среднее значение
    ctx[chart].series = [...yAxis];
  },
  /** Заполняем Pie графики данными
   *
   * @param {} ctx - vue context
   * @param {'vendorChartPie'|'osChartPie'|'typeChartPie'|'localeChartPie'|'authChartPie'|'authenChartPie'|'browserChartPie'} chart - pie
   * @param {{}} data - response data
   * @param {'vendor'|'os'|'type'|'locale'|'auth'|'authen'|'browser'} subtypeName
   */

  fillPieSeries(ctx, chart, subtypeName, data) {
    const { values, counts } = data;
    const allCounts = counts.reduce(sum, 0);
    const series = values
      .map((_el, i) => {
        let name = '';
        if (subtypeName === 'browser' || subtypeName === 'os' || subtypeName === 'vendor') {
          name = values[i] || ctx.$t('portalStats.other');
        } else if (subtypeName === 'locale') {
          name = helpers.capitalize(values[i]) || ctx.$t('portalStats.other');
        } else if (subtypeName === 'auth' || subtypeName === 'authen') {
          name = values[i] ? ctx.$t(`portalStats.${subtypeName}.${values[i]}`) : ctx.$t('portalStats.other');
        } else if (subtypeName === 'first_visit') {
          name = values[i] === 'true' ? ctx.$t('portalStats.users.new') : ctx.$t('portalStats.users.repeat');
        } else {
          name = values[i] ? ctx.$t(`portalStats.${values[i]}`) : ctx.$t('portalStats.other');
        }
        return {
          amount: counts[i],
          name,
          y: (counts[i] / allCounts) * 100
        };
      })
      .sort((a, b) => b.amount - a.amount);
    ctx[chart].series[0].data = series;
  },
  // объединяет данные по разным профилям в один итогоговый объект со статистикой
  // для отображения в графиках - пончиках
  combineDataForPieCharts(dataArray) {
    // console.log(dataArray)
    const result = {};
    if (dataArray === undefined || dataArray.length === 0) {
      return [
        {
          values: [],
          counts: [],
          total: 0
        }
      ];
    }
    const values = [];
    const counts = [];
    let total = 0;
    const combinedDataByKeys = {};
    for (const item of dataArray) {
      total += item.total;
      for (const valueIndex in item.values) {
        if (combinedDataByKeys[item.values[valueIndex]] === undefined) {
          combinedDataByKeys[item.values[valueIndex]] = item.counts[valueIndex];
        } else {
          combinedDataByKeys[item.values[valueIndex]] =
            combinedDataByKeys[item.values[valueIndex]] + item.counts[valueIndex];
        }
      }
    }
    for (const item in combinedDataByKeys) {
      values.push(item);
      counts.push(combinedDataByKeys[item]);
    }
    result.total = total;
    result.values = values;
    result.counts = counts;
    // console.log(result);
    return [result];
  },
  // функция нужна для задачки WNE-1830
  // из апи данные приходят с таймстампом определенного дня, но сами эти данные за вчерашний день,
  // относительного этого приходящего дня.
  // поэтому тут отматываем даты на день назад, чтобы далее показать в графике дату соответствующую данным.
  changeDatesToOneDayBeforeDates(data) {
    const dataFromApi = data;
    if (dataFromApi.dates === undefined || dataFromApi.dates.length === 0) {
      return dataFromApi;
    }
    // console.log(dataFromApi.dates)
    const changedDates = dataFromApi.dates.map((date) => {
      // приводим каждую дату к началу суток
      const ts = new Date(date * 1000);
      ts.setHours(0);
      ts.setMinutes(0);
      ts.setSeconds(0);
      const tsForDayBeginFromApi = ts.valueOf();
      const tsForOneDayBefore = moment(tsForDayBeginFromApi).unix() - 24 * 60 * 60; // минус один день
      return tsForOneDayBefore;
    });
    // console.log(changedDates)
    dataFromApi.dates = changedDates;
    return dataFromApi;
  },
  // если в ответ пришел таймстамп меньше запрашиваемого, то вырезаем такие данные
  cutNonInRangeTimestampsForTimeseries(data, tsStart) {
    // console.log(data);
    const dataFromApi = JSON.parse(JSON.stringify(data));
    if (dataFromApi.dates === undefined || dataFromApi.dates.length === 0) {
      return dataFromApi;
    }
    for (const position in dataFromApi.dates) {
      if (dataFromApi.dates[position] < tsStart) {
        dataFromApi.dates.splice(position, 1);
        for (const statisticArrKey in dataFromApi.values) {
          dataFromApi.values[statisticArrKey].splice(position, 1);
        }
      }
    }

    // console.log(dataFromApi);
    return dataFromApi;
  },
  // getSMSCostForRange(ctx) {
  //   ctx.$store.commit('getSMSCostForRangeRequestExecutingBegin');
  //   // setTimeout(() => {
  //   //   // console.log('sdfsdf')
  //   //   ctx.smsCostForRange = Math.floor(Math.random() * (3000 - 100) + 100);
  //   //   ctx.$store.commit('getSMSCostForRangeRequestExecutingEnd');
  //   // }, Math.floor(Math.random() * (3000 - 500) + 500));
  //
  //   // запрашиваем данные за последние 7 дней
  //   const sevenDays = 7 * 24 * 60 * 60 * 1000;
  //   const start = moment().startOf('day').valueOf() - sevenDays;
  //   const stop = moment().endOf('day').valueOf();
  //   const params = {
  //     start: moment(start).unix(),
  //     stop: moment(stop).unix()
  //   };
  //   Vue.axios.get(`${API_URL || ''}/api/portal/admin/total_cost`, { params }).then(
  //     (response) => {
  //       if (response.status === 200 && response.data.status === 'success') {
  //         // console.log(response.data.data)
  //         if (response.data.hasOwnProperty('data') && response.data.data.hasOwnProperty('total')) {
  //           ctx.smsCostForRange = response.data.data.total;
  //         }
  //         ctx.$store.commit('getSMSCostForRangeRequestExecutingEnd');
  //       } else {
  //         VueNotifications.error({ message: response.data.description });
  //         ctx.$store.commit('getSMSCostForRangeRequestExecutingEnd');
  //       }
  //     },
  //     (err) => {
  //       // VueNotifications.error({ message: err });
  //
  //       // show errors in the console instead of the alert window
  //       if (
  //         Object.prototype.hasOwnProperty.call(window, 'isShowAxiosErrorInConsole') &&
  //         typeof window.isShowAxiosErrorInConsole === 'function' &&
  //         window.isShowAxiosErrorInConsole(err)
  //       ) {
  //         window.showAxiosErrInConsole(err);
  //       } else {
  //         VueNotifications.error({ message: err });
  //       }
  //
  //       ctx.$store.commit('getSMSCostForRangeRequestExecutingEnd');
  //     }
  //   );
  // },
  getSMSCostForRange(ctx, useLocationFilter = false) {
    const isUseLocationFilters = useLocationFilter
    ctx.$store.commit('getSMSCostForRangeRequestExecutingBegin');
    // setTimeout(() => {
    //   // console.log('sdfsdf')
    //   ctx.smsCostForRange = Math.floor(Math.random() * (3000 - 100) + 100);
    //   ctx.$store.commit('getSMSCostForRangeRequestExecutingEnd');
    // }, Math.floor(Math.random() * (3000 - 500) + 500));

    // запрашиваем данные за последние 7 дней
    const sevenDays = 7 * 24 * 60 * 60 * 1000;
    const start = moment().startOf('day').valueOf() - sevenDays;
    const stop = moment().endOf('day').valueOf();
    const params = {
      start: moment(start).unix(),
      stop: moment(stop).unix()
    };
    if (!isUseLocationFilters) {
      params.with_childs = ctx.$store.getters.getDefaultWithChildsForRequests(ctx)
    }

    if (isUseLocationFilters){
      if (ctx.filters.location === 'All locations') {
        params.location = ctx.$store.state.requestBaseUserLocationIfNoSelected ? ctx.userBaseLocation : undefined;
        params.with_childs = true;
      } else {
        params.location = ctx.filters.location;
        params.with_childs = ctx.filters.with_childs;
      }
    }

    Vue.axios.get(`${API_URL || ''}/api/portal/admin/total_cost`, { params }).then(
      (response) => {
        if (response.status === 200 && response.data.status === 'success') {
          // console.log(response.data.data)
          if (response.data.hasOwnProperty('data') && response.data.data.hasOwnProperty('total')) {
            ctx.smsCostForRange = response.data.data.total;
          }
          ctx.$store.commit('getSMSCostForRangeRequestExecutingEnd');
        } else {
          VueNotifications.error({ message: response.data.description });
          ctx.$store.commit('getSMSCostForRangeRequestExecutingEnd');
        }
      },
      (err) => {
        // VueNotifications.error({ message: err });

        // show errors in the console instead of the alert window
        if (
          Object.prototype.hasOwnProperty.call(window, 'isShowAxiosErrorInConsole') &&
          typeof window.isShowAxiosErrorInConsole === 'function' &&
          window.isShowAxiosErrorInConsole(err)
        ) {
          window.showAxiosErrInConsole(err);
        } else {
          VueNotifications.error({ message: err });
        }

        ctx.$store.commit('getSMSCostForRangeRequestExecutingEnd');
      }
    );
  },
  // getSMSCostForPrevRange(ctx) {
  //   ctx.$store.commit('getSMSCostForPrevRangeRequestExecutingBegin');
  //   // setTimeout(() => {
  //   //   // console.log('sdfsdf2')
  //   //   ctx.smsCostForPrevRange = Math.floor(Math.random() * (3000 - 100) + 100);
  //   //   ctx.$store.commit('getSMSCostForPrevRangeRequestExecutingEnd');
  //   // }, Math.floor(Math.random() * (3000 - 500) + 500));
  //
  //   // загружаем период 7 дней перед текущим периодом в 7 дней (от 14 дней назад до 7 дней назад)
  //   const sevenDays = 7 * 24 * 60 * 60 * 1000;
  //   const start = moment().startOf('day').valueOf() - sevenDays - sevenDays;
  //   const stop = moment().startOf('day').valueOf() - sevenDays - 1;
  //   const params = {
  //     start: moment(start).unix(),
  //     stop: moment(stop).unix()
  //   };
  //   Vue.axios.get(`${API_URL || ''}/api/portal/admin/total_cost`, { params }).then(
  //     (response) => {
  //       if (response.status === 200 && response.data.status === 'success') {
  //         // console.log(response.data.data)
  //         if (response.data.hasOwnProperty('data') && response.data.data.hasOwnProperty('total')) {
  //           ctx.smsCostForPrevRange = response.data.data.total;
  //         }
  //         ctx.$store.commit('getSMSCostForPrevRangeRequestExecutingEnd');
  //       } else {
  //         VueNotifications.error({ message: response.data.description });
  //         ctx.$store.commit('getSMSCostForPrevRangeRequestExecutingEnd');
  //       }
  //     },
  //     (err) => {
  //       // VueNotifications.error({ message: err });
  //
  //       // show errors in the console instead of the alert window
  //       if (
  //         Object.prototype.hasOwnProperty.call(window, 'isShowAxiosErrorInConsole') &&
  //         typeof window.isShowAxiosErrorInConsole === 'function' &&
  //         window.isShowAxiosErrorInConsole(err)
  //       ) {
  //         window.showAxiosErrInConsole(err);
  //       } else {
  //         VueNotifications.error({ message: err });
  //       }
  //
  //       ctx.$store.commit('getSMSCostForPrevRangeRequestExecutingEnd');
  //     }
  //   );
  // },
  getSMSCostForPrevRange(ctx, useLocationFilter = false) {
    const isUseLocationFilters = useLocationFilter
    ctx.$store.commit('getSMSCostForPrevRangeRequestExecutingBegin');
    // setTimeout(() => {
    //   // console.log('sdfsdf2')
    //   ctx.smsCostForPrevRange = Math.floor(Math.random() * (3000 - 100) + 100);
    //   ctx.$store.commit('getSMSCostForPrevRangeRequestExecutingEnd');
    // }, Math.floor(Math.random() * (3000 - 500) + 500));

    // загружаем период 7 дней перед текущим периодом в 7 дней (от 14 дней назад до 7 дней назад)
    const sevenDays = 7 * 24 * 60 * 60 * 1000;
    const start = moment().startOf('day').valueOf() - sevenDays - sevenDays;
    const stop = moment().startOf('day').valueOf() - sevenDays - 1;
    const params = {
      start: moment(start).unix(),
      stop: moment(stop).unix()
    };

    if (!isUseLocationFilters) {
      params.with_childs = ctx.$store.getters.getDefaultWithChildsForRequests(ctx)
    }

    if (isUseLocationFilters){
      if (ctx.filters.location === 'All locations') {
        params.location = ctx.$store.state.requestBaseUserLocationIfNoSelected ? ctx.userBaseLocation : undefined;
        params.with_childs = true;
      } else {
        params.location = ctx.filters.location;
        params.with_childs = ctx.filters.with_childs;
      }
    }


    Vue.axios.get(`${API_URL || ''}/api/portal/admin/total_cost`, { params }).then(
      (response) => {
        if (response.status === 200 && response.data.status === 'success') {
          // console.log(response.data.data)
          if (response.data.hasOwnProperty('data') && response.data.data.hasOwnProperty('total')) {
            ctx.smsCostForPrevRange = response.data.data.total;
          }
          ctx.$store.commit('getSMSCostForPrevRangeRequestExecutingEnd');
        } else {
          VueNotifications.error({ message: response.data.description });
          ctx.$store.commit('getSMSCostForPrevRangeRequestExecutingEnd');
        }
      },
      (err) => {
        // VueNotifications.error({ message: err });

        // show errors in the console instead of the alert window
        if (
          Object.prototype.hasOwnProperty.call(window, 'isShowAxiosErrorInConsole') &&
          typeof window.isShowAxiosErrorInConsole === 'function' &&
          window.isShowAxiosErrorInConsole(err)
        ) {
          window.showAxiosErrInConsole(err);
        } else {
          VueNotifications.error({ message: err });
        }

        ctx.$store.commit('getSMSCostForPrevRangeRequestExecutingEnd');
      }
    );
  },
  // getSMSCostForRangeForStatisticSection(ctx) {
  //   ctx.smsCost = 0; // обнуляем
  //   ctx.$store.commit('getSMSCostForRangeForStatisticRequestExecutingBegin');
  //   const params = {
  //     start: moment(ctx.drPickerDate.startDate).unix(),
  //     stop: moment(ctx.drPickerDate.endDate).unix()
  //   };
  //   Vue.axios.get(`${API_URL || ''}/api/portal/admin/total_cost`, { params }).then(
  //     (response) => {
  //       if (response.status === 200 && response.data.status === 'success') {
  //         // console.log(response.data.data)
  //         if (response.data.hasOwnProperty('data') && response.data.data.hasOwnProperty('total')) {
  //           ctx.smsCost = response.data.data.total;
  //         }
  //         ctx.$store.commit('getSMSCostForRangeForStatisticRequestExecutingEnd');
  //       } else {
  //         VueNotifications.error({ message: response.data.description });
  //         ctx.$store.commit('getSMSCostForRangeForStatisticRequestExecutingEnd');
  //       }
  //     },
  //     (err) => {
  //       // VueNotifications.error({ message: err });
  //
  //       // show errors in the console instead of the alert window
  //       if (
  //         Object.prototype.hasOwnProperty.call(window, 'isShowAxiosErrorInConsole') &&
  //         typeof window.isShowAxiosErrorInConsole === 'function' &&
  //         window.isShowAxiosErrorInConsole(err)
  //       ) {
  //         window.showAxiosErrInConsole(err);
  //       } else {
  //         VueNotifications.error({ message: err });
  //       }
  //
  //       ctx.$store.commit('getSMSCostForRangeForStatisticRequestExecutingEnd');
  //     }
  //   );
  // },
  getSMSCostForRangeForStatisticSection(ctx, useLocationFilter = false) {
    const isUseLocationFilters = useLocationFilter
    ctx.smsCost = 0; // обнуляем
    ctx.$store.commit('getSMSCostForRangeForStatisticRequestExecutingBegin');
    const params = {
      start: moment(ctx.drPickerDate.startDate).unix(),
      stop: moment(ctx.drPickerDate.endDate).unix()
    };
    if (!isUseLocationFilters) {
      params.with_childs = ctx.$store.getters.getDefaultWithChildsForRequests(ctx)
    }
    if (isUseLocationFilters){
      params.location = ctx.filters.location && ctx.filters.location.id ? ctx.filters.location.id : [];
      params.location_id = ctx.filters.location && ctx.filters.location.id ? ctx.filters.location.id : [];
      params.with_childs = !(ctx.filters && ctx.filters.hasOwnProperty('with_childs') && ctx.filters.with_childs === false);
    }
    Vue.axios.get(`${API_URL || ''}/api/portal/admin/total_cost`, { params }).then(
      (response) => {
        if (response.status === 200 && response.data.status === 'success') {
          // console.log(response.data.data)
          if (response.data.hasOwnProperty('data') && response.data.data.hasOwnProperty('total')) {
            ctx.smsCost = response.data.data.total;
          }
          ctx.$store.commit('getSMSCostForRangeForStatisticRequestExecutingEnd');
        } else {
          VueNotifications.error({ message: response.data.description });
          ctx.$store.commit('getSMSCostForRangeForStatisticRequestExecutingEnd');
        }
      },
      (err) => {
        // VueNotifications.error({ message: err });

        // show errors in the console instead of the alert window
        if (
          Object.prototype.hasOwnProperty.call(window, 'isShowAxiosErrorInConsole') &&
          typeof window.isShowAxiosErrorInConsole === 'function' &&
          window.isShowAxiosErrorInConsole(err)
        ) {
          window.showAxiosErrInConsole(err);
        } else {
          VueNotifications.error({ message: err });
        }

        ctx.$store.commit('getSMSCostForRangeForStatisticRequestExecutingEnd');
      }
    );
  },
  // getSMSCostForPreviousPeriodRangeForStatisticSection(ctx) {
  //   ctx.smsCostForPreviousPeriod = 0; // обнуляем
  //   ctx.$store.commit('getSMSCostForPreviousPeriodRangeForStatisticRequestExecutingBegin');
  //   // берем старт и стоп для предидущего переода
  //   const previousPeriodstart = moment(ctx.drPickerDateForPreviousPeriod.startDate).unix();
  //   const previousPeriodstop = moment(ctx.drPickerDateForPreviousPeriod.endDate).unix();
  //   const params = {
  //     start: previousPeriodstart,
  //     stop: previousPeriodstop
  //   };
  //   Vue.axios.get(`${API_URL || ''}/api/portal/admin/total_cost`, { params }).then(
  //     (response) => {
  //       if (response.status === 200 && response.data.status === 'success') {
  //         // console.log(response.data.data)
  //         if (response.data.hasOwnProperty('data') && response.data.data.hasOwnProperty('total')) {
  //           ctx.smsCostForPreviousPeriod = response.data.data.total;
  //         }
  //         ctx.$store.commit('getSMSCostForPreviousPeriodRangeForStatisticRequestExecutingEnd');
  //       } else {
  //         VueNotifications.error({ message: response.data.description });
  //         ctx.$store.commit('getSMSCostForPreviousPeriodRangeForStatisticRequestExecutingEnd');
  //       }
  //     },
  //     (err) => {
  //       // VueNotifications.error({ message: err });
  //
  //       // show errors in the console instead of the alert window
  //       if (
  //         Object.prototype.hasOwnProperty.call(window, 'isShowAxiosErrorInConsole') &&
  //         typeof window.isShowAxiosErrorInConsole === 'function' &&
  //         window.isShowAxiosErrorInConsole(err)
  //       ) {
  //         window.showAxiosErrInConsole(err);
  //       } else {
  //         VueNotifications.error({ message: err });
  //       }
  //
  //       ctx.$store.commit('getSMSCostForPreviousPeriodRangeForStatisticRequestExecutingEnd');
  //     }
  //   );
  // }
  getSMSCostForPreviousPeriodRangeForStatisticSection(ctx, useLocationFilter = false) {
    const isUseLocationFilters = useLocationFilter
    ctx.smsCostForPreviousPeriod = 0; // обнуляем
    ctx.$store.commit('getSMSCostForPreviousPeriodRangeForStatisticRequestExecutingBegin');
    // берем старт и стоп для предидущего переода
    const previousPeriodstart = moment(ctx.drPickerDateForPreviousPeriod.startDate).unix();
    const previousPeriodstop = moment(ctx.drPickerDateForPreviousPeriod.endDate).unix();
    const params = {
      start: previousPeriodstart,
      stop: previousPeriodstop
    };
    if (!isUseLocationFilters) {
      params.with_childs = ctx.$store.getters.getDefaultWithChildsForRequests(ctx)
    }
    if (isUseLocationFilters){
      params.location = ctx.filters.location && ctx.filters.location.id ? ctx.filters.location.id : [];
      params.location_id = ctx.filters.location && ctx.filters.location.id ? ctx.filters.location.id : [];
      params.with_childs = !(ctx.filters && ctx.filters.hasOwnProperty('with_childs') && ctx.filters.with_childs === false);
    }
    Vue.axios.get(`${API_URL || ''}/api/portal/admin/total_cost`, { params }).then(
      (response) => {
        if (response.status === 200 && response.data.status === 'success') {
          // console.log(response.data.data)
          if (response.data.hasOwnProperty('data') && response.data.data.hasOwnProperty('total')) {
            ctx.smsCostForPreviousPeriod = response.data.data.total;
          }
          ctx.$store.commit('getSMSCostForPreviousPeriodRangeForStatisticRequestExecutingEnd');
        } else {
          VueNotifications.error({ message: response.data.description });
          ctx.$store.commit('getSMSCostForPreviousPeriodRangeForStatisticRequestExecutingEnd');
        }
      },
      (err) => {
        // VueNotifications.error({ message: err });

        // show errors in the console instead of the alert window
        if (
          Object.prototype.hasOwnProperty.call(window, 'isShowAxiosErrorInConsole') &&
          typeof window.isShowAxiosErrorInConsole === 'function' &&
          window.isShowAxiosErrorInConsole(err)
        ) {
          window.showAxiosErrInConsole(err);
        } else {
          VueNotifications.error({ message: err });
        }

        ctx.$store.commit('getSMSCostForPreviousPeriodRangeForStatisticRequestExecutingEnd');
      }
    );
  }
};
