/**
 * Модуль Vuex для управления меню: свернуто/развернуто.
 *
 * @module menu
 * @namespace
 *
 * ! info пока что работает только для нового меню.
 * ! warn пока что кеширование стейта не работает корректно из-за легаси-механизма сворачивания/разворачивания контента. Поэтому временно отключено.
 *
 * Здесь размещаются стейты, геттеры, мутации и экшны, специфические для локализации.
 * Для использования в компонентах и других модулях Vuex.
 *
 * doc о модулях vuex:
 * https://v3.vuex.vuejs.org/ru/guide/modules.html
 *
 */
import { IS_MENU_COLLAPSED } from '@/config';
import { MenuMaster } from '@/utils';

/**
 * Начальная состояние меню: свернуто/развернуто.
 * @constant {string}
 */

const initialMenuState = MenuMaster.getCachedMenuState() ?? IS_MENU_COLLAPSED;

const menu = {
  state: () => ({
    menuCollapsedState: initialMenuState
  }),
  getters: {
    isMenuCollapsed: (state) => state.menuCollapsedState
  },
  mutations: {
    setIsMenuCollapsedState(state, newState) {
      state.menuCollapsedState = newState;
    }
  },
  actions: {
    /**
     * Кэширует текущее состояние меню: свернуто/развернуто.
     * !!! Напрямую в компонентах не используется
     * @param {Object} context - Контекст хранилища Vuex.
     * @param {boolean} newState - новый стейт меню.
     */
    _cacheCurrentState(ctx, newState) {
      MenuMaster.setCachedMenuState(newState);
    },
    /**
     * Переключает текущее состояние меню свернуто/развернуто.
     * @param {Object} context - Контекст хранилища Vuex.
     */
    toggleMenu({ getters, commit }) {
      const newInMenuCollapsedState = !getters.isMenuCollapsed;

      commit('setIsMenuCollapsedState', newInMenuCollapsedState);
      // ! warn временно отключено из-за несовместимости с легаси
      // dispatch('_cacheCurrentState', newInMenuCollapsedState);
    }
  },
  namespaced: true
};

export default menu;
