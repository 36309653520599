/**
 * Изменяет в переданном объекте wlan значения speed_download и speed_upload на нули из пустых строк
 * Нужно для того чтобы передать на бэк
 *
 * @param wlanObject
 */
function modifyEmptyStringToZerosForSpeedControlBeforeSendToAPI(wlanObject) {
  if (
    Object.prototype.hasOwnProperty.call(wlanObject, 'speed_download') &&
    typeof wlanObject.speed_download === 'object' && wlanObject.speed_download !== null &&
    wlanObject.speed_download.value === ''
  ) {
    // eslint-disable-next-line no-param-reassign
    wlanObject.speed_download.value = 0;
  }
  if (
    Object.prototype.hasOwnProperty.call(wlanObject, 'speed_upload') &&
    typeof wlanObject.speed_upload === 'object' && wlanObject.speed_upload !== null &&
    wlanObject.speed_upload.value === ''
  ) {
    // eslint-disable-next-line no-param-reassign
    wlanObject.speed_upload.value = 0;
  }
}

/**
 * Изменяет в переданном объекте wlan значения speed_download и speed_upload нули на пустые строки
 * Нужно для показа в UI путых строк
 *
 * @param wlanObject
 */
function modifyZerosToEmptyStringsForSpeedControlWhenReceivedFromAPIBeforeShowInUI(wlanObject) {
  if (
    Object.prototype.hasOwnProperty.call(wlanObject, 'speed_download') &&
    typeof wlanObject.speed_download === 'object' && wlanObject.speed_download !== null &&
    wlanObject.speed_download.value === 0
  ) {
    // eslint-disable-next-line no-param-reassign
    wlanObject.speed_download.value = '';
  }
  if (
    Object.prototype.hasOwnProperty.call(wlanObject, 'speed_upload') &&
    typeof wlanObject.speed_upload === 'object' && wlanObject.speed_upload !== null &&
    wlanObject.speed_upload.value === 0
  ) {
    // eslint-disable-next-line no-param-reassign
    wlanObject.speed_upload.value = '';
  }
}


const beforeSendToAPISpeedControlModifiers = [modifyEmptyStringToZerosForSpeedControlBeforeSendToAPI];
const whenReceivedFromAPIBeforeShowInUISpeedControlModifiers = [modifyZerosToEmptyStringsForSpeedControlWhenReceivedFromAPIBeforeShowInUI];

export {
  beforeSendToAPISpeedControlModifiers,
  whenReceivedFromAPIBeforeShowInUISpeedControlModifiers
};
