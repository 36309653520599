<template>
  <span
    class="badge badge-light rrm-groups-button"
    :class="[props.row.power ? 'badge-success' : 'badge-default']"
  >
    <i class="fa fa-signal"></i>
  </span>
</template>

<script>
/**
 * компонент - ячейка power.
 * компонент локальный: используется только в RRMGroupsTable.vue
 * @component
 */

export default {
  name: 'PowerCell',
  components: {},
  props: {
    props: {
      type: Object,
      required: true
    }
  },
  methods: {
  }
};
</script>

<style lang="css" scoped>
</style>
