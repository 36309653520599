<template>
  <div>
    <div v-for="wifiState in props.row.two.wifiState"
      class="cpe-interface-state"
      :key="wifiState._id"
    >
      <div class="cpe-interface-state__badge">
        <span class="badge badge-light"
          :class="{ 'badge-success': wifiState.enabled, 'badge-default': !wifiState.enabled }"
        >
          <i class="fa fa-wifi"></i>
        </span>
        <span v-if="wifiState.calculatedTotalWlansState.active.length"
          class="badge badge-light badge-success"
          v-tooltip.top-center="`${$t('aps.cpeStatusTooltipActive')}: ${wifiState.calculatedTotalWlansState.active}`"
        >
          {{ wifiState.calculatedTotalWlansState.active.length }}
        </span>
        <span v-if="wifiState.calculatedTotalWlansState.updating.length"
          class="badge badge-light badge-info"
          v-tooltip.top-center="`${$t('aps.cpeStatusTooltipUpdating')}: ${wifiState.calculatedTotalWlansState.updating}`"
        >
          {{ wifiState.calculatedTotalWlansState.updating.length }}
        </span>
        <span v-if="wifiState.calculatedTotalWlansState.notActive.length"
          class="badge badge-light"
          :class="[wifiState.enabled ? 'badge-danger' : 'badge-default']"
          v-tooltip.top-center="`${$t('aps.cpeStatusTooltipNotActive')}: ${wifiState.calculatedTotalWlansState.notActive}`"
        >
          {{ wifiState.calculatedTotalWlansState.notActive.length }}
        </span>
        <span v-if="!wifiState.calculatedTotalWlansState.active.length && !wifiState.calculatedTotalWlansState.notActive.length && !wifiState.calculatedTotalWlansState.updating.length"
          class="badge badge-light badge-default"
        >
          0
        </span>
        <span  v-if="wifiState.enabled && wifiState.totalClients"
          class="ml-h badge badge-light"
          :class="[wifiState.totalClients ? 'badge-success' : 'badge-default']"
        >
          <i class="fa mr-q fa-user"></i>
          <span v-if="wifiState.totalClients">
            {{ wifiState.totalClients }}
          </span>
          <span v-else>0</span>
        </span>
      </div>
    </div>
    <template v-if="!props.row.two.wifiState">
      <div v-for="wifiConfig in props.row.two.wifiConfig"
        class="cpe-interface-state"
        :key="wifiConfig._id"
      >
        <div class="cpe-interface-state__badge">
          <span
            class="badge badge-light badge-danger"
            :class="[wifiConfig.enabled ? 'badge-success' : 'badge-default']"
          >
            <i class="fa fa-wifi"></i>
          </span>
          <span
            class="badge badge-light badge-danger"
            v-tooltip.top-center="'Wrong config: misconfiguration</br>Click to watch events'"
            @click.stop="handleGoToEvents(props.row.nativeData)"
          >
            0
          </span>
        </div>
      </div>
    </template>
    <span v-if="!props.row.two.wifiState && !props.row.two.wifiConfig.length">-</span>
  </div>
</template>

<script>
/**
 * компонент - ячейка 2ghz.
 * компонент локальный: используется только в ApsTable.vue
 * @component
 */

export default {
  name: 'TwoGhzCell',
  components: {},
  props: {
    props: {
      type: Object,
      required: true
    }
  },
  methods: {
  }
};
</script>

<style lang="css" scoped>
.cpe-interface-state {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.cpe-interface-state:not(:last-of-type){
  margin-bottom: 2px;
}

.cpe-interface-state__badge {
  display: flex;
}
</style>
