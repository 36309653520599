import { render, staticRenderFns } from "./accessControl.vue?vue&type=template&id=35c7fd46&scoped=true&"
import script from "./accessControl.vue?vue&type=script&lang=js&"
export * from "./accessControl.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "35c7fd46",
  null
  
)

export default component.exports