<template>
  <div v-if="props.row.locationDescription">
    <span class="in-location-cell-item-in-cpes-list">
      {{ locationDescription(props.row.locationDescription) }}
    </span>
  </div>
  <span v-else>-</span>
</template>

<script>
/**
 * компонент - ячейка locationDescription.
 * компонент локальный: используется только в ApsTable.vue
 * @component
 */

import commonService from '@/services/commonService';

export default {
  name: 'LocationDescriptionCell',
  components: {},
  props: {
    props: {
      type: Object,
      required: true
    }
  },
  methods: {
    locationDescription(id) {
      const showLocationFromId = commonService.showLocationDescriptionFromId(id);
      return showLocationFromId;
    }
  }
};
</script>

<style lang="css" scoped>
.in-location-cell-item-in-cpes-list {
  margin-top: 2px;
  margin-bottom: 2px;
  font-size: 0.8em;
}
</style>
