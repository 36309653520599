<template>
  <span class="checkbox-container">
    <input
      type="checkbox"
      v-bind="$attrs"
      v-on="{
        ...$listeners,
        change: (evt) => this.$emit('change', evt),
        input: () => this.$emit('input', !this.value)
      }"
      :id="id"
      :value="value"
      :checked="isChecked"
      :disabled="$attrs.disabled"
      tabindex="0"
      class="checkbox-square"
      :class="{ checked: isChecked || $attrs.checked }"
    />
    <label :for="id" role="button">{{ label }}</label>
  </span>
</template>

<script>
export default {
  name: 'CheckboxComponent',
  props: {
    value: {},
    name: { type: String, default: '' },
    label: { type: String, default: '' },
    id: { type: String, default: '' }
  },

  computed: {
    isChecked() {
      return !!this.value;
    },
    isDisabled() {
      return this.$attrs.disabled;
    }
  },
  // model: {
  //   prop: 'checked',
  //   event: 'change'
  // },
  // watch: {
  //   checked() {
  //     this.isChecked = this.checked;
  //   }
  // },
  inheritAttrs: false
};
</script>

<style lang="scss">
.checkbox-square {
  position: absolute;
  z-index: -1;
  opacity: 0;
}
.checkbox-container {
  display: flex;
  place-items: center;
}
.checkbox-square + label {
  display: inline-flex;
  align-items: center;
  user-select: none;
  margin-bottom: 0;
}

.checkbox-square + label::before {
  content: '';
  display: inline-block;
  width: 1rem;
  height: 1rem;
  flex-shrink: 0;
  flex-grow: 0;
  border: 1px solid rgba(40, 40, 40, 0.2);
  border-radius: 3px;
  margin-right: 0.5em;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
  transition: border-color 0.15s ease-in, background-color 0.15s ease-in;
}

.checkbox-square.checked + label::before {
  background-position-y: bottom 1px;
  border-color: var(--checkbox-bg);

  background-color: var(--checkbox-bg);
  background-image: url("data:image/svg+xml,%3Csvg width='13' height='13' viewBox='0 0 13 13' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M10.8936 4.96484L4.96462 10.8938L2.00016 7.92931' stroke='%23282828' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
  transition: border-color 0.15s ease-in, background-color 0.15s ease-in;
}

/* стили при наведении курсора на checkbox */
.checkbox-square:not(:disabled):not(.checked) + label:hover::before {
  border-color: rgba(40, 40, 40, 0.35);
}
/* стили для активного состояния чекбокса (при нажатии на него) */
/* .checkbox-square:not(:disabled):active + label::before {
  background-color: #b3d7ff;
  border-color: #b3d7ff;
} */
/* стили для чекбокса, находящегося в фокусе */
/* .checkbox-square:focus + label::before {
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
} */
/* стили для чекбокса, находящегося в фокусе и не находящегося в состоянии checked */
.checkbox-square:focus:not(.checked) + label::before {
  border-color: rgba(40, 40, 40, 0.4);
}
/* стили для чекбокса, находящегося в состоянии disabled */
.checkbox-square:disabled + label::before {
  border: 1px solid rgba(40, 40, 40, 0.2);
  cursor: default;
}

.checkbox-square:disabled + label {
  color: rgba(40, 40, 40, 0.5);
  mix-blend-mode: normal;
  cursor: default;
}
</style>
