/**
 * @typedef {Object} Input
 * @property {string} name - Имя входного элемента.
 * @property {*} [defaultValue] - Значение по умолчанию для входного элемента.
 */

/**
 * @typedef {Object} ContentGroup
 * @property {Input[]} [inputs] - Массив входных элементов в группе.
 */

/**
 * @typedef {Object} Tab
 * @property {ContentGroup[]} [content] - Массив групп контента для вкладки.
 * @property {Tab[]} [childrenTabs] - Массив дочерних вкладок.
 */

/**
 * Создаёт начальное состояние формы из структуры вкладок для компонента FormWithTabs.vue.
 *
 * @param {Tab[]} tabs - Массив вкладок, из которых будет создано состояние формы.
 * @returns {Object<string, *>} - Объект состояния формы, где ключи — имена полей ввода, а значения — их значения по умолчанию.
 *
 * @example
 * Пример данных
 * const tabs = [
 *   {
 *     content: [
 *       {
 *         inputs: [
 *           { name: 'username', defaultValue: 'guest' },
 *           { name: 'password' } // Значение по умолчанию не задано
 *         ]
 *       }
 *     ],
 *     childrenTabs: [
 *       {
 *         content: [
 *           {
 *             inputs: [
 *               { name: 'email', defaultValue: 'user@example.com' }
 *             ]
 *           }
 *         ]
 *       }
 *     ]
 *   }
 * ];
 *
 * Вызов функции
 * const formState = createFormState(tabs);
 *
 * formState будет равен:
 * {
 *   username: 'guest',
 *   password: undefined,
 *   email: 'user@example.com'
 * }
*/

function createFormState(tabs) {
  const state = {};

  function recurse(tabs) {
    tabs.forEach(tab => {
      if (tab.content && Array.isArray(tab.content)) {
        tab.content.forEach(contentGroup => {
          if (contentGroup.inputs) {
            contentGroup.inputs.forEach(input => {
              state[input.name] = input.defaultValue !== undefined ? input.defaultValue : undefined;
            });
          }
        });
      }

      if (tab.childrenTabs && tab.childrenTabs.length > 0) {
        recurse(tab.childrenTabs);
      }
    });
  }

  recurse(tabs);
  return state;
}

export {
  createFormState
};
