// selected checkbox of a row or not
function getSelectedRowValue(currentApJoinProfileId, selectedApJoinProfileIdsArray) {
  let value = false;

  selectedApJoinProfileIdsArray.forEach(apId => {
    if (apId === currentApJoinProfileId) {
      value = true;
    }
  });

  return value;
}

export {
  getSelectedRowValue
};
