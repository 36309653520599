/* eslint-disable camelcase */
/**
 * @typedef {import('./types.js').BranchProfile_FRONTEND} BranchProfile_FRONTEND
 * @typedef {import('./types.js').BaseProfile_FRONTEND} BaseProfile_FRONTEND
 * @typedef {import('./types.js').BranchProfile_BACKEND} BranchProfile_BACKEND
 * @typedef {import('./types.js').Id} Id
 *
 */

/**
 * Формирует URL с query params на основе массива параметров.
 *
 * @param {string} url - api url.
 * @param {Array<{name: string, value: any}>} urlParams - Массив объектов с именем и значением параметра.
 * @returns {string} URL с query params.
 */
function getFetcherUrl(url, urlParams) {
  // Фильтруем параметры, у которых есть значение
  const queryParams = urlParams
    .filter(param => (param.value !== undefined && param.value !== null))
    .map(param => `${encodeURIComponent(param.name)}=${encodeURIComponent(param.value)}`)
    .join('&');

  const resultUrl = `${url}${queryParams ? '?' : ''}${queryParams}`;

  return resultUrl;
}

/**
 * Конвертер типов branch_profile с бэковых типов (BranchProfile_BACKEND) в фронтовые (BranchProfile_FRONTEND).
 * Использовать, когда получаем branch_profile с бэка, чтобы использовать на фронте данные с корректными для фронта типами.
 *
 * @param {BranchProfile_BACKEND} branch_profile - BranchProfile_BACKEND данные для конвертации типов.
 * @returns {BranchProfile_FRONTEND} - сконвертированные данные с типизацией BranchProfile_FRONTEND.
 */
function branchProfileDataTypesConverterForFrontend(branch_profile) {
  const {
    id,
    name,
    description,
    native_vlan_id,
    fallback_radio_shut_enabled,
    arp_caching_enabled,
    efficient_sw_upgrade_enabled,
    ip_overlap_enabled,
    pmk_propagation_enabled,
    radius_group,
    branch_profile_vlans,
    web_auth_profile
  } = branch_profile;

  const convertedTypesToFrontendFormat = {
    id: String(id),
    name: String(name),
    description: String(description),
    native_vlan_id: Number(native_vlan_id),
    fallback_radio_shut_enabled: String(fallback_radio_shut_enabled) === 'True',
    arp_caching_enabled: String(arp_caching_enabled) === 'True',
    efficient_sw_upgrade_enabled: String(efficient_sw_upgrade_enabled) === 'True',
    ip_overlap_enabled: String(ip_overlap_enabled) === 'True',
    pmk_propagation_enabled: String(pmk_propagation_enabled) === 'True',
    radius_group: String(radius_group),
    branch_profile_vlans: branch_profile_vlans.map(branchProfileVlan => {
      const {
        name,
        vlan_id,
        acl_ingress,
        acl_egress
      } = branchProfileVlan;

      return {
        name: String(name),
        vlan_id: Number(vlan_id),
        acl_ingress: String(acl_ingress) === 'NA' ? '' : String(acl_ingress),
        acl_egress: String(acl_egress) === 'NA' ? '' : String(acl_egress)
      };
    }),
    web_auth_profile: String(web_auth_profile)
  };

  return convertedTypesToFrontendFormat;
}

/**
 * Конвертер типов ap_join_profile с фронтовых типов (BranchProfile_FRONTEND) в бэковые (BranchProfile_BACKEND).
 * Использовать, когда возвращаем branch_profile с фронта на бэк.
 *
 * ! поля id могут быть, могут не быть в зависимости от запроса.
 *
 * @param {BaseProfile_FRONTEND & Partial<Id>} branch_profile - BranchProfile_FRONTEND данные для конвертации типов.
 * @returns {BranchProfile_BACKEND>} - сконвертированные данные с типизацией BranchProfile_BACKEND.
 */
function branchProfileDataTypesConverterForBackend(branch_profile) {
  const {
    id, // может быть, может не быть. При создании сущности его не будет.
    name,
    description,
    native_vlan_id,
    fallback_radio_shut_enabled,
    arp_caching_enabled,
    efficient_sw_upgrade_enabled,
    ip_overlap_enabled,
    pmk_propagation_enabled,
    radius_group,
    branch_profile_vlans,
    web_auth_profile
  } = branch_profile;

  const convertedTypesToBackendFormat = {
    name: String(name),
    description: String(description),
    native_vlan_id: String(native_vlan_id),
    fallback_radio_shut_enabled: fallback_radio_shut_enabled ? 'True' : 'False',
    arp_caching_enabled: arp_caching_enabled ? 'True' : 'False',
    efficient_sw_upgrade_enabled: efficient_sw_upgrade_enabled ? 'True' : 'False',
    ip_overlap_enabled: ip_overlap_enabled ? 'True' : 'False',
    pmk_propagation_enabled: pmk_propagation_enabled ? 'True' : 'False',
    radius_group: String(radius_group),
    branch_profile_vlans: branch_profile_vlans.map(branchProfileVlan => {
      const {
        name,
        vlan_id,
        acl_ingress,
        acl_egress
      } = branchProfileVlan;
      return {
        name: String(name),
        vlan_id: String(vlan_id),
        acl_ingress: String(acl_ingress) === '' ? 'NA' : String(acl_ingress),
        acl_egress: String(acl_egress) === '' ? 'NA' : String(acl_egress)
      };
    }),
    web_auth_profile: String(web_auth_profile)
  };

  // может быть, может не быть. При создании сущности его не будет.
  if (id) {
    convertedTypesToBackendFormat.id = String(id);
  }

  return convertedTypesToBackendFormat;
}

export {
  getFetcherUrl,
  branchProfileDataTypesConverterForFrontend,
  branchProfileDataTypesConverterForBackend
};
