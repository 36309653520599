<template>
  <div class="w-100 d-flex flex-row justify-content-end mb-2 position-relative">
    <div role="button" class="gear-wrapper" @click="toggleVisibilitySettingsBlock">
      <i class="fa fa-gear gear-styles"></i>
    </div>
    <div v-if="showSettingsBlock" class="settings-block">
      <div class="w-100 d-flex flex-row justify-content-end mb-h">
        <div role="button" @click.stop.prevent="closeBlock" class="fa fa-close close-button-div"></div>
      </div>
      <div v-if="isCurrentUserMarketer">
        <div class=""
             :class="{'mt-h column-item-wrapper': isBlocksVisibilityItemAvailableForThisBuildForMarketer(blockKey)}"
             v-for="(blockValue, blockKey) in blocks.marketer" :key="blockKey">
          <div v-if="isBlocksVisibilityItemAvailableForThisBuildForMarketer(blockKey)"
               class="form-group col-sm-12 mb-q">
            <label class="custom-control custom-checkbox">
              <input type="checkbox" class="custom-control-input" :checked="blockValue.value"
                     v-model="blockValue.value"/>
              <span aria-hidden="true" class="custom-control-indicator"></span>
              <span class="custom-control-description">
                {{ blockValue.caption }}
              </span>
            </label>
          </div>
        </div>
      </div>
      <div v-else>
        <div class=""
             :class="{'column-item-wrapper mt-h': isBlocksVisibilityItemAvailableForThisBuildForMarketer(blockKey)}"
             v-for="(blockValue, blockKey) in blocks.general" :key="blockKey">
          <div v-if="isBlocksVisibilityItemAvailableForThisBuild(blockKey)"
               class="form-group col-sm-12 mb-q">
            <label class="custom-control custom-checkbox">
              <input type="checkbox" class="custom-control-input" :checked="blockValue.value"
                     v-model="blockValue.value"/>
              <span aria-hidden="true" class="custom-control-indicator"></span>
              <span class="custom-control-description">
                {{ blockValue.caption }}
              </span>
            </label>
          </div>
        </div>
      </div>
      <WButton primary outline sm fullWidth
        customClass="mt-q"
        @click="visibilitySettingsSave"
      >
        {{ $t('general.save') }}
      </WButton>
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';

const { mapGetters: featureFlagsMapGetters } = createNamespacedHelpers('featureFlags');

export default {
  name: 'DashboardBlocksVisibilitySettings',
  props: {},
  data() {
    return {
      showSettingsBlock: false,
      blocks: {
        general: {
          serverInfo: { caption: this.$t('dashboard.serverInfo'), value: true },
          cpu: { caption: this.$t('dashboard.cpu'), value: true },
          cpuLoad: { caption: this.$t('dashboard.cpuLoad'), value: true },
          mem: { caption: this.$t('dashboard.memory'), value: true },
          cpuLoadChart: { caption: this.$t('dashboard.cpuLoad'), value: true },
          memoryUsedChart: { caption: this.$t('dashboard.memoryUsed'), value: true },
          connectedCPEsChart: { caption: this.$t('dashboard.connectedCPEs'), value: true },
          configCPEsChart: { caption: this.$t('dashboard.configCPEs'), value: true },
          eventsChart: { caption: this.$t('dashboard.last10events'), value: true },
          mostLoadCPES: { caption: this.$t('dashboard.mostloadCPEs'), value: true },
          mostActiveCPES: { caption: this.$t('dashboard.mostactiveCPEs'), value: true },
          mostActiveClients: { caption: this.$t('dashboard.mostactiveclients'), value: true },
          visitors: { caption: this.$t('dashboard.radar'), value: true }
        },
        marketer: {
          marketerViewLocations: { caption: this.$t('dashboard.locations'), value: true },
          marketerViewlocationsWhereManager: {
            caption: this.$t('dashboard.locationsWhereManager'),
            value: true
          },
          marketerViewLocationsMap: { caption: this.$t('dashboard.locationsMap'), value: true },
          marketerViewAds: { caption: this.$t('dashboard.ads'), value: true },
          marketerViewAdsadsViewStatistic: {
            caption: this.$t('dashboard.adsStatistic'),
            value: true
          },
          marketerViewConnectionsStatistic: {
            caption: this.$t('dashboard.connectionsStat'),
            value: true
          },
          visitors: { caption: this.$t('dashboard.radar'), value: true }
        }
      }
    };
  },
  methods: {
    isBlocksVisibilityItemAvailableForThisBuild(itemName) {
      if (!this.isContentAnalyticsEnabled && itemName === 'visitors') {
        return false;
      }
      return true;
    },
    isBlocksVisibilityItemAvailableForThisBuildForMarketer(itemName) {
      if (!this.isContentAnalyticsEnabled && itemName === 'visitors') {
        return false;
      }
      if (!this.isShowMapsAndLatitudeLongitudeSettings && itemName === 'marketerViewLocationsMap') {
        return false;
      }
      if (!this.isContentPortalEnabled &&
        (itemName === 'marketerViewAds' ||
          itemName === 'marketerViewAdsadsViewStatistic' ||
          itemName === 'marketerViewConnectionsStatistic'))
      {
        return false
      }
      if (!this.areLocationsVisible && (itemName === 'marketerViewLocations' ||
        itemName === 'marketerViewlocationsWhereManager' ||
        itemName === 'marketerViewLocationsMap')) {
        return false;
      }
      return true;
    },
    closeBlock() {
      this.showSettingsBlock = false;
    },
    toggleVisibilitySettingsBlock() {
      // console.log('toggled');
      this.initBlockVisibilityValues();
      this.showSettingsBlock = !this.showSettingsBlock;
    },
    visibilitySettingsSave() {
      Object.keys(this.blocks).forEach((blockSection) => {
        const blockSettings = {};
        // console.log(blockSettings);
        Object.keys(this.blocks[blockSection]).forEach((blockName) => {
          blockSettings[blockName] = this.blocks[blockSection][blockName].value;
        });
        this.$store.commit('setDashboardBlocksVisibilityForBlockSection', {
          blockSection: blockSection,
          blocksSettings: blockSettings
        });
      });
      this.showSettingsBlock = false;
    },
    // initBlockVisibilityValues(){
    //   this.blocks.general.serverInfo.value  = this.dashboardBlocksVisibilityFromStore.general.serverInfo
    //   this.blocks.general.cpu.value  = this.dashboardBlocksVisibilityFromStore.general.cpu
    //   this.blocks.general.cpuLoad.value  = this.dashboardBlocksVisibilityFromStore.general.cpuLoad
    //   this.blocks.general.mem.value  = this.dashboardBlocksVisibilityFromStore.general.mem
    //   this.blocks.general.cpuLoadChart.value  = this.dashboardBlocksVisibilityFromStore.general.cpuLoadChart
    //   this.blocks.general.memoryUsedChart.value  = this.dashboardBlocksVisibilityFromStore.general.memoryUsedChart
    //   this.blocks.general.connectedCPEsChart.value  = this.dashboardBlocksVisibilityFromStore.general.connectedCPEsChart
    //   this.blocks.general.configCPEsChart.value  = this. dashboardBlocksVisibilityFromStore.general.configCPEsChart
    //   this.blocks.general.eventsChart.value = this.dashboardBlocksVisibilityFromStore.general.eventsChart
    //   this.blocks.general.mostLoadCPES.value  = this.dashboardBlocksVisibilityFromStore.general.mostLoadCPES
    //   this.blocks.general.mostActiveCPES.value  = this.dashboardBlocksVisibilityFromStore.general.mostActiveCPES
    //   this.blocks.general. mostActiveClients.value = this.dashboardBlocksVisibilityFromStore.general.mostActiveClients
    //   this.blocks.general.visitors.value  = this.dashboardBlocksVisibilityFromStore.general.visitors
    //
    //   this.blocks.marketer.marketerViewLocations.value  = this.dashboardBlocksVisibilityFromStore.marketer.marketerViewLocations
    //   this.blocks.marketer.marketerViewlocationsWhereManager.value  = this.dashboardBlocksVisibilityFromStore.marketer.marketerViewlocationsWhereManager
    //   this.blocks.marketer.marketerViewLocationsMap.value  = this.dashboardBlocksVisibilityFromStore.marketer.marketerViewLocationsMap
    //   this.blocks.marketer.marketerViewAds.value = this.dashboardBlocksVisibilityFromStore.marketer.marketerViewAds
    //   this.blocks.marketer.marketerViewAdsadsViewStatistic.value = this.dashboardBlocksVisibilityFromStore.marketer.marketerViewAdsadsViewStatistic
    //   this.blocks.marketer.marketerViewConnectionsStatistic.value = this.dashboardBlocksVisibilityFromStore.marketer.marketerViewConnectionsStatistic
    //   this.blocks.marketer.visitors.value = this.dashboardBlocksVisibilityFromStore.marketer.visitors
    // },

    initBlockVisibilityValues() {
      Object.keys(this.dashboardBlocksVisibilityFromStore).forEach((blockSection) => {
        if (blockSection !== 'nameInLocalStorage') {
          Object.keys(this.dashboardBlocksVisibilityFromStore[blockSection]).forEach((blockName) => {
            this.blocks[blockSection][blockName].value =
              this.dashboardBlocksVisibilityFromStore[blockSection][blockName];
          });
        }
      });
    }

    // changeSettingsForBlock(blockSection, blockName, value){
    //
    //   console.log(value)
    // }
  },
  mounted() {
    this.initBlockVisibilityValues();
  },
  computed: {
    ...featureFlagsMapGetters(['isContentAnalyticsEnabled, isContentPortalEnabled']),
    isShowMapsAndLatitudeLongitudeSettings() {
      return this.$store.getters.isShowMapsAndLatitudeLongitudeSettings;
    },
    dashboardBlocksVisibilityFromStore() {
      return this.$store.getters.getDashboardBlocksVisibility;
    },
    isCurrentUserMarketer() {
      return this.$store.state.userData.role === 'marketer';
    }
  }
};
</script>
<style lang="scss" scoped>
.close-button-div {
  float: right;
  position: absolute;
}

.gear-styles {
  font-size: 1.4em;
}

.settings-block {
  display: inline-block;
  width: 300px;
  min-height: 250px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.3);
  position: absolute;
  // top: 0px;
  right: 40px;
  background: white;
  opacity: 0.97;
  padding: 10px;
  border-radius: 3px;
  z-index: 10000;
}

.column-item-wrapper {
  display: inline-block;
  width: 100%;
  line-height: 20px;
}
</style>
