/**
 * Миксин содержит computed с серийным номером ТД (или пустой строкой)
 *
 * Используется только в компонентах, где выводится ионфрмация о ТД и где есть this.cpeEditCurrent
 * (сейчас это панелька редактирования ТД в Aps и модальное окном с информацией о ТД в Maps)
 *
 * @mixin
 *
 */

const cpeEditCurrentSerialNumberMixin = {
  computed: {
    /**
     * Возвращает серийный номер текущей редактируемой ТД, или же "" (пустую строку) если номер отсутствует или если это поле вообще не пришло с бэка
     *
     *  @return {String}
     */
    cpeEditCurrentSerialNumber() {
      if (
        this.cpeEditCurrent &&
        typeof this.cpeEditCurrent === 'object' &&
        Object.prototype.hasOwnProperty.call(this.cpeEditCurrent, 'state') &&
        typeof this.cpeEditCurrent.state === 'object' &&
        Object.prototype.hasOwnProperty.call(this.cpeEditCurrent.state, 'manufacturing_data') &&
        typeof this.cpeEditCurrent.state.manufacturing_data === 'object' &&
        this.cpeEditCurrent.state.manufacturing_data.serial_number
      ) {
        return this.cpeEditCurrent.state.manufacturing_data.serial_number;
      }
      return '';
    }
  }
};

export default cpeEditCurrentSerialNumberMixin;
