/**
 * Содержит настройки для визуального отображения графика eventsSummaryChart
 */
const eventsSummaryChartOptions = {
  chart: {
    type: 'column',
    height: '180px'
  },
  title: {
    text: ''
  },
  legend: {
    labelFormatter() {
      let total = 0;
      for (let i = this.yData.length; i--; ) {
        total += this.yData[i];
      }
      return `${this.name}: ${total}`;
    },
    enabled: true,
    align: 'center'
  },
  xAxis: {
    type: 'datetime'
  },
  yAxis: {
    allowDecimals: false,
    min: 0,
    title: {
      text: ''
    },
    labels: {
      enabled: true
    },
    endOnTick: false
  },
  tooltip: {
    headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
    pointFormat:
      '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
      '<td style="padding:2px"><b>{point.y:.0f}</b></td></tr>',
    footerFormat: '</table>',
    shared: true,
    useHTML: true
  },
  plotOptions: {
    column: {
      stacking: 'normal',
      pointWidth: 18,
      pointPadding: 0,
      borderWidth: 0.5
    },
    series: {
      minPointLength: 4
    }
  }
};
export default eventsSummaryChartOptions;
