const PaymentSystems = () => import(/* webpackChunkName: "PaymentSystems" */ '../views/Portal/PaymentSystems');
const Portal = () => import(/* webpackChunkName: "Portal" */ '../views/Portal/Portal');
const PortalAdsNew = () => import(/* webpackChunkName: "PortalAdsNew" */ '../views/Portal/PortalAdsNew');
const PortalAdsStats = () => import(/* webpackChunkName: "PortalAdsStats" */ '../views/Portal/PortalAdsStats');
const PortalBirthdays = () => import(/* webpackChunkName: "PortalBirthdays" */ '../views/Portal/PortalBirthdays');
const PortalSessions = () => import(/* webpackChunkName: "PortalSessions" */ '../views/Portal/PortalSessions');
const PortalDashboard = () => import(/* webpackChunkName: "PortalDashboard" */ '../views/Portal/PortalDashboard');
const PortalHotelVouchers = () => import(/* webpackChunkName: "PortalHotelVouchers" */ '../views/Portal/PortalHotelVouchers');
const PortalPagesNew = () => import(/* webpackChunkName: "PortalPagesNew" */ '../views/Portal/PortalPagesNew');
const PortalPreview = () => import(/* webpackChunkName: "PortalPreview" */ '../views/Portal/PortalPreview');
const PortalProfilesNew = () => import(/* webpackChunkName: "PortalProfilesNew" */ '../views/Portal/PortalProfilesNew');
const PortalStats = () => import(/* webpackChunkName: "PortalStats" */ '../views/Portal/PortalStats');
const Tariffs = () => import(/* webpackChunkName: "Tariffs" */ '../views/Portal/Tariffs');
const UserAccounts = () => import(/* webpackChunkName: "UserAccounts" */ '../views/Portal/UserAccounts');
const Vouchers = () => import(/* webpackChunkName: "Vouchers" */ '../views/Portal/Vouchers');
const PortalLocationsStat = () => import(/* webpackChunkName: "PortalLocationsStat" */ '../views/Portal/PortalLocationsStat');
const PortalAccessServers = () => import(/* webpackChunkName: "PortalAccessServer" */ '../views/Portal/PortalAccessServers');

const ProfilesRoute = {
  path: 'portal',
  name: 'Portal',
  component: Portal,
  redirect: 'portal/dashboard',
  children: [
    {
      path: 'profiles',
      name: 'PortalProfilesNew',
      component: PortalProfilesNew
    },
    {
      path: 'pages',
      name: 'PortalPagesNew',
      component: PortalPagesNew
    },
    {
      path: 'ads',
      name: 'PortalAdsNew',
      component: PortalAdsNew,
      props: true
    },
    {
      path: 'statistics-ads',
      name: 'PortalAdsStats',
      component: PortalAdsStats,
      props: true
    },
    {
      path: 'accounts',
      name: 'UserAccounts',
      component: UserAccounts
    },
    {
      path: 'sessions',
      name: 'PortalSessions',
      component: PortalSessions
    },
    {
      path: 'birthdays',
      name: 'PortalBirthdays',
      component: PortalBirthdays
    },
    {
      path: 'preview',
      name: 'PortalPreview',
      component: PortalPreview
    },
    {
      path: 'statistics',
      name: 'PortalStats',
      component: PortalStats
    },
    {
      path: 'vouchers',
      name: 'Vouchers',
      component: Vouchers
    },
    {
      path: 'hotel-vouchers',
      name: 'PortalHotelVouchers',
      component: PortalHotelVouchers
    },
    {
      path: 'tariffs',
      name: 'Tariffs',
      component: Tariffs
    },
    {
      path: 'payment-systems',
      name: 'PaymentSystems',
      component: PaymentSystems
    },
    {
      path: 'dashboard',
      name: 'PortalDashboard',
      component: PortalDashboard
    },
    {
      path: 'statistics-location',
      name: 'PortalLocationsStat',
      component: PortalLocationsStat
    },
    {
      path: 'access-servers',
      name: 'PortalAccessServers',
      component: PortalAccessServers
    }
  ]
};

export default ProfilesRoute;
