// selected checkbox of a row or not
function getSelectedRowValue(currentBranchProfileId, selectedBranchProfileIdsArray) {
  let value = false;

  selectedBranchProfileIdsArray.forEach(apId => {
    if (apId === currentBranchProfileId) {
      value = true;
    }
  });

  return value;
}

export {
  getSelectedRowValue
};
