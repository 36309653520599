var render = function () {
var _obj;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"col-lg-12"},[_c('div',{staticClass:"card",class:( _obj = {}, _obj[("card-accent-" + _vm.cardAccent)] = _vm.cardAccent, _obj )},[_vm._t("loader"),_vm._t("header-close-button"),_c('div',{staticClass:"card-header d-flex align-items-center",class:{'folding-card-header-when-not-open': !_vm.isBlockShown }},[_c('div',{staticClass:"clickable width_full d-flex",on:{"click":_vm.toggleBlockDisplaying}},[(_vm.isBlockShown)?_c('i',{directives:[{name:"tooltip",rawName:"v-tooltip.top-center",value:({ content: this.$t('general.minimize'),
                            popperOptions: {
                                modifiers: {
                                  preventOverflow: {
                                    boundariesElement: 'window'
                                  }
                                }} }),expression:"{ content: this.$t('general.minimize'),\n                            popperOptions: {\n                                modifiers: {\n                                  preventOverflow: {\n                                    boundariesElement: 'window'\n                                  }\n                                }} }",modifiers:{"top-center":true}}],staticClass:"icon-arrow-down icon-padding"}):_c('i',{directives:[{name:"tooltip",rawName:"v-tooltip.top-center",value:({ content: this.$t('general.expand'),
                            popperOptions: {
                                modifiers: {
                                  preventOverflow: {
                                    boundariesElement: 'window'
                                  }
                                }} }),expression:"{ content: this.$t('general.expand'),\n                            popperOptions: {\n                                modifiers: {\n                                  preventOverflow: {\n                                    boundariesElement: 'window'\n                                  }\n                                }} }",modifiers:{"top-center":true}}],staticClass:"icon-arrow-right icon-padding"}),_vm._t("header-icon"),_c('span',[_vm._v(" "+_vm._s(_vm.headerTitle))]),_vm._t("header-caption",[_c('div',{staticClass:"text-muted add-padding one-line-text"},[_c('small',[_vm._v(_vm._s(_vm.headerCaption))])])])],2),_vm._t("header-link")],2),(_vm.isBlockShown)?_c('div',{staticClass:"card-block"},[_c('div',{staticClass:"row"},[_vm._t("body")],2)]):_vm._e()],2)])}
var staticRenderFns = []

export { render, staticRenderFns }