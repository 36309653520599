/**
 * Модуль Vuex для управления темами приложения.
 *
 * @module theme
 * @namespace
 *
 * Здесь размещаются стейты, геттеры, мутации и экшны, специфические для темификации.
 * Для использования в компонентах и других модулях Vuex.
 *
 * doc о модулях vuex:
 * https://v3.vuex.vuejs.org/ru/guide/modules.html
 */

import { THEME_STATES } from '@/config';
import { ThemeMaster } from '@/utils';
import {
  getDefaultTheme,
  setClassToTheDocument,
  getThemeBrandPrefix
} from './utils';

const theme = {
  state: () => ({
    /**
     * Текущая тема (light/dark)
     * можно поменять в vue dev tools, и протестировать стили.
     */
    currentTheme: getDefaultTheme(),
    /**
     * Текущий бренд
     * можно поменять в vue dev tools, и протестировать стили.
     */
    currentBrandPrefix: getThemeBrandPrefix()
  }),
  getters: {
    /**
     * Получает имя темы с префиксом бренда.
     * @param {Object} state - Состояние хранилища Vuex.
     * @returns {string} - Имя темы с префиксом бренда.
     */
    themeName: (state) => `${state.currentBrandPrefix}-theme-${state.currentTheme}`,
    isLightTheme: (state) => state.currentTheme === THEME_STATES.light,
    isDarkTheme: (state) => state.currentTheme === THEME_STATES.dark
  },
  mutations: {
    setTheme(state, newTheme) {
      state.currentTheme = newTheme;
    }
  },
  actions: {
    /**
     * Инициализирует тему при загрузке приложения.
     * @param {Object} context - Контекст хранилища Vuex.
     */
    initTheme({ getters }) {
      setClassToTheDocument(getters.themeName);
    },
    /**
     * Переключает класс темы в документе.
     * !!! Напрямую в компонентах не используется
     * @param {Object} context - Контекст хранилища Vuex.
     */
    _toggleThemeClassInTheDocument({ getters }) {
      setClassToTheDocument(getters.themeName);
    },
    /**
     * Кэширует текущую тему.
     * !!! Напрямую в компонентах не используется
     * @param {Object} context - Контекст хранилища Vuex.
     * @param {string} newTheme - Новая тема для кэширования.
     */
    _cacheCurrentTheme(ctx, newTheme) {
      ThemeMaster.setCachedTheme(newTheme);
    },
    /**
     * Переключает текущую тему.
     * @param {Object} context - Контекст хранилища Vuex.
     */
    toggleTheme({ state, commit, dispatch }) {
      const newTheme = state.currentTheme === THEME_STATES.light
        ? THEME_STATES.dark
        : THEME_STATES.light;

      commit('setTheme', newTheme);
      dispatch('_toggleThemeClassInTheDocument');
      dispatch('_cacheCurrentTheme', newTheme);
    }
  },
  namespaced: true
};

export default theme;
