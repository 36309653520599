/**
 * Минимально и максимально возможное значение Dynamic VLAN для ввода
 * @type {{min: number, max: number}}
 */
const DYNAMIC_VLAN_BOUNDARIES = { min: 1, max: 4096 };

/**
 * Минимально и максимально возможное значение VLAN для ввода
 * @type {{min: number, max: number}}
 */
const VLAN_BOUNDARIES = { min: 0, max: 4094 };

/**
 * Минимально и максимально возможное значение  VLAN для ввода для сборки WLC, если для WLAN включен
 * AAA Override
 * @type {{min: number, max: number}}
 */
const VLAN_BOUNDARIES_FOR_WLC_WITH_AAA_OVERIDE_ON = { min: 1, max: 4094 };

export { DYNAMIC_VLAN_BOUNDARIES, VLAN_BOUNDARIES, VLAN_BOUNDARIES_FOR_WLC_WITH_AAA_OVERIDE_ON };
