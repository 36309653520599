/**
 * Оценивает, включен ли брендинг для Билайна.
 *
 * @function
 * @returns {boolean} - Возвращает true, если включен, иначе - false.
 *
 * @example
 * Пример использования в навигационном охраннике
 *
 * const router = new VueRouter({
 *   routes: [
 *     {
 *       path: '/some-protected-route',
 *       component: SomeProtectedComponent,
 *       beforeEnter: (to, from, next) => {
 *         if (isBeelineBrandingGuard()) {
 *           * // Разрешить навигацию, так как брендинг Билайна включен
 *           next();
 *         } else {
 *           * // В случае, если брендинг не Билайна, выполнить перенаправление
 *           next({ name: 'Dashboard' });
 *         }
 *       },
 *     },
 *   ],
 * });
 */

import store from '@/store';

const isBeelineBrandingGuard = () => {
  const isBeelineBranding = store.getters['branding/isBeelineBranding'];

  return isBeelineBranding;
};

export default isBeelineBrandingGuard;
