/**
 * Подписывается на изменения состояния в хранилище Vuex, вызывая коллбэки при изменении состояния.
 *
 * @param {Object} options - Опции подписчика на состояние.
 * @param {string} options.storeGetterName - Имя геттера хранилища Vuex, состояние которого нужно отслеживать.
 * @param {Function[]} options.callbackSubscribers - Массив коллбэк функций, которые будут вызываться при изменении состояния.
*/

import store from '@/store';

const onStoreStateSubscriber = ({
  storeGetterName,
  callbackSubscribers
}) => {
  store.watch(
    () => store.getters[storeGetterName],
    (newState) => {
      callbackSubscribers.forEach(callback => {
        callback(newState);
      });
    }
  );
};

export default onStoreStateSubscriber;
