import {
  executeRouterGuards,
  isNotOperatorRoleGuard
} from './navigationGuards';

const Firewall = () => import(/* webpackChunkName: "Firewall" */ '../views/Firewall/Firewall');
const FirewallWizard = () => import(/* webpackChunkName: "FirewallWizard" */ '../views/Firewall/FirewallWizard');
const FirewallStep1 = () => import(/* webpackChunkName: "FirewallWizard" */ '../views/Firewall/FirewallStep1');
const FirewallStep2 = () => import(/* webpackChunkName: "FirewallWizard" */ '../views/Firewall/FirewallStep2');
const FirewallStep3 = () => import(/* webpackChunkName: "FirewallWizard" */ '../views/Firewall/FirewallStep3');

const FirewallRoute = {
  path: 'Firewall',
  name: 'Firewall',
  component: Firewall,
  children: [
    {
      path: 'firewall-wizard',
      name: 'firewall-wizard',
      redirect: 'firewall-wizard/firewall-step-1',
      component: FirewallWizard,
      beforeEnter: executeRouterGuards(isNotOperatorRoleGuard, { name: 'Aps' }),
      children: [
        {
          path: 'firewall-step-1',
          name: 'firewall-step-1',
          component: FirewallStep1
        },
        {
          path: 'firewall-step-2',
          name: 'firewall-step-2',
          component: FirewallStep2
        },
        {
          path: 'firewall-step-3',
          name: 'firewall-step-3',
          component: FirewallStep3
        }
      ]
    }
  ]
};

export default FirewallRoute;
