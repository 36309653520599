const GuestControl = () => import(/* webpackChunkName: "GuestControl" */ '../views/GuestControl/GuestControl');
const GuestControlWizard = () => import(/* webpackChunkName: "GuestControlWizard" */ '../views/GuestControl/GuestControlWizard');
const GuestControlStep1 = () => import(/* webpackChunkName: "GuestControlWizard" */ '../views/GuestControl/GuestControlStep1');
const GuestControlStep2 = () => import(/* webpackChunkName: "GuestControlWizard" */ '../views/GuestControl/GuestControlStep2');

const GuestControlRoute = {
  path: 'GuestControl',
  name: 'GuestControl',
  component: GuestControl,
  children: [
    {
      path: 'GuestControl-wizard',
      name: 'GuestControl-wizard',
      redirect: 'GuestControl-wizard/GuestControl-step-1',
      component: GuestControlWizard,
      children: [
        {
          path: 'GuestControl-step-1',
          name: 'GuestControl-step-1',
          component: GuestControlStep1
        },
        {
          path: 'GuestControl-step-2',
          name: 'GuestControl-step-2',
          component: GuestControlStep2
        }
      ]
    }
  ]
};

export default GuestControlRoute;
