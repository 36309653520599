<template>
  <aside class="w-sidebar-wrapper">
    <div class="heading-wrapper">
      <slot name="heading"></slot>
    </div>
    <div class="content-wrapper">
      <slot name="content"></slot>
    </div>
    <div class="footer-wrapper">
      <slot name="footer"></slot>
    </div>
  </aside>
</template>

<script>
export default {
  name: 'SidebarWrapper'
};
</script>

<style lang="css" scoped>

.w-sidebar-wrapper {
  max-width: 240px;
  height: 100%;

  display: flex;
  flex-direction: column;
  gap: 28px;

  background-color: var(--sidebar-bg-color);
  color: var(--sidebar-font-color);
}

.heading-wrapper {
  flex: 0 0 auto;
  display: flex;
}

.content-wrapper {
  flex: 1 0 auto;
}

.footer-wrapper {
  flex: 0 0 auto;
}
</style>
