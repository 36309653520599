<template>
  <div>
    <WCard class="form-card"
      v-if="isCreateVlanFormOpen"
    >
      <CreateVLANForm
        :forbiddenVlanNames="forbiddenVlanNames"
        :forbiddenVlanIds="forbiddenVlanIds"
        :suggestedVlanNames="suggestedVlanNames"
        :suggestedAclNames="suggestedAclNames"
        @submit="handleCreateVlan"
      >
        <template v-slot:form-control-buttons>
          <div class="button-section">
            <WButton secondary outline
              type="reset"
              @click="closeCreateVlanForm"
            >
              {{ $t('general.cancel') }}
            </WButton>
            <WButton success
              type="submit"
            >
              {{ $t('general.save') }}
            </WButton>
          </div>
        </template>
      </CreateVLANForm>
    </WCard>

    <WCard class="form-card"
      v-if="isChangeVlanFormOpen"
    >
      <ChangeVLANForm
        :forbiddenVlanNames="forbiddenVlanNames"
        :forbiddenVlanIds="forbiddenVlanIds"
        :suggestedVlanNames="suggestedVlanNames"
        :suggestedAclNames="suggestedAclNames"
        :selectedVLANForChanging="selectedVLANForChanging"
        @submit="handleChangeVlan"
      >
      <template v-slot:form-control-buttons>
        <div class="button-section">
          <WButton secondary outline
            type="reset"
            @click="closeChangeVlanForm"
          >
            {{ $t('general.cancel') }}
          </WButton>
          <WButton success
            type="submit"
          >
            {{ $t('general.save') }}
          </WButton>
        </div>
      </template>
    </ChangeVLANForm>
    </WCard>

    <VLANTable
      :items="branchProfileVlans"
      :selectedVLANIds="selectedVLANIds"
      :activeRowVLANId="selectedVLANForChanging.vlan_id"
      :isDataLoading="false"
      :isDisabled="false"
      :isOneColMode="false"
      :isSelectionRowsModeEnabled="true"
      :perPage="limit"
      @on-cell-click="handleCellClick"
      @on-selected-rows-change="handleSelectRow"
      @on-per-page-change="handlePerPageChange"
    >
      <template v-slot:table-menu>
        <div class="control-panel">
          <div class="control-panel__container left">
            <WButton info
              :disabled="!isVLANTableInteractionEnabled"
              @click="openCreateVlanForm"
            >
              {{ $t('general.add') }}
            </WButton>
            <WButton info
              :disabled="!isVLANTableInteractionEnabled || !selectedVLANIds.length"
              @click="openDeleteVlanModal"
            >
              {{ $t('general.delete') }}
            </WButton>
          </div>
          <div class="control-panel__container right">
          </div>
        </div>
      </template>
    </VLANTable>

    <DeleteVLANModal
      :vlanIdsToDelete="selectedVLANIds"
      :isOpen="isDeleteVlanModalOpen"
      @close="closeDeleteVlanModal"
      @confirm="handleDeleteVlans"
    />

  </div>
</template>

<script>
/**
 *
 * компонент управления содержимым таба vlan.
 * Содержит разметку, правила рендеринга таба vlan.
 *
 * ! компонент локальный, используется только в BranchProfileFormTabContent.vue
 * (Но по архитектуре - независимый, может использоваться самостоятельно или в другом окружении)
 * @component
 *
 */

import {
  VLANTable,
  CreateVLANForm,
  ChangeVLANForm,
  DeleteVLANModal
} from './components';

export default {
  name: 'VLANTabContent',
  inject: ['$validator'],
  components: {
    VLANTable,
    CreateVLANForm,
    ChangeVLANForm,
    DeleteVLANModal
  },
  props: {
    tabContent: {
      type: Array,
      required: true
    },
    branchProfileVlans: {
      type: Array,
      required: true
    },
    suggestedVlanNames: {
      type: Array,
      required: true
    },
    suggestedAclNames: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      selectedVLANIds: [],
      selectedVLANForChanging: {},
      limit: 5,
      isCreateVlanFormOpen: false,
      isChangeVlanFormOpen: false,
      isDeleteVlanModalOpen: false
    };
  },
  computed: {
    forbiddenVlanNames() {
      return this.branchProfileVlans.map(vlan => vlan.name);
    },
    forbiddenVlanIds() {
      return this.branchProfileVlans.map(vlan => vlan.vlan_id);
    },
    isVLANTableInteractionEnabled() {
      const condition = this.isCreateVlanFormOpen || this.isChangeVlanFormOpen || this.isDeleteVlanModalOpen;
      return !condition;
    }
  },
  methods: {
    handleCellClick(rowData) {
      const { nativeData } = rowData;

      if (!this.isVLANTableInteractionEnabled) {
        return;
      }

      this.selectedVLANForChanging = nativeData;

      this.openChangeVlanForm();
    },
    handleSelectRow(selectedRowData) {
      const selectedVlanIds = selectedRowData.map(rowData => rowData.vlanId);

      this.selectedVLANIds = selectedVlanIds;
    },
    handleCreateVlan(newVlanData) {
      const {
        vlanName,
        vlanId,
        aclIngress,
        aclEgress
      } = newVlanData;

      this.$emit('update:branchProfileVlans', {
        value: [
          ...this.branchProfileVlans,
          {
            name: vlanName,
            vlan_id: vlanId,
            acl_ingress: aclIngress,
            acl_egress: aclEgress
          }
        ]
      });

      this.closeCreateVlanForm();
    },
    handleChangeVlan(updatedVlanData) {
      const {
        vlanName,
        vlanId,
        aclIngress,
        aclEgress
      } = updatedVlanData;

      const branchProfileVlansWithChangedOne = this.branchProfileVlans.map(vlan => {
        if (vlan.vlan_id === vlanId) {
          return {
            name: vlanName,
            vlan_id: vlanId,
            acl_ingress: aclIngress,
            acl_egress: aclEgress
          };
        }

        return vlan;
      });

      this.$emit('update:branchProfileVlans', {
        value: branchProfileVlansWithChangedOne
      });

      this.closeChangeVlanForm();
    },
    handleDeleteVlans() {
      const vlanIdsToDelete = this.selectedVLANIds;

      const filteredVLANs = this.branchProfileVlans.filter(vlan => {
        return !vlanIdsToDelete.includes(vlan.vlan_id);
      });

      this.$emit('update:branchProfileVlans', {
        value: filteredVLANs
      });

      this.selectedVLANIds = [];
      this.closeDeleteVlanModal();
    },
    handlePerPageChange(paginationParams) {
      const { currentPerPage } = paginationParams;
      this.limit = currentPerPage;
    },
    openCreateVlanForm() {
      this.isCreateVlanFormOpen = true;
    },
    closeCreateVlanForm() {
      this.isCreateVlanFormOpen = false;
    },
    openChangeVlanForm() {
      this.isChangeVlanFormOpen = true;
    },
    closeChangeVlanForm() {
      this.isChangeVlanFormOpen = false;

      this.selectedVLANForChanging = {};
    },
    openDeleteVlanModal() {
      this.isDeleteVlanModalOpen = true;
    },
    closeDeleteVlanModal() {
      this.isDeleteVlanModalOpen = false;
    }
  },
  mounted() {
  }
};
</script>

<style lang="css" scoped>
.button-section {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.control-panel {
  display: flex;
  gap: 24px;
  padding: 8px 0;
}

.control-panel__container {
  display: flex;
  gap: 8px;
}

.control-panel__container.right {
  justify-content: flex-end;
  flex: 1 1 auto;
}
</style>
