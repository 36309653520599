<template>
  <div
    class="cpe-tunnels__wrapper mt-0"
    :class="classForWrapperElement"
  >
    <div v-if="tunnelsListLength === 0">
      {{ $t('aps.tunnels.noTunnels').toLowerCase() }}
    </div>
    <div v-else>
      <div>
        <div v-for="tunnel of tunnelsListSortedByl2interfaceName">
          {{ cpeTunnels[tunnel.tunnelNameObjectKey].host_l2interface_name }}
        </div>
      </div>
      <!--      <pre>{{ cpeTunnels }}</pre>-->
    </div>
  </div>
</template>

<script>
export default {
  name: 'Tunnels',
  props: {
    cpeTunnels: {
      // cюда передается объект с тунелями CPE
      type: Object,
      required: true
    },
    classForWrapperElement: {
      type: String,
      default: ''
    }
  },
  computed: {
    // cpeTunnelsMock() {
    //   return {
    //     'br-clients': {
    //       cpe_interface_name: 'l2tps1',
    //       cpe_session_id: 1,
    //       cpe_tunnel_id: 1,
    //       host_interface_name: ' br-clients',
    //       host_l2interface_name: 't85984-s54640',
    //       host_session_id: 54640,
    //       host_tunnel_id: 85984
    //     },
    //     'ar-clients': {
    //       cpe_interface_name: 'l2tps1',
    //       cpe_session_id: 1,
    //       cpe_tunnel_id: 1,
    //       host_interface_name: 'br-clients',
    //       host_l2interface_name: 'a85984-s54640',
    //       host_session_id: 54640,
    //       host_tunnel_id: 85984
    //     }
    //   };
    // },
    tunnelsListSortedByl2interfaceName() {
      try {
        if (this.tunnelsListLength !== 0) {
          // const listForSort = Object.values(this.cpeTunnels);

          const listForSort = [];
          for (const tunnelNameObjectKey in this.cpeTunnels) {
            listForSort.push({ ...this.cpeTunnels[tunnelNameObjectKey], tunnelNameObjectKey: tunnelNameObjectKey });
          }

          const sortedList = listForSort.sort((a, b) => {
            if (a.host_l2interface_name.toLowerCase() > b.host_l2interface_name.toLowerCase()) {
              return 1;
            }
            if (a.host_l2interface_name.toLowerCase() < b.host_l2interface_name.toLowerCase()) {
              return -1;
            }
            return 0;
          });
          return sortedList;
        }
        return [];
      } catch (e) {
        console.log(e);
        return [];
      }
    },
    tunnelsListLength() {
      try {
        if (this.cpeTunnels !== undefined && typeof this.cpeTunnels === 'object' && this.cpeTunnels !== null) {
          return Object.keys(this.cpeTunnels).length;
        }
        return 0;
      } catch (e) {
        console.log(e);
        return 0;
      }
    }
  }
};
</script>

<style scoped>
.cpe-tunnels__wrapper {
}
</style>
