/**
 * Миксин содержит метод-хенделер для свитчера ppsk, ииспользуемого для настроек WLAN
 *
 * Используется только в разделах с созданием/редактированием WLAN
 *
 * @mixin
 *
 */



const ppskSwitcherHandlerMixin = {
  methods: {
    /**
     * При переключении свитчера ppsk копирует выбранные RADIUS серверы из одного места другое.
     * Для праавильного отображения в UI. Поскольку в UI в зависимости от ppsk отображаются разные списки с разными v-model,
     * Которые отправляются на бэк в разных полях объекта wlan. Но в UI отображаются в одом месте
     * @param event
     */
    ppskSwitcherHandler(event) {
      const eventValue = event.target.value;
      // console.log(eventValue);
      if (eventValue === 'true') {
        this.updatedWlanRadiusAuthentication = [...this.updatedWlanRadiusMAC];
        this.updatedWlanRadiusMAC = [];
      } else {
        this.updatedWlanRadiusMAC = [...this.updatedWlanRadiusAuthentication];
        this.updatedWlanRadiusAuthentication = [];
      }
    },

  }
};

export default ppskSwitcherHandlerMixin;
