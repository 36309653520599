<template>
  <div class="col-12">
    <h3 v-if="title" class="my-1">{{ title }}</h3>
    <div class="row">
      <div class="col-6 glossary-text" :class="{ category_second_level: secondLevel === true }">
        <h4 v-if="header && headerLink" class="col-12">
          <router-link :to="{ name: headerLink }">
            <text-highlight :queries="queriesForSearch">{{ header }}</text-highlight>
          </router-link>
        </h4>
        <h4 v-else-if="header" class="col-12">
          <text-highlight :queries="queriesForSearch">{{ header }}</text-highlight>
        </h4>
        <slot name="rawText">
          <div class="col-12">
            <text-highlight :queries="queriesForSearch">{{ rawText }}</text-highlight>
          </div>
        </slot>
        <ul v-if="listedText.length" class="glossary-text">
          <li v-for="(row, index) in listedText" :key="index">
            <text-highlight :queries="queriesForSearch">{{ row }}</text-highlight>
          </li>
        </ul>
      </div>
      <div class="col-6 d-flex flex-column justify-content-center align-items-center glossary-img-wrapper">
        <img
          v-for="(url, index) in imgUrls"
          class="glossary-img"
          :class="{ 'mt-q': index !== 0 }"
          :src="url"
          :key="url"
          role="button"
          @click="handleScale"
        />
        <transition name="fade">
          <div v-if="src" class="popup">
            <div class="popup_bg" @click="src = ''"></div>
            <img :src="src" class="popup_img" @click="src = ''" />
          </div>
        </transition>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'GlossaryCard',
  props: {
    title: { type: String, default: '' },
    header: { type: String, default: '' },
    rawText: { type: String, default: '' },
    listedText: { type: Array, default: () => [] },
    imgUrls: { type: Array, default: () => [] },
    headerLink: { type: String, default: '' },
    queriesForSearch: { type: Array, default: () => [] },
    secondLevel: { type: Boolean, default: false }
  },
  data() {
    return { src: '' };
  },
  methods: {
    handleScale(img) {
      const { src } = img.srcElement;
      this.src = src;
    }
  }
};
</script>

<style lang="scss" scoped>
.glossary-img {
  max-height: 300px;
  max-width: 80%;
  /*max-width: 500px;*/
}

.glossary-img-wrapper {
  max-width: 100%;
}

.popup {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  /* display: none; */
  text-align: center;
  z-index: 1041;
}

.popup_bg {
  background: rgba(0, 0, 0, 0.8);
  position: fixed;
  z-index: 1041;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: auto;
  height: auto;
  cursor: pointer;
}

.popup_img {
  position: fixed;
  z-index: 1042;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  max-height: 90%;
  max-width: 90%;
  cursor: pointer;
}

.popup_img {
  pointer-events: none;
}

.category_second_level {
  padding-left: 2em;
}
</style>
