<template>
  <div class="w-card">
    <slot></slot>
  </div>
</template>

<script>
/**
* UI-компонент WCard - карточка.
* Используется во всем проекте. Регистрируется глобально (в конкретном компоненте регистрировать не нужно).
* Использовать как оболочку для содержимого. Она содержит минимальную стилизацию, которая должна быть везде.
* Цвета и проч навешивать на содержимое.
*
* Примеры использования компонента WCard:
*
* @example
* <WCard :class="card-content-wrapper">
    <h2>Card heading</h2>
    <p>Lorem ipsum</p>
    <WButton success @click="handleClick">Buy</WButton>
  </WCard>
*
* @example
* Больше конкретных примеров использования доступно при поиске по проекту.
*/

export default {
  name: 'WCard',
  data() {
    return {};
  },
  computed: {
  },
  methods: {
  }
};
</script>

<style lang="css" scoped>
.w-card {
  padding: 20px;

  display: flex;
  flex-direction: column;

  background-color: transparent;
  box-shadow: var(--box-shadow);
  border-radius: var(--border-radius);
}
</style>
