import { COUNTRIES_CODES } from '@/config';

const countriesCodesOptionsMixin = {
  computed: {
    /**
     * Список опций для селектора выбора кода страны
     * Используется в настройках радиоинтерфейсов
     *
     */
    countriesCodesOptionsForSelectorInInterfaceRadioSettings() {
      return COUNTRIES_CODES.map((countryCode) => {
        const isI18nNameForCountryCode = this.$te(`countries.${countryCode}`);
        const countryNameForDisplaying = isI18nNameForCountryCode
          ? `${countryCode} (${this.$t(`countries.${countryCode}`)})`
          : `${countryCode}`;
        return { data: countryCode, display: countryNameForDisplaying };
      });
    }
  }
};

export default countriesCodesOptionsMixin;
