import { STORAGE_KEY_LOCALE, LOCALES } from '../config';
import { ObjectStorage } from './storage';

/**
 * Класс для управления кэшированной локалью.
 */

export default class LocaleMaster {
  /**
   * Получает кэшированную локаль из LocalStorage.
   * @returns {string | null} - Кэшированная локаль или null, если локаль не найдена.
   * @example
   * const cachedLocale = LocaleMaster.getCachedLocale();
   * if (cachedLocale) {
   *   console.log('Кэшированная локаль:', cachedLocale);
   * } else {
   *   console.log('Локаль не найдена в кэше.');
   * }
   */
  static getCachedLocale() {
    return ObjectStorage.getItem(STORAGE_KEY_LOCALE);
  }

  /**
   * Устанавливает кэшированную локаль в LocalStorage.
   * @param {string} newLocale - Новая локаль для кэширования.
   * @example
   * LocaleMaster.setCachedLocale(LOCALES.en);
   */
  static setCachedLocale(newLocale) {
    ObjectStorage.setItem(STORAGE_KEY_LOCALE, newLocale);
  }

  /**
   * Очищает кэшированную локаль из LocalStorage.
   * @example
   * LocaleMaster.clearCachedLocale();
   */
  static clearCachedLocale() {
    ObjectStorage.removeItem(STORAGE_KEY_LOCALE);
  }

  /**
   * Проверяет, является ли кэшированная локаль русской.
   * @returns {boolean} true, если кэшированная локаль - русская, в противном случае false.
   * @example
   * if (LocaleMaster.isCachedLocaleRU()) {
   *   console.log('Кэшированная локаль - русская.');
   * } else {
   *   console.log('Кэшированная локаль не является русской.');
   * }
   */
  static isCachedLocaleRU() {
    const currentCachedLocale = ObjectStorage.getItem(STORAGE_KEY_LOCALE);
    return currentCachedLocale === LOCALES.ru;
  }

  /**
   * Проверяет, является ли кэшированная локаль английской.
   * @returns {boolean} true, если кэшированная локаль - английская, в противном случае false.
   * @example
   * if (LocaleMaster.isCachedLocaleEN()) {
   *   console.log('Кэшированная локаль - английская.');
   * } else {
   *   console.log('Кэшированная локаль не является английской.');
   * }
   */
  static isCachedLocaleEN() {
    const currentCachedLocale = ObjectStorage.getItem(STORAGE_KEY_LOCALE);
    return currentCachedLocale === LOCALES.en;
  }
}
