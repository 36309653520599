<template>
  <WButton link @click="toggleOldNewMenu">
    {{ `${isNewMenu ? $t('sidebarNew.newMenuStyle') : $t('sidebarNew.oldMenuStyle')}` }}
  </WButton>
</template>

<script>
export default {
  name: 'MenuTypeToggler',
  data() {
    return {};
  },
  computed: {
    isNewMenu() {
      // todo refactor
      return this.$store.state.menuShowForExperementalBuild === 'new';
    }
  },
  methods: {
    toggleOldNewMenu() {
      // todo refactor
      this.$store.commit('toggleOldNewMenu');
    }
  }
};
</script>

<style lang="css" scoped>
</style>
