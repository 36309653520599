<template>
  <modal  close-btn @cancel="cancelChangeLocation"  title="Change locations" v-model="isChangeLocationOpen" class="modal-info" effect="fade/zoom">
    <h4 slot="modal-title" class="modal-title">{{ $t('general.changeLocations') }}</h4>
    <div>
      {{ $t('general.modalChangeLocationsCaption') }} {{ list.length > 1 ? list.length : '' }}
      {{ getCurrentObject(type, list.length) }}?
      <ul class="list-unstyled mt-q">
        <li
          class="d-inline-block mr-h tooltip-text"
          v-for="(item, i) in list"
          v-if="showItem(i)"
          :key="item"
          v-tooltip.top-center="{
            content: `<ul class='list-unstyled text-left m-0'>
                                                <li><small><b>id</b>: ${item}</small></li>
                                                <li><small><b>location</b>:
                                                  ${getCurrentLocation(item, type)}
                                                </small></li>
                                              </ul>`
          }"
        >
          <b>
            {{ getCurrentItem(item, type) }}
            <span v-if="i === 9 && list.length > limit">...</span>
          </b>
        </li>
        <li v-if="list.length > limit" class="mr-h mb-h mt-h">
          <a href="#" class="m-0" @click.prevent="limit = Infinity" v-if="list.length > limit">show all</a>
        </li>
      </ul>
    </div>
    <label for="mass-location">{{ $t('aps.location') }}</label>
    <select v-model="locationId" name="mass-location" id="mass-location" class="form-control">
      <option v-for="location in locationsList" :key="location.id" :value="location.id">
        {{ location.name }}
      </option>
    </select>
    <div slot="modal-footer" class="modal-footer">
      <WButton secondary @click="cancelChangeLocation">
        {{ $t('general.cancel') }}
      </WButton>
      <WButton info
        @click="changeLocations(list, locationId)"
        :disabled="isChangeProcessing"
      >
        {{ $t('general.change') }}
      </WButton>
    </div>
  </modal>
</template>

<script>
import Modal from '../../Modal.vue';

export default {
  name: 'ChangeLocation',
  components: {
    Modal
  },
  props: {
    type: {
      type: String
    },
    isChangeLocationOpen: {
      type: Boolean
    },
    list: {
      type: Array
    },
    commonService: {
      type: Object
    },
    changeLocations: {
      type: Function
    },
    cancelChangeLocation: {
      type: Function
    },
    massLocationId: {
      type: String
    },
    locationsList: {
      type: Array
    }
  },
  watch: {
    isChangeLocationOpen(val) {
      if (!val) {
        this.cancelChangeLocation();
      }
    }
  },
  data() {
    return {
      locationId: this.massLocationId,
      limit: 10
    };
  },
  computed: {
    isChangeProcessing() {
      return this.$store.state.muteChangeLocation;
    }
  },
  methods: {
    getCurrentObject(type, count) {
      switch (type) {
        case 'cpe':
          return count > 1 ? this.$t('general.cpeSeveral') : this.$t('general.cpeOne');
        case 'wlan':
          return count > 1 ? this.$t('general.wlanSeveral') : this.$t('general.wlanOne');
        default:
          return 'objects';
      }
    },
    getCurrentItem(item, type) {
      switch (type) {
        case 'cpe':
          return this.commonService.cpeObjbyId(item).name;
        case 'wlan':
          return this.commonService.wlanObjbyId(item).ssid;
        default:
          return 'no data';
      }
    },
    getCurrentLocation(item, type) {
      switch (type) {
        case 'cpe':
          return this.commonService.showLocationFromId(this.commonService.cpeObjbyId(item).base_location);
        case 'wlan':
          return this.commonService.showLocationFromId(this.commonService.wlanObjbyId(item).base_location);
      }
    },
    showItem(index) {
      return index < this.limit;
    }
  },
  created() {}
};
</script>

<style lang="scss" scoped></style>
