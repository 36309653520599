/* eslint-disable no-param-reassign */
import axios from 'axios';
import { API_URL } from '@/config';
import { AuthTokenMaster } from '@/utils';

const AXIOS_INSTANCE = axios.create({
  baseURL: API_URL,
  headers: {
    'Content-Type': 'application/json'
  }
});

const deleteAuthHeader = () => delete AXIOS_INSTANCE.defaults.headers.common.Authorization;

// используется для проверки авторизации
// подставляет в каждый запрос авторизационный токен, если он есть

const pasteJWTHandler = (axiosRequestConfig) => {
  const token = AuthTokenMaster.getAccessToken();

  if (!token) {
    deleteAuthHeader();

    return axiosRequestConfig;
  }

  if (!axiosRequestConfig.headers) {
    axiosRequestConfig.headers = {};
  }

  axiosRequestConfig.headers.Authorization = `Bearer ${token}`;

  return axiosRequestConfig;
};

AXIOS_INSTANCE.interceptors.request.use(
  pasteJWTHandler
);

export default AXIOS_INSTANCE;
