import RouteGroups from './RouteGroups';

const marketerLocations = [
  ...RouteGroups.ANALYTICS,
  ...RouteGroups.PORTAL,
  ...RouteGroups.COMMON,
  ...RouteGroups.STATISTICSFORMARKETER
];
const hotelRepresentativeLocations = [...RouteGroups.HOTEL];
const advertisingAgent = [...RouteGroups.ADVERTISING_AGENT];

/**
 * Permissions are grouped by user roles
 * Permissions consist of allowed actions and routes where action is allowed
 *
 * Intended actions are: 'all' (all actions are allowed), 'create', 'read', 'update', 'delete'
 * Intended routes are: 'all' (actions is allowed on all routes) or specific routes
 *
 * 'read' permission is checked before route navigation. It is required to display route.
 *
 * Each role can have multiple permissions
 * Permissions and routes include multiple items in a single permission
 * Routes currently must be specified by names
 * Some route names are grouped for easier use (i.e. route + its wizard screens)
 */
const permissions = {
  admin: [
    {
      actions: ['all'],
      routes: ['all']
    }
  ],
  operator: [
    {
      actions: ['read'],
      routes: ['all']
    }
  ],
  marketer: [
    {
      actions: ['all'],
      routes: marketerLocations
    }
  ],
  hotel_employee: [
    {
      actions: ['all'],
      routes: hotelRepresentativeLocations
    }
  ],
  advertising: [
    {
      actions: ['all'],
      routes: advertisingAgent
    }
  ]
};

export default permissions;
