import Vue from 'vue';
import VueNotifications from 'vue-notifications';
import helpers from '../helpers';
import portalPagesScheduleService from './portalPagesScheduleService';
import { PORTAL_API_URL } from '@/config';

export default {
  // getPortalPages(ctx, props = {}) {
  //   ctx.$store.commit('toggleLoadingPortalPages');
  //   const config = props.query ?
  //     {
  //       params: {
  //         limit: ctx.filters.limit,
  //         offset: ctx.filters.offset,
  //         sort: `${ctx.filters.sort_order === -1 ? '-' : ''}${ctx.filters.sort_by}`
  //       }
  //     } :
  //     {};
  //   if (props.query && ctx.filters.search !== '') {
  //     config.params.search = ctx.filters.search;
  //   }
  //   Vue.axios.get(`${PORTAL_API_URL || ''}/api/portal/admin/webpage`, config).then(
  //     (response) => {
  //       if (response.status === 200 && response.data.status === 'success') {
  //         // ctx.$store.commit('setPortalPagesList', response.data.data);
  //         ctx.$store.commit('pushPortalPagesList', response.data.data);
  //         this.getPagesLocations(ctx, ctx.$store.state.portalPagesList);
  //         // portalPagesScheduleService.getAllPortalPagesSchedulesByIds(ctx, ctx.$store.state.portalPagesList);
  //         portalPagesScheduleService.getPortalPagesSchedules(ctx);
  //         ctx.totalResultPortalPages = response.data.total;
  //         if (ctx.$store.state.portalPagesList.length >= response.data.total) {
  //           ctx.canLoadMorePortalPages = false;
  //         } else {
  //           ctx.canLoadMorePortalPages = true;
  //         }
  //       } else if (response.data.status === 'error') {
  //         VueNotifications.error({ message: response.data.description });
  //       }
  //       ctx.$store.commit('toggleLoadingPortalPages');
  //     },
  //     (err) => {
  //       // VueNotifications.error({ message: err });
  //
  //       // show errors in the console instead of the alert window
  //       if (
  //         Object.prototype.hasOwnProperty.call(window, 'isShowAxiosErrorInConsole') &&
  //         typeof window.isShowAxiosErrorInConsole === 'function' &&
  //         window.isShowAxiosErrorInConsole(err)
  //       ) {
  //         window.showAxiosErrInConsole(err);
  //       } else {
  //         VueNotifications.error({ message: err });
  //       }
  //
  //       ctx.error = err;
  //       ctx.$store.commit('toggleLoadingPortalPages');
  //     }
  //   );
  // },
  getPortalPages(ctx, props = {}) {
    ctx.$store.commit('toggleLoadingPortalPages');
    const config = props.query ?
      {
        params: {
          limit: ctx.filters.limit,
          offset: ctx.filters.offset,
          sort: `${ctx.filters.sort_order === -1 ? '-' : ''}${ctx.filters.sort_by}`,
                  location: ctx.$store.getters.getDefaultLocationForRequests(ctx),
        with_childs: ctx.$store.getters.getDefaultWithChildsForRequests(ctx)
        }
      } :
      {        params: {
                  location: ctx.$store.getters.getDefaultLocationForRequests(ctx),
        with_childs: ctx.$store.getters.getDefaultWithChildsForRequests(ctx)
        }};
    if (props.query && ctx.filters.search !== '') {
      config.params.search = ctx.filters.search;
    }
    if (props.query && props.useLocationFilter) {
      if (ctx.filters?.location &&
        typeof ctx.filters.location === 'object' &&
        ctx.filters.location.data  &&
        typeof ctx.filters.location.data === 'object' ) {
        if (Object.keys(ctx.filters.location.data).length === 0) {
          // console.log('is zero ')
          config.params.location = ctx.$store.state.requestBaseUserLocationIfNoSelected ? ctx.userBaseLocation : undefined;
          config.params.with_childs = true;
        } else {
          config.params.location = ctx.filters.location.data.id;
          config.params.with_childs = ctx.filters.location.with_childs;
        }
      }
    }

    Vue.axios.get(`${PORTAL_API_URL || ''}/api/portal/admin/webpage`, config).then(
      (response) => {
        if (response.status === 200 && response.data.status === 'success') {
          // ctx.$store.commit('setPortalPagesList', response.data.data);
          ctx.$store.commit('pushPortalPagesList', response.data.data);
          this.getPagesLocations(ctx, ctx.$store.state.portalPagesList);
          ctx.totalResultPortalPages = response.data.total;
          if (ctx.$store.state.portalPagesList.length >= response.data.total) {
            ctx.canLoadMorePortalPages = false;
          } else {
            ctx.canLoadMorePortalPages = true;
          }
        } else if (response.data.status === 'error') {
          VueNotifications.error({ message: response.data.description });
        }
        ctx.$store.commit('toggleLoadingPortalPages');
      },
      (err) => {
        // VueNotifications.error({ message: err });

        // show errors in the console instead of the alert window
        if (
          Object.prototype.hasOwnProperty.call(window, 'isShowAxiosErrorInConsole') &&
          typeof window.isShowAxiosErrorInConsole === 'function' &&
          window.isShowAxiosErrorInConsole(err)
        ) {
          window.showAxiosErrInConsole(err);
        } else {
          VueNotifications.error({ message: err });
        }

        ctx.error = err;
        ctx.$store.commit('toggleLoadingPortalPages');
      }
    );
  },
  createPage(ctx, page) {
    ctx.$store.commit('toggleMutePortalPageEdit');
    const { base_location } = page;
    Vue.axios
      .post(`${PORTAL_API_URL || ''}/api/portal/admin/webpage`, {
        request: [page],
        base_location
      })
      .then(
        (response) => {
          if (response.status === 200 && response.data.status === 'success') {
            VueNotifications.success({ message: ctx.$t('pageprofile.pageCreatedSuccessfully') });
            ctx.getWithQuery(ctx);
            ctx.cancelEditMode();
            ctx.currentRedirectUrl = response.data.data[0].url_id;
          } else {
            VueNotifications.error({ message: response.data.description });
          }
          ctx.$store.commit('toggleMutePortalPageEdit');
        },
        (err) => {
          // VueNotifications.error({ message: err });

          // show errors in the console instead of the alert window
          if (
            Object.prototype.hasOwnProperty.call(window, 'isShowAxiosErrorInConsole') &&
            typeof window.isShowAxiosErrorInConsole === 'function' &&
            window.isShowAxiosErrorInConsole(err)
          ) {
            window.showAxiosErrInConsole(err);
          } else {
            VueNotifications.error({ message: err });
          }

          ctx.error = err;
          ctx.$store.commit('toggleMutePortalPageEdit');
        }
      );
  },
  createPageFromUsersDemoWizard(ctx, page) {
    const { base_location } = page;
    Vue.axios
      .post(`${PORTAL_API_URL || ''}/api/portal/admin/webpage`, {
        request: [page],
        base_location
      })
      .then(
        (response) => {
          if (response.status === 200 && response.data.status === 'success') {
            VueNotifications.success({ message: ctx.$t('pageprofile.pageCreatedSuccessfully') });
            ctx.$store.commit('setUsersDemoWizardStep5SavedPageObject', response.data.data[0]);
            ctx.nextStep();
          } else {
            VueNotifications.error({ message: response.data.description });
            // ctx.exitFromWizard();
          }
        },
        (err) => {
          // VueNotifications.error({ message: err });

          // show errors in the console instead of the alert window
          if (
            Object.prototype.hasOwnProperty.call(window, 'isShowAxiosErrorInConsole') &&
            typeof window.isShowAxiosErrorInConsole === 'function' &&
            window.isShowAxiosErrorInConsole(err)
          ) {
            window.showAxiosErrInConsole(err);
          } else {
            VueNotifications.error({ message: err });
          }

          ctx.error = err;
          // ctx.exitFromWizard();
        }
      );
  },
  createPageFromEasyInsatllWizard(ctx, page) {
    const { base_location } = page;
    Vue.axios
      .post(`${PORTAL_API_URL || ''}/api/portal/admin/webpage`, {
        request: [page],
        base_location
      })
      .then(
        (response) => {
          if (response.status === 200 && response.data.status === 'success') {
            VueNotifications.success({ message: ctx.$t('pageprofile.pageCreatedSuccessfully') });
            ctx.$store.commit('setEasyInstallWizardStep2SavedPageObject', response.data.data[0]);
            ctx.nextStep();
          } else {
            VueNotifications.error({ message: response.data.description });
            // ctx.exitFromWizard();
          }
        },
        (err) => {
          // VueNotifications.error({ message: err });

          // show errors in the console instead of the alert window
          if (
            Object.prototype.hasOwnProperty.call(window, 'isShowAxiosErrorInConsole') &&
            typeof window.isShowAxiosErrorInConsole === 'function' &&
            window.isShowAxiosErrorInConsole(err)
          ) {
            window.showAxiosErrInConsole(err);
          } else {
            VueNotifications.error({ message: err });
          }

          ctx.error = err;
          // ctx.exitFromWizard();
        }
      );
  },
  updatePage(ctx, page) {
    ctx.$store.commit('toggleMutePortalPageEdit');
    const { base_location } = page;
    delete page.base_location;
    Vue.axios
      .put(`${PORTAL_API_URL || ''}/api/portal/admin/webpage`, {
        request: [page],
        base_location
      })
      .then(
        (response) => {
          if (response.status === 200 && response.data.status === 'success') {
            VueNotifications.success({ message: ctx.$t('pageprofile.pageUpdatedSuccessfully') });
            ctx.getWithQuery(ctx);
            ctx.cancelEditMode();
          } else {
            VueNotifications.error({ message: response.data.description });
          }
          ctx.$store.commit('toggleMutePortalPageEdit');
        },
        (err) => {
          // VueNotifications.error({ message: err });

          // show errors in the console instead of the alert window
          if (
            Object.prototype.hasOwnProperty.call(window, 'isShowAxiosErrorInConsole') &&
            typeof window.isShowAxiosErrorInConsole === 'function' &&
            window.isShowAxiosErrorInConsole(err)
          ) {
            window.showAxiosErrInConsole(err);
          } else {
            VueNotifications.error({ message: err });
          }

          ctx.error = err;
          ctx.$store.commit('toggleMutePortalPageEdit');
        }
      );
  },
  deletePage(ctx, profile) {
    ctx.deletingPageModal = false;
    ctx.$store.commit('toggleMutePortalPageEdit');
    Vue.axios
      .delete(`${PORTAL_API_URL || ''}/api/portal/admin/webpage`, {
        params: {
          ids: [profile.id]
        }
      })
      .then(
        (response) => {
          if (response.status === 200 && response.data.status === 'success') {
            VueNotifications.success({ message: ctx.$t('pageprofile.pageDeletedSuccessfully') });
            ctx.getWithQuery(ctx);
            ctx.cancelEditMode();
          } else {
            VueNotifications.error({ message: response.data.description });
          }
          ctx.$store.commit('toggleMutePortalPageEdit');
        },
        (err) => {
          // VueNotifications.error({ message: err });

          // show errors in the console instead of the alert window
          if (
            Object.prototype.hasOwnProperty.call(window, 'isShowAxiosErrorInConsole') &&
            typeof window.isShowAxiosErrorInConsole === 'function' &&
            window.isShowAxiosErrorInConsole(err)
          ) {
            window.showAxiosErrInConsole(err);
          } else {
            VueNotifications.error({ message: err });
          }

          ctx.error = err;
          ctx.$store.commit('toggleMutePortalPageEdit');
        }
      );
  },
  clonePage(ctx, profile) {
    ctx.$store.commit('toggleMutePortalPageEdit');
    const { base_location } = profile;
    Vue.axios
      .post(`${PORTAL_API_URL || ''}/api/portal/admin/webpage`, {
        clone_page: true,
        request: [profile],
        base_location
      })
      .then(
        (response) => {
          if (response.status === 200 && response.data.status === 'success') {
            VueNotifications.success({ message: ctx.$t('pageprofile.pageClonedSuccessfully') });
            ctx.getWithQuery(ctx);
            ctx.cancelEditMode();
          } else {
            VueNotifications.error({ message: response.data.description });
          }
          ctx.$store.commit('toggleMutePortalPageEdit');
        },
        (err) => {
          // VueNotifications.error({ message: err });

          // show errors in the console instead of the alert window
          if (
            Object.prototype.hasOwnProperty.call(window, 'isShowAxiosErrorInConsole') &&
            typeof window.isShowAxiosErrorInConsole === 'function' &&
            window.isShowAxiosErrorInConsole(err)
          ) {
            window.showAxiosErrInConsole(err);
          } else {
            VueNotifications.error({ message: err });
          }

          ctx.error = err;
          ctx.$store.commit('toggleMutePortalPageEdit');
        }
      );
  },
  getPagesLocations(ctx, pages) {
    // Запихиват в store объект, где ключи - id страниц, а значения - id локаций
    ctx.$store.commit('toggleLoadingPortalPagesLocations');
    const pagesIdsArray = [];
    for (const page of pages) {
      pagesIdsArray.push(page.id);
    }
    Vue.axios
      .post(`${PORTAL_API_URL || ''}/api/base_locations`, {
        action: 'R',
        ids: pagesIdsArray
      })
      .then(
        (response) => {
          if (response.status === 200 && response.data.status === 'success') {
            const locationFromApi = response.data.data.itemslist;
            let loctationsToStore = {};
            if (response.data.data.itemslist && response.data.data.itemslist.length > 0) {
              for (const location of locationFromApi) {
                if (location.Obj.model === 'portal_webpage') {
                  loctationsToStore[location.Obj.model_id] = location.location_id;
                }
              }
            } else {
              loctationsToStore = false;
            }
            ctx.$store.commit('setPortalPagesLocationsList', loctationsToStore);
            ctx.$store.commit('toggleLoadingPortalPagesLocations');
          } else if (response.data.status === 'error') {
            VueNotifications.error({ message: response.data.description });
            ctx.$store.commit('toggleLoadingPortalPagesLocations');
          }
        },
        (err) => {
          ctx.$store.commit('toggleLoadingPortalPagesLocations');
          // VueNotifications.error({ message: err });

          // show errors in the console instead of the alert window
          if (
            Object.prototype.hasOwnProperty.call(window, 'isShowAxiosErrorInConsole') &&
            typeof window.isShowAxiosErrorInConsole === 'function' &&
            window.isShowAxiosErrorInConsole(err)
          ) {
            window.showAxiosErrInConsole(err);
          } else {
            VueNotifications.error({ message: err });
          }

          ctx.error = err;
        }
      );
  },
  async requestPages(searchQuery) {
    const config = {
      params: {
        search: searchQuery,
        limit: 20,
        sort: 'name',
        with_childs: true,
      }
    };
    const json = await Vue.axios.get(`${PORTAL_API_URL || ''}/api/portal/admin/webpage`, config);
    if (json.data.status === 'success') {
      return json.data.data;
    }
    return [];
  },
  async requestPagesFilteredByLocationAndWithChilds(ctx, searchQuery) {
    const config = {
      params: {
        search: searchQuery,
        limit: 20,
        sort: 'name',
        location: ctx.$store.getters.getDefaultLocationForRequests(ctx),
        with_childs: ctx.$store.getters.getDefaultWithChildsForRequests(ctx)
      }
    };
    const json = await Vue.axios.get(`${PORTAL_API_URL || ''}/api/portal/admin/webpage`, config);
    if (json.data.status === 'success') {
      return json.data.data;
    }
    return [];
  }
};
