<template>
  <!-- todo refactor badge без бутстрапа -->
  <div
    class="badge badge-primary menu-badge"
    :class="[isCollapsed && 'collapsed']"
    v-tooltip.top-center="`${$t('sidebar.cpuLoad')}: ${cpuLoadPercentage}`"
  >
    <span v-if="!isCollapsed">
      {{ `${$t('sidebar.cpu')}: ${cpuLoadPercentage}` }}
    </span>
    <span v-else>
      {{ `${cpuLoadPercentage}` }}
    </span>
  </div>
</template>

<script>
export default {
  name: 'CPULoadBadge',
  props: {
    isCollapsed: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
    };
  },
  computed: {
    systemTotalObjList() {
      return this.$store.state.systemTotalObjList;
    },
    cpuLoadPercentage() {
      return `${(this.systemTotalObjList.system.load.load_core * 100).toFixed(0)}%`;
    }
  },
  watch: {
  },
  methods: {
  }
};
</script>

<style lang="css" scoped>
.menu-badge {
  margin: 0 auto;
  border-radius: 4px;

  cursor: help;
  background-color: var(--brand-info);
}
.menu-badge.collapsed {
  font-size: 70%;
  padding: 6px 4px;
}
</style>
