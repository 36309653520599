<template>
  <div class="crumb root" :class="[item.next.id ? 'root_active' : '']"
    @click="onRootCrumbClick"
  >
    {{ item.title | capitalize }}
    <i v-if="item.next.id" class="fa fa-sort-desc icon" />
  </div>
</template>

<script>

export default {
  name: 'CrumbRoot',
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  computed: {
  },
  methods: {
    onRootCrumbClick(event) {
      // eslint-disable-next-line no-param-reassign
      event.ingnoreInHandleOutsideClick = true;
      this.$emit('on-root-role-click', this.item.name);
    }
  }
};
</script>

<style lang="css" scoped>
.crumb {
  display: flex;
}

.root_active {
  cursor: pointer;
  transition: var(--transition);
}

.root_active:hover {
  opacity: var(--opacity);
}

.icon {
  color: var(--breadcrumb-accent-color);
  padding: 0 4px;
}
</style>
