import Vue from 'vue';
import VueNotifications from 'vue-notifications';
import radiusPollingService from './radiusPollingService';
import { API_URL } from '@/config';

export default {
  getRRMs(ctx) {
    ctx.$store.commit('toggleLoadingRRM');
    Vue.axios.post(`${API_URL || ''}/api/rrm_groups`, { action: 'R', location: ctx.$store.getters.getDefaultLocationForRequests(ctx), with_childs: ctx.$store.getters.getDefaultWithChildsForRequests(ctx) }).then(
      (response) => {
        if (response.data.status === 'success') {
          ctx.$store.commit('setRRMList', response.data.data.itemslist);
        } else if (response.data.status === 'error') {
          VueNotifications.error({ message: response.data.description });
        }
        ctx.$store.commit('toggleLoadingRRM');
      },
      (err) => {
        // VueNotifications.error({ message: err });

        // show errors in the console instead of the alert window
        if (
          Object.prototype.hasOwnProperty.call(window, 'isShowAxiosErrorInConsole') &&
          typeof window.isShowAxiosErrorInConsole === 'function' &&
          window.isShowAxiosErrorInConsole(err)
        ) {
          window.showAxiosErrInConsole(err);
        } else {
          VueNotifications.error({ message: err });
        }

        ctx.$store.commit('toggleLoadingRRM');
        ctx.error = err;
      }
    );
  },
  getRRMsInRRMPage(ctx) {
    ctx.$store.commit('toggleLoadingRRM');
    const payload = {
      action: 'R'

    };
    if (ctx.filters?.location) {
      if (ctx.filters.location === 'All locations') {
        // console.log('ctx.userBaseLocation', ctx.userBaseLocation);
        if (ctx.userBaseLocation ) {
          payload.location = ctx.$store.state.requestBaseUserLocationIfNoSelected ? ctx.userBaseLocation : undefined;
        }
        payload.with_childs = true;
      } else {
        payload.location = ctx.filters.location;
        payload.with_childs = ctx.filters.with_childs;
      }
    }
    Vue.axios.post(`${API_URL || ''}/api/rrm_groups`, payload).then(
      (response) => {
        if (response.data.status === 'success') {
          ctx.$store.commit('setRRMList', response.data.data.itemslist);
        } else if (response.data.status === 'error') {
          VueNotifications.error({ message: response.data.description });
        }
        ctx.$store.commit('toggleLoadingRRM');
      },
      (err) => {
        // VueNotifications.error({ message: err });

        // show errors in the console instead of the alert window
        if (
          Object.prototype.hasOwnProperty.call(window, 'isShowAxiosErrorInConsole') &&
          typeof window.isShowAxiosErrorInConsole === 'function' &&
          window.isShowAxiosErrorInConsole(err)
        ) {
          window.showAxiosErrInConsole(err);
        } else {
          VueNotifications.error({ message: err });
        }

        ctx.$store.commit('toggleLoadingRRM');
        ctx.error = err;
      }
    );
  },

  createRRM(ctx) {
    ctx.$store.commit('toggleMuteRRMEdit');

    const updateRRMQuery = { action: 'C', items: { 0: ctx.updatedGroup } };
    Vue.axios.post(`${API_URL || ''}/api/rrm_groups`, updateRRMQuery).then(
      (response) => {
        if (response.data.status === 'success') {
          ctx.cancelEditMode();
          this.getRRMs(ctx);
          ctx.refreshCpes();
          ctx.selectedCpesId = [];
          ctx.$store.commit('toggleMuteRRMEdit');
          VueNotifications.success({ message: ctx.$t('RRM.notificationRRMGroupCreated') });
        } else if (response.data.status === 'error') {
          ctx.$store.commit('toggleMuteRRMEdit', true);
          VueNotifications.error({ message: response.data.description });
        }
      },
      (err) => {
        // VueNotifications.error({ message: err });

        // show errors in the console instead of the alert window
        if (
          Object.prototype.hasOwnProperty.call(window, 'isShowAxiosErrorInConsole') &&
          typeof window.isShowAxiosErrorInConsole === 'function' &&
          window.isShowAxiosErrorInConsole(err)
        ) {
          window.showAxiosErrInConsole(err);
        } else {
          VueNotifications.error({ message: err });
        }

        ctx.error = err;
        ctx.$store.commit('toggleMuteRRMEdit');
      }
    );
  },

  updateRRM(ctx) {
    ctx.$store.commit('toggleMuteRRMEdit');

    const updateRRMQuery = { action: 'U', items: { [ctx.updatedGroup.id]: ctx.updatedGroup } };
    Vue.axios.post(`${API_URL || ''}/api/rrm_groups`, updateRRMQuery).then(
      (response) => {
        if (response.data.status === 'success') {
          ctx.cancelEditMode();
          this.getRRMs(ctx);
          ctx.refreshCpes();
          ctx.selectedCpesId = [];
          ctx.$store.commit('toggleMuteRRMEdit');
          VueNotifications.success({ message: ctx.$t('RRM.notificationRRMGroupUpdated') });
        } else if (response.data.status === 'error') {
          ctx.$store.commit('toggleMuteRRMEdit', true);
          VueNotifications.error({ message: response.data.description });
        }
      },
      (err) => {
        // VueNotifications.error({ message: err });

        // show errors in the console instead of the alert window
        if (
          Object.prototype.hasOwnProperty.call(window, 'isShowAxiosErrorInConsole') &&
          typeof window.isShowAxiosErrorInConsole === 'function' &&
          window.isShowAxiosErrorInConsole(err)
        ) {
          window.showAxiosErrInConsole(err);
        } else {
          VueNotifications.error({ message: err });
        }

        ctx.error = err;
        ctx.$store.commit('toggleMuteRRMEdit');
      }
    );
  },

  // forceRRMGroup(ctx, rrmGroupObject) {
  //   if (!rrmGroupObject) {
  //     return;
  //   }
  //   ctx.$store.commit('forceRRMGroupRequestBegin');
  //   const rrmObjectForForce = JSON.parse(JSON.stringify(rrmGroupObject));
  //   rrmObjectForForce.force = true;
  //   const forceRRMGroupQuery = { action: 'U', items: { [rrmObjectForForce.id]: rrmObjectForForce } };
  //   Vue.axios.post(`${API_URL || ''}/api/rrm_groups_force`, forceRRMGroupQuery).then(
  //     (response) => {
  //       // console.log(response)
  //       if (response.data.status === 'success') {
  //
  //         VueNotifications.success({ message: ctx.$t('RRM.notificationRRMGroupForceCall') });
  //       } else if (response.data.status === 'error') {
  //
  //         VueNotifications.error({ message: response.data.description });
  //       } else {
  //         VueNotifications.success({ message: ctx.$t('RRM.notificationRRMGroupForceCall') });
  //       }
  //       ctx.$store.commit('forceRRMGroupRequestEnd');
  //     },
  //     (err) => {
  //       ctx.$store.commit('forceRRMGroupRequestEnd');
  //       // VueNotifications.error({ message: err });
  //
  //       // show errors in the console instead of the alert window
  //       if (
  //         Object.prototype.hasOwnProperty.call(window, 'isShowAxiosErrorInConsole') &&
  //         typeof window.isShowAxiosErrorInConsole === 'function' &&
  //         window.isShowAxiosErrorInConsole(err)
  //       ) {
  //         window.showAxiosErrInConsole(err);
  //       } else {
  //         VueNotifications.error({ message: err });
  //       }
  //
  //       ctx.error = err;
  //     }
  //   );
  // },

  async forceRRMGroup(ctx, rrmGroupObject, successCallback) {
    if (!rrmGroupObject) {
      return;
    }
    ctx.$store.commit('forceRRMGroupRequestBegin');
    const rrmObjectForForce = JSON.parse(JSON.stringify(rrmGroupObject));
    rrmObjectForForce.force = true;
    const forceRRMGroupQuery = {action: 'U', items: {[rrmObjectForForce.id]: rrmObjectForForce}};
    try {
      const result = await Vue.axios.post(`${API_URL || ''}/api/rrm_groups_force`, forceRRMGroupQuery)
      // console.log(response)
      if (result.data.status === 'success') {
        VueNotifications.success({message: ctx.$t('RRM.notificationRRMGroupForceCall')});
        if (successCallback && typeof successCallback === 'function') {
          successCallback();
        }
      } else if (result.data.status === 'error') {

        VueNotifications.error({message: result.data.description});
      } else {
        VueNotifications.success({message: ctx.$t('RRM.notificationRRMGroupForceCall')});
        if (successCallback && typeof successCallback === 'function') {
          successCallback();
        }
      }
      ctx.$store.commit('forceRRMGroupRequestEnd');

    } catch (err) {
      ctx.$store.commit('forceRRMGroupRequestEnd');
      // VueNotifications.error({ message: err });

      // show errors in the console instead of the alert window
      if (
        Object.prototype.hasOwnProperty.call(window, 'isShowAxiosErrorInConsole') &&
        typeof window.isShowAxiosErrorInConsole === 'function' &&
        window.isShowAxiosErrorInConsole(err)
      ) {
        window.showAxiosErrInConsole(err);
      } else {
        VueNotifications.error({message: err});
      }

      ctx.error = err;
    }
  },

  updateRRMs(ctx, array) {
    ctx.$store.commit('toggleMuteRRMEdit');

    const updateRRMQuery = { action: 'U', items: array };
    Vue.axios.post(`${API_URL || ''}/api/rrm_groups`, updateRRMQuery).then(
      (response) => {
        if (response.data.status === 'success') {
          ctx.cancelEditMode();
          this.getRRMs(ctx);
          ctx.refreshCpes();
          ctx.selectedCpesId = [];
          ctx.$store.commit('toggleMuteRRMEdit');
          VueNotifications.success({ message: ctx.$t('RRM.notificationRRMGroupUpdated') });
        } else if (response.data.status === 'error') {
          ctx.$store.commit('toggleMuteRRMEdit', true);
          VueNotifications.error({ message: response.data.description });
        }
      },
      (err) => {
        // VueNotifications.error({ message: err });

        // show errors in the console instead of the alert window
        if (
          Object.prototype.hasOwnProperty.call(window, 'isShowAxiosErrorInConsole') &&
          typeof window.isShowAxiosErrorInConsole === 'function' &&
          window.isShowAxiosErrorInConsole(err)
        ) {
          window.showAxiosErrInConsole(err);
        } else {
          VueNotifications.error({ message: err });
        }

        ctx.error = err;
        ctx.$store.commit('toggleMuteRRMEdit');
      }
    );
  },

  deleteRRM(ctx) {
    ctx.$store.commit('toggleMuteRRMEdit');

    const updateRRMQuery = { action: 'D', items: { [ctx.updatedGroup.id]: ctx.updatedGroup } };
    Vue.axios.post(`${API_URL || ''}/api/rrm_groups`, updateRRMQuery).then(
      (response) => {
        if (response.data.status === 'success') {
          ctx.cancelEditMode();
          this.getRRMs(ctx);
          ctx.refreshCpes();
          ctx.selectedCpesId = [];
          ctx.$store.commit('toggleMuteRRMEdit');
          VueNotifications.success({ message: ctx.$t('RRM.notificationRRMGroupDeleted') });
        } else if (response.data.status === 'error') {
          ctx.$store.commit('toggleMuteRRMEdit');
          VueNotifications.error({ message: response.data.description });
        }
      },
      (err) => {
        // VueNotifications.error({ message: err });

        // show errors in the console instead of the alert window
        if (
          Object.prototype.hasOwnProperty.call(window, 'isShowAxiosErrorInConsole') &&
          typeof window.isShowAxiosErrorInConsole === 'function' &&
          window.isShowAxiosErrorInConsole(err)
        ) {
          window.showAxiosErrInConsole(err);
        } else {
          VueNotifications.error({ message: err });
        }

        ctx.error = err;
        ctx.$store.commit('toggleMuteRRMEdit');
      }
    );
  }
};
