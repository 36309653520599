<template>
  <div class="apjoin-tab">
    <div class="apjoin-tab-content">
      <div v-for="(content, index) in tabContent"
        class="apjoin-tab-input-group"
        :key="index"
      >
        <h3 v-if="content.label"
          class="apjoin-tab-input-group-heading"
        >
          {{ content.label }}
        </h3>
        <div v-for="input in content.inputs"
          class="apjoin-tab-input"
          :key="input.id"
        >
          <template v-if="input.inputType === 'checkbox'">
            <WCheckbox
              :label="input.label"
              :name="input.name"
              :id="input.name"
              :checked="formState[input.name]"
              @change="handleCheckbox(input.name, $event)"
              :disabled="input.isDisabled"
              v-validate="input.validateRules"
              data-vv-validate-on="change"
              :data-vv-as="input.label"
              :errorText="errors.first(input.name)"
            />
          </template>
          <template v-else-if="input.inputType === 'select'">
            <WSelect
              :options="input.selectOptions"
              :label="input.label"
              :name="input.name"
              :id="input.name"
              :value="formState[input.name]"
              @input="handleSelect(input.name, $event)"
              :disabled="input.isDisabled"
              v-validate="input.validateRules"
              data-vv-validate-on="input"
              :data-vv-as="input.label"
              :errorText="errors.first(input.name)"
            />
          </template>
          <!-- обычные инпуты -->
          <template v-else>
            <!-- логика зависимых инпутов -->
            <template v-if="input.name === 'general.offsetHH' || input.name === 'general.offsetMM'">
              <WInput v-if="formState['general.timezone'] === 'delta'"
                data-vv-scope="timezone"
                :key="input.id"
                :type="input.inputType"
                :label="input.label"
                :value="formState[input.name]"
                @input="handleInput(input, $event)"
                :id="input.name"
                :name="input.name"
                :placeholder="input.placeholder"
                :disabled="input.isDisabled"
                v-validate="input.validateRules"
                data-vv-validate-on="input"
                :data-vv-as="input.label"
                :errorText="errors.first(input.name)"
                autocomplete="one-time-code"
              />
            </template>
            <!-- логика зависимых инпутов -->
            <!-- дизейблим если включен mss-->
            <template v-else-if="input.name === 'general.mssSizeOfWanInterface'">
              <WInput
                :key="input.id"
                :type="input.inputType"
                :label="input.label"
                :value="formState[input.name]"
                @input="handleInput(input, $event)"
                :id="input.name"
                :name="input.name"
                :placeholder="input.placeholder"
                :readonly="!formState['general.mssState']"
                v-validate="input.validateRules"
                data-vv-validate-on="input"
                :data-vv-as="input.label"
                :errorText="errors.first(input.name)"
                autocomplete="one-time-code"
              />
            </template>
            <!-- логика зависимых инпутов -->
            <template v-else-if="input.name === 'management.sshPort' || input.name === 'management.username' || input.name === 'management.password' || input.name === 'management.secretPassword'">
              <WInput v-if="formState['management.sshSupport']"
                data-vv-scope="sshSupport"
                :key="input.id"
                :type="input.inputType"
                :label="input.label"
                :value="formState[input.name]"
                @input="handleInput(input, $event)"
                :id="input.name" :name="input.name"
                :placeholder="input.placeholder"
                :disabled="input.isDisabled"
                v-validate="input.validateRules"
                data-vv-validate-on="input"
                :data-vv-as="input.label"
                :errorText="errors.first(input.name)"
                autocomplete="one-time-code"
              />
            </template>
            <!-- логика зависимых инпутов -->
            <template v-else-if="input.name === 'statistics.apStatiscticReportPeriod'">
              <WInput v-if="formState['statistics.sendApStatistic']"
                data-vv-scope="statistics"
                :key="input.id"
                :type="input.inputType"
                :label="input.label"
                :value="formState[input.name]"
                @input="handleInput(input, $event)"
                :id="input.name"
                :name="input.name"
                :placeholder="input.placeholder"
                :disabled="input.isDisabled"
                v-validate="input.validateRules"
                data-vv-validate-on="input"
                :data-vv-as="input.label"
                :errorText="errors.first(input.name)"
                autocomplete="one-time-code"
              />
            </template>
            <!-- логика простых инпутов -->
            <template v-else>
              <WInput
                :key="input.id"
                :type="input.inputType"
                :label="input.label"
                :value="formState[input.name]"
                @input="handleInput(input, $event)"
                :id="input.name"
                :name="input.name"
                :placeholder="input.placeholder"
                :disabled="input.isDisabled"
                v-validate="input.validateRules"
                data-vv-validate-on="input"
                :data-vv-as="input.label"
                :errorText="errors.first(input.name)"
                autocomplete="one-time-code"
              />
            </template>
          </template>
        </div>
      </div>
    </div>
    <!-- информационный (правый блок таба) -->
    <div v-if="currentTabName === 'management'"
      class="apjoin-tab-description"
    >
      <div class="apjoin-tab-description__content">
        <p class="warning-caption alert alert-warning">
          {{ $t('apjoin.tabText.warningCaption') }}
        </p>
        <!-- <ul class="rules-list">
          <li v-for="(rule, index) in managementTabPasswordRules"
            :key="index"
          >
            {{ rule }}
          </li>
        </ul> -->
      </div>
    </div>
  </div>
</template>

<script>
/**
 *
 * компонент таба APProfileForm.
 * Содержит разметку, правила рендеринга (в т.ч. рекурсивные), специфических настроек отображения
 * и параметров валидации отдельных инпутов в соответствии с конфигом
 * @component
 *
 */

export default {
  name: 'APProfileFormTabContent',
  inject: ['$validator'],
  components: {
  },
  props: {
    tabContent: {
      type: Array,
      required: true
    },
    formState: {
      type: Object,
      required: true
    },
    currentTabName: {
      type: String,
      required: true
    }
  },
  data() {
    return {
    };
  },
  computed: {
    managementTabPasswordRules() {
      return [
        this.$t('apjoin.tabText.passwordRulesDescription.1'),
        this.$t('apjoin.tabText.passwordRulesDescription.2'),
        this.$t('apjoin.tabText.passwordRulesDescription.3'),
        this.$t('apjoin.tabText.passwordRulesDescription.4'),
        this.$t('apjoin.tabText.passwordRulesDescription.5')
      ];
    }
  },
  methods: {
    handleInput(inputObj, value) {
      const { name, inputType } = inputObj;

      let correctFormatValue = value;

      // из-за особенностей архитектуры и невозможности использовать здесь v-modal, форматировать number приходится так
      if (inputType === 'number') {
        correctFormatValue = value === '' ? '' : Number(correctFormatValue);
      }

      this.$emit('update:inputValue', {
        inputName: name,
        value: correctFormatValue
      });
    },
    handleCheckbox(inputName, event) {
      if (inputName === 'management.sshSupport') {
        this.handleSshSupportCheckbox(inputName, event);

        return;
      }

      if (inputName === 'statistics.sendApStatistic') {
        this.handleSendApStatisticCheckbox(inputName, event);

        return;
      }

      // else default handler
      this.handleDefaultCheckbox(inputName, event);
    },
    handleDefaultCheckbox(inputName, event) {
      const isChecked = event.target.checked;
      this.$emit('update:inputValue', { inputName, value: isChecked });
    },
    handleSshSupportCheckbox(inputName, event) {
      const isChecked = event.target.checked;

      // в зависимых от значения чекбокса значениях нужно вручную обнулять значения из-за структуры кода и vee-validate v2
      if (!isChecked) {
        this.$emit('update:inputValue', {
          inputName: 'management.sshPort',
          value: 22
        });

        this.$emit('update:inputValue', {
          inputName: 'management.username',
          value: ''
        });

        this.$emit('update:inputValue', {
          inputName: 'management.password',
          value: ''
        });

        this.$emit('update:inputValue', {
          inputName: 'management.secretPassword',
          value: ''
        });

        // сброс состояния валидации с удаленных полей и ошибок в частности
        // https://vee-validate.logaretm.com/v2/examples/scopes.html
        // https://vee-validate.logaretm.com/v2/api/validator.html#methods
        const matcher = {
          scope: 'sshSupport',
          vmId: this.$validator.id
        };

        this.$validator.reset(matcher);
      }
      this.$emit('update:inputValue', { inputName, value: isChecked });
    },
    handleSendApStatisticCheckbox(inputName, event) {
      const isChecked = event.target.checked;

      // в зависимых от значения чекбокса значениях нужно вручную обнулять значения из-за структуры кода и vee-validate v2
      if (!isChecked) {
        this.$emit('update:inputValue', {
          inputName: 'statistics.apStatiscticReportPeriod',
          value: 60
        });

        // сброс состояния валидации с удаленных полей и ошибок в частности
        // https://vee-validate.logaretm.com/v2/examples/scopes.html
        // https://vee-validate.logaretm.com/v2/api/validator.html#methods
        const matcher = {
          scope: 'statistics',
          vmId: this.$validator.id
        };

        this.$validator.reset(matcher);
      }
      this.$emit('update:inputValue', { inputName, value: isChecked });
    },
    handleSelect(inputName, value) {
      if (inputName === 'general.timezone') {
        this.handleTimezoneSelect(inputName, value);

        return;
      }

      // else default handler
      this.$emit('update:inputValue', { inputName, value });
    },
    // спец обработчик селекта для general.timezone
    handleTimezoneSelect(inputName, value) {
      const condition = value !== 'delta';
      // в зависимых от значения general.timezone инпутах нужно вручную обнулять значения из-за структуры кода и vee-validate
      if (condition) {
        this.$emit('update:inputValue', {
          inputName: 'general.offsetHH',
          value: 0
        });

        this.$emit('update:inputValue', {
          inputName: 'general.offsetMM',
          value: 0
        });

        // сброс состояния валидации с удаленных полей и ошибок в частности
        // https://vee-validate.logaretm.com/v2/examples/scopes.html
        // https://vee-validate.logaretm.com/v2/api/validator.html#methods
        const matcher = {
          scope: 'timezone',
          vmId: this.$validator.id
        };

        this.$validator.reset(matcher);
      }

      this.$emit('update:inputValue', { inputName, value });
    }
  }
};
</script>

<style lang="css" scoped>
.apjoin-tab {
  height: 100%;
  max-height: 500px;
  display: flex;
  gap: 24px;
  overflow-y: auto;
  overflow-x: hidden;
  padding-right: 8px;
}

/* стили для скроллбара и трека */
.apjoin-tab::-webkit-scrollbar {
  width: 5px;
}

.apjoin-tab::-webkit-scrollbar-track:vertical {
  border-radius: 8px;
  background-color: var(--gray-lighter);
}

.apjoin-tab::-webkit-scrollbar-thumb {
  width: 5px;
  border-radius: 3px;
  background: var(--brand-info);
}

.apjoin-tab-content {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  gap: 16px;
  /* flex-wrap: wrap; */
}

.apjoin-tab-input-group {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.apjoin-tab-description {
  flex: 0 0 40%;
  display: flex;
}

.apjoin-tab-description__content {
  display: flex;
  flex-direction: column;
  gap: 32px;
}

.apjoin-tab-input-group-heading {
  margin: 0;
  font-size: 16px;
}

p {
  margin: 0;
}

.warning-caption {
  font-weight: 700;
}

.rules-list {
  display: flex;
  flex-direction: column;
  gap: 8px;
  list-style-type: decimal;
  padding-left: 16px;
  margin: 0;
}
</style>
