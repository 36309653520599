import { API_URL, GAPI_URL } from '@/config';
import { AXIOS_INSTANCE } from '@/api';

const GET_BRANCH_PROFILES_URL = `${GAPI_URL}/api/v1/profiles/branch`;
const POST_CREATE_BRANCH_PROFILE_URL = `${GAPI_URL}/api/v1/profiles/branch`;
const POST_UPDATE_BRANCH_PROFILE_URL = `${GAPI_URL}/api/v1/profiles/branch`;
const POST_DELETE_BRANCH_PROFILE_URL = `${GAPI_URL}/api/v1/profiles/branch/bulk/deletion`;
const GET_BRANCH_PROFILE_BY_NAME_URL = `${GAPI_URL}/api/v1/profiles/branch/find-by-name/`;
const GET_BRANCH_VLANS_URL = `${GAPI_URL}/api/v1/profiles/branch/vlans`;
const GET_RADUIS_GROUPS_URL = `${API_URL}/api/radius`;
const GET_REDIRECT_RULES_URL = `${API_URL}/api/network/redirects`;
const GET_ACL_VALUES_URL = `${API_URL}/api/network/l2chains`;

export {
  AXIOS_INSTANCE,
  GET_BRANCH_PROFILES_URL,
  POST_CREATE_BRANCH_PROFILE_URL,
  POST_UPDATE_BRANCH_PROFILE_URL,
  POST_DELETE_BRANCH_PROFILE_URL,
  GET_BRANCH_PROFILE_BY_NAME_URL,
  GET_BRANCH_VLANS_URL,
  GET_RADUIS_GROUPS_URL,
  GET_REDIRECT_RULES_URL,
  GET_ACL_VALUES_URL
};
