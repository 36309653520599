<template>
  <WButton success squared sm
    customClass="rrm-groups-button"
    v-tooltip.top-center="{ content: `${$t('RRM.rrmForceCallButtonInModal')}` }"
    @click.stop="handleClickActionButton"
  >
    <i class="fa fa-play-circle"></i>
  </WButton>
</template>

<script>
/**
 * компонент - ячейка action.
 * компонент локальный: используется только в RRMGroupsTable.vue
 * @component
 */

export default {
  name: 'ActionCell',
  components: {},
  props: {
    props: {
      type: Object,
      required: true
    }
  },
  methods: {
    handleClickActionButton() {
      this.$emit('on-action-button-click');
    }
  }
};
</script>

<style lang="css" scoped>
</style>
