import {
  executeRouterGuards,
  isNotOperatorRoleGuard
} from './navigationGuards';

const Radius = () => import(/* webpackChunkName: "Radius" */ '../views/Radius/RadiusV2');
const RadiusWizard = () => import(/* webpackChunkName: "RadiusWizard" */ '../views/Radius/RadiusWizard');
const RadiusStep1 = () => import(/* webpackChunkName: "RadiusWizard" */ '../views/Radius/RadiusStep1');
const RadiusStep2 = () => import(/* webpackChunkName: "RadiusWizard" */ '../views/Radius/RadiusStep2V2');
const RadiusStep3 = () => import(/* webpackChunkName: "RadiusWizard" */ '../views/Radius/RadiusStep3V2');

const RadiusRoute = {
  path: 'radius',
  name: 'Radius',
  component: Radius,
  children: [
    {
      path: 'radius-wizard',
      name: 'radius-wizard',
      redirect: 'radius-wizard/radius-step-1',
      component: RadiusWizard,
      beforeEnter: executeRouterGuards(isNotOperatorRoleGuard, { name: 'Radius' }),
      children: [
        {
          path: 'radius-step-1',
          name: 'radius-step-1',
          component: RadiusStep1
        },
        {
          path: 'radius-step-2',
          name: 'radius-step-2',
          component: RadiusStep2
        },
        {
          path: 'radius-step-3',
          name: 'radius-step-3',
          component: RadiusStep3
        }
      ]
    }
  ]
};

export default RadiusRoute;
