function getDefaultTableSettings(i18nInstance) {
  return {
    SNMPMonitoringConfig: {
      name: {
        name: i18nInstance.t('aps.tableTab_name'),
        value: true,
        disabled: false
      },
      // model: {
      //   name: i18nInstance.t('aps.tableTab_model'),
      //   value: true,
      //   disabled: true
      // },
      description: {
        name: i18nInstance.t('aps.description'),
        value: true,
        disabled: true
      },
      status: {
        name: i18nInstance.t('aps.tableTab_status'),
        value: true,
        disabled: false
      },
      server: {
        name: i18nInstance.t('SNMPMonitoringConfig.tableColumnTitleServer'),
        value: true,
        disabled: true
      },
      snmpStatus: {
        name: i18nInstance.t('SNMPMonitoringConfig.tableColumnTitleSNMPStatus'),
        value: true,
        disabled: false
      },
      ip: {
        name: i18nInstance.t('SNMPMonitoringConfig.tableColumnTitleIP'),
        value: true,
        disabled: true
      }
    // two: {
    //   name: i18nInstance.t('aps.tableTab_two'),
    //   value: true,
    //   disabled: true
    // },
    // five: {
    //   name: i18nInstance.t('aps.tableTab_five'),
    //   value: true,
    //   disabled: true
    // },
    // wired: {
    //   name: i18nInstance.t('aps.tableTab_wired'),
    //   value: true,
    //   disabled: true
    // },
    // radar: {
    //   name: i18nInstance.t('aps.tableTab_radar'),
    //   value: true,
    //   disabled: true
    // },
    // location: {
    //   name: i18nInstance.t('aps.tableTab_location'),
    //   value: true,
    //   disabled: true
    // },
    // tags: {
    //   name: i18nInstance.t('aps.tableTab_tags'),
    //   value: true,
    //   disabled: true
    // },
    // actions: {
    //   name: '',
    //   hiddenName: 'Actions',
    //   value: true,
    //   disabled: true
    // }
    },
    cpe: {
      name: {
        name: i18nInstance.t('aps.tableTab_name'),
        value: true,
        disabled: false
      },
      model: {
        name: i18nInstance.t('aps.tableTab_model'),
        value: true,
        disabled: true
      },
      status: {
        name: i18nInstance.t('aps.tableTab_status'),
        value: true,
        disabled: false
      },

      ip: {
        name: 'IP',
        value: true,
        disabled: true
      },

      two: {
        name: i18nInstance.t('aps.tableTab_two'),
        value: true,
        disabled: true
      },
      five: {
        name: i18nInstance.t('aps.tableTab_five'),
        value: true,
        disabled: true
      },
      wired: {
      // нужна только для beeline сборки
        name: i18nInstance.t('aps.tableTab_wired'),
        value: true,
        disabled: true
      },
      radar: {
      // нужна только для сборок с analytics
        name: i18nInstance.t('aps.tableTab_radar'),
        value: true,
        disabled: true
      },
      location: {
        name: i18nInstance.t('aps.tableTab_location'),
        value: true,
        disabled: true
      },
      locationDescription: {
        name: i18nInstance.t('location.locationDescription'),
        value: true,
        disabled: true
      },
      // tags: {
      //   name: i18nInstance.t('aps.tableTab_tags'),
      //   value: true,
      //   disabled: true
      // },
      tags: {
        name: i18nInstance.t('aps.tableTab_tags'),
        value: false,
        disabled: true
      },
      firstConnection: {
        name: i18nInstance.t('aps.tableTab_firstConnection'),
        value: true,
        disabled: true
      },
      lastConnection: {
        name: i18nInstance.t('aps.tableTab_lastConnection'),
        value: true,
        disabled: true
      },
      lastDisconnection: {
        name: i18nInstance.t('aps.tableTab_lastDisconnection'),
        value: true,
        disabled: true
      },
      actions: {
        name: '',
        hiddenName: 'Actions',
        value: true,
        disabled: true
      }
    },
    wlan: {
      ssid: {
        name: i18nInstance.t('wlans.tableTab_ssid'),
        value: true,
        disabled: false
      },
      // status: {
      //     name: i18nInstance.t('wlans.tableTab_status'),
      //     value: true,
      //     disabled: false
      // },
      security: {
        name: i18nInstance.t('wlans.tableTab_security'),
        value: true,
        disabled: false
      },
      cpes: {
        name: i18nInstance.t('wlans.tableTab_cpes'),
        value: true,
        disabled: true
      },
      clients: {
        name: i18nInstance.t('wlans.tableTab_clients'),
        value: true,
        disabled: true
      },
      visibility: {
        name: i18nInstance.t('wlans.tableTab_visibility'),
        value: true,
        disabled: true
      },
      vlan: {
      // name: i18nInstance.t('wlans.tableTab_vlan'),
      // name: i18nInstance.t('wlans.tableTab_encapsulation'),
        name: i18nInstance.t('wlans.tableTab_dataTrafficManagement'),
        value: true,
        disabled: true
      },

      // encapsulation: {
      //   name: i18nInstance.t('wlans.tableTab_encapsulation'),
      //   value: true,
      //   disabled: true
      // },

      filter: {
        name: i18nInstance.t('wlans.tableTab_filter'),
        value: true,
        disabled: true
      },
      location: {
        name: i18nInstance.t('wlans.tableTab_location'),
        value: true,
        disabled: true
      },
      tags: {
        name: i18nInstance.t('wlans.tableTab_tags'),
        value: true,
        disabled: true
      },
      hasPortal: {
        name: i18nInstance.t('wlans.tableTab_hasPortal'),
        value: true,
        disabled: true
      },
      actions: {
        name: '',
        hiddenName: 'Actions',
        value: true,
        disabled: true
      }
    },
    wlanV2: {
      status: {
        name: i18nInstance.t('wlans.tableTab_status'),
        value: true,
        disabled: false
      },
      name: {
        name: i18nInstance.t('wlans.tableTab_name'),
        value: true,
        disabled: true
      },
      id: {
        name: 'ID',
        value: true,
        disabled: true
      },
      ssid: {
        name: i18nInstance.t('wlans.tableTab_ssid'),
        value: true,
        disabled: false
      },
      security: {
        name: i18nInstance.t('wlans.tableTab_security'),
        value: true,
        disabled: true
      },
      actions: {
        name: '',
        hiddenName: 'Actions',
        value: true,
        disabled: true
      }
    },
    client: {
    // type: {
    //     name: i18nInstance.t('clients.tableTab_type'),
    //     value: true,
    //     disabled: false
    // },
      mac: {
        name: i18nInstance.t('clients.tableTab_mac'),
        value: true,
        disabled: false
      },
      ip: {
        name: 'IP',
        value: true,
        disabled: true
      },
      // authorization: {
      //   name: i18nInstance.t('clients.auth'),
      //   value: true,
      //   disabled: true
      // },
      // description: {
      //   name: i18nInstance.t('clients.tableTab_description'),
      //   value: true,
      //   disabled: true
      // },
      cpe: {
        name: i18nInstance.t('clients.tableTab_cpe'),
        value: true,
        disabled: false
      },
      wlan: {
        name: i18nInstance.t('clients.tableTab_wlan'),
        value: true,
        disabled: false
      },
      // healthScore: {
      //   name: i18nInstance.t('clients.tableTab_healthScore'),
      //   value: true,
      //   disabled: false
      // },
      events: {
        name: i18nInstance.t('clients.eventsTableCaption'),
        value: true,
        disabled: true
      }

    // cpe: {
    //     name: i18nInstance.t('clients.tableTab_cpe'),
    //     value: true,
    //     disabled: true
    // },
    // frequency: {
    //     name: i18nInstance.t('clients.tableTab_frequency'),
    //     value: true,
    //     disabled: true
    // },
    // channel: {
    //     name: i18nInstance.t('clients.tableTab_channel'),
    //     value: true,
    //     disabled: true
    // },
    // rssi: {
    //     name: i18nInstance.t('clients.tableTab_rssi'),
    //     value: true,
    //     disabled: true
    // },
    // wlan: {
    //     name: i18nInstance.t('clients.tableTab_wlan'),
    //     value: true,
    //     disabled: true
    // },
    },
    clientRF: {
      mac: {
        name: i18nInstance.t('clients.tableTab_mac'),
        value: true,
        disabled: false
      },
      ip: {
        name: 'IP',
        value: true,
        disabled: true
      },
      authorization: {
        name: i18nInstance.t('clientsRF.authTable'),
        value: true,
        disabled: true
      },
      description: {
        name: i18nInstance.t('clients.tableTab_description'),
        value: true,
        disabled: true
      },
      cpe: {
        name: i18nInstance.t('clients.tableTab_cpe'),
        value: true,
        disabled: true
      },
      wlan: {
        name: i18nInstance.t('clients.tableTab_wlan'),
        value: true,
        disabled: true
      }
    },
    clientRFP: {
      mac: {
        name: i18nInstance.t('clients.tableTab_mac'),
        value: true,
        disabled: false
      },
      snrAvg: {
        name: i18nInstance.t('clientsRF.snrAvg'),
        value: true,
        disabled: true
      },
      snrP20: {
        name: 'SNR P20',
        value: true,
        disabled: true
      },
      snrP80: {
        name: 'SNR P80',
        value: true,
        disabled: true
      },
      signalP10: {
        name: i18nInstance.t('clientsRF.signalP10'),
        value: true,
        disabled: true
      },
      signalP90: {
        name: i18nInstance.t('clientsRF.signalP90'),
        value: true,
        disabled: true
      }
    },
    clientDistance: {
      name: {
        name: i18nInstance.t('aps.tableTab_name'),
        value: true,
        disabled: false
      },
      model: {
        name: i18nInstance.t('aps.tableTab_model'),
        value: true,
        disabled: true
      },
      status: {
        name: i18nInstance.t('aps.tableTab_status'),
        value: true,
        disabled: true
      },
      two: {
        name: i18nInstance.t('aps.tableTab_two'),
        value: true,
        disabled: true
      },
      five: {
        name: i18nInstance.t('aps.tableTab_five'),
        value: true,
        disabled: true
      },
      wired: {
        name: i18nInstance.t('aps.tableTab_wired'),
        value: true,
        disabled: true
      },
      radar: {
        name: i18nInstance.t('aps.tableTab_radar'),
        value: true,
        disabled: true
      },
      location: {
        name: i18nInstance.t('aps.tableTab_location'),
        value: true,
        disabled: true
      },
      tags: {
        name: i18nInstance.t('aps.tableTab_tags'),
        value: true,
        disabled: true
      },
      actions: {
        name: '',
        hiddenName: 'Actions',
        value: true,
        disabled: true
      }
    },
    clientsInMapsForSelectedCpe: {
    // type: {
    //     name: i18nInstance.t('clients.tableTab_type'),
    //     value: true,
    //     disabled: false
    // },
      mac: {
        name: i18nInstance.t('clients.tableTab_mac'),
        value: true,
        disabled: false
      },
      ip: {
        name: 'IP',
        value: true,
        disabled: true
      },
      authorization: {
        name: i18nInstance.t('clients.auth'),
        value: false,
        disabled: true
      },
      description: {
        name: i18nInstance.t('clients.tableTab_description'),
        value: true,
        disabled: true
      },
      cpe: {
        name: i18nInstance.t('clients.tableTab_cpe'),
        value: false,
        disabled: false
      },
      wlan: {
        name: i18nInstance.t('clients.tableTab_wlan'),
        value: true,
        disabled: false
      },
      healthScore: {
        name: i18nInstance.t('clients.tableTab_healthScore'),
        value: true,
        disabled: false
      }

    // cpe: {
    //     name: i18nInstance.t('clients.tableTab_cpe'),
    //     value: true,
    //     disabled: true
    // },
    // frequency: {
    //     name: i18nInstance.t('clients.tableTab_frequency'),
    //     value: true,
    //     disabled: true
    // },
    // channel: {
    //     name: i18nInstance.t('clients.tableTab_channel'),
    //     value: true,
    //     disabled: true
    // },
    // rssi: {
    //     name: i18nInstance.t('clients.tableTab_rssi'),
    //     value: true,
    //     disabled: true
    // },
    // wlan: {
    //     name: i18nInstance.t('clients.tableTab_wlan'),
    //     value: true,
    //     disabled: true
    // },
    }
  };
}

export {
  getDefaultTableSettings
};
