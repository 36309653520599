<template>
  <div v-show="showAlert" class="alert fade show alert-grid" role="alert">
    <slot name="text">
      {{ text }}
    </slot>
    <button v-if="closable" type="button" class="close" data-dismiss="alert" aria-label="Close" @click="handleClose">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
</template>

<script>
export default {
  name: 'Alert',
  props: {
    closable: {
      type: Boolean,
      default: false
    },
    text: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      showAlert: true
    };
  },
  methods: {
    handleClose() {
      this.showAlert = false;
      this.$emit('alert-closed');
    }
  }
};
</script>

<style lang="scss" scoped>
.alert-grid {
  display: grid;
  grid-template-columns: auto min-content;
}
</style>
