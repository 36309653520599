import { BRANDING, BRANDS, THEME_STATES } from '@/config';
import { ThemeMaster } from '@/utils';

/**
 * Базовая тема по умолчанию.
 */
const DEFAULT_THEME = THEME_STATES.light;

/**
 * Получает значение темы по умолчанию из кеша.
 * Если кешировнной темы нет, возвращает тему по умолчанию.
 * @returns {string} - Значение темы по умолчанию.
 */
function getDefaultTheme() {
  const cachedTheme = ThemeMaster.getCachedTheme();

  if (!cachedTheme) {
    const defaultTheme = DEFAULT_THEME;
    return defaultTheme;
  }

  return cachedTheme;
}

/**
 * Устанавливает класс в корневой элемент документа.
 * @param {string} className - Имя класса для установки.
 */
function setClassToTheDocument(className) {
  document.documentElement.className = className;
}

/**
 * Получает префикс бренда для темы.
 * @returns {string} - Префикс бренда для темы.
 */
function getThemeBrandPrefix() {
  switch (BRANDING) {
    case BRANDS.beeline:
      return 'beeline';
    case BRANDS.mts:
      return 'mts';

    default:
      return 'wimark';
  }
}

export {
  getDefaultTheme,
  setClassToTheDocument,
  getThemeBrandPrefix
};
