import { AuthTokenMaster } from '@/utils';

/**
 * Модуль Vuex для статуса аутентификации.
 *
 * @module auth
 * @namespace
 *
 *
 * Здесь размещаются стейты, геттеры, мутации и экшны, специфические для локализации.
 * Для использования в компонентах и других модулях Vuex.
 *
 * doc о модулях vuex:
 * https://v3.vuex.vuejs.org/ru/guide/modules.html
 *
 */

const initialAuthState = Boolean(AuthTokenMaster.getAccessToken());

const menu = {
  state: () => ({
    isAuthenticatedStatus: initialAuthState
  }),
  getters: {
    isAuthenticated: (state) => state.isAuthenticatedStatus
  },
  mutations: {
    setIsAuthenticatedStatus(state, newState) {
      state.isAuthenticatedStatus = newState;
    }
  },
  actions: {
  },
  namespaced: true
};

export default menu;
