import { createNamespacedHelpers } from 'vuex';

const { mapActions: intervalUpdateModeActions } = createNamespacedHelpers('intervalUpdateMode');

// todo: этот миксин используется в компонентах страниц, где нужны обновления данных по интервалу (где нужен функционал vuex module - intervalUpdateMode).

// необходимо:
// 1. примешать миксин к компоненту
// 2. в хуке mounted подписать свой колбэк на интервальное обновление.
// отписка происходит автоматически в beforeDestroy этого миксина.

// mounted() {
//     this.subscribeOnIntervalUpdate(() => yourCallback());
// },

const intervalUpdateModeMixin = {
  methods: {
    ...intervalUpdateModeActions(['subscribeOnIntervalUpdate', 'unsubscribeFromIntervalUpdate'])
  },
  beforeDestroy() {
    this.unsubscribeFromIntervalUpdate();
  }
};

export default intervalUpdateModeMixin;
