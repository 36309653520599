<template>
  <div class="tab">
    <!-- рендеринг вложенных табов, если они есть в конфиге -->
    <template v-if="tabContent.childrenTabs && tabContent.childrenTabs.length">
      <template v-if="tabContent.content && tabContent.content.length">
        <!-- рендеринг контента текущего таба через слот, если он есть в конфиге -->
        <slot name="tab-content"
          :tabContent="tabContent.content"
          :formState="formState"
          :currentTab="highOrderCurrentTab"
        >
        </slot>
      </template>
      <!-- рендеринг вложенных табов рекурсией -->
      <Tabs
        :tabs="tabContent.childrenTabs"
        :currentTab="currentSubTab"
        :tabsWithErrors="tabsWithErrors"
        @on-tab-change="handleTabClick"
      >
        <div v-for="(item, index) in tabContent.childrenTabs" :key="index">
          <FormTab v-show="currentSubTab === index"
            :tabContent="item"
            :formState="formState"
            :tabsWithErrors="tabsWithErrors"
            :highOrderCurrentTab="highOrderCurrentTabValueForChildComponent"
          >
            <!-- Прокидываем слот дальше во вложенные табы -->
            <template v-slot:tab-content="slotProps">
              <slot name="tab-content"
                v-bind="slotProps"
              >
              </slot>
            </template>
          </FormTab>
        </div>
      </Tabs>
    </template>

    <!-- если вложенных табов нет, контента текущего таба через слот -->
    <template v-else>
      <slot name="tab-content"
        :tabContent="tabContent.content"
        :formState="formState"
      >
      </slot>
    </template>
  </div>

</template>

<script>
/**
 * компонент Таба для FormWithTabs.
 * Отвечает только за рендеринг на основе конфига контента табов и вложенных табов через рекурсию, если они есть.
 * Не зависит от контента таба, он может быть любым. Контент таба задается через slot в FormWithTabs
 * @component
 *
 * ! компонент локальный. Используется только в FormWithTabs.vue
 */

export default {
  name: 'FormTab',
  inject: ['$validator'],
  components: {
  },
  props: {
    tabContent: {
      type: Object,
      required: true
    },
    formState: {
      type: Object,
      required: true
    },
    tabsWithErrors: {
      type: Array,
      default: () => ([])
    },
    highOrderCurrentTab: {
      type: Number,
      default: () => 0
    }
  },
  data() {
    return {
      currentSubTab: 0
    };
  },
  computed: {
    // значение для сброса текущего таба для дочернего таба в зависимости от значений любого из родителей.
    // если какой-то из родительских табов был переключен, все дочерние табы сбросятся в изначальное состояние, на первый таб.
    highOrderCurrentTabValueForChildComponent() {
      let value = 0;

      if (this.highOrderCurrentTab !== 0) {
        value = this.highOrderCurrentTab;
      }

      if (this.currentSubTab !== 0) {
        value = this.currentSubTab;
      }

      return value;
    }
  },
  watch: {
    highOrderCurrentTab(newVal, oldVal) {
      const INITIAL_TAB_NUMBER = 0;

      if (this.currentSubTab === INITIAL_TAB_NUMBER) {
        return;
      }

      // если текущий вышестоящий таб меняется, то текущий таб в этом компоненте сбрасывается в изначальное состояние, на первый таб
      if (newVal !== oldVal) {
        this.currentSubTab = INITIAL_TAB_NUMBER;
      }
    }
  },
  methods: {
    handleTabClick(num) {
      this.currentSubTab = num;
    },
    handleInput(value) {
      this.$emit('update:inputValue', value);
    }
  },
  mounted() {
  }
};
</script>

<style lang="css" scoped>
.tab {
  height: 100%;
}
</style>
