import Vue from 'vue';
import VueNotifications from 'vue-notifications';
import radiusPollingService from './radiusPollingService';
import { API_URL } from '@/config';

export default {
  // getRadius(ctx) {
  //   ctx.$store.commit('toggleLoadingRadius');
  //   Vue.axios.post(`${API_URL || ''}/api/radius`, { action: 'R' }).then(
  //     (response) => {
  //       if (response.data.status === 'success') {
  //         ctx.$store.commit('setRadiusList', response.data.data.itemslist);
  //       } else if (response.data.status === 'error') {
  //         VueNotifications.error({ message: response.data.description });
  //       }
  //       ctx.$store.commit('toggleLoadingRadius');
  //     },
  //     (err) => {
  //       // VueNotifications.error({ message: err });
  //
  //       // show errors in the console instead of the alert window
  //       if (
  //         Object.prototype.hasOwnProperty.call(window, 'isShowAxiosErrorInConsole') &&
  //         typeof window.isShowAxiosErrorInConsole === 'function' &&
  //         window.isShowAxiosErrorInConsole(err)
  //       ) {
  //         window.showAxiosErrInConsole(err);
  //       } else {
  //         VueNotifications.error({ message: err });
  //       }
  //
  //       ctx.$store.commit('toggleLoadingRadius');
  //       ctx.error = err;
  //     }
  //   );
  // },
    getRadius(ctx, useLocationFilter = false) {
        ctx.$store.commit('toggleLoadingRadius');
        const isUseLocationFilters = useLocationFilter

        const params = {
            action: 'R'
        }
            if (!isUseLocationFilters) {
      params.location =  ctx.$store.getters.getDefaultLocationForRequests(ctx);
      params.with_childs =  ctx.$store.getters.getDefaultWithChildsForRequests(ctx);
    }
        if (ctx.filters?.location && isUseLocationFilters) {
            if (ctx.filters.location === 'All locations') {
                params.location = ctx.$store.state.requestBaseUserLocationIfNoSelected ? ctx.userBaseLocation : undefined;
                params.with_childs = true;
            } else {
                params.location = ctx.filters.location;
                params.with_childs = ctx.filters.with_childs;
            }
        }


        Vue.axios.post(`${API_URL || ''}/api/radius`, params).then(
            (response) => {
                if (response.data.status === 'success') {
                    ctx.$store.commit('setRadiusList', response.data.data.itemslist);
                } else if (response.data.status === 'error') {
                    VueNotifications.error({message: response.data.description});
                }
                ctx.$store.commit('toggleLoadingRadius');
            },
            (err) => {
                // VueNotifications.error({ message: err });

                // show errors in the console instead of the alert window
                if (
                    Object.prototype.hasOwnProperty.call(window, 'isShowAxiosErrorInConsole') &&
                    typeof window.isShowAxiosErrorInConsole === 'function' &&
                    window.isShowAxiosErrorInConsole(err)
                ) {
                    window.showAxiosErrInConsole(err);
                } else {
                    VueNotifications.error({message: err});
                }

                ctx.$store.commit('toggleLoadingRadius');
                ctx.error = err;
            }
        );
    },

      getRadiusV2(ctx, useLocationFilter = false) {
        ctx.$store.commit('toggleLoadingRadius');
        const isUseLocationFilters = useLocationFilter

        const params = {
            action: 'R',
          page: {limit: ctx.limit, offset: ctx.offset},
          search: ctx.filters.query
        }
        params.sort_by = { field: ctx.filters.sort_by.field, order: ctx.filters.sort_by.order }
            if (!isUseLocationFilters) {
      params.location =  ctx.$store.getters.getDefaultLocationForRequests(ctx);
      params.with_childs =  ctx.$store.getters.getDefaultWithChildsForRequests(ctx);
    }
        if (ctx.filters?.location && isUseLocationFilters) {
            if (ctx.filters.location === 'All locations') {
                params.location = ctx.$store.state.requestBaseUserLocationIfNoSelected ? ctx.userBaseLocation : undefined;
                params.with_childs = true;
            } else {
                params.location = ctx.filters.location;
                params.with_childs = ctx.filters.with_childs;
            }
        }


        Vue.axios.post(`${API_URL || ''}/api/radius`, params).then(
            (response) => {
                if (response.data.status === 'success') {
                  // console.log(response.data.data)
                  ctx.totalResult = response.data.data.total
                    ctx.$store.commit('setRadiusListForRadiusV2Page', response.data.data.itemslist);
                } else if (response.data.status === 'error') {
                    VueNotifications.error({message: response.data.description});
                }
                ctx.$store.commit('toggleLoadingRadius');
            },
            (err) => {
                // VueNotifications.error({ message: err });

                // show errors in the console instead of the alert window
                if (
                    Object.prototype.hasOwnProperty.call(window, 'isShowAxiosErrorInConsole') &&
                    typeof window.isShowAxiosErrorInConsole === 'function' &&
                    window.isShowAxiosErrorInConsole(err)
                ) {
                    window.showAxiosErrInConsole(err);
                } else {
                    VueNotifications.error({message: err});
                }

                ctx.$store.commit('toggleLoadingRadius');
                ctx.error = err;
            }
        );
    },

        getRadiusV2FromWizard(ctx, useLocationFilter = false) {
        ctx.$store.commit('toggleLoadingRadius');
        const isUseLocationFilters = useLocationFilter

        const params = {
            action: 'R',
          page: {limit: 10, offset: 0},
          search: ''
        }
        params.sort_by = { field: 'name', order: 1 }
            if (!isUseLocationFilters) {
      params.location =  ctx.$store.getters.getDefaultLocationForRequests(ctx);
      params.with_childs =  ctx.$store.getters.getDefaultWithChildsForRequests(ctx);
    }
        if (ctx.filters?.location && isUseLocationFilters) {
            if (ctx.filters.location === 'All locations') {
                params.location = ctx.$store.state.requestBaseUserLocationIfNoSelected ? ctx.userBaseLocation : undefined;
                params.with_childs = true;
            } else {
                params.location = ctx.filters.location;
                params.with_childs = ctx.filters.with_childs;
            }
        }


        Vue.axios.post(`${API_URL || ''}/api/radius`, params).then(
            (response) => {
                if (response.data.status === 'success') {
                  // console.log(response.data.data)
                  ctx.totalResult = response.data.data.total
                    ctx.$store.commit('setRadiusListForRadiusV2Page', response.data.data.itemslist);
                } else if (response.data.status === 'error') {
                    VueNotifications.error({message: response.data.description});
                }
                ctx.$store.commit('toggleLoadingRadius');
            },
            (err) => {
                // VueNotifications.error({ message: err });

                // show errors in the console instead of the alert window
                if (
                    Object.prototype.hasOwnProperty.call(window, 'isShowAxiosErrorInConsole') &&
                    typeof window.isShowAxiosErrorInConsole === 'function' &&
                    window.isShowAxiosErrorInConsole(err)
                ) {
                    window.showAxiosErrInConsole(err);
                } else {
                    VueNotifications.error({message: err});
                }

                ctx.$store.commit('toggleLoadingRadius');
                ctx.error = err;
            }
        );
    },

  updateRadius(ctx) {
    ctx.$store.commit('toggleMuteRadiusEdit', true);
    const updateRadiusQuery = { action: 'U', items: { [ctx.updatedRadius.id]: ctx.updatedRadius } };
    Vue.axios.post(`${API_URL || ''}/api/radius`, updateRadiusQuery).then(
      (response) => {
        if (response.data.status === 'success') {
          radiusPollingService.hadleOperationResponse(response.data.data.items);
          ctx.refreshRadiusList();
        } else if (response.data.status === 'error') {
          VueNotifications.error({ message: response.data.description });
        }
        ctx.disableEditMode();
        ctx.$store.commit('toggleMuteRadiusEdit', false);
      },
      (err) => {
        // VueNotifications.error({ message: err });

        // show errors in the console instead of the alert window
        if (
          Object.prototype.hasOwnProperty.call(window, 'isShowAxiosErrorInConsole') &&
          typeof window.isShowAxiosErrorInConsole === 'function' &&
          window.isShowAxiosErrorInConsole(err)
        ) {
          window.showAxiosErrInConsole(err);
        } else {
          VueNotifications.error({ message: err });
        }

        ctx.$store.commit('toggleMuteRadiusEdit', false);
        ctx.error = err;
      }
    );
  },


    updateRadiusV2(ctx) {
    ctx.$store.commit('toggleMuteRadiusEdit', true);
    const updateRadiusQuery = { action: 'U', items: { [ctx.updatedRadius.id]: ctx.updatedRadius } };
    Vue.axios.post(`${API_URL || ''}/api/radius`, updateRadiusQuery).then(
      (response) => {
        if (response.data.status === 'success') {
          radiusPollingService.hadleOperationResponse(response.data.data.items);
          ctx.refreshRadiusList();
        } else if (response.data.status === 'error') {
          VueNotifications.error({ message: response.data.description });
        }
        ctx.disableEditMode();
        ctx.$store.commit('toggleMuteRadiusEdit', false);
      },
      (err) => {
        // VueNotifications.error({ message: err });

        // show errors in the console instead of the alert window
        if (
          Object.prototype.hasOwnProperty.call(window, 'isShowAxiosErrorInConsole') &&
          typeof window.isShowAxiosErrorInConsole === 'function' &&
          window.isShowAxiosErrorInConsole(err)
        ) {
          window.showAxiosErrInConsole(err);
        } else {
          VueNotifications.error({ message: err });
        }

        ctx.$store.commit('toggleMuteRadiusEdit', false);
        ctx.error = err;
      }
    );
  },


  deleteRadius(ctx) {
    ctx.$store.commit('toggleMuteRadiusEdit', true);
    const deleteRadiusQuery = { action: 'D', items: { [ctx.radiusIdToDelete]: { type: 'both' } } };
    Vue.axios.post(`${API_URL || ''}/api/radius`, deleteRadiusQuery).then(
      (response) => {
        if (response.data.status === 'success') {
          ctx.radiusIdToDelete = '';
          radiusPollingService.hadleOperationResponse(response.data.data.items);
          ctx.refreshRadiusList();
        } else if (response.data.status === 'error') {
          VueNotifications.error({ message: response.data.description });
        }
        ctx.deletingRadiusModal = false;
        ctx.$store.state.editRadiusIndex = '';
        ctx.$store.commit('toggleMuteRadiusEdit', false);
      },
      (err) => {
        // VueNotifications.error({ message: err });

        // show errors in the console instead of the alert window
        if (
          Object.prototype.hasOwnProperty.call(window, 'isShowAxiosErrorInConsole') &&
          typeof window.isShowAxiosErrorInConsole === 'function' &&
          window.isShowAxiosErrorInConsole(err)
        ) {
          window.showAxiosErrInConsole(err);
        } else {
          VueNotifications.error({ message: err });
        }

        ctx.$store.commit('toggleMuteRadiusEdit', false);
        ctx.error = err;
      }
    );
  },

    deleteRadiusV2(ctx) {
    ctx.$store.commit('toggleMuteRadiusEdit', true);
    const deleteRadiusQuery = { action: 'D', items: { [ctx.radiusIdToDelete]: { type: 'both' } } };
    Vue.axios.post(`${API_URL || ''}/api/radius`, deleteRadiusQuery).then(
      (response) => {
        if (response.data.status === 'success') {
          ctx.radiusIdToDelete = '';
          radiusPollingService.hadleOperationResponse(response.data.data.items);
          ctx.refreshRadiusList();
        } else if (response.data.status === 'error') {
          VueNotifications.error({ message: response.data.description });
        }
        ctx.deletingRadiusModal = false;
        ctx.$store.state.editRadiusIndex = '';
        ctx.$store.commit('toggleMuteRadiusEdit', false);
      },
      (err) => {
        // VueNotifications.error({ message: err });

        // show errors in the console instead of the alert window
        if (
          Object.prototype.hasOwnProperty.call(window, 'isShowAxiosErrorInConsole') &&
          typeof window.isShowAxiosErrorInConsole === 'function' &&
          window.isShowAxiosErrorInConsole(err)
        ) {
          window.showAxiosErrInConsole(err);
        } else {
          VueNotifications.error({ message: err });
        }

        ctx.$store.commit('toggleMuteRadiusEdit', false);
        ctx.error = err;
      }
    );
  },
  deleteMultiselectedRadiusV2(ctx, idsList) {
    if (!idsList.length) {
      return
    }
    const itemsForDelete = {};
    idsList.forEach((id) => {
      itemsForDelete[id] = {type: 'both'}
    })
    ctx.$store.commit('toggleMuteRadiusEdit', true);
    const deleteRadiusQuery = {action: 'D', items: {...itemsForDelete}};
    Vue.axios.post(`${API_URL || ''}/api/radius`, deleteRadiusQuery).then(
      (response) => {
        if (response.data.status === 'success') {
          // ctx.radiusIdToDelete = '';
          radiusPollingService.hadleOperationResponse(response.data.data.items);
          ctx.clearSelectedRADIUSId();
          ctx.deletingRadiusModalForMultiselect = false;
          ctx.refreshRadiusList();

        } else if (response.data.status === 'error') {
          VueNotifications.error({message: response.data.description});
        }
        // ctx.deletingRadiusModal = false;
        // ctx.$store.state.editRadiusIndex = '';
        ctx.$store.commit('toggleMuteRadiusEdit', false);
      },
      (err) => {
        // VueNotifications.error({ message: err });

        // show errors in the console instead of the alert window
        if (
          Object.prototype.hasOwnProperty.call(window, 'isShowAxiosErrorInConsole') &&
          typeof window.isShowAxiosErrorInConsole === 'function' &&
          window.isShowAxiosErrorInConsole(err)
        ) {
          window.showAxiosErrInConsole(err);
        } else {
          VueNotifications.error({message: err});
        }

        ctx.$store.commit('toggleMuteRadiusEdit', false);
        ctx.error = err;
      }
    );
  }



};
