<template>
  <APProfileFormModal
    :heading="$t('apjoin.addAPJoinProfile')"
    :isOpen="isOpen"
    @close="handleClose"
    v-bind="$attrs"
  >
    <template v-slot:ap-profile-form>
      <CreateAPProfileForm
        :forbiddenProfileNames="forbiddenProfileNames"
        @submit="handleSubmit"
      >
        <template v-slot:form-control-buttons>
          <div class="button-section">
            <WButton secondary outline
              @click="handleClose"
            >
              {{ $t('general.cancel') }}
            </WButton>
            <WButton success type="submit">
              {{ $t('general.add') }}
            </WButton>
          </div>
        </template>
      </CreateAPProfileForm>
    </template>
  </APProfileFormModal>
</template>

<script>
/**
 * компонент модального окна с формой для создания нового профия ap join.
 * ! Компонент локальный, используется только в APJoinPage.vue
 * @component
 */

import {
  APProfileFormModal
} from '../features';

import {
  CreateAPProfileForm
} from './components';

export default {
  name: 'CreateAPProfileModal',
  components: {
    CreateAPProfileForm,
    APProfileFormModal
  },
  props: {
    isOpen: {
      type: Boolean,
      required: true
    },
    forbiddenProfileNames: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
    };
  },
  computed: {
  },
  methods: {
    handleSubmit(formValues) {
      this.$emit('submit', formValues);
    },
    handleClose() {
      this.$emit('close');
    }
  },
  created() {
  }
};
</script>

<style lang="css" scoped>
.button-section {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
</style>
