<template>
  <div class="externalAP-wrapper">
    <div class="row">
      <div class="col-lg-12 mb-1">
        <WButton info
          customClass="float-right"
          v-if="!isOperator"
          @click="addNewExternalAP"
        >
          {{ $t('externalAP.addExternalAP') }}
        </WButton>
      </div>
      <div class="col-lg-12">
        <div class="filter-block p-0">
          <div class="filters w-100">
            <div class="filters__search">
                <div class="col-lg-3 input-group input-group-sm filters__input p-0 pr-1" style="height: 27.5px">
                  <span class="input-group-addon">
                    <i class="fa fa-spin fa-circle-o-notch" v-if="searchSpinner"></i>
                    <i class="fa fa-search" v-else></i>
                  </span>
                  <input
                    id="search"
                    name="search"
                    :placeholder="$t('general.search')"
                    @keyup.13="searchInputHandler(searchValue)"
                    class="form-control"
                    v-model="searchValue"
                    @input="searchInputHandler(searchValue)"
                  />
                  <span class="clear-input text-muted" style="right: 20px">
                    <i class="fa fa-remove" v-if="searchValue && !searchSpinner" @click="searchInputHandler('')"></i>
                  </span>
                </div>

              <div class="col-lg-3 input-group-for-location-select-in-templates--filters p-0 pr-1" v-if="areLocationsVisible">
                <span class="input-group-addon filters__field-icon">
                  <i class="fa fa-map-marker" :class="{ 'text-primary': filters.location !== 'All locations' }"></i>
                </span>
                <SelectComponentV3ForUseInLocationSelectors
                  v-if="showLocationFilter"
                  no-wrap-selected-option
                  class="select-location-in-templates--filters"
                  enable-max-option-width
                  small-size
                  :show-search-field-into-items-list="
                    !isSelectedDefaultLocationForRequests ||
                    (isSelectedDefaultLocationForRequests && $store.getters.getDefaultWithChildsForRequests())
                  "
                  :no-clear-selected-option-when-click-on-it="isSelectedDefaultLocationForRequests"
                  :show-delete-button="!isSelectedDefaultLocationForRequests"
                  v-model="locationInFiltersData"
                  track-by="name"
                  option-id-name="id"
                  :no-options-found="$t('general.noResultForSearch')"
                  :async-function="requestLocations.bind(this)"
                  :placeholder="$t('portalStats.selectLocation')"
                  @select="selectLocationInFilters"
                >
                  <template #dropdown-start>
                    <div class="ml-h mt-1">
                      <Switch-component
                        v-if="
                          !isSelectedDefaultLocationForRequests ||
                          (isSelectedDefaultLocationForRequests && $store.getters.getDefaultWithChildsForRequests())
                        "
                        @input="selectLocation"
                        :label="$t('general.withChild')"
                        v-model="filters.with_childs"
                        class=""
                      />
                    </div>
                  </template>
                </SelectComponentV3ForUseInLocationSelectors>
              </div>
              <div class="col-lg-3 mb-h pl-0">
                <div class="input-group input-group-sm filters__sort flex-wrap mr-1">
                <span class="input-group-addon filters__field-icon">
                  <i
                    class="fa text-primary icon-sort"
                    :class="[filters.sort_by.order === 1 ? 'fa-sort-amount-asc' : 'fa-sort-amount-desc']"
                    @click="toggleSortMode"
                  />
                </span>
                  <select @change="handleSelectSortField" id="filter"
                          class="form-control form-control-sm filters__field"
                          v-model="filters.sort_by.field">
                    <option value="name">{{ $t('externalAP.byName') }}</option>
                    <option value="mac">{{ $t('externalAP.byMac') }}</option>
                    <option value="serial">{{ $t('externalAP.bySN') }}</option>
                  </select>
                </div>
              </div>
            </div>
            <div v-if="isFiltersActive" class="mb-1">
              <WButton secondary outline sm
                @click="resetAllFilters"
              >
                <i class="fa fa-close"></i>
                {{ $t('general.resetAllFilters') }}
              </WButton>
            </div>
          </div>
          <!-- <div class="pl-1">
            <WButton info
              v-if="!isDisable"
              @click="openTemplateWizard"
            >
              {{ $t('template.addTemplate') }}
            </WButton>
          </div> -->
        </div>
      </div>

      <div class="col-lg-12">
        <div class="externalAps animated fadeIn" :class="{ 'edit-visible': isEditVisible }">
          <!--        <div class="loader loader-backdrop" v-if="isExternalApsLoading"></div>-->
          <!--        <loader-spinner v-if="isExternalApsLoading" ></loader-spinner>-->
          <div class="externalAps-list">
            <!--           <loader-spinner v-if="isExternalApsLoading" ></loader-spinner>-->
            <div class="row">
              <div class="col-lg-12">
                <div class="card">
                  <loader-spinner v-if="isExternalApsLoading" />
                  <div class="card-header">
                    <i class="fa fa-align-justify"></i>
                    {{ $t('externalAP.externalApsList') }}
                  </div>
                  <div class="card-block">
                    <table class="table">
                      <thead>
                        <tr>
                          <th>{{ $t('general.name') }}</th>
                          <th>{{ $t('externalAP.status') }}</th>
                          <th v-if="!isEditVisible">MAC</th>
                          <th v-if="!isEditVisible">{{ $t('externalAP.serial') }}</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          :class="{
                            selected: updatedExternalAP && externalAP.id === updatedExternalAP.id,
                            'externalAP-updating': externalApOperations.includes(externalAP.id),
                            'externalAP-loading': externalApOperations.includes(externalAP.id)
                          }"
                          v-for="externalAP in externalAPsList"
                          :key="externalAP.id"
                          :data-id="externalAP.id"
                          @click="toggleEdit(externalAP.id)"
                        >
                          <td>{{ externalAP.name }}</td>
                          <td>
                            <span v-if="externalApOperations.includes(externalAP.id)" class="text-info config-updating">
                              <i class="fa fa-circle-o-notch fa-spin"></i>
                              {{ $t('aps.updating') }}
                            </span>
                            <span
                              v-else
                              :class="{
                                'text-success': externalAP.status === 'connected',
                                'text-danger': externalAP.status === 'error',
                                'text-muted': externalAP.status === 'disconnected' || externalAP.status === 'empty',
                                'text-primary': externalAP.status === 'provision'
                              }"
                            >
                              {{ externalAP.status }}
                            </span>
                          </td>
                          <td v-if="!isEditVisible">
                            <span :class="{ 'text-muted': !externalAP.mac }">
                              {{ externalAP.mac ? helpers.getFormatMac(externalAP.mac) : $t('general.noData') }}
                            </span>
                          </td>
                          <td v-if="!isEditVisible">
                            <span :class="{ 'text-muted': !externalAP.serial }">
                              {{ externalAP.serial ? externalAP.serial : $t('general.noData') }}
                            </span>
                          </td>
                        </tr>
                        <tr v-if="!externalAPsList.length">
                          <td colspan="4" class="no-data-row">
                            <span class="text-muted">{{ $t('aps.noDataToDisplay') }}</span>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <div
                      v-if="canLoadMore && !isExternalApsLoading && externalAPsList.length"
                      class="card-block text-center p-0"
                    >
                      <WButton link
                        @click="loadMore()"
                      >
                        {{ $t('externalAP.load') }}
                        <span v-if="this.limit < this.totalResult - this.externalAPsList.length">{{ this.limit }}</span>
                        <span v-else>{{ this.totalResult - this.externalAPsList.length }}</span>
                        {{ $t('externalAP.externalAPsOutOf') }} {{ this.totalResult - this.externalAPsList.length }}
                      </WButton>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="externalAp-edit" v-if="isEditVisible">
            <div class="row">
              <div class="col-lg-12">
                <div class="card">
                  <div class="card-block">
                    <div class="card-top-buttons">
                      <WButton secondary outline sm
                        @click="disableEditMode"
                      >
                        <i class="fa fa-close"></i>
                      </WButton>
                    </div>
                    <externalAPSettings
                      @isBothSSHPasswordAndSSHKeyExistsChanged = 'setValueForisBothSSHPasswordAndSSHKeyExistsInUpdatedExternalAP'
                      v-if="updatedExternalAP"
                      :external-a-p-data="updatedExternalAP"
                      :current-external-a-p-data="currentExternalAP"
                      :isDisable="isDisable"
                    ></externalAPSettings>
                  </div>
                  <div class="actions-buttons" :class="{ 'actions-buttons__operator': isDisable }">
                    <div>
                      <WButton success outline
                        v-if="!isDisable"
                        @click="updateExternalAP()"
                        :disabled="errors.any() || !enableSaveChanges || muteEdit"
                      >
                        <span :class="{ invisible: muteEdit }">{{ $t('general.save') }}</span>
                        <span v-if="muteEdit" class="loader loader--mini"></span>
                      </WButton>
                      <WButton danger outline
                        customClass="ml-1"
                        v-if="!isDisable"
                        @click="openDeletingDialog(updatedExternalAP.id)"
                        :disabled="muteEdit"
                      >
                        <span :class="{ invisible: muteEdit }">{{ $t('general.delete') }}</span>
                        <span v-if="muteEdit" class="loader loader--mini"></span>
                      </WButton>
                    </div>

                    <div>
                      <WButton secondary outline
                        @click="disableEditMode"
                      >
                        {{ $t('general.close') }}
                      </WButton>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <modal
        title="externalAP"
        v-model="wizardModal"
        @ok="addUser = false"
        effect="fade/zoom"
        class="modal-success"
        ref="newExternalAPModal"
      >
        <h4 slot="modal-title" class="modal-title">{{ $t('externalAP.externalAPHeader') }}</h4>
        <external-a-p-settings
          @isBothSSHPasswordAndSSHKeyExistsChanged = 'setValueForisBothSSHPasswordAndSSHKeyExistsInNewExternalAP'
          :external-a-p-data="newExternalAPData"
          :current-external-a-p-data="{}"
          :isDisable="isDisable"
        ></external-a-p-settings>
        <div slot="modal-footer" class="">
          <div class="actions-buttons" :class="{ 'actions-buttons__operator': isDisable }">
            <div>
              <WButton secondary outline
                @click="wizardModal = false"
              >
                {{ $t('general.close') }}
              </WButton>
            </div>
            <div>
              <WButton success outline
                v-if="!isDisable"
                @click="createNewExternalAP"
                :disabled="muteEdit || errors.any()"
              >
                <span :class="{ invisible: muteEdit }">{{ $t('general.add') }}</span>
                <span v-if="muteEdit" class="loader loader--mini"></span>
              </WButton>
            </div>
          </div>
        </div>
      </modal>

      <modal title="Delete User" class="modal-danger" v-model="deleteModal" effect="fade/zoom">
        <h4 slot="modal-title" class="modal-title">{{ $t('externalAP.deleteExternalAPHeader') }}</h4>
        {{ $t('externalAP.confirmDelete') }}
        <strong>{{ updatedExternalAP.name }}</strong>

        <div slot="modal-footer" class="modal-footer">
          <WButton secondary
            @click="deleteModal = false"
          >
            {{ $t('general.cancel') }}
          </WButton>
          <WButton danger outline
            @click="deleteExternalAP"
            :disabled="muteEdit"
          >
            <span :class="{ invisible: muteEdit }">{{ $t('general.delete') }}</span>
            <span v-if="muteEdit" class="loader loader--mini"></span>
          </WButton>
        </div>
      </modal>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import VueNotifications from 'vue-notifications';
import VeeValidate from 'vee-validate';
import moment from 'moment';
// import controllerConfig from '../components/Controllers/controllerConfig.vue';
import Modal from '../components/Modal.vue';
// import controllersService from '../services/controllersService';
// import controllerPollingService from '../services/controllerPollingService';
// import commonService from '../services/commonService';
import helpers from '../helpers';
import externalApsService from '../services/externalApsService';
import externalAPSettings from '../components/ExternalAPs/externalAPSettings.vue';
import externalAPsPollingService from '../services/externalAPsPollingService';
// import templatesService from '../services/templatesService';
import locationService from '../services/locationService';
import SwitchComponent from '../components/Universal/Switch-component.vue';
import SelectComponentV3ForUseInLocationSelectors from '../components/Universal/select/select-componentV3ForUseInLocationSelectors.vue';


Vue.use(require('vue-moment'));

export default {
  name: 'ExternalAPs',
  components: {
    Modal,
    // controllerConfig,
    externalAPSettings,
    SelectComponentV3ForUseInLocationSelectors,
    SwitchComponent
  },
  data() {
    return {
      // externalAPsList: [],
      isBothSSHPasswordAndSSHKeyExistsInNewExternalAP: false,
      isBothSSHPasswordAndSSHKeyExistsInUpdatedExternalAP: false,
      locationInFiltersData: [],
      searchSpinner: '',
      searchValue: '',
      filters: {
        search: '',
        location: 'All locations',
        with_childs: false,
        sort_by: {
          order: 1,
          field: 'name'
        }
      },
      showLocationFilter: true,
      canLoadMore: true,
      offset: 0,
      limit: 100,
      totalResult: false,
      newExternalAPData: {
        // state
        // status: '',
        // mac: '',
        // serial: '',
        // vendor: '',
        // fw_version: '',

        name: '',
        description: '',
        ssh: {
          username: '',
          password: '',
          key: '',
          port: undefined
        },
        snmp: {
          community: 'public',
          version: 'Version1',
          port: undefined,
          set_trap_server: true
        }
      },
      enableSaveChanges: false,
      wizardModal: false,
      deleteModal: false,
      updatedExternalAP: {},
      currentCpeData: {},
      modalErrors: []
    };
  },
  computed: {
    isFiltersActive() {
      return this.filters.location !== 'All locations' || this.searchValue !== '' ||
        this.filters.sort_by.field !== 'name' || this.filters.sort_by.order !== 1;
    },
    isSelectedDefaultLocationForRequests() {
      if (this.$store.getters.getDefaultLocationForRequests(this)) {
        return true;
      } else {
        return false;
      }
    },
    helpers() {
      return helpers;
    },
    window() {
      return window;
    },
    // externalAPConfig() {
    //   return ExternalAPConfig;
    // },
    isDisable() {
      // return false;
      return (
        this.$store.state.userData.role !== 'admin' ||
        (this.updatedExternalAP && this.externalApOperations.includes(this.updatedExternalAP.id))
      );
    },
    isOperator() {
      return this.$store.state.userData.role !== 'admin';
    },
    isExternalApsLoading() {
      return this.$store.state.loadingExternalAps;
    },
    externalAPsList() {
      return this.$store.state.externalAPsList;
    },
    isEditVisible() {
      return Object.keys(this.updatedExternalAP).length;
    },
    muteEdit() {
      return this.$store.state.muteExternalApEdit;
    },
    externalApOperations() {
      return helpers.combineModelIdsArrayFromObjectsArray(this.$store.state.externalApOperations);
    }
  },
  watch: {
    externalAPsList() {
      externalAPsPollingService.startPolling(this);
    },
    updatedExternalAP: {
      handler(val, oldVal) {
        if (!oldVal) {
          this.enableSaveChanges = false;
        } else if (val && oldVal && val.id !== oldVal.id) {
          this.enableSaveChanges = false;
        } else if (!val) {
          this.enableSaveChanges = false;
        } else {
          this.enableSaveChanges = true;
        }
      },
      deep: true
    }
  },
  methods: {
    setValueForisBothSSHPasswordAndSSHKeyExistsInNewExternalAP(emitedValue) {
      this.isBothSSHPasswordAndSSHKeyExistsInNewExternalAP = emitedValue
    },
    setValueForisBothSSHPasswordAndSSHKeyExistsInUpdatedExternalAP(emitedValue) {
      this.isBothSSHPasswordAndSSHKeyExistsInUpdatedExternalAP = emitedValue
    },
    setDefaultCommunityValueIfEmpty(externalAPObject) {
      try {
        if (externalAPObject.snmp.community === '' ||
          externalAPObject.snmp.community === undefined ||
          externalAPObject.snmp.community === null) {
          // eslint-disable-next-line no-param-reassign
          externalAPObject.snmp.community = 'public';
        }
      } catch (e) {
        console.log(e);
      }
    },
    handleSelectSortField() {
      this.disableEditMode();
      this.resetPagintaion();
      externalApsService.getExternalAps(this, 'spinner');
    },
    toggleSortMode() {
      this.disableEditMode();
      this.resetPagintaion();
      if (this.filters.sort_by.order === 1) {
        this.filters.sort_by.order = -1;
      } else {
        this.filters.sort_by.order = 1;
      }
      externalApsService.getExternalAps(this, 'spinner');
    },
    searchInputHandler(value) {
      this.disableEditMode();
      this.resetPagintaion();
      this.searchValue = value;
      this.searchSpinner = true;
      clearTimeout(this.delayTimer);
      this.delayTimer = setTimeout(() => {
        externalApsService.getExternalAps(this, 'spinner');
        this.searchSpinner = false;
      }, 1000);
    },
    resetPagintaion(){
      // console.log('reseted')
      this.offset = 0;
      this.limit = 100;
    },
    resetAllFilters() {
      this.disableEditMode();
      // this.locationInFiltersData = [];
      // this.toggleShowLocationFilter();
      this.filters = {
        search: '',
        // with_childs: false,
        // location: 'All locations',
        sort_by: {
          order: 1,
          field: 'name'
        }
      };
      this.resetPagintaion();
      this.searchValue = '';
      if (!this.isSelectedDefaultLocationForRequests) {
        this.filters.location = 'All locations';
        this.filters.with_childs = false;
        this.locationInFiltersData = [];
      } else {
        this.filters.location = this.$store.getters.getDefaultLocationForRequests(this);
        this.filters.with_childs = this.$store.getters.getDefaultWithChildsForRequests(this);
        this.locationInFiltersData = this.$store.getters.getInHeaderLocationSelectBlockData.selectedLocationObject;
      }
      this.toggleShowLocationFilter();
      externalApsService.getExternalAps(this, 'spinner');
    },
    toggleShowLocationFilter() {
      this.showLocationFilter = false;
      this.$nextTick(() => {
        this.showLocationFilter = true;
      });
    },
    selectLocationInFilters(location) {
      // console.log('selected')
      this.disableEditMode();
      this.resetPagintaion();
      // console.log(location)
      if (!location) {
        this.filters.location = 'All locations';
      } else {
        this.filters.location = location.id;
        // this.addLocationInPreviouslySelectedLocationsInFilter(location)
      }
      externalApsService.getExternalAps(this, 'spinner');
    },

    async requestLocations(searchQuery) {
      // return locationService.requestLocations(searchQuery);
      // return locationService.requestLocationsWithSort(searchQuery);
      return locationService.requestLocationsWithSortAndFilteringByChildsForLocationSelectInPages(this, searchQuery);
    },
    selectLocation() {
      this.disableEditMode();
      this.resetPagintaion();
      externalApsService.getExternalAps(this, 'spinner');
    },
    loadMore() {
      this.offset++;
      externalApsService.getExternalAps(this, 'spinner', true);
    },
    clearAllValidationErrors() {
      this.isBothSSHPasswordAndSSHKeyExistsInNewExternalAP = false;
      this.isBothSSHPasswordAndSSHKeyExistsInUpdatedExternalAP = false;
      if (this.errors) {
        this.errors.clear();
      }
      if (this.$refs.newExternalAPModal) {
        if (this.$refs.newExternalAPModal.errors) {
          this.$refs.newExternalAPModal.errors.clear();
        }
      }
    },
    addNewExternalAP() {
      this.openWizard();
    },
    resetNewExternalAPData() {
      this.newExternalAPData = {
        // state
        // status: '',
        // mac: '',
        // serial: '',
        // vendor: '',
        // fw_version: '',

        name: '',
        description: '',
        ssh: {
          username: '',
          password: '',
          key: '',
          port: undefined
        },
        snmp: {
          community: 'public',
          version: 'Version1',
          port: undefined,
          set_trap_server: true
        }
      };
    },
    openWizard() {
      this.disableEditMode()
      this.clearAllValidationErrors();
      this.resetNewExternalAPData();
      this.wizardModal = true;
    },
    toggleEdit(id) {
      this.isBothSSHPasswordAndSSHKeyExistsInUpdatedExternalAP = false;
      for (const externalAP of this.externalAPsList) {
        if (externalAP.id === id) {
          this.updatedExternalAP = JSON.parse(JSON.stringify(externalAP));
          if (!Object.prototype.hasOwnProperty.call(this.updatedExternalAP.snmp, 'set_trap_server')) {
            this.$set(this.updatedExternalAP.snmp, 'set_trap_server', false);
          }
          this.currentExternalAP = externalAP;
        }
      }
    },
    disableEditMode() {
      this.isBothSSHPasswordAndSSHKeyExistsInUpdatedExternalAP = false;
      this.updatedExternalAP = {};
      this.deleteModal = false;
      this.wizardModal = false;
    },
    openDeletingDialog() {
      this.deleteModal = true;
    },
    ipv4Validate(value) {
      const macRegex = new RegExp(
        /((^\s*(((\d|[1-9]\d|1\d{2}|2[0-4]\d|25[0-5])\.){3}(\d|[1-9]\d|1\d{2}|2[0-4]\d|25[0-5]))\s*$)|(^\s*((([\dA-Fa-f]{1,4}:){7}([\dA-Fa-f]{1,4}|:))|(([\dA-Fa-f]{1,4}:){6}(:[\dA-Fa-f]{1,4}|((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3})|:))|(([\dA-Fa-f]{1,4}:){5}(((:[\dA-Fa-f]{1,4}){1,2})|:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3})|:))|(([\dA-Fa-f]{1,4}:){4}(((:[\dA-Fa-f]{1,4}){1,3})|((:[\dA-Fa-f]{1,4})?:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([\dA-Fa-f]{1,4}:){3}(((:[\dA-Fa-f]{1,4}){1,4})|((:[\dA-Fa-f]{1,4}){0,2}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([\dA-Fa-f]{1,4}:){2}(((:[\dA-Fa-f]{1,4}){1,5})|((:[\dA-Fa-f]{1,4}){0,3}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([\dA-Fa-f]{1,4}:)(((:[\dA-Fa-f]{1,4}){1,6})|((:[\dA-Fa-f]{1,4}){0,4}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(:(((:[\dA-Fa-f]{1,4}){1,7})|((:[\dA-Fa-f]{1,4}){0,5}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:)))(%.+)?\s*$))/
      );
      return macRegex.test(value);
    },
    createNewExternalAP() {
      // console.log(this.$refs)
      // this.$validator.validateAll().then((result) => {
      if (this.$refs.newExternalAPModal) {
        this.$refs.newExternalAPModal.$validator.validateAll().then((result) => {
          if (result) {
            if (!this.isBothSSHPasswordAndSSHKeyExistsInNewExternalAP) {
              this.setDefaultCommunityValueIfEmpty(this.newExternalAPData);
              // console.log(result)
              externalApsService.addExternalAp(this);
            }

          }
        });
      }
    },
    refreshExternalAPs(spinner) {
      // externalApsService.getExternalAps(this, spinner);
      externalApsService.refreshExternalAps(this, 'spinner');
    },
    deleteExternalAP() {
      externalApsService.deleteExternalAp(this);
    },
    updateExternalAP() {
      this.$validator.validateAll().then((result) => {
        if (result) {
          if (!this.isBothSSHPasswordAndSSHKeyExistsInUpdatedExternalAP) {
            this.setDefaultCommunityValueIfEmpty(this.updatedExternalAP);
            externalApsService.updateExternalAp(this);
          }

        }
      });
    }
  },
  filters: {
    moment(value) {
      if (value) {
        return moment(value * 1000).format(' D MMM YYYY, H:mm:ss ');
      }
    }
  },
  created() {
    this.$store.commit('clearExternalApsList');
    let inHeaderLocationSelectedApplying = true;
    if (this.$store.state.inHeaderLocationSelectModeEnabled && inHeaderLocationSelectedApplying) {
      let withChildsForFiltering = this.$store.getters.getInHeaderLocationSelectBlockData.withChilds;
      let locationForFiltering = this.$store.getters.getInHeaderLocationSelectBlockData.selectedLocationID;
      if (!locationForFiltering || locationForFiltering === '') {
        // locationForFiltering = this.$store.getters.getInHeaderLocationSelectBlockData.userBaseLocation
        locationForFiltering = 'All locations';
      }

      if (locationForFiltering === 'All locations') {
        this.locationInFiltersData = [];
      } else {
        this.locationInFiltersData = this.$store.getters.getInHeaderLocationSelectBlockData.selectedLocationObject;
      }

      this.filters.location = locationForFiltering;
      this.filters.with_childs = withChildsForFiltering;
    }
    externalApsService.getExternalAps(this, 'spinner');
  }
};
</script>

<style scoped lang="scss">
.externalAP-wrapper {
  .externalAP-loading {
    animation-name: blink;
    animation-timing-function: linear;
    animation-duration: 1s;
    animation-iteration-count: infinite;
  }

  @keyframes blink {
    50% {
      opacity: 0;
    }
  }

  .externalAps {
    display: flex;
  }

  .externalAps-list {
    display: inline-block;
    width: 100%;
  }

  .externalAps-list,
  .externalAp-edit {
    transition: width 0.2s;
  }

  table tr {
    transition: all 0.2s;
    cursor: pointer;
  }

  table tr .no-data-row {
    cursor: default;
  }

  table tbody tr:hover {
    background: rgba(236, 236, 236, 0.59);
  }

  table tr.selected {
    background: #d9e3ec;
  }

  .externalAp-edit {
    display: inline-block;
    width: 0%;
  }

  .edit-visible .externalAps-list {
    transition: width, margin-right 0.2s;
    margin-right: 10px;
    width: 50%;
  }

  .edit-visible .externalAp-edit {
    transition: width 0.2s;
    width: 50%;
  }

  .externalAp-updating {
    opacity: 0.9;
    background: rgba(236, 236, 236, 0.45);
  }

  .status {
    position: relative;

    padding-left: 15px;

    &::after {
      content: '';
      position: absolute;
      left: 0;
      top: 5px;
      display: inline-block !important;
      width: 10px;
      height: 10px;
      margin-right: 0.25rem;
      border: 1px solid #fff;
      border-radius: 50em;
    }

    &--connected:after {
      background-color: #4dbd74 !important;
    }

    &--disconnected:after {
      background-color: #bcc5d0 !important;
    }
  }

  .card-top-buttons {
    display: flex;
    justify-content: flex-end;

    & .btn-sm {
      padding: 0.18rem 0.35rem;
      font-size: 0.675rem;
    }
  }
}
</style>
