/**
 * Изменяет в переданном объекте wlan значения security.data.hotspot20_profile из отсутсвующего на пустую строку
 * Нужно для показа в UI путых строк
 *
 * изначальное значение пустое. Если не поставить, то не будет отображаться 'Нет' в инпуте Hotspot 2.0 профиль
 * https://wimark.kaiten.ru/34134096
 *
 * @param wlanObject
 */
function modifyUndefinedToEmptyStringForHotspot20ProfileWhenReceivedFromAPIBeforeShowInUI(wlanObject) {
  if (wlanObject && wlanObject.security.data === null) {
    // eslint-disable-next-line no-param-reassign
    wlanObject.security.data = {};
  }

  if (wlanObject && !wlanObject.security.data.hotspot20_profile) {
    // eslint-disable-next-line no-param-reassign
    wlanObject.security.data.hotspot20_profile = '';
  }
}

const whenReceivedFromAPIBeforeShowInUISecurityModifiers = [modifyUndefinedToEmptyStringForHotspot20ProfileWhenReceivedFromAPIBeforeShowInUI]

export { whenReceivedFromAPIBeforeShowInUISecurityModifiers };
