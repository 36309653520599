// миксин используется только в компонентах связанных с редиректами

import { REDIRECT_TYPES } from '@/views/GuestControl/config';

const defineRedirectTypeMixin = {
  methods: {
    /**
     * Проверяет cтатический ли тип у редиректа
     *
     * @param {string} redirect
     * @returns {boolean}
     */
    isStaticRedirectTypeDefine(redirectType) {
      return redirectType === REDIRECT_TYPES.STATIC || redirectType === ''; // '' - это тоже static https://wimark.kaiten.ru/34477225
    },
    /**
     * Проверяет динамический  ли тип у редиректа
     *
     * @param {object} redirect
     * @returns {boolean}
     */
    isDynamicRedirectTypeDefine(redirectType) {
      return redirectType === REDIRECT_TYPES.DYNAMIC;
    },
    /**
     * Проверяет пустой тип у редиректа.
     * фактически пришло ли поле redirect_type с бэка
     *
     * @param {object} redirect
     * @returns {boolean}
     */
    isNoRedirectTypeDefine(redirectType) {
      return redirectType === undefined;
    }
  }
};

export default defineRedirectTypeMixin;
