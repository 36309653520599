/**
 * Модуль Vuex для управления локализацией приложения.
 *
 * @module locale
 * @namespace
 *
 * Здесь размещаются стейты, геттеры, мутации и экшны, специфические для локализации.
 * Для использования в компонентах и других модулях Vuex.
 *
 * doc о модулях vuex:
 * https://v3.vuex.vuejs.org/ru/guide/modules.html
 *
 */

import { DEFAULT_LOCALE, LOCALES, FALLBACK_LOCALE } from '@/config';
import { LocaleMaster } from '@/utils';
import { getFallbackLocale } from './utils';
import { localeService } from '@/services';
import LOCALES_FILE from '@/locales';

/**
 * Начальная локаль приложения.
 * @constant {string}
 */
const currentLocale = LocaleMaster.getCachedLocale() || DEFAULT_LOCALE;

const locale = {
  state: () => ({
    locale: currentLocale, // Начальная локаль
    fallbackLocale: FALLBACK_LOCALE, // Локаль по умолчанию
    messages: {
      // todo:
      // дефолтные локали по задумке не нужны, они подставляются динамически.
      // Поэтому поле messages должно было быть пустым объектом изначально.
      // но из-за этого появился баг на странице Login,
      // когда локаль в i18n instance не подставлялась, и не рендерилась никакая локаль вообще.
      // чтобы воспроизвести баг, сделай messages пустым объектом и перезагрузи страницу Login
      // временное решение - ставить дефолтные локали здесь. Пока будет так. Возможно, в дальнейшем найдем решение бага получше
      en: LOCALES_FILE.en,
      ru: LOCALES_FILE.ru
    },
    localeFetchError: false
  }),
  getters: {
    locale: state => state.locale,
    fallbackLocale: state => state.fallbackLocale,
    messages: state => state.messages,
    isLocaleExist: state => localeNameToCheck => Boolean(state.messages[localeNameToCheck]),
    isRuLocale: state => state.locale === LOCALES.ru,
    isEnLocale: state => state.locale === LOCALES.en
  },
  mutations: {
    /**
     * Устанавливает текущую локаль.
     * @param {object} state - Состояние модуля Vuex.
     * @param {string} newLocale - Новая локаль.
     */
    setLocale(state, newLocale) {
      state.locale = newLocale;

      LocaleMaster.setCachedLocale(newLocale);
    },

    /**
     * Устанавливает локализованные сообщения для указанной локали.
     * @param {object} state - Состояние модуля Vuex.
     * @param {object} payload - Объект с новой локалью и сообщениями.
     * @param {string} payload.newLocale - Новая локаль.
     * @param {object} payload.messages - Объект с локализованными сообщениями.
     */
    async setMessages(state, { newLocale, messages }) {
      state.messages[newLocale] = messages;
    },

    /**
     * Устанавливает флаг ошибки при загрузке локали.
     * !!! Напрямую в компонентах не используется
     * @param {object} state - Состояние модуля Vuex.
     * @param {boolean} isError - Флаг ошибки загрузки локали.
     */
    _setLocaleFetchError(state, isError) {
      state.localeFetchError = isError;
    }
  },
  actions: {
    /**
     * Централизованный экшн-обработчик для загрузки локалей.
     * !!! Напрямую в компонентах не используется
     * @param {object} context - Контекст Vuex.
     * @param {function} context.commit - Функция для вызова мутаций.
     * @param {object} payload - Объект с параметрами.
     * @param {function} payload.fetcher - Функция для загрузки локали.
     * @param {string} payload.newLocale - Новая локаль.
     */
    async _handleLocaleFetch({ commit }, { fetcher, newLocale }) {
      commit('setLoading', null, { root: true });

      await fetcher()
        .then(fetchedLocale => {
          commit('setMessages', { newLocale, messages: fetchedLocale });
          commit('setSuccess', null, { root: true });
        })
        .catch((error) => {
          commit('_setLocaleFetchError', true);
          commit('setError', error.message, { root: true });
        });
    },

    /**
     * Загрузка локализации с бэкенда.
     * !!! Напрямую в компонентах не используется
     * @param {object} context - Контекст Vuex.
     * @param {function} context.commit - Функция для вызова мутаций.
     * @param {function} context.getters - Функция для получения геттеров.
     * @param {function} context.dispatch - Функция для вызова других экшнов.
     * @param {string} newLocale - Новая локаль.
     */
    async _loadLocale({ commit, getters, dispatch }, newLocale) {
      // чтобы не загружать второй раз локаль, если она уже была загружена
      if (getters.isLocaleExist(newLocale)) {
        return;
      }

      commit('_setLocaleFetchError', false);

      switch (newLocale) {
        case LOCALES.en:
          await dispatch('_handleLocaleFetch', {
            fetcher: localeService.fetchLocaleEN,
            newLocale: LOCALES.en
          });
          break;

        case LOCALES.ru:
        default:
          await dispatch('_handleLocaleFetch', {
            fetcher: localeService.fetchLocaleRU,
            newLocale: LOCALES.ru
          });
      }
    },

    /**
     * Загрузка резервной локали из файла.
     * !!! Напрямую в компонентах не используется
     * @param {object} context - Контекст Vuex.
     * @param {object} context.state - Состояние модуля Vuex.
     * @param {function} context.commit - Функция для вызова мутаций.
     * @param {function} context.getters - Функция для получения геттеров.
     */
    async _loadFallbackLocale({ state, commit, getters }) {
      // чтобы не загружать второй раз локаль, если она уже была загружена
      if (getters.isLocaleExist(state.fallbackLocale)) {
        return;
      }

      const fallbackLocaleMessages = getFallbackLocale(state.fallbackLocale);
      commit('setMessages', { newLocale: state.fallbackLocale, messages: fallbackLocaleMessages });
    },

    /**
     * Инициализация локали при первой загрузке приложения.
     * !!! Используем этот action только в компоненте App при первой загрузке приложения, когда надо инициализировать локаль
     * @param {object} context - Контекст Vuex.
     * @param {object} payload - Объект с параметрами.
     * @param {function} payload.onInitLocaleSuccess - - Опциональный коллбэк, который будет вызван после успешной инициализации локали.
     * @param {function} payload.onInitLocaleError - Опциональный коллбэк, который будет вызван при ошибке инициализации локали.
     */
    async initLocale({ state, dispatch }, { onInitLocaleSuccess, onInitLocaleError }) {
      // Вызываем экшн для загрузки локализации
      await dispatch('_loadLocale', state.locale);

      // если ошибка загрузки локали, то применить фолбэк локаль
      if (state.localeFetchError) {
        dispatch('_loadFallbackLocale');

        if (onInitLocaleError) {
          onInitLocaleError(state.fallbackLocale);
        }

        return;
      }

      if (onInitLocaleSuccess) {
        onInitLocaleSuccess(state.locale);
      }
    },

    /**
     * Переключение локали из интерфейса.
     * @param {object} context - Контекст Vuex.
     * @param {object} context.state - Состояние модуля Vuex.
     * @param {function} context.commit - Функция для вызова мутаций.
     * @param {function} context.dispatch - Функция для вызова других экшнов.
     * @param {object} payload - Объект с параметрами.
     * @param {string} payload.newLocale - Новая локаль.
     * @param {Function} payload.onLocaleSwitchSuccess - Опциональный коллбэк, который будет вызван после успешного переключения локали.
     */
    async switchLocale({ state, commit, dispatch }, { newLocale, onLocaleSwitchSuccess }) {
      // Вызываем экшн для загрузки локализации
      await dispatch('_loadLocale', newLocale);

      // локаль не меняется, если по каким-то причинам требуемая локаль не загрузилась
      if (state.localeFetchError) {
        return;
      }

      commit('setLocale', newLocale);
      if (onLocaleSwitchSuccess) {
        onLocaleSwitchSuccess(state.locale);
      }
    }
  },
  namespaced: true
};

export default locale;
