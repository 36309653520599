import $can from './$can';

const accessControl = {
  install(Vue, options) {
    Vue.prototype.$can = $can;

    /**
     * Checks if action can be performed for all routes in group
     * Used for collapsing sidebar nav groups if none of sections can be accessed
     * @param {string} action
     * @param {array.<string>} routeGroup
     * @return {boolean}
     */
    Vue.prototype.$canSomeInGroup = function (action, routeGroup) {
      /*
      This is may seem counter-intuitive, but it does the following:
      1) create an array of the same length as routeGroup,
      2) fill it with empty strings (otherwise it will be filled with empty elements),
      3) replace items with evaluation results for each route
      */
      const evaluationResults = new Array(routeGroup.length)
        .fill('')
        .map((el, index) => this.$can(action, { route: routeGroup[index] }));
      return evaluationResults.some((item) => item === true);
    };
  }
};

export default accessControl;
