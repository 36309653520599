<template>
  <div v-if="props.row.wired.amountOfWiredVlans !== null || props.row.wired.amountOfWiredClients !== null"
    class="cpe-interface-state"
  >
    <div class="cpe-interface-state__badge">
      <span
        class="badge badge-light"
        :class="[props.row.wired.amountOfWiredVlans ? 'badge-success' : 'badge-default']"
      >
        <i class="fa fa-plug"></i>
      </span>
      <span
        class="badge badge-light"
        :class="[props.row.wired.amountOfWiredVlans ? 'badge-success' : 'badge-default']"
      >
        {{ props.row.wired.amountOfWiredVlans }}
      </span>
      <span v-if="props.row.wired.amountOfWiredClients"
        class="ml-h badge badge-light badge-success"
      >
        <i class="fa fa-user"></i>
      </span>
      <span v-if="props.row.wired.amountOfWiredClients"
        class="badge badge-light badge-success"
      >
        {{ props.row.wired.amountOfWiredClients }}
      </span>
      <span v-if="props.row.wired.areTunnelAndWLANtunnelNotMatchAndNotNatForTable"
        class="has-tooltip ml-h"
        v-tooltip.top-center="$t('aps.tunnelAndWLANtunnelsAreNotMatchForCPETable')"
      >
        <i class="fa fa-warning text-danger"></i>
      </span>
    </div>
  </div>
  <span v-else>-</span>
</template>

<script>
/**
 * компонент - ячейка wired.
 * компонент локальный: используется только в ApsTable.vue
 * @component
 */

export default {
  name: 'WiredCell',
  components: {},
  props: {
    props: {
      type: Object,
      required: true
    }
  },
  methods: {
  }
};
</script>

<style lang="css" scoped>
.cpe-interface-state__badge {
  display: flex;
}
</style>
