<template>
  <section class="component">
    <header class="component__header hidden"><h6 class="component__title">WMM</h6></header>

    <div class="component__body">
      <div class="form-group">
        <Switch-component
          v-model="updatedWlan.wmm.disabled"
          :disabled="isDisable"
          :label="$t('general.enable')"
          :id="'wmm-disabled'"
          :inverse-switcher-enabled-logic="true"
        />
      </div>

      <div class="form-group">
        <Switch-component
          v-model="updatedWlan.wmm.uapsd"
          :disabled="isDisable"
          :label="$t('wlans.UAPSD')"
          :id="'wmm-uapsd'"
        />
      </div>

      <div
        class="category"
        v-for="(category, categoryKey) in CATEGORIES"
        :class="{ 'mb-2': !isCategoryShow(categoryKey) }"
        :key="categoryKey"
      >
        <div class="category__header" @click="showCategory(categoryKey, category)">
          <div class="h6 category__title">
            {{ $t(`wmm.${categoryKey}`) }}
          </div>
          <WButton link customClass="new-modern-style-btn-other-btn">
            <i :class="{
                  'icon-arrow-left': !isCategoryShow(categoryKey),
                  'icon-arrow-down': isCategoryShow(categoryKey)
                }"></i>
          </WButton>
        </div>
        <div
          class="category__block mb-2 mt-1"
          v-if="updatedWlan.wmm.categories[category]"
          v-show="isCategoryShow(categoryKey)"
        >

          <div
            class="form-group"
            v-for="(fieldConfig, fieldConfigKey) in WMM_CATEGORIES_AND_CONFIGURATION[`${category}`]['configuration']"
            v-if="fieldConfig.isShowing"
            :key="fieldConfigKey"
          >
            <div class="category__config" v-if="fieldConfig.type === 'int' || fieldConfig.type === 'float'">
              <label :for="fieldConfig.nameForValidation">{{ fieldConfig.nameForDispaly }}</label>
              <div class="w-100">
                <WInput sm
                  v-if="fieldConfig.type === 'int'"
                  type="text"
                  :disabled="isDisable"
                  :data-vv-as="fieldConfig.nameForDispaly"
                  :name="fieldConfig.nameForValidation"
                  :id="fieldConfig.nameForValidation"
                  v-validate="getValidationRulesForField(category, fieldConfig)"
                  v-model.number="updatedWlan.wmm.categories[`${category}`][`${fieldConfigKey}`]"
                  :errorText="errors.first(fieldConfig.nameForValidation)"
                />
                <WInput sm
                  v-if="fieldConfig.type === 'float'"
                  type="number"
                  step="0.1"
                  :disabled="isDisable"
                  :data-vv-as="fieldConfig.nameForDispaly"
                  :name="fieldConfig.nameForValidation"
                  :id="fieldConfig.nameForValidation"
                  v-validate="getValidationRulesForField(category, fieldConfig)"
                  :errorText="errors.first(fieldConfig.nameForValidation)"
                  v-model.number="updatedWlan.wmm.categories[`${category}`][`${fieldConfigKey}`]"
                />
                <small>{{ $t(`wmm.hint.${fieldConfigKey}`)}}</small>
              </div>
            </div>
            <div v-if="fieldConfig.type === 'bool'">
              <Switch-component
                v-model="updatedWlan.wmm.categories[`${category}`][`${fieldConfigKey}`]"
                :disabled="isDisable"
                :label="fieldConfig.nameForDispaly"
                :id="fieldConfig.nameForValidation"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import Vue from 'vue';
import commonService from '../../services/commonService';
import SwitchComponent from '../Universal/Switch-component.vue';

import {WMM_CATEGORIES_AND_CONFIGURATION, WMM_PREFIX_FOR_FIELDS_VALIDATION} from './WMMconfig';


export default {
  name: 'WMM',
  components: { SwitchComponent },
  inject: ['$validator'],
  props: {
    updatedWlan: {
      type: Object
    },
    wlanEditCurrent: {
      type: Object
    },
    isDisable: {
      type: Boolean
    },
    showCategoryStausBadges: {
      type: Boolean,
      default: true
    },
    isBackgroundShow: {
      type: Boolean
    },
    isBestEffortShow: {
      type: Boolean
    },
    isVideoShow: {
      type: Boolean
    },
    isVoiceShow: {
      type: Boolean
    }
  },
  data() {
    return {
      isBackgroundShowConfig: this.isBackgroundShow,
      isBestEffortShowConfig: this.isBestEffortShow,
      isVideoShowConfig: this.isVideoShow,
      isVoiceShowConfig: this.isVoiceShow
    };
  },

  watch: {
    errors: {
      handler() {
        this.checkWmmFieldsValidationErrorsAndEmitEvent();
      },
      deep: true
    }
  },
  computed: {
    prefixForFieldsValidation() {
      return WMM_PREFIX_FOR_FIELDS_VALIDATION;
    },
    commonService() {
      return commonService;
    },
    CATEGORIES() {
      const result = {};
      for (const key in this.WMM_CATEGORIES_AND_CONFIGURATION) {
        result[`${this.WMM_CATEGORIES_AND_CONFIGURATION[key].nameForDispaly}`] = key
      }
      return result;
    },
    WMM_CATEGORIES_AND_CONFIGURATION(){
      return WMM_CATEGORIES_AND_CONFIGURATION;
    }
  },
  methods: {
    /**
     * Функция для получения правил для валидации. В базовом случае выдвет правило из объекта конфигурации поля
     * Но в зависмости от заполнения полей могут быть нюансы - например нужно чтобы Client cwMin было не больше чем Client cwMax и тд
     *
     * @param {object} fieldConfig - тут прилетает информация о поле из wmmCategoriesAndConfiguration.js
     * @param {String} category - а тут ключ категории (BK, BE) и тд
     *
     */
    getValidationRulesForField(category, fieldConfig) {
      const { prefixForFieldsValidation } = this;
      // Кейс чтобы Client cwMin было не больше чем Client cwMax
      // (плюс в обратной стуации Client cwMax меньше чем Client cwMin тоже срабатывает, так как это одно и тоже)
      if (fieldConfig.nameForValidation === `${prefixForFieldsValidation}-${category}-cli_cw_min`
        && this.updatedWlan.wmm.categories[category]['cli_cw_max'] && this.updatedWlan.wmm.categories[category]['cli_cw_min']) {
        return `required|min_value:0|max_value:${this.updatedWlan.wmm.categories[category]['cli_cw_max']}`;
      }

      // Кейс на случай если в Client cwMin ввели значение, а в Client cwMax значения нет. В таком случае не произойдет проверка что больше, а что меньше,
      // а перед пересылкой на бэк Client cwMax заполнится значенияем по умолчанию, и может оказатся в итоге меньше чем введеный Client cwMin.
      // Поэтому, чтобы избежать такого делаем в таком случае Client cwMax обязательным
      if (fieldConfig.nameForValidation === `${prefixForFieldsValidation}-${category}-cli_cw_max` && !this.updatedWlan.wmm.categories[category]['cli_cw_max']
        && this.updatedWlan.wmm.categories[category]['cli_cw_min']) {
        return 'required';
      }
      // Кейс на случай если в Client cwMax ввели значение, а в Client cwMin значения нет. В таком случае не произойдет проверка что больше, а что меньше,
      // а перед пересылкой на бэк Client cwMin заполнится значенияем по умолчанию, и может оказатся в итоге больше чем введеный Client cwMax.
      // Поэтому, чтобы избежать такого делаем в таком случае Client cwMax обязательным
      if (fieldConfig.nameForValidation === `${prefixForFieldsValidation}-${category}-cli_cw_min` && !this.updatedWlan.wmm.categories[category]['cli_cw_min']
        && this.updatedWlan.wmm.categories[category]['cli_cw_max']) {
        return 'required';
      }

      // Кейс чтобы AC cwMin было не больше чем AC cwMax (плюс в обратной стуации AC cwMax  меннб чем AC cwMin тоже срабатывает, так как это одно и тоже)
      if (fieldConfig.nameForValidation === `${prefixForFieldsValidation}-${category}-ac_cw_min`
        && this.updatedWlan.wmm.categories[category]['ac_cw_max'] && this.updatedWlan.wmm.categories[category]['ac_cw_min']) {
        return `${fieldConfig.validation}|max_value:${this.updatedWlan.wmm.categories[category]['ac_cw_max']}`;
      }
      // Кейс на случай если в AC cwMin ввели значение, а в AC cwMax значения нет. В таком случае не произойдет проверка что больше, а что меньше,
      // а перед пересылкой на бэк AC cwMax заполнится значенияем по умолчанию, и может оказатся в итоге меньше чем введеный AC cwMin.
      // Поэтому, чтобы избежать такого делаем в таком случае AC cwMax обязательным
      if (fieldConfig.nameForValidation === `${prefixForFieldsValidation}-${category}-ac_cw_max` && !this.updatedWlan.wmm.categories[category]['ac_cw_max']
        && this.updatedWlan.wmm.categories[category]['ac_cw_min']) {
        return 'required';
      }
      // Кейс на случай если в AC cwMax ввели значение, а в AC cwMin значения нет. В таком случае не произойдет проверка что больше, а что меньше,
      // а перед пересылкой на бэк AC cwMin заполнится значенияем по умолчанию, и может оказатся в итоге меньше чем введеный AC cwMax.
      // Поэтому, чтобы избежать такого делаем в таком случае AC cwMax обязательным
      if (fieldConfig.nameForValidation === `${prefixForFieldsValidation}-${category}-ac_cw_min` && !this.updatedWlan.wmm.categories[category]['ac_cw_min']
        && this.updatedWlan.wmm.categories[category]['ac_cw_max']) {
        return 'required';
      }

      // если ничего не подошло возвращаем базовое правило валидации из из объекта конфигурации поля
      return fieldConfig.validation;
    },
    /**
     * Заполняет пустые категории дефолтными значениями полей
     */
    fillEmptyFieldsWithDefaultValues() {
      for (const category of Object.values(this.CATEGORIES)) {
        if (!this.updatedWlan.wmm.categories[category]) {
          Vue.set(this.updatedWlan.wmm.categories, category, {});
          for (const config in WMM_CATEGORIES_AND_CONFIGURATION[category]['configuration']) {
            Vue.set(this.updatedWlan.wmm.categories[category], config, WMM_CATEGORIES_AND_CONFIGURATION[category]['configuration'][config]['defaultValue']);
          }
        }
      }
    },
    /**
     * Проверяет есть ли ошибки в wmm полях и инициализирует эмит события
     */
    checkWmmFieldsValidationErrorsAndEmitEvent() {
      if (this.errors?.items) {
        const filteredWmmErrors = this.errors.items.filter((item) => {
          return item.field.includes(this.prefixForFieldsValidation);
        });
        if (filteredWmmErrors.length) {
          this.emitWmmValidationErrorsChanged(true);
        } else {
          this.emitWmmValidationErrorsChanged(false);
        }
      } else {
        this.emitWmmValidationErrorsChanged(false);
      }
    },
    /**
     * Эмитит событие wmm-validation-errors-changed
     *
     * @param {Boolean} hasWmmFieldsErrors - есть ли ошибки или нет
     */
    emitWmmValidationErrorsChanged(hasWmmFieldsErrors) {
      this.$emit('wmm-validation-errors-changed', hasWmmFieldsErrors);
    },
    showCategory(key, category) {
      this[`is${key}ShowConfig`] = !this[`is${key}ShowConfig`];

      if (!this.updatedWlan.wmm.categories[category]) {
        Vue.set(this.updatedWlan.wmm.categories, category, {});
        for (const config in WMM_CATEGORIES_AND_CONFIGURATION[category]['configuration']) {
          Vue.set(this.updatedWlan.wmm.categories[category], config, '');
        }
      } else {
        let categoryHasNoEmptyConfigValues = false;
        for (const config in WMM_CATEGORIES_AND_CONFIGURATION[category]['configuration']) {
          if (this.updatedWlan.wmm.categories[category][config] !== '') {
            categoryHasNoEmptyConfigValues = true;
          }
        }
        if (!categoryHasNoEmptyConfigValues) {
          Vue.delete(this.updatedWlan.wmm.categories, category);
        }
      }
      this.$nextTick(() => {
        this.$validator.validateAll();
      });
    },
    isCategoryShow(key) {
      return this[`is${key}ShowConfig`];
    }
  },
  created() {
    if (!this.updatedWlan.wmm.categories) {
      this.updatedWlan.wmm.categories = {};
    }
    this.fillEmptyFieldsWithDefaultValues();
  }
};
</script>

<style lang="scss" scoped>
.category__header {
  display: flex;
  justify-content: space-between;

  cursor: pointer;
}

.category__title {
  position: relative;
  margin-bottom: 0;
}

.category__status {
  margin-left: 10px;
}

.category__config {
  position: relative;
  display: flex;

  & label {
    flex-basis: 25%;
  }
  & input {
    flex-basis: 75%;
  }
}

.category__error {
  position: absolute;
  right: 8px;
  top: 0px;

  cursor: pointer;
}
</style>
