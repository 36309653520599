import Vue from 'vue';
import VueNotifications from 'vue-notifications';
import externalAPsPollingService from './externalAPsPollingService';
import { API_URL } from '@/config';

export default {
  getExternalAps(ctx, spinner, loadmore) {
    if (!loadmore) {
      ctx.$store.commit('clearExternalApsList');
    }

    if (spinner) {
      ctx.$store.commit('toggleLoadingExternalAps');
    }

    const query = {
      action: 'R',
      search: ctx.searchValue,
      location: ctx.$store.getters.getDefaultLocationForRequests(ctx),
      with_childs: ctx.$store.getters.getDefaultWithChildsForRequests(ctx),
      sort_by: { field: ctx.filters.sort_by.field, order: ctx.filters.sort_by.order },
      page: {}
    };

    if (ctx.filters.location === 'All locations') {
      query.location = ctx.$store.state.requestBaseUserLocationIfNoSelected ? ctx.userBaseLocation : undefined;
      query.with_childs = true;
    } else {
      query.location = ctx.filters.location;
      query.with_childs = ctx.filters.with_childs;
    }

    query.page.limit = ctx.limit;
    query.page.offset = ctx.limit * ctx.offset;

    Vue.axios.post(`${API_URL || ''}/api/ext_access_points`, query).then(
      (response) => {
        if (response.data.status === 'success') {
          ctx.totalResult = response.data.data.total;
          ctx.$store.commit('pushExternalApsList', response.data.data.itemslist);
          if (ctx.$store.state.externalAPsList.length >= response.data.data.total) {
            ctx.canLoadMore = false;
          } else {
            ctx.canLoadMore = true;
          }
        } else if (response.data.status === 'error') {
          VueNotifications.error({ message: response.data.description });
        }
        if (spinner) {
          ctx.$store.commit('toggleLoadingExternalAps');
        }
      },
      (err) => {
        // VueNotifications.error({ message: err });

        // show errors in the console instead of the alert window
        if (
          Object.prototype.hasOwnProperty.call(window, 'isShowAxiosErrorInConsole') &&
          typeof window.isShowAxiosErrorInConsole === 'function' &&
          window.isShowAxiosErrorInConsole(err)
        ) {
          window.showAxiosErrInConsole(err);
        } else {
          VueNotifications.error({ message: err });
        }

        ctx.error = err;
        if (spinner) {
          ctx.$store.commit('toggleLoadingExternalAps');
        }
      }
    );
  },

  refreshExternalAps(ctx, spinner) {
    ctx.$store.commit('clearExternalApsList');

    if (spinner) {
      ctx.$store.commit('toggleLoadingExternalAps');
    }

    const query = {
      action: 'R',
      search: ctx.searchValue,
      location: ctx.$store.getters.getDefaultLocationForRequests(ctx),
      with_childs: ctx.$store.getters.getDefaultWithChildsForRequests(ctx),
      sort_by: { field: ctx.filters.sort_by.field, order: ctx.filters.sort_by.order },
      page: {}
    };

    if (ctx.filters.location === 'All locations') {
      query.location = ctx.$store.state.requestBaseUserLocationIfNoSelected ? ctx.userBaseLocation : undefined;
      query.with_childs = true;
    } else {
      query.location = ctx.filters.location;
      query.with_childs = ctx.filters.with_childs;
    }

    query.page.limit = ctx.limit + ctx.limit * ctx.offset;
    query.page.offset = 0;

    Vue.axios.post(`${API_URL || ''}/api/ext_access_points`, query).then(
      (response) => {
        if (response.data.status === 'success') {
          ctx.totalResult = response.data.data.total;
          ctx.$store.commit('pushExternalApsList', response.data.data.itemslist);
          if (ctx.$store.state.externalAPsList.length >= response.data.data.total) {
            ctx.canLoadMore = false;
          } else {
            ctx.canLoadMore = true;
          }
        } else if (response.data.status === 'error') {
          VueNotifications.error({ message: response.data.description });
        }
        if (spinner) {
          ctx.$store.commit('toggleLoadingExternalAps');
        }
      },
      (err) => {
        // VueNotifications.error({ message: err });

        // show errors in the console instead of the alert window
        if (
          Object.prototype.hasOwnProperty.call(window, 'isShowAxiosErrorInConsole') &&
          typeof window.isShowAxiosErrorInConsole === 'function' &&
          window.isShowAxiosErrorInConsole(err)
        ) {
          window.showAxiosErrInConsole(err);
        } else {
          VueNotifications.error({ message: err });
        }

        ctx.error = err;
        if (spinner) {
          ctx.$store.commit('toggleLoadingExternalAps');
        }
      }
    );
  },

  addExternalAp(ctx) {
    ctx.$store.commit('toggleMuteExternalApEdit', true);
    Vue.axios
      .post(`${API_URL || ''}/api/ext_access_points`, { action: 'C', items: { 0: ctx.newExternalAPData } })
      .then(
        (response) => {
          if (response.data.status === 'success') {
            ctx.$store.commit('toggleMuteExternalApEdit', false);
            ctx.resetNewExternalAPData();
            externalAPsPollingService.hadleOperationResponse(response.data.data.items);
          } else {
            ctx.$store.commit('toggleMuteExternalApEdit', false);
            VueNotifications.error({ message: response.data.description });
          }
          ctx.wizardModal = false;
          this.getExternalAps(ctx);
        },
        (err) => {
          ctx.$store.commit('toggleMuteExternalApEdit', false);
          VueNotifications.error({ message: err });
          ctx.error = err;
        }
      );
  },

  updateExternalAp(ctx) {
    ctx.$store.commit('toggleMuteExternalApEdit', true);
    const updateExternalApQuery = {
      action: 'U',
      items: { [ctx.updatedExternalAP.id]: ctx.updatedExternalAP }
    };
    Vue.axios.post(`${API_URL || ''}/api/ext_access_points`, updateExternalApQuery).then(
      (response) => {
        if (response.data.status === 'success') {
          externalAPsPollingService.hadleOperationResponse(response.data.data.items);
        } else {
          VueNotifications.error({ message: response.data.description });
        }
        ctx.$store.commit('toggleMuteExternalApEdit', false);
        ctx.disableEditMode();
        this.refreshExternalAps(ctx);
      },
      (err) => {
        ctx.$store.commit('toggleMuteExternalApEdit', false);
        // VueNotifications.error({ message: err });

        // show errors in the console instead of the alert window
        if (
          Object.prototype.hasOwnProperty.call(window, 'isShowAxiosErrorInConsole') &&
          typeof window.isShowAxiosErrorInConsole === 'function' &&
          window.isShowAxiosErrorInConsole(err)
        ) {
          window.showAxiosErrInConsole(err);
        } else {
          VueNotifications.error({ message: err });
        }

        ctx.error = err;
      }
    );
  },

  deleteExternalAp(ctx) {
    ctx.$store.commit('toggleMuteExternalApEdit', true);
    const deleteExternalApQuery = { action: 'D', items: { [ctx.updatedExternalAP.id]: {} } };
    Vue.axios.post(`${API_URL || ''}/api/ext_access_points`, deleteExternalApQuery).then(
      (response) => {
        if (response.data.status === 'success') {
          externalAPsPollingService.hadleOperationResponse(response.data.data.items);
        } else {
          VueNotifications.error({ message: response.data.description });
        }
        ctx.$store.commit('toggleMuteExternalApEdit', false);
        ctx.disableEditMode();
        this.refreshExternalAps(ctx);
      },
      (err) => {
        ctx.$store.commit('toggleMuteExternalApEdit', false);
        // VueNotifications.error({ message: err });

        // show errors in the console instead of the alert window
        if (
          Object.prototype.hasOwnProperty.call(window, 'isShowAxiosErrorInConsole') &&
          typeof window.isShowAxiosErrorInConsole === 'function' &&
          window.isShowAxiosErrorInConsole(err)
        ) {
          window.showAxiosErrInConsole(err);
        } else {
          VueNotifications.error({ message: err });
        }

        ctx.error = err;
      }
    );
  }
};
