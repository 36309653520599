import { GAPI_URL } from '@/config';
import { AXIOS_INSTANCE } from '@/api';

const GET_APS_PROFILES_URL = `${GAPI_URL}/api/v1/profiles/ap-join`;
const POST_CREATE_AP_PROFILE_URL = `${GAPI_URL}/api/v1/profiles/ap-join`;
const POST_UPDATE_AP_PROFILE_URL = `${GAPI_URL}/api/v1/profiles/ap-join`;
const POST_DELETE_AP_PROFILE_URL = `${GAPI_URL}/api/v1/profiles/ap-join/bulk/deletion`;

export {
  AXIOS_INSTANCE,
  GET_APS_PROFILES_URL,
  POST_CREATE_AP_PROFILE_URL,
  POST_UPDATE_AP_PROFILE_URL,
  POST_DELETE_AP_PROFILE_URL
};
