<template>
  <WDropdown openCloseToggleMode>
    <template v-slot:button-inner-content>
      <div class="crumb section">
        {{ item.title | capitalize }}
        <i class="fa fa-sort-desc icon" />
      </div>
    </template>

    <template v-slot:inner-content>
      <div class="list-wrapper">
        <div
          class="menu-item role-section color"
          :class="[item.selected && 'selected']"
        >
          <i v-if="item.icon" class="icon-item" :class="[item.icon, item.selected && 'selected']" />
          <h3 class="heading">
            {{ item.title | capitalize }}
          </h3>
        </div>

        <WList class="link-list"
          :fixedItems="{ maxItems: 8 }"
          ref="wlist"
        >
          <li v-for="child in item.children" :key="child.id">
            <router-link
              class="menu-item role-link color selectable"
              :class="[child.selected && 'selected']"
              :to="child.to"
              ref="roleLink"
            >
              <i v-if="child.icon" class="icon-item" :class="[child.icon, child.selected && 'selected']"/>
              <span class="text-content">
                {{ child.title | capitalize }}
              </span>
            </router-link>
          </li>
        </WList>
      </div>

    </template>
  </WDropdown>
</template>

<script>

export default {
  name: 'CrumbSection',
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  computed: {
  },
  methods: {
    scrollListToSelectedLink() {
      if (!this.$refs.roleLink && !this.$refs.wlist) {
        return;
      }

      this.$refs.roleLink.forEach(el => {
        const isSelected = el.$el.classList.contains('selected');

        if (!isSelected) {
          return;
        }

        const selectedLinkEl = el.$el;
        const wListEl = this.$refs.wlist.$el;

        wListEl.scrollTop = (selectedLinkEl.offsetTop - 100);
      });
    }

  },
  mounted() {
    this.scrollListToSelectedLink();
  }
};
</script>

<style lang="css" scoped>
.crumb {
  display: flex;
}

.icon {
  color: var(--breadcrumb-accent-color);
  padding: 0 4px;
}

.list-wrapper {
  min-width: 260px;
  max-width: 260px;
  padding: 20px 24px;
  background-color: var(--breadcrumb-layout-bg-color);
  border: 1px solid var(--breadcrumb-border-color);
  border-radius: 4px;
  position: relative;
}

.link-list {
  padding: 0 4px 0 16px;
}

.role-section {
  display: flex;
  align-items: center;
  gap: 4px;
  margin: 0 0 8px 0;
}

.heading {
  font-size: 16px;
  font-weight: 400;
  line-height: 1.2;
  margin: 0;
}

.selectable {
  position: relative;
  z-index: 1;
}

.selectable.selected::before {
  content: '';
  display: inline-block;
  width: 100%;
  height: 100%;
  background-color: var(--brand-success);
  opacity: .5;
  border-radius: 8px;
  position: absolute;
  left: 0;
  top: 0;
  z-index: -1;
}

.selectable.selected::after {
  content: '';
  display: inline-block;
  width: 4px;
  height: 60%;
  background-color: var(--brand-success);
  opacity: 1;
  border-radius: 8px;
  position: absolute;
  left: 0%;
  top: 50%;
  transform: translateY(-50%);
  z-index: -1;
}

a:hover,
a:focus {
  text-decoration: none;
}

.icon-item.selected {
  color: var(--brand-success);
}

.menu-item {
  padding: 2px 8px;
  width: 100%;
  display: flex;
  align-items: center;
  gap: 4px;
  position: relative;
  z-index: 1;
  transition: var(--transition);
}

.menu-item:hover>.text-content {
  opacity: var(--opacity);
}

.menu-item.color {
  color: var(--breadcrumb-font-color);
}

.role-link.color {
  color: color-mix(in srgb, var(--breadcrumb-font-color) 80%, transparent);
}

.text-content {
  color: inherit;
  flex: 1 1 auto;
  text-align: start;

  transition: var(--transition);

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
