<template>
  <section>
    <div class="interface-block mb-1" v-if="currentCpeConfig.firewall">
      <div class="setting-block-header" @click.prevent="showFirewall">
        <div class="setting-block-status">
          <p class="h6 mb-h pr-h setting-block-title">{{ $t('accessControl.accessControl') }}</p>
          <div v-if="currentCpeConfig">
            <span v-if="currentCpeConfig.firewall.l2_chain" class="badge mr-q badge-warning">
              {{ $t('accessControl.firewall') }}
            </span>

            <span v-if="currentCpeConfig.firewall.wan_access_block" class="badge mr-q badge-warning">
              {{ $t('accessControl.wanBlockAccess') }}
            </span>
            <span
              v-if="
                currentCpeConfig.firewall.l3_filter &&
                currentCpeConfig.firewall.l3_filter.mac_list &&
                currentCpeConfig.firewall.l3_filter.mac_list.length
              "
              class="badge mr-q badge-success"
            >
              L3 MAC
            </span>
            <span
              v-if="
                currentCpeConfig.firewall.l3_filter &&
                currentCpeConfig.firewall.l3_filter.ip_list &&
                currentCpeConfig.firewall.l3_filter.ip_list.length
              "
              class="badge mr-q badge-success"
            >
              L3 IP
            </span>
          </div>
        </div>
        <div class="setting-block-buttons">
          <WButton link v-if="!isEditBlockShow">
            <i class="icon-arrow-left"></i>
          </WButton>
          <WButton link v-if="isEditBlockShow">
            <i class="icon-arrow-down"></i>
          </WButton>
        </div>
      </div>
      <transition name="interface">
        <div class="mt-1" v-if="isEditBlockShow">
          <div class="form-group">
            <label for="l2_chain">{{ $t('accessControl.firewall') }}</label>
            <select
              v-model="updatedCpeConfig.firewall.l2_chain"
              name="l2_chain"
              id="l2_chain"
              :class="{ 'select-disabled': isDisable }"
              class="form-control"
              :disabled="isDisable"
            >
              <option :value="''">{{ $t('misc.none') }}</option>
              <option v-for="firewall in firewallsList" :value="firewall.id" :key="firewall.id">
                {{ commonService.firewallNamebyId(firewall.id) }}
              </option>
            </select>
          </div>

          <div class="form-group mb-h mt-2" v-if="updatedCpeConfig.firewall.l3_filter">
            <label for="l3_mac">MAC {{ $t('general.address') }}</label>
            <div class="input-group">
              <input
                :class="{ input: true, 'is-danger': errors.has('l3_mac') }"
                :disabled="isDisable"
                @input="enableSaveChanges = true"
                @keyup.13="addL3MacAddress"
                class="form-control"
                data-vv-validate-on="input"
                id="l3_mac"
                name="l3_mac"
                placeholder="00:00:00:00:00:00"
                type="text"
                v-model="newL3MacAddress"
                v-validate="'mac'"
              />
              <span class="input-group-btn">
                <WButton success customClass="mb-0"
                  @click="addL3MacAddress"
                  :disabled="this.newL3MacAddress == '' || errors.has('l3_mac')"
                >
                  {{ $t('general.add') }}
                </WButton>
              </span>
            </div>
          </div>
          <div class="ml-h mb-q" v-if="firewallL3List.mac_list.length">
            <small>
              <strong>
                {{ $t('misc.current') }} {{ $t('misc.items').toLowerCase() }}: {{ firewallL3List.mac_list.length }}
              </strong>
            </small>
          </div>
          <ul class="items_list p-0 ml-h mb-2">
            <li v-for="(mac, index) in firewallL3List.mac_list" :data-index="index" :key="mac">
              <small :class="{ 'item-for-delete': !isDisable }" @click="removeL3Mac" class="text-uppercase">
                {{ mac }}
                <i v-if="!isDisable" class="fa fa-remove text-danger"></i>
              </small>
            </li>
          </ul>

          <div class="form-group mb-h mt-1" v-if="updatedCpeConfig.firewall.l3_filter">
            <label for="l3_ip">IP {{ $t('general.address') }}</label>
            <div class="input-group">
              <input
                type="text"
                :disabled="isDisable"
                name="l3_ip"
                class="form-control"
                placeholder="0.0.0.0"
                id="l3_ip"
                @input="enableSaveChanges = true"
                v-validate="'ipv4'"
                @keyup.13="addL3IPAddress"
                data-vv-validate-on="input"
                v-model="newL3IPAddress"
                :class="{ input: true, 'is-danger': errors.has('l3_ip') }"
              />
              <span class="input-group-btn">
                <WButton success customClass="mb-0"
                  @click="addL3IPAddress"
                  :disabled="this.newL3IPAddress == '' || errors.has('l3_ip')"
                >
                  {{ $t('general.add') }}
                </WButton>
              </span>
            </div>
          </div>
          <div class="ml-h mb-q" v-if="firewallL3List.ip_list.length">
            <small>
              <strong>
                {{ $t('misc.current') }} {{ $t('misc.items').toLowerCase() }}: {{ firewallL3List.ip_list.length }}
              </strong>
            </small>
          </div>
          <ul class="items_list p-0 ml-h mb-2">
            <li v-for="(ip, index) in firewallL3List.ip_list" :key="ip" :data-index="index">
              <small :class="{ 'item-for-delete': !isDisable }" @click="removeL3IP" class="text-uppercase">
                {{ ip }}
                <i v-if="!isDisable" class="fa fa-remove text-danger"></i>
              </small>
            </li>
          </ul>

          <div class="form-group mb-1 mt-2">
            <Switch-component
              v-model="updatedCpeConfig.firewall.wan_access_block"
              :disabled="isDisable"
              :label="$t('accessControl.wanBlockAccess')"
              :id="'nat-access'"
            />
          </div>
        </div>
      </transition>
    </div>
  </section>
</template>

<script>
import Vue from 'vue';
import commonService from '../../services/commonService';
import SwitchComponent from '../Universal/Switch-component.vue';

export default {
  name: 'AccessControl',
  components: {
    SwitchComponent
  },
  inject: ['$validator'],
  props: {
    updatedCpeConfig: {
      type: Object
    },
    currentCpeConfig: {
      type: Object
    },
    firewallsList: {
      type: Array
    },
    firewallL3List: {
      type: Object
    },
    isConnected: {
      type: Boolean
    },
    isDisable: {
      type: Boolean
    },
    isOperator: {
      type: Boolean
    },
    isEditShow: {
      type: Boolean
    }
  },
  data() {
    return {
      isEditBlockShow: this.isEditShow,
      newL3IPAddress: '',
      newL3MacAddress: ''
      // firewallL3List: {
      //     ip_list: [],
      //     mac_list: [],
      // },
    };
  },
  watch: {},
  computed: {
    commonService() {
      return commonService;
    }
  },
  methods: {
    showFirewall() {
      this.isEditBlockShow = !this.isEditBlockShow;
    },
    addL3MacAddress() {
      this.$validator.validateAll({ l3_mac: this.newL3MacAddress }).then((result) => {
        if (result) {
          if (this.firewallL3List.mac_list.indexOf(this.newL3MacAddress) == -1 && this.newL3MacAddress) {
            this.firewallL3List.mac_list.push(this.newL3MacAddress);
          } else {
            return;
          }
          this.newL3MacAddress = '';
          this.$validator.reset();
        }
      });
    },
    addL3IPAddress() {
      this.$validator.validateAll({ l3_ip: this.newL3IPAddress }).then((result) => {
        if (result) {
          if (this.firewallL3List.ip_list.indexOf(this.newL3IPAddress) == -1 && this.newL3IPAddress) {
            this.firewallL3List.ip_list.push(this.newL3IPAddress);
          } else {
            return;
          }
          this.newL3IPAddress = '';
          this.$validator.reset();
        }
      });
    },
    removeL3Mac(e) {
      if (this.isDisable) {
        return;
      }
      const selectedNode = e.target.closest('[data-index]');
      const { index } = selectedNode.dataset;
      this.firewallL3List.mac_list.splice(index, 1);
    },
    removeL3IP(e) {
      if (this.isDisable) {
        return;
      }
      const selectedNode = e.target.closest('[data-index]');
      const { index } = selectedNode.dataset;
      this.firewallL3List.ip_list.splice(index, 1);
    }
  },
  created() {}
};
</script>

<style lang="scss" scoped></style>
