<template>
    <form
      class="branch-profile-vlan-form"
      novalidate
      @submit.prevent="handleSubmit"
      @reset.prevent="handleReset"
    >
      <div class="form-content-wrapper">
        <div v-for="(content, index) in formConfig[0].content"
          class="form-content-section"
          :key="index"
        >
          <p v-if="content.label"
            class="form-content-section-label"
          >
            {{ content.label }}
          </p>
          <div v-for="input in content.inputs"
            :key="input.id"
          >
            <template v-if="input.inputType === 'radio'">
              <WRadio
                :label="input.label"
                :name="input.name"
                :id="input.id"
                :value="input.radioValue"
                @input="handleRadio(input, $event)"
                :checked="formState[input.name] === input.radioValue"
              />
            </template>
            <template v-else-if="input.name === 'aclIngressVlaue' || input.name === 'aclEgressValue'">
              <WSelect
                v-if="formState['aclType'] === 'unidirectional'"
                withInitialOption
                :options="input.selectOptions"
                :label="input.label"
                :name="input.name"
                :id="input.name"
                :value="formState[input.name]"
                @input="handleSelect(input.name, $event)"
                :disabled="input.isDisabled"
                v-validate.immediate="input.validateRules"
                data-vv-validate-on="input"
                :data-vv-as="input.label"
                :errorText="errors.first(input.name)"
              />
            </template>
            <template v-else-if="input.name === 'aclValue'">
              <WSelect
                v-if="formState['aclType'] === 'bidirectional'"
                withInitialOption
                :options="input.selectOptions"
                :label="input.label"
                :name="input.name"
                :id="input.name"
                :value="formState[input.name]"
                @input="handleSelect(input.name, $event)"
                :disabled="input.isDisabled"
                v-validate="input.validateRules"
                data-vv-validate-on="input"
                :data-vv-as="input.label"
                :errorText="errors.first(input.name)"
              />
            </template>
            <template v-else-if="input.inputType === 'textWithSelect'">
              <TextWithSelectInput
                :inputConfig="input"
                :value="formState[input.name]"
                @updateValue="handleTextWithSelectInput"
              />
            </template>
            <template v-else>
              <WInput
                :key="input.id"
                :type="input.inputType"
                :label="input.label"
                :value="formState[input.name]"
                @input="handleInput(input, $event)"
                :id="input.id"
                :name="input.name"
                :placeholder="input.placeholder"
                :disabled="input.isDisabled"
                v-validate="input.validateRules"
                data-vv-validate-on="input"
                :data-vv-as="input.label"
                :errorText="errors.first(input.name)"
                autocomplete="one-time-code"
              />
            </template>
          </div>
        </div>
      </div>
      <div>
        <slot name="control-buttons"></slot>
      </div>
    </form>
</template>

<script>
/**
 * локальный компонент формы добавления VLAN для branch-profile.
 * Представляет собой обертку-интерфейс с настроенными разметкой, формой, генератором стейта формы и валидацей.
 * по FSD это слой feature https://feature-sliced.design/ru/docs/get-started/overview#layers
 * @component
 */

import {
  createFormState
} from './utils';

import {
  getVLANFormValidators
} from './validators';

import {
  TextWithSelectInput
} from './components';

export default {
  name: 'VLANForm',
  components: {
    TextWithSelectInput
  },
  props: {
    formConfig: {
      type: Array,
      required: true
    },
    forbiddenVlanNames: {
      type: Array,
      default: () => []
    },
    forbiddenVlanIds: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      formState: {}
    };
  },
  methods: {
    handleInput(inputObj, value) {
      const { name, inputType } = inputObj;

      // из-за особенностей архитектуры и невозможности использовать здесь v-modal, форматировать number приходится так
      let correctFormatValue = value;

      if (inputType === 'number') {
        correctFormatValue = value === '' ? '' : Number(correctFormatValue);
      }

      this.formState = {
        ...this.formState,
        [name]: correctFormatValue
      };
    },
    handleRadio(inputObj, value) {
      const { name } = inputObj;

      if (name === 'aclType' && value === 'bidirectional') {
        this.resetBidirectionalAclValues();
      }

      if (name === 'aclType' && value === 'unidirectional') {
        this.resetUnidirectionalAclValues();
      }

      this.formState = {
        ...this.formState,
        [name]: value
      };
    },
    resetBidirectionalAclValues() {
      // в зависимых от значения радио aclType нужно вручную обнулять значения из-за структуры кода и vee-validate v2
      this.formState = {
        ...this.formState,
        aclIngressVlaue: '',
        aclEgressValue: ''
      };

      // сброс состояния валидации с удаленных полей и ошибок в частности
      // https://vee-validate.logaretm.com/v2/examples/scopes.html
      // https://vee-validate.logaretm.com/v2/api/validator.html#methods
      const matcher = {
        scope: '',
        vmId: this.$validator.id
      };

      this.$validator.reset(matcher);
    },
    resetUnidirectionalAclValues() {
      // в зависимых от значения радио aclType нужно вручную обнулять значения из-за структуры кода и vee-validate v2
      this.formState = {
        ...this.formState,
        aclValue: ''
      };

      // сброс состояния валидации с удаленных полей и ошибок в частности
      // https://vee-validate.logaretm.com/v2/examples/scopes.html
      // https://vee-validate.logaretm.com/v2/api/validator.html#methods
      const matcher = {
        scope: '',
        vmId: this.$validator.id
      };

      this.$validator.reset(matcher);
    },
    handleTextWithSelectInput({ name, value }) {
      this.formState = {
        ...this.formState,
        [name]: value
      };

      // нужно запустить валидацию поля императивно тк селекты в данном случае не инпут
      this.$nextTick(() => {
        // Запускаем валидацию поля после обновления состояния
        this.$validator.validate(name);
      });
    },
    handleSelect(name, value) {
      this.formState = {
        ...this.formState,
        [name]: value
      };
    },
    async validateForm() {
      // vee-validate не может провалидировать все поля разом включая те, которые в скоупах
      // по умолчанию this.$validator.validateAll() валидирует только те поля, которые без скоупа
      // this.$validator.validateAll('scopeName') - валидирует все поля конкретного скоупа
      // необходимо получить все скоупы и провалидировать все поля независимо от скоупов

      function getAllVeeValidateScopes(veeValidateValidator) {
        // Извлекаем все уникальные скоупы из vee-validator fields
        const allFieldsScopes = veeValidateValidator.fields.items.map(field => field.scope);

        const scopes = Array.from(new Set(allFieldsScopes));
        return scopes;
      }

      const allVeeValidateScopes = getAllVeeValidateScopes(this.$validator);

      // Создаем массив промисов для валидации всех скоупов
      const validationPromises = allVeeValidateScopes.map(scopeName => {
        return this.$validator.validateAll(scopeName);
      });

      const validationResults = await Promise.all(validationPromises);

      // Проверяем, все ли промисы вернули true
      const isFormValid = validationResults.every(result => result);

      return isFormValid;
    },
    async handleSubmit() {
      const isFormValid = await this.validateForm();

      if (!isFormValid) {
        return;
      }

      const {
        vlanName,
        vlanId,
        aclType,
        aclValue,
        aclIngressVlaue,
        aclEgressValue
      } = this.formState;

      this.$emit('submit', {
        vlanName,
        vlanId,
        aclIngress: aclType === 'bidirectional' ? aclValue : aclIngressVlaue,
        aclEgress: aclType === 'bidirectional' ? aclValue : aclEgressValue
      });

      this.handleReset();
    },
    handleReset() {
      const formState = createFormState(this.formConfig);

      this.formState = formState;
    },
    setVlanFormValidators() {
      const validators = getVLANFormValidators(this.$i18n);

      const uniqueVlanName = validators.uniqueVlanName(this.forbiddenVlanNames);
      const vlanNameMask = validators.vlanNameMask();
      const uniqueVlanId = validators.uniqueVlanId(this.forbiddenVlanIds);

      const validator = this.$validator;

      validator.extend('uniqueVlanName', uniqueVlanName);
      validator.extend('vlanNameMask', vlanNameMask);
      validator.extend('uniqueVlanId', uniqueVlanId);
    }
  },
  mounted() {
    this.setVlanFormValidators();
  },
  created() {
    const formState = createFormState(this.formConfig);
    this.formState = formState;
  }
};
</script>

<style lang="css" scoped>
.branch-profile-vlan-form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 16px;
}

.form-content-wrapper {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.form-content-section {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.form-content-section-label {
  margin: 0;
  font-size: 1.1rem;
}
</style>
