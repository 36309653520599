import { v4 as uuidv4 } from 'uuid';

/**
 * Описание ролей:
 * роли нужны для построения иерархичности + для специфичного отображения элементов в соответствии с ролью.
 *
 * root - это начало категории. Может быть ссылкой без вложенных элементов, так и не ссылкой с вложенными элементами.
 * section - подраздел. Не является ссылкой. Имеет вложенные элементы children, которые являются ссылкой (обычно role: link). Используется как группировка раздела ссылок и отображается как заголовок.
 * link - конечный элемент пути - ссылка. Не имеет children
 * @param {'root' | 'section' | 'link'} role
 */

const getDashboard = ({ $t, thisInstance, $can }) => (
  {
    id: uuidv4(),
    name: 'dashboard',
    title: $t('sidebarNew.dashboard'),
    icon: 'fa fa-home fa-fw fa-lg',
    to: '/dashboard',
    selected: false,
    role: 'root',
    isShow: $can('read', { route: 'Dashboard' }) && !thisInstance.isPortalOnlyModeEnabled,
    children: []
  }
);

export default getDashboard;
