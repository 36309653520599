<template>
  <div>
    <div v-if="isCpeLatestStatsRequestExecuting" class="spinner-wrapper">
      <loader-spinner centered></loader-spinner>
    </div>
    <div v-else>
      <div class="error-wrapper d-flex justify-content-center align-items-center" v-if="isErrorFromResponse">
        <div>
          {{ getErrorText() }}
        </div>
      </div>
      <div v-else>
        <div class="form-group mt-2 d-flex flex-column align-items-center justify-content-center">
          <div class="chart-title-wrapper mb-0">
            <h5>{{ $t('aps.showCurrentStatsUptimeCaption') }}</h5>
          </div>
          <div class="chart-title-wrapper mt-0">
            <span>{{ lastByTimestampUptimeAsString }}</span>
            <span class="text-muted">({{ lastByTimestampUptime }} {{ $t('general.secShort') }})</span>
          </div>
        </div>

        <div
          v-if="isEth0StateInfoExisting"
          class="form-group mt-1 d-flex flex-column align-items-center justify-content-center"
        >
          <div class="chart-title-wrapper mb-0">
            <h5 class="">{{ $t('aps.eth0StateCaption') }}</h5>
          </div>
          <div v-if="isShowEth0StateInfo" class="chart-title-wrapper mt-0">
            {{ lastByTimestampEth0State.operstate }}({{ lastByTimestampEth0State.speed }} /
            {{ lastByTimestampEth0State.duplex }}) ({{ lastByTimestampEth0State.carrier_changes }}
            {{ getSwitchingTimesCaption(lastByTimestampEth0State.carrier_changes) }}
            {{ $t('aps.eth0StateSinceRebootCaption') }})
          </div>
          <div v-if="!isShowEth0StateInfo" class="chart-title-wrapper mt-0">
            <span class="text-muted">{{ $t('aps.eth0IsOff') }}</span>
          </div>
        </div>

        <div class="form-group mt-3 d-flex flex-column align-items-center justify-content-center">
          <div class="chart-title-wrapper">
            <h5>{{ $t('statistics.cpuLoadChartTitle') }}</h5>
          </div>
          <!--          cpu chart-->
          <div class="col-lg-12 mt-2">
            <highcharts :options="cpuChart" id="cpuCharts" ref="cpuChart"></highcharts>
            <save-png-button
              class="save-icon color-brand save-icon-in-stat-cpe-latest"
              id="cpuCharts"
              :name="`${cpeName}-CPU-Load-Chart(${moment(new Date()).format('lll')})`"
            />
          </div>
        </div>
        <div class="form-group mt-2 mt-2 d-flex flex-column align-items-center justify-content-center">
          <div class="chart-title-wrapper">
            <h5>{{ $t('aps.showCurrentStatsRamChartChartTitle') }}</h5>
          </div>
          <!--          ram chart  memory chart-->
          <div class="col-lg-12">
            <highcharts :options="ramChart" id="ramChart" ref="ramChart"></highcharts>
            <SavePngButton
              class="save-icon color-brand save-icon-in-stat-cpe-latest"
              id="ramChart"
              :name="`${cpeName}-RAM-Chart(${moment(new Date()).format('lll')})`"
            />
          </div>
        </div>
        <div class="form-group mt-2 d-flex flex-column align-items-center justify-content-center">
          <div class="chart-title-wrapper">
            <h5>{{ $t('aps.showCurrentStatsInterfacesHeader') }}</h5>
          </div>
          <div class="col-12 cpe-stat-latest-interface-charts-no-padding">
            <div class="row">
              <div class="col-lg-2">
                <div class=" ">
                  <table class="table">
                    <thead>
                      <tr>
                        <th class="text-center">{{ $t('aps.showCurrentStatsInterfacesInterfaceNameTableCaption') }}</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        @click="selectInterface(interfaceName)"
                        :class="{ 'table-active': interfaceName === selectedInterfaceName }"
                        v-for="interfaceName in interfacesNames"
                      >
                        <td class="interfaces-names-in-table">{{ interfaceName }}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div class="">
                  <table class="table">
                    <thead>
                      <tr>
                        <th class="text-center">{{ $t('aps.showCurrentStatsUnits') }}</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        @click="selectUnits(unitsKey)"
                        :class="{ 'table-active': unitsKey === selectedUnits }"
                        v-for="(unitsName, unitsKey) in units"
                      >
                        <td class="interfaces-names-in-table">{{ unitsName }}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div class="col-lg-10">
                <table class="table">
                  <tbody>
                    <tr class="tr-in-charts-for-interfaces-table">
                      <td class="cpe-stat-latest-interface-charts-no-padding"></td>
                    </tr>
                  </tbody>
                </table>

                <div class="mt-1 d-flex flex-column align-items-center">
                  <!--                        <div class="mb-1 chart-title-wrapper"><h6>{{$t('aps.showCurrentStatsInterfacesInterfaceTableHeaderCaption')}}: {{selectedInterfaceName}}</h6></div>-->
                  <!--                        tx/rx chart-->
                  <div
                    class="col-lg-12 d-flex flex-column align-items-center justify-content-center cpe-stat-latest-interface-charts-no-padding"
                  >
                    <transition name="interfaceschartstransition">
                      <div v-if="showInterfacesChartsTitle" class="chart-title-wrapper">
                        <h5>
                          {{ $t('aps.showCurrentStatsInterfacesTxRxChartTitle') }}
                          <span class="interface-name-in-chart-title">{{ selectedInterfaceName }}</span>
                        </h5>
                      </div>
                    </transition>
                    <div class="col-lg-12">
                      <highcharts :options="txRxChart" id="txRxChart" ref="txRxChart"></highcharts>
                      <SavePngButton
                        class="save-icon color-brand save-icon-in-stat-cpe-latest"
                        id="txRxChart"
                        :name="`${cpeName}-TX-RX-Chart(${moment(new Date()).format('lll')})`"
                      />
                    </div>
                  </div>
                  <!--                        tx/rx speed chart-->
                  <div
                    class="mt-3 col-lg-12 d-flex flex-column align-items-center justify-content-center cpe-stat-latest-interface-charts-no-padding"
                  >
                    <transition name="interfaceschartstransition">
                      <div v-if="showInterfacesChartsTitle" class="chart-title-wrapper">
                        <h5>
                          {{ $t('aps.showCurrentStatsInterfacesTxRxSpeedChartTitle') }}
                          <span class="interface-name-in-chart-title">{{ selectedInterfaceName }}</span>
                        </h5>
                      </div>
                    </transition>
                    <div class="col-lg-12">
                      <highcharts :options="txRxSpeedChart" id="txRxSpeedChart" ref="txRxSpeedChart"></highcharts>
                      <SavePngButton
                        class="save-icon color-brand save-icon-in-stat-cpe-latest"
                        id="txRxSpeedChart"
                        :name="`${cpeName}-TX-RX-Speed-Chart(${moment(new Date()).format('lll')})`"
                      />
                    </div>
                  </div>
                  <!--                        tx/rx delta chart-->
                  <div
                    class="mt-3 col-lg-12 d-flex flex-column align-items-center justify-content-center cpe-stat-latest-interface-charts-no-padding"
                  >
                    <transition name="interfaceschartstransition">
                      <div v-if="showInterfacesChartsTitle" class="chart-title-wrapper">
                        <h5>
                          {{ $t('aps.showCurrentStatsInterfacesTxRxDeltaChartTitle') }}
                          <span class="interface-name-in-chart-title">{{ selectedInterfaceName }}</span>
                        </h5>
                      </div>
                    </transition>
                    <div class="col-lg-12">
                      <highcharts :options="txRxDeltaChart" id="txRxDeltaChart" ref="txRxDeltaChart"></highcharts>
                      <SavePngButton
                        class="save-icon color-brand save-icon-in-stat-cpe-latest"
                        id="txRxDeltaChart"
                        :name="`${cpeName}-TX-RX-Delta-Chart(${moment(new Date()).format('lll')})`"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!--        <pre>{{ sortedByTimeStatsDataForCPE }}</pre>-->
      </div>
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import statCpeLatestService from '../../services/statCpeLatestService';
import SavePngButton from '../save-png-button.vue';
// import Highcharts from 'highcharts';

const { mapGetters: localeMapGetters } = createNamespacedHelpers('locale');

export default {
  name: 'StatCpeLatest',
  components: {
    SavePngButton
  },
  data() {
    return {
      // используем чтобы добавить transition при выборе интерфейса (через v-if)
      showInterfacesChartsTitle: true,
      selectedInterfaceName: 'lan',
      units: {
        B: this.$t('aps.showCurrentStatsBytes'),
        KB: this.$t('aps.showCurrentStatsKbytes'),
        MB: this.$t('aps.showCurrentStatsMbytes'),
        GB: this.$t('aps.showCurrentStatsGbytes')
      },
      selectedUnits: 'MB',
      dataForCharts: {},
      rawDataFromGetCpeLatestStats: undefined,
      sortedByTimeStatsDataForCPE: undefined,
      errorFromResponse: '',
      // charts options and settings and data
      // cpu chart
      cpuChart: {
        time: {
          useUTC: false
        },
        chart: {
          type: 'area',
          height: '300',
          zoomType: 'x',
          panning: true,
          panKey: 'shift'
        },
        title: {
          // text: this.$t('statistics.cpuLoadChartTitle'),
          // x: -20 // center
          text: ''
        },
        plotOptions: {
          series: {
            fillOpacity: 0.5
          }
        },
        xAxis: {
          type: 'datetime',
          crosshair: {
            enabled: true
          }
        },
        yAxis: {
          title: {
            text: this.$t('statistics.cpuLoadChartAxis')
          },
          labels: {
            formatter() {
              return `${this.value}%`;
            }
          }
        },
        subtitle: {
          // text: this.$t('statistics.pan')
        },
        tooltip: {
          pointFormat: '<b>{point.y} %</b>',
          hideDelay: 50
        },
        series: [
          {
            name: this.$t('statistics.cpuLoadChartSeries'),
            color: '#E57373',
            data: [] // заменяется при вызове функции refreshAllChartSeries
          }
        ]
      },
      // ram chart  memory chart
      ramChart: {
        time: {
          useUTC: false
        },
        chart: {
          type: 'area',
          height: '300',
          zoomType: 'x',
          panning: true,
          panKey: 'shift'
        },
        title: {
          // text: this.$t('aps.showCurrentStatsRamChartChartTitle'),
          // x: -20 // center
          text: ''
        },
        plotOptions: {
          series: {
            fillOpacity: 0.5
          }
        },
        xAxis: {
          type: 'datetime',
          crosshair: {
            enabled: true
          }
        },
        yAxis: {
          title: {
            text: this.$t('aps.showCurrentStatsRamChartYAxisTitle')
          },
          labels: {
            // formatter() {
            //   return `${this.value} МБ`;
            // }
            // formatter: (label) => {
            //   return `${Highcharts.Axis.prototype.defaultLabelFormatter.call(label)} ${this.$t('aps.showCurrentStatsRamChartTooltip')}`;
            // }
            formatter: (label) =>
              // console.log (label)
              `${label.value} ${this.$t('aps.showCurrentStatsRamChartTooltip')}`

          }
        },
        subtitle: {
          // text: this.$t('statistics.pan')
        },
        tooltip: {
          pointFormat: `{series.name}: <b>{point.y} ${this.$t('aps.showCurrentStatsRamChartTooltip')}</b>`,
          split: true,
          distance: 30,
          padding: 5,
          hideDelay: 50
        },
        series: [
          {
            name: this.$t('aps.showCurrentStatsRamChartTotalRam'),
            color: '#a5cbe5',
            data: [] // заменяется при вызове функции refreshAllChartSeries
          },
          {
            name: this.$t('aps.showCurrentStatsRamChartFreeRam'),
            color: '#6ee56f',
            data: [] // заменяется при вызове функции refreshAllChartSeries
          }
        ]
      },

      // interface charts
      // tx/rx chart
      txRxChart: {
        time: {
          useUTC: false
        },
        chart: {
          type: 'line',
          height: '300',
          zoomType: 'x',
          panning: true,
          panKey: 'shift'
        },
        title: {
          // text: this.$t('aps.showCurrentStatsRamChartChartTitle'),
          // x: -20 // center
          text: ''
        },
        plotOptions: {
          series: {
            fillOpacity: 0.5
          }
        },
        xAxis: {
          type: 'datetime',
          crosshair: {
            enabled: true
          }
        },
        yAxis: {
          title: {
            text: this.$t('aps.showCurrentStatsInterfacesTxRxChartYAxisTitleB')
          },
          labels: {
            // formatter() {
            //   return `${this.value} МБ`;
            // }
            // formatter: (label) => {
            //   return `${Highcharts.Axis.prototype.defaultLabelFormatter.call(label)} ${this.$t('aps.showCurrentStatsRamChartTooltip')}`;
            // }
            // formatter: (label) => {
            //   // console.log (label)
            //   return `${label.value} ${this.$t('aps.showCurrentStatsInterfacesTxRxChartTooltipB')}`;
            // }
            formatter: (label) => {
              // заполянется при вызове функции refreshInterfacesChartUnitsCaptions
            }
          }
        },
        subtitle: {
          // text: this.$t('statistics.pan')
        },
        tooltip: {
          // pointFormat: `{series.name}: <b>{point.y} ${this.$t('aps.showCurrentStatsInterfacesTxRxChartTooltipB')}</b>`,
          pointFormat: '', // заполянется при вызове функции refreshInterfacesChartUnitsCaptions
          split: true,
          distance: 30,
          padding: 5,
          hideDelay: 50
        },
        series: [
          {
            name: this.$t('aps.showCurrentStatsInterfacesTxRxChartTx'),
            color: '#a5cbe5',
            data: [] // заменяется при вызове функции refreshAllChartSeries и refreshInterfacesChartSeries
          },
          {
            name: this.$t('aps.showCurrentStatsInterfacesTxRxChartRx'),
            color: '#e58783',
            data: [] // заменяется при вызове функции refreshAllChartSeries и refreshInterfacesChartSeries
          }
        ]
      },
      // tx/rx speed chart
      txRxSpeedChart: {
        time: {
          useUTC: false
        },
        chart: {
          type: 'line',
          height: '300',
          zoomType: 'x',
          panning: true,
          panKey: 'shift'
        },
        title: {
          // text: this.$t('aps.showCurrentStatsRamChartChartTitle'),
          // x: -20 // center
          text: ''
        },
        plotOptions: {
          series: {
            fillOpacity: 0.5
          }
        },
        xAxis: {
          type: 'datetime',
          crosshair: {
            enabled: true
          }
        },
        yAxis: {
          title: {
            text: this.$t('aps.showCurrentStatsInterfacesTxRxSpeedChartYAxisTitleB')
          },
          labels: {
            // formatter() {
            //   return `${this.value} МБ`;
            // }
            // formatter: (label) => {
            //   return `${Highcharts.Axis.prototype.defaultLabelFormatter.call(label)} ${this.$t('aps.showCurrentStatsRamChartTooltip')}`;
            // }
            // formatter: (label) => {
            //   // console.log (label)
            //   return `${label.value} ${this.$t('aps.showCurrentStatsInterfacesTxRxSpeedChartTooltipB')}`;
            // }
            formatter: (label) => {
              // заполянется при вызове функции refreshInterfacesChartUnitsCaptions
            }
          }
        },
        subtitle: {
          // text: this.$t('statistics.pan')
        },
        tooltip: {
          // pointFormat: `{series.name}: <b>{point.y} ${this.$t('aps.showCurrentStatsInterfacesTxRxSpeedChartTooltipB')}</b>`,
          pointFormat: '', // заполянется при вызове функции refreshInterfacesChartUnitsCaptions
          split: true,
          distance: 30,
          padding: 5,
          hideDelay: 50
        },
        series: [
          {
            name: this.$t('aps.showCurrentStatsInterfacesTxRxSpeedChartTx'),
            color: '#a5cbe5',
            data: [] // заменяется при вызове функции refreshAllChartSeries и refreshInterfacesChartSeries
          },
          {
            name: this.$t('aps.showCurrentStatsInterfacesTxRxSpeedChartRx'),
            color: '#e58783',
            data: [] // заменяется при вызове функции refreshAllChartSeries и refreshInterfacesChartSeries
          }
        ]
      },
      // tx/rx delta chart
      txRxDeltaChart: {
        time: {
          useUTC: false
        },
        chart: {
          type: 'line',
          height: '300',
          zoomType: 'x',
          panning: true,
          panKey: 'shift'
        },
        title: {
          // text: this.$t('aps.showCurrentStatsRamChartChartTitle'),
          // x: -20 // center
          text: ''
        },
        plotOptions: {
          series: {
            fillOpacity: 0.5
          }
        },
        xAxis: {
          type: 'datetime',
          crosshair: {
            enabled: true
          }
        },
        yAxis: {
          title: {
            text: this.$t('aps.showCurrentStatsInterfacesTxRxDeltaChartYAxisTitleB')
          },
          labels: {
            // formatter() {
            //   return `${this.value} МБ`;
            // }
            // formatter: (label) => {
            //   return `${Highcharts.Axis.prototype.defaultLabelFormatter.call(label)} ${this.$t('aps.showCurrentStatsRamChartTooltip')}`;
            // }
            // formatter: (label) => {
            //   // console.log (label)
            //   return `${label.value}`;
            // }
            formatter: (label) => {
              // заполянется при вызове функции refreshInterfacesChartUnitsCaptions
            }
          }
        },
        subtitle: {
          // text: this.$t('statistics.pan')
        },
        tooltip: {
          // pointFormat: `{series.name}: <b>{point.y} ${this.$t('aps.showCurrentStatsInterfacesTxRxDeltaChartTooltipB')}</b>`,
          pointFormat: '', // заполянется при вызове функции refreshInterfacesChartUnitsCaptions
          split: true,
          distance: 30,
          padding: 5,
          hideDelay: 50
        },
        series: [
          {
            name: this.$t('aps.showCurrentStatsInterfacesTxRxDeltaChartTx'),
            color: '#a5cbe5',
            data: [] // заменяется при вызове функции refreshAllChartSeries и refreshInterfacesChartSeries
          },
          {
            name: this.$t('aps.showCurrentStatsInterfacesTxRxDeltaChartRx'),
            color: '#e58783',
            data: [] // заменяется при вызове функции refreshAllChartSeries и refreshInterfacesChartSeries
          }
        ]
      }
    };
  },
  props: {
    cpeId: {
      type: String,
      required: true
    },
    cpeName: {
      type: String,
      required: false,
      default: ''
    }
  },
  computed: {
    ...localeMapGetters(['isRuLocale']),
    interfacesNames() {
      try {
        return this.dataForCharts.interfacesNames;
      } catch (e) {
        return [];
      }
    },
    allInterfacesStatData() {
      try {
        return this.dataForCharts.interfaces;
      } catch (e) {
        return [];
      }
    },
    seriesForCpuChart() {
      try {
        return this.dataForCharts.cpu;
      } catch (e) {
        return [];
      }
    },
    seriesForRAMChartTotal() {
      try {
        return this.dataForCharts.ram.total;
      } catch (e) {
        return [];
      }
    },
    seriesForRAMChartFree() {
      try {
        return this.dataForCharts.ram.free;
      } catch (e) {
        return [];
      }
    },
    seriesForTxRxChartTx() {
      try {
        const { selectedInterfaceName } = this;
        const units = this.selectedUnits;
        return this.dataForCharts.interfaces[selectedInterfaceName].tx.map((seriesItem) => [seriesItem[0], statCpeLatestService.bytesConvert(seriesItem[1], units)]);
      } catch (e) {
        return [];
      }
    },
    seriesForTxRxChartRx() {
      try {
        const { selectedInterfaceName } = this;
        const units = this.selectedUnits;
        return this.dataForCharts.interfaces[selectedInterfaceName].rx.map((seriesItem) => [seriesItem[0], statCpeLatestService.bytesConvert(seriesItem[1], units)]);
      } catch (e) {
        return [];
      }
    },
    seriesForTxRxSpeedChartTx() {
      try {
        const { selectedInterfaceName } = this;
        const units = this.selectedUnits;
        return this.dataForCharts.interfaces[selectedInterfaceName].tx_speed.map((seriesItem) => [seriesItem[0], statCpeLatestService.bytesConvert(seriesItem[1], units)]);
      } catch (e) {
        return [];
      }
    },
    seriesForTxRxSpeedChartRx() {
      try {
        const { selectedInterfaceName } = this;
        const units = this.selectedUnits;
        return this.dataForCharts.interfaces[selectedInterfaceName].rx_speed.map((seriesItem) => [seriesItem[0], statCpeLatestService.bytesConvert(seriesItem[1], units)]);
      } catch (e) {
        return [];
      }
    },
    seriesForTxRxDeltaChartTx() {
      try {
        const { selectedInterfaceName } = this;
        const units = this.selectedUnits;
        return this.dataForCharts.interfaces[selectedInterfaceName].tx_delta.map((seriesItem) => [seriesItem[0], statCpeLatestService.bytesConvert(seriesItem[1], units)]);
      } catch (e) {
        return [];
      }
    },
    seriesForTxRxDeltaChartRx() {
      try {
        const { selectedInterfaceName } = this;
        const units = this.selectedUnits;
        return this.dataForCharts.interfaces[selectedInterfaceName].rx_delta.map((seriesItem) => [seriesItem[0], statCpeLatestService.bytesConvert(seriesItem[1], units)]);
      } catch (e) {
        return [];
      }
    },
    isCpeLatestStatsRequestExecuting() {
      return this.$store.state.cpeLatestStatsRequestExecuting;
    },
    isErrorFromResponse() {
      if (this.errorFromResponse !== '') {
        return true;
      }
      return false;
    },
    lastByTimestampStatItem() {
      return this.sortedByTimeStatsDataForCPE[this.sortedByTimeStatsDataForCPE.length - 1];
    },
    lastByTimestampUptime() {
      return this.lastByTimestampStatItem.uptime;
    },
    lastByTimestampEth0State() {
      // последний по времени объект с состоянием eth0
      try {
        if (Object.prototype.hasOwnProperty.call(this.lastByTimestampStatItem, 'eth0_state')) {
          return this.lastByTimestampStatItem.eth0_state;
        }
        return {};
      } catch (e) {
        console.log(e);
        return {};
      }
    },
    isEth0StateInfoExisting() {
      // проверям не пустой ли объект с состоянием eth0
      try {
        if (
          typeof this.lastByTimestampEth0State === 'object' &&
          Object.keys(this.lastByTimestampEth0State).length > 0
        ) {
          return true;
        }
        return false;
      } catch (e) {
        console.log(e);
        return false;
      }
    },
    isShowEth0StateInfo() {
      // проверяем ввключен ли интерфейс ethernet
      try {
        if (
          typeof this.lastByTimestampEth0State === 'object' &&
          Object.prototype.hasOwnProperty.call(this.lastByTimestampEth0State, 'carrier') &&
          this.lastByTimestampEth0State.carrier === 1
        ) {
          return true;
        }
        return false;
      } catch (e) {
        console.log(e);
        return false;
      }
    },
    lastByTimestampUptimeAsString() {
      const day = this.$t('general.dayShort');
      const min = this.$t('general.minShort');
      const h = this.$t('general.hourShort');
      const sec = this.$t('general.secShort');
      return moment
        .duration(this.lastByTimestampUptime, 'seconds')
        .format(`D [${day}], h [${h}], mm [${min}], ss [${sec}]`);
    }
  },
  watch: {
    dataForCharts: {
      // смотрим за объектом, котрый обновляется из функции getCpeLatestStats в statCpeLatestService.js
      // как только обновился, прпихиваем новые серии, взятые оттуда в графики
      deep: true,
      handler(val) {
        // console.log('changed')
        // console.log(val)
        this.refreshAllChartSeries();
      }
    }
  },
  methods: {
    declOfNum(number, titles) {
      // для правильного окончания в слове переключение
      const cases = [2, 0, 1, 1, 1, 2];
      return titles[number % 100 > 4 && number % 100 < 20 ? 2 : cases[number % 10 < 5 ? number % 10 : 5]];
    },
    getSwitchingTimesCaption(switchingTimes) {
      try {
        if (this.isRuLocale) {
          return this.declOfNum(switchingTimes, ['переключение', 'переключения', 'переключений']);
        }
        if (switchingTimes === 1) {
          return 'switch';
        }
        return 'switches';
      } catch (e) {
        console.log(e);
        return '';
      }
    },
    selectUnits(unitsKey) {
      // console.log(unitsKey)
      this.selectedUnits = unitsKey;
      this.refreshInterfacesChartSeries();
    },
    selectInterface(interfaceName) {
      this.showInterfacesChartsTitle = false;
      this.selectedInterfaceName = interfaceName;
      this.$nextTick(() => {
        this.showInterfacesChartsTitle = true;
        this.refreshInterfacesChartSeries();
      });
    },
    refreshAllChartSeries() {
      // обновляем серии для всех графиков
      this.cpuChart.series[0].data = this.seriesForCpuChart;
      this.ramChart.series[0].data = this.seriesForRAMChartTotal;
      this.ramChart.series[1].data = this.seriesForRAMChartFree;
      this.refreshInterfacesChartSeries();
    },
    refreshInterfacesChartSeries() {
      // обновляем серии для графиков для интерефеса
      this.txRxChart.series[0].data = this.seriesForTxRxChartTx;
      this.txRxChart.series[1].data = this.seriesForTxRxChartRx;
      this.txRxSpeedChart.series[0].data = this.seriesForTxRxSpeedChartTx;
      this.txRxSpeedChart.series[1].data = this.seriesForTxRxSpeedChartRx;
      this.txRxDeltaChart.series[0].data = this.seriesForTxRxDeltaChartTx;
      this.txRxDeltaChart.series[1].data = this.seriesForTxRxDeltaChartRx;
      this.refreshInterfacesChartUnitsCaptions();
    },
    refreshInterfacesChartUnitsCaptions() {
      if (this.selectedUnits === 'MB') {
        this.txRxChart.yAxis.title.text = this.$t('aps.showCurrentStatsInterfacesTxRxChartYAxisTitleMB');
        this.txRxSpeedChart.yAxis.title.text = this.$t('aps.showCurrentStatsInterfacesTxRxSpeedChartYAxisTitleMB');
        this.txRxDeltaChart.yAxis.title.text = this.$t('aps.showCurrentStatsInterfacesTxRxDeltaChartYAxisTitleMB');

        this.txRxChart.tooltip.pointFormat = `{series.name}: <b>{point.y} ${this.$t(
          'aps.showCurrentStatsInterfacesTxRxChartTooltipMB'
        )}</b>`;
        this.txRxSpeedChart.tooltip.pointFormat = `{series.name}: <b>{point.y} ${this.$t(
          'aps.showCurrentStatsInterfacesTxRxSpeedChartTooltipMB'
        )}</b>`;
        this.txRxDeltaChart.tooltip.pointFormat = `{series.name}: <b>{point.y} ${this.$t(
          'aps.showCurrentStatsInterfacesTxRxDeltaChartTooltipMB'
        )}</b>`;

        this.txRxChart.yAxis.labels.formatter = (label) =>
          // console.log (label)
          `${label.value} ${this.$t('aps.showCurrentStatsInterfacesTxRxChartTooltipMB')}`;
        this.txRxSpeedChart.yAxis.labels.formatter = (label) =>
          // console.log (label)
          `${label.value} ${this.$t('aps.showCurrentStatsInterfacesTxRxSpeedChartTooltipMB')}`;
        this.txRxDeltaChart.yAxis.labels.formatter = (label) =>
          // console.log (label)
          `${label.value} ${this.$t('aps.showCurrentStatsInterfacesTxRxDeltaChartTooltipMB')}`;
      } else if (this.selectedUnits === 'KB') {
        this.txRxChart.yAxis.title.text = this.$t('aps.showCurrentStatsInterfacesTxRxChartYAxisTitleKB');
        this.txRxSpeedChart.yAxis.title.text = this.$t('aps.showCurrentStatsInterfacesTxRxSpeedChartYAxisTitleKB');
        this.txRxDeltaChart.yAxis.title.text = this.$t('aps.showCurrentStatsInterfacesTxRxDeltaChartYAxisTitleKB');

        this.txRxChart.tooltip.pointFormat = `{series.name}: <b>{point.y} ${this.$t(
          'aps.showCurrentStatsInterfacesTxRxChartTooltipKB'
        )}</b>`;
        this.txRxSpeedChart.tooltip.pointFormat = `{series.name}: <b>{point.y} ${this.$t(
          'aps.showCurrentStatsInterfacesTxRxSpeedChartTooltipKB'
        )}</b>`;
        this.txRxDeltaChart.tooltip.pointFormat = `{series.name}: <b>{point.y} ${this.$t(
          'aps.showCurrentStatsInterfacesTxRxDeltaChartTooltipKB'
        )}</b>`;

        this.txRxChart.yAxis.labels.formatter = (label) =>
          // console.log (label)
          `${label.value} ${this.$t('aps.showCurrentStatsInterfacesTxRxChartTooltipKB')}`;
        this.txRxSpeedChart.yAxis.labels.formatter = (label) =>
          // console.log (label)
          `${label.value} ${this.$t('aps.showCurrentStatsInterfacesTxRxSpeedChartTooltipKB')}`;
        this.txRxDeltaChart.yAxis.labels.formatter = (label) =>
          // console.log (label)
          `${label.value} ${this.$t('aps.showCurrentStatsInterfacesTxRxDeltaChartTooltipKB')}`;
      } else if (this.selectedUnits === 'GB') {
        this.txRxChart.yAxis.title.text = this.$t('aps.showCurrentStatsInterfacesTxRxChartYAxisTitleGB');
        this.txRxSpeedChart.yAxis.title.text = this.$t('aps.showCurrentStatsInterfacesTxRxSpeedChartYAxisTitleGB');
        this.txRxDeltaChart.yAxis.title.text = this.$t('aps.showCurrentStatsInterfacesTxRxDeltaChartYAxisTitleGB');

        this.txRxChart.tooltip.pointFormat = `{series.name}: <b>{point.y} ${this.$t(
          'aps.showCurrentStatsInterfacesTxRxChartTooltipGB'
        )}</b>`;
        this.txRxSpeedChart.tooltip.pointFormat = `{series.name}: <b>{point.y} ${this.$t(
          'aps.showCurrentStatsInterfacesTxRxSpeedChartTooltipGB'
        )}</b>`;
        this.txRxDeltaChart.tooltip.pointFormat = `{series.name}: <b>{point.y} ${this.$t(
          'aps.showCurrentStatsInterfacesTxRxDeltaChartTooltipGB'
        )}</b>`;

        this.txRxChart.yAxis.labels.formatter = (label) =>
          // console.log (label)
          `${label.value} ${this.$t('aps.showCurrentStatsInterfacesTxRxChartTooltipGB')}`;
        this.txRxSpeedChart.yAxis.labels.formatter = (label) =>
          // console.log (label)
          `${label.value} ${this.$t('aps.showCurrentStatsInterfacesTxRxSpeedChartTooltipGB')}`;
        this.txRxDeltaChart.yAxis.labels.formatter = (label) =>
          // console.log (label)
          `${label.value} ${this.$t('aps.showCurrentStatsInterfacesTxRxDeltaChartTooltipGB')}`;
      } else if (this.selectedUnits === 'B') {
        this.txRxChart.yAxis.title.text = this.$t('aps.showCurrentStatsInterfacesTxRxChartYAxisTitleB');
        this.txRxSpeedChart.yAxis.title.text = this.$t('aps.showCurrentStatsInterfacesTxRxSpeedChartYAxisTitleB');
        this.txRxDeltaChart.yAxis.title.text = this.$t('aps.showCurrentStatsInterfacesTxRxDeltaChartYAxisTitleB');

        this.txRxChart.tooltip.pointFormat = `{series.name}: <b>{point.y} ${this.$t(
          'aps.showCurrentStatsInterfacesTxRxChartTooltipB'
        )}</b>`;
        this.txRxSpeedChart.tooltip.pointFormat = `{series.name}: <b>{point.y} ${this.$t(
          'aps.showCurrentStatsInterfacesTxRxSpeedChartTooltipB'
        )}</b>`;
        this.txRxDeltaChart.tooltip.pointFormat = `{series.name}: <b>{point.y} ${this.$t(
          'aps.showCurrentStatsInterfacesTxRxDeltaChartTooltipB'
        )}</b>`;

        this.txRxChart.yAxis.labels.formatter = (label) =>
          // console.log (label)
          `${label.value} ${this.$t('aps.showCurrentStatsInterfacesTxRxChartTooltipB')}`;
        this.txRxSpeedChart.yAxis.labels.formatter = (label) =>
          // console.log (label)
          `${label.value} ${this.$t('aps.showCurrentStatsInterfacesTxRxSpeedChartTooltipB')}`;
        this.txRxDeltaChart.yAxis.labels.formatter = (label) =>
          // console.log (label)
          `${label.value} ${this.$t('aps.showCurrentStatsInterfacesTxRxDeltaChartTooltipB')}`;
      }
    },
    getErrorText() {
      if (this.isErrorFromResponse) {
        if (this.errorFromResponse === 'noData') {
          return this.$t('aps.showCurrentStatsNoDataForCPE');
        }
        return this.errorFromResponse;
      }
      return '';
    }
  },
  created() {
    statCpeLatestService.getCpeLatestStats(this, this.cpeId);
  }
};
</script>

<style scoped>
.spinner-wrapper {
  min-height: 100px;
  position: relative;
}
.error-wrapper {
  min-height: 100px;
  position: relative;
}
.save-icon {
  position: absolute !important;
  top: 10px;
  right: 30px;
}
.chart-title-wrapper {
  position: relative;
  left: 15px;
  /*width: 100%;*/
  margin-bottom: 1em;
}
.interfaces-names-in-table {
  font-size: 0.9em;
}
.tr-in-charts-for-interfaces-table:hover {
  background-color: inherit !important;
}
.save-icon-in-stat-cpe-latest {
  position: relative;
  top: -35px;
  right: -5px;
}

.interfaceschartstransition-enter-active,
.interfaceschartstransition-leave-active {
  transition: opacity 1s;
}

.interfaceschartstransition-enter,
.interfaceschartstransition-leave-to {
  opacity: 0;
}
.interface-name-in-chart-title {
  font-size: 1.1em;
  font-weight: 700 !important;
}
.cpe-stat-latest-interface-charts-no-padding {
  padding: 0px !important;
}
</style>
