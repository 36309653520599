// отдельный модуль только для ONE продукта
// здесь размещать только специфические для этого продукта стейты, геттеры, мутации и экшны
// doc о модулях vuex:
// https://v3.vuex.vuejs.org/ru/guide/modules.html

const ONEProduct = {
  state: () => ({
    // todo: добавлять специфические только для ONE продукта стейты сюда
    // https://v3.vuex.vuejs.org/ru/guide/state.html

    // isONEProduct: Boolean(process.env.VUE_APP_PRODUCT_ONE)
  }),
  getters: {
    // todo: добавлять специфические только для ONE продукта вычисляемые функции сюда
    // https://v3.vuex.vuejs.org/ru/guide/getters.html
  },
  mutations: {
    // todo: добавлять специфические только для ONE продукта мутации сюда
    // https://v3.vuex.vuejs.org/ru/guide/mutations.html
  },
  actions: {
    // todo: добавлять специфические только для ONE продукта экшны сюда
    // https://v3.vuex.vuejs.org/ru/guide/actions.html
  }
};

export default ONEProduct;
