// миксин используется только в компонентах связанных с редиректами
// в нем функционал для работы селектора выбора типа редиректа в формах созданя/редактирования редиректа

import { REDIRECT_TYPES } from '@/views/GuestControl/config';

const redirectTypeSelectorMixin = {
  computed: {
    /**
     * Список опций для селектора выбора типа редиректа
     */
    redirectTypesOptionsForSelector() {
      const redirectTypes = Object.values(REDIRECT_TYPES).map((redirectType) => {
        const redirectNameForDisplaying = this.getRedirectTypeWithi18n(redirectType);
        return { data: redirectType, display: redirectNameForDisplaying };
      });
      return [{ data: '', display: this.$t('general.noSelected') }, ...redirectTypes];
    }
  },
  methods: {
    /**
     * Метод вызывавпется при выборе чего-либо в селекторе типа редиректа
     */
    redirectTypeSelectorHandler(selectedValue) {
      const selectedRedirectType = selectedValue;
      if (selectedRedirectType === REDIRECT_TYPES.DYNAMIC) {
        this.clearRedirectUrlField();
      } else if (selectedRedirectType === REDIRECT_TYPES.STATIC) {
        this.fillRedirectUrlFieldByRedirectURLConfigData();
      } else {
        this.redirectRuleData.redirect_type = undefined;
      }
    },
    /**
     * Очищает ненужное для динамического редиректа поле redirect_url
     */
    clearRedirectUrlField() {
      this.redirectRuleData.redirect_url = '';
    },
    /**
     * Заполняем поле redirect_url на основе данных из полей в UI (из объекта redirectURLConfig)
     */
    fillRedirectUrlFieldByRedirectURLConfigData() {
      this.$nextTick(() => {
        this.redirectRuleData.redirect_url = this.calculatedURL;
      });
    }
  }
};

export default redirectTypeSelectorMixin;
