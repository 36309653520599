import {
  beforeSendToAPISpeedControlModifiers,
  whenReceivedFromAPIBeforeShowInUISpeedControlModifiers
} from './speedControlModifiers';

import { whenReceivedFromAPIBeforeShowInUISecurityModifiers } from './securityModifiers';

import {beforeSendToAPIWMMlModifiers} from './wmmModifiers';


// функции, которые выполянются над объектом WLAN когда происходит модификация значений из UI перед их отправкой на бэк
const beforeSendToAPIModifiers = [...beforeSendToAPISpeedControlModifiers, ...beforeSendToAPIWMMlModifiers];

// функции, которые выполянются над объектом WLAN когда происходит модификация значений прилетеших с бэка перед показом в UI
const whenReceivedFromAPIBeforeShowInUIModifiers = [
  ...whenReceivedFromAPIBeforeShowInUISpeedControlModifiers,
  ...whenReceivedFromAPIBeforeShowInUISecurityModifiers
];

/**
 * Изменяет в переданном объекте wlan значения введеные в UI на значения которые нужно отпарвить на бэк
 *
 * @param {Object} wlanObject
 */
function modifyWLANObjectBeforeSendToAPI(wlanObject) {
  for (const modifyWLANObjectFunc of beforeSendToAPIModifiers) {
    modifyWLANObjectFunc(wlanObject);
  }
}

/**
 * Изменяет в переданном объекте wlan значения прилетевшие с бэка на значения, которые нужно показать в UI
 *
 * @param {Object} wlanObject
 */
function modifyWLANObjectFromAPIBeforeShowInUI(wlanObject) {
  for (const modifyWLANObjectFunc of whenReceivedFromAPIBeforeShowInUIModifiers) {
    modifyWLANObjectFunc(wlanObject);
  }
}

/**
 * Копирует переданный объект, изменяет в этой копии объекта wlan значения введеные в UI на значения которые нужно отпарвить на бэк и
 * возвращает эту копию
 *
 * @param {Object} wlanObject
 *
 * @return {Object} copiedWlanObject
 */
function copyAndModifyWLANObjectBeforeSendToAPI(wlanObject) {
  const copiedWlanObjectForModify = JSON.parse(JSON.stringify(wlanObject));
  modifyWLANObjectBeforeSendToAPI(copiedWlanObjectForModify);
  return copiedWlanObjectForModify;
}

/**
 * Копирует переданный объект, изменяет в этой копии объекта wlan значения прилетевшие с бэка на значения, которые нужно показать в UI и
 * возвращает эту копию
 *
 * @param {Object} wlanObject
 *
 * @return {Object} copiedWlanObject
 */
function copyAndModifyWLANObjectFromAPIBeforeShowInUI(wlanObject) {
  const copiedWlanObjectForModify = JSON.parse(JSON.stringify(wlanObject));
  modifyWLANObjectFromAPIBeforeShowInUI(copiedWlanObjectForModify);
  return copiedWlanObjectForModify;
}

export {
  copyAndModifyWLANObjectBeforeSendToAPI,
  copyAndModifyWLANObjectFromAPIBeforeShowInUI,
  modifyWLANObjectFromAPIBeforeShowInUI,
  modifyWLANObjectBeforeSendToAPI
};
