<template>
  <BranchProfileFormModal
    :heading="$t('branchProfile.changeBranchProfile')"
    :isOpen="isOpen"
    @close="handleClose"
    v-bind="$attrs"
  >
    <template v-slot:ap-profile-form>
      <ChangeBranchProfileForm
        :forbiddenProfileNames="forbiddenProfileNames"
        :branchProfileForChanging="branchProfileForChanging"
        :radiusGroupsNames="radiusGroupsNames"
        :redirectRulesNames="redirectRulesNames"
        @submit="handleSubmit"
      >
        <template v-slot:form-control-buttons>
          <div class="button-section">
            <WButton secondary outline
              @click="handleClose"
            >
              {{ $t('general.cancel') }}
            </WButton>
            <WButton success type="submit">
              {{ $t('general.update') }}
            </WButton>
          </div>
        </template>
      </ChangeBranchProfileForm>
    </template>
  </BranchProfileFormModal>
</template>

<script>
/**
 * компонент модального окна с формой для изменения существующего branch profile.
 * ! Компонент локальный, используется только в BranchProfilePage.vue
 * @component
 */

import {
  BranchProfileFormModal
} from '../features';

import {
  ChangeBranchProfileForm
} from './components';

export default {
  name: 'ChangeBranchProfileModal',
  components: {
    ChangeBranchProfileForm,
    BranchProfileFormModal
  },
  props: {
    isOpen: {
      type: Boolean,
      required: true
    },
    forbiddenProfileNames: {
      type: Array,
      default: () => []
    },
    branchProfileForChanging: {
      type: Object,
      required: true
    },
    radiusGroupsNames: {
      type: Array,
      default: () => []
    },
    redirectRulesNames: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
    };
  },
  computed: {
  },
  methods: {
    handleSubmit(formValues) {
      this.$emit('submit', formValues);
    },
    handleClose() {
      this.$emit('close');
    }
  },
  created() {
  }
};
</script>

<style lang="css" scoped>
.button-section {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
</style>
