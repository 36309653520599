/* eslint-disable consistent-return */
import store from '@/store';
import { AXIOS_INSTANCE, POST_REQUEST_PASSWORD_RECOVERY_URL } from '../config';

/**
 * Функция-фетчер для отправки запроса на сброс пароля.
 *
 * @param {Object} credentials - данные для сброса пароля.
 * @param {string} credentials.username - логин.
 * @returns {Promise<Object>} - Обещание с данными ответа.
 * @throws {Error} - Ошибка при выполнении запроса.
 */

const requestPasswordRecoveryFetcher = async (credentials) => {
  if (!credentials) {
    throw new Error('credentials must be set');
  }

  const payload = {
    username: credentials.username,
    redirect_uri: `${document.location.protocol}//${document.location.host}/pages/reset-password`
  };

  try {
    const response = await AXIOS_INSTANCE.post(POST_REQUEST_PASSWORD_RECOVERY_URL, payload);

    const { data } = response;

    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};

/**
 * фукнция-обработчик фетчера requestPasswordRecoveryFetcher.
 * выполняет побочные действия и обрабатывает тело запроса.
 *
 * @param {Object} credentials - данные для cброса пароля.
 * @param {string} credentials.username - логин.
 * @param {Object} [options] - Дополнительные опции запроса.
 * @param {Function} [options.onLoading] - Callback при начале выполнения запроса.
 * @param {Function} [options.onSuccess] - Callback при успешном выполнении запроса.
 * @param {Function} [options.onError] - Callback при ошибке выполнения запроса.
 * @returns {Promise<Object>} - Обещание с данными ответа.
 * @throws {Error} - Ошибка при выполнении запроса.
 */
const requestPasswordRecovery = async (credentials, options) => {
  const {
    onLoading,
    onSuccess,
    onError
  } = options;

  try {
    if (onLoading) {
      onLoading();
    }

    const response = await requestPasswordRecoveryFetcher(credentials);

    const { data, status, description } = response;

    if (status === 'error') {
      throw new Error(description);
    }

    if (status === 'success' && onSuccess) {
      onSuccess(data);
    }

    if (status === 'success') {
      store.commit('setSuccess');

      return Promise.resolve(data);
    }
  } catch (error) {
    if (onError) {
      onError(error.message);
    }
    store.commit('setError');
  }
};

export default requestPasswordRecovery;
