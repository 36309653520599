/**
 * Миксин для централизованной обработки событий API и отображения уведомлений.
 *
 * !!! примешивается только один раз в компоненте App
 *
 * @mixin
 *
*/

import { mapState, mapGetters } from 'vuex';
import { IS_APP_DEV_MODE } from '@/config';

const SUCCESS_WARN_CONSOLE_MESSAGE = "successMessage is required in setSuccess commit for success toast notification. You should set your message as the second parameter: store.commit('setSuccess', message)";
const ERROR_WARN_CONSOLE_MESSAGE = "errorMessage is required for error toast notification. You should set your message as the second parameter: store.commit('setError', message)";

/**
 * Отображает предупреждающее сообщение в консоли браузера, если приложение находится в режиме разработки (`IS_APP_DEV_MODE === true`).
 *
 * @param {'success' | 'error'} type - Тип сообщения ("success" или "error").
 */
const showWarnMessage = (type) => {
  if (!IS_APP_DEV_MODE) {
    return;
  }

  if (type === 'success') {
    console.warn(SUCCESS_WARN_CONSOLE_MESSAGE);
  }

  if (type === 'error') {
    console.warn(ERROR_WARN_CONSOLE_MESSAGE);
  }
};

const apiStatusNotificationMixin = {
  computed: {
    ...mapState({
      errorMessage: state => state.apiStatus.errorMessage,
      successMessage: state => state.apiStatus.successMessage
    }),
    ...mapGetters({
      isError: 'isError',
      isSuccess: 'isSuccess'
    })
  },
  /**
   * Центральный обработчик событий API, содержит настройки уведомлений.
   *
   * работает на основе библиотек:
   * https://www.npmjs.com/package/vue-notifications
   * https://www.npmjs.com/package/mini-toastr
   *
   */
  notifications: {
    /**
     * Отображает уведомление о событии с заданными параметрами.
     *
     * @param {Object} action - Данные об событии.
     * @param {string} action.title - Заголовок.
     * @param {string} action.message - Сообщение события.
     * @param {string} action.message - Тип уведомления (например, 'error', 'info', 'success', и др.).
     */
    showFetchMessage: {
      title: '',
      message: '',
      type: 'info'
    }
  },
  watch: {
    // show error
    isError(isError) {
      if (!isError) {
        return;
      }

      if (!this.errorMessage) {
        showWarnMessage('error');

        return;
      }

      this.showFetchMessage({
        title: 'Error',
        message: this.errorMessage,
        type: 'error'
      });
    },
    // show success
    isSuccess(isSuccess) {
      if (!isSuccess) {
        return;
      }

      if (!this.successMessage) {
        showWarnMessage('success');

        return;
      }

      this.showFetchMessage({
        title: 'Success',
        message: this.successMessage,
        type: 'success'
      });
    }
  }
};

export default apiStatusNotificationMixin;
