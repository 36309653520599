const Settings = () => import(/* webpackChunkName: "Settings" */ '../views/Settings');
const Account = () => import(/* webpackChunkName: "Account" */ '../views/Account');
const Location = () => import(/* webpackChunkName: "Location" */ '../views/Location');
const Users = () => import(/* webpackChunkName: "Users" */ '../views/Users');

const SettingsRoute = {
  path: 'settings',
  name: 'Settings',
  redirect: 'settings/account',
  component: Settings,
  children: [
    {
      path: 'account',
      name: 'Account',
      component: Account
    },
    {
      path: 'location',
      name: 'Location',
      component: Location,
      props: true
    },
    {
      path: 'Users',
      name: 'Users',
      component: Users
    }
  ]
};

export default SettingsRoute;
