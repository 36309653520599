import Vue from 'vue';
import Router from 'vue-router';
import store from '@/store';
import authApi from '@/services/auth';
import { CONTENT_PORTAL_ONLY_MODE } from '@/config';
import {
  executeRouterGuards,
  isAdminRoleGuard,
  isNotOperatorRoleGuard,
  isContentPortalEnabledGuard,
  isNotWLCProductGuard,
  isWLCProductGuard,
  isExperimentalModeGuard
} from './navigationGuards';
import SettingsService from '@/services/settingsService';

// Containers
import { Full } from '@/containers';

import Dashboard from './Dashboard';
import Statistics from './Statistics';
import Portal from './Portal';
import Settings from './Settings';
import System from './System';
import Clients from './Clients';
import Radius from './Radius';
import Monitor from './Monitor';
import Maps from './Maps';
import Templates from './Templates';
import Firewall from './Firewall';
import GuestControl from './guestControl';
import Hotspot from './Hotspot';
import Marketing from './Marketing';
import Radar from './Radar';
import Administration from './Administration';

// Views
import Aps from '../views/Aps/Aps.vue';
import RegisterCpe from '../views/RegisterCpe.vue';
import RRM from '../views/RRM/RRM.vue';
import Controllers from '../views/Controllers.vue';
import ExternalAPs from '../views/ExternalAPs.vue';
import Support from '../views/Support/Support.vue';
import ConfigExportImport from '../views/ConfigExportImport/ConfigExportImport.vue';

import APJoinPage from '@/views/APJoin/APJoinPage.vue';
import BranchProfilePage from '@/views/BranchProfile/BranchProfilePage.vue';
import Wlans from '@/views/WLANS/Wlans/WlansV2.vue';
// import WlanWizard from '../views/WLANS/Wizard/WlanWizard.vue';
// import Step1 from '../views/WLANS/Wizard/Step1.vue';
// import Step2 from '../views/WLANS/Wizard/Step2.vue';
// import Step3 from '../views/WLANS/Wizard/Step3.vue';
// import Step4 from '../views/WLANS/Wizard/Step4.vue';
// import Step5 from '../views/WLANS/Wizard/Step5.vue';
// import Step6 from '../views/WLANS/Wizard/Step6.vue';
// import Step7 from '../views/WLANS/Wizard/Step7.vue';
// import Step8 from '../views/WLANS/Wizard/Step8.vue';
// import Step9 from '../views/WLANS/Wizard/Step9.vue';
// import StepLast from '../views/WLANS/Wizard/StepLast.vue';

import WlanWizardV2 from '../views/WLANS/WizardV2/WlanWizardV2.vue';
// import Step1 from '../views/WLANS/WizardV2/Step1.vue';
// import Step2 from '../views/WLANS/WizardV2/Step2.vue';
// import Step3 from '../views/WLANS/WizardV2/Step3.vue';
// import Step4 from '../views/WLANS/WizardV2/Step4.vue';
// import Step5 from '../views/WLANS/WizardV2/Step5.vue';
// import Step6 from '../views/WLANS/WizardV2/Step6.vue';
// import Step7 from '../views/WLANS/WizardV2/Step7.vue';
// import Step8 from '../views/WLANS/WizardV2/Step8.vue';
// import Step9 from '../views/WLANS/WizardV2/Step9.vue';
// import StepLast from '../views/WLANS/WizardV2/StepLast.vue';

import Page404 from '../views/pages/Page404.vue';
import Login from '../views/Authentication/Login.vue';
import RecoverPassword from '../views/Authentication/RecoverPassword.vue';
import ResetPassword from '../views/Authentication/ResetPassword.vue';
import UsersDemoWizard from '../views/Users/UsersDemoWizard.vue';
import UsersDemoWizardStep1 from '../views/Users/UsersDemoWizardStep1.vue';
import UsersDemoWizardStep2 from '../views/Users/UsersDemoWizardStep2.vue';
import UsersDemoWizardStep3 from '../views/Users/UsersDemoWizardStep3.vue';
import UsersDemoWizardStep4 from '../views/Users/UsersDemoWizardStep4.vue';
import UsersDemoWizardStep5 from '../views/Users/UsersDemoWizardStep5.vue';

// easy install wizard
import easyInstallWizard from '../views/easyInstallWizard/easyInstallWizard.vue';
import EasyInstallWizardStep1 from '../views/easyInstallWizard/EasyInstallWizardStep1.vue';
import EasyInstallWizardstep2 from '../views/easyInstallWizard/EasyInstallWizardstep2.vue';
import EasyInstallWizardstep3 from '../views/easyInstallWizard/EasyInstallWizardstep3.vue';
import EasyInstallWizardstep4 from '../views/easyInstallWizard/EasyInstallWizardstep4.vue';
import EasyInstallWizardstep5 from '../views/easyInstallWizard/EasyInstallWizardstep5.vue';

// for user self-registration of cpes
import RegisterMyCPEPlease from '../views/pages/RegisterMyCPEPlease.vue';

function checkIsAuthenticatedByRequest(router) {
  // следующий блок с вызовом  SettingsService.getLocations нужен для проверки не испортился ли
  // jwt токен при переходе между роутами (в том числе и при открытии админки в браузере)
  // + возможно зачем то нужен для обновления списка локаций в сторе (но это не точно)

  // вызов этой функции обязательно нужен, потому что бэк при невалидном user auth token по любым ручкам не отдает одинаковый ответ с одинаковыми message и statusCode.
  // В ином случае этот костыль был бы не нужен, и можно было автоматически разлогировать юзера по axios-интерцептору, основываясь на ответе бэка.
  // Но тк этого нет, приходится костыльно вызывать этот метод в роутере.
  let checkAuthQuery = null;
  if (store.state.userData.location != '') {
    checkAuthQuery = {};
    checkAuthQuery.ids = [store.state.userData.base_location];
    checkAuthQuery.fields = ['id', 'name'];
  }

  SettingsService.getLocations(checkAuthQuery)
    .catch(() => {
      authApi.logout(() => router.push('/pages/login'));
    });
}

Vue.use(Router);

// const userRole = document.querySelector('#app').__vue__.$store;
// console.log(userRole);


const router = new Router({
  mode: 'history', // Demo is living in GitHub.io, so required!
  linkActiveClass: 'open active',
  scrollBehavior: () => ({ y: 0 }),
  routes: [
    {
      path: '/',
      beforeRouteEnter(to, from, next) {},
      // redirect: (to) => {
      //   if (router.app.$store.state.userData.role === 'hotel_employee') {
      //     return '/portal';
      //   }
      //   return '/dashboard';
      // },
      redirect: '/dashboard',
      name: 'Home',
      component: Full,
      children: [
        Administration,
        Dashboard,
        Statistics,
        Settings,
        System,
        Clients,
        Radius,
        Templates,
        Firewall,
        GuestControl,
        Hotspot,
        Monitor,
        Marketing,
        Radar,
        Maps,
        Portal,
        // {
        //   path: 'wizard',
        //   name: 'Wizard',
        //   redirect: 'wizard/step1',
        //   component: WlanWizard,
        //   children: [
        //     {
        //       path: 'step1',
        //       name: 'step1',
        //       component: Step1
        //     },
        //     {
        //       path: 'step2',
        //       name: 'step2',
        //       component: Step2
        //     },
        //     {
        //       path: 'step3',
        //       name: 'step3',
        //       component: Step3
        //     },
        //     {
        //       path: 'step4',
        //       name: 'step4',
        //       component: Step4
        //     },
        //     {
        //       path: 'step5',
        //       name: 'step5',
        //       component: Step5
        //     },
        //     {
        //       path: 'step6',
        //       name: 'step6',
        //       component: Step6
        //     },
        //     {
        //       path: 'step7',
        //       name: 'step7',
        //       component: Step7
        //     },
        //     {
        //       path: 'step8',
        //       name: 'step8',
        //       component: Step8
        //     },
        //     {
        //       path: 'step9',
        //       name: 'step9',
        //       component: Step9
        //     },
        //     {
        //       path: 'StepLast',
        //       name: 'StepLast',
        //       component: StepLast
        //     }
        //   ]
        // },
        {
          path: 'wlanWizardV2',
          name: 'wlanWizardV2',
          component: WlanWizardV2,
          beforeEnter: executeRouterGuards([isNotOperatorRoleGuard, isNotWLCProductGuard], { name: 'Wlans' })
        },
        {
          path: 'aps',
          name: 'Aps',
          component: Aps,
          props: true
        },
        {
          path: 'rrm',
          name: 'RRM',
          component: RRM
        },
        {
          path: 'controllers',
          name: 'Controllers',
          component: Controllers
        },
        {
          path: 'external-aps',
          name: 'ExternalAPs',
          component: ExternalAPs
        },
        {
          path: 'apjoin',
          name: 'APJoinPage',
          component: APJoinPage,
          beforeEnter: executeRouterGuards(isWLCProductGuard, { name: 'Dashboard' })
        },
        {
          path: 'branch-profile',
          name: 'BranchProfilePage',
          component: BranchProfilePage,
          beforeEnter: executeRouterGuards(isWLCProductGuard, { name: 'Dashboard' })
        },
        {
          path: 'wlans',
          name: 'Wlans',
          component: Wlans
        },
        {
          path: 'registercpe',
          name: 'RegisterCpe',
          component: RegisterCpe,
          beforeEnter: executeRouterGuards(isNotOperatorRoleGuard, { name: 'Aps' })
        },
        {
          path: 'support',
          name: 'Support',
          component: Support
        },
        {
          path: 'config_export_import',
          name: 'ConfigExportImport',
          component: ConfigExportImport,
          beforeEnter: executeRouterGuards(isAdminRoleGuard, { name: 'Dashboard' })
        },
        {
          path: 'users-demo-wizard',
          name: 'usersDemoWizard',
          redirect: 'users-demo-wizard/step-1',
          component: UsersDemoWizard,
          beforeEnter: executeRouterGuards(isAdminRoleGuard, { name: 'Users' }),
          children: [
            {
              path: 'step-1',
              name: 'UsersDemoWizardStep1',
              component: UsersDemoWizardStep1
            },
            {
              path: 'step-2',
              name: 'UsersDemoWizardStep2',
              component: UsersDemoWizardStep2
            },
            {
              path: 'step-3',
              name: 'UsersDemoWizardStep3',
              component: UsersDemoWizardStep3
            },
            {
              path: 'step-4',
              name: 'UsersDemoWizardStep4',
              component: UsersDemoWizardStep4
            },
            {
              path: 'step-5',
              name: 'UsersDemoWizardStep5',
              component: UsersDemoWizardStep5
            }
          ]
        },
        {
          path: 'easy-install-wizard',
          name: 'easyInstallWizard',
          component: easyInstallWizard,
          beforeEnter: executeRouterGuards([isAdminRoleGuard, isContentPortalEnabledGuard]),
          redirect: 'easy-install-wizard/step-1',
          children: [
            {
              path: 'step-1',
              name: 'EasyInstallWizardStep1',
              component: EasyInstallWizardStep1
            },
            {
              path: 'step-2',
              name: 'EasyInstallWizardStep2',
              component: EasyInstallWizardstep2
            },
            {
              path: 'step-3',
              name: 'EasyInstallWizardStep3',
              component: EasyInstallWizardstep3
            },
            {
              path: 'step-4',
              name: 'EasyInstallWizardStep4',
              component: EasyInstallWizardstep4
            },
            {
              path: 'step-5',
              name: 'EasyInstallWizardStep5',
              component: EasyInstallWizardstep5
            }
          ]
        }
      ]
    },
    {
      path: '/pages',
      redirect: '/pages/p404',
      name: 'Pages',
      component: {
        render(c) {
          return c('router-view');
        }
      },
      children: [
        {
          path: '404',
          name: 'Page404',
          component: Page404
        },
        {
          path: 'login',
          name: 'Login',
          component: Login
        },
        {
          path: 'recover-password',
          name: 'RecoverPassword',
          component: RecoverPassword,
          beforeEnter: executeRouterGuards(isNotWLCProductGuard, { name: 'Login' })
        },
        {
          path: 'reset-password',
          name: 'ResetPassword',
          component: ResetPassword,
          beforeEnter: executeRouterGuards([isNotWLCProductGuard, (to, from) => {
            // Проверяем пришел ли пользователь по ссылке сброса пароля, или просто так. Если просто так,
            // то перекидываем на страницу логина.
            // А если по ссылке сброса, то позволяем ввести новый пароль

            const { token, username } = to.query;

            if (!token || !username) {
              return false;
            }

            return true;
          }], { name: 'Login' })
        }
      ]
    },
    {
      path: '/registerMyCPEPlease',
      name: 'registerMyCPEPlease',
      component: RegisterMyCPEPlease
    }
  ]
});

router.beforeEach((to, from, next) => {
  const routeFromName = from.name;
  if (routeFromName) {
    // console.log('routeFromName ', routeFromName)
    router.app.$store.commit('cancelAllRequestsAndClearAllTokensForComponent', { componentName: routeFromName });
  }
  router.app.$store.commit('setIsClickToGoToAnotherPageTrue');
  if (from.name === 'PortalAdsNew') {
    // эта штука нужна чтобы при попытке перехода в другой раздел, пока не подгрузились медиа, из разделе Реклама и Опросы
    // прервать загрузку этих самых медиа (видяшки),  из за которой  дальнейшие запросы, которые нужны для перехода
    // в другой раздел никак не могут выполнится
    const media = document.getElementsByClassName('loading-media-in-card-on-page');
    if (media) {
      for (let i = media.length - 1; i >= 0; --i) {
        media[i].src = '';
        media[i].load();
        // а теперь удаляем сам элемент чтобы не было сообщений об ошибки в карточке с видео, поскольку
        // src='' генерирует ошибку элемента <video>
        media[i].remove();
      }
    }
    // console.log(videos);
  }
  // const userRole = JSON.parse(localStorage.getItem('userData')).role;
  let userRole = '';
  if (localStorage.getItem('userData')) {
    userRole = JSON.parse(localStorage.getItem('userData')).role;
    // console.log('role is ' + userRole)
  }
  console.info(`Transition from ${from.name} to ${to.name}`);
  if (to.name !== 'Login' && to.name !== 'ResetPassword' && to.name !== 'RecoverPassword') {
    if (to.name === 'Dashboard' && userRole === 'hotel_employee') {
      router.app.$store.commit('setIsClickToGoToAnotherPageFalse');
      next('/portal/hotel-vouchers');
    } else if (to.name === 'Dashboard' && userRole === 'advertising') {
      // console.log(to.name)
      // console.log(userRole)
      router.app.$store.commit('setIsClickToGoToAnotherPageFalse');
      next('/portal/ads');
    } else if (to.name === 'Dashboard' && CONTENT_PORTAL_ONLY_MODE) {
      router.app.$store.commit('setIsClickToGoToAnotherPageFalse');
      next('/portal/dashboard');
    }

    const isAuthenticated = store.getters['auth/isAuthenticated'];

    if (isAuthenticated) {
      console.warn('Logined');

      // почему нужна эта функция см в ее описании
      checkIsAuthenticatedByRequest(router);

      // todo legacy
      // тут проверка на включеный флаг userRegistersTheCpeHimself в сторе. Он включается если ранее пользователь
      // зашел на /registerMyCPEPlease чтобы зарегать точку, но был не
      // залогинен и переправлен на страницу логина. После ввода  логина и успешной аутентификации смотрим на этот флаг
      // и перенаправляем его обратно на registerMyCPEPlease и прокидывваем в query id регистрируемой  точки
      if (router.app.$store.state.userRegistersTheCpeHimself) {
        router.app.$store.commit('setIsClickToGoToAnotherPageFalse');
        router.push({
          name: 'registerMyCPEPlease',
          query: { cpe_id: router.app.$store.state.userRegistersTheCpeHimselfCpeId }
        });
        // далее очищаем переменные в сторе, необходимые для перенаправления после логина
        // обратно на страницу registerMyCPEPlease
        router.app.$store.commit('userRegistersTheCpeHimselfDisableAndClearCpeID');
        return;
      }
      if (router.app.$can('read', { route: to.name }) || to.name === 'Dashboard') {
        router.app.$store.commit('setIsClickToGoToAnotherPageFalse');
        next();
        return;
      }
      if (!router.app.$can('read', { route: to.name }) && userRole === 'advertising' && to.name !== 'Dashboard') {
        router.app.$store.commit('setIsClickToGoToAnotherPageFalse');
        router.push('/portal/ads');
      }
    }

    if (!isAuthenticated) {
      console.warn('Not Logined');

      // todo legacy
      // тут если пользователь пытлася зайти на /registerMyCPEPlease  с целью зарегать точку
      // но был не залогинен, ставим флаг что он хотел зарегть точку и запоминаем ID точки и потом он
      // перенаправляется на страницу логина
      if (to.name === 'registerMyCPEPlease' && !router.app.$store.state.userRegistersTheCpeHimself) {
        router.app.$store.commit('userRegistersTheCpeHimselfEnable');
        router.app.$store.commit('userRegistersTheCpeHimselfSetCpeId', to.query.cpe_id);
      }
      router.app.$store.commit('setIsClickToGoToAnotherPageFalse');
      router.push('/pages/login');
    }
  } else {
    router.app.$store.commit('setIsClickToGoToAnotherPageFalse');
    next();
  }
});

router.afterEach((to, from) => {
  console.log('Current route name is', to.name);
  router.app.$store.commit('setCurrentRouteComponentName', to.name);
});

export default router;
