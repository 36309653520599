<template>
  <div>
    <label v-if="label" :for="id" class="label">
      {{ label }}
    </label>
    <textarea
      class="w-textarea view-type-default"
      :id="id"
      :name="name"
      :value="value"
      :placeholder="placeholder"
      :class="[isErrorClass, sizeClass, customClass]"
      :style="customStyle"
      :disabled="disabled"
      @input="handleInput"
      @change="handleChange"
      @focus="handleFocus"
      @blur="handleBlur"
      @keyup="handleKeyup"
      @keydown="handleKeydown"
      v-bind="$attrs"
    />
    <span v-show="errorText" class="error-tooltip is-error">
      {{ errorText }}
    </span>
  </div>
</template>

<script>
/**
* UI-компонент textarea.
* Используется во всем проекте. Регистрируется глобально (в конкретном компоненте регистрировать не нужно).
*
* @typedef {Object} WTextareaProps
* @prop {string|number} value - Значение textarea. Для использования компонента с v-model.
* @prop {string} id  - ID.
* @prop {string} [name=''] - Имя textarea.
* @prop {string} [placeholder=''] - Placeholder textarea.
* @prop {string} [label=''] - Label для textarea. Если не задать, label не будет.
* @prop {string|Object} [customClass=''] - Кастомные классы для textarea. Если вдруг нужно задать нестандартный класс.
* @prop {Object} [customStyle={}] - Кастомные стили для textarea. Если вдруг нужно задать нестандартные inline-свойства.
* @prop {boolean} [disabled=false] - Флаг для отключения textarea.
*
* Размеры:
* @prop {boolean} [sm=true] - Флаг для размера 'sm'.
* @prop {boolean} [md=false] - Флаг для размера 'md'.
* @prop {boolean} [lg=false] - Флаг для размера 'lg'.
*
* Валидация:
* @prop {string} [errorText=''] - Текст ошибки для textarea.
*
* События:
* @event input - Срабатывает при изменении значения textarea.
* @event change - Срабатывает при изменении значения и потере фокуса textarea.
* @event focus - Срабатывает при фокусировке на textarea.
* @event blur - Срабатывает при потере фокуса textarea.
* @event keyup - Срабатывает при отпускании клавиши на textarea.
* @event keydown - Срабатывает при нажатии клавиши на textarea.
*
* Примеры использования компонента WTextarea:
*
* @example
* Пример 1: Базовый textarea
* <WTextarea v-model="inputValue" />
*
* @example
* Пример 2: Textarea с label и placeholder
* <WTextarea v-model="textValue" label="Имя:" placeholder="Введите имя" />
*
* @example
* Пример 3: Textarea с пользовательскими классами и стилями
* <WTextarea v-model="emailValue" customClass="my-custom-class" :customStyle="{ color: 'blue', border: '1px solid blue' }" />
*
* @example
* Пример 4: Отключенный textarea
* <WTextarea v-model="inputValue" :disabled="isTextareaDisabled" />
*
* @example
* Пример 5: Textarea с валидацией VeeValidate
* <WTextarea v-model="validatedValue"
    v-validate="'required|ipOrMacListForReactiveLogs'"
    data-vv-validate-on="input"
    :data-vv-as="$t('reactiveLogs.modalMACIP')"
    :errorText="errors.first('macIp')"
  />
*
* @example
* Пример 6: Textarea с размером 'lg'
* <WTextarea v-model="largeValue" lg />
*/

export default {
  name: 'WTextarea',
  inheritAttrs: false, // Отключаем наследование атрибутов по умолчанию
  props: {
    value: {
      type: [String, Number],
      default: '',
      required: true
    },
    id: {
      type: String,
      required: true
    },
    name: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: ''
    },
    label: {
      type: String,
      default: ''
    },
    customClass: {
      type: [String, Object],
      default: ''
    },
    customStyle: {
      type: Object
    },
    disabled: {
      type: Boolean,
      default: false
    },
    errorText: {
      type: String,
      default: ''
    },
    // sizes
    sm: {
      type: Boolean,
      default: false
    },
    md: {
      type: Boolean,
      default: true
    },
    lg: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
    };
  },
  computed: {
    isErrorClass() {
      return {
        'is-error': this.errorText
      };
    },
    sizeClass() {
      if (this.sm) {
        return 'sm';
      }

      if (this.md) {
        return 'md';
      }

      if (this.lg) {
        return 'lg';
      }

      return '';
    }
  },
  methods: {
    async handleInput(event) {
      const targetValue = event.target.value;
      this.$emit('input', targetValue);
    },
    async handleChange(event) {
      this.$emit('change', event);
    },
    handleFocus(event) {
      this.$emit('focus', event);
    },
    handleBlur(event) {
      this.$emit('blur', event);
    },
    handleKeyup(event) {
      this.$emit('keyup', event);
    },
    handleKeydown(event) {
      this.$emit('keydown', event);
    }
  }
};
</script>

<style lang="css" scoped>
/* root input class */
.w-textarea {
  display: block;
  width: 100%;
  line-height: 1.25;
  background-image: none;
  background-clip: padding-box;
  border-radius: 0;
  resize: none;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
}

.w-textarea::placeholder {
  opacity: 0.5;
}

.w-textarea:focus {
  border-color: var(--brand-info);
}

.w-textarea:disabled,
.w-textarea[readonly] {
  cursor: not-allowed;
}

/* view type
  он один по умолчанию, поэтому только один класс
*/
.view-type-default {
  color: var(--font-color);
  background-color: transparent;
  border: 1px solid var(--border-color);
}

/* error tooltip */
.error-tooltip {
  display: block;
  font-size: 0.85rem;
  margin-top: 5px;
  text-align: start;
}

.is-error {
  color: var(--brand-danger);
  border-color: var(--brand-danger)
}

/* label */
.label {
  display: inline-block;
  margin-bottom: 0.5rem;
  color: var(--label-font-color);
  line-height: 1.5;
}

/* size classes */
.sm {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
}
.md {
  padding: 0.5rem 0.75rem;
  font-size: 0.875rem;
}
.lg {
  padding: 0.75rem 1.5rem;
  font-size: 1.25rem;
}
</style>
