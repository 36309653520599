<template>
  <ul class="menu-list-wrapper">
    <MenuItem v-for="item in menuItems"
      :key="item.id"
      :item="item"
      :isCollapsed="isCollapsed"
    />
  </ul>
</template>

<script>
import { MenuItem } from './components';

export default {
  name: 'Menu',
  components: {
    MenuItem
  },
  props: {
    menuItems: {
      type: Array,
      required: true
    },
    isCollapsed: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {};
  },
  watch: {
  },
  computed: {
  },
  methods: {
  },
  mounted() {
  }
};
</script>

<style lang="css" scoped>
.menu-list-wrapper {
  width: 100%;
  background-color: var(--sidebar-bg-color);
  color: var(--sidebar-font-color);
  border-radius: 8px;
  margin: 0;

  display: flex;
  flex-direction: column;
  gap: 12px;
}
</style>
