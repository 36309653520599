import Vue from 'vue';
import VueNotifications from 'vue-notifications';
import { get } from 'leaflet/src/dom/DomUtil';
import allSettled from 'promise.allsettled';
import { API_URL } from '@/config';

export default {
  addLatLng(items, ctx) {
    for (const item of items) {
      // console.log(item.x)
      // console.log(item.y)
      // console.log(item.uniq_mac)
      item.lng = item.x;
      item.lat = item.y;
      item.total = item.uniq_mac;
    }
    return items;
  },
  getGridSize(ctx) {
    // размер сетки для запросов
    const sizes = { x: 10, y: 10 };
    if (Object.prototype.hasOwnProperty.call(ctx, 'gridSize')) {
      if (Object.prototype.hasOwnProperty.call(ctx.gridSize, 'x')) {
        sizes.x = ctx.gridSize.x;
      }
      if (Object.prototype.hasOwnProperty.call(ctx.gridSize, 'y')) {
        sizes.y = ctx.gridSize.y;
      }
    }
    return sizes;
  },
  getMaps(ctx) {
    ctx.$store.commit('toggleLoadingMaps');
    Vue.axios.post(`${API_URL || ''}/api/maps`, { action: 'R', location: ctx.$store.getters.getDefaultLocationForRequests(ctx), with_childs: ctx.$store.getters.getDefaultWithChildsForRequests(ctx), }).then(
      (response) => {
        if (response.data.status === 'success') {
          ctx.$store.commit('setMapsList', response.data.data.itemslist);
        } else if (response.data.status === 'error') {
          VueNotifications.error({ message: response.data.description });
        }
        ctx.$store.commit('toggleLoadingMaps');
      },
      (err) => {
        // VueNotifications.error({ message: err });

        // show errors in the console instead of the alert window
        if (
          Object.prototype.hasOwnProperty.call(window, 'isShowAxiosErrorInConsole') &&
          typeof window.isShowAxiosErrorInConsole === 'function' &&
          window.isShowAxiosErrorInConsole(err)
        ) {
          window.showAxiosErrInConsole(err);
        } else {
          VueNotifications.error({ message: err });
        }

        ctx.$store.commit('toggleLoadingMaps');
        ctx.error = err;
      }
    );
  },
  // getMapsV2(ctx) {
  //   ctx.$store.commit('toggleLoadingMaps');
  //   const query = {
  //     action: 'R',
  //     search: ctx.searchValue,
  //     page: {
  //       limit: ctx.limit,
  //       offset: ctx.limit * ctx.offset
  //     }
  //   };
  //   Vue.axios.post(`${API_URL || ''}/api/maps`, query).then(
  //     (response) => {
  //       if (response.data.status === 'success') {
  //         ctx.totalResult = response.data.data.total;
  //         // далее цикл для борьбы с  багом, который возникает, когда в Zones приходит null вместо пустого массиова []
  //         // задачка WNE-2160
  //         // response.data.data.itemslist[2].Zones = null; // <- для воспроизвдения проблемы
  //         for (const map of response.data.data.itemslist) {
  //           if (map.Zones === null) {
  //             map.Zones = [];
  //           }
  //         }
  //         ctx.$store.commit('setMapsList', response.data.data.itemslist);
  //         if (ctx.$store.state.mapsList.length >= response.data.data.total) {
  //           ctx.canLoadMore = false;
  //         } else {
  //           ctx.canLoadMore = true;
  //         }
  //         ctx.searchSpinnerForTable = false;
  //       } else if (response.data.status === 'error') {
  //         VueNotifications.error({ message: response.data.description });
  //         ctx.searchSpinnerForTable = false;
  //       }
  //       ctx.$store.commit('toggleLoadingMaps');
  //     },
  //     (err) => {
  //       // VueNotifications.error({ message: err });
  //
  //       // show errors in the console instead of the alert window
  //       if (
  //         Object.prototype.hasOwnProperty.call(window, 'isShowAxiosErrorInConsole') &&
  //         typeof window.isShowAxiosErrorInConsole === 'function' &&
  //         window.isShowAxiosErrorInConsole(err)
  //       ) {
  //         window.showAxiosErrInConsole(err);
  //       } else {
  //         VueNotifications.error({ message: err });
  //       }
  //
  //       ctx.$store.commit('toggleLoadingMaps');
  //       ctx.error = err;
  //       ctx.searchSpinnerForTable = false;
  //     }
  //   );
  // },
    getMapsV2(ctx, useLocationFilter= false) {
    ctx.$store.commit('toggleLoadingMaps');
    const isUseLocationFilters = useLocationFilter;
    const query = {
      action: 'R',
      search: ctx.searchValue,
      location: ctx.$store.getters.getDefaultLocationForRequests(ctx), with_childs: ctx.$store.getters.getDefaultWithChildsForRequests(ctx),
      page: {
        limit: ctx.limit,
        offset: ctx.limit * ctx.offset
      }
    };

    if (ctx.filters?.location && isUseLocationFilters) {
      if (ctx.filters.location === 'All locations') {
        query.location = ctx.$store.state.requestBaseUserLocationIfNoSelected ? ctx.userBaseLocation : undefined;
        query.with_childs = true;
      } else {
        query.location = ctx.filters.location;
        query.with_childs = ctx.filters.with_childs;
      }
    }


    Vue.axios.post(`${API_URL || ''}/api/maps`, query).then(
      (response) => {
        if (response.data.status === 'success') {
          ctx.totalResult = response.data.data.total;
          // далее цикл для борьбы с  багом, который возникает, когда в Zones приходит null вместо пустого массиова []
          // задачка WNE-2160
          // response.data.data.itemslist[2].Zones = null; // <- для воспроизвдения проблемы
          for (const map of response.data.data.itemslist) {
            if (map.Zones === null) {
              map.Zones = [];
            }
          }
          ctx.$store.commit('setMapsList', response.data.data.itemslist);
          if (ctx.$store.state.mapsList.length >= response.data.data.total) {
            ctx.canLoadMore = false;
          } else {
            ctx.canLoadMore = true;
          }
          ctx.searchSpinnerForTable = false;
        } else if (response.data.status === 'error') {
          VueNotifications.error({ message: response.data.description });
          ctx.searchSpinnerForTable = false;
        }
        ctx.$store.commit('toggleLoadingMaps');
      },
      (err) => {
        // VueNotifications.error({ message: err });

        // show errors in the console instead of the alert window
        if (
          Object.prototype.hasOwnProperty.call(window, 'isShowAxiosErrorInConsole') &&
          typeof window.isShowAxiosErrorInConsole === 'function' &&
          window.isShowAxiosErrorInConsole(err)
        ) {
          window.showAxiosErrInConsole(err);
        } else {
          VueNotifications.error({ message: err });
        }

        ctx.$store.commit('toggleLoadingMaps');
        ctx.error = err;
        ctx.searchSpinnerForTable = false;
      }
    );
  },
  getMapsV3(ctx, useLocationFilter= false) {
    ctx.$store.commit('toggleLoadingMaps');
    const isUseLocationFilters = useLocationFilter;
    const query = {
      action: 'R',
      search: ctx.searchValue,
      location: ctx.$store.getters.getDefaultLocationForRequests(ctx), with_childs: ctx.$store.getters.getDefaultWithChildsForRequests(ctx),
      page: {
        limit: ctx.limit,
        offset: ctx.limit * ctx.offset
      },
      sort_by: {
        field: 'name',
        order: 1
      }
    };

    if (ctx.filters?.location && isUseLocationFilters) {
      if (ctx.filters.location === 'All locations') {
        query.location = ctx.$store.state.requestBaseUserLocationIfNoSelected ? ctx.userBaseLocation : undefined;
        query.with_childs = true;
      } else {
        query.location = ctx.filters.location;
        query.with_childs = ctx.filters.with_childs;
      }
    }

    if (ctx.filters.sortOrder === 'asc') {
      query.sort_by.order = 1;
    } else if (ctx.filters.sortOrder === 'desc') {
      query.sort_by.order = -1;
    }
    if (ctx.filters.sortByFilter === 'Name') {
      query.sort_by.field = 'name';
    } else if (ctx.filters.sortByFilter === 'Created') {
      query.sort_by.field = 'created_at';
    } else if (ctx.filters.sortByFilter === 'Updated') {
      query.sort_by.field = 'last_updated';
    }


    Vue.axios.post(`${API_URL || ''}/api/maps`, query).then(
      (response) => {
        if (response.data.status === 'success') {
          ctx.totalResult = response.data.data.total;
          // далее цикл для борьбы с  багом, который возникает, когда в Zones приходит null вместо пустого массиова []
          // задачка WNE-2160
          // response.data.data.itemslist[2].Zones = null; // <- для воспроизвдения проблемы
          for (const map of response.data.data.itemslist) {
            if (map.Zones === null) {
              map.Zones = [];
            }
          }
          ctx.$store.commit('setMapsList', response.data.data.itemslist);
          if (ctx.$store.state.mapsList.length >= response.data.data.total) {
            ctx.canLoadMore = false;
          } else {
            ctx.canLoadMore = true;
          }
          ctx.searchSpinnerForTable = false;
        } else if (response.data.status === 'error') {
          VueNotifications.error({ message: response.data.description });
          ctx.searchSpinnerForTable = false;
        }
        ctx.$store.commit('toggleLoadingMaps');
      },
      (err) => {
        // VueNotifications.error({ message: err });

        // show errors in the console instead of the alert window
        if (
          Object.prototype.hasOwnProperty.call(window, 'isShowAxiosErrorInConsole') &&
          typeof window.isShowAxiosErrorInConsole === 'function' &&
          window.isShowAxiosErrorInConsole(err)
        ) {
          window.showAxiosErrInConsole(err);
        } else {
          VueNotifications.error({ message: err });
        }

        ctx.$store.commit('toggleLoadingMaps');
        ctx.error = err;
        ctx.searchSpinnerForTable = false;
      }
    );
  },

  // getMapsV2Paginated(ctx) {
  //   ctx.$store.commit('toggleLoadingMaps');
  //   const query = {
  //     action: 'R',
  //     search: ctx.searchValue,
  //     page: {
  //       limit: ctx.limit,
  //       offset: ctx.limit * ctx.offset
  //     }
  //   };
  //   Vue.axios.post(`${API_URL || ''}/api/maps`, query).then(
  //     (response) => {
  //       if (response.data.status === 'success') {
  //         ctx.totalResult = response.data.data.total;
  //         // далее цикл для борьбы с  багом, который возникает, когда в Zones приходит null вместо пустого массиова []
  //         // задачка WNE-2160
  //         // response.data.data.itemslist[2].Zones = null; // <- для воспроизвдения проблемы
  //         for (const map of response.data.data.itemslist) {
  //           if (map.Zones === null) {
  //             map.Zones = [];
  //           }
  //         }
  //         ctx.$store.commit('pushMapsList', response.data.data.itemslist);
  //         if (ctx.$store.state.mapsList.length >= response.data.data.total) {
  //           ctx.canLoadMore = false;
  //         } else {
  //           ctx.canLoadMore = true;
  //         }
  //         ctx.searchSpinnerForTable = false;
  //       } else if (response.data.status === 'error') {
  //         VueNotifications.error({ message: response.data.description });
  //         ctx.searchSpinnerForTable = false;
  //       }
  //       ctx.$store.commit('toggleLoadingMaps');
  //     },
  //     (err) => {
  //       // VueNotifications.error({ message: err });
  //
  //       // show errors in the console instead of the alert window
  //       if (
  //         Object.prototype.hasOwnProperty.call(window, 'isShowAxiosErrorInConsole') &&
  //         typeof window.isShowAxiosErrorInConsole === 'function' &&
  //         window.isShowAxiosErrorInConsole(err)
  //       ) {
  //         window.showAxiosErrInConsole(err);
  //       } else {
  //         VueNotifications.error({ message: err });
  //       }
  //
  //       ctx.$store.commit('toggleLoadingMaps');
  //       ctx.error = err;
  //       ctx.searchSpinnerForTable = false;
  //     }
  //   );
  // },

  getMapsV2Paginated(ctx, useLocationFilter= false) {
    ctx.$store.commit('toggleLoadingMaps');
    const isUseLocationFilters = useLocationFilter;
    const query = {
      action: 'R',
      location: ctx.$store.getters.getDefaultLocationForRequests(ctx), with_childs: ctx.$store.getters.getDefaultWithChildsForRequests(ctx),
      search: ctx.searchValue,
      page: {
        limit: ctx.limit,
        offset: ctx.limit * ctx.offset
      }
    };

    if (ctx.filters?.location && isUseLocationFilters) {
      if (ctx.filters.location === 'All locations') {
        query.location = ctx.userBaseLocation;
        query.with_childs = true;
      } else {
        query.location = ctx.filters.location;
        query.with_childs = ctx.filters.with_childs;
      }
    }

    Vue.axios.post(`${API_URL || ''}/api/maps`, query).then(
      (response) => {
        if (response.data.status === 'success') {
          ctx.totalResult = response.data.data.total;
          // далее цикл для борьбы с  багом, который возникает, когда в Zones приходит null вместо пустого массиова []
          // задачка WNE-2160
          // response.data.data.itemslist[2].Zones = null; // <- для воспроизвдения проблемы
          for (const map of response.data.data.itemslist) {
            if (map.Zones === null) {
              map.Zones = [];
            }
          }
          ctx.$store.commit('pushMapsList', response.data.data.itemslist);
          if (ctx.$store.state.mapsList.length >= response.data.data.total) {
            ctx.canLoadMore = false;
          } else {
            ctx.canLoadMore = true;
          }
          ctx.searchSpinnerForTable = false;
        } else if (response.data.status === 'error') {
          VueNotifications.error({ message: response.data.description });
          ctx.searchSpinnerForTable = false;
        }
        ctx.$store.commit('toggleLoadingMaps');
      },
      (err) => {
        // VueNotifications.error({ message: err });

        // show errors in the console instead of the alert window
        if (
          Object.prototype.hasOwnProperty.call(window, 'isShowAxiosErrorInConsole') &&
          typeof window.isShowAxiosErrorInConsole === 'function' &&
          window.isShowAxiosErrorInConsole(err)
        ) {
          window.showAxiosErrInConsole(err);
        } else {
          VueNotifications.error({ message: err });
        }

        ctx.$store.commit('toggleLoadingMaps');
        ctx.error = err;
        ctx.searchSpinnerForTable = false;
      }
    );
  },

  getMapsV3Paginated(ctx, useLocationFilter= false) {
    ctx.$store.commit('toggleLoadingMaps');
    const isUseLocationFilters = useLocationFilter;
    const query = {
      action: 'R',
      location: ctx.$store.getters.getDefaultLocationForRequests(ctx), with_childs: ctx.$store.getters.getDefaultWithChildsForRequests(ctx),
      search: ctx.searchValue,
      page: {
        limit: ctx.limit,
        offset: ctx.limit * ctx.offset
      },
      sort_by: {
        field: 'name',
        order: 1
      }
    };

    if (ctx.filters?.location && isUseLocationFilters) {
      if (ctx.filters.location === 'All locations') {
        query.location = ctx.userBaseLocation;
        query.with_childs = true;
      } else {
        query.location = ctx.filters.location;
        query.with_childs = ctx.filters.with_childs;
      }
    }

    if (ctx.filters.sortOrder === 'asc') {
      query.sort_by.order = 1;
    } else if (ctx.filters.sortOrder === 'desc') {
      query.sort_by.order = -1;
    }
    if (ctx.filters.sortByFilter === 'Name') {
      query.sort_by.field = 'name';
    } else if (ctx.filters.sortByFilter === 'Created') {
      query.sort_by.field = 'created_at';
    } else if (ctx.filters.sortByFilter === 'Updated') {
      query.sort_by.field = 'last_updated';
    }

    Vue.axios.post(`${API_URL || ''}/api/maps`, query).then(
      (response) => {
        if (response.data.status === 'success') {
          ctx.totalResult = response.data.data.total;
          // далее цикл для борьбы с  багом, который возникает, когда в Zones приходит null вместо пустого массиова []
          // задачка WNE-2160
          // response.data.data.itemslist[2].Zones = null; // <- для воспроизвдения проблемы
          for (const map of response.data.data.itemslist) {
            if (map.Zones === null) {
              map.Zones = [];
            }
          }
          ctx.$store.commit('pushMapsList', response.data.data.itemslist);
          if (ctx.$store.state.mapsList.length >= response.data.data.total) {
            ctx.canLoadMore = false;
          } else {
            ctx.canLoadMore = true;
          }
          ctx.searchSpinnerForTable = false;
        } else if (response.data.status === 'error') {
          VueNotifications.error({ message: response.data.description });
          ctx.searchSpinnerForTable = false;
        }
        ctx.$store.commit('toggleLoadingMaps');
      },
      (err) => {
        // VueNotifications.error({ message: err });

        // show errors in the console instead of the alert window
        if (
          Object.prototype.hasOwnProperty.call(window, 'isShowAxiosErrorInConsole') &&
          typeof window.isShowAxiosErrorInConsole === 'function' &&
          window.isShowAxiosErrorInConsole(err)
        ) {
          window.showAxiosErrInConsole(err);
        } else {
          VueNotifications.error({ message: err });
        }

        ctx.$store.commit('toggleLoadingMaps');
        ctx.error = err;
        ctx.searchSpinnerForTable = false;
      }
    );
  },

  deleteMap(ctx) {
    ctx.$store.commit('toggleUpdateMap');
    let mapForDelete;
    if (ctx.currentMap) {
      mapForDelete = ctx.currentMapData.id;
    } else {
      mapForDelete = ctx.mapIdForDelete;
    }
    Vue.axios.post(`${API_URL || ''}/api/maps`, { action: 'D', items: { [mapForDelete]: {} } }).then(
      (response) => {
        if (response.data.status === 'success') {
          // ctx.refreshMaps();
          ctx.refreshAndClearMaps();
          ctx.resetAllLayers();
          ctx.disableEditMode();
          ctx.resetAllMode();
          VueNotifications.success({ message: ctx.$t('maps.notificationMapDeletedSuccessfully') });
        } else if (response.data.status === 'error') {
          VueNotifications.error({ message: response.data.description });
        }
        ctx.$store.commit('toggleUpdateMap');
      },
      (err) => {
        // VueNotifications.error({ message: err });

        // show errors in the console instead of the alert window
        if (
          Object.prototype.hasOwnProperty.call(window, 'isShowAxiosErrorInConsole') &&
          typeof window.isShowAxiosErrorInConsole === 'function' &&
          window.isShowAxiosErrorInConsole(err)
        ) {
          window.showAxiosErrInConsole(err);
        } else {
          VueNotifications.error({ message: err });
        }

        ctx.$store.commit('toggleUpdateMap');
        ctx.error = err;
      }
    );
  },

  updateMapFromModal(ctx) {
    ctx.$store.commit('toggleUpdateMap');
    const updatedQuery = { ...ctx.currentMapData };
    // console.log(updatedQuery);
    if (ctx.updatedMap.name !== '') {
      updatedQuery.name = ctx.updatedMap.name;
      // ctx.currentMapData.name = ctx.updatedMap.name;
    }

    if (ctx.updatedMap.base_location !== '') {
      updatedQuery.base_location = ctx.updatedMap.base_location;
      // ctx.currentMapData.base_location = ctx.updatedMap.base_location;
    }
    // далее для настроек времени работы
    if (ctx.updatedMap.start !== '') {
      updatedQuery.start = ctx.updatedMap.start;
      // ctx.currentMapData.base_location = ctx.updatedMap.base_location;
    } else {
      updatedQuery.start = '';
    }
    if (ctx.updatedMap.stop !== '') {
      updatedQuery.stop = ctx.updatedMap.stop;
      // ctx.currentMapData.base_location = ctx.updatedMap.base_location;
    } else {
      updatedQuery.stop = '';
    }
    if (ctx.updatedMap.time_zone !== '') {
      updatedQuery.time_zone = ctx.updatedMap.time_zone;
      // ctx.currentMapData.base_location = ctx.updatedMap.base_location;
    } else {
      updatedQuery.time_zone = '';
    }

    // console.log(updatedQuery);

    Vue.axios
      .post(`${API_URL || ''}/api/maps`, {
        action: 'U',
        items: {
          [updatedQuery.id]: updatedQuery
        }
      })
      .then(
        (response) => {
          if (response.data.status === 'success') {
            ctx.$store.commit('toggleUpdateMap');
            ctx.closeEditMapModal();
            ctx.disableEditMode();
            // ctx.refreshMaps();
            ctx.refreshAndClearMaps();
          } else if (response.data.status === 'error') {
            ctx.$store.commit('toggleUpdateMap');
            VueNotifications.error({ message: response.data.description });
            ctx.closeEditMapModal();
            ctx.disableEditMode();
            // ctx.refreshMaps();
            ctx.refreshAndClearMaps();
          }
        },
        (err) => {
          // VueNotifications.error({ message: err });

          // show errors in the console instead of the alert window
          if (
            Object.prototype.hasOwnProperty.call(window, 'isShowAxiosErrorInConsole') &&
            typeof window.isShowAxiosErrorInConsole === 'function' &&
            window.isShowAxiosErrorInConsole(err)
          ) {
            window.showAxiosErrInConsole(err);
          } else {
            VueNotifications.error({ message: err });
          }

          ctx.$store.commit('toggleUpdateMap');
          ctx.error = err;
          ctx.closeEditMapModal();
          ctx.disableEditMode();
          // ctx.refreshMaps();
          ctx.refreshAndClearMaps();
        }
      );
  },

  updateMap(ctx) {
    ctx.$store.commit('toggleUpdateMap');
    const updatedQuery = { ...ctx.currentMap };

    // updating zoom
    updatedQuery.zoom = parseFloat(ctx.map.currentMapScale.toFixed(6));

    // updating map image offset
    updatedQuery.offset_left = ctx.map.mapData.offsetX;
    updatedQuery.offset_top = ctx.map.mapData.offsetY;

    Vue.axios
      .post(`${API_URL || ''}/api/maps`, {
        action: 'U',
        items: {
          [updatedQuery.id]: updatedQuery
        }
      })
      .then(
        (response) => {
          if (response.data.status === 'success') {
            ctx.dataFromMap.isSetScaleActive = false;
            ctx.$emit('setScaleForceOff');

            const newCpeArray = ctx.map.drawedCpes.map((cpe) => {
              const transformedCpeObject = {};

              transformedCpeObject.coord_top = cpe.offsetY;
              transformedCpeObject.coord_left = cpe.offsetX;
              transformedCpeObject.cpe_id = cpe.id;

              return transformedCpeObject;
            });

            Vue.axios
              .post(`${API_URL || ''}/api/maps/map_cpelist_update`, {
                action: 'U',
                items: {
                  [updatedQuery.id]: { cpe_list: newCpeArray }
                }
              })
              .then(
                (response) => {
                  if (response.data.status === 'success') {
                    ctx.$store.commit('toggleUpdateMap');
                    ctx.$store.commit('updateSingleMapData', response.data.data.itemslist);
                    ctx.dataFromMap.isSetScaleActive = false;
                    ctx.$emit('setScaleForceOff');
                    // ctx.refreshMaps();
                    ctx.refreshAndClearMaps();
                  } else if (response.data.status === 'error') {
                    ctx.$store.commit('toggleUpdateMap');
                    VueNotifications.error({ message: response.data.description });
                  }
                },
                (err) => {
                  // VueNotifications.error({ message: err });

                  // show errors in the console instead of the alert window
                  if (
                    Object.prototype.hasOwnProperty.call(window, 'isShowAxiosErrorInConsole') &&
                    typeof window.isShowAxiosErrorInConsole === 'function' &&
                    window.isShowAxiosErrorInConsole(err)
                  ) {
                    window.showAxiosErrInConsole(err);
                  } else {
                    VueNotifications.error({ message: err });
                  }

                  ctx.$store.commit('toggleUpdateMap');
                  ctx.error = err;
                }
              );
          } else if (response.data.status === 'error') {
            ctx.$store.commit('toggleUpdateMap');
            VueNotifications.error({ message: response.data.description });
          }
        },
        (err) => {
          // VueNotifications.error({ message: err });

          // show errors in the console instead of the alert window
          if (
            Object.prototype.hasOwnProperty.call(window, 'isShowAxiosErrorInConsole') &&
            typeof window.isShowAxiosErrorInConsole === 'function' &&
            window.isShowAxiosErrorInConsole(err)
          ) {
            window.showAxiosErrInConsole(err);
          } else {
            VueNotifications.error({ message: err });
          }

          ctx.$store.commit('toggleUpdateMap');
          ctx.error = err;
        }
      );
  },
  updateCpesOnMap(ctx, init) {
    const newCpeArray = ctx.cpesMarkers.map((cpe) => ({
      coord_left: cpe._latlng.lng,
      coord_top: cpe._latlng.lat,
      cpe_id: cpe.options.cpe_id
    }));
    // console.log(newCpeArray);
    const mapId = ctx.currentMapData.id;
    const zonesArray = ctx.zonesLayersAsArray;
    Vue.axios
      .post(`${API_URL || ''}/api/maps/cpelist_update`, {
        action: 'U',
        items: {
          [mapId]: { cpe_list: newCpeArray, zones: zonesArray }
        }
      })
      .then(
        (response) => {
          if (response.data.status === 'success') {
            if (init !== 'init') {
              // ctx.refreshMapCpes();
              VueNotifications.success({ message: ctx.$t('maps.notificationMapCPEpositionupdated') });
              ctx.isCPEsChanged = false;
              setTimeout(() => {
                ctx.toggleMode('show');
              }, 1000);
            }
            // ctx.refreshMaps();
            ctx.refreshAndClearMaps();
          } else if (response.data.status === 'error') {
            VueNotifications.error({ message: response.data.description });
          }
        },
        (err) => {
          // VueNotifications.error({ message: err });

          // show errors in the console instead of the alert window
          if (
            Object.prototype.hasOwnProperty.call(window, 'isShowAxiosErrorInConsole') &&
            typeof window.isShowAxiosErrorInConsole === 'function' &&
            window.isShowAxiosErrorInConsole(err)
          ) {
            window.showAxiosErrInConsole(err);
          } else {
            VueNotifications.error({ message: err });
          }

          ctx.error = err;
        }
      );
  },
  zonesUpdate(ctx, init) {
    const newCpeArray = ctx.cpesMarkers.map((cpe) => ({
      coord_left: cpe._latlng.lng,
      coord_top: cpe._latlng.lat,
      cpe_id: cpe.options.cpe_id
    }));
    const zonesArray = ctx.zonesLayersAsArray;
    const mapId = ctx.currentMapData.id;
    Vue.axios
      .post(`${API_URL || ''}/api/maps/cpelist_update`, {
        action: 'U',
        items: {
          [mapId]: { cpe_list: newCpeArray, zones: zonesArray }
        }
      })
      .then(
        (response) => {
          if (response.data.status === 'success') {
            if (init !== 'init') {
              // ctx.refreshMapCpes();
              VueNotifications.success({ message: ctx.$t('maps.notificationMapZonesUpdated') });
            }
            // ctx.refreshMaps();
            ctx.refreshAndClearMaps();
          } else if (response.data.status === 'error') {
            VueNotifications.error({ message: response.data.description });
          }
        },
        (err) => {
          // VueNotifications.error({ message: err });

          // show errors in the console instead of the alert window
          if (
            Object.prototype.hasOwnProperty.call(window, 'isShowAxiosErrorInConsole') &&
            typeof window.isShowAxiosErrorInConsole === 'function' &&
            window.isShowAxiosErrorInConsole(err)
          ) {
            window.showAxiosErrInConsole(err);
          } else {
            VueNotifications.error({ message: err });
          }

          ctx.error = err;
        }
      );
  },
  createMapScale(ctx, callback) {
    ctx.$store.commit('toggleUpdateMap');
    const updatedQuery = { ...ctx.currentMap };

    if (ctx.dataFromMap.distance && ctx.dataFromMap.scale) {
      updatedQuery.upp = parseFloat(ctx.dataFromMap.distance) / ctx.dataFromMap.scale;
      updatedQuery.unit = ctx.dataFromMap.scaleInUnits;

      Vue.axios
        .post(`${API_URL || ''}/api/maps/scale_update`, {
          action: 'U',
          items: {
            [updatedQuery.id]: { unit: updatedQuery.unit, upp: updatedQuery.upp }
          }
        })
        .then(
          (response) => {
            if (response.data.status === 'success') {
              ctx.$store.commit('toggleUpdateMap');
              ctx.dataFromMap.isSetScaleActive = false;
              // ctx.map.toggleSetUpp(false);
              ctx.$emit('setScaleForceOff');
              if (typeof callback === 'function') {
                callback();
              }
            } else if (response.data.status === 'error') {
              ctx.$store.commit('toggleUpdateMap');
              VueNotifications.error({ message: response.data.description });
            }
          },
          (err) => {
            // VueNotifications.error({ message: err });

            // show errors in the console instead of the alert window
            if (
              Object.prototype.hasOwnProperty.call(window, 'isShowAxiosErrorInConsole') &&
              typeof window.isShowAxiosErrorInConsole === 'function' &&
              window.isShowAxiosErrorInConsole(err)
            ) {
              window.showAxiosErrInConsole(err);
            } else {
              VueNotifications.error({ message: err });
            }

            ctx.$store.commit('toggleUpdateMap');
            ctx.error = err;
          }
        );
    } else {
      ctx.$store.commit('toggleUpdateMap');
    }
  },
  updateMapScale(ctx) {
    const updatedQuery = { ...ctx.currentMap };

    if (ctx.scaleData.realDistance && ctx.scaleData.pixelDistance) {
      updatedQuery.upp = parseFloat(ctx.scaleData.realDistance) / ctx.scaleData.pixelDistance;
      updatedQuery.unit = ctx.scaleData.scaleUnits;

      Vue.axios
        .post(`${API_URL || ''}/api/maps/scale_update`, {
          action: 'U',
          items: {
            [updatedQuery.id]: { unit: updatedQuery.unit, upp: updatedQuery.upp }
          }
        })
        .then(
          (response) => {
            if (response.data.status === 'success') {
              VueNotifications.success({ message: ctx.$t('maps.notificationMapScaleUpdated') });
              ctx.resetScaleData();
              ctx.disableEditMode();
              // ctx.refreshMaps();
              ctx.refreshAndClearMaps();
            } else if (response.data.status === 'error') {
              VueNotifications.error({ message: response.data.description });
            }
          },
          (err) => {
            ctx.error = err;
          }
        );
    }
  },
  getClients(id, dateRange) {
    const start = moment(dateRange.startDate).unix();
    const stop = moment(dateRange.endDate).unix();
    return Vue.axios.post(`${API_URL || ''}/api/maps/mapclients`, {
      action: 'R',
      timebounds: { start, stop },
      ids: [id]
    });
  },
  getMarkerClients(ctx, id, callback) {
    ctx.$store.commit('toggleLoadingMapClients', true);
    const start = moment(ctx.dateRange.startDate).unix();
    const stop = moment(ctx.dateRange.endDate).unix();

    Vue.axios
      .post(`${API_URL || ''}/api/maps/passerby`, {
        action: 'R',
        timebounds: { start, stop },
        ids: [id]
      })
      .then(
        (response) => {
          if (response.data.status === 'success') {
            ctx.warningMapModal = false;
            ctx.clientsOnMap = response.data.data.itemslist;
            if (typeof callback === 'function') {
              callback();
            }
            ctx.$store.commit('toggleLoadingMapClients', false);
          } else if (response.data.status === 'error') {
            ctx.warningMapModal = true;
            VueNotifications.error({ message: response.data.description });
            ctx.$store.commit('toggleLoadingMapClients', false);
          }
        },
        (err) => {
          // VueNotifications.error({ message: err });

          // show errors in the console instead of the alert window
          if (
            Object.prototype.hasOwnProperty.call(window, 'isShowAxiosErrorInConsole') &&
            typeof window.isShowAxiosErrorInConsole === 'function' &&
            window.isShowAxiosErrorInConsole(err)
          ) {
            window.showAxiosErrInConsole(err);
          } else {
            VueNotifications.error({ message: err });
          }

          ctx.$store.commit('toggleLoadingMapClients', false);
          ctx.error = err;
        }
      );
  },
  getBubblesTimeline(ctx, id, type, callback) {
    ctx.$store.commit('toggleLoadingMapClients', true);

    const SEGMENTS = 24;
    const start = moment(ctx.dateRange.startDate).unix();
    const stop = moment(ctx.dateRange.endDate).unix();
    const interval = Math.floor((stop - start) / SEGMENTS);
    ctx.segmentedInterval = interval;
    ctx.segmentedPeriods = [stop];
    ctx.segmentedForBubbles = new Array(SEGMENTS).fill({});
    ctx.segmentedForBubblesCounts = new Array(SEGMENTS).fill(0);

    // // console.log()
    let apiUrl = '/api/bubble/passerby';
    if (type === 'bubble_lbs') {
      apiUrl = '/api/bubble/passerby';
    } else if (type === 'bubble_clients') {
      apiUrl = '/api/bubble/clients';
    } else if (type === 'bubble_traffic') {
      apiUrl = '/api/bubble/traffic';
    }

    const getSegment = (index) => Vue.axios.post(`${API_URL || ''}${apiUrl}`, {
      action: 'R',
      timebounds: { start: ctx.segmentedPeriods[index], stop: ctx.segmentedPeriods[index + 1] },
      ids: [id]
    });

    for (let i = 1; i <= SEGMENTS; i++) {
      const period = stop - interval * i;
      ctx.segmentedPeriods.unshift(period);
    }

    /* Load previous hour segment */
    const lastSegment = ctx.segmentedForBubbles.length - 1;
    getSegment(lastSegment).then(
      (response) => {
        if (response.data.status === 'success') {
          ctx.selectedSegment = lastSegment;
          const originalArray = response.data.data.itemslist[0];
          const orderedArray = originalArray.sort((curr, prev) => {
            const currTotal = curr.total;
            const nextTotal = prev.total;
            if (nextTotal) {
              if (currTotal > nextTotal) {
                return -1;
              }
              if (currTotal === nextTotal) {
                return 0;
              }
              return 1;
            }
          });
          let maxTotal = 0;
          let minTotal = 0;
          if (orderedArray.length) {
            maxTotal = orderedArray[0].total;
            minTotal = orderedArray.length > 1 ? orderedArray[orderedArray.length - 1].total : orderedArray[0].total;
          }
          let counter = 0;
          orderedArray.forEach((item) => {
            counter += item.total;
          });
          ctx.fillBubbleForTimeLine(originalArray, maxTotal, minTotal, lastSegment);
          // ctx.segmentedClientsCounts[lastSegment] = segment.length;
          ctx.segmentedForBubblesCounts[lastSegment] = counter;
          if (typeof callback === 'function') {
            callback(lastSegment);
          }
          ctx.$store.commit('toggleLoadingMapClients', false);
        } else if (response.data.status === 'error') {
          // ctx.warningMapModal = true;
          VueNotifications.error({ message: response.data.description });
          ctx.$store.commit('toggleLoadingMapClients', false);
        }
      },
      (err) => {
        // VueNotifications.error({ message: err });

        // // show errors in the console instead of the alert window
        // if (
        //   Object.prototype.hasOwnProperty.call(window, 'isShowAxiosErrorInConsole') &&
        //   typeof window.isShowAxiosErrorInConsole === 'function' &&
        //   window.isShowAxiosErrorInConsole(err)
        // ) {
        //   window.showAxiosErrInConsole(err);
        // } else {
        //   VueNotifications.error({ message: err });
        // }

        VueNotifications.error({ message: err });

        ctx.$store.commit('toggleLoadingMapClients', false);
        ctx.error = err;
      }
    );

    /* Load other segments asynchronously */
    const requests = [];
    for (let i = 0; i < ctx.segmentedPeriods.length - 2; i++) {
      requests.push(getSegment(i));
    }

    /* Polyfill for old Safari; Remove if allSettled is no longer used */
    allSettled.shim();
    Promise.allSettled(requests).then((responses) => responses.forEach(
      (response, index) => {
        if (response.status === 'fulfilled' && response.value.data.status === 'success') {
          // const segment = response.value.data.data.itemslist;
          // ctx.segmentedForBubbles[index] = segment;
          // Vue.set(ctx.segmentedForBubblesCounts, index, segment.length);
          const originalArray = response.value.data.data.itemslist[0];
          const orderedArray = originalArray.sort((curr, prev) => {
            const currTotal = curr.total;
            const nextTotal = prev.total;
            if (nextTotal) {
              if (currTotal > nextTotal) {
                return -1;
              }
              if (currTotal === nextTotal) {
                return 0;
              }
              return 1;
            }
          });
          let maxTotal = 0;
          let minTotal = 0;
          if (orderedArray.length) {
            maxTotal = orderedArray[0].total;
            minTotal = orderedArray.length > 1 ? orderedArray[orderedArray.length - 1].total : orderedArray[0].total;
          }
          let counter = 0;
          orderedArray.forEach((item) => {
            counter += item.total;
          });
          ctx.fillBubbleForTimeLine(originalArray, maxTotal, minTotal, index);
          Vue.set(ctx.segmentedForBubblesCounts, index, counter);
        } else {
          VueNotifications.error({ message: response.reason });
          ctx.error = response.reason;
        }
      },
      (err) => {
        // VueNotifications.error({ message: err });

        // show errors in the console instead of the alert window
        if (
          Object.prototype.hasOwnProperty.call(window, 'isShowAxiosErrorInConsole') &&
            typeof window.isShowAxiosErrorInConsole === 'function' &&
            window.isShowAxiosErrorInConsole(err)
        ) {
          window.showAxiosErrInConsole(err);
        } else {
          VueNotifications.error({ message: err });
        }

        ctx.error = err;
      }
    ));
  },
  getMarkerClientsTimeline(ctx, id, callback) {
    ctx.$store.commit('toggleLoadingMapClients', true);

    const SEGMENTS = 24;
    const start = moment(ctx.dateRange.startDate).unix();
    const stop = moment(ctx.dateRange.endDate).unix();
    const interval = Math.floor((stop - start) / SEGMENTS);
    ctx.segmentedInterval = interval;
    ctx.segmentedPeriods = [stop];
    ctx.segmentedClients = new Array(SEGMENTS).fill({});
    ctx.segmentedClientsCounts = new Array(SEGMENTS).fill(0);

    const getSegment = (index) => Vue.axios.post(`${API_URL || ''}/api/maps/passerby`, {
      action: 'R',
      timebounds: { start: ctx.segmentedPeriods[index], stop: ctx.segmentedPeriods[index + 1] },
      ids: [id]
    });

    for (let i = 1; i <= SEGMENTS; i++) {
      const period = stop - interval * i;
      ctx.segmentedPeriods.unshift(period);
    }

    /* Load previous hour segment */
    const lastSegment = ctx.segmentedClients.length - 1;
    getSegment(lastSegment).then(
      (response) => {
        if (response.data.status === 'success') {
          ctx.warningMapModal = false;
          const segment = response.data.data.itemslist;
          ctx.segmentedClients[lastSegment] = segment;
          ctx.segmentedClientsCounts[lastSegment] = segment.length;
          ctx.clientsOnMap = segment;
          ctx.selectedSegment = lastSegment;
          if (typeof callback === 'function') {
            callback();
          }
          ctx.$store.commit('toggleLoadingMapClients', false);
        } else if (response.data.status === 'error') {
          ctx.warningMapModal = true;
          VueNotifications.error({ message: response.data.description });
          ctx.$store.commit('toggleLoadingMapClients', false);
        }
      },
      (err) => {
        // VueNotifications.error({ message: err });

        // show errors in the console instead of the alert window
        if (
          Object.prototype.hasOwnProperty.call(window, 'isShowAxiosErrorInConsole') &&
          typeof window.isShowAxiosErrorInConsole === 'function' &&
          window.isShowAxiosErrorInConsole(err)
        ) {
          window.showAxiosErrInConsole(err);
        } else {
          VueNotifications.error({ message: err });
        }

        ctx.$store.commit('toggleLoadingMapClients', false);
        ctx.error = err;
      }
    );

    /* Load other segments asynchronously */
    const requests = [];
    for (let i = 0; i < ctx.segmentedPeriods.length - 2; i++) {
      requests.push(getSegment(i));
    }

    /* Polyfill for old Safari; Remove if allSettled is no longer used */
    allSettled.shim();
    Promise.allSettled(requests).then((responses) => responses.forEach(
      (response, index) => {
        if (response.status === 'fulfilled' && response.value.data.status === 'success') {
          const segment = response.value.data.data.itemslist;
          ctx.segmentedClients[index] = segment;
          Vue.set(ctx.segmentedClientsCounts, index, segment.length);
        } else {
          VueNotifications.error({ message: response.reason });
          ctx.error = response.reason;
        }
      },
      (err) => {
        //  VueNotifications.error({ message: err });

        // show errors in the console instead of the alert window
        if (
          Object.prototype.hasOwnProperty.call(window, 'isShowAxiosErrorInConsole') &&
            typeof window.isShowAxiosErrorInConsole === 'function' &&
            window.isShowAxiosErrorInConsole(err)
        ) {
          window.showAxiosErrInConsole(err);
        } else {
          VueNotifications.error({ message: err });
        }

        ctx.error = err;
      }
    ));
  },

  getMarkerClientsSplitRequests(ctx, id, callback) {
    // ctx.$store.commit('toggleLoadingMapClients', true);
    ctx.$store.commit('setPercentsOfClientsMarkerDownloadsRequestsCount', '5');

    const SEGMENTS = 5;
    const start = moment(ctx.dateRange.startDate).unix();
    const stop = moment(ctx.dateRange.endDate).unix();
    // console.log('start in datepicker', start)
    // console.log('stop in datepicker', stop)
    // console.log('-----------------')
    const interval = Math.floor((stop - start) / SEGMENTS);
    // console.log(interval)
    // ctx.segmentedInterval = interval;
    const segmentedPeriods = [stop];
    // ctx.segmentedClients = new Array(SEGMENTS).fill({});
    // ctx.segmentedClientsCounts = new Array(SEGMENTS).fill(0);

    // убираем все старые точки-почетителей и если включен режим тепловой карты чистим и его даныне
    ctx.clientsOnMap = [];
    if (ctx.showMode.heatmap === true) {
      // console.log('updateheatmap')
      ctx.updateHeatmap();
    }
    if (typeof callback === 'function') {
      // console.log('callback');
      callback();
    }

    const arrForSequentialRequests = [];

    for (let i = 1; i <= SEGMENTS; i++) {
      arrForSequentialRequests.push(i);
      const period = stop - interval * i;
      segmentedPeriods.unshift(period);
    }
    // console.log(segmentedPeriods);

    function makeRequestsFromArray(arr) {
      let index = 0;

      function request() {
        // console.log('start', segmentedPeriods[index]);
        // console.log('stop', (segmentedPeriods[index + 1] - 1))
        ctx.$store.commit('toggleLoadingMapClients', true);
        return Vue.axios
          .post(`${API_URL || ''}/api/maps/passerby`, {
            action: 'R',
            timebounds: { start: segmentedPeriods[index], stop: segmentedPeriods[index + 1] - 1 },
            ids: [id]
          })
          .then((response) => {
            // ctx.$store.commit('toggleLoadingMapClients', true);
            index++;
            // const segment = response.data.data.itemslist;
            // ctx.clientsOnMap = ctx.clientsOnMap.concat(segment);
            // if (typeof callback === 'function') {
            //   // console.log('callback');
            //   callback();
            //   if (ctx.showMode.heatmap === true) {
            //     // console.log('updateheatmap')
            //     ctx.updateHeatmap();
            //   }
            // }
            if (response.data.status === 'success') {
              const segment = response.data.data.itemslist;
              ctx.clientsOnMap = ctx.clientsOnMap.concat(segment);
              if (typeof callback === 'function') {
                // console.log('callback');
                if (ctx.showMode.heatmap === true) {
                  // console.log('updateheatmap')
                  ctx.updateHeatmap();
                }
                callback();
              }
            } else if (response.data.status === 'error') {
              VueNotifications.error({ message: response.data.description });
            }
            // console.log(index);
            // console.log('request', index, 'of', SEGMENTS);
            const stepPercents = (100 / SEGMENTS) * index;
            const stepPercentsRounded = Math.round(stepPercents);
            // console.log(`${stepPercentsRounded}%`);
            // console.log('-----------------');
            ctx.$store.commit('setPercentsOfClientsMarkerDownloadsRequestsCount', `${stepPercentsRounded}`);

            if (index >= arr.length) {
              // тут обработка при последнем запросе
              ctx.$store.commit('setPercentsOfClientsMarkerDownloadsRequestsCount', '95');
              ctx.$nextTick(() => {
                ctx.$store.commit('setPercentsOfClientsMarkerDownloadsRequestsCount', '100');
                ctx.$nextTick(() => {
                  setTimeout(() => {
                    ctx.$store.commit('toggleLoadingMapClients', false);
                    ctx.$store.commit('setPercentsOfClientsMarkerDownloadsRequestsCount', undefined);
                  }, 200);
                });
              });

              // console.log('done')

              return 'done';
            }
            // а тут не при посслденем
            ctx.$store.commit('toggleLoadingMapClients', false);

            return request();
          })
          .catch((err) => {
            index++;
            VueNotifications.error({ message: err });
            // console.log(index);
            // console.log('request', index, 'of', SEGMENTS);
            const stepPercents = (100 / SEGMENTS) * index;
            const stepPercentsRounded = Math.round(stepPercents);
            // console.log(`${stepPercentsRounded}%`);
            // console.log('-----------------');
            ctx.$store.commit('setPercentsOfClientsMarkerDownloadsRequestsCount', `${stepPercentsRounded}`);
            ctx.$store.commit('toggleLoadingMapClients', false);
            if (index >= arr.length) {
              // тут обработка при последнем запросе
              ctx.$store.commit('setPercentsOfClientsMarkerDownloadsRequestsCount', '95');
              ctx.$nextTick(() => {
                ctx.$store.commit('setPercentsOfClientsMarkerDownloadsRequestsCount', '100');
                ctx.$nextTick(() => {
                  setTimeout(() => {
                    ctx.$store.commit('toggleLoadingMapClients', false);
                    ctx.$store.commit('setPercentsOfClientsMarkerDownloadsRequestsCount', undefined);
                  }, 200);
                });
              });

              // console.log('done')

              return 'done';
            }
            // а тут не при посслденем
            ctx.$store.commit('toggleLoadingMapClients', false);

            return request();
          });
      }

      return request();
    }

    // makeRequestsFromArray([0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10]);
    makeRequestsFromArray(arrForSequentialRequests);

    // const getSegment = (index) => Vue.axios.post(`${API_URL || ''}/api/maps/passerby`, {
    //   action: 'R',
    //   timebounds: { start: ctx.segmentedPeriods[index], stop: ctx.segmentedPeriods[index + 1] },
    //   ids: [id]
    // });
    //

    //
    // /* Load previous hour segment */
    // const lastSegment = ctx.segmentedClients.length - 1;
    // getSegment(lastSegment).then(
    //   (response) => {
    //     if (response.data.status === 'success') {
    //       ctx.warningMapModal = false;
    //       const segment = response.data.data.itemslist;
    //       ctx.segmentedClients[lastSegment] = segment;
    //       ctx.segmentedClientsCounts[lastSegment] = segment.length;
    //       // console.log(segment)
    //       ctx.clientsOnMap = ctx.clientsOnMap.concat(segment)
    //       // ctx.clientsOnMap = segment;
    //       // console.log(ctx.clientsOnMap)
    //       ctx.selectedSegment = lastSegment;
    //       if (typeof callback === 'function') {
    //         console.log('callback')
    //         callback();
    //       }
    //
    //     } else if (response.data.status === 'error') {
    //       ctx.warningMapModal = true;
    //       VueNotifications.error({ message: response.data.description });
    //       ctx.$store.commit('toggleLoadingMapClients', false);
    //     }
    //   },
    //   (err) => {
    //     // VueNotifications.error({ message: err });
    //
    //     // show errors in the console instead of the alert window
    //     if (Object.prototype.hasOwnProperty.call(window, 'isShowAxiosErrorInConsole') &&
    //       (typeof window.isShowAxiosErrorInConsole === 'function') &&
    //       window.isShowAxiosErrorInConsole(err)) {
    //       window.showAxiosErrInConsole(err);
    //     } else {
    //       VueNotifications.error({ message: err });
    //     }
    //
    //     ctx.$store.commit('toggleLoadingMapClients', false);
    //     ctx.error = err;
    //   }
    // );

    // /* Load other segments asynchronously */
    // const requests = [];
    // for (let i = 0; i < ctx.segmentedPeriods.length - 2; i++) {
    //   requests.push(getSegment(i));
    // }

    // /* Polyfill for old Safari; Remove if allSettled is no longer used */
    // allSettled.shim();
    // Promise.allSettled(requests).then((responses) => responses.forEach(
    //   (response, index) => {
    //     if (response.status === 'fulfilled' && response.value.data.status === 'success') {
    //       const segment = response.value.data.data.itemslist;
    //       ctx.segmentedClients[index] = segment;
    //       console.log(responses)
    //       ctx.clientsOnMap = ctx.clientsOnMap.concat(segment)
    //       if (typeof callback === 'function') {
    //         console.log('callback')
    //         callback();
    //       }
    //       ctx.$store.commit('toggleLoadingMapClients', false);
    //       Vue.set(ctx.segmentedClientsCounts, index, segment.length);
    //     } else {
    //       VueNotifications.error({ message: response.reason });
    //       ctx.error = response.reason;
    //     }
    //   },
    //   (err) => {
    //    //  VueNotifications.error({ message: err });
    //     ctx.$store.commit('toggleLoadingMapClients', false);
    //     // show errors in the console instead of the alert window
    //     if (Object.prototype.hasOwnProperty.call(window, 'isShowAxiosErrorInConsole') &&
    //       (typeof window.isShowAxiosErrorInConsole === 'function') &&
    //       window.isShowAxiosErrorInConsole(err)) {
    //       window.showAxiosErrInConsole(err);
    //     } else {
    //       VueNotifications.error({ message: err });
    //     }
    //
    //     ctx.error = err;
    //   }
    // ));
  },

  getBubbleLbs(ctx, id) {
    ctx.$store.commit('toggleLoadingMapClients', true);
    Vue.axios
      .post(`${API_URL || ''}/api/bubble/passerby`, {
        action: 'R',
        ids: [id],
        timebounds: {
          start: moment(ctx.dateRange.startDate).unix(),
          stop: moment(ctx.dateRange.endDate).unix()
        }
      })
      .then(
        (response) => {
          if (response.data.status === 'success') {
            const originalArray = response.data.data.itemslist[0];
            const orderedArray = originalArray.sort((curr, prev) => {
              const currTotal = curr.total;
              const nextTotal = prev.total;
              if (nextTotal) {
                if (currTotal > nextTotal) {
                  return -1;
                }
                if (currTotal === nextTotal) {
                  return 0;
                }
                return 1;
              }
            });
            let maxTotal = 0;
            let minTotal = 0;
            if (orderedArray.length) {
              maxTotal = orderedArray[0].total;
              minTotal = orderedArray.length > 1 ? orderedArray[orderedArray.length - 1].total : orderedArray[0].total;
            }
            ctx.drawBubble(originalArray, maxTotal, minTotal);
            ctx.$store.commit('toggleLoadingMapClients', false);
          } else if (response.data.status === 'error') {
            VueNotifications.error({ message: response.data.description });
            ctx.$store.commit('toggleLoadingMapClients', false);
          }
        },
        (err) => {
          // VueNotifications.error({ message: err });

          // show errors in the console instead of the alert window
          // if (
          //   Object.prototype.hasOwnProperty.call(window, 'isShowAxiosErrorInConsole') &&
          //   typeof window.isShowAxiosErrorInConsole === 'function' &&
          //   window.isShowAxiosErrorInConsole(err)
          // ) {
          //   window.showAxiosErrInConsole(err);
          // } else {
          //   VueNotifications.error({ message: err });
          // }

          VueNotifications.error({ message: err });

          ctx.$store.commit('toggleLoadingMapClients', false);
        }
      );
  },
  getBubbleClients(ctx, id) {
    ctx.$store.commit('toggleLoadingMapClients', true);
    Vue.axios
      .post(`${API_URL || ''}/api/bubble/clients`, {
        action: 'R',
        ids: [id],
        timebounds: {
          start: moment(ctx.dateRange.startDate).unix(),
          stop: moment(ctx.dateRange.endDate).unix()
        }
      })
      .then(
        (response) => {
          if (response.data.status === 'success') {
            const originalArray = response.data.data.itemslist[0];
            const orderedArray = originalArray.sort((curr, prev) => {
              const currTotal = curr.total;
              const nextTotal = prev.total;
              if (nextTotal) {
                if (currTotal > nextTotal) {
                  return -1;
                }
                if (currTotal === nextTotal) {
                  return 0;
                }
                return 1;
              }
            });
            let maxTotal = 0;
            let minTotal = 0;
            if (orderedArray.length) {
              maxTotal = orderedArray[0].total;
              minTotal = orderedArray.length > 1 ? orderedArray[orderedArray.length - 1].total : orderedArray[0].total;
            }

            ctx.drawBubble(originalArray, maxTotal, minTotal);
            ctx.$store.commit('toggleLoadingMapClients', false);
          } else if (response.data.status === 'error') {
            VueNotifications.error({ message: response.data.description });
            ctx.$store.commit('toggleLoadingMapClients', false);
          }
        },
        (err) => {
          // VueNotifications.error({ message: err });

          // // show errors in the console instead of the alert window
          // if (
          //   Object.prototype.hasOwnProperty.call(window, 'isShowAxiosErrorInConsole') &&
          //   typeof window.isShowAxiosErrorInConsole === 'function' &&
          //   window.isShowAxiosErrorInConsole(err)
          // ) {
          //   window.showAxiosErrInConsole(err);
          // } else {
          //   VueNotifications.error({ message: err });
          // }

          VueNotifications.error({ message: err });


          ctx.$store.commit('toggleLoadingMapClients', false);
        }
      );
  },
  getBubbleTraffic(ctx, id) {
    ctx.$store.commit('toggleLoadingMapClients', true);
    Vue.axios
      .post(`${API_URL || ''}/api/bubble/traffic`, {
        action: 'R',
        ids: [id],
        timebounds: {
          start: moment(ctx.dateRange.startDate).unix(),
          stop: moment(ctx.dateRange.endDate).unix()
        }
      })
      .then(
        (response) => {
          if (response.data.status === 'success') {
            const originalArray = response.data.data.itemslist[0];
            const orderedArray = originalArray.sort((curr, prev) => {
              const currTotal = curr.total;
              const nextTotal = prev.total;
              if (nextTotal) {
                if (currTotal > nextTotal) {
                  return -1;
                }
                if (currTotal === nextTotal) {
                  return 0;
                }
                return 1;
              }
            });
            let maxTotal = 0;
            let minTotal = 0;
            if (orderedArray.length) {
              maxTotal = orderedArray[0].total;
              minTotal = orderedArray.length > 1 ? orderedArray[orderedArray.length - 1].total : orderedArray[0].total;
            }

            ctx.drawBubble(originalArray, maxTotal, minTotal);
            ctx.$store.commit('toggleLoadingMapClients', false);
          } else if (response.data.status === 'error') {
            VueNotifications.error({ message: response.data.description });
            ctx.$store.commit('toggleLoadingMapClients', false);
          }
        },
        (err) => {
          // VueNotifications.error({ message: err });

          // // show errors in the console instead of the alert window
          // if (
          //   Object.prototype.hasOwnProperty.call(window, 'isShowAxiosErrorInConsole') &&
          //   typeof window.isShowAxiosErrorInConsole === 'function' &&
          //   window.isShowAxiosErrorInConsole(err)
          // ) {
          //   window.showAxiosErrInConsole(err);
          // } else {
          //   VueNotifications.error({ message: err });
          // }

          VueNotifications.error({ message: err });

          ctx.$store.commit('toggleLoadingMapClients', false);
        }
      );
  },
  getFiltredClients(ctx, map_id, value) {
    const start = moment(ctx.dateRange.startDate).unix();
    const stop = moment(ctx.dateRange.endDate).unix();

    Vue.axios
      .post(`${API_URL || ''}/api/maps/passerby`, {
        action: 'R',
        timebounds: { start, stop },
        ids: [map_id],
        search: value,
        insensitive: true
      })
      .then(
        (response) => {
          if (response.data.status === 'success') {
            ctx.clientsMarker = [];
            const foundClients = response.data.data.itemslist;
            foundClients.forEach((client) => {
              const clientMarker = L.marker(client, {
                mac: client.mac,
                mac_addr: client.mac_addr,
                vendor: client.vendor,
                timestamp: client.timestamp,
                loadaedFromSearch: true // потом по этому флагу удаляем элемент с карты
              });
              clientMarker
                .bindTooltip(
                  `<ul class="list-unstyled m-0 p-0" id="client-marker-form-search${client.mac}${client.timestamp}">
                            <li><strong>MAC:</strong>  ${client.mac}</li>
                            <li><strong>Vendor:</strong>  ${client.vendor}</li>
                        </ul>`,
                  {
                    permanent: false,
                    direction: 'top',
                    offset: [0, -10]
                  }
                )
                .openTooltip();
              clientMarker.on('mouseover', (event) => {
                // console.log('over')
                const htmlId = `client-marker-form-search${client.mac}${client.timestamp}`;
                ctx.getClientInfoFromApiForTooltip(client.mac, htmlId, ctx);
              });
              clientMarker.on('mouseout', (event) => {
                // ctx.selectedClientDataFromClientsApiForTooltip = {};
                ctx.selectedClientDataFromClientsApi = {};
              });
              ctx.clientsMarker.push(clientMarker);
            });
            ctx.searchSpinner = false;
            ctx.toggleSearchIcon();
          } else if (response.data.status === 'error') {
            VueNotifications.error({ message: response.data.description });
            ctx.searchSpinner = false;
          }
        },
        (err) => {
          // VueNotifications.error({ message: err });

          // show errors in the console instead of the alert window
          if (
            Object.prototype.hasOwnProperty.call(window, 'isShowAxiosErrorInConsole') &&
            typeof window.isShowAxiosErrorInConsole === 'function' &&
            window.isShowAxiosErrorInConsole(err)
          ) {
            window.showAxiosErrInConsole(err);
          } else {
            VueNotifications.error({ message: err });
          }

          ctx.error = err;
          ctx.searchSpinner = false;
        }
      );
  },
  // getClientPath(ctx, mac) {
  //   return Vue.axios.post(`${API_URL || ''}/api/maps/passerby/track`, {
  //     action: 'R',
  //     ids: [ctx.currentMap.id],
  //     items: { [mac]: {} },
  //     timebounds: {
  //       start: Math.round(ctx.dateRange.startDate / 1000),
  //       stop: Math.round(ctx.dateRange.endDate / 1000)
  //     }
  //   });
  // },
  getClientPath(ctx, mac) {
    let start = Math.round(ctx.dateRange.startDate / 1000);
    let stop = Math.round(ctx.dateRange.endDate / 1000);

    // а тут обрабатываем случай, когда включили режим динамика и там тыкают запрос пути, нужно взять оттуда
    // start и stop
    //
    if (ctx.showMode.timeline) {
      // тут если включили режим Динамика, но так и не тыкнули по столбцу,
      // делаем запрос с тамймстампами
      // самого последнего сегмента
      if (ctx.mapVisitorsSelectedSegmentNumber === undefined) {
        start = ctx.segmentedPeriods[ctx.segmentedPeriods.length - 2];
        stop = ctx.segmentedPeriods[ctx.segmentedPeriods.length - 1];
      } else {
        // а тут если тыкали и номер сегмента записался в mapVisitorsSelectedSegmentNumber
        // вылавливаем ts уже на основе номера выбраного сегмента
        start = ctx.segmentedPeriods[ctx.mapVisitorsSelectedSegmentNumber];
        stop = ctx.segmentedPeriods[ctx.mapVisitorsSelectedSegmentNumber + 1];
      }
    }

    return Vue.axios.post(`${API_URL || ''}/api/maps/passerby/track`, {
      action: 'R',
      ids: [ctx.currentMap.id],
      items: { [mac]: {} },
      timebounds: {
        start,
        stop
      }
    });
  },
  // getCpesRatingAndDrawMap(ctx, cpesIdList) {
  //   ctx.cpesStatisticOnMap = {};
  //   ctx.$store.commit('toggleLoadingCpesRating');
  //   // const start = moment(ctx.drPickerDateForRating.startDate).unix();
  //   // const stop = moment(ctx.drPickerDateForRating.endDate).unix();
  //
  //
  //   const query = {
  //     action: 'R',
  //     // timebounds: { start, stop },
  //     ids: cpesIdList
  //   };
  //
  //   Vue.axios
  //     .post(`${API_URL || ''}/api/stat/rating/cpes`, query)
  //     .then((response) => {
  //       if (response.data.status === 'success') {
  //         const cpesRatingList = response.data.data.itemslist;
  //         // ctx.$store.commit('setCpesRatingList', cpesRatingList);
  //         // console.log(cpesRatingList);
  //         for (const cpeInfo of cpesRatingList) {
  //           ctx.cpesStatisticOnMap[cpeInfo.id] = cpeInfo;
  //         }
  //         ctx.$store.commit('toggleLoadingCpesRating');
  //       } else if (response.data.status === 'error') {
  //         ctx.$store.commit('toggleLoadingCpesRating');
  //         VueNotifications.error({ message: response.data.description });
  //       }
  //       ctx.getMapObject();
  //       ctx.selectMapMode();
  //       ctx.initLeaflet();
  //     }, (err) => {
  //       ctx.getMapObject();
  //       ctx.selectMapMode();
  //       ctx.initLeaflet();
  //       ctx.$store.commit('toggleLoadingCpesRating');
  //       VueNotifications.error({ message: err });
  //     });
  // },
  // getCpesRatingAndClientsAndRefreshMapCpes(ctx, cpesIdList) {
  //   ctx.cpesStatisticOnMap = {};
  //   ctx.cpesStatisticClientsOnMap = {};
  //   // ctx.$store.commit('toggleLoadingCpesRating');
  //   ctx.$store.commit('beginLoadingMapCpesStatisticAndClients');
  //   ctx.$store.commit('beginLoadingUsersInfoForSelectedCpeOnMap');
  //
  //   // настройки  запроса для данных о нагрузке на CPU и загруженности памяти точки
  //   const startForCpesRating = moment().unix() - 60 * 60; // сейчас минус час;
  //   const stopForCpesRating = moment().endOf('day').unix();
  //   const queryForCpesRating = {
  //     action: 'R',
  //     timebounds: { start: startForCpesRating, stop: stopForCpesRating },
  //     ids: cpesIdList,
  //     location: ctx.currentMap.base_location ? ctx.currentMap.base_location : '',
  //     with_childs: true
  //   };
  //
  //   // настройки запроса про количетсво подключенных пользователей к точке
  //   const queryForCpesClients = {
  //     action: 'R',
  //     location: ctx.$store.getters.getDefaultLocationForRequests(ctx), with_childs: ctx.$store.getters.getDefaultWithChildsForRequests(ctx),
  //     sort_by: { field: 'state', order: 1 },
  //     q: {
  //       state: 'CONNECTED',
  //       cpe_id: { $in: cpesIdList }
  //     }
  //   };
  //   const cpesRating = Vue.axios.post(`${API_URL || ''}/api/stat/rating/cpes`, queryForCpesRating).then(
  //     (response) => {
  //       if (response.data.status === 'success') {
  //         const cpesRatingList = response.data.data.itemslist;
  //         // ctx.$store.commit('setCpesRatingList', cpesRatingList);
  //         // console.log(cpesRatingList);
  //         for (const cpeInfo of cpesRatingList) {
  //           ctx.cpesStatisticOnMap[cpeInfo.id] = cpeInfo;
  //         }
  //         // ctx.$store.commit('toggleLoadingCpesRating');
  //       } else if (response.data.status === 'error') {
  //         // ctx.$store.commit('toggleLoadingCpesRating');
  //         VueNotifications.error({ message: response.data.description });
  //       }
  //     },
  //     (err) => {
  //       // ctx.$store.commit('toggleLoadingCpesRating');
  //       // VueNotifications.error({ message: err });
  //
  //       // show errors in the console instead of the alert window
  //       if (
  //         Object.prototype.hasOwnProperty.call(window, 'isShowAxiosErrorInConsole') &&
  //         typeof window.isShowAxiosErrorInConsole === 'function' &&
  //         window.isShowAxiosErrorInConsole(err)
  //       ) {
  //         window.showAxiosErrInConsole(err);
  //       } else {
  //         VueNotifications.error({ message: err });
  //       }
  //     }
  //   );
  //
  //   const cpesClients = Vue.axios.post(`${API_URL || ''}/api/clients`, queryForCpesClients).then(
  //     (response) => {
  //       if (response.data.status === 'success') {
  //         const itemsList = response.data.data.itemslist;
  //         for (const client of itemsList) {
  //           if (ctx.cpesStatisticClientsOnMap.hasOwnProperty(client.cpe_id)) {
  //             ctx.cpesStatisticClientsOnMap[client.cpe_id].push(client);
  //           } else {
  //             ctx.cpesStatisticClientsOnMap[client.cpe_id] = [];
  //             ctx.cpesStatisticClientsOnMap[client.cpe_id].push(client);
  //           }
  //           // console.log(ctx.cpesStatisticClientsOnMap);
  //         }
  //         // ctx.userListForSelectedCpe = itemsList;
  //         ctx.$store.commit('endLoadingUsersInfoForSelectedCpeOnMap');
  //       } else if (response.data.status === 'error') {
  //         ctx.$store.commit('endLoadingUsersInfoForSelectedCpeOnMap');
  //         VueNotifications.error({ message: response.data.description });
  //       }
  //     },
  //     (err) => {
  //       ctx.$store.commit('endLoadingUsersInfoForSelectedCpeOnMap');
  //       VueNotifications.error({ message: err });
  //     }
  //   );
  //   /* Polyfill for old Safari; Remove if allSettled is no longer used */
  //   allSettled.shim();
  //   Promise.allSettled([cpesRating, cpesClients]).then(() => {
  //     // console.log('refresh');
  //     ctx.refreshMapCpesNOgetCpesRatingAndClients();
  //     ctx.$store.commit('endLoadingMapCpesStatisticAndClients');
  //     ctx.$store.commit('endLoadingUsersInfoForSelectedCpeOnMap');
  //   });
  // },

  getCpesRatingAndClientsAndRefreshMapCpes(ctx, cpesIdList) {

    // ctx.$store.commit('toggleLoadingCpesRating');
    ctx.$store.commit('beginLoadingUsersInfoForSelectedCpeOnMap');
    ctx.$store.commit('beginLoadingMapCpesStatisticAndClients');

    ctx.cpesStatisticOnMap = {};
    ctx.cpesStatisticClientsOnMap = {};


    // настройки  запроса для данных о нагрузке на CPU и загруженности памяти точки
    const startForCpesRating = moment().unix() - 60 * 60; // сейчас минус час;
    const stopForCpesRating = moment().endOf('day').unix();
    const queryForCpesRating = {
      action: 'R',
      timebounds: { start: startForCpesRating, stop: stopForCpesRating },
      ids: cpesIdList,
      location: ctx.currentMap.base_location ? ctx.currentMap.base_location : '',
      with_childs: true
    };

    // настройки запроса про количетсво подключенных пользователей к точке
    const queryForCpesClients = {
      action: 'R',
      sort_by: { field: 'state', order: 1 },
      q: {
        state: 'CONNECTED',
        cpe_id: { $in: cpesIdList }
      },
      location: ctx.$store.getters.getDefaultLocationForRequests(ctx),
      with_childs: ctx.$store.getters.getDefaultWithChildsForRequests(ctx),
    };
    const cpesRating = Vue.axios.post(`${API_URL || ''}/api/stat/rating/cpes`, queryForCpesRating).then(
      (response) => {
        if (response.data.status === 'success') {
          const cpesRatingList = response.data.data.itemslist;
          // ctx.$store.commit('setCpesRatingList', cpesRatingList);
          // console.log(cpesRatingList);
          for (const cpeInfo of cpesRatingList) {
            ctx.cpesStatisticOnMap[cpeInfo.id] = cpeInfo;
          }
           ctx.$store.commit('endLoadingMapCpesStatisticAndClients');
          // ctx.$store.commit('toggleLoadingCpesRating');
        } else if (response.data.status === 'error') {
          // ctx.$store.commit('toggleLoadingCpesRating');
          VueNotifications.error({ message: response.data.description });
           ctx.$store.commit('endLoadingMapCpesStatisticAndClients');
        }
      },
      (err) => {
        // ctx.$store.commit('toggleLoadingCpesRating');
        // VueNotifications.error({ message: err });

        // show errors in the console instead of the alert window
        // if (
        //   Object.prototype.hasOwnProperty.call(window, 'isShowAxiosErrorInConsole') &&
        //   typeof window.isShowAxiosErrorInConsole === 'function' &&
        //   window.isShowAxiosErrorInConsole(err)
        // ) {
        //   window.showAxiosErrInConsole(err);
        // } else {
        //   VueNotifications.error({ message: err });
        // }
        ctx.$store.commit('endLoadingMapCpesStatisticAndClients');
        VueNotifications.error({ message: err })
      }
    );

    const cpesClients = Vue.axios.post(`${API_URL || ''}/api/clients`, queryForCpesClients).then(
      (response) => {
        if (response.data.status === 'success') {
          const itemsList = response.data.data.itemslist;
          for (const client of itemsList) {
            if (ctx.cpesStatisticClientsOnMap.hasOwnProperty(client.cpe_id)) {
              ctx.cpesStatisticClientsOnMap[client.cpe_id].push(client);
            } else {
              ctx.cpesStatisticClientsOnMap[client.cpe_id] = [];
              ctx.cpesStatisticClientsOnMap[client.cpe_id].push(client);
            }
            // console.log(ctx.cpesStatisticClientsOnMap);
          }
          // ctx.userListForSelectedCpe = itemsList;
          ctx.$store.commit('endLoadingUsersInfoForSelectedCpeOnMap');
        } else if (response.data.status === 'error') {
          ctx.$store.commit('endLoadingUsersInfoForSelectedCpeOnMap');
          VueNotifications.error({ message: response.data.description });
        }
      },
      (err) => {
        ctx.$store.commit('endLoadingUsersInfoForSelectedCpeOnMap');
        VueNotifications.error({ message: err });
      }
    );
    /* Polyfill for old Safari; Remove if allSettled is no longer used */
    allSettled.shim();
    Promise.allSettled([cpesRating, cpesClients]).then(() => {
      // console.log('refresh');
      ctx.refreshMapCpesNOgetCpesRatingAndClients();
      ctx.$store.commit('endLoadingMapCpesStatisticAndClients');
      ctx.$store.commit('endLoadingUsersInfoForSelectedCpeOnMap');
    });
  },


  // getMarkerClientsAPIV2(ctx, id, callback) {
  //   console.log('refresh')
  //   ctx.$store.commit('toggleLoadingMapClients', true);
  //   const start = moment(ctx.dateRange.startDate).unix();
  //   const stop = moment(ctx.dateRange.endDate).unix();
  //
  //   const mockResult = [
  //     {
  //       lat: 3.437,
  //       lng: 11.624,
  //       total: 900,
  //       items: ['mac1', 'mac2'],
  //       zone: ''
  //     },
  //     {
  //       lat: 4.437,
  //       lng: 12.624,
  //       total: 10000,
  //       items: ['mac1', 'mac2', 'mac3', 'mac4', 'mac5', 'mac6', 'mac7', 'mac8', 'mac9', 'mac10'],
  //       zone: ''
  //     },
  //     {
  //       lat: 2.437,
  //       lng: 9.624,
  //       total: 1,
  //       mac: 'c8:f7:33:1e:aa:0f',
  //       mac_addr: 'c8:f7:33:1e:aa:0f',
  //       timestamp: 1623676551,
  //       vendor: 'HTC',
  //       zone: ''
  //     },
  //     {
  //       lat: 4.437,
  //       lng: 1.624,
  //       total: 1,
  //       mac: 'c8:f7:33:1e:aa:0f',
  //       mac_addr: 'c8:f7:33:1e:aa:0f',
  //       timestamp: 1623676551,
  //       vendor: 'HTC',
  //       zone: ''
  //     },
  //     {
  //       lat: 5.437,
  //       lng: 4.624,
  //       total: 1,
  //       mac: 'c8:f7:33:1e:aa:0f',
  //       mac_addr: 'c8:f7:33:1e:aa:0f',
  //       timestamp: 1623676551,
  //       vendor: 'HTC',
  //       zone: ''
  //     },
  //     {
  //       lat: 1.437,
  //       lng: 8.624,
  //       total: 1,
  //       mac: 'c8:f7:33:1e:aa:0f',
  //       mac_addr: 'c8:f7:33:1e:aa:0f',
  //       timestamp: 1623676551,
  //       vendor: 'HUAWEI',
  //       zone: ''
  //     },
  //     {
  //       lat: 1.237,
  //       lng: 8.724,
  //       total: 50000,
  //       items: ['mac1', 'mac2', 'mac3', 'mac4', 'mac5'],
  //       zone: ''
  //     },
  //     {
  //       lat: 3.237,
  //       lng: 2.724,
  //       total: 9000,
  //       items: ['mac1', 'mac2', 'mac3', 'mac4', 'mac5'],
  //       zone: ''
  //     },
  //     {
  //       lat: 6.237,
  //       lng: 6.724,
  //       total: 7500,
  //       items: ['mac1', 'mac2', 'mac3', 'mac4', 'mac5'],
  //       zone: ''
  //     },
  //     {
  //       lat: 6.237,
  //       lng: 5.724,
  //       total: 25000,
  //       items: ['mac1', 'mac2', 'mac3', 'mac4', 'mac5'],
  //       zone: ''
  //     },
  //     {
  //       lat: 6.937,
  //       lng: 5.224,
  //       total: 1,
  //       mac: 'c8:f7:33:1e:aa:0f',
  //       mac_addr: 'c8:f7:33:1e:aa:0f',
  //       timestamp: 1623676551,
  //       vendor: 'HUAWEI',
  //       zone: ''
  //     },
  //     {
  //       lat: 6.837,
  //       lng: 5.624,
  //       total: 1,
  //       mac: 'c8:f7:33:1e:aa:0f',
  //       mac_addr: 'c8:f7:33:1e:aa:0f',
  //       timestamp: 1623676551,
  //       vendor: 'HUAWEI',
  //       zone: ''
  //     },
  //     {
  //       lat: 6.637,
  //       lng: 5.324,
  //       total: 1,
  //       mac: 'c8:f7:33:1e:aa:0f',
  //       mac_addr: 'c8:f7:33:1e:aa:0f',
  //       timestamp: 1623676551,
  //       vendor: 'HUAWEI',
  //       zone: ''
  //     },
  //     {
  //       lat: 6.638,
  //       lng: 5.324,
  //       total: 1,
  //       mac: 'c8:f7:33:1e:aa:0f',
  //       mac_addr: 'c8:f7:33:1e:aa:0f',
  //       timestamp: 1623676551,
  //       vendor: 'HUAWEI',
  //       zone: ''
  //     },
  //     {
  //       lat: 6.638,
  //       lng: 5.324,
  //       total: 25,
  //       items: ['mac1', 'mac2', 'mac3', 'mac4', 'mac5'],
  //       zone: ''
  //     },
  //   ];
  //   ctx.clientsOnMap = mockResult
  //   if (typeof callback === 'function') {
  //     setTimeout(() => {
  //       ctx.$store.commit('toggleLoadingMapClients', false);
  //       callback();
  //     }, 500);
  //     // callback();
  //   }
  //
  //
  //
  //   // Vue.axios
  //   //   .post(`${API_URL || ''}/api/maps/passerbyV2`, {
  //   //     action: 'R',
  //   //     timebounds: { start, stop },
  //   //     ids: [id]
  //   //   })
  //   //   .then(
  //   //     (response) => {
  //   //       if (response.data.status === 'success') {
  //   //         ctx.warningMapModal = false;
  //   //         ctx.clientsOnMap = response.data.data.itemslist;
  //   //         if (typeof callback === 'function') {
  //   //           callback();
  //   //         }
  //   //         ctx.$store.commit('toggleLoadingMapClients', false);
  //   //       } else if (response.data.status === 'error') {
  //   //         ctx.warningMapModal = true;
  //   //         VueNotifications.error({ message: response.data.description });
  //   //         ctx.$store.commit('toggleLoadingMapClients', false);
  //   //       }
  //   //     },
  //   //     (err) => {
  //   //       // VueNotifications.error({ message: err });
  //   //
  //   //       // show errors in the console instead of the alert window
  //   //       if (Object.prototype.hasOwnProperty.call(window, 'isShowAxiosErrorInConsole') &&
  //   //         (typeof window.isShowAxiosErrorInConsole === 'function') &&
  //   //         window.isShowAxiosErrorInConsole(err)) {
  //   //         window.showAxiosErrInConsole(err);
  //   //       } else {
  //   //         VueNotifications.error({ message: err });
  //   //       }
  //   //
  //   //       ctx.$store.commit('toggleLoadingMapClients', false);
  //   //       ctx.error = err;
  //   //     }
  //   //   );
  // },
  getMarkerClientsAPIV2(ctx, id, callback) {
    // console.log('refresh')
    ctx.$store.commit('toggleLoadingMapClients', true);
    const start = moment(ctx.dateRange.startDate).unix();
    const stop = moment(ctx.dateRange.endDate).unix();

    //   Vue.axios
    // .post(`${API_URL || ''}/api/maps/heatmap`, {
    //   action: 'R',
    //   timebounds: { start, stop },
    //   ids: [id],
    //   q: { x: this.getGridSize(ctx).x, y: this.getGridSize(ctx).y }
    // })
    const reqData = {
      action: 'R',
      timebounds: { start, stop },
      ids: [id],
      q: { x: this.getGridSize(ctx).x, y: this.getGridSize(ctx).y }
    };

    if (ctx.addToRequestsWithOutNightClientsSettings) {
      reqData.with_out_night_clients = true;
    }

    Vue.axios.post(`${API_URL || ''}/api/maps/heatmap`, reqData).then(
      (response) => {
        if (response.data.status === 'success') {
          ctx.warningMapModal = false;
          // console.log(response.data.data.itemslist)
          // const itemsFromRequest = response.data.data.itemslist
          // for (const item of itemsFromRequest){
          //   //console.log(item.x)
          //   //console.log(item.y)
          //   //console.log(item.uniq_mac)
          //   item.lat = item.x;
          //   item.lng = item.y;
          //   item.total = item.uniq_mac;
          // }
          // ctx.clientsOnMap = itemsFromRequest;
          const itemsFromRequest = response.data.data.itemslist;
          const itemsFromRequestWithLatLng = this.addLatLng(itemsFromRequest, ctx);
          ctx.clientsOnMap = itemsFromRequestWithLatLng;
          // mock for heatmap and visitors data
          // ctx.clientsOnMap.forEach((item) => {
          //   const min = Math.ceil(0);
          //   const max = Math.floor(10000);
          //   if (Math.random() < 0.5) {
          //     item.total = Math.floor(Math.random() * (max - min + 1)) + min;
          //   }
          //
          // })
          if (typeof callback === 'function') {
            callback();
          }
          if (ctx.showMode.heatmap === true) {
            // console.log('updateheatmap')
            ctx.updateHeatmap();
          }
          ctx.$store.commit('toggleLoadingMapClients', false);
        } else if (response.data.status === 'error') {
          ctx.warningMapModal = true;
          VueNotifications.error({ message: response.data.description });
          ctx.$store.commit('toggleLoadingMapClients', false);
        }
      },
      (err) => {
        // VueNotifications.error({ message: err });

        // // show errors in the console instead of the alert window
        // if (
        //   Object.prototype.hasOwnProperty.call(window, 'isShowAxiosErrorInConsole') &&
        //   typeof window.isShowAxiosErrorInConsole === 'function' &&
        //   window.isShowAxiosErrorInConsole(err)
        // ) {
        //   window.showAxiosErrInConsole(err);
        // } else {
        //   VueNotifications.error({ message: err });
        // }

        VueNotifications.error({ message: err });

        ctx.$store.commit('toggleLoadingMapClients', false);
        ctx.error = err;
      }
    );

    // const mockResult = [
    //   {
    //     lat: 3.437,
    //     lng: 11.624,
    //     total: 900,
    //     items: ['mac1', 'mac2'],
    //     zone: ''
    //   },
    //   {
    //     lat: 4.437,
    //     lng: 12.624,
    //     total: 10000,
    //     items: ['mac1', 'mac2', 'mac3', 'mac4', 'mac5', 'mac6', 'mac7', 'mac8', 'mac9', 'mac10'],
    //     zone: ''
    //   },
    //   {
    //     lat: 2.437,
    //     lng: 9.624,
    //     total: 1,
    //     mac: 'c8:f7:33:1e:aa:0f',
    //     mac_addr: 'c8:f7:33:1e:aa:0f',
    //     timestamp: 1623676551,
    //     vendor: 'HTC',
    //     zone: ''
    //   },
    //   {
    //     lat: 4.437,
    //     lng: 1.624,
    //     total: 1,
    //     mac: 'c8:f7:33:1e:aa:0f',
    //     mac_addr: 'c8:f7:33:1e:aa:0f',
    //     timestamp: 1623676551,
    //     vendor: 'HTC',
    //     zone: ''
    //   },
    //   {
    //     lat: 5.437,
    //     lng: 4.624,
    //     total: 1,
    //     mac: 'c8:f7:33:1e:aa:0f',
    //     mac_addr: 'c8:f7:33:1e:aa:0f',
    //     timestamp: 1623676551,
    //     vendor: 'HTC',
    //     zone: ''
    //   },
    //   {
    //     lat: 1.437,
    //     lng: 8.624,
    //     total: 1,
    //     mac: 'c8:f7:33:1e:aa:0f',
    //     mac_addr: 'c8:f7:33:1e:aa:0f',
    //     timestamp: 1623676551,
    //     vendor: 'HUAWEI',
    //     zone: ''
    //   },
    //   {
    //     lat: 1.237,
    //     lng: 8.724,
    //     total: 50000,
    //     items: ['mac1', 'mac2', 'mac3', 'mac4', 'mac5'],
    //     zone: ''
    //   },
    //   {
    //     lat: 3.237,
    //     lng: 2.724,
    //     total: 9000,
    //     items: ['mac1', 'mac2', 'mac3', 'mac4', 'mac5'],
    //     zone: ''
    //   },
    //   {
    //     lat: 6.237,
    //     lng: 6.724,
    //     total: 7500,
    //     items: ['mac1', 'mac2', 'mac3', 'mac4', 'mac5'],
    //     zone: ''
    //   },
    //   {
    //     lat: 6.237,
    //     lng: 5.724,
    //     total: 25000,
    //     items: ['mac1', 'mac2', 'mac3', 'mac4', 'mac5'],
    //     zone: ''
    //   },
    //   {
    //     lat: 6.937,
    //     lng: 5.224,
    //     total: 1,
    //     mac: 'c8:f7:33:1e:aa:0f',
    //     mac_addr: 'c8:f7:33:1e:aa:0f',
    //     timestamp: 1623676551,
    //     vendor: 'HUAWEI',
    //     zone: ''
    //   },
    //   {
    //     lat: 6.837,
    //     lng: 5.624,
    //     total: 1,
    //     mac: 'c8:f7:33:1e:aa:0f',
    //     mac_addr: 'c8:f7:33:1e:aa:0f',
    //     timestamp: 1623676551,
    //     vendor: 'HUAWEI',
    //     zone: ''
    //   },
    //   {
    //     lat: 6.637,
    //     lng: 5.324,
    //     total: 1,
    //     mac: 'c8:f7:33:1e:aa:0f',
    //     mac_addr: 'c8:f7:33:1e:aa:0f',
    //     timestamp: 1623676551,
    //     vendor: 'HUAWEI',
    //     zone: ''
    //   },
    //   {
    //     lat: 6.638,
    //     lng: 5.324,
    //     total: 1,
    //     mac: 'c8:f7:33:1e:aa:0f',
    //     mac_addr: 'c8:f7:33:1e:aa:0f',
    //     timestamp: 1623676551,
    //     vendor: 'HUAWEI',
    //     zone: ''
    //   },
    //   {
    //     lat: 6.638,
    //     lng: 5.324,
    //     total: 25,
    //     items: ['mac1', 'mac2', 'mac3', 'mac4', 'mac5'],
    //     zone: ''
    //   },
    // ];
    // ctx.clientsOnMap = mockResult
    // if (typeof callback === 'function') {
    //   setTimeout(() => {
    //     ctx.$store.commit('toggleLoadingMapClients', false);
    //     callback();
    //   }, 500);
    //   // callback();
    // }

    // Vue.axios
    //   .post(`${API_URL || ''}/api/maps/passerbyV2`, {
    //     action: 'R',
    //     timebounds: { start, stop },
    //     ids: [id]
    //   })
    //   .then(
    //     (response) => {
    //       if (response.data.status === 'success') {
    //         ctx.warningMapModal = false;
    //         ctx.clientsOnMap = response.data.data.itemslist;
    //         if (typeof callback === 'function') {
    //           callback();
    //         }
    //         ctx.$store.commit('toggleLoadingMapClients', false);
    //       } else if (response.data.status === 'error') {
    //         ctx.warningMapModal = true;
    //         VueNotifications.error({ message: response.data.description });
    //         ctx.$store.commit('toggleLoadingMapClients', false);
    //       }
    //     },
    //     (err) => {
    //       // VueNotifications.error({ message: err });
    //
    //       // show errors in the console instead of the alert window
    //       if (Object.prototype.hasOwnProperty.call(window, 'isShowAxiosErrorInConsole') &&
    //         (typeof window.isShowAxiosErrorInConsole === 'function') &&
    //         window.isShowAxiosErrorInConsole(err)) {
    //         window.showAxiosErrInConsole(err);
    //       } else {
    //         VueNotifications.error({ message: err });
    //       }
    //
    //       ctx.$store.commit('toggleLoadingMapClients', false);
    //       ctx.error = err;
    //     }
    //   );
  },
  getMarkerClientsSplitRequestsV2(ctx, id, callback) {
    // ctx.$store.commit('toggleLoadingMapClients', true);
    ctx.$store.commit('setPercentsOfClientsMarkerDownloadsRequestsCount', '5');

    const SEGMENTS = 5;
    const start = moment(ctx.dateRange.startDate).unix();
    const stop = moment(ctx.dateRange.endDate).unix();
    // console.log('start in datepicker', start)
    // console.log('stop in datepicker', stop)
    // console.log('-----------------')
    const interval = Math.floor((stop - start) / SEGMENTS);
    // console.log(interval)
    // ctx.segmentedInterval = interval;
    const segmentedPeriods = [stop];
    // ctx.segmentedClients = new Array(SEGMENTS).fill({});
    // ctx.segmentedClientsCounts = new Array(SEGMENTS).fill(0);

    // убираем все старые точки-почетителей и если включен режим тепловой карты чистим и его даныне
    ctx.clientsOnMap = [];
    if (ctx.showMode.heatmap === true) {
      // console.log('updateheatmap')
      ctx.updateHeatmap();
    }
    if (typeof callback === 'function') {
      // console.log('callback');
      callback();
    }

    const arrForSequentialRequests = [];

    for (let i = 1; i <= SEGMENTS; i++) {
      arrForSequentialRequests.push(i);
      const period = stop - interval * i;
      segmentedPeriods.unshift(period);
    }
    // console.log(segmentedPeriods);

    function makeRequestsFromArray(arr) {
      let index = 0;

      function request() {
        // дублируем тут эту функцию, пототму что отсюда до контекста самого mapService.js не достучатся
        // чтобы вызвать оттуда
        function addLatLng(items, ctx) {
          for (const item of items) {
            // console.log(item.x)
            // console.log(item.y)
            // console.log(item.uniq_mac)
            item.lng = item.x;
            item.lat = item.y;
            item.total = item.uniq_mac;
          }
          return items;
        }

        function getGridSize(ctx) {
          // дублируем тут эту функцию, пототму что отсюда до контекста самого mapService.js не достучатся
          // чтобы вызвать оттуда
          // размер сетки для запросов
          const sizes = { x: 10, y: 10 };
          if (Object.prototype.hasOwnProperty.call(ctx, 'gridSize')) {
            if (Object.prototype.hasOwnProperty.call(ctx.gridSize, 'x')) {
              sizes.x = ctx.gridSize.x;
            }
            if (Object.prototype.hasOwnProperty.call(ctx.gridSize, 'y')) {
              sizes.y = ctx.gridSize.y;
            }
          }
          return sizes;
        }

        function mergeClientsOnMapWhenSplitedRequest(clientsOnMap, itemsFromRequest) {
          // суммируем счетчики после результтаов последжовательных запросов
          // console.log('mergeClientsOnMapWhenSplitedRequest');
          // console.log(clientsOnMap);
          const clientsOnMapAsObject = {};
          for (const item of clientsOnMap) {
            clientsOnMapAsObject[`x${item.x}y${item.y}`] = item;
          }
          for (const item of itemsFromRequest) {
            // console.log(clientsOnMapAsObject[`x${item.x}y${item.y}`].uniq_mac + item.uniq_mac)
            clientsOnMapAsObject[`x${item.x}y${item.y}`].uniq_mac =
              clientsOnMapAsObject[`x${item.x}y${item.y}`].uniq_mac + item.uniq_mac;
          }
          // console.log(clientsOnMapAsObject)
          return Object.values(clientsOnMapAsObject);
          // console.log(clientsOnMapAsObject);
        }

        // console.log('start', segmentedPeriods[index]);
        // console.log('stop', (segmentedPeriods[index + 1] - 1))
        ctx.$store.commit('toggleLoadingMapClients', true);

        //         return Vue.axios.post(`${API_URL || ''}/api/maps/heatmap`, {
        //   action: 'R',
        //   timebounds: { start: segmentedPeriods[index], stop: (segmentedPeriods[index + 1] - 1) },
        //   ids: [id],
        //   q: { x: getGridSize(ctx).x, y: getGridSize(ctx).y }
        // })

        const reqData = {
          action: 'R',
          timebounds: { start: segmentedPeriods[index], stop: segmentedPeriods[index + 1] - 1 },
          ids: [id],
          q: { x: getGridSize(ctx).x, y: getGridSize(ctx).y }
        };

        if (ctx.addToRequestsWithOutNightClientsSettings) {
          reqData.with_out_night_clients = true;
        }

        return Vue.axios
          .post(`${API_URL || ''}/api/maps/heatmap`, reqData)
          .then((response) => {
            // ctx.$store.commit('toggleLoadingMapClients', true);
            index++;
            // const segment = response.data.data.itemslist;
            // ctx.clientsOnMap = ctx.clientsOnMap.concat(segment);
            // if (typeof callback === 'function') {
            //   // console.log('callback');
            //   callback();
            //   if (ctx.showMode.heatmap === true) {
            //     // console.log('updateheatmap')
            //     ctx.updateHeatmap();
            //   }
            // }
            if (response.data.status === 'success') {
              // const segment = response.data.data.itemslist;
              const itemsFromRequest = response.data.data.itemslist;

              if (ctx.clientsOnMap.length === 0) {
                ctx.clientsOnMap = ctx.clientsOnMap.concat(itemsFromRequest);
              } else if (ctx.clientsOnMap.length >= 1) {
                // этой функции скалдываем значения счетчиков для одинаковых координат
                ctx.clientsOnMap = mergeClientsOnMapWhenSplitedRequest(ctx.clientsOnMap, itemsFromRequest);
              }
              // for (const item of ctx.clientsOnMap){
              //   item.lat = item.x;
              //   item.lng = item.y;
              //   item.total = item.uniq_mac;
              // }
              ctx.clientsOnMap = addLatLng(ctx.clientsOnMap, ctx);
              // ctx.clientsOnMap = ctx.clientsOnMap.concat(itemsFromRequest);
              if (typeof callback === 'function') {
                // console.log('callback');
                if (ctx.showMode.heatmap === true) {
                  // console.log('updateheatmap')
                  ctx.updateHeatmap();
                }
                callback();
              }
            } else if (response.data.status === 'error') {
              VueNotifications.error({ message: response.data.description });
            }
            // console.log(index);
            // console.log('request', index, 'of', SEGMENTS);
            const stepPercents = (100 / SEGMENTS) * index;
            const stepPercentsRounded = Math.round(stepPercents);
            // console.log(`${stepPercentsRounded}%`);
            // console.log('-----------------');
            ctx.$store.commit('setPercentsOfClientsMarkerDownloadsRequestsCount', `${stepPercentsRounded}`);

            if (index >= arr.length) {
              // тут обработка при последнем запросе
              ctx.$store.commit('setPercentsOfClientsMarkerDownloadsRequestsCount', '95');
              ctx.$nextTick(() => {
                ctx.$store.commit('setPercentsOfClientsMarkerDownloadsRequestsCount', '100');
                ctx.$nextTick(() => {
                  setTimeout(() => {
                    ctx.$store.commit('toggleLoadingMapClients', false);
                    ctx.$store.commit('setPercentsOfClientsMarkerDownloadsRequestsCount', undefined);
                  }, 200);
                });
              });

              // console.log('done')

              return 'done';
            }
            // а тут не при посслденем
            ctx.$store.commit('toggleLoadingMapClients', false);

            return request();
          })
          .catch((err) => {
            index++;
            VueNotifications.error({ message: err });
            // console.log(index);
            // console.log('request', index, 'of', SEGMENTS);
            const stepPercents = (100 / SEGMENTS) * index;
            const stepPercentsRounded = Math.round(stepPercents);
            // console.log(`${stepPercentsRounded}%`);
            // console.log('-----------------');
            ctx.$store.commit('setPercentsOfClientsMarkerDownloadsRequestsCount', `${stepPercentsRounded}`);
            ctx.$store.commit('toggleLoadingMapClients', false);
            if (index >= arr.length) {
              // тут обработка при последнем запросе
              ctx.$store.commit('setPercentsOfClientsMarkerDownloadsRequestsCount', '95');
              ctx.$nextTick(() => {
                ctx.$store.commit('setPercentsOfClientsMarkerDownloadsRequestsCount', '100');
                ctx.$nextTick(() => {
                  setTimeout(() => {
                    ctx.$store.commit('toggleLoadingMapClients', false);
                    ctx.$store.commit('setPercentsOfClientsMarkerDownloadsRequestsCount', undefined);
                  }, 200);
                });
              });

              // console.log('done')

              return 'done';
            }
            // а тут не при посслденем
            ctx.$store.commit('toggleLoadingMapClients', false);

            return request();
          });
      }

      return request();
    }

    // makeRequestsFromArray([0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10]);
    makeRequestsFromArray(arrForSequentialRequests);

    // const getSegment = (index) => Vue.axios.post(`${API_URL || ''}/api/maps/passerby`, {
    //   action: 'R',
    //   timebounds: { start: ctx.segmentedPeriods[index], stop: ctx.segmentedPeriods[index + 1] },
    //   ids: [id]
    // });
    //

    //
    // /* Load previous hour segment */
    // const lastSegment = ctx.segmentedClients.length - 1;
    // getSegment(lastSegment).then(
    //   (response) => {
    //     if (response.data.status === 'success') {
    //       ctx.warningMapModal = false;
    //       const segment = response.data.data.itemslist;
    //       ctx.segmentedClients[lastSegment] = segment;
    //       ctx.segmentedClientsCounts[lastSegment] = segment.length;
    //       // console.log(segment)
    //       ctx.clientsOnMap = ctx.clientsOnMap.concat(segment)
    //       // ctx.clientsOnMap = segment;
    //       // console.log(ctx.clientsOnMap)
    //       ctx.selectedSegment = lastSegment;
    //       if (typeof callback === 'function') {
    //         console.log('callback')
    //         callback();
    //       }
    //
    //     } else if (response.data.status === 'error') {
    //       ctx.warningMapModal = true;
    //       VueNotifications.error({ message: response.data.description });
    //       ctx.$store.commit('toggleLoadingMapClients', false);
    //     }
    //   },
    //   (err) => {
    //     // VueNotifications.error({ message: err });
    //
    //     // show errors in the console instead of the alert window
    //     if (Object.prototype.hasOwnProperty.call(window, 'isShowAxiosErrorInConsole') &&
    //       (typeof window.isShowAxiosErrorInConsole === 'function') &&
    //       window.isShowAxiosErrorInConsole(err)) {
    //       window.showAxiosErrInConsole(err);
    //     } else {
    //       VueNotifications.error({ message: err });
    //     }
    //
    //     ctx.$store.commit('toggleLoadingMapClients', false);
    //     ctx.error = err;
    //   }
    // );

    // /* Load other segments asynchronously */
    // const requests = [];
    // for (let i = 0; i < ctx.segmentedPeriods.length - 2; i++) {
    //   requests.push(getSegment(i));
    // }

    // /* Polyfill for old Safari; Remove if allSettled is no longer used */
    // allSettled.shim();
    // Promise.allSettled(requests).then((responses) => responses.forEach(
    //   (response, index) => {
    //     if (response.status === 'fulfilled' && response.value.data.status === 'success') {
    //       const segment = response.value.data.data.itemslist;
    //       ctx.segmentedClients[index] = segment;
    //       console.log(responses)
    //       ctx.clientsOnMap = ctx.clientsOnMap.concat(segment)
    //       if (typeof callback === 'function') {
    //         console.log('callback')
    //         callback();
    //       }
    //       ctx.$store.commit('toggleLoadingMapClients', false);
    //       Vue.set(ctx.segmentedClientsCounts, index, segment.length);
    //     } else {
    //       VueNotifications.error({ message: response.reason });
    //       ctx.error = response.reason;
    //     }
    //   },
    //   (err) => {
    //    //  VueNotifications.error({ message: err });
    //     ctx.$store.commit('toggleLoadingMapClients', false);
    //     // show errors in the console instead of the alert window
    //     if (Object.prototype.hasOwnProperty.call(window, 'isShowAxiosErrorInConsole') &&
    //       (typeof window.isShowAxiosErrorInConsole === 'function') &&
    //       window.isShowAxiosErrorInConsole(err)) {
    //       window.showAxiosErrInConsole(err);
    //     } else {
    //       VueNotifications.error({ message: err });
    //     }
    //
    //     ctx.error = err;
    //   }
    // ));
  },

  getMarkerClientsInfoForLatLngPoint(ctx, id, lat, lng, marker) {
    // console.log(marker)
    ctx.$store.commit('beginLoadingclickedManyClientsData');

    let start = moment(ctx.dateRange.startDate).unix();
    let stop = moment(ctx.dateRange.endDate).unix();

    // а тут обрабатываем случай, когда включили режим динамика и там тыкают по точке, нужно взять оттуда
    // start и stop
    //
    if (ctx.showMode.timeline) {
      // тут если включили режим Динамика, но так и не тыкнули по столбцу,
      // делаем запрос с тамймстампами
      // самого последнего сегмента
      if (ctx.mapVisitorsSelectedSegmentNumber === undefined) {
        start = ctx.segmentedPeriods[ctx.segmentedPeriods.length - 2];
        stop = ctx.segmentedPeriods[ctx.segmentedPeriods.length - 1];
      } else {
        // а тут если тыкали и номер сегмента записался в mapVisitorsSelectedSegmentNumber
        // вылавливаем ts уже на основе номера выбраного сегмента
        start = ctx.segmentedPeriods[ctx.mapVisitorsSelectedSegmentNumber];
        stop = ctx.segmentedPeriods[ctx.mapVisitorsSelectedSegmentNumber + 1];
      }
    }

    // console.log(start, stop, lat, lng);

    //   Vue.axios
    // .post(`${API_URL || ''}/api/maps/heatmap_macs`, {
    //   action: 'R',
    //   timebounds: { start, stop },
    //   ids: [id],
    //   q: { x: this.getGridSize(ctx).x, y: this.getGridSize(ctx).y, x_mid: lng, y_mid: lat}
    // })
    // .

    const reqData = {
      action: 'R',
      timebounds: { start, stop },
      ids: [id],
      q: {
        x: this.getGridSize(ctx).x, y: this.getGridSize(ctx).y, x_mid: lng, y_mid: lat
      }
    };
    if (ctx.addToRequestsWithOutNightClientsSettings) {
      reqData.with_out_night_clients = true;
    }

    Vue.axios.post(`${API_URL || ''}/api/maps/heatmap_macs`, reqData).then(
      (response) => {
        if (response.data.status === 'success') {
          ctx.clickedManyClientsDataMarker = marker;
          const itemsFromRequestWithLatLng = [];
          // for (const item of response.data.data.itemslist){
          //   item.lat = lat
          //   item.lng = lng
          // }
          // console.log(response.data.data.itemslist)
          ctx.clickedManyClientsData = JSON.parse(JSON.stringify(response.data.data.itemslist));
          ctx.clickedManyClientsDataFiltered = JSON.parse(JSON.stringify(response.data.data.itemslist));
          ctx.$store.commit('endLoadingclickedManyClientsData');
        } else if (response.data.status === 'error') {
          ctx.warningMapModal = true;
          VueNotifications.error({ message: response.data.description });
          ctx.$store.commit('endLoadingclickedManyClientsData');
        }
      },
      (err) => {
        ctx.$store.commit('endLoadingclickedManyClientsData');
        // VueNotifications.error({ message: err });

        // show errors in the console instead of the alert window
        if (
          Object.prototype.hasOwnProperty.call(window, 'isShowAxiosErrorInConsole') &&
          typeof window.isShowAxiosErrorInConsole === 'function' &&
          window.isShowAxiosErrorInConsole(err)
        ) {
          window.showAxiosErrInConsole(err);
        } else {
          VueNotifications.error({ message: err });
        }
        ctx.error = err;
      }
    );

    // const mockResult = [
    //
    //   {
    //     lat: 6.937,
    //     lng: 5.224,
    //     total: 1,
    //     mac: 'c8:f7:33:1e:aa:0f',
    //     mac_addr: 'c8:f7:33:1e:aa:0f',
    //     timestamp: 1623676551,
    //     vendor: 'HUAWEI',
    //     zone: ''
    //   },
    //   {
    //     lat: 6.837,
    //     lng: 5.624,
    //     total: 1,
    //     mac: 'c8:f7:33:1e:aa:0f',
    //     mac_addr: 'c8:f7:33:1e:aa:0f',
    //     timestamp: 1623676551,
    //     vendor: 'HUAWEI',
    //     zone: ''
    //   },
    //   {
    //     lat: 6.637,
    //     lng: 5.324,
    //     total: 1,
    //     mac: 'c8:f7:33:1e:aa:0f',
    //     mac_addr: 'c8:f7:33:1e:aa:0f',
    //     timestamp: 1623676551,
    //     vendor: 'HUAWEI',
    //     zone: ''
    //   },
    //   {
    //     lat: 6.638,
    //     lng: 5.324,
    //     total: 1,
    //     mac: 'c8:f7:33:1e:aa:0f',
    //     mac_addr: 'c8:f7:33:1e:aa:0f',
    //     timestamp: 1623676551,
    //     vendor: 'HUAWEI',
    //     zone: ''
    //   }
    // ];
    // const mockResult = {
    //   lat: 1.437,
    //   lng: 7.624,
    //   zone: '',
    //   total: 3,
    //   items: [
    //     {
    //       mac: 'c8:f7:33:1e:aa:0f',
    //       mac_addr: 'c8:f7:33:1e:aa:0f',
    //       timestamp: 1623676554,
    //       vendor: 'acsasc'
    //     },
    //     {
    //       mac: 'a4:f7:33:a4:aa:0f',
    //       mac_addr: 'a4:f7:33:a4:aa:0f',
    //       timestamp: 162367655,
    //       vendor: 'sacascasc'
    //     },
    //     {
    //       mac: 'a4:f7:33:a4:aa:0f',
    //       mac_addr: 'a4:f7:33:a4:aa:0f',
    //       timestamp: 162367655,
    //       vendor: 'sacascasc'
    //     }
    //   ]
    //
    // };

    // setTimeout(() => {
    //   ctx.clickedManyClientsDataMarker = marker;
    //   ctx.clickedManyClientsData = JSON.parse(JSON.stringify(mockResult.items));
    //   ctx.clickedManyClientsDataFiltered = JSON.parse(JSON.stringify(mockResult.items));
    //   ctx.$store.commit('endLoadingclickedManyClientsData');
    // }, 500);
  },
  // getMarkerClientsTimelineV2(ctx, id, callback) {
  //
  //   ctx.$store.commit('toggleLoadingMapClients', true);
  //
  //   const SEGMENTS = 24;
  //   const start = moment(ctx.dateRange.startDate).unix();
  //   const stop = moment(ctx.dateRange.endDate).unix();
  //   const interval = Math.floor((stop - start) / SEGMENTS);
  //   ctx.segmentedInterval = interval;
  //   ctx.segmentedPeriods = [stop];
  //   ctx.segmentedClients = new Array(SEGMENTS).fill({});
  //   ctx.segmentedClientsCounts = new Array(SEGMENTS).fill(0);
  //
  //   const getSegment = (index) => Vue.axios.post(`${API_URL || ''}/api/maps/passerby`, {
  //     action: 'R',
  //     timebounds: { start: ctx.segmentedPeriods[index], stop: ctx.segmentedPeriods[index + 1] },
  //     ids: [id]
  //   });
  //
  //   for (let i = 1; i <= SEGMENTS; i++) {
  //     const period = stop - interval * i;
  //     ctx.segmentedPeriods.unshift(period);
  //   }
  //
  //   /* Load previous hour segment */
  //   const lastSegment = ctx.segmentedClients.length - 1;
  //   getSegment(lastSegment).then(
  //     (response) => {
  //       if (response.data.status === 'success') {
  //         ctx.warningMapModal = false;
  //         // const segment = response.data.data.itemslist;
  //
  //         const segment = [
  //           {
  //             lat: 3.437,
  //             lng: 11.624,
  //             total: 1000,
  //             items: ['mac1', 'mac2'],
  //             zone: ''
  //           },
  //           {
  //             lat: 4.437,
  //             lng: 12.624,
  //             total: 5,
  //             items: ['mac1', 'mac2', 'mac3', 'mac4', 'mac5', 'mac6', 'mac7', 'mac8', 'mac9', 'mac10'],
  //             zone: ''
  //           },
  //           {
  //             lat: 2.437,
  //             lng: 9.624,
  //             total: 1,
  //             mac: 'c8:f7:33:1e:aa:0f',
  //             mac_addr: 'c8:f7:33:1e:aa:0f',
  //             timestamp: 1623676551,
  //             vendor: 'HTC',
  //             zone: ''
  //           },
  //           {
  //             lat: 1.437,
  //             lng: 8.624,
  //             total: 1,
  //             mac: 'c8:f7:33:1e:aa:0f',
  //             mac_addr: 'c8:f7:33:1e:aa:0f',
  //             timestamp: 1623676551,
  //             vendor: 'HUAWEI',
  //             zone: ''
  //           },
  //           {
  //             lat: 1.237,
  //             lng: 8.724,
  //             total: 50,
  //             items: ['mac1', 'mac2', 'mac3', 'mac4', 'mac5'],
  //             zone: ''
  //           },
  //           {
  //             lat: 6.237,
  //             lng: 5.724,
  //             total: 250,
  //             items: ['mac1', 'mac2', 'mac3', 'mac4', 'mac5'],
  //             zone: ''
  //           },
  //           {
  //             lat: 6.937,
  //             lng: 5.224,
  //             total: 1,
  //             mac: 'c8:f7:33:1e:aa:0f',
  //             mac_addr: 'c8:f7:33:1e:aa:0f',
  //             timestamp: 1623676551,
  //             vendor: 'HUAWEI',
  //             zone: ''
  //           },
  //
  //           {
  //             lat: 6.638,
  //             lng: 5.324,
  //             total: 1,
  //             mac: 'c8:f7:33:1e:aa:0f',
  //             mac_addr: 'c8:f7:33:1e:aa:0f',
  //             timestamp: 1623676551,
  //             vendor: 'HUAWEI',
  //             zone: ''
  //           },
  //           {
  //             lat: 6.638,
  //             lng: 5.324,
  //             total: 25,
  //             items: ['mac1', 'mac2', 'mac3', 'mac4', 'mac5'],
  //             zone: ''
  //           }
  //         ];
  //
  //         let segmentCounter = 0;
  //         segment.forEach((item) => {
  //           segmentCounter = segmentCounter + item.total;
  //         });
  //
  //         ctx.segmentedClients[lastSegment] = segment;
  //         ctx.segmentedClientsCounts[lastSegment] = segmentCounter;
  //         ctx.clientsOnMap = segment;
  //         ctx.selectedSegment = lastSegment;
  //         if (typeof callback === 'function') {
  //           callback();
  //         }
  //         ctx.$store.commit('toggleLoadingMapClients', false);
  //       } else if (response.data.status === 'error') {
  //         ctx.warningMapModal = true;
  //         VueNotifications.error({ message: response.data.description });
  //         ctx.$store.commit('toggleLoadingMapClients', false);
  //       }
  //     },
  //     (err) => {
  //       // VueNotifications.error({ message: err });
  //
  //       // show errors in the console instead of the alert window
  //       if (Object.prototype.hasOwnProperty.call(window, 'isShowAxiosErrorInConsole') &&
  //         (typeof window.isShowAxiosErrorInConsole === 'function') &&
  //         window.isShowAxiosErrorInConsole(err)) {
  //         window.showAxiosErrInConsole(err);
  //       } else {
  //         VueNotifications.error({ message: err });
  //       }
  //
  //       ctx.$store.commit('toggleLoadingMapClients', false);
  //       ctx.error = err;
  //     }
  //   );
  //
  //   /* Load other segments asynchronously */
  //   const requests = [];
  //   for (let i = 0; i < ctx.segmentedPeriods.length - 2; i++) {
  //     requests.push(getSegment(i));
  //   }
  //
  //   /* Polyfill for old Safari; Remove if allSettled is no longer used */
  //   allSettled.shim();
  //   Promise.allSettled(requests).then((responses) => responses.forEach(
  //     (response, index) => {
  //       if (response.status === 'fulfilled' && response.value.data.status === 'success') {
  //         // const segment = response.value.data.data.itemslist;
  //                   const segment = [
  //           {
  //             lat: 3.437,
  //             lng: 11.624,
  //             total: 900,
  //             items: ['mac1', 'mac2'],
  //             zone: ''
  //           },
  //           {
  //             lat: 4.437,
  //             lng: 12.624,
  //             total: 10000,
  //             items: ['mac1', 'mac2', 'mac3', 'mac4', 'mac5', 'mac6', 'mac7', 'mac8', 'mac9', 'mac10'],
  //             zone: ''
  //           },
  //           {
  //             lat: 2.437,
  //             lng: 9.624,
  //             total: 1,
  //             mac: 'c8:f7:33:1e:aa:0f',
  //             mac_addr: 'c8:f7:33:1e:aa:0f',
  //             timestamp: 1623676551,
  //             vendor: 'HTC',
  //             zone: ''
  //           },
  //           {
  //             lat: 1.437,
  //             lng: 8.624,
  //             total: 1,
  //             mac: 'c8:f7:33:1e:aa:0f',
  //             mac_addr: 'c8:f7:33:1e:aa:0f',
  //             timestamp: 1623676551,
  //             vendor: 'HUAWEI',
  //             zone: ''
  //           },
  //           {
  //             lat: 1.237,
  //             lng: 8.724,
  //             total: 500000,
  //             items: ['mac1', 'mac2', 'mac3', 'mac4', 'mac5'],
  //             zone: ''
  //           },
  //           {
  //             lat: 6.237,
  //             lng: 5.724,
  //             total: 25000,
  //             items: ['mac1', 'mac2', 'mac3', 'mac4', 'mac5'],
  //             zone: ''
  //           },
  //           {
  //             lat: 6.937,
  //             lng: 5.224,
  //             total: 1,
  //             mac: 'c8:f7:33:1e:aa:0f',
  //             mac_addr: 'c8:f7:33:1e:aa:0f',
  //             timestamp: 1623676551,
  //             vendor: 'HUAWEI',
  //             zone: ''
  //           },
  //           {
  //             lat: 6.837,
  //             lng: 5.624,
  //             total: 1,
  //             mac: 'c8:f7:33:1e:aa:0f',
  //             mac_addr: 'c8:f7:33:1e:aa:0f',
  //             timestamp: 1623676551,
  //             vendor: 'HUAWEI',
  //             zone: ''
  //           },
  //           {
  //             lat: 6.637,
  //             lng: 5.324,
  //             total: 1,
  //             mac: 'c8:f7:33:1e:aa:0f',
  //             mac_addr: 'c8:f7:33:1e:aa:0f',
  //             timestamp: 1623676551,
  //             vendor: 'HUAWEI',
  //             zone: ''
  //           },
  //           {
  //             lat: 6.638,
  //             lng: 5.324,
  //             total: 1,
  //             mac: 'c8:f7:33:1e:aa:0f',
  //             mac_addr: 'c8:f7:33:1e:aa:0f',
  //             timestamp: 1623676551,
  //             vendor: 'HUAWEI',
  //             zone: ''
  //           },
  //           {
  //             lat: 6.638,
  //             lng: 5.324,
  //             total: 25,
  //             items: ['mac1', 'mac2', 'mac3', 'mac4', 'mac5'],
  //             zone: ''
  //           }
  //         ];
  //         let segmentCounter = 0;
  //         segment.forEach((item) => {
  //           segmentCounter = segmentCounter + item.total;
  //         });
  //         ctx.segmentedClients[index] = segment;
  //         Vue.set(ctx.segmentedClientsCounts, index, segmentCounter);
  //       } else {
  //         VueNotifications.error({ message: response.reason });
  //         ctx.error = response.reason;
  //       }
  //     },
  //     (err) => {
  //      //  VueNotifications.error({ message: err });
  //
  //       // show errors in the console instead of the alert window
  //       if (Object.prototype.hasOwnProperty.call(window, 'isShowAxiosErrorInConsole') &&
  //         (typeof window.isShowAxiosErrorInConsole === 'function') &&
  //         window.isShowAxiosErrorInConsole(err)) {
  //         window.showAxiosErrInConsole(err);
  //       } else {
  //         VueNotifications.error({ message: err });
  //       }
  //
  //       ctx.error = err;
  //     }
  //   ));
  // },
  getMarkerClientsTimelineV2(ctx, id, callback) {
    ctx.$store.commit('toggleLoadingMapClients', true);

    const SEGMENTS = 24;
    const start = moment(ctx.dateRange.startDate).unix();
    const stop = moment(ctx.dateRange.endDate).unix();
    const interval = Math.floor((stop - start) / SEGMENTS);
    ctx.segmentedInterval = interval;
    ctx.segmentedPeriods = [stop];
    ctx.segmentedClients = new Array(SEGMENTS).fill({});
    ctx.segmentedClientsCounts = new Array(SEGMENTS).fill(0);

    // const getSegment = (index) => Vue.axios.post(`${API_URL || ''}/api/maps/heatmap`, {
    //   action: 'R',
    //   timebounds: { start: ctx.segmentedPeriods[index], stop: ctx.segmentedPeriods[index + 1] },
    //   ids: [id],
    //   q: { x: this.getGridSize(ctx).x, y: this.getGridSize(ctx).y }
    // });

    const getSegment = (index) => {
      const reqData = {
        action: 'R',
        timebounds: { start: ctx.segmentedPeriods[index], stop: ctx.segmentedPeriods[index + 1] },
        ids: [id],
        q: { x: this.getGridSize(ctx).x, y: this.getGridSize(ctx).y }
      };
      if (ctx.addToRequestsWithOutNightClientsSettings) {
        reqData.with_out_night_clients = true;
      }
      return Vue.axios.post(`${API_URL || ''}/api/maps/heatmap`, reqData);
    };

    for (let i = 1; i <= SEGMENTS; i++) {
      const period = stop - interval * i;
      ctx.segmentedPeriods.unshift(period);
    }

    /* Load previous hour segment */
    const lastSegment = ctx.segmentedClients.length - 1;
    getSegment(lastSegment).then(
      (response) => {
        if (response.data.status === 'success') {
          ctx.warningMapModal = false;
          // console.log('response.data.data.itemslist', response.data.data.itemslist)
          const responesData = response.data.data.itemslist;
          // for (const item of responesData){
          //     item.lat = item.x;
          //     item.lng = item.y;
          //     item.total = item.uniq_mac;
          //   }
          // const segment = responesData;
          const itemsFromRequestWithLatLng = this.addLatLng(responesData, ctx);
          const segment = itemsFromRequestWithLatLng;

          // const segment = [
          //   {
          //     lat: 3.437,
          //     lng: 11.624,
          //     total: 1000,
          //     items: ['mac1', 'mac2'],
          //     zone: ''
          //   },
          //   {
          //     lat: 4.437,
          //     lng: 12.624,
          //     total: 5,
          //     items: ['mac1', 'mac2', 'mac3', 'mac4', 'mac5', 'mac6', 'mac7', 'mac8', 'mac9', 'mac10'],
          //     zone: ''
          //   },
          //   {
          //     lat: 2.437,
          //     lng: 9.624,
          //     total: 1,
          //     mac: 'c8:f7:33:1e:aa:0f',
          //     mac_addr: 'c8:f7:33:1e:aa:0f',
          //     timestamp: 1623676551,
          //     vendor: 'HTC',
          //     zone: ''
          //   },
          //   {
          //     lat: 1.437,
          //     lng: 8.624,
          //     total: 1,
          //     mac: 'c8:f7:33:1e:aa:0f',
          //     mac_addr: 'c8:f7:33:1e:aa:0f',
          //     timestamp: 1623676551,
          //     vendor: 'HUAWEI',
          //     zone: ''
          //   },
          //   {
          //     lat: 1.237,
          //     lng: 8.724,
          //     total: 50,
          //     items: ['mac1', 'mac2', 'mac3', 'mac4', 'mac5'],
          //     zone: ''
          //   },
          //   {
          //     lat: 6.237,
          //     lng: 5.724,
          //     total: 250,
          //     items: ['mac1', 'mac2', 'mac3', 'mac4', 'mac5'],
          //     zone: ''
          //   },
          //   {
          //     lat: 6.937,
          //     lng: 5.224,
          //     total: 1,
          //     mac: 'c8:f7:33:1e:aa:0f',
          //     mac_addr: 'c8:f7:33:1e:aa:0f',
          //     timestamp: 1623676551,
          //     vendor: 'HUAWEI',
          //     zone: ''
          //   },
          //
          //   {
          //     lat: 6.638,
          //     lng: 5.324,
          //     total: 1,
          //     mac: 'c8:f7:33:1e:aa:0f',
          //     mac_addr: 'c8:f7:33:1e:aa:0f',
          //     timestamp: 1623676551,
          //     vendor: 'HUAWEI',
          //     zone: ''
          //   },
          //   {
          //     lat: 6.638,
          //     lng: 5.324,
          //     total: 25,
          //     items: ['mac1', 'mac2', 'mac3', 'mac4', 'mac5'],
          //     zone: ''
          //   }
          // ];

          let segmentCounter = 0;
          segment.forEach((item) => {
            segmentCounter += item.total;
          });

          ctx.segmentedClients[lastSegment] = segment;
          ctx.segmentedClientsCounts[lastSegment] = segmentCounter;
          ctx.clientsOnMap = segment;
          ctx.selectedSegment = lastSegment;
          if (typeof callback === 'function') {
            callback();
          }
          ctx.$store.commit('toggleLoadingMapClients', false);
        } else if (response.data.status === 'error') {
          ctx.warningMapModal = true;
          VueNotifications.error({ message: response.data.description });
          ctx.$store.commit('toggleLoadingMapClients', false);
        }
      },
      (err) => {
        // VueNotifications.error({ message: err });

        // // show errors in the console instead of the alert window
        // if (
        //   Object.prototype.hasOwnProperty.call(window, 'isShowAxiosErrorInConsole') &&
        //   typeof window.isShowAxiosErrorInConsole === 'function' &&
        //   window.isShowAxiosErrorInConsole(err)
        // ) {
        //   window.showAxiosErrInConsole(err);
        // } else {
        //   VueNotifications.error({ message: err });
        // }

        VueNotifications.error({ message: err });

        ctx.$store.commit('toggleLoadingMapClients', false);
        ctx.error = err;
      }
    );

    /* Load other segments asynchronously */
    const requests = [];
    for (let i = 0; i < ctx.segmentedPeriods.length - 2; i++) {
      requests.push(getSegment(i));
    }

    /* Polyfill for old Safari; Remove if allSettled is no longer used */
    allSettled.shim();
    Promise.allSettled(requests).then((responses) => responses.forEach(
      (response, index) => {
        if (response.status === 'fulfilled' && response.value.data.status === 'success') {
          // const segment = response.value.data.data.itemslist;
          const responesData = response.value.data.data.itemslist;
          // for (const item of responesData) {
          //   item.lat = item.x;
          //   item.lng = item.y;
          //   item.total = item.uniq_mac;
          // }
          // const segment = responesData;
          const itemsFromRequestWithLatLng = this.addLatLng(responesData, ctx);
          const segment = itemsFromRequestWithLatLng;
          // console.log(segment)
          //           const segment = [
          //   {
          //     lat: 3.437,
          //     lng: 11.624,
          //     total: 900,
          //     items: ['mac1', 'mac2'],
          //     zone: ''
          //   },
          //   {
          //     lat: 4.437,
          //     lng: 12.624,
          //     total: 10000,
          //     items: ['mac1', 'mac2', 'mac3', 'mac4', 'mac5', 'mac6', 'mac7', 'mac8', 'mac9', 'mac10'],
          //     zone: ''
          //   },
          //   {
          //     lat: 2.437,
          //     lng: 9.624,
          //     total: 1,
          //     mac: 'c8:f7:33:1e:aa:0f',
          //     mac_addr: 'c8:f7:33:1e:aa:0f',
          //     timestamp: 1623676551,
          //     vendor: 'HTC',
          //     zone: ''
          //   },
          //   {
          //     lat: 1.437,
          //     lng: 8.624,
          //     total: 1,
          //     mac: 'c8:f7:33:1e:aa:0f',
          //     mac_addr: 'c8:f7:33:1e:aa:0f',
          //     timestamp: 1623676551,
          //     vendor: 'HUAWEI',
          //     zone: ''
          //   },
          //   {
          //     lat: 1.237,
          //     lng: 8.724,
          //     total: 500000,
          //     items: ['mac1', 'mac2', 'mac3', 'mac4', 'mac5'],
          //     zone: ''
          //   },
          //   {
          //     lat: 6.237,
          //     lng: 5.724,
          //     total: 25000,
          //     items: ['mac1', 'mac2', 'mac3', 'mac4', 'mac5'],
          //     zone: ''
          //   },
          //   {
          //     lat: 6.937,
          //     lng: 5.224,
          //     total: 1,
          //     mac: 'c8:f7:33:1e:aa:0f',
          //     mac_addr: 'c8:f7:33:1e:aa:0f',
          //     timestamp: 1623676551,
          //     vendor: 'HUAWEI',
          //     zone: ''
          //   },
          //   {
          //     lat: 6.837,
          //     lng: 5.624,
          //     total: 1,
          //     mac: 'c8:f7:33:1e:aa:0f',
          //     mac_addr: 'c8:f7:33:1e:aa:0f',
          //     timestamp: 1623676551,
          //     vendor: 'HUAWEI',
          //     zone: ''
          //   },
          //   {
          //     lat: 6.637,
          //     lng: 5.324,
          //     total: 1,
          //     mac: 'c8:f7:33:1e:aa:0f',
          //     mac_addr: 'c8:f7:33:1e:aa:0f',
          //     timestamp: 1623676551,
          //     vendor: 'HUAWEI',
          //     zone: ''
          //   },
          //   {
          //     lat: 6.638,
          //     lng: 5.324,
          //     total: 1,
          //     mac: 'c8:f7:33:1e:aa:0f',
          //     mac_addr: 'c8:f7:33:1e:aa:0f',
          //     timestamp: 1623676551,
          //     vendor: 'HUAWEI',
          //     zone: ''
          //   },
          //   {
          //     lat: 6.638,
          //     lng: 5.324,
          //     total: 25,
          //     items: ['mac1', 'mac2', 'mac3', 'mac4', 'mac5'],
          //     zone: ''
          //   }
          // ];
          let segmentCounter = 0;
          segment.forEach((item) => {
            segmentCounter += item.total;
          });
          ctx.segmentedClients[index] = segment;
          Vue.set(ctx.segmentedClientsCounts, index, segmentCounter);
        } else {
          VueNotifications.error({ message: response.reason });
          ctx.error = response.reason;
        }
      },
      (err) => {
        //  VueNotifications.error({ message: err });

        // show errors in the console instead of the alert window
        if (
          Object.prototype.hasOwnProperty.call(window, 'isShowAxiosErrorInConsole') &&
            typeof window.isShowAxiosErrorInConsole === 'function' &&
            window.isShowAxiosErrorInConsole(err)
        ) {
          window.showAxiosErrInConsole(err);
        } else {
          VueNotifications.error({ message: err });
        }

        ctx.error = err;
      }
    ));
  }
};
