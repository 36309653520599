<template>
  <span :class="[!props.row.security && 'muted']">
    {{ props.row.security ? getSecurityInfoForTableColumn(props.row.security) : '-' }}
  </span>
</template>

<script>
/**
 * компонент - ячейка security.
 * компонент локальный: используется только в WlansTable.vue
 * @component
 */

export default {
  name: 'LocationCell',
  components: {},
  props: {
    props: {
      type: Object,
      required: true
    }
  },
  methods: {
    // ! legacy. todo refactor
    getSecurityInfoForTableColumn(security) {
      try {
        let result = '';
        let suites = [];
        if (Object.prototype.hasOwnProperty.call(security, 'data') &&
          typeof security.data === 'object' && security.data !== null) {
          suites = security.data.suites;
        }
        const securityType = security.type;
        let securityTypeForDisplay = this.$t('wlans.securityOpen');
        if (securityType === 'wpapersonal') {
          securityTypeForDisplay = 'WPA Personal';
        }
        if (securityType === 'wpaenterprise') {
          securityTypeForDisplay = 'WPA Enterprise';
        }
        if (securityType === 'wpa2personal') {
          securityTypeForDisplay = 'WPA2 Personal';
        }
        if (securityType === 'wpa2enterprise') {
          securityTypeForDisplay = 'WPA2 Enterprise';
        }
        if (securityType === 'wpa3personal') {
          securityTypeForDisplay = 'WPA3 Personal';
        }
        if (securityType === 'wpa3enterprise') {
          securityTypeForDisplay = 'WPA3 Enterprise';
        }
        if (securityType === 'wpa23personal') {
          securityTypeForDisplay = 'WPA2/3 Personal';
        }
        if (securityType === 'wpa23enterprise') {
          securityTypeForDisplay = 'WPA2/3 Enterprise';
        }
        // console.log(suites)
        // console.log(securityTypeForDisplay)
        result = `[${securityTypeForDisplay}]`
        if (Array.isArray(suites)) {
          for (const suiteName of suites) {
            result = result + `[${suiteName}]`
          }
        }
        return result.toUpperCase();
      } catch (e) {
        console.log(e)
        return ''
      }
    }
  }
};
</script>

<style lang="css" scoped>
.muted {
  color: var(--gray-light);
}
</style>
