// selected checkbox of a row or not
function getSelectedRowValue(currentRRMGroupId, selectedRRMGroupIdsArray) {
  let value = false;

  selectedRRMGroupIdsArray.forEach(rrmGroupId => {
    if (rrmGroupId === currentRRMGroupId) {
      value = true;
    }
  });

  return value;
}

export {
  getSelectedRowValue
};
