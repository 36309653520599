<template>
  <div>
    <h5 class="mb-2">{{ $t('aps.maxinactivityAboutModalText') }}:</h5>
    <div>
      <pre>
# If a station does not send anything in ap_max_inactivity seconds, an
# empty data frame is sent to it in order to verify whether it is
# still in range. If this frame is not ACKed, the station will be
# disassociated and then deauthenticated. This feature is used to
# clear station table of old entries when the STAs move out of the
# range.
#
# The station can associate again with the AP if it is still in range;
# this inactivity poll is just used as a nicer way of verifying
# inactivity; i.e., client will not report broken connection because
# disassociation frame is not sent immediately without first polling
# the STA with a data frame.
# default: 300 (i.e., 5 minutes)
    </pre
      >
    </div>
  </div>
</template>

<script>
export default {
  name: 'MaxInactivityAbout'
};
</script>

<style scoped></style>
