/**
 * Список правил из src/validators.js для валидации паролей в зависимости от продукта WLC или ONE
 * Правила используются в полях для ввода нового пароля (создание нового юзера, изменение текущего пароля и тд)
 *
 */

const USER_PASSWORD_VALIDATION_RULES = {
  // Правила из src/validators.js для полей с паролями в ONE
  ONE: [
    'userPasswordV2CheckValidCharacters',
    'userPasswordV2CheckLengthMin',
    'userPasswordV2CheckLengthMax',
    'userPasswordV2UpperCaseLetterIsContained',
    'userPasswordV2LowerCaseLetterIsContained',
    'userPasswordV2DigitIsContained'
  ],

  // Правила из src/validators.js для полей с паролями в WLC
  WLC: [
    'userPasswordV2CheckValidCharacters',
    'userPasswordV2CheckLengthMinForWLC'
  ]
};

export { USER_PASSWORD_VALIDATION_RULES };
