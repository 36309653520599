<template>
  <div class="row" v-if="this.$store.state.userData.role === 'admin'">
    <div class="col-lg-3">
      <div class="card card-inverse card-success">
        <div class="card-block">
          <div class="h1 text-muted text-left mb-2">
            <img class="img img-responsive wizard-wlan-icon" :src="'/static/img/wifi1.svg'" alt="" />
          </div>
          <div class="h4 mb-0">{{ $t('easyInstallWizard.headerCaption') }}</div>
          <template v-for="stepNumber of wizardSteps">
            <div :key="stepNumber" v-if="currentRouteName === `EasyInstallWizardStep${stepNumber}`">
              <p class="text-muted text-uppercase font-weight-bold">{{ $t(`general.step${stepNumber}`) }}</p>
              <p>{{ $t(`easyInstallWizard.step${stepNumber}Caption`) }}</p>
            </div>
          </template>
          <div class="progress progress-white progress-xs mt-1">
            <div
              role="progressbar"
              aria-valuenow="25"
              aria-valuemin="0"
              aria-valuemax="100"
              class="progress-bar"
              :style="{ width: progressWidth + '%', transition: 'all 0.2s' }"
            ></div>
          </div>
          <hr />
          <div class="where-Is-Looking-For-This-Settings">
            <div class="mb-1">{{ $t('easyInstallWizard.whereIsLookingForThisSettings') }}?</div>
            <template v-for="stepNumber of wizardSteps">
              <div
                :key="`${stepNumber}-whereIsLookingForThisSettings`"
                v-if="currentRouteName === `EasyInstallWizardStep${stepNumber}`"
              >
                {{getWhereIsLookingForThisSettingsForStep(stepNumber)}}
              </div>
            </template>
          </div>
        </div>
      </div>
    </div>
    <div class="col-lg-5">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';

const { mapGetters: featureFlagsMapGetters } = createNamespacedHelpers('featureFlags');

export default {
  name: 'easyInstallWizard',
  data() {
    return {
      wizardSteps: [1, 2, 3, 4, 5],
      prevRoutePath: null
    };
  },
  methods: {
    /**
     * Возвращает строку для отобрадения в UI с названием раздела,
     * где потом можно найти настройки, задаваемые на шаге визарда.
     * Нужно для задачки WNE-4054 https://wimarksystems.atlassian.net/browse/WNE-4054
     * Дело в том, что в зависимости от сборки, настройки могут быть как внутри пункта меню NMS, так и в корне меню
     * Поэтому в зависимости от сборки нужно выдавать " "NMS" -> "Сети Wi-Fi" " или " " "Сети Wi-Fi" "
     * (зависит от того есть или нет Аналитика в сборке)
     *
     * @param stepNumber
     * @returns {string}
     */
    getWhereIsLookingForThisSettingsForStep(stepNumber) {
      if (stepNumber === 2 || !this.isContentAnalyticsEnabled) {
        // второй шаг находится в разделе Портал, а не NMS. Для него просто выводим как есть.
        return `${this.$t(`easyInstallWizard.step${stepNumber}WhereIsLookingForThisSettings`)}`;
      }
      return `"${this.$t('sidebar.nms')}" -> ${this.$t(`easyInstallWizard.step${stepNumber}WhereIsLookingForThisSettings`)}`
    }
  },
  computed: {
    ...featureFlagsMapGetters([
      'isContentPortalEnabled',
      'isContentAnalyticsEnabled'
    ]),
    currentRouteName() {
      // console.warn('ROUTE');
      // console.warn(this.$route.name);
      return this.$route.name;
    },
    progressWidth() {
      let width;
      const currentStepRoute = this.$route.name;

      switch (currentStepRoute) {
        case 'EasyInstallWizardStep1':
          width = 20;
          break;
        case 'EasyInstallWizardStep2':
          width = 40;
          break;
        case 'EasyInstallWizardStep3':
          width = 60;
          break;
        case 'EasyInstallWizardStep4':
          width = 75;
          break;
        case 'EasyInstallWizardStep5':
          width = 90;
          break;
        default:
          width = 100;
      }
      return width;
    }
  },
  beforeRouteEnter(to, from, next) {
    // смотри откуда пришел пользователь, чтобы потом, после последнего шага прокинуть его туда-же
    // https://v3.router.vuejs.org/ru/guide/advanced/navigation-guards.html#%D1%85%D1%83%D0%BA%D0%B8-%D0%B4%D0%BB%D1%8F-%D0%BA%D0%BE%D0%BD%D0%BA%D1%80%D0%B5%D1%82%D0%BD%D1%8B%D1%85-%D0%BA%D0%BE%D0%BC%D0%BF%D0%BE%D0%BD%D0%B5%D0%BD%D1%82%D0%BE%D0%B2
    next((vm) => {
      vm.$store.commit('setEasyInstallWizardRouteFromUserWentToWizard', from.fullPath);
    });
  }
};
</script>
<style scoped lang="scss">
.wizard-user-icon {
  width: 80px;
}
.where-Is-Looking-For-This-Settings {
  background-color: rgba(0, 0, 0, 0.11);
  border-radius: 15px;
  border: 1px solid rgba(0, 0, 0, 0.11);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  text-align: center;
}
</style>


<!-- beforeRouteEnter(to, from, next) {
    // console.log('beforeRouteEnter')
    // смотри откуда пришел пользователь, чтобы потом, после последнего шага прокинуть его туда-же
    next((vm) => {
      console.log('easyWizard vm', {
        vm,
        from
      })
      try {
        if (from !== undefined && from.fullPath !== undefined) {
          vm.prevRoutePath = from.fullPath;
        }
      } catch (e) {
        console.log(e);
      }
    });
  },
  watch: {
    prevRoutePath(val) {
      console.log('easyWizard prevRoutePath', val)
      if (val !== null) {
        console.log(val);
        this.$store.commit('setEasyInstallWizardRouteFromUserWentToWizard', val);
      }
    }
  } -->
