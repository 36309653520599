<template>
  <div class="loading-wrapper new-modern-style-data-loader-spiner">
    <div class="loading__container loading-spinner-component-background new-modern-style-data-loader-spiner">
      <div class="loading d-flex justify-content-center align-items-center" :style="styleWithTopMargin">
        <div>
          <img class="small-spinner-gif" src="/static/img/Gear-0.2s-48px.gif" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'loaderSpinnerSmall',
  props: {
    centered: {
      type: Boolean,
      default: false
    },
    topmargin: {
      type: String
    }
  },
  computed: {
    styleWithTopMargin() {
      if (this.centered === true) {
        return '';
      }
      let top = '0px';
      if (this.topmargin === undefined) {
        top = '20px';
      } else {
        top = `${this.topmargin}`;
      }
      return `position: absolute; top: ${top};`;
    }
  }
};
</script>

<style lang="scss" scoped>
.loading__container {
  --loading-ratio: 0.6;
}
.loading {
  width: calc(200px * var(--loading-ratio));
  height: calc(200px * var(--loading-ratio));
  display: inline-block;
  overflow: hidden;
  /* background: #ffffff; */
  z-index: 99999;
}
.loading__container {
  width: 100%;
  height: 100%;

  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  justify-content: center;
  align-items: center;
  z-index: 99999;
}
.from-top {
  position: absolute;
  top: 50px;
}

.loaded_hiding .loading__container {
  transition: 0.4s opacity;
  opacity: 0;
}

.loaded .loading__container {
  display: block;
}
.loading-wrapper {
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.58);
}
.small-spinner-gif {
  width: 35px;
  height: 35px;
}
</style>
<style lang="scss" scoped>
.loading-spinner-component-background {
  background-color: rgba(255, 255, 255, 0.58);
}
</style>
