<template>
  <div class="w-input-container">
    <label v-if="label" :for="id" class="label">
      {{ label }}
    </label>
    <div class="input-wrapper">

      <div v-if="withIconLeft" class="icon-container left" @click="handleIconClick">
        <slot name="icon-left"></slot>
      </div>
      <input
          class="w-input view-type-default"
          :type="type"
          :id="id"
          :name="name"
          :value="value"
          :placeholder="placeholder"
          :class="[withIconLeft && 'with-icon-left', withIconRight && 'with-icon-right', isErrorClass, sizeClass, customClass]"
          :style="customStyle"
          :disabled="disabled"
          @input="handleInput"
          @change="handleChange"
          @focus="handleFocus"
          @blur="handleBlur"
          @keyup="handleKeyup"
          @keydown="handleKeydown"
          v-bind="$attrs"
          ref="inputRef"
        />
        <div v-if="withIconRight" class="icon-container right" @click="handleIconClick">
          <slot name="icon-right"></slot>
        </div>
    </div>
    <span v-show="errorText" class="error-tooltip is-error">
      {{ errorText }}
    </span>
  </div>
</template>

<script>
/**
* UI-компонент input.
* Используется во всем проекте. Регистрируется глобально (в конкретном компоненте регистрировать не нужно).
*
* @typedef {Object} WInputProps
* @prop {string|number} value - Значение input. Для использования компонента с v-bind.
* @prop {InputType} [type='text'] - Тип input.
* @prop {string} id  - ID.
* @prop {string} [name=''] - Имя input.
* @prop {string} [placeholder=''] - Placeholder input.
* @prop {string} [label=''] - Label для input. Если не задать, label не будет.
* @prop {string|Object} [customClass=''] - Кастомные классы для input. Если вдруг нужно задать нестандартный класс.
* @prop {Object} [customStyle={}] - Кастомные стили для input. Если вдруг нужно задать нестандартные inline-свойства.
* @prop {boolean} [disabled=false] - Флаг для отключения input.
*
* Размеры:
* @prop {boolean} [sm=true] - Флаг для размера 'sm'.
* @prop {boolean} [md=false] - Флаг для размера 'md'.
* @prop {boolean} [lg=false] - Флаг для размера 'lg'.
*
* Слоты:
* @slot icon-left - Слот для отображения иконки слева от инпута.
* @slot icon-right - Слот для отображения иконки справа от инпута.
*
*
* Валидация:
* @prop {string} [errorText=''] - Текст ошибки для textarea.
*
* События:
* @event input - Срабатывает при изменении значения инпута.
* @event change - Срабатывает при изменении значения и потере фокуса инпутом.
* @event focus - Срабатывает при фокусировке на инпуте.
* @event blur - Срабатывает при потере фокуса инпутом.
* @event keyup - Срабатывает при отпускании клавиши на инпуте.
* @event keydown - Срабатывает при нажатии клавиши на инпуте.
*
* Примеры использования компонента WInput:
*
* @example
* Пример 1: Базовый input
* <WInput v-model="inputValue" />
*
* @example
* Пример 2: Input с заданным типом
* <WInput v-model="numberValue" type="number" />
*
* @example
* Пример 3: Input с label и placeholder
* <WInput v-model="textValue" label="Имя:" placeholder="Введите имя" />
*
* @example
* Пример 4: Input с пользовательскими классами и стилями
* <WInput v-model="emailValue" customClass="my-custom-class" :customStyle="{ color: 'blue', border: '1px solid blue' }" />
*
* @example
* Пример 5: Отключенный input
* <WInput v-model="inputValue" :disabled="isInputDisabled" />
*
* @example
* Пример 6: readonly input
* <WInput v-model="inputValue" :readonly="isInputReadonly" />
*
* @example
* Пример 7: Textarea с валидацией VeeValidate
* <WInput
  type="number"
  v-model.number="customTimeRangeInputs.amount"
  @focus="setData(RADIO_OPTIONS_CONFIG['customTimeRange'].label)"
  name="customTimeRange"
  v-validate="{ required: isValidateCustomTimeRangeInput }"
  data-vv-validate-on="input"
  :data-vv-as="$t('reactiveLogs.interval')"
  :errorText="errors.first(name)"
  />
*
* @example
* Пример 8: Input с размером 'lg'
* <WInput v-model="largeValue" lg />
*
* @example
* Пример 9: Input с иконкой слева и справа
* <WInput v-model="iconInputValue">
*   <template #icon-left>
*     <MyIcon name="left-icon" />
*   </template>
*   <template #icon-right>
*     <MyIcon name="right-icon" />
*   </template>
* </WInput>
*/

const ALLOWED_INPUT_TYPES = [
  'text',
  'number',
  'search',
  'tel',
  'password',
  'date',
  'datetime-local',
  'month',
  'time',
  'week',
  'email',
  'file',
  'url'
];

export default {
  name: 'WInput',
  inheritAttrs: false, // Отключаем наследование атрибутов по умолчанию
  props: {
    value: {
      type: [String, Number],
      default: '',
      required: true
    },
    type: {
      type: String,
      default: 'text',
      validator(value) {
        return ALLOWED_INPUT_TYPES.indexOf(value) !== -1;
      }
    },
    id: {
      type: String,
      required: true
    },
    name: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: ''
    },
    label: {
      type: String,
      default: ''
    },
    customClass: {
      type: [String, Object, Array],
      default: ''
    },
    customStyle: {
      type: Object
    },
    disabled: {
      type: Boolean,
      default: false
    },
    // sizes
    sm: {
      type: Boolean,
      default: true
    },
    md: {
      type: Boolean,
      default: false
    },
    lg: {
      type: Boolean,
      default: false
    },
    errorText: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
    };
  },
  computed: {
    isErrorClass() {
      return {
        'is-error': this.errorText
      };
    },
    sizeClass() {
      if (this.md) {
        return 'md';
      }

      if (this.lg) {
        return 'lg';
      }

      if (this.sm) {
        return 'sm';
      }

      return '';
    },
    withIconLeft() {
      return Boolean(this.$slots?.['icon-left']?.[0]);
    },
    withIconRight() {
      return Boolean(this.$slots?.['icon-right']?.[0]);
    }
  },
  methods: {
    async handleInput(event) {
      const targetValue = event.target.value;
      this.$emit('input', targetValue);
    },
    async handleChange(event) {
      this.$emit('change', event);
    },
    handleFocus(event) {
      this.$emit('focus', event);
    },
    handleBlur(event) {
      this.$emit('blur', event);
    },
    handleKeyup(event) {
      this.$emit('keyup', event);
    },
    handleKeydown(event) {
      this.$emit('keydown', event);
    },
    handleIconClick() {
      const inputElement = this.$refs.inputRef;
      inputElement.focus();
    }
  }
};
</script>

<style lang="css" scoped>
/* root input class */
.w-input-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
}

.input-wrapper {
  display: flex;
  width: 100%;
  position: relative;
}

.icon-container {
  position: absolute;

  width: 15%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  cursor: text;
}

.icon-container.left {
  left: 0;
  top: 0;
}

.icon-container.right {
  right: 0;
  top: 0;
}

.w-input {
  display: block;
  width: 100%;
  line-height: 1.25;
  background-image: none;
  background-clip: padding-box;
  border-radius: 0;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
}

.w-input.with-icon-left {
  padding-left: 15%;
}

.w-input.with-icon-right {
  padding-right: 15%;
}

.w-input::placeholder {
  opacity: 0.5;
}

.w-input:focus {
  border-color: var(--brand-info);
}

.w-input:disabled {
  background-color: var(--gray-lighter);
  cursor: not-allowed;
}

.w-input[readonly] {
  background-color: var(--gray-lighter);
  cursor: not-allowed;
  border: 1px solid var(--border-color);
}

/* view type
  он один по умолчанию, поэтому только один класс
*/
.view-type-default {
  color: var(--font-color);
  background-color: transparent;
  border: 1px solid var(--border-color);
  /* todo когда отрефакторим все инпуты в проекте на WInput, можно будет раскомментить */
  /* border-radius: 8px; */
}

/* error tooltip */
.error-tooltip {
  display: block;
  font-size: 0.85rem;
  margin-top: 5px;
  text-align: start;
}

.is-error {
  color: var(--brand-danger);
  border-color: var(--brand-danger)
}

/* label */
.label {
  display: inline-block;
  margin-bottom: 0.5rem;
  color: var(--label-font-color);
  line-height: 1.5;
}

/* size classes */
.sm {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
}
.md {
  padding: 0.5rem 0.75rem;
  font-size: 0.875rem;
}
.lg {
  padding: 0.75rem 1.5rem;
  font-size: 1.25rem;
}
</style>
