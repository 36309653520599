<template>
  <modal v-model="isOpen" class="modal-warning" effect="fade/zoom">
    <h4 slot="modal-title" class="modal-title">{{ $t('general.closeModal') }}</h4>
    <span>{{ $t('general.closeModalConfirm') }}</span>
    <div slot="modal-footer" class="modal-footer">
      <WButton secondary customClass="ml-auto" @click="handleYes">
        {{ $t('general.yes') }}, {{ $t('general.close').toLowerCase() }}
      </WButton>
      <WButton secondary customClass="ml-1" @click="handleNo">
        {{ $t('general.no') }}
      </WButton>
    </div>
  </modal>
</template>

<script>
import Modal from './Modal.vue';

/**
* @deprecated Этот компонент устарел и будет удален в следующих версиях.
* ! Warning: Следует использовать WModalContent или WModalWindow для модальных окон.
*/

export default {
  name: 'CloseDialog',
  components: { Modal },
  props: {
    isOpen: { type: Boolean, required: true },
    handleYes: { type: Function, default: () => {} },
    handleNo: { type: Function, default: () => {} }
  }
};
</script>
