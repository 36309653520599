<template>
  <WDropdown
    class="table-data-downloader-container"
    position="bottom-left"
    openCloseToggleMode
    @close="handleCloseDropdown"
    @open="handleOpenDropdown"
    :isOpen="isDropdownOpen"
  >
    <template v-slot:button-inner-content>
      <WButton info
        customClass="table-data-downloader-button"
        :disabled="isDisabled"
      >
        <i class="fa fa-download"></i>
        <span v-if="!onlyIconButton">
          {{ downloadButtonText }}
        </span>
        <i class="fa fa-caret-down"></i>
      </WButton>
    </template>
    <template v-slot:inner-content>
      <div class="list-wrapper">
        <WList :fixedItems="{ maxItems: 6 }">
          <li v-show="isCsvReport">
            <WButton link fullWidth
              customClass="list-item__button"
              @click="getCsvReport"
            >
              {{ $t('general.downloadCsv')}}
            </WButton>
          </li>
          <li v-if="isXlsReport">
            <WButton link fullWidth
              customClass="list-item__button"
              @click="getXlsxReport"
            >
              {{ $t('general.downloadXlsx') }}
            </WButton>
          </li>
          <li v-if="isPDFReport">
            <WButton link fullWidth
              customClass="list-item__button"
              @click="getPdfReport"
            >
              {{ $t('general.downloadPdf') }}
            </WButton>
          </li>
          <li v-if="isConsolidatedReport">
            <WButton link fullWidth
              customClass="list-item__button"
              @click="getConsolidatedReport"
            >
              {{ $t('general.downloadAllPng') }}
            </WButton>
          </li>
          <li v-if="isVouchersReport">
            <WButton link fullWidth
              customClass="list-item__button"
              @click="getVouchersReport"
            >
              {{ $t('general.downloadVouchers') }}
            </WButton>
          </li>
        </WList>
      </div>
    </template>
  </WDropdown>
</template>

<script>
import XLSX from 'xlsx';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import html2canvas from 'html2canvas';
import helpers from '../helpers';

pdfMake.vfs = pdfFonts.pdfMake.vfs;

export default {
  name: 'TableDataDownloader',
  components: {},
  props: {
    data: {
      type: [Object, Array],
      required: false,
      default() {
        return [];
      }
    },
    // todo отревакторить так, чтобы проп с данными (data и dataForExcel) использовался один - data
    dataForExcel: {
      type: [Object, Boolean],
      required: false,
      default: false
    },
    filename: {
      type: String,
      required: true
    },
    availableReports: {
      type: Object,
      default() {
        return {
          csv: true,
          xlsx: {
            enabled: true,
            // при включении автоматом задает ширину колонок, подсчитывая максимальное  колиество символов в каждой из них
            autoColumnsWidth: false,
            tableHeading: ''
          },
          pdf: {
            enabled: true,
            // Принудительно задаем самый большой размер листа.
            // Нужно например в компоненте Users, в котором список юхеров самого NMS.
            // Там в pdf запихивается хэш пароля, он - длинная строка и на меньших листах он уползает за поля
            // Если же его разорвать, например  с помощью \n, то все разорвется и в CSV файлах
            pageSize: 'A4',
            // // массив, позволяющий задачть ширну колонок в pdf,
            columnsWidth: undefined,
            // // позволяет выключить названия столбцов в pdf
            hideColumnHeadings: false,
            tableHeading: ''
          },
          vouchers: false,
          consolidated: {
            enabled: false,
            selector: 'body',
            customFilename: ''
          }
        };
      }
    },
    buttonText: {
      type: String,
      required: false
    },
    onlyIconButton: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isDropdownOpen: false
    };
  },
  computed: {
    downloadButtonText() {
      if (this.buttonText) {
        return this.buttonText;
      }

      return this.$t('general.download');
    },
    isCsvReport() {
      return (this.availableReports?.csv || this.availableReports?.csv?.enabled)
        && (this.dataForExcel && Object.keys(this.dataForExcel).length);
    },
    isXlsReport() {
      return (this.availableReports?.xlsx || this.availableReports?.xlsx?.enabled)
        && (this.dataForExcel && Object.keys(this.dataForExcel).length);
    },
    isPDFReport() {
      return this.availableReports?.pdf || this.availableReports?.pdf?.enabled;
    },
    isConsolidatedReport() {
      return this.availableReports?.consolidated?.enabled;
    },
    isVouchersReport() {
      return this.availableReports?.vouchers === true;
    },
    isDisabled() {
      if (this.data.length || this.dataForExcel?.length) {
        return false;
      }

      if (!this.data.length || !this.dataForExcel?.length) {
        return true;
      }

      return false;
    }
  },
  watch: {
  },
  methods: {
    handleOpenDropdown() {
      this.isDropdownOpen = true;
    },
    handleCloseDropdown() {
      this.isDropdownOpen = false;
    },
    getTimestamp: helpers.getTimestamp,
    getPdfReport() {
      const {
        pageSize,
        columnsWidth,
        hideColumnHeadings,
        tableHeading
      } = this.availableReports.pdf;

      if (!this.data.length) {
        console.warn('No data');

        return;
      }

      const documentDefinition = {
        pageOrientation: 'landscape',
        pageSize: 'A2',
        content: [
          {
            table: {
              body: []
            }
          }
        ]
      };

      const tableBody = documentDefinition.content[0].table.body;
      const headings = Object.keys(this.data[0]);
      // Pick page size based on number of columns
      const MAX_HEADINGS = 9;

      if (headings.length <= MAX_HEADINGS * 2) {
        documentDefinition.pageSize = 'A3';
      }

      if (headings.length <= MAX_HEADINGS) {
        documentDefinition.pageSize = 'A4';
      }

      if (pageSize) {
        documentDefinition.pageSize = pageSize;
      }

      // если передан пропс с массивом с шириной колонок
      if (Array.isArray(columnsWidth) && columnsWidth.length === headings.length) {
        // устанавливаем ширину для колонок pdf'a
        // (https://pdfmake.github.io/docs/0.1/document-definition-object/tables/)
        documentDefinition.content[0].table.widths = JSON.parse(JSON.stringify(columnsWidth));
      }
      // Populate table with data
      // tableBody.push(headings);
      if (!hideColumnHeadings) {
        tableBody.push(headings);
      }

      this.data.forEach((row) => {
        tableBody.push(Object.values(row));
      });

      if (tableHeading !== '') {
        documentDefinition.content.unshift({
          text: tableHeading,
          style: 'header',
          tocItem: true
        });
        documentDefinition.styles = {
          header: {
            fontSize: 16,
            bold: true,
            lineHeight: 2
          }
        };
      }

      pdfMake
        .createPdf(documentDefinition)
        .download(`${this.filename}.pdf`);

      this.handleCloseDropdown();
    },
    async getConsolidatedReport() {
      const { selector, customFilename } = this.availableReports.consolidated;
      const rootEl = document.querySelector(selector);
      const filename = customFilename || this.filename;

      const canvas = await html2canvas(rootEl, { scale: 2 })

      canvas.toBlob((blob) => {
        const link = document.createElement('a');
        link.download = customFilename ? `${customFilename}.png` : `${filename}_${this.getTimestamp()}.png`;
        link.href = URL.createObjectURL(blob);
        link.click();
        URL.revokeObjectURL(link.href);
      }, 'image/png');

      this.handleCloseDropdown();
    },
    getCsvReport() {
      const tabNames = Object.keys(this.dataForExcel);

      tabNames.forEach((tab) => {
        const workPage = XLSX.utils.aoa_to_sheet(this.dataForExcel[tab]);

        const csv = XLSX.utils.sheet_to_csv(workPage);

        const blob = new Blob([csv], { type: 'text/csv' });
        const url = URL.createObjectURL(blob);

        // Create a link element and set its href to the blob URL
        const a = document.createElement('a');
        a.href = url;
        a.download = `${this.filename}_${tab}.csv`;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        URL.revokeObjectURL(url);
      });

      this.handleCloseDropdown();
    },
    getXlsxReport() {
      const {
        autoColumnsWidth,
        tableHeading
      } = this.availableReports.xlsx;

      // On Click Excel download button
      // console.info(this.dataForExcel);
      const fileType = 'xlsx';

      // A workbook is the name given to an Excel file
      const wb = XLSX.utils.book_new(); // make Workbook of Excel

      const tabNames = Object.keys(this.dataForExcel);

      tabNames.forEach((tab) => {
        let workPage;
        if (tableHeading && tableHeading !== '') {
          const headerRow = XLSX.utils.aoa_to_sheet([[`${tableHeading}`]]);
          workPage = XLSX.utils.sheet_add_aoa(headerRow, this.dataForExcel[tab], { origin: "A3" });
        } else {
          workPage = XLSX.utils.aoa_to_sheet(this.dataForExcel[tab]);
        }
        // console.log(workPage)
        if (autoColumnsWidth) {
          if (this.dataForExcel[tab].length > 0) {
            const numberOfColumns = this.dataForExcel[tab][0].length;
            const maxStringSizeInByColumns = new Array(numberOfColumns).fill(0);
            for (const row of this.dataForExcel[tab]) {
              const rowLen = row.length;
              for (const index in row) {
                const value = row[index];
                let valueLen = 0;
                if (value !== undefined) {
                  valueLen = value.length;
                }
                if (maxStringSizeInByColumns[index] < valueLen) {
                  maxStringSizeInByColumns[index] = valueLen;
                }
              }
            }
            workPage['!cols'] = [];
            for (const columnLen of maxStringSizeInByColumns) {
              workPage['!cols'].push({ width: columnLen + 1 });
            }
          }
        }
        // add Worksheet to Workbook
        // Workbook contains one or more worksheets
        XLSX.utils.book_append_sheet(wb, workPage, tab); // sheetAName is name of Worksheet
      });

      // export Excel file
      XLSX.writeFile(wb, `${this.filename}.${fileType}`, { bookType: fileType }); // name of the file is 'book.xlsx'

      this.handleCloseDropdown();
    },
    getVouchersReport() {
      // On Click Excel download button
      const fileType = 'xlsx';

      const size = 2; // размер подмассива
      // console.log(this.data, 'data');
      const subarray = []; // массив в который будет выведен результат.
      for (let i = 0; i < Math.ceil(this.data.length / size); i += 1) {
        subarray[i] = this.data.slice(i * size, i * size + size);
      }

      // A workbook is the name given to an Excel file
      const wb = XLSX.utils.book_new(); // make Workbook of Excel

      const workPage = XLSX.utils.aoa_to_sheet(subarray);
      workPage['!cols'] = Array(size).fill({ wpx: 250 });
      workPage['!rows'] = Array(Math.ceil(this.data.length / size)).fill({ hpx: 50 });
      // add Worksheet to Workbook
      // Workbook contains one or more worksheets
      XLSX.utils.book_append_sheet(wb, workPage); // sheetAName is name of Worksheet

      // export Excel file
      XLSX.writeFile(wb, `${this.filename}.${fileType}`, { bookType: fileType }); // name of the file is 'book.xlsx'

      this.handleCloseDropdown();
    }
  }
};
</script>

<style lang="css" scoped>
.table-data-downloader-container {
  width: fit-content;
}

.table-data-downloader-button > *:not(:last-child) {
  margin-right: 6px;
}

.list-wrapper {
  min-width: 140px;
  padding: 16px 16px;
  background-color: var(--table-layout-bg-color);
  color: var(--table-font-color);
  /* box-shadow: var(--box-shadow); */
  border-radius: 4px;
  border: 1px solid var(--table-border-color);

  position: relative;
}

.list-item {
  padding: 0;
  cursor: pointer;
  transition: var(--transition);
}

.list-item__button {
  text-align: start;
}

.list-item:hover {
  opacity: var(--opacity);
}

.list-item:not(:last-of-type) {
  margin-bottom: 4px;
}
</style>
