import Vue from 'vue';
import VueNotifications from 'vue-notifications';
import helpers from '../helpers';
import store from '../store';
import { API_URL } from '@/config';

export default {
  getCpeModels(ctx) {
    ctx.$store.commit('toggleLoadingCpeModels');
    Vue.axios
      .post(`${API_URL || ''}/api/cpe_models`, { action: 'R', location: ctx.$store.getters.getDefaultLocationForRequests(ctx), with_childs: ctx.$store.getters.getDefaultWithChildsForRequests(ctx), sort_by: { field: 'description', order: 1 } })
      .then(
        (response) => {
          if (response.data.status === 'success') {
            ctx.$store.commit('setCpeModelsList', response.data.data.itemslist);
          } else if (response.data.status === 'error') {
            VueNotifications.error({ message: response.data.description });
          }
          ctx.$store.commit('toggleLoadingCpeModels');
        },
        (err) => {
          // VueNotifications.error({ message: err });

          // show errors in the console instead of the alert window
          if (
            Object.prototype.hasOwnProperty.call(window, 'isShowAxiosErrorInConsole') &&
            typeof window.isShowAxiosErrorInConsole === 'function' &&
            window.isShowAxiosErrorInConsole(err)
          ) {
            window.showAxiosErrInConsole(err);
          } else {
            VueNotifications.error({ message: err });
          }

          ctx.$store.commit('toggleLoadingCpeModels');
          ctx.error = err;
        }
      );
  },
  // getCpeTemplates(ctx) {
  //   ctx.$store.commit('toggleLoadingCpeTemplates');
  //   Vue.axios.post(`${API_URL || ''}/api/cpe_config_templates`, { action: 'R' }).then(
  //     (response) => {
  //       if (response.data.status === 'success') {
  //         ctx.$store.commit('setCpeTemplatesList', response.data.data.itemslist);
  //       } else if (response.data.status === 'error') {
  //         VueNotifications.error({ message: response.data.description });
  //       }
  //       ctx.$store.commit('toggleLoadingCpeTemplates');
  //     },
  //     (err) => {
  //       // VueNotifications.error({ message: err });
  //
  //       // show errors in the console instead of the alert window
  //       if (
  //         Object.prototype.hasOwnProperty.call(window, 'isShowAxiosErrorInConsole') &&
  //         typeof window.isShowAxiosErrorInConsole === 'function' &&
  //         window.isShowAxiosErrorInConsole(err)
  //       ) {
  //         window.showAxiosErrInConsole(err);
  //       } else {
  //         VueNotifications.error({ message: err });
  //       }
  //
  //       ctx.$store.commit('toggleLoadingCpeTemplates');
  //       ctx.error = err;
  //     }
  //   );
  // },
  getCpeTemplates(ctx, useLocationFilter = false) {
    ctx.$store.commit('toggleLoadingCpeTemplates');
    const isUseLocationFilters = useLocationFilter
    const params = {
      action: 'R'
    }

    if (!isUseLocationFilters) {
      params.location =  ctx.$store.getters.getDefaultLocationForRequests(ctx);
      params.with_childs =  ctx.$store.getters.getDefaultWithChildsForRequests(ctx);
    }

    if (ctx.filters?.location && isUseLocationFilters) {
      if (ctx.filters.location === 'All locations') {
        params.location = ctx.$store.state.requestBaseUserLocationIfNoSelected ? ctx.userBaseLocation : undefined;
        params.with_childs = true;
      } else {
        params.location = ctx.filters.location;
        params.with_childs = ctx.filters.with_childs;
      }
    }


    Vue.axios.post(`${API_URL || ''}/api/cpe_config_templates`, params).then(
      (response) => {
        if (response.data.status === 'success') {
          ctx.$store.commit('setCpeTemplatesList', response.data.data.itemslist);
        } else if (response.data.status === 'error') {
          VueNotifications.error({ message: response.data.description });
        }
        ctx.$store.commit('toggleLoadingCpeTemplates');
      },
      (err) => {
        // VueNotifications.error({ message: err });

        // show errors in the console instead of the alert window
        if (
          Object.prototype.hasOwnProperty.call(window, 'isShowAxiosErrorInConsole') &&
          typeof window.isShowAxiosErrorInConsole === 'function' &&
          window.isShowAxiosErrorInConsole(err)
        ) {
          window.showAxiosErrInConsole(err);
        } else {
          VueNotifications.error({ message: err });
        }

        ctx.$store.commit('toggleLoadingCpeTemplates');
        ctx.error = err;
      }
    );
  },
  deleteTemplate(ctx) {
    const deleteWlanQuery = { action: 'D', items: { [ctx.templateForDelete]: {} } };
    Vue.axios.post(`${API_URL || ''}/api/cpe_config_templates`, deleteWlanQuery).then(
      (response) => {
        if (response.data.status === 'success') {
          ctx.templateForDelete = '';
          VueNotifications.success({ message: ctx.$t('template.notificationTemplateDeletedSuccessfully') });
          ctx.refreshTemplatesList();
          ctx.disableEditMode();
        } else if (response.data.status === 'error') {
          VueNotifications.error({ message: response.data.description });
        }
        ctx.deletingWlanModal = false;
        ctx.cpeForDelete = '';
        ctx.deletingCpeModal = false;
      },
      (err) => {
        // VueNotifications.error({ message: err });

        // show errors in the console instead of the alert window
        if (
          Object.prototype.hasOwnProperty.call(window, 'isShowAxiosErrorInConsole') &&
          typeof window.isShowAxiosErrorInConsole === 'function' &&
          window.isShowAxiosErrorInConsole(err)
        ) {
          window.showAxiosErrInConsole(err);
        } else {
          VueNotifications.error({ message: err });
        }

        ctx.error = err;
      }
    );
  },
  updateCpeTemplate(ctx) {
    ctx.updatedTemplate.template.cpe_config_template.lbs_config.clienttimeout = parseFloat(
      ctx.updatedTemplate.template.cpe_config_template.lbs_config.clienttimeout
    );
    ctx.updatedTemplate.template.cpe_config_template.lbs_config.reportperiod = parseFloat(
      ctx.updatedTemplate.template.cpe_config_template.lbs_config.reportperiod
    );
    ctx.updatedTemplate.template.cpe_config_template.log_config.reportperiod = parseFloat(
      ctx.updatedTemplate.template.cpe_config_template.log_config.reportperiod
    );
    ctx.updatedTemplate.template.cpe_config_template.stats_config.reportperiod = parseFloat(
      ctx.updatedTemplate.template.cpe_config_template.stats_config.reportperiod
    );

    if (ctx.updatedTemplate.template.cpe_config_template.log_config.log_port === '') {
      ctx.updatedTemplate.template.cpe_config_template.log_config.log_port = 0;
    }

    const updatedTemplateQuery = {
      action: 'U',
      items: { [ctx.templateSelectedId]: JSON.parse(JSON.stringify(ctx.updatedTemplate)) }
    };
    const updatedInterfaces = Object.keys(ctx.interfaceSelectedWlans);

    if (ctx.cpeModelData) {
      const newSelectedWifi = helpers.transformArrayToObject(
        updatedTemplateQuery.items[ctx.templateSelectedId].template.cpe_config_template.wifi
      );
      updatedTemplateQuery.items[ctx.templateSelectedId].template.cpe_config_template.wifi.forEach((currIterface) => {
        if (updatedInterfaces.includes(currIterface.id)) {
          const backgroundConfig = currIterface.scanningconfig;
          ctx.interfaceCapabilities[currIterface.id].selected.id = currIterface.id;
          currIterface = JSON.parse(JSON.stringify(ctx.interfaceCapabilities[currIterface.id].selected));
          currIterface.scanningconfig = JSON.parse(
            JSON.stringify(ctx.interfaceCapabilities[currIterface.id].selected.scanningconfig)
          );
          for (const option in currIterface) {
            if (currIterface.frequency === '2.4') {
              if (currIterface.bandmode === '11n') {
                currIterface.bandmode = '11g';
              }
            }

            if (currIterface.frequency === '5') {
              currIterface.bandmode = '11a';
            }
            if (option !== 'channels' && option !== 'wlanconfig' && option !== 'power' && currIterface[option]) {
              currIterface[option] = currIterface[option].toString();
            }
            currIterface.scanningconfig = backgroundConfig;
            currIterface.scanningconfig.reportperiod = 0;
            currIterface.scanningconfig.scannumber = 0;
            currIterface.scanningconfig.scantimeout = 0;
            currIterface.maxclients = parseInt(currIterface.maxclients);
            currIterface.txpower = currIterface.txpower.toString();
            newSelectedWifi[currIterface.id] = currIterface;
          }

          currIterface.wlans = ctx.interfaceSelectedWlans[currIterface.id].map((el) => el.id);
        }
      });
      updatedTemplateQuery.items[ctx.templateSelectedId].template.cpe_config_template.wifi = newSelectedWifi;
      updatedTemplateQuery.items[
        ctx.templateSelectedId
      ].template.cpe_config_template.wired = helpers.transformArrayToObject(
        updatedTemplateQuery.items[ctx.templateSelectedId].template.cpe_config_template.wired
      );
    } else {
      updatedTemplateQuery.items[ctx.templateSelectedId].template.cpe_config_template.wifi = null;
      updatedTemplateQuery.items[ctx.templateSelectedId].template.wlans = ctx.interfaceSelectedWlans.common.map(
        (el) => el.id
      );
    }

    Vue.axios.post(`${API_URL || ''}/api/cpe_config_templates`, updatedTemplateQuery).then(
      (response) => {
        if (response.data.status === 'success') {
          VueNotifications.success({ message: ctx.$t('template.notificationTemplateUpdatedSuccessfully') });
          ctx.refreshTemplatesList();
          ctx.disableEditMode();
        } else if (response.data.status === 'error') {
          VueNotifications.error({ message: response.data.description });
        }
      },
      (err) => {
        // VueNotifications.error({ message: err });

        // show errors in the console instead of the alert window
        if (
          Object.prototype.hasOwnProperty.call(window, 'isShowAxiosErrorInConsole') &&
          typeof window.isShowAxiosErrorInConsole === 'function' &&
          window.isShowAxiosErrorInConsole(err)
        ) {
          window.showAxiosErrInConsole(err);
        } else {
          VueNotifications.error({ message: err });
        }

        ctx.error = err;
      }
    );
  },
  createTemplate(ctx) {
    ctx.$store.commit('toggleLoadingCpeModels', true);
    const { newTemplateData } = ctx.$store.state;

    newTemplateData.template.cpe_config_template.lbs_config.reportperiod = parseFloat(
      newTemplateData.template.cpe_config_template.lbs_config.reportperiod
    );

    Vue.axios
      .post(`${API_URL || ''}/api/cpe_config_templates`, { action: 'C', items: { 0: newTemplateData } })
      .then(
        (response) => {
          if (response.data.status === 'error') {
            VueNotifications.error({ message: response.data.description });
          }
        },
        (err) => {
          // VueNotifications.error({ message: err });

          // show errors in the console instead of the alert window
          if (
            Object.prototype.hasOwnProperty.call(window, 'isShowAxiosErrorInConsole') &&
            typeof window.isShowAxiosErrorInConsole === 'function' &&
            window.isShowAxiosErrorInConsole(err)
          ) {
            window.showAxiosErrInConsole(err);
          } else {
            VueNotifications.error({ message: err });
          }

          ctx.error = err;
        }
      );
  }
};
