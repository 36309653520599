/**
 * Оценивает, является ли текущий пользователь оператором на основе роли в хранилище.
 *
 * @function
 * @returns {boolean} - Возвращает true, если текущий пользователь оператор, иначе - false.
 *
 * @example
 * Пример использования в навигационном охраннике:
 *
 * const router = new VueRouter({
 *   routes: [
 *     {
 *       path: '/some-protected-route',
 *       component: SomeProtectedComponent,
 *       beforeEnter: (to, from, next) => {
 *         if (isOperatorRoleGuard()) {
 *           * // Разрешить навигацию, так как текущий пользователь оператор
 *           next();
 *         } else {
 *           * // Если текущий пользователь не оператор, выполнить перенаправление
 *           next({ name: 'Dashboard' });
 *         }
 *       },
 *     },
 *   ],
 * });
 */

import store from '@/store';

const isOperatorRoleGuard = () => {
  const isOperatorRole = store.state.userData.role === 'operator';

  return isOperatorRole;
};

export default isOperatorRoleGuard;
