<template>
  <div class="product-build-info">
    <ul>
      <li v-if="BUILD_VERSION">
        <small class="muted">
          Version: {{ BUILD_VERSION }}
        </small>
      </li>
      <li v-if="BUILD_DATE">
        <small class="muted">
          Build date: {{ BUILD_DATE }}
        </small>
      </li>
      <li v-if="!isBeelineBranding">
        <small class="muted">
          <a href="http://wimark.com" target="_blank">
            Wimark Systems
          </a>
          <span>
            &copy; {{ new Date().getFullYear() }}
          </span>
        </small>
      </li>
    </ul>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import { BUILD_VERSION, BUILD_DATE } from '@/config';

const { mapGetters: brandingMapGetters } = createNamespacedHelpers('branding');

export default {
  name: 'ProductBuildInfo',
  props: {
  },
  data() {
    return {
    };
  },
  methods: {
  },
  computed: {
    ...brandingMapGetters(['isBeelineBranding'])
  },
  watch: {
  },
  created() {
    this.BUILD_VERSION = BUILD_VERSION;
    this.BUILD_DATE = BUILD_DATE;
  },
  mounted() {
  }
};
</script>

<style lang="css" scoped>
ul {
  margin: 0;
}
.product-build-info {
  display: flex;
  flex-direction: column;
  padding: 0 8px;
}

.muted {
  color: var(--gray-light);
}
</style>
