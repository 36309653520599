<template>
  <AuthenticationWrapper>
    <template v-slot:form-content>
      <AuthenticationCard
        :heading="$t('login.resetPasswordHeader')"
      >
        <RecoverPasswordForm/>
      </AuthenticationCard>
    </template>
  </AuthenticationWrapper>
</template>

<script>
import { AuthenticationWrapper, AuthenticationCard, RecoverPasswordForm } from './components';

export default {
  name: 'RecoverPassword',
  components: {
    AuthenticationWrapper,
    AuthenticationCard,
    RecoverPasswordForm
  }
};
</script>

<style lang="css" scoped>
</style>
