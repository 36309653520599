/**
 * Модуль Vuex для управления брендированием приложения.
 *
 * @module branding
 * @namespace
 *
 * Здесь размещаются стейты, геттеры, мутации и экшны, специфические для брендинга.
 * Для использования в компонентах и других модулях Vuex.
 *
 * doc о модулях vuex:
 * https://v3.vuex.vuejs.org/ru/guide/modules.html
 */

import { BRANDING, BRANDS } from '@/config';

const branding = {
  state: () => ({
    // Текущее брендирование из env-переменной при сборке.
    // Нигде не используется.Просто для удобства дебаггинга в vue-devtools
    brandingName: BRANDING,
    defaultBrandingStatus: BRANDING === BRANDS.default,
    beelineBrandingStatus: BRANDING === BRANDS.beeline,
    QTECHBrandingStatus: BRANDING === BRANDS.qtech,
    AquariusBrandingStatus: BRANDING === BRANDS.aquarius,
    MTSBrandingStatus: BRANDING === BRANDS.mts,
    BulatBrandingStatus: BRANDING === BRANDS.bulat,
    WiflyBrandingStatus: BRANDING === BRANDS.wifly
  }),
  getters: {
    isDefaultBranding: (state) => state.defaultBrandingStatus,
    isBeelineBranding: (state) => state.beelineBrandingStatus,
    isQTECHBranding: (state) => state.QTECHBrandingStatus,
    isAquariusBranding: (state) => state.AquariusBrandingStatus,
    isMTSBranding: (state) => state.MTSBrandingStatus,
    isBulatBranding: (state) => state.BulatBrandingStatus,
    isWiflyBranding: (state) => state.WiflyBrandingStatus
  },
  namespaced: true
};

export default branding;
