<template>
  <div class="d-flex flex-row justify-content-end dasboard-close-button-div-wrapper">
    <div
      role="button"
      @click.stop.prevent="closeBlock"
      class="fa fa-close dasboard-close-button-div"
      :style="closeButtonStyles"
    ></div>
  </div>
</template>

<script>
export default {
  name: 'DashboardBlockCloseButton',
  props: {
    blockSection: { type: String, required: true },
    blockName: { type: String, required: true },
    buttonPaddingTop: { type: String },
    buttonPaddingRight: { type: String }
  },
  methods: {
    closeBlock() {
      if (this.blockSection && this.blockName) {
        this.$store.commit('setDashboardBlockVisibilityForBlock', {
          blockSection: this.blockSection,
          blockName: this.blockName,
          visible: false,
        });
      }
    }
  },
  computed: {
    closeButtonStyles() {
      return {
        paddingTop: this.buttonPaddingTop ?? '0.5em',
        paddingRight: this.buttonPaddingRight ?? '0.5em'
      };
    }
  }
};
</script>
<style lang="scss" scoped>
.dasboard-close-button-div {
  float: right;
  position: absolute;
}
.dasboard-close-button-div-wrapper {
  width: 100%;
}
</style>
