/**
 * Оценивает, включен ли экспериментальный режим на основе состояния хранилища.
 *
 * @function
 * @returns {boolean} - Возвращает true, если экспериментальный режим включен, иначе - false.
 *
 * @example
 * Пример использования в навигационном охраннике
 *
 * const router = new VueRouter({
 *   routes: [
 *     {
 *       path: '/some-protected-route',
 *       component: SomeProtectedComponent,
 *       beforeEnter: (to, from, next) => {
 *         if (isExperimentalModeGuard()) {
 *            * // Разрешить навигацию, так как экспериментальный режим включен
 *           next();
 *         } else {
 *           * // В случае, если экспериментальный режим выключен, выполнить перенаправление
 *           next({ name: 'Dashboard' });
 *         }
 *       },
 *     },
 *   ],
 * });
 */

import store from '@/store';

const isExperimentalModeGuard = () => {
  const isExperimentalModeEnabled = store.getters['featureFlags/isExperimentalModeEnabled'];

  return isExperimentalModeEnabled;
};

export default isExperimentalModeGuard;
