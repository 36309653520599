<template>
  <span :class="[!props.row.group && 'muted']">
    {{ props.row.group ? rrmGroupName : $t('RRM.noGroup') }}
  </span>
</template>

<script>
/**
 * компонент - ячейка group.
 * компонент локальный: используется только в RRMApsTable.vue
 * @component
 */

import commonService from '@/services/commonService';

export default {
  name: 'GroupCell',
  components: {},
  props: {
    props: {
      type: Object,
      required: true
    }
  },
  computed: {
    rrmGroupName() {
      return commonService.getRrmById(this.props.row.group);
    }
  },
  methods: {
  }
};
</script>

<style lang="css" scoped>
.muted {
  color: var(--gray-light);
}
</style>
