import { createNamespacedHelpers } from 'vuex';
/**
 * Миксин содержит различне computed используемые для отображение тех или иных настроек WLAN
 * (например в зависимости от сборки )
 * Используется только в разделах с созданием/редактированием WLAN
 *
 * @mixin
 *
 */

const { mapGetters: WLCProductMapGetters } = createNamespacedHelpers('WLCProduct');

const apsAndTemplatesSettingsVisibilityMixin = {
  computed: {
    ...WLCProductMapGetters(['isWLCProduct']),
    /**
     * Услиовие для отображения (или же нет) блока настроек Wi-fi таргет
     * В настройках ТД/в настройках шаблонов
     * В настоящее время не отображаем для WLC сборки
     *
     * @returns {boolean}
     */
    isWifiTargetBlockShowingInApsSettingsDependingOnTheBuild() {
      return !this.isWLCProduct;
    }
  }
};

export default apsAndTemplatesSettingsVisibilityMixin;
