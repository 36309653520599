<template>
  <footer
    class="app-footer"
    :class="{ 'footer-when-paywall-for-auth-freemium-showing': isPaywallForAuthFreemiumShowing }"
  >
    <!-- <a href="http://wimark.com/">WiMark Systems</a> &copy; 2017. -->
    <!-- <span class="float-right">Powered by <a href="https://genesisui.com">VueJS</a></span> -->
  </footer>
</template>
<script>

export default {
  name: 'VFooter',
  computed: {
    isPaywallForAuthFreemiumShowing() {
      return this.$store.state.isPaywallForAuthFreemiumShowing;
    }
  }
};
</script>
<style lang="scss">
.app-footer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.footer-when-paywall-for-auth-freemium-showing {
  background: rgba(0, 0, 0, 0.49) !important;
  border: 0px !important;
}
</style>
