<template>
  <button
    class="w-button"
    :type="type"
    :class="[viewTypeClass, outlineClass, sizeClass, shapeClass, fullWidthClass, customClass]"
    :style="customStyle"
    :disabled="disabled"
    @click="handleClick"
    @focus="handleFocus"
    @blur="handleBlur"
    @mouseenter="handleMouseEnter"
    @mouseleave="handleMouseLeave"
  >
  <slot>click</slot>
  </button>
</template>

<script>
/**
 * UI-компонент кнопки.
 * Используется во всем проекте. Регистрируется глобально (в конкретном компоненте регистрировать не нужно).
 *
 * @typedef {Object} WButtonProps
 * @property {string} [type='button'] - Тип кнопки: 'button' (по умолчанию), 'submit' или 'reset'.
 * @property {string} [customClass=''] - Пользовательский класс для кнопки. Если вдруг нужно задать нестандартный класс.
 * @property {Object} [customStyle={}] - Пользовательские стили для кнопки в виде объекта. Если вдруг нужно задать нестандартные inline-свойства.
 * @property {boolean} [disabled=false] - Определяет, активна ли кнопка.
 *
 * Вид кнопки:
 * @property {boolean} [primary=true] - primary (по умолчанию).
 * @property {boolean} [secondary=false] - secondary.
 * @property {boolean} [info=false] - info.
 * @property {boolean} [success=false] - success.
 * @property {boolean} [warning=false] - warning.
 * @property {boolean} [danger=false] - danger.
 * @property {boolean} [link=false] - link.
 *
 * @property {boolean} [outline=false] - Определяет, будет ли кнопка с рамкой.
 *
 * Размеры кнопки:
 * @property {boolean} [sm=false] - small.
 * @property {boolean} [md=true] -middle (по умолчанию).
 * @property {boolean} [lg=false] - large.
 *
 * @property {boolean} [fullWidth=false] - Определяет, будет ли кнопка занимать всю ширину.
 *
 * Форма кнопки:
 * @property {boolean} [rounded=false] - Определяет, будет ли кнопка круглой.
 * @property {boolean} [squared=false] - Определяет, будет ли кнопка с квадратными углами.
 *
 * Примеры использования компонента WButton:
 *
 * @example
 * Пример 1: Кнопка по умолчанию
 * <WButton>btn text</WButton>
 *
 * @example
 * Пример 2: Кнопка с разными типами
 * <WButton type="submit">btn text</WButton>
 * <WButton type="reset">btn text</WButton>
 *
 * @example
 * Пример 3: Кнопка с пользовательским классом и стилями
 * <WButton customClass="my-custom-class" :customStyle="{ color: 'red', fontSize: '16px' }">btn text</WButton>
 *
 * @example
 * Пример 4: Кнопка с отключением
 * <WButton disabled>btn text</WButton>
 *
 * @example
 * Пример 5: Кнопка разных видов
 * <WButton primary>btn text</WButton>
 * <WButton secondary>btn text</WButton>
 * <WButton info>btn text</WButton>
 * <WButton success>btn text</WButton>
 * <WButton warning>btn text</WButton>
 * <WButton danger>btn text</WButton>
 * <WButton link>btn text</WButton>
 *
 * @example
 * Пример 6: Кнопка с рамкой и разными размерами
 * <WButton outline sm>btn text</WButton>
 * <WButton success outline md>btn text</WButton>
 * <WButton warning outline lg>btn text</WButton>
 *
 * @example
 * Пример 7: Кнопка с заданным видом + outline + размер
 * <WButton danger outline sm>btn text</WButton>
 *
 * @example
 * Пример 8: Кнопка, занимающая всю ширину
 * <WButton fullWidth>btn text</WButton>
 *
 * @example
 * Пример 9: Кнопка круглая
 * <WButton rounded>btn text</WButton>
 */

export default {
  name: 'WButton',
  props: {
    type: {
      type: String,
      default: 'button',
      validator(value) {
        return ['button', 'submit', 'reset'].indexOf(value) !== -1;
      }
    },
    customClass: {
      type: String,
      default: ''
    },
    customStyle: {
      type: Object,
      default: () => ({})
    },
    disabled: {
      type: Boolean,
      default: false
    },
    // view types
    primary: {
      type: Boolean,
      default: true
    },
    secondary: {
      type: Boolean,
      default: false
    },
    info: {
      type: Boolean,
      default: false
    },
    success: {
      type: Boolean,
      default: false
    },
    warning: {
      type: Boolean,
      default: false
    },
    danger: {
      type: Boolean,
      default: false
    },
    link: {
      type: Boolean,
      default: false
    },
    outline: {
      type: Boolean,
      default: false
    },
    // sizes
    sm: {
      type: Boolean,
      default: false
    },
    md: {
      type: Boolean,
      default: true
    },
    lg: {
      type: Boolean,
      default: false
    },
    fullWidth: {
      type: Boolean,
      default: false
    },
    // shape
    rounded: {
      type: Boolean,
      default: false
    },
    squared: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    viewTypeClass() {
      if (this.secondary) {
        return 'secondary';
      }

      if (this.info) {
        return 'info';
      }

      if (this.success) {
        return 'success';
      }

      if (this.warning) {
        return 'warning';
      }

      if (this.danger) {
        return 'danger';
      }

      if (this.link) {
        return 'link';
      }

      if (this.primary) {
        return 'primary';
      }

      return '';
    },
    sizeClass() {
      if (this.sm) {
        return 'sm';
      }

      if (this.md) {
        return 'md';
      }

      if (this.lg) {
        return 'lg';
      }

      return '';
    },
    outlineClass() {
      return this.outline ? 'outline' : '';
    },
    fullWidthClass() {
      return this.fullWidth ? 'full-width' : '';
    },
    shapeClass() {
      if (this.rounded) {
        return 'rounded';
      }

      if (this.squared) {
        return 'squared';
      }

      return '';
    }
  },
  methods: {
    handleClick(event) {
      this.$emit('click', event);
    },
    handleFocus(event) {
      this.$emit('focus', event);
    },
    handleBlur(event) {
      this.$emit('blur', event);
    },
    handleMouseEnter(event) {
      this.$emit('mouseenter', event);
    },
    handleMouseLeave(event) {
      this.$emit('mouseleave', event);
    }
  }
};
</script>

<style lang="css" scoped>
/* root btn classes */
.w-button {
  margin: 0;
  padding: 0;
  display: inline-block;
  font-weight: normal;
  line-height: 1.25;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  border-radius: var(--border-radius);
  box-shadow: none;
  border: 1px solid transparent;
  background-image: none;
  background-color: transparent;
  transition: 0.2s ease-in-out;
}

.w-button:disabled {
  cursor: not-allowed;
  opacity: 0.65;
}

.w-button:disabled:hover {
  filter: brightness(1);
}

.w-button:hover {
  filter: contrast(0.85);
}

/* type classes */
.primary {
  color: var(--btn-font-color);
  background-color: var(--btn-bg-primary);
  border-color: var(--btn-bg-primary);
}

.primary:focus {
  box-shadow: 0 0 0 0.2rem color-mix(in srgb, var(--btn-bg-primary) 50%, transparent);
}

.secondary {
  color: var(--btn-font-color);
  background-color: var(--btn-bg-secondary);
  border-color: var(--btn-bg-secondary);
}

.secondary:focus {
  box-shadow: 0 0 0 0.2rem color-mix(in srgb, var(--btn-bg-secondary) 50%, transparent);
}

.info {
  color: var(--btn-font-color);
  background-color: var(--btn-bg-info);
  border-color: var(--btn-bg-info);
}

.info:focus {
  box-shadow: 0 0 0 0.2rem color-mix(in srgb, var(--btn-bg-info) 50%, transparent);
}

.success {
  color: var(--btn-font-color);
  background-color: var(--btn-bg-success);
  border-color: var(--btn-bg-success);
}

.success:focus {
  box-shadow: 0 0 0 0.2rem color-mix(in srgb, var(--btn-bg-success) 50%, transparent);
}

.warning {
  color: var(--btn-font-color);
  background-color: var(--btn-bg-warning);
  border-color: var(--btn-bg-warning);
}

.warning:focus {
  box-shadow: 0 0 0 0.2rem color-mix(in srgb, var(--btn-bg-warning) 50%, transparent);
}

.danger {
  color: var(--btn-font-color);
  background-color: var(--btn-bg-danger);
  border-color: var(--btn-bg-danger);
}

.danger:focus {
  box-shadow: 0 0 0 0.2rem color-mix(in srgb, var(--btn-bg-danger) 50%, transparent);
}

.link {
  border-color: transparent;
  color: var(--btn-bg-link);
  border-radius: 0;
}

.link:hover {
  text-decoration: underline;
}

.link:disabled {
  color: var(--gray-light);
  text-decoration: none;
}

/* outline classes*/
.outline {
  background-image: none;
  background-color: transparent;
}

.outline:hover {
  filter: brightness(1);
}

.outline:disabled:hover {
  background-image: none !important;
  background-color: transparent !important;
}

.primary.outline {
  color: var(--btn-bg-primary);
  border-color: var(--btn-bg-primary);
}

.primary.outline:hover {
  color: var(--btn-font-color);
  background-color: var(--btn-bg-primary);
  border-color: var(--btn-bg-primary);
}

.primary.outline:disabled:hover {
  color: var(--btn-bg-primary);
}

.secondary.outline {
  color: var(--btn-bg-secondary);
  border-color: var(--btn-bg-secondary);
}

.secondary.outline:hover {
  color: var(--btn-font-color);
  background-color: var(--btn-bg-secondary);
  border-color: var(--btn-bg-secondary);
}

.secondary.outline:disabled:hover {
  color: var(--btn-bg-secondary);
}

.success.outline {
  color: var(--btn-bg-success);
  border-color: var(--btn-bg-success);
}

.success.outline:hover {
  color: var(--btn-font-color);
  background-color: var(--btn-bg-success);
  border-color: var(--btn-bg-success);
}

.success.outline:disabled:hover {
  color: var(--btn-bg-success);
}

.info.outline {
  color: var(--btn-bg-info);
  border-color: var(--btn-bg-info);
}

.info.outline:hover {
  color: var(--btn-font-color);
  background-color: var(--btn-bg-info);
  border-color: var(--btn-bg-info);
}

.info.outline:disabled:hover {
  color: var(--btn-bg-info);
}

.warning.outline {
  color: var(--btn-bg-warning);
  border-color: var(--btn-bg-warning);
}

.warning.outline:hover {
  color: var(--btn-font-color);
  background-color: var(--btn-bg-warning);
  border-color: var(--btn-bg-warning);
}

.warning.outline:disabled:hover {
  color: var(--btn-bg-warning);
}

.danger.outline {
  color: var(--btn-bg-danger);
  border-color: var(--btn-bg-danger);
}

.danger.outline:hover {
  color: var(--btn-font-color);
  background-color: var(--btn-bg-danger);
  border-color: var(--btn-bg-danger);
}

.danger.outline:disabled:hover {
  color: var(--btn-bg-danger);
}

/* size classes */
.sm {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
}
.md {
  padding: 0.5rem 1rem;
  font-size: 0.875rem;
}

.lg {
  padding: 0.75rem 1.5rem;
  font-size: 1.25rem;
}

/* full width class */
.full-width {
  display: block;
  width: 100%;
}

/* shape classes */

.rounded {
  aspect-ratio: 1/1;
  border-radius: 50%;
}

.squared {
  aspect-ratio: 1/1;
  border-radius: 4px;
}
</style>
