/**
 * Оценивает, является ли текущая сборка любой но НЕ WLC.
 *
 * @function
 * @returns {boolean} - Возвращает true, если сборка НЕ WLC, иначе - false.
 *
 * @example
 * Пример использования в навигационном охраннике
 *
 * const router = new VueRouter({
 *   routes: [
 *     {
 *       path: '/some-protected-route',
 *       component: SomeProtectedComponent,
 *       beforeEnter: (to, from, next) => {
 *         if (isWLCProductGuard()) {
 *           * // Разрешить навигацию, так как сборка НЕ WLC
 *           next();
 *         } else {
 *           * // В случае, если сборка WLC, выполнить перенаправление
 *           next({ name: 'Dashboard' });
 *         }
 *       },
 *     },
 *   ],
 * });
 */

import store from '@/store';

const isNotWLCProductGuard = () => {
  const isWLCProduct = store.getters['WLCProduct/isWLCProduct'];

  return !isWLCProduct;
};

export default isNotWLCProductGuard;
