<template>
  <AuthenticationFormTemplate
    :submit="handleSubmit"
    :subheadingText="$t('login.signHeader')"
    :successText="successText"
    :errorText="getAuthenticationErrorTextForDisplayWithI18n(errorText)"
  >
    <template v-slot:fields>
      <WInput md
        tabindex="1"
        id="username"
        name="username"
        customClass="auth-input"
        v-model.trim="credentials.username"
        :placeholder="$t('login.username')"
        data-vv-validate-on="input"
        v-validate="'required'"
        :disabled="isLoading"
        autocomplete="on"
        :data-vv-as="$t('login.username')"
        :errorText="errors.first('username')"
      >
        <template v-slot:icon-left>
          <i class="fa fa-user"></i>
        </template>
      </WInput>
      <WInput md
        tabindex="2"
        id="password"
        name="password"
        customClass="auth-input"
        :type="isPasswordHidden ? 'password' : 'text'"
        v-model.trim="credentials.password"
        :placeholder="$t('login.password')"
        data-vv-validate-on="input"
        v-validate="'required'"
        :disabled="isLoading"
        autocomplete="on"
        :data-vv-as="$t('login.password')"
        :errorText="errors.first('password')"
      >
        <template v-slot:icon-left>
          <button type="button"
            class="password-icon-button"
            @click="togglePasswordVisibility"
          >
            <i class="fa" :class="isPasswordHidden ? 'fa-eye-slash' : 'fa-eye'"></i>
          </button>
        </template>
        <template v-slot:icon-right>
          <button type="button"
            v-show="credentials.password"
            class="password-icon-button"
            @click="clearPasswordValue"
          >
            <i class="fa fa-remove"></i>
          </button>
        </template>
      </WInput>
    </template>

    <template v-slot:subfields>
      <WButton link sm
        v-if="!isWLCProduct"
        @click="redirectToRecoverPassword"
        :disabled="isLoading"
      >
        {{ $t('login.resetPassword') }}
      </WButton>
    </template>

    <template v-slot:buttons>
      <WButton primary fullWidth
        tabindex="3"
        type="submit"
        :disabled="isLoading || errors.any()"
      >
        {{ isLoading ? $t('general.state.loading') : $t('login.login') }}
      </WButton>
    </template>
  </AuthenticationFormTemplate>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import authApi from '@/services/auth';
import AuthenticationFormTemplate from './AuthenticationFormTemplate.vue';
import { authenticationErrorsShowingWithI18nMixin } from './mixins';

const { mapGetters: WLCProductMapGetters } = createNamespacedHelpers('WLCProduct');

export default {
  name: 'LoginForm',
  mixins: [authenticationErrorsShowingWithI18nMixin],
  components: {
    AuthenticationFormTemplate
  },
  props: {
  },
  data() {
    return {
      credentials: {
        username: '',
        password: ''
      },
      isLoading: false,
      errorText: '',
      successText: '',
      isPasswordHidden: true
    };
  },
  computed: {
    ...WLCProductMapGetters(['isWLCProduct'])
  },
  methods: {
    togglePasswordVisibility() {
      this.isPasswordHidden = !this.isPasswordHidden;
    },
    clearPasswordValue() {
      this.credentials.password = '';
    },
    redirectToRecoverPassword() {
      this.$router.push({ name: 'RecoverPassword' });
    },
    redirectAfterLogin(userRole) {
      if (userRole === 'hotel_employee') {
        this.$router.push({ path: '/portal/hotel-vouchers' });
        return;
      }

      if (userRole === 'advertising') {
        this.$router.push({ path: '/portal/ads' });
        return;
      }

      if (this.isPortalOnlyModeEnabled) {
        this.$router.push({ path: '/portal/dashboard' });
        return;
      }

      // default
      this.$router.push({ path: '/dashboard' });
    },
    async handleSubmit() {
      const isFormValid = await this.$validator.validateAll();

      if (!isFormValid) {
        return;
      }

      authApi.login({
        username: this.credentials.username,
        password: this.credentials.password
      }, {
        onLoading: () => {
          this.isLoading = true;
          this.successText = '';
          this.errorText = '';
        },
        onSuccess: (userData) => {
          this.isLoading = false;

          this.$store.commit('setUserData', userData);

          const { role } = userData;

          this.redirectAfterLogin(role);
        },
        onError: (error) => {
          this.errorText = error;
          this.isLoading = false;
        }
      });
    }
  },
  watch: {
  },
  created() {
  },
  mounted() {
  }
};
</script>

<style lang="css" scoped>
</style>
