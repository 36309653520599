<template>
  <div class="tabs">
    <div class="tabs-switcher">
      <button v-for="(tab, index) in tabs"
        type="button" :key="tab.id"
        @click="setCurrentTab(index)"
        :class="[
          currentTab === index && 'active',
          getErrorClass(tab.name)
        ]"
      >
        {{ tab.label }}
      </button>
    </div>
    <div class="tab-wrapper">
      <slot></slot>
    </div>
  </div>
</template>

<script>
/**
 * UI-компонент табов.
 * ! Используется во всем проекте. Регистрируется глобально (в конкретном компоненте регистрировать не нужно).
 * @component
 *
 * не зависит от и не контролирует содержимое таба
 * не управляет текущим табом. Стейт таба должен задаваться из родителя и передаваться пропом currentTab.
 * может выделять нужные табы красным в случае ошибки. Особенно полезно в случае формы на табах. Для этого необходимо передать проп tabsWithErrors.
 *
 * @example
 *  <Tabs
      :tabs="tabs"
      :currentTab="currentTab"
      :tabsWithErrors="tabsWithErrors"
      @on-tab-change="handleTabClick"
    >
      <template v-for="tab in tabs">
        <div class="tab" :key="tab.id">
          <h3>
            {{ tab.heading }}
          </h3>
          <div>
            {{ tab.content }}
          </div>
        </div>
      </template>
    </Tabs>
 */

export default {
  name: 'Tabs',
  inject: ['$validator'],
  components: {},
  props: {
    tabs: {
      type: Array,
      required: true
    },
    currentTab: {
      type: Number,
      required: true
    },
    tabsWithErrors: {
      type: Array,
      default: () => ([])
    }
  },
  data() {
    return {
    };
  },
  computed: {
  },
  watch: {
  },
  methods: {
    getErrorClass(tabName) {
      const isIncludes = this.tabsWithErrors.includes(tabName);
      return isIncludes && 'error';
    },
    setCurrentTab(index) {
      this.$emit('on-tab-change', index);
    }
  }
};
</script>

<style lang="css" scoped>
.tabs {
  display: flex;
  flex-direction: column;
  gap: 16px;
  height: 100%;
}

.tabs-switcher {
  border-bottom: 1px solid var(--border-color);
}

.tab-wrapper {
  flex: 1 0 auto;
}

button {
  color: var(--font-color);
  cursor: pointer;
  border-radius: 12.5px 12.5px 0px 0px;
  border: 1px solid transparent;
  padding: 0.5em 1em;
  background: transparent;

  transition: var(--transition);
}

button.active {
  border-color: var(--gray-lighter);
  border-bottom-color: var(--white);
}

button.error {
  background-color: var(--brand-danger);
  color: var(--white);
}

.tab-content {
  padding: 20px;
  border: 1px solid var(--border-color);
}
</style>
