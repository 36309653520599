import { render, staticRenderFns } from "./ChangeAPProfileForm.vue?vue&type=template&id=39858d8b&scoped=true&"
import script from "./ChangeAPProfileForm.vue?vue&type=script&lang=js&"
export * from "./ChangeAPProfileForm.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "39858d8b",
  null
  
)

export default component.exports