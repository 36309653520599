var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"tooltip",rawName:"v-tooltip.top-center",value:({
      content: _vm.getWlanStatusForTooltipWithI18N(_vm.props.row.status),
      popperOptions: {
      modifiers: {
            preventOverflow: {
            boundariesElement: 'window'
          }
        }
      }
    }),expression:"{\n      content: getWlanStatusForTooltipWithI18N(props.row.status),\n      popperOptions: {\n      modifiers: {\n            preventOverflow: {\n            boundariesElement: 'window'\n          }\n        }\n      }\n    }",modifiers:{"top-center":true}}],staticClass:"status-cell-wrapper"},[_c('span',{staticClass:"status-badge",class:{
      'connected': _vm.props.row.status === 'Active',
      'disconnected': _vm.props.row.status === 'Not active',
      'updating': _vm.props.row.status === 'Pending'
    }})])}
var staticRenderFns = []

export { render, staticRenderFns }