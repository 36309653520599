/* eslint-disable no-restricted-syntax */

import { v4 as uuidv4 } from 'uuid';
import helpers from '@/helpers';
import commonService from '@/services/commonService';

function getWiredCellData(wiredConfig, clients) {
  function calculateWiredVLANs(wiredConfig) {
    if (!wiredConfig?.length) {
      return null;
    }

    const amountOfWiredVlans = wiredConfig.reduce((counter, wiredInterface) => {
      const isVlansPropertyExisis = Object.prototype.hasOwnProperty.call(wiredInterface, 'vlans');

      if (isVlansPropertyExisis && wiredInterface.id.includes('eth')) {
        const updatedCounter = counter + wiredInterface.vlans.length;
        return updatedCounter;
      }

      return counter;
    }, 0);

    return amountOfWiredVlans;
  }

  function calculateWiredClients(clients) {
    if (!clients) {
      return null;
    }

    const ethKeys = Object.keys(clients).filter((client) => client.includes('eth'));

    if (!ethKeys.length) {
      return null;
    }

    return ethKeys.reduce((acc, current) => clients[current].total + acc, 0);
  }

  // !!! legacy function
  function checkAreTunnelAndWLANtunnelNotMatchAndNotNatForTable(wired) {
    // console.log(wired)
    // возвращаем true если туннель и туннель выбраной wlan (если он есть) не совпадают в каком-либо wired config (и для этого wired config включено тунелирование)
    // чтобы показать сообщение об этом в UI (если true)
    try {
      const wiredInterfaces = JSON.parse(JSON.stringify(wired));
      for (const wiredItem of wiredInterfaces) {
        if (Object.prototype.hasOwnProperty.call(wiredItem, 'vlans') && Array.isArray(wiredItem.vlans)) {
          for (const vlanItem of wiredItem.vlans) {
            if (
              typeof vlanItem === 'object'
                && Object.prototype.hasOwnProperty.call(vlanItem, 'tunnel')
                && Object.prototype.hasOwnProperty.call(vlanItem, 'fake_wlan')
                && Object.prototype.hasOwnProperty.call(vlanItem, 'nat')
            ) {
              const { tunnel } = vlanItem;
              const wlanId = vlanItem.fake_wlan;
              const { nat } = vlanItem;
              let selectedWlanTunnel = '';

              for (const wlan of this.wlansList) {
                if (wlan.id === wlanId) {
                  if (Object.prototype.hasOwnProperty.call(wlan, 'default_tunnel')) {
                    selectedWlanTunnel = wlan.default_tunnel;
                  }
                  break;
                }
              }

              if (selectedWlanTunnel !== '' && selectedWlanTunnel !== tunnel && !nat) {
                return true;
              }
            }
          }
        }
      }

      return false;
    } catch (e) {
      console.log(e);
      return false;
    }
  }

  return {
    amountOfWiredVlans: calculateWiredVLANs(wiredConfig),
    amountOfWiredClients: calculateWiredClients(clients),
    areTunnelAndWLANtunnelNotMatchAndNotNatForTable: checkAreTunnelAndWLANtunnelNotMatchAndNotNatForTable(wiredConfig)
  };
}

function getFrequencyCellsData(ap) {
  // !!! legacy function
  function wlansState(cpe, id) {
    const stateOfWlans = {
      active: [],
      notActive: [],
      updating: []
    };
    const cpeConfig = helpers.transformArrayToObject(cpe.config.wifi);
    if (!cpeConfig[id]) {
      return stateOfWlans;
    }
    const wlansConfigArray = cpeConfig[id].wlans;
    if (!cpe.state.wifi) {
      return stateOfWlans;
    }
    const wlanStates = cpe.state.wifi[id].wlanstates;

    wlansConfigArray.forEach((wlanId) => {
      if (wlanStates[wlanId] && wlanStates[wlanId].state === 'ENABLED') {
        if (commonService.wlanNamebyId(wlanId)) {
          stateOfWlans.active.push(commonService.wlanNamebyId(wlanId));
        }
      } else if (!wlanStates[wlanId] || wlanStates[wlanId].state === 'DISABLED') {
        if (commonService.wlanNamebyId(wlanId)) {
          stateOfWlans.notActive.push(commonService.wlanNamebyId(wlanId));
        }
      } else if (commonService.wlanNamebyId(wlanId)) {
        stateOfWlans.updating.push(commonService.wlanNamebyId(wlanId));
      }
    });

    return stateOfWlans;
  }

  // state.wifi
  const wifiStateEntries = (ap.state.wifi ? Object.entries(ap.state.wifi) : [])
    .map(([key, wifiState]) => {
      return {
        _id: uuidv4(),
        key,
        ...wifiState,
        calculatedTotalWlansState: wlansState(ap, key),
        totalClients: ap.clients[key]?.total
      };
    });

  const twoWifiStateArray = wifiStateEntries.filter((wifiState) => {
    return wifiState.frequency === '2.4';
  });

  const fiveWifiStateArray = wifiStateEntries.filter((wifiState) => {
    return wifiState.frequency === '5';
  });

  // config.wifi
  const wifiConfigArray = (ap.config?.wifi?.length ? ap.config.wifi : [])
    .map(wifiConfig => {
      return {
        _id: uuidv4(),
        ...wifiConfig
      };
    });

  const twoWifiConfigArray = wifiConfigArray.filter(el => {
    return el.frequency === '2.4';
  });

  const fiveWifiConfigArray = wifiConfigArray.filter(el => {
    return el.frequency === '5';
  });

  return {
    twoGhzCellData: {
      wifiState: twoWifiStateArray,
      wifiConfig: twoWifiConfigArray
    },
    fiveGhzCellData: {
      wifiState: fiveWifiStateArray,
      wifiConfig: fiveWifiConfigArray
    }
  };
}

// disabled or enabled checkbox of a row
function getDisabledSelectionAbilityRowValue(apConfigStatus) {
  return apConfigStatus === 'updating' || apConfigStatus === 'upgrading';
}

// selected checkbox of a row or not
function getSelectedRowValue(currentApId, selectedAPIdsArray) {
  let value = false;

  selectedAPIdsArray.forEach(apId => {
    if (apId === currentApId) {
      value = true;
    }
  });

  return value;
}

export {
  getFrequencyCellsData,
  getWiredCellData,
  getDisabledSelectionAbilityRowValue,
  getSelectedRowValue
};
