<template>
  <WModalWindow
    :isOpen="isOpen"
    @close="handleClose"
    v-bind="$attrs"
  >
    <div class="content" :class="[viewTypeClass]">
      <div class="header">
        <h4 class="heading">
          {{ heading }}
        </h4>
      </div>
      <div class="body">
        <slot name="ap-profile-form"></slot>
      </div>
    </div>
  </WModalWindow>
</template>

<script>
/**
 * локальный компонент модалки с таб-формой для последующей настройки.
 * функционально - это компонент-обертка для более специфических компонентов-модалкок с настроенной формой - BranchProfileForm.
 * по FSD это слой feature https://feature-sliced.design/ru/docs/get-started/overview#layers
 * @component
 */

export default {
  name: 'BranchProfileFormModal',
  components: {
  },
  props: {
    isOpen: {
      type: Boolean,
      required: true
    },
    heading: {
      type: String,
      required: true
    }
  },
  data() {
    return {
    };
  },
  computed: {
    viewTypeClass() {
      if (this.checkCurrentViewType('secondary')) {
        return 'secondary';
      }

      if (this.checkCurrentViewType('info')) {
        return 'info';
      }

      if (this.checkCurrentViewType('success')) {
        return 'success';
      }

      if (this.checkCurrentViewType('warning')) {
        return 'warning';
      }

      if (this.checkCurrentViewType('danger')) {
        return 'danger';
      }

      return 'primary';
    }
  },
  methods: {
    checkCurrentViewType(type) {
      const attrsKeys = Object.keys(this.$attrs);

      return attrsKeys.includes(type);
    },
    handleClose() {
      this.$emit('close');
    }
  },
  created() {
  }
};
</script>

<style lang="css" scoped>
  h4 {
    margin: 0;
  }

  .content {
    display: flex;
    flex-direction: column;
    min-height: 70vh;
  }

  .content>div {
    padding: 16px;
  }

  .header {
    flex: 0 0 auto;
    color: var(--modal-header-color);
  }

  .body {
    flex: 1 1 100%;
    display: flex;
  }

  /* type classes */
  .primary>.header {
    background-color: var(--modal-primary);
  }

  .primary>div:not(:last-of-type) {
    border-bottom: 1px solid var(--modal-primary);
  }

  .secondary>.header {
    background-color: var(--modal-secondary);
  }

  .secondary>div:not(:last-of-type) {
    border-bottom: 1px solid var(--modal-secondary);
  }

  .info>.header {
    background-color: var(--modal-info);
  }

  .info>div:not(:last-of-type) {
    border-bottom: 1px solid var(--modal-info);
  }

  .success>.header {
    background-color: var(--modal-success);
  }

  .success>div:not(:last-of-type) {
    border-bottom: 1px solid var(--modal-success);
  }

  .warning>.header {
    background-color: var(--modal-warning);
  }

  .warning>div:not(:last-of-type) {
    border-bottom: 1px solid var(--modal-warning);
  }

  .danger>.header {
    background-color: var(--modal-danger);
  }

  .danger>div:not(:last-of-type) {
    border-bottom: 1px solid var(--modal-danger);
  }
</style>
