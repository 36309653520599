<template>
  <WButton info squared sm
    customClass="rrm-groups-button"
    v-tooltip.top-center="{ content: `${$t('RRM.tableRRMCPEsTooltip')}` }"
    @click.stop="handleClickRRMApsButton"
  >
    {{ props.row.aps.length }}
  </WButton>
</template>

<script>
/**
 * компонент - ячейка aps.
 * компонент локальный: используется только в RRMGroupsTable.vue
 * @component
 */

export default {
  name: 'ApsCell',
  components: {},
  props: {
    props: {
      type: Object,
      required: true
    }
  },
  methods: {
    handleClickRRMApsButton() {
      this.$emit('on-rrm-group-aps-button-click');
    }
  }
};
</script>

<style lang="css" scoped>
.muted {
  color: var(--gray-light);
}
</style>
