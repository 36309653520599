<template>
  <div class="row" :class="{ 'full-screen': mapFullScreen }">
    <div class="col-lg-12">
      <div class="card">
        <div class="card-block">
          <div class="h4 mb-xs">{{ $t('maps.setMapUnitsPerPixelNew') }}</div>

          <div class="map-container">
            <l-map id="map" ref="map" :crs="crs" :minZoom="minZoom" @click="addMarker">
              <l-image-overlay
                :url="`${API_URL || ''}${mapData[0].image_url}`"
                :bounds="originalBounds"
                :crossOrigin="crossOrigin"
              ></l-image-overlay>
              <l-polyline :latLngs="measureToLine" v-if="measure.length === 2"></l-polyline>
            </l-map>
          </div>
          <WButton success outline
            customClass="mt-1"
            @click="updateMapScale"
            :disabled="errors.any() || !isTwoPoints"
          >
            {{ $t('maps.saveAndCreateButton') }}
          </WButton>
          <div class="set-scale-block card">
            <p class="mb-0" v-if="isTwoPoints">
              {{ $t('maps.scaleModeDistance') }}
              <span v-if="dataFromMap.scale && isTwoPoints">
                {{ $t('maps.scaleModeOf') }} {{ dataFromMap.scale }} {{ $t('maps.mapsWizardPx') }}
              </span>
            </p>
            <p>
              <small v-if="!isTwoPoints" class="text-danger">{{ $t('maps.scaleModeSetTwoPoints') }}</small>
              <small v-else class="text-success">{{ $t('maps.scaleModeTypeDistance') }}</small>
            </p>
            <div class="form-group">
              <div class="input-group-btn">
                <select class="btn btn-default form-control" v-model="dataFromMap.scaleInUnits">
                  <option value="m">m</option>
                  <!--<option value="m">ft</option>-->
                </select>
              </div>
              <input
                type="number"
                :disabled="!isTwoPoints"
                :placeholder="$t('maps.scaleModePlaceholder')"
                :class="{ input: true, 'is-danger': errors.has('distance') }"
                name="distance"
                v-validate="'required|decimal:2|min_value:0.01|max_value:999'"
                class="form-control"
                v-model.number="dataFromMap.distance"
                :data-vv-as="$t('maps.scaleModeDistance')"
              />
              <span v-show="errors.has('distance')" class="help is-danger location-danger">
                {{ errors.first('distance') }}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VueNotifications from 'vue-notifications';
import { LMap, LImageOverlay, LPolyline } from 'vue2-leaflet';
import { API_URL } from '@/config';
import mapsService from '../../services/mapsService';

export default {
  name: 'MapsStep3',
  components: {
    LMap,
    LImageOverlay,
    LPolyline
  },
  data() {
    return {
      crs: L.CRS.Simple,
      minZoom: -4,
      measure: [],
      currentMap: null,
      crossOrigin: true,
      map: null,
      mapFullScreen: false,
      dataFromMap: {
        distance: '',
        scale: null,
        scaleInUnits: 'm'
      }
    };
  },
  computed: {
    distance() {
      return this.dataFromMap.distance;
    },
    window() {
      return window;
    },
    mapData() {
      return this.$store.state.createdMapData;
    },
    muteUpdate() {
      return this.$store.state.updateMap;
    },
    originalBounds() {
      if (this.currentMap.height && this.currentMap.width) {
        return [
          [0, 0],
          [this.currentMap.height, this.currentMap.width]
        ];
      }
    },
    isTwoPoints() {
      return this.measure.length === 2;
    },
    measureToLine() {
      const measureLine = this.measure.map((point) => [point._latlng.lat, point._latlng.lng]);
      return measureLine;
    }
  },
  methods: {
    updateMapScale() {
      this.$validator
        .validateAll({
          distance: this.dataFromMap.distance
        })
        .then((result) => {
          if (result) {
            mapsService.createMapScale(this, this.nextStep);
          }
        });
    },
    addMarker(e) {
      if (this.measure.length === 2) {
        this.measure.forEach((marker) => {
          this.$refs.map.mapObject.removeLayer(marker);
        });
        this.measure = [];
      }
      const newMarker = new L.marker(e.latlng).addTo(this.$refs.map.mapObject);
      this.measure.push(newMarker);

      if (this.measure.length === 2) {
        this.getDistance();
      }
    },
    getDistance() {
      if (this.measure.length !== 2) {
        return;
      }
      const a = this.measure[0]._latlng;
      const b = this.measure[1]._latlng;

      const distance = Math.sqrt(Math.pow(b.lat - a.lat, 2) + Math.pow(b.lng - a.lng, 2));
      this.dataFromMap.scale = Math.round(distance);
    },
    nextStep() {
      mapsService.getMaps(this);
      this.$router.push({ name: 'maps' });
      VueNotifications.success({ message: this.$t('maps.notificationMapCreatedSuccessfully') });
    }
  },
  mounted() {
    if (this.originalBounds) {
      this.$refs.map.mapObject.fitBounds(this.originalBounds);
    }
    // ставим курсор - палец
    document.getElementById('map').style.cursor = 'pointer';
  },
  created() {
    this.API_URL = API_URL;

    if (!this.$store.state.createdMapData) {
      this.$router.push({ name: 'maps-step-1' });
    } else {
      this.currentMap = this.$store.state.createdMapData[0];
    }
  },
  watch: {
    distance(newValue) {
      // console.log('watching!')
      if (newValue > 999) {
        setTimeout(() => {
          this.dataFromMap.distance = 999;
        }, 700);
      }
    }
  }
};
</script>
<style lang="scss">
.map-container {
  position: relative;
  min-width: 100px;
  height: 530px;
  width: 100%;
  display: flex;
}
</style>
