import { createNamespacedHelpers } from 'vuex';
import locationService from '@/services/locationService';
import radiusService from '@/services/radiusService';
import firewallService from '@/services/firewallService';
import operationsService from '@/services/operationsService';
import redirectsService from '@/services/redirectsService';
// import helpers from '@/helpers';

const { mapGetters: themeMapGetters } = createNamespacedHelpers('theme');

// todo: отрефакторить и избавиться от него после того, как стилизация избавится от бутстрапа

// этот миксин содержит в себе легаси из старого компонента src/components/Header.vue
// здесь содержится то, что не удалось распилить при рефакторинге и создании нового Header.vue
// оставлен для совместимости и примешивается только 1 раз на уровне Header.vue


// здесь содержится:
// 1. логика каких - то запросов

// В дальшейшем - отрефактроить и примешивать его только там, где он нужен / удалить его совсем

const oldHeaderLegacyCodeMixin = {
  computed: {
    ...themeMapGetters(['isDarkTheme']),
  },
  methods: {
  },
  created() {
    // если список локаций почему-то пуст, то подгружаем, чтобы показать картинку в шапке
    if (Object.keys(this.$store.state.locationsItems).length === 0) {
      // console.log(this.$store.state.locationsItems);
      locationService.getLocations(this);
    }
    if (localStorage.getItem('bossUserData')) {
      this.$store.commit('subordinateLoginSetTrue');
    }

    if (!this.$store.state.radiusList) {
      radiusService.getRadius(this);
    }
    if (!this.$store.state.firewallsList) {
      firewallService.getFirewalls(this);
    }
    if (!this.$store.state.operationsList || !this.$store.state.operationsList.length) {
      operationsService.getPedingOperations(this);
    }
    if (!this.$store.state.redirectsList || !this.$store.state.redirectsList.length) {
      redirectsService.getRedirectsRule(this);
    }
  },
};

export default oldHeaderLegacyCodeMixin;
