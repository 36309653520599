import {
  executeRouterGuards,
  isNotOperatorRoleGuard
} from './navigationGuards';

import Templates from '../views/Templates/Templates.vue';
import TemplatesWizard from '../views/Templates/TemplatesWizard.vue';
import TemplatesStep1 from '../views/Templates/TemplatesStep1.vue';
import TemplatesStep2 from '../views/Templates/TemplatesStep2.vue';
import TemplatesStep3 from '../views/Templates/TemplatesStep3.vue';

const TemplatesRoute = {
  path: 'Templates',
  name: 'Templates',
  component: Templates,
  children: [
    {
      path: 'templates-wizard',
      name: 'templates-wizard',
      redirect: 'templates-wizard/templates-step-1',
      component: TemplatesWizard,
      beforeEnter: executeRouterGuards(isNotOperatorRoleGuard, { name: 'Templates' }),
      children: [
        {
          path: 'templates-step-1',
          name: 'templates-step-1',
          component: TemplatesStep1
        },
        {
          path: 'templates-step-2',
          name: 'templates-step-2',
          component: TemplatesStep2
        },
        {
          path: 'templates-step-3',
          name: 'templates-step-3',
          component: TemplatesStep3
        }
      ]
    }
  ]
};

export default TemplatesRoute;
