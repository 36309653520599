/* eslint-disable consistent-return */
import store from '@/store';
import { AXIOS_INSTANCE, POST_LOGIN_URL } from '../config';
import { setIsAuthenticatedStatus } from '../utils';

/**
 * Функция-фетчер для отправки запроса на аутентификацию юзера.
 *
 * @param {Object} credentials - данные для аутентификации.
 * @param {string} credentials.username - логин.
 * @param {string} credentials.password - пароль.
 * @returns {Promise<Object>} - Обещание с данными ответа.
 * @throws {Error} - Ошибка при выполнении запроса.
 */

const loginFetcher = async (credentials) => {
  if (!credentials) {
    throw new Error('credentials must be set');
  }

  try {
    const response = await AXIOS_INSTANCE.post(POST_LOGIN_URL, credentials);

    const { data } = response;

    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};

/**
 * фукнция-обработчик фетчера loginFetcher.
 * выполняет побочные действия и обрабатывает тело запроса.
 *
 * @param {Object} credentials - данные для аутентификации.
 * @param {string} credentials.username - логин.
 * @param {string} credentials.password - пароль.
 * @param {Object} [options] - Дополнительные опции запроса.
 * @param {Function} [options.onLoading] - Callback при начале выполнения запроса.
 * @param {Function} [options.onSuccess] - Callback при успешном выполнении запроса.
 * @param {Function} [options.onError] - Callback при ошибке выполнения запроса.
 * @returns {Promise<Object>} - Обещание с данными ответа.
 * @throws {Error} - Ошибка при выполнении запроса.
 */
const login = async (credentials, options) => {
  const {
    onLoading,
    onSuccess,
    onError
  } = options;

  try {
    if (onLoading) {
      onLoading();
    }

    const response = await loginFetcher(credentials);

    const { data: { items }, status, description } = response;

    const authToken = items.token;

    if (status === 'error') {
      throw new Error(description);
    }

    if (status === 'success' && authToken) {
      store.commit('setSuccess');

      setIsAuthenticatedStatus({ status: true, token: authToken });

      if (onSuccess) {
        onSuccess(items);
      }

      return Promise.resolve(items);
    }
  } catch (error) {
    if (onError) {
      onError(error.message);
    }
    store.commit('setError');
  }
};

export default login;
