import Vue from 'vue';
import VueNotifications from 'vue-notifications';
import wlansPollingService from './wlansPollingService';
import helpers from '../helpers';
import cpeService from './cpeService';
import store from '../store';
import { API_URL } from '@/config';
import { VLANHelpers } from '@/helpers/VLAN';
import { copyAndModifyWLANObjectBeforeSendToAPI } from '../views/WLANS/helpers';

export default {
  // getQuery(ctx, typeOfQuery) {
  //   const query = {
  //     action: 'R',
  //     search: ctx.searchValue,
  //     with: ['status', 'clients'],
  //     sort_by: {
  //       field: 'ssid',
  //       order: 1
  //     },
  //     page: {},
  //     q: {}
  //   };
  //
  //   if (typeOfQuery === 'paginate') {
  //     query.page.limit = ctx.limit;
  //     query.page.offset = ctx.limit * ctx.offset;
  //   } else if (typeOfQuery === 'update') {
  //     query.page.limit = ctx.limit + ctx.limit * ctx.offset;
  //     query.page.offset = 0;
  //   }
  //
  //   if (ctx.filters.location !== 'All locations') {
  //     query.q = Object.assign(query.q, { base_location: ctx.filters.location });
  //   }
  //
  //   if (ctx.filters.tag !== 'All tags') {
  //     query.q = Object.assign(query.q, { tag: ctx.filters.tag });
  //   }
  //
  //   if (ctx.filters.securityFilter) {
  //     query.q = Object.assign(query.q, { 'security.type': ctx.filters.securityFilter });
  //   }
  //
  //   return query;
  // },
  getQuery(ctx, typeOfQuery) {
    const query = {
      action: 'R',
      location: ctx.$store.getters.getDefaultLocationForRequests(ctx), with_childs: ctx.$store.getters.getDefaultWithChildsForRequests(ctx),
      search: ctx.searchValue,
      with: ['status', 'clients'],
      sort_by: {
        field: 'ssid',
        order: 1
      },
      page: {},
      q: {}
    };

    if (typeOfQuery === 'paginate') {
      query.page.limit = ctx.limit;
      query.page.offset = ctx.limit * ctx.offset;
    } else if (typeOfQuery === 'update') {
      query.page.limit = ctx.limit + ctx.limit * ctx.offset;
      query.page.offset = 0;
    }
    // if (ctx.filters.location !== 'All locations') {
    //   query.q = Object.assign(query.q, { base_location: ctx.filters.location });
    // }
    if (ctx.filters?.location && Object.prototype.hasOwnProperty.call(ctx.filters, 'with_childs')) {
      if (ctx.filters.location === 'All locations') {
        // console.log('ctx.userBaseLocation', ctx.userBaseLocation);
        if (ctx.userBaseLocation) {
          query.location = ctx.$store.state.requestBaseUserLocationIfNoSelected ? ctx.userBaseLocation : undefined;
        }
        query.with_childs = true;
      } else {
        query.location = ctx.filters.location;
        query.with_childs = ctx.filters.with_childs;
      }
    }

    if (ctx.filters.tag !== 'All tags') {
      query.q = Object.assign(query.q, { tag: ctx.filters.tag });
    }

    if (ctx.filters.securityFilter) {
      query.q = Object.assign(query.q, { 'security.type': ctx.filters.securityFilter });
    }

    if (ctx.filters.dataTrafficManagementType && ctx.filters.dataTrafficManagementType !== '') {

      if (ctx.filters.dataTrafficManagementType === 'localSwitching') {
        const noTunneling = { 'tunneling': false}
        const natEnabled = { 'nat': true };
        // const hasVlan = { 'vlan': { $ne: 0 } }
        const hasVlan = { 'vlan': { $nin: ['', 0] } }
        const noTunnelingAndhasVlan = { $and: [noTunneling, hasVlan] };
        const queryParamsForLocalSwitching = { $or: [natEnabled, noTunnelingAndhasVlan] };
        query.q = Object.assign(query.q, queryParamsForLocalSwitching);
      }
      if (ctx.filters.dataTrafficManagementType === 'centralSwitching') {
        query.q = Object.assign(query.q, { 'tunneling': true });
        query.q = Object.assign(query.q, { 'proto': 'l2tpv3' });
      }

      if (ctx.filters.dataTrafficManagementType === 'tunneling') {
        query.q = Object.assign(query.q, { 'tunneling': true });
        query.q = Object.assign(query.q, { 'proto': { $ne:'l2tpv3' } });
      }


    }

    return query;
  },
  getQueryV2(ctx, typeOfQuery) {
    const query = {
      action: 'R',
      location: ctx.$store.getters.getDefaultLocationForRequests(ctx), with_childs: ctx.$store.getters.getDefaultWithChildsForRequests(ctx),
      search: ctx.searchValue,
      with: ['status', 'clients'],
      sort_by: {
        field: 'ssid',
        order: 1
      },
      page: {},
      q: {}
    };

    query.sort_by = { field: ctx.filters.sort_by.field, order: ctx.filters.sort_by.order }
    //
    // if (typeOfQuery === 'paginate') {
    //   query.page.limit = ctx.limit;
    //   query.page.offset = ctx.limit * ctx.offset;
    // } else if (typeOfQuery === 'update') {
    //   query.page.limit = ctx.limit + ctx.limit * ctx.offset;
    //   query.page.offset = 0;
    // }

    query.page.limit = ctx.limit;
    query.page.offset = ctx.offset;

    // if (ctx.filters.location !== 'All locations') {
    //   query.q = Object.assign(query.q, { base_location: ctx.filters.location });
    // }
    if (ctx.filters?.location && Object.prototype.hasOwnProperty.call(ctx.filters, 'with_childs')) {
      if (ctx.filters.location === 'All locations') {
        // console.log('ctx.userBaseLocation', ctx.userBaseLocation);
        if (ctx.userBaseLocation) {
          query.location = ctx.$store.state.requestBaseUserLocationIfNoSelected ? ctx.userBaseLocation : undefined;
        }
        query.with_childs = true;
      } else {
        query.location = ctx.filters.location;
        query.with_childs = ctx.filters.with_childs;
      }
    }

    if (ctx.filters.tag !== 'All tags') {
      query.q = Object.assign(query.q, { tag: ctx.filters.tag });
    }

    if (ctx.filters.securityFilter) {
      query.q = Object.assign(query.q, { 'security.type': ctx.filters.securityFilter });
    }

    if (ctx.filters.dataTrafficManagementType && ctx.filters.dataTrafficManagementType !== '') {

      if (ctx.filters.dataTrafficManagementType === 'localSwitching') {
        const noTunneling = { 'tunneling': false}
        const natEnabled = { 'nat': true };
        // const hasVlan = { 'vlan': { $ne: 0 } }
        const hasVlan = { 'vlan': { $nin: ['', 0] } }
        const noTunnelingAndhasVlan = { $and: [noTunneling, hasVlan] };
        const queryParamsForLocalSwitching = { $or: [natEnabled, noTunnelingAndhasVlan] };
        query.q = Object.assign(query.q, queryParamsForLocalSwitching);
      }
      if (ctx.filters.dataTrafficManagementType === 'centralSwitching') {
        query.q = Object.assign(query.q, { 'tunneling': true });
        query.q = Object.assign(query.q, { 'proto': 'l2tpv3' });
      }

      if (ctx.filters.dataTrafficManagementType === 'tunneling') {
        query.q = Object.assign(query.q, { 'tunneling': true });
        query.q = Object.assign(query.q, { 'proto': { $ne:'l2tpv3' } });
      }


    }

    return query;
  },

  getCurrentCpes(ctx, list) {
    const cpesIds = [];
    list.forEach((wlan) => {
      wlan.cpe_ids.forEach((cpe_id) => {
        if (!cpesIds.includes(cpe_id)) {
          cpesIds.push(cpe_id);
        }
      });
    });
    cpeService.getSelectedCpes(ctx, cpesIds);
  },

  getPaginatedWlans(ctx) {
    ctx.$store.commit('toggleLoadingWlans');

    Vue.axios.post(`${API_URL || ''}/api/wlans`, this.getQuery(ctx, 'paginate')).then(
      (response) => {
        if (response.data.status === 'success') {
          ctx.totalResult = response.data.data.total;
          this.getCurrentCpes(ctx, response.data.data.itemslist);

          ctx.$store.commit('pushWlanList', response.data.data.itemslist);
          if (ctx.$store.state.wlansList.length >= response.data.data.total) {
            ctx.canLoadMore = false;
          } else {
            ctx.canLoadMore = true;
          }
          ctx.searchSpinner = false;
          ctx.$store.commit('toggleLoadingWlans');
        } else if (response.data.status === 'error') {
          VueNotifications.error({ message: response.data.description });
          ctx.searchSpinner = false;
          ctx.$store.commit('toggleLoadingWlans');
        }
      },
      (err) => {
        // VueNotifications.error({ message: err });

        // show errors in the console instead of the alert window
        if (
          Object.prototype.hasOwnProperty.call(window, 'isShowAxiosErrorInConsole') &&
          typeof window.isShowAxiosErrorInConsole === 'function' &&
          window.isShowAxiosErrorInConsole(err)
        ) {
          window.showAxiosErrInConsole(err);
        } else {
          VueNotifications.error({ message: err });
        }

        ctx.$store.commit('toggleLoadingWlans');
        ctx.error = err;
        ctx.searchSpinner = false;
      }
    );
  },


  getPaginatedWlansV2(ctx) {
    ctx.$store.commit('setWlansList', false);
    ctx.$store.commit('toggleLoadingWlans');

    Vue.axios.post(`${API_URL || ''}/api/wlans`, this.getQueryV2(ctx, 'paginate')).then(
      (response) => {
        if (response.data.status === 'success') {
          ctx.totalResult = response.data.data.total;
          this.getCurrentCpes(ctx, response.data.data.itemslist);

          ctx.$store.commit('pushWlanList', response.data.data.itemslist);
          if (ctx.$store.state.wlansList.length >= response.data.data.total) {
            ctx.canLoadMore = false;
          } else {
            ctx.canLoadMore = true;
          }
          ctx.searchSpinner = false;
          ctx.$store.commit('toggleLoadingWlans');
        } else if (response.data.status === 'error') {
          VueNotifications.error({ message: response.data.description });
          ctx.searchSpinner = false;
          ctx.$store.commit('toggleLoadingWlans');
        }
      },
      (err) => {
        // VueNotifications.error({ message: err });

        // show errors in the console instead of the alert window
        if (
          Object.prototype.hasOwnProperty.call(window, 'isShowAxiosErrorInConsole') &&
          typeof window.isShowAxiosErrorInConsole === 'function' &&
          window.isShowAxiosErrorInConsole(err)
        ) {
          window.showAxiosErrInConsole(err);
        } else {
          VueNotifications.error({ message: err });
        }

        ctx.$store.commit('toggleLoadingWlans');
        ctx.error = err;
        ctx.searchSpinner = false;
      }
    );
  },


  refreshWlans(ctx) {
    Vue.axios.post(`${API_URL || ''}/api/wlans`, this.getQuery(ctx, 'update')).then(
      (response) => {
        if (response.data.status === 'success') {
          ctx.totalResult = response.data.data.total;
          ctx.$store.commit('setWlansList', response.data.data.itemslist);

          this.getCurrentCpes(ctx, response.data.data.itemslist);

          ctx.$store.commit('setWlansList', response.data.data.itemslist);
          if (ctx.$store.state.wlansList.length >= response.data.data.total) {
            ctx.canLoadMore = false;
          } else {
            ctx.canLoadMore = true;
          }
          ctx.searchSpinner = false;
        } else if (response.data.status === 'error') {
          ctx.searchSpinner = false;
          VueNotifications.error({ message: response.data.description });
        }
      },
      (err) => {
        ctx.searchSpinner = false;
        // VueNotifications.error({ message: err });

        // show errors in the console instead of the alert window
        if (
          Object.prototype.hasOwnProperty.call(window, 'isShowAxiosErrorInConsole') &&
          typeof window.isShowAxiosErrorInConsole === 'function' &&
          window.isShowAxiosErrorInConsole(err)
        ) {
          window.showAxiosErrInConsole(err);
        } else {
          VueNotifications.error({ message: err });
        }

        ctx.error = err;
      }
    );
  },

  refreshWlansV2(ctx) {
    this.getPaginatedWlansV2(ctx)
  },

  getAllWlans(ctx) {
    ctx.$store.commit('toggleLoadingWlans');
    Vue.axios
      .post(`${API_URL || ''}/api/wlans/compact`, {
        action: 'R',
        location: ctx.$store.getters.getDefaultLocationForRequests(ctx), with_childs: ctx.$store.getters.getDefaultWithChildsForRequests(ctx),
        with: ['status', 'clients'],
        sort_by: {
          field: 'ssid',
          order: 1
        }
      })
      .then(
        (response) => {
          if (response.data.status === 'success') {
            ctx.$store.commit('setWlansList', response.data.data.itemslist);
            ctx.$store.commit('setWlansItems', helpers.transformArrayToObject(response.data.data.itemslist));
          } else if (response.data.status === 'error') {
            VueNotifications.error({ message: response.data.description });
          }
          ctx.$store.commit('toggleLoadingWlans');
        },
        (err) => {
          // VueNotifications.error({ message: err });

          // show errors in the console instead of the alert window
          if (
            Object.prototype.hasOwnProperty.call(window, 'isShowAxiosErrorInConsole') &&
            typeof window.isShowAxiosErrorInConsole === 'function' &&
            window.isShowAxiosErrorInConsole(err)
          ) {
            window.showAxiosErrInConsole(err);
          } else {
            VueNotifications.error({ message: err });
          }

          ctx.$store.commit('toggleLoadingWlans');
          ctx.error = err;
        }
      );
  },

  async requestWlansWithSearch(searchQuery) {
    const config = {
      action: 'R',
      location: store.getters.getDefaultLocationForRequests(undefined), with_childs: store.getters.getDefaultWithChildsForRequests(undefined),
      search: searchQuery,
      with: ['status', 'clients'],
      sort_by: {
        field: 'ssid',
        order: 1
      }
    };
    if (searchQuery === '') {
      config.page = {
        limit: 10
      };
    }
    const json = await Vue.axios.post(`${API_URL || ''}/api/wlans/compact`, config);
    if (json.data.status === 'success') {
      return json.data.data;
    }
    return [];
  },

  getAllWPAOrWPA2PersonalWlansCompact(ctx) {
    // запрашиваем только WPA и WPA2 Personal сети
    ctx.$store.commit('toggleLoadingWlans');
    Vue.axios
      .post(`${API_URL || ''}/api/wlans/compact`, {
        action: 'R',
        location: ctx.$store.getters.getDefaultLocationForRequests(ctx), with_childs: ctx.$store.getters.getDefaultWithChildsForRequests(ctx),
        with: ['status', 'clients'],
        sort_by: {
          field: 'ssid',
          order: 1
        },
        q: { 'security.type': { $in: ['wpa2personal', 'wpapersonal'] } } // запрашиваем только WPA и WPA2 Personal сети
      })
      .then(
        (response) => {
          if (response.data.status === 'success') {
            ctx.$store.commit('setWlansList', response.data.data.itemslist);
            ctx.$store.commit('setWlansItems', helpers.transformArrayToObject(response.data.data.itemslist));
          } else if (response.data.status === 'error') {
            VueNotifications.error({ message: response.data.description });
          }
          ctx.$store.commit('toggleLoadingWlans');
        },
        (err) => {
          // VueNotifications.error({ message: err });

          // show errors in the console instead of the alert window
          if (
            Object.prototype.hasOwnProperty.call(window, 'isShowAxiosErrorInConsole') &&
            typeof window.isShowAxiosErrorInConsole === 'function' &&
            window.isShowAxiosErrorInConsole(err)
          ) {
            window.showAxiosErrInConsole(err);
          } else {
            VueNotifications.error({ message: err });
          }

          ctx.$store.commit('toggleLoadingWlans');
          ctx.error = err;
        }
      );
  },
  getAllWPAPersonalWlansCompact(ctx) {
    // запрашиваем только WPA и WPA2 Personal сети
    ctx.$store.commit('toggleLoadingWlans');
    Vue.axios
      .post(`${API_URL || ''}/api/wlans/compact`, {
        action: 'R',
        location: ctx.$store.getters.getDefaultLocationForRequests(ctx), with_childs: ctx.$store.getters.getDefaultWithChildsForRequests(ctx),
        with: ['status', 'clients'],
        sort_by: {
          field: 'ssid',
          order: 1
        },
        q: { 'security.type': { $in: ['wpa2personal', 'wpapersonal', 'wpa3personal', 'wpa23personal'] } } // запрашиваем WPA, WPA2, WPA3, WPA2/3  Personal сети
      })
      .then(
        (response) => {
          if (response.data.status === 'success') {
            ctx.$store.commit('setWlansList', response.data.data.itemslist);
            ctx.$store.commit('setWlansItems', helpers.transformArrayToObject(response.data.data.itemslist));
          } else if (response.data.status === 'error') {
            VueNotifications.error({ message: response.data.description });
          }
          ctx.$store.commit('toggleLoadingWlans');
        },
        (err) => {
          // VueNotifications.error({ message: err });

          // show errors in the console instead of the alert window
          if (
            Object.prototype.hasOwnProperty.call(window, 'isShowAxiosErrorInConsole') &&
            typeof window.isShowAxiosErrorInConsole === 'function' &&
            window.isShowAxiosErrorInConsole(err)
          ) {
            window.showAxiosErrInConsole(err);
          } else {
            VueNotifications.error({ message: err });
          }

          ctx.$store.commit('toggleLoadingWlans');
          ctx.error = err;
        }
      );
  },

  getSelectedWlans(ctx, wlansIds) {
    Vue.axios
      .post(`${API_URL || ''}/api/wlans/compact`, { action: 'R', ids: wlansIds, with: ['status', 'clients'] })
      .then(
        (response) => {
          if (response.data.status === 'success') {
            ctx.wlansList = response.data.data.itemslist;
            ctx.$store.commit('setWlansList', response.data.data.itemslist);
          } else if (response.data.status === 'error') {
            VueNotifications.error({ message: response.data.description });
          }
        },
        (err) => {
          // VueNotifications.error({ message: err });

          // show errors in the console instead of the alert window
          if (
            Object.prototype.hasOwnProperty.call(window, 'isShowAxiosErrorInConsole') &&
            typeof window.isShowAxiosErrorInConsole === 'function' &&
            window.isShowAxiosErrorInConsole(err)
          ) {
            window.showAxiosErrInConsole(err);
          } else {
            VueNotifications.error({ message: err });
          }

          ctx.error = err;
        }
      );
  },

  getHosts(ctx) {
    Vue.axios.post(`${API_URL || ''}/api/hosts`, { action: 'R', location: ctx.$store.getters.getDefaultLocationForRequests(ctx), with_childs: ctx.$store.getters.getDefaultWithChildsForRequests(ctx), with: ['status', 'clients'] }).then(
      (response) => {
        if (response.data.status === 'success') {
          ctx.hostsList = response.data.data.itemslist;
          ctx.$store.commit('setHostsList', response.data.data.itemslist);
        } else if (response.data.status === 'error') {
          VueNotifications.error({ message: response.data.description });
        }
      },
      (err) => {
        // VueNotifications.error({ message: err });

        // show errors in the console instead of the alert window
        if (
          Object.prototype.hasOwnProperty.call(window, 'isShowAxiosErrorInConsole') &&
          typeof window.isShowAxiosErrorInConsole === 'function' &&
          window.isShowAxiosErrorInConsole(err)
        ) {
          window.showAxiosErrInConsole(err);
        } else {
          VueNotifications.error({ message: err });
        }

        ctx.error = err;
      }
    );
  },

  getWlansTags(ctx) {
    Vue.axios.post(`${API_URL || ''}/api/tags/wlans`, { action: 'R', location: ctx.$store.getters.getDefaultLocationForRequests(ctx), with_childs: ctx.$store.getters.getDefaultWithChildsForRequests(ctx)  }).then(
      (response) => {
        if (response.data.status === 'success') {
          ctx.$store.commit('setWlansTagsList', response.data.data.itemslist);
        } else if (response.data.status === 'error') {
          VueNotifications.error({ message: response.data.description });
        }
      },
      (err) => {
        // VueNotifications.error({ message: err });

        // show errors in the console instead of the alert window
        if (
          Object.prototype.hasOwnProperty.call(window, 'isShowAxiosErrorInConsole') &&
          typeof window.isShowAxiosErrorInConsole === 'function' &&
          window.isShowAxiosErrorInConsole(err)
        ) {
          window.showAxiosErrInConsole(err);
        } else {
          VueNotifications.error({ message: err });
        }

        ctx.error = err;
      }
    );
  },

  updateWlan(ctx) {
    ctx.$store.commit('clearUpdatedWlanIdsInOperations'); // в конечном итоге используется для спинера поверх обновляемой WLAN WNE-2408
    ctx.$store.commit('toggleMuteWlanEdit', true);
    const updatedWlan = JSON.parse(JSON.stringify(ctx.updatedWlan));
    updatedWlan.vlan = parseInt(updatedWlan.vlan);
    const radiusAcctServersIds = helpers.combineIdsArrayFromObjectsArray(ctx.updatedWlanRadiusAccounting);
    const radiusMACIds = helpers.combineIdsArrayFromObjectsArray(ctx.updatedWlanRadiusMAC);
    if (updatedWlan.security.type == 'open') {
      updatedWlan.roam80211r = false;
      if (Object.prototype.hasOwnProperty.call(updatedWlan, 'ft_over_ds')) {
        updatedWlan.ft_over_ds = false;
      }
    }

    updatedWlan.radius_acct_servers = radiusAcctServersIds;
    updatedWlan.guest_control.mac_radius_auth_servers = radiusMACIds;

    // if (
    //   updatedWlan.security &&
    //   (updatedWlan.security.type === 'wpa2enterprise' || updatedWlan.security.type === 'wpaenterprise')
    // ) {
    //   const radiusAuthServersIds = helpers.combineIdsArrayFromObjectsArray(ctx.updatedWlanRadiusAuthentication);
    //   updatedWlan.security.data.radiusauthentication = radiusAuthServersIds;
    // }

    if (
      updatedWlan.security &&
      (updatedWlan.security.type === 'wpa2enterprise' || updatedWlan.security.type === 'wpaenterprise' ||
      updatedWlan.security.type === 'wpa3enterprise' || updatedWlan.security.type === 'wpa23enterprise' ||
        (updatedWlan.security.type.indexOf('personal') !== -1 && ctx.isPPSKEnabled))
    ) {
      const radiusAuthServersIds = helpers.combineIdsArrayFromObjectsArray(ctx.updatedWlanRadiusAuthentication);
      updatedWlan.security.data.radiusauthentication = radiusAuthServersIds;
    }


    if (!updatedWlan.tunneling) {
      updatedWlan.default_tunnel = '';
    }

    // TODO удалить закоменченую старую реализацию очисиски WMM если не появится багов (17.07.24)
    // Удалено в процессе задачи https://wimark.kaiten.ru/space/327612/card/35693179
    // if (updatedWlan.wmm.categories) {
    //   for (const category in updatedWlan.wmm.categories) {
    //     for (const config in updatedWlan.wmm.categories[category]) {
    //       if (!updatedWlan.wmm.categories[category][config]) {
    //         delete updatedWlan.wmm.categories[category][config];
    //       }
    //     }
    //   }
    // }
    if (!updatedWlan.vlan) {
      updatedWlan.vlan = 0;
    }
    updatedWlan.vlans = VLANHelpers.convertDynamicVlanStringToArrayFormat(ctx.dynamicVlansString);

    updatedWlan.whitelist = ctx.filterList.whitelist;
    updatedWlan.blacklist = ctx.filterList.blacklist;

    const updateWlanQuery = { action: 'U', items: { [updatedWlan.id]: updatedWlan } };
    Vue.axios.post(`${API_URL || ''}/api/wlans`, updateWlanQuery).then(
      (response) => {
        if (response.data.status === 'success') {
          wlansPollingService.hadleOperationResponse(response.data.data.items);
          // console.log(response.data.data)
          // далее  в конечном итоге используется для спинера поверх обновляемой WLAN WNE-2408
          this.pushWlansIdsToUpdatedWlanIdsInOperations(response.data.data.itemslist);
          ctx.hideSettings();
          ctx.refreshWlansList();
          ctx.refreshWlansTags();
        } else if (response.data.status === 'error') {
          VueNotifications.error({ message: response.data.description });
        }
        ctx.$store.commit('toggleMuteWlanEdit', false);
      },
      (err) => {
        // VueNotifications.error({ message: err });

        // show errors in the console instead of the alert window
        if (
          Object.prototype.hasOwnProperty.call(window, 'isShowAxiosErrorInConsole') &&
          typeof window.isShowAxiosErrorInConsole === 'function' &&
          window.isShowAxiosErrorInConsole(err)
        ) {
          window.showAxiosErrInConsole(err);
        } else {
          VueNotifications.error({ message: err });
        }

        ctx.$store.commit('toggleMuteWlanEdit', false);
        ctx.error = err;
      }
    );
  },
  updateWlanV2(ctx) {
    ctx.$store.commit('clearUpdatedWlanIdsInOperations'); // в конечном итоге используется для спинера поверх обновляемой WLAN WNE-2408
    ctx.$store.commit('toggleMuteWlanEdit', true);
    // const updatedWlan = JSON.parse(JSON.stringify(ctx.updatedWlan));
    const updatedWlan = copyAndModifyWLANObjectBeforeSendToAPI(ctx.updatedWlan);
    updatedWlan.vlan = parseInt(updatedWlan.vlan);
    const radiusAcctServersIds = helpers.combineIdsArrayFromObjectsArray(ctx.updatedWlanRadiusAccounting);
    const radiusMACIds = helpers.combineIdsArrayFromObjectsArray(ctx.updatedWlanRadiusMAC);
    if (updatedWlan.security.type == 'open') {
      updatedWlan.roam80211r = false;
      if (Object.prototype.hasOwnProperty.call(updatedWlan, 'ft_over_ds')) {
        updatedWlan.ft_over_ds = false;
      }
    }

    updatedWlan.radius_acct_servers = radiusAcctServersIds;
    updatedWlan.guest_control.mac_radius_auth_servers = radiusMACIds;

    if (Object.prototype.hasOwnProperty.call(updatedWlan, 'r0kh') && ctx.isWLCProduct) {
      updatedWlan.r0kh = helpers.combineIdsArrayFromObjectsArray(ctx.updatedWlanApsForDirectRRBExchange);
    }

    // if (
    //   updatedWlan.security &&
    //   (updatedWlan.security.type === 'wpa2enterprise' || updatedWlan.security.type === 'wpaenterprise')
    // ) {
    //   const radiusAuthServersIds = helpers.combineIdsArrayFromObjectsArray(ctx.updatedWlanRadiusAuthentication);
    //   updatedWlan.security.data.radiusauthentication = radiusAuthServersIds;
    // }

    if (
      updatedWlan.security &&
      (updatedWlan.security.type === 'wpa2enterprise' || updatedWlan.security.type === 'wpaenterprise' ||
      updatedWlan.security.type === 'wpa3enterprise' || updatedWlan.security.type === 'wpa23enterprise') ||
      (updatedWlan.security.type.indexOf('personal') !== -1 && ctx.isPPSKEnabled)
    ) {
      const radiusAuthServersIds = helpers.combineIdsArrayFromObjectsArray(ctx.updatedWlanRadiusAuthentication);
      updatedWlan.security.data.radiusauthentication = radiusAuthServersIds;
    }


    if (!updatedWlan.tunneling) {
      updatedWlan.default_tunnel = '';
    }

    // TODO удалить закоменченую старую реализацию очисиски WMM если не появится багов (17.07.24)
    // Удалено в процессе задачи https://wimark.kaiten.ru/space/327612/card/35693179
    // if (updatedWlan.wmm.categories) {
    //   for (const category in updatedWlan.wmm.categories) {
    //     for (const config in updatedWlan.wmm.categories[category]) {
    //       if (!updatedWlan.wmm.categories[category][config]) {
    //         delete updatedWlan.wmm.categories[category][config];
    //       }
    //     }
    //   }
    // }
    if (!updatedWlan.vlan) {
      updatedWlan.vlan = 0;
    }
    updatedWlan.vlans = VLANHelpers.convertDynamicVlanStringToArrayFormat(ctx.dynamicVlansString);

    updatedWlan.whitelist = ctx.filterList.whitelist;
    updatedWlan.blacklist = ctx.filterList.blacklist;

    const updateWlanQuery = { action: 'U', items: { [updatedWlan.id]: updatedWlan } };
    Vue.axios.post(`${API_URL || ''}/api/wlans`, updateWlanQuery).then(
      (response) => {
        if (response.data.status === 'success') {
          wlansPollingService.hadleOperationResponse(response.data.data.items);
          // console.log(response.data.data)
          // далее  в конечном итоге используется для спинера поверх обновляемой WLAN WNE-2408
          this.pushWlansIdsToUpdatedWlanIdsInOperations(response.data.data.itemslist);
          ctx.hideSettings();
          ctx.refreshWlansList();
          ctx.refreshWlansTags();
        } else if (response.data.status === 'error') {
          VueNotifications.error({ message: response.data.description });
        }
        ctx.$store.commit('toggleMuteWlanEdit', false);
      },
      (err) => {
        // VueNotifications.error({ message: err });

        // show errors in the console instead of the alert window
        if (
          Object.prototype.hasOwnProperty.call(window, 'isShowAxiosErrorInConsole') &&
          typeof window.isShowAxiosErrorInConsole === 'function' &&
          window.isShowAxiosErrorInConsole(err)
        ) {
          window.showAxiosErrInConsole(err);
        } else {
          VueNotifications.error({ message: err });
        }

        ctx.$store.commit('toggleMuteWlanEdit', false);
        ctx.error = err;
      }
    );
  },

  createWlanFromModalV2(ctx) {
    ctx.$store.commit('clearUpdatedWlanIdsInOperations'); // в конечном итоге используется для спинера поверх обновляемой WLAN WNE-2408
    ctx.$store.commit('toggleMuteWlanEdit', true);
    // const updatedWlan = JSON.parse(JSON.stringify(ctx.updatedWlan));
    const updatedWlan = copyAndModifyWLANObjectBeforeSendToAPI(ctx.updatedWlan);
    updatedWlan.vlan = parseInt(updatedWlan.vlan);
    const radiusAcctServersIds = helpers.combineIdsArrayFromObjectsArray(ctx.updatedWlanRadiusAccounting);
    const radiusMACIds = helpers.combineIdsArrayFromObjectsArray(ctx.updatedWlanRadiusMAC);
    if (updatedWlan.security.type == 'open') {
      updatedWlan.roam80211r = false;
      if (Object.prototype.hasOwnProperty.call(updatedWlan, 'ft_over_ds')) {
        updatedWlan.ft_over_ds = false;
      }
    }

    updatedWlan.radius_acct_servers = radiusAcctServersIds;
    updatedWlan.guest_control.mac_radius_auth_servers = radiusMACIds;

    if (Object.prototype.hasOwnProperty.call(updatedWlan, 'r0kh') && ctx.isWLCProduct) {
      updatedWlan.r0kh = helpers.combineIdsArrayFromObjectsArray(ctx.updatedWlanApsForDirectRRBExchange);
    }

    if (!Object.prototype.hasOwnProperty.call(updatedWlan, 'central_auth') && ctx.isWLCProduct) {
      // на случай если юзер создает новую сеть wlan и никак не взаимодействовал со свитчером (в таком случае central_auth - undefined )
      // чтобы на бэк ушло false
      updatedWlan.central_auth = false;
    }

    if (!Object.prototype.hasOwnProperty.call(updatedWlan, 'nas_ip') && ctx.isWLCProduct) {
      // на случай если юзер создает новую сеть wlan и никак не взаимодействовал со полем ввода nas_ip
      // чтобы на бэк ушла пустая строка ''
      updatedWlan.nas_ip = '';
    }

    // if (
    //   updatedWlan.security &&
    //   (updatedWlan.security.type === 'wpa2enterprise' || updatedWlan.security.type === 'wpaenterprise')
    // ) {
    //   const radiusAuthServersIds = helpers.combineIdsArrayFromObjectsArray(ctx.updatedWlanRadiusAuthentication);
    //   updatedWlan.security.data.radiusauthentication = radiusAuthServersIds;
    // }

    if (
      updatedWlan.security &&
      (updatedWlan.security.type === 'wpa2enterprise' || updatedWlan.security.type === 'wpaenterprise' ||
      updatedWlan.security.type === 'wpa3enterprise' || updatedWlan.security.type === 'wpa23enterprise') ||
      (updatedWlan.security.type.indexOf('personal') !== -1 && ctx.isPPSKEnabled)
    ) {
      const radiusAuthServersIds = helpers.combineIdsArrayFromObjectsArray(ctx.updatedWlanRadiusAuthentication);
      updatedWlan.security.data.radiusauthentication = radiusAuthServersIds;
    }


    if (!updatedWlan.tunneling) {
      updatedWlan.default_tunnel = '';
    }
    // TODO удалить закоменченую старую реализацию очисиски WMM если не появится багов (17.07.24)
    // Удалено в процессе задачи https://wimark.kaiten.ru/space/327612/card/35693179
    // if (updatedWlan.wmm.categories) {
    //   for (const category in updatedWlan.wmm.categories) {
    //     for (const config in updatedWlan.wmm.categories[category]) {
    //       if (!updatedWlan.wmm.categories[category][config]) {
    //         delete updatedWlan.wmm.categories[category][config];
    //       }
    //     }
    //   }
    // }
    if (!updatedWlan.vlan) {
      updatedWlan.vlan = 0;
    }
    updatedWlan.vlans = VLANHelpers.convertDynamicVlanStringToArrayFormat(ctx.dynamicVlansString);

    updatedWlan.whitelist = ctx.filterList.whitelist;
    updatedWlan.blacklist = ctx.filterList.blacklist;

    const updateWlanQuery = { action: 'C', items: { 0: updatedWlan }};
    Vue.axios.post(`${API_URL || ''}/api/wlans`, updateWlanQuery).then(
      (response) => {
        if (response.data.status === 'success') {
          wlansPollingService.hadleOperationResponse(response.data.data.items);
          // console.log(response.data.data)
          // далее  в конечном итоге используется для спинера поверх обновляемой WLAN WNE-2408
          this.pushWlansIdsToUpdatedWlanIdsInOperations(response.data.data.itemslist);
          ctx.hideSettings();
          ctx.refreshWlansList();
          ctx.refreshWlansTags();
        } else if (response.data.status === 'error') {
          VueNotifications.error({ message: response.data.description });
        }
        ctx.$store.commit('toggleMuteWlanEdit', false);
      },
      (err) => {
        // VueNotifications.error({ message: err });

        // show errors in the console instead of the alert window
        if (
          Object.prototype.hasOwnProperty.call(window, 'isShowAxiosErrorInConsole') &&
          typeof window.isShowAxiosErrorInConsole === 'function' &&
          window.isShowAxiosErrorInConsole(err)
        ) {
          window.showAxiosErrInConsole(err);
        } else {
          VueNotifications.error({ message: err });
        }

        ctx.$store.commit('toggleMuteWlanEdit', false);
        ctx.error = err;
      }
    );
  },

  deleteWlan(ctx) {
    ctx.$store.commit('toggleMuteWlanEdit', true);
    const deleteWlanQuery = { action: 'D', items: { [ctx.wlanIdToDelete]: {} } };
    Vue.axios.post(`${API_URL || ''}/api/wlans`, deleteWlanQuery).then(
      (response) => {
        if (response.data.status === 'success') {
          wlansPollingService.hadleOperationResponse(response.data.data.items);
          ctx.refreshWlansList();
          ctx.refreshWlansTags();
        } else if (response.data.status === 'error') {
          VueNotifications.error({ message: response.data.description });
        }
        ctx.deletingWlanModal = false;
        ctx.$store.state.editWlanIndex = '';
        ctx.$store.commit('toggleMuteWlanEdit', false);
      },
      (err) => {
        // VueNotifications.error({ message: err });

        // show errors in the console instead of the alert window
        if (
          Object.prototype.hasOwnProperty.call(window, 'isShowAxiosErrorInConsole') &&
          typeof window.isShowAxiosErrorInConsole === 'function' &&
          window.isShowAxiosErrorInConsole(err)
        ) {
          window.showAxiosErrInConsole(err);
        } else {
          VueNotifications.error({ message: err });
        }

        ctx.$store.commit('toggleMuteWlanEdit', false);
        ctx.error = err;
      }
    );
  },

  deleteWlanV2(ctx) {
    ctx.$store.commit('toggleMuteWlanEdit', true);
    const deleteWlanQuery = { action: 'D', items: { [ctx.wlanIdToDelete]: {} } };
    Vue.axios.post(`${API_URL || ''}/api/wlans`, deleteWlanQuery).then(
      (response) => {
        if (response.data.status === 'success') {
          wlansPollingService.hadleOperationResponse(response.data.data.items);
          ctx.refreshWlansList();
          ctx.refreshWlansTags();
        } else if (response.data.status === 'error') {
          VueNotifications.error({ message: response.data.description });
        }
        ctx.deletingWlanModal = false;
        ctx.$store.state.editWlanIndex = '';
        ctx.$store.commit('toggleMuteWlanEdit', false);
      },
      (err) => {
        // VueNotifications.error({ message: err });

        // show errors in the console instead of the alert window
        if (
          Object.prototype.hasOwnProperty.call(window, 'isShowAxiosErrorInConsole') &&
          typeof window.isShowAxiosErrorInConsole === 'function' &&
          window.isShowAxiosErrorInConsole(err)
        ) {
          window.showAxiosErrInConsole(err);
        } else {
          VueNotifications.error({ message: err });
        }

        ctx.$store.commit('toggleMuteWlanEdit', false);
        ctx.error = err;
      }
    );
  },

  deleteWlans(ctx) {
    ctx.$store.commit('toggleMuteWlanEdit', true);
    const deleteWlanQuery = { action: 'D', items: ctx.wlansForEdit };
    Vue.axios.post(`${API_URL || ''}/api/wlans`, deleteWlanQuery).then(
      (response) => {
        if (response.data.status === 'success') {
          ctx.wlansForEdit = [];
          wlansPollingService.hadleOperationResponse(response.data.data.items);
          ctx.refreshWlansList();
          ctx.refreshWlansTags();
        } else if (response.data.status === 'error') {
          VueNotifications.error({ message: response.data.description });
        }
        ctx.selectedWlans = '';
        ctx.$store.state.editWlanIndex = '';
        ctx.deletingWlansModal = false;
        ctx.cancelCommonEdit();
        ctx.$store.commit('toggleMuteWlanEdit', false);
      },
      (err) => {
        // VueNotifications.error({ message: err });

        // show errors in the console instead of the alert window
        if (
          Object.prototype.hasOwnProperty.call(window, 'isShowAxiosErrorInConsole') &&
          typeof window.isShowAxiosErrorInConsole === 'function' &&
          window.isShowAxiosErrorInConsole(err)
        ) {
          window.showAxiosErrInConsole(err);
        } else {
          VueNotifications.error({ message: err });
        }

        ctx.$store.commit('toggleMuteWlanEdit', false);
        ctx.error = err;
      }
    );
  },

  deleteWlansV2(ctx) {
    ctx.$store.commit('toggleMuteWlanEdit', true);
    const deleteWlanQuery = { action: 'D', items: ctx.wlansForEdit };
    Vue.axios.post(`${API_URL || ''}/api/wlans`, deleteWlanQuery).then(
      (response) => {
        if (response.data.status === 'success') {
          ctx.wlansForEdit = [];
          wlansPollingService.hadleOperationResponse(response.data.data.items);
          ctx.refreshWlansList();
          ctx.refreshWlansTags();
        } else if (response.data.status === 'error') {
          VueNotifications.error({ message: response.data.description });
        }
        ctx.selectedWlans = '';
        ctx.$store.state.editWlanIndex = '';
        ctx.deletingWlansModal = false;
        ctx.cancelCommonEdit();
        ctx.$store.commit('toggleMuteWlanEdit', false);
      },
      (err) => {
        // VueNotifications.error({ message: err });

        // show errors in the console instead of the alert window
        if (
          Object.prototype.hasOwnProperty.call(window, 'isShowAxiosErrorInConsole') &&
          typeof window.isShowAxiosErrorInConsole === 'function' &&
          window.isShowAxiosErrorInConsole(err)
        ) {
          window.showAxiosErrInConsole(err);
        } else {
          VueNotifications.error({ message: err });
        }

        ctx.$store.commit('toggleMuteWlanEdit', false);
        ctx.error = err;
      }
    );
  },

  updateWlans(ctx) {
    ctx.$store.commit('clearUpdatedWlanIdsInOperations'); // в конечном итоге используется для спинера поверх обновляемой WLAN WNE-2408
    ctx.$store.commit('toggleMuteWlanEdit', true);

    const clonedWlansList = JSON.parse(JSON.stringify(ctx.wlansList));
    const selectedWlans = clonedWlansList.filter((wlans, index) => ctx.selectedWlansId.includes(wlans.id));
    if (ctx.logSettingsData.radius_acct_interval === '') {
      ctx.logSettingsData.radius_acct_interval = 600;
    }
    selectedWlans.forEach((wlan) => {
      wlan.radius_acct_interval = parseFloat(ctx.logSettingsData.radius_acct_interval);
      if (!wlan.vlan) {
        wlan.vlan = 0;
      }
    });

    const updateWlanQuery = { action: 'U', items: helpers.transformArrayToObject(selectedWlans) };
    Vue.axios.post(`${API_URL || ''}/api/wlans`, updateWlanQuery).then(
      (response) => {
        if (response.data.status === 'success') {
          wlansPollingService.hadleOperationResponse(response.data.data.items);
          // далее  в конечном итоге используется для спинера поверх обновляемой WLAN WNE-2408
          this.pushWlansIdsToUpdatedWlanIdsInOperations(response.data.data.itemslist);
          ctx.refreshWlansList();
          ctx.refreshWlansTags();
        } else if (response.data.status === 'error') {
          VueNotifications.error({ message: response.data.description });
        }
        ctx.selectedWlans = [];
        ctx.selectedWlansId = [];
        ctx.allChecked = false;
        const checkAll = document.getElementById('checkAll');
        if (checkAll) {
          checkAll.checked = false;
        }
        ctx.disableEditMode();
        ctx.$store.commit('toggleMuteWlanEdit', false);
      },
      (err) => {
        // VueNotifications.error({ message: err });

        // show errors in the console instead of the alert window
        if (
          Object.prototype.hasOwnProperty.call(window, 'isShowAxiosErrorInConsole') &&
          typeof window.isShowAxiosErrorInConsole === 'function' &&
          window.isShowAxiosErrorInConsole(err)
        ) {
          window.showAxiosErrInConsole(err);
        } else {
          VueNotifications.error({ message: err });
        }

        ctx.$store.commit('toggleMuteWlanEdit', false);
        ctx.error = err;
      }
    );
    ctx.logSettingsData.radius_acct_interval = '';
  },

  updateWlansV2(ctx) {
    ctx.$store.commit('clearUpdatedWlanIdsInOperations'); // в конечном итоге используется для спинера поверх обновляемой WLAN WNE-2408
    ctx.$store.commit('toggleMuteWlanEdit', true);

    const clonedWlansList = JSON.parse(JSON.stringify(ctx.wlansList));
    const selectedWlans = clonedWlansList.filter((wlans, index) => ctx.selectedWlansId.includes(wlans.id));
    if (ctx.logSettingsData.radius_acct_interval === '') {
      ctx.logSettingsData.radius_acct_interval = 600;
    }
    selectedWlans.forEach((wlan) => {
      wlan.radius_acct_interval = parseFloat(ctx.logSettingsData.radius_acct_interval);
      if (!wlan.vlan) {
        wlan.vlan = 0;
      }
    });

    const updateWlanQuery = { action: 'U', items: helpers.transformArrayToObject(selectedWlans) };
    Vue.axios.post(`${API_URL || ''}/api/wlans`, updateWlanQuery).then(
      (response) => {
        if (response.data.status === 'success') {
          wlansPollingService.hadleOperationResponse(response.data.data.items);
          // далее  в конечном итоге используется для спинера поверх обновляемой WLAN WNE-2408
          this.pushWlansIdsToUpdatedWlanIdsInOperations(response.data.data.itemslist);
          ctx.refreshWlansList();
          ctx.refreshWlansTags();
        } else if (response.data.status === 'error') {
          VueNotifications.error({ message: response.data.description });
        }
        ctx.selectedWlans = [];
        ctx.selectedWlansId = [];
        ctx.allChecked = false;
        const checkAll = document.getElementById('checkAll');
        if (checkAll) {
          checkAll.checked = false;
        }
        ctx.disableEditMode();
        ctx.$store.commit('toggleMuteWlanEdit', false);
      },
      (err) => {
        // VueNotifications.error({ message: err });

        // show errors in the console instead of the alert window
        if (
          Object.prototype.hasOwnProperty.call(window, 'isShowAxiosErrorInConsole') &&
          typeof window.isShowAxiosErrorInConsole === 'function' &&
          window.isShowAxiosErrorInConsole(err)
        ) {
          window.showAxiosErrInConsole(err);
        } else {
          VueNotifications.error({ message: err });
        }

        ctx.$store.commit('toggleMuteWlanEdit', false);
        ctx.error = err;
      }
    );
    ctx.logSettingsData.radius_acct_interval = '';
  },

  assignTags(ctx) {
    ctx.$store.commit('clearUpdatedWlanIdsInOperations'); // в конечном итоге используется для спинера поверх обновляемой WLAN WNE-2408
    ctx.$store.commit('toggleMuteWlanEdit', true);

    const clonedWlansList = JSON.parse(JSON.stringify(ctx.wlansList));
    const selectedWlans = clonedWlansList.filter((wlans, index) => ctx.selectedWlansId.includes(wlans.id));

    selectedWlans.forEach((item) => {
      const newTagsArray = item.tags.concat(ctx.newTags);
      if (!item.vlan) {
        item.vlan = 0;
      }
      item.tags = newTagsArray;
    });

    const updateWlanQuery = { action: 'U', items: helpers.transformArrayToObject(selectedWlans) };
    Vue.axios.post(`${API_URL || ''}/api/wlans`, updateWlanQuery).then(
      (response) => {
        if (response.data.status === 'success') {
          wlansPollingService.hadleOperationResponse(response.data.data.items);
          // далее  в конечном итоге используется для спинера поверх обновляемой WLAN WNE-2408
          this.pushWlansIdsToUpdatedWlanIdsInOperations(response.data.data.itemslist);
          ctx.refreshWlansList();
          ctx.refreshWlansTags();
        } else if (response.data.status === 'error') {
          VueNotifications.error({ message: response.data.description });
        }
        ctx.selectedWlans = [];
        ctx.selectedWlansId = [];
        ctx.allChecked = false;
        const checkAll = document.getElementById('checkAll');
        if (checkAll) {
          checkAll.checked = false;
        }
        ctx.disableEditMode();
        ctx.newTags = [];
        ctx.$store.commit('toggleMuteWlanEdit', false);
      },
      (err) => {
        // VueNotifications.error({ message: err });

        // show errors in the console instead of the alert window
        if (
          Object.prototype.hasOwnProperty.call(window, 'isShowAxiosErrorInConsole') &&
          typeof window.isShowAxiosErrorInConsole === 'function' &&
          window.isShowAxiosErrorInConsole(err)
        ) {
          window.showAxiosErrInConsole(err);
        } else {
          VueNotifications.error({ message: err });
        }

        ctx.$store.commit('toggleMuteWlanEdit', false);
        ctx.error = err;
      }
    );
    ctx.logSettingsData.radius_acct_interval = '';
  },

  deleteCommonTags(ctx) {
    ctx.$store.commit('toggleMuteWlanEdit', true);

    const clonedWlansList = JSON.parse(JSON.stringify(ctx.wlansList));
    const selectedWlans = clonedWlansList.filter((wlans, index) => ctx.selectedWlansId.includes(wlans.id));

    selectedWlans.forEach((item) => {
      item.tags = [];
      if (!item.vlan) {
        item.vlan = 0;
      }
    });

    const updateWlanQuery = { action: 'U', items: helpers.transformArrayToObject(selectedWlans) };
    Vue.axios.post(`${API_URL || ''}/api/wlans`, updateWlanQuery).then(
      (response) => {
        if (response.data.status === 'success') {
          wlansPollingService.hadleOperationResponse(response.data.data.items);
          ctx.refreshWlansList();
          ctx.refreshWlansTags();
        } else if (response.data.status === 'error') {
          VueNotifications.error({ message: response.data.description });
        }
        ctx.selectedWlans = [];
        ctx.selectedWlansId = [];
        ctx.allChecked = false;
        const checkAll = document.getElementById('checkAll');
        if (checkAll) {
          checkAll.checked = false;
        }
        ctx.disableEditMode();
        ctx.newTags = [];
        ctx.$store.commit('toggleMuteWlanEdit', false);
      },
      (err) => {
        // VueNotifications.error({ message: err });

        // show errors in the console instead of the alert window
        if (
          Object.prototype.hasOwnProperty.call(window, 'isShowAxiosErrorInConsole') &&
          typeof window.isShowAxiosErrorInConsole === 'function' &&
          window.isShowAxiosErrorInConsole(err)
        ) {
          window.showAxiosErrInConsole(err);
        } else {
          VueNotifications.error({ message: err });
        }

        ctx.$store.commit('toggleMuteWlanEdit', false);
        ctx.error = err;
      }
    );
    ctx.logSettingsData.radius_acct_interval = '';
  },
  changeWlansLocations(ctx, wlansIds, locationId) {
    if (!wlansIds || !wlansIds.length) {
      return;
    }
    const changeLocationsQuery = { action: 'U', ids: wlansIds, items: { 0: { base_location: locationId } } };
    ctx.$store.commit('toggleMuteChangeLocation');

    Vue.axios.post(`${API_URL || ''}/api/bulk/locations/wlans`, changeLocationsQuery).then(
      (response) => {
        if (response.data.status === 'success') {
          ctx.isChangeLocationOpen = false;
          ctx.cancelCommonEdit();
          ctx.refreshWlansList();
          // VueNotifications.success({ message: 'Location is successfully changed' });
          ctx.$store.commit('toggleMuteChangeLocation');
          VueNotifications.success({ message: ctx.$t('aps.notificationLocationSuccessfullyChanged') });
        } else if (response.data.status === 'error') {
          VueNotifications.error({ message: response.data.description });
          ctx.$store.commit('toggleMuteChangeLocation');
        }
      },
      (err) => {
        // VueNotifications.error({ message: err });

        // show errors in the console instead of the alert window
        if (
          Object.prototype.hasOwnProperty.call(window, 'isShowAxiosErrorInConsole') &&
          typeof window.isShowAxiosErrorInConsole === 'function' &&
          window.isShowAxiosErrorInConsole(err)
        ) {
          window.showAxiosErrInConsole(err);
        } else {
          VueNotifications.error({ message: err });
        }

        ctx.error = err;
        ctx.$store.commit('toggleMuteChangeLocation');
      }
    );
  },
  getWlansWhenSelectCpeInAps(ctx, wlansIds) {
    // используем чтобы вытянуть объекты wlan сетей для на выбранной точке и запрашиваем данные этих сетей по их idшникам
    // чтобы дальше вытащить оттуда данные по типу туннеля
    Vue.axios
      .post(`${API_URL || ''}/api/wlans`, { action: 'R', ids: wlansIds, with: ['status', 'clients'] })
      .then(
        (response) => {
          if (response.data.status === 'success') {
            // ctx.wlansList = response.data.data.itemslist;
            // ctx.$store.commit('setWlansList', response.data.data.itemslist);
            // console.log(response.data.data.itemslist)
            ctx.wlansFullObjectsForSeletedCpe = helpers.transformArrayToObject(response.data.data.itemslist);
          } else if (response.data.status === 'error') {
            // VueNotifications.error({ message: response.data.description });
          }
        },
        (err) => {
          console.log(err);
          // VueNotifications.error({ message: err });
          // ctx.error = err;
        }
      );
  },
  pushWlansIdsToUpdatedWlanIdsInOperations(wlansItems) {
    // конечном итоге используется для спинера поверх обновляемой WLAN WNE-2408
    try {
      for (const item of wlansItems) {
        store.commit('pushUpdatedWlanIdsInOperations', item.model_id);
      }
    } catch (e) {
      console.log(e);
    }
  },
  getWlansCompactForFilter(ctx) {
    ctx.$store.commit('setGetWlansCompactForFilterRequestBegin');
    Vue.axios.post(`${API_URL || ''}/api/wlans/compact`, { action: 'R', location: ctx.$store.getters.getDefaultLocationForRequests(ctx), with_childs: ctx.$store.getters.getDefaultWithChildsForRequests(ctx), }).then(
      (response) => {
        if (response.data.status === 'success') {
          const itemsList = response.data.data.itemslist;
          ctx.wlansCompactListForFilter = itemsList;
        } else if (response.data.status === 'error') {
          VueNotifications.error({ message: response.data.description });
        }
        ctx.$store.commit('setGetWlansCompactForFilterRequestEnd');
      },
      (err) => {
        // VueNotifications.error({ message: err });
        ctx.$store.commit('setGetWlansCompactForFilterRequestEnd');
        // show errors in the console instead of the alert window
        if (
          Object.prototype.hasOwnProperty.call(window, 'isShowAxiosErrorInConsole') &&
          typeof window.isShowAxiosErrorInConsole === 'function' &&
          window.isShowAxiosErrorInConsole(err)
        ) {
          window.showAxiosErrInConsole(err);
        } else {
          VueNotifications.error({ message: err });
        }

        ctx.error = err;
      }
    );
  },

  getAllCompactWlansWithDataRemaining(ctx, locationId = undefined, with_childs = undefined) {
    ctx.$store.commit('setGetAllCompactWlansWithDataRemainingRequestBegin');
    ctx.$store.commit('loadingWlansRequestBegin');
    const paramsForRequest = {
      action: 'R',
      location: ctx.$store.getters.getDefaultLocationForRequests(ctx),
      with_childs: ctx.$store.getters.getDefaultWithChildsForRequests(ctx),
      with: ['status', 'clients'],
      sort_by: {
        field: 'ssid',
        order: 1
      }
    }
    if (locationId !== undefined) {
      paramsForRequest.location = locationId;
    }
    if (with_childs !== undefined) {
      paramsForRequest.with_childs = with_childs;
    }

    Vue.axios
      .post(`${API_URL || ''}/api/wlans/compact`, paramsForRequest)
      .then(
        (response) => {
          if (response.data.status === 'success') {
            let loaded;
            let total;
            const dataFromResponse = [];
            if (response.data.data.itemslist[0]) {
              // console.log(response);
              loaded = response.data.data.count;
              total = response.data.data.total;
              for (const item of response.data.data.itemslist) {
                dataFromResponse.push(item);
              }
            }
            if (loaded && total) {
              // console.log('loaded', loaded);
              // console.log('total', total);
              if (loaded < total) {
                const offset = loaded;
                const limit = total - loaded;
                if (limit > 0 && offset > 0) {
                  // console.log('limit', limit);
                  // console.log('offset', offset);
                  this.getAllCompactWlansRemainingData(ctx, limit, offset, dataFromResponse, paramsForRequest);
                } else {
                  ctx.$store.commit('setWlansList', dataFromResponse);
                  ctx.$store.commit('setWlansItems', helpers.transformArrayToObject(dataFromResponse));
                  ctx.$store.commit('setGetAllCompactWlansWithDataRemainingRequestEnd');
                  ctx.$store.commit('loadingWlansRequestEnd');
                  // this.clientRFData = dataFromResponse;
                }
              } else {
                ctx.$store.commit('setWlansList', dataFromResponse);
                ctx.$store.commit('setWlansItems', helpers.transformArrayToObject(dataFromResponse));
                ctx.$store.commit('setGetAllCompactWlansWithDataRemainingRequestEnd');
                ctx.$store.commit('loadingWlansRequestEnd');
                // this.clientRFData = dataFromResponse;
              }
            } else {
              ctx.$store.commit('setWlansList', dataFromResponse);
              ctx.$store.commit('setWlansItems', helpers.transformArrayToObject(dataFromResponse));
              ctx.$store.commit('setGetAllCompactWlansWithDataRemainingRequestEnd');
              ctx.$store.commit('loadingWlansRequestEnd');
              // this.clientRFData = dataFromResponse;
            }
          } else if (response.data.status === 'error') {
            ctx.$store.commit('setGetAllCompactWlansWithDataRemainingRequestEnd');
            ctx.$store.commit('loadingWlansRequestEnd');
            VueNotifications.error({message: response.data.description});
          }
        },
        (err) => {
          VueNotifications.error({message: err});
          ctx.$store.commit('setGetAllCompactWlansWithDataRemainingRequestEnd');
          ctx.$store.commit('loadingWlansRequestEnd');
          ctx.error = err;
        }
      );
  },

  getAllCompactWlansRemainingData(ctx, limit, offset, dataFromFirstReq, paramsForRequestFromFirstReq) {
    if (!limit || !offset) {
      ctx.$store.commit('setWlansList', dataFromFirstReq);
      ctx.$store.commit('setWlansItems', helpers.transformArrayToObject(dataFromFirstReq));
      ctx.$store.commit('loadingWlansRequestEnd');
      ctx.$store.commit('setGetAllCompactWlansWithDataRemainingRequestEnd');
      return;
    }
    const praramsForRequest = JSON.parse(JSON.stringify(paramsForRequestFromFirstReq));
    praramsForRequest.page = {limit, offset};


    Vue.axios.post(`${API_URL || ''}/api/wlans/compact`, praramsForRequest).then(
      (response) => {
        if (response.data.status === 'success') {
          if (response.data.data.itemslist[0]) {
            for (const item of response.data.data.itemslist) {
              dataFromFirstReq.push(item);
            }
          }
          ctx.$store.commit('setWlansList', dataFromFirstReq);
          ctx.$store.commit('setWlansItems', helpers.transformArrayToObject(dataFromFirstReq));

        } else if (response.data.status === 'error') {
          ctx.$store.commit('setWlansList', dataFromFirstReq);
          ctx.$store.commit('setWlansItems', helpers.transformArrayToObject(dataFromFirstReq));

          VueNotifications.error({message: response.data.description});
        }
        ctx.$store.commit('loadingWlansRequestEnd');
        ctx.$store.commit('setGetAllCompactWlansWithDataRemainingRequestEnd');
      },
      (err) => {
        ctx.$store.commit('loadingWlansRequestEnd');
        ctx.$store.commit('setGetAllCompactWlansWithDataRemainingRequestEnd');
        ctx.$store.commit('setWlansList', dataFromFirstReq);
        ctx.$store.commit('setWlansItems', helpers.transformArrayToObject(dataFromFirstReq));
        VueNotifications.error({message: err});
      }
    );
  },

  getAllCompactWlansWithDataRemainingWithoutClients(ctx, locationId = undefined, with_childs = undefined) {
    ctx.$store.commit('setGetAllCompactWlansWithDataRemainingRequestBegin');
    ctx.$store.commit('loadingWlansRequestBegin');
    const paramsForRequest = {
      action: 'R',
      location: ctx.$store.getters.getDefaultLocationForRequests(ctx),
      with_childs: ctx.$store.getters.getDefaultWithChildsForRequests(ctx),
      sort_by: {
        field: 'ssid',
        order: 1
      }
    }
    if (locationId !== undefined) {
      paramsForRequest.location = locationId;
    }
    if (with_childs !== undefined) {
      paramsForRequest.with_childs = with_childs;
    }

    Vue.axios
      .post(`${API_URL || ''}/api/wlans/compact`, paramsForRequest)
      .then(
        (response) => {
          if (response.data.status === 'success') {
            let loaded;
            let total;
            const dataFromResponse = [];
            if (response.data.data.itemslist[0]) {
              // console.log(response);
              loaded = response.data.data.count;
              total = response.data.data.total;
              for (const item of response.data.data.itemslist) {
                dataFromResponse.push(item);
              }
            }
            if (loaded && total) {
              // console.log('loaded', loaded);
              // console.log('total', total);
              if (loaded < total) {
                const offset = loaded;
                const limit = total - loaded;
                if (limit > 0 && offset > 0) {
                  // console.log('limit', limit);
                  // console.log('offset', offset);
                  this.getAllCompactWlansRemainingDataWithoutClients(ctx, limit, offset, dataFromResponse, paramsForRequest);
                } else {
                  ctx.$store.commit('setWlansList', dataFromResponse);
                  ctx.$store.commit('setWlansItems', helpers.transformArrayToObject(dataFromResponse));
                  ctx.$store.commit('setGetAllCompactWlansWithDataRemainingRequestEnd');
                  ctx.$store.commit('loadingWlansRequestEnd');
                  // this.clientRFData = dataFromResponse;
                }
              } else {
                ctx.$store.commit('setWlansList', dataFromResponse);
                ctx.$store.commit('setWlansItems', helpers.transformArrayToObject(dataFromResponse));
                ctx.$store.commit('setGetAllCompactWlansWithDataRemainingRequestEnd');
                ctx.$store.commit('loadingWlansRequestEnd');
                // this.clientRFData = dataFromResponse;
              }
            } else {
              ctx.$store.commit('setWlansList', dataFromResponse);
              ctx.$store.commit('setWlansItems', helpers.transformArrayToObject(dataFromResponse));
              ctx.$store.commit('setGetAllCompactWlansWithDataRemainingRequestEnd');
              ctx.$store.commit('loadingWlansRequestEnd');
              // this.clientRFData = dataFromResponse;
            }
          } else if (response.data.status === 'error') {
            ctx.$store.commit('setGetAllCompactWlansWithDataRemainingRequestEnd');
            ctx.$store.commit('loadingWlansRequestEnd');
            VueNotifications.error({message: response.data.description});
          }
        },
        (err) => {
          VueNotifications.error({message: err});
          ctx.$store.commit('setGetAllCompactWlansWithDataRemainingRequestEnd');
          ctx.$store.commit('loadingWlansRequestEnd');
          ctx.error = err;
        }
      );
  },

  getAllCompactWlansFromaAllLocationsWithDataRemainingWithoutClients(ctx) {
    ctx.$store.commit('setGetAllCompactWlansWithDataRemainingRequestBegin');
    ctx.$store.commit('loadingWlansRequestBegin');
    const paramsForRequest = {
      action: 'R',
      with_childs: true,
      sort_by: {
        field: 'ssid',
        order: 1
      }
    }

    Vue.axios
      .post(`${API_URL || ''}/api/wlans/compact`, paramsForRequest)
      .then(
        (response) => {
          if (response.data.status === 'success') {
            let loaded;
            let total;
            const dataFromResponse = [];
            if (response.data.data.itemslist[0]) {
              // console.log(response);
              loaded = response.data.data.count;
              total = response.data.data.total;
              for (const item of response.data.data.itemslist) {
                dataFromResponse.push(item);
              }
            }
            if (loaded && total) {
              // console.log('loaded', loaded);
              // console.log('total', total);
              if (loaded < total) {
                const offset = loaded;
                const limit = total - loaded;
                if (limit > 0 && offset > 0) {
                  // console.log('limit', limit);
                  // console.log('offset', offset);
                  this.getAllCompactWlansRemainingDataWithoutClients(ctx, limit, offset, dataFromResponse, paramsForRequest);
                } else {
                  ctx.$store.commit('setWlansList', dataFromResponse);
                  ctx.$store.commit('setWlansItems', helpers.transformArrayToObject(dataFromResponse));
                  ctx.$store.commit('setGetAllCompactWlansWithDataRemainingRequestEnd');
                  ctx.$store.commit('loadingWlansRequestEnd');
                  // this.clientRFData = dataFromResponse;
                }
              } else {
                ctx.$store.commit('setWlansList', dataFromResponse);
                ctx.$store.commit('setWlansItems', helpers.transformArrayToObject(dataFromResponse));
                ctx.$store.commit('setGetAllCompactWlansWithDataRemainingRequestEnd');
                ctx.$store.commit('loadingWlansRequestEnd');
                // this.clientRFData = dataFromResponse;
              }
            } else {
              ctx.$store.commit('setWlansList', dataFromResponse);
              ctx.$store.commit('setWlansItems', helpers.transformArrayToObject(dataFromResponse));
              ctx.$store.commit('setGetAllCompactWlansWithDataRemainingRequestEnd');
              ctx.$store.commit('loadingWlansRequestEnd');
              // this.clientRFData = dataFromResponse;
            }
          } else if (response.data.status === 'error') {
            ctx.$store.commit('setGetAllCompactWlansWithDataRemainingRequestEnd');
            ctx.$store.commit('loadingWlansRequestEnd');
            VueNotifications.error({message: response.data.description});
          }
        },
        (err) => {
          VueNotifications.error({message: err});
          ctx.$store.commit('setGetAllCompactWlansWithDataRemainingRequestEnd');
          ctx.$store.commit('loadingWlansRequestEnd');
          ctx.error = err;
        }
      );
  },

  getAllCompactWlansRemainingDataWithoutClients(ctx, limit, offset, dataFromFirstReq, paramsForRequestFromFirstReq) {
    if (!limit || !offset) {
      ctx.$store.commit('setWlansList', dataFromFirstReq);
      ctx.$store.commit('setWlansItems', helpers.transformArrayToObject(dataFromFirstReq));
      ctx.$store.commit('loadingWlansRequestEnd');
      ctx.$store.commit('setGetAllCompactWlansWithDataRemainingRequestEnd');
      return;
    }
    const praramsForRequest = JSON.parse(JSON.stringify(paramsForRequestFromFirstReq));
    praramsForRequest.page = {limit, offset};


    Vue.axios.post(`${API_URL || ''}/api/wlans/compact`, praramsForRequest).then(
      (response) => {
        if (response.data.status === 'success') {
          if (response.data.data.itemslist[0]) {
            for (const item of response.data.data.itemslist) {
              dataFromFirstReq.push(item);
            }
          }
          ctx.$store.commit('setWlansList', dataFromFirstReq);
          ctx.$store.commit('setWlansItems', helpers.transformArrayToObject(dataFromFirstReq));

        } else if (response.data.status === 'error') {
          ctx.$store.commit('setWlansList', dataFromFirstReq);
          ctx.$store.commit('setWlansItems', helpers.transformArrayToObject(dataFromFirstReq));

          VueNotifications.error({message: response.data.description});
        }
        ctx.$store.commit('loadingWlansRequestEnd');
        ctx.$store.commit('setGetAllCompactWlansWithDataRemainingRequestEnd');
      },
      (err) => {
        ctx.$store.commit('loadingWlansRequestEnd');
        ctx.$store.commit('setGetAllCompactWlansWithDataRemainingRequestEnd');
        ctx.$store.commit('setWlansList', dataFromFirstReq);
        ctx.$store.commit('setWlansItems', helpers.transformArrayToObject(dataFromFirstReq));
        VueNotifications.error({message: err});
      }
    );
  },

  async asyncGetAllCompactWlansWithDataRemaining(ctx) {
    try {
      return await this.asyncGetCompactWlansWithDataRemaining(ctx, undefined);
    } catch (e) {
      console.log(e)
      return [];
    }
  },
  async asyncGetWlansWithDataRemainingByIds(ctx, wlansIds) {
    try {
      return await this.asyncGetCompactWlansWithDataRemaining(ctx, wlansIds);
    } catch (e) {
      console.log(e)
      return [];
    }
  },


  async asyncGetCompactWlansWithDataRemaining(ctx, wlansIds = undefined) {
    try {
      const paramsForRequest = {
        action: 'R',
        location: ctx.$store.getters.getDefaultLocationForRequests(ctx), with_childs: ctx.$store.getters.getDefaultWithChildsForRequests(ctx),
        with: ['status', 'clients'],
        sort_by: {
          field: 'ssid',
          order: 1
        }
      }
      if (wlansIds) {
        paramsForRequest.ids = wlansIds
      }
      const response = await Vue.axios.post(`${API_URL || ''}/api/wlans/compact`, paramsForRequest);
      if (response.data.status === 'success') {
        let loaded;
        let total;
        const dataFromResponse = [];
        if (response.data.data.itemslist[0]) {
          // console.log(response);
          loaded = response.data.data.count;
          total = response.data.data.total;
          for (const item of response.data.data.itemslist) {
            dataFromResponse.push(item);
          }
        }
        if (loaded && total) {
          if (loaded < total) {
            const offset = loaded;
            const limit = total - loaded;
            if (limit > 0 && offset > 0) {

              // if (!limit || !offset) {
              //   return [];
              // }

              const praramsForSecondRequest = JSON.parse(JSON.stringify(paramsForRequest));
              praramsForSecondRequest.page = { limit, offset };
              const response2 = await Vue.axios.post(`${API_URL || ''}/api/wlans/compact`, praramsForSecondRequest);
              if (response2.data.status === 'success') {
                if (response2.data.data.itemslist[0]) {
                  for (const item of response2.data.data.itemslist) {
                    dataFromResponse.push(item);
                  }
                }
                return dataFromResponse

              } else if (response.data.status === 'error') {

                VueNotifications.error({message: response.data.description});
                return dataFromResponse
              }

            } else {
              return dataFromResponse
            }
          } else {
            return dataFromResponse

            // this.clientRFData = dataFromResponse;
          }
        } else {
          return dataFromResponse

          // this.clientRFData = dataFromResponse;
        }
      } else if (response.data.status === 'error') {
        VueNotifications.error({message: response.data.description});
        return []
      }


    } catch (e) {
      console.log(e)
      VueNotifications.error({message: e});
      return [];
    }

  },

  async asyncGetAllCompactWlansWithDataRemainingWithoutClients(ctx) {
    try {
      return await this.asyncGetCompactWlansWithDataRemainingWithoutClients(ctx, undefined);
    } catch (e) {
      console.log(e)
      return [];
    }
  },
  async asyncGetWlansWithDataRemainingByIdsWithoutClients(ctx, wlansIds) {
    try {
      return await this.asyncGetCompactWlansWithDataRemainingWithoutClients(ctx, wlansIds);
    } catch (e) {
      console.log(e)
      return [];
    }
  },


  async asyncGetCompactWlansWithDataRemainingWithoutClients(ctx, wlansIds = undefined) {
    try {
      const paramsForRequest = {
        action: 'R',
        location: ctx.$store.getters.getDefaultLocationForRequests(ctx),
        with_childs: ctx.$store.getters.getDefaultWithChildsForRequests(ctx),
        sort_by: {
          field: 'ssid',
          order: 1
        }
      }
      if (wlansIds) {
        paramsForRequest.ids = wlansIds
        paramsForRequest.location = undefined
        paramsForRequest.with_childs = true;
      }
      const response = await Vue.axios.post(`${API_URL || ''}/api/wlans/compact`, paramsForRequest);
      if (response.data.status === 'success') {
        let loaded;
        let total;
        const dataFromResponse = [];
        if (response.data.data.itemslist[0]) {
          // console.log(response);
          loaded = response.data.data.count;
          total = response.data.data.total;
          for (const item of response.data.data.itemslist) {
            dataFromResponse.push(item);
          }
        }
        if (loaded && total) {
          if (loaded < total) {
            const offset = loaded;
            const limit = total - loaded;
            if (limit > 0 && offset > 0) {

              // if (!limit || !offset) {
              //   return [];
              // }

              const praramsForSecondRequest = JSON.parse(JSON.stringify(paramsForRequest));
              praramsForSecondRequest.page = {limit, offset};
              const response2 = await Vue.axios.post(`${API_URL || ''}/api/wlans/compact`, praramsForSecondRequest);
              if (response2.data.status === 'success') {
                if (response2.data.data.itemslist[0]) {
                  for (const item of response2.data.data.itemslist) {
                    dataFromResponse.push(item);
                  }
                }
                return dataFromResponse

              } else if (response.data.status === 'error') {

                VueNotifications.error({message: response.data.description});
                return dataFromResponse
              }

            } else {
              return dataFromResponse
            }
          } else {
            return dataFromResponse

            // this.clientRFData = dataFromResponse;
          }
        } else {
          return dataFromResponse

          // this.clientRFData = dataFromResponse;
        }
      } else if (response.data.status === 'error') {
        VueNotifications.error({message: response.data.description});
        return []
      }


    } catch (e) {
      console.log(e)
      VueNotifications.error({message: e});
      return [];
    }

  },


  async requestCompactWPAPersonalWlansWithSearch(searchQuery, locationId= undefined, with_childs = undefined, limit = undefined) {
    const config = {
      action: 'R',
      location: store.getters.getDefaultLocationForRequests(),
      with_childs: store.getters.getDefaultWithChildsForRequests(),
      with: ['status', 'clients'],
      search: searchQuery,
      sort_by: {
        field: 'ssid',
        order: 1
      },
      page: { limit: 1000 },
      q: {'security.type': {$in: ['wpa2personal', 'wpapersonal', 'wpa3personal', 'wpa23personal']}}, // запрашиваем WPA, WPA2, WPA3, WPA2/3  Personal сети
    };
    if (searchQuery === '') {
      config.page = {
        limit: 10
      };
    }
    if (limit) {
      config.page.limit = limit;
    }
    if (locationId !== undefined) {
      config.location = locationId;
    }
    if (with_childs !== undefined) {
      config.with_childs = with_childs;
    }
    const json = await Vue.axios.post(`${API_URL || ''}/api/wlans/compact`, config);
    if (json.data.status === 'success') {
      return json.data.data;
    }
    return [];
  },
  async requestCompactWPAPersonalWlansWithoutClientsAndWithSearch(searchQuery, locationId= undefined, with_childs = undefined, limit = undefined) {
    const config = {
      action: 'R',
      location: store.getters.getDefaultLocationForRequests(),
      with_childs: store.getters.getDefaultWithChildsForRequests(),
      search: searchQuery,
      sort_by: {
        field: 'ssid',
        order: 1
      },
      page: { limit: 1000 },
      q: {'security.type': {$in: ['wpa2personal', 'wpapersonal', 'wpa3personal', 'wpa23personal']}}, // запрашиваем WPA, WPA2, WPA3, WPA2/3  Personal сети
    };
    if (searchQuery === '') {
      config.page = {
        limit: 10
      };
    }
    if (limit) {
      config.page.limit = limit;
    }
    if (locationId !== undefined) {
      config.location = locationId;
    }
    if (with_childs !== undefined) {
      config.with_childs = with_childs;
    }
    const json = await Vue.axios.post(`${API_URL || ''}/api/wlans/compact`, config);
    if (json.data.status === 'success') {
      return json.data.data;
    }
    return [];
  },
  async requestAllCompactWlansWithoutClientsAndWithSearch(searchQuery, locationId= undefined, with_childs = undefined, limit = undefined) {
    const config = {
      action: 'R',
      location: store.getters.getDefaultLocationForRequests(),
      with_childs: store.getters.getDefaultWithChildsForRequests(),
      search: searchQuery,
      sort_by: {
        field: 'ssid',
        order: 1
      },
      page: { limit: 1000 },
    };
    if (searchQuery === '') {
      config.page = {
        limit: 10
      };
    }
    if (limit) {
      config.page.limit = limit;
    }
    if (locationId !== undefined) {
      config.location = locationId;
    }
    if (with_childs !== undefined) {
      config.with_childs = with_childs;
    }
    const json = await Vue.axios.post(`${API_URL || ''}/api/wlans/compact`, config);
    if (json.data.status === 'success') {
      return json.data.data;
    }
    return [];
  },



};
