<template>
  <WTable
    style="height: calc(60vh)"
    max-height="calc(60vh - 130px)"
    :columns="columns"
    :rows="rows"
    :isDisabled="isDisabled"
    :bordered="false"
    :withToggleColumnVisibilityMode="false"
    :row-style-class="rowStyleClassFn"
    :sort-options="{
      enabled: !isOneColMode
    }"
    :select-options="{
      enabled: isSelectionRowsModeEnabled,
      selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
      disableSelectInfo: true, // disable the select info panel on top
      selectAllByGroup: false, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
    }"
    :pagination-options="{
      enabled: !isOneColMode,
      remoteMode: true,
      mode: 'records', // pages, records
      position: 'bottom',
      perPage: perPage,
      perPageDropdownEnabled: true,
      perPageDropdown: [5, 10, 20, 40, 50],
      dropdownAllowAll: false,
      jumpFirstOrLast : true,
      firstLabel: $t('general.pagination.firstPage'),
      lastLabel: $t('general.pagination.lastPage'),
      nextLabel: $t('general.pagination.nextPage'),
      prevLabel: $t('general.pagination.prevPage'),
      rowsPerPageLabel: $t('general.pagination.rowsPerPage'),
      pageLabel: $t('general.pagination.page'),
      ofLabel: $t('general.pagination.of'),
      allLabel: $t('general.pagination.all')
    }"
    :isLoading.sync="isDataLoading"
    @update:columns="handleUpdateColumns"
    @on-selected-rows-change="handleSelectedRowsChange"
    @on-select-all="handleSelectedAllRowsChange"
    @on-cell-click="handleCellClick"
    @on-page-change="handlePageChange"
    @on-per-page-change="handlePerPageChange"
    @on-sort-change="onSortChange"
  >

    <template v-slot:table-menu>
      <slot name="table-menu"></slot>
    </template>

    <template v-slot:table-row="props">
      <template>
        {{ props.formattedRow[props.column.field] ? props.formattedRow[props.column.field] : '-' }}
      </template>
    </template>

    <template v-slot:table-actions-bottom>
      <slot name="table-actions-bottom"></slot>
    </template>

  </WTable>
</template>

<script>
/**
 * компонент - таблица Branch Profile VLANs.
 * ! компонент локальный: используется только в VLANTabContent.vue
 * подробнее по настройкам и пропсам - см компонент WTable.vue
 * @component
*/

import {
  generateTableColumns,
  generateTableRows
} from './config';

export default {
  name: 'VLANTable',
  components: {
  },
  props: {
    items: {
      type: Array,
      required: true
    },
    activeRowVLANId: {
      type: [String, Number],
      default: ''
    },
    selectedVLANIds: {
      type: Array,
      required: true
    },
    isDataLoading: {
      type: Boolean,
      default: false
    },
    isDisabled: {
      type: Boolean,
      default: false
    },
    isOneColMode: {
      type: Boolean,
      default: false
    },
    isSelectionRowsModeEnabled: {
      type: Boolean,
      default: true
    },
    perPage: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      isTableColumnHidden: {
        vlanId: false,
        vlanName: false,
        aclIngress: false,
        aclEgress: false
      }
    };
  },
  watch: {
  },
  computed: {
    columns: {
      get() {
        return generateTableColumns({
          i18nInstance: this.$i18n,
          hiddenColumnRules: {
            vlanId: this.isTableColumnHidden.vlanId,
            vlanName: this.isTableColumnHidden.vlanName,
            aclIngress: this.isTableColumnHidden.aclIngress,
            aclEgress: this.isTableColumnHidden.aclEgress
          },
          additionalRules: {}
        });
      },
      set(updatedColumns) {
        // обновить таблицу, когда обновились данные в колонках
        // например, какую-то колонку скрыли/раскрыли
        updatedColumns.forEach(el => {
          this.isTableColumnHidden[el.field] = el.hidden;
        });
      }
    },
    rows() {
      return generateTableRows({
        VLANsData: this.items,
        selectedVLANIds: this.selectedVLANIds
      });
    }
  },
  methods: {
    handleUpdateColumns(columns) {
      this.columns = columns;
    },
    rowStyleClassFn(row) {
      const rowWLANId = row.nativeData.vlan_id;

      const classesArr = [
        this.getActiveRowClass(rowWLANId)
      ];

      return classesArr.join(' ');
    },
    getActiveRowClass(rowWLANId) {
      return this.activeRowVLANId === rowWLANId && 'branch-profile-vlan-table__active-row';
    },
    handleSelectedAllRowsChange(event) {
      this.$emit('on-select-all', event);
    },
    handleSelectedRowsChange(event) {
      const { selectedRows } = event;
      this.$emit('on-selected-rows-change', selectedRows);
    },
    handleCellClick(event) {
      this.$emit('on-cell-click', event.row);
    },
    handlePageChange(event) {
      this.$emit('on-page-change', event);
    },
    handlePerPageChange(event) {
      this.$emit('on-per-page-change', event);
    },
    onSortChange(event) {
      this.$emit('on-sort-change', event);
    }
  }
};
</script>

<style lang="css" scoped>
</style>

<style lang="css">
.branch-profile-vlan-table-td-wrapper {
  max-width: 130px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.branch-profile-vlan-table__active-row {
  background: color-mix(in srgb, var(--brand-info) 70%, transparent);
  /* color: var(--font-color-main); */
}

.branch-profile-vlan-table__active-row:hover {
  background: color-mix(in srgb, var(--brand-info) 70%, transparent) !important;
  /* color: var(--font-color-main) !important; */
}
</style>
