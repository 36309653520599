/**
 * Объект содержит возможные типы редеректов
 */
const REDIRECT_TYPES = {
  STATIC: 'static',
  DYNAMIC: 'dynamic'
};

export {
  REDIRECT_TYPES
};
