/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
/**
 * конфигурация таблицы BranchProfileTable.vue
*/

/**
 * генератор колонок таблицы
 * https://xaksis.github.io/vue-good-table/guide/configuration/column-options.html#label
*/

import {
  getSelectedRowValue
} from './utils';

function generateTableColumns({ i18nInstance, hiddenColumnRules, additionalRules }) {
  const $t = i18nInstance.t.bind(i18nInstance);

  return [
    {
      label: $t('branchProfile.tableHeading.name'),
      field: 'name',
      width: 'auto',
      type: 'text',
      tdClass: '',
      hidden: hiddenColumnRules.name
    },
    {
      label: $t('branchProfile.tableHeading.description'),
      field: 'description',
      width: 'auto',
      type: 'text',
      hidden: hiddenColumnRules.description
      // visibilityDropdownSettings: {
      //   hidden: true
      // }
    }
  ];
}

/**
 * генератор строк таблицы
 * https://xaksis.github.io/vue-good-table/guide/configuration/#rows
 * https://xaksis.github.io/vue-good-table/guide/advanced/grouped-table.html#customizing-header-row
*/
function generateTableRows({ branchProfilesData, selectedBranchProfileNames }) {
  return branchProfilesData.map(branchProfile => {
    const {
      created_at,
      updated_at,
      name,
      description,
      native_vlan_id,
      fallback_radio_shut_enabled,
      arp_caching_enabled,
      efficient_sw_upgrade_enabled,
      ip_overlap_enabled,
      pmk_propagation_enabled,
      radius_group,
      web_auth_profile,
      branch_profile_vlans
    } = branchProfile;

    return {
      // nativeData - хранит неформатированные данные. Нужны, чтобы удобно эмитить их по событию для дальнейших манипуляций.
      nativeData: branchProfile,
      name,
      description,
      // можно ли выбирать строку чекбоксом
      // vgtDisabled: false,
      // выбрана ли строка в чекбоксе исходя из стейта, который контролирует выбранные строки
      vgtSelected: getSelectedRowValue(name, selectedBranchProfileNames)
    };
  });
}

export {
  generateTableColumns,
  generateTableRows
};
