/* eslint-disable consistent-return */
import store from '@/store';
import { AXIOS_INSTANCE, POST_SET_NEW_PASSWORD } from '../config';

/**
 * Функция-фетчер для отправки запроса на установку нового пароля после запроса на смену пароля.
 *
 * @param {Object} credentials - данные для смены пароля юзера.
 * @param {string} credentials.username - логин.
 * @param {string} credentials.token - токен смены пароля.
 * @param {string} credentials.newPassword - новый пароль.
 * @returns {Promise<Object>} - Обещание с данными ответа.
 * @throws {Error} - Ошибка при выполнении запроса.
 */

const setNewPasswordFetcher = async (credentials) => {
  if (!credentials) {
    throw new Error('credentials must be set');
  }

  const setNewPasswordQuery = {
    username: credentials.username,
    token: credentials.token,
    password: credentials.newPassword
  };

  try {
    const response = await AXIOS_INSTANCE.post(POST_SET_NEW_PASSWORD, setNewPasswordQuery);

    const { data } = response;

    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};

/**
 * фукнция-обработчик фетчера setNewPasswordFetcher.
 * выполняет побочные действия и обрабатывает тело запроса.
 *
 * @param {Object} credentials - данные для смены пароля юзера.
 * @param {string} credentials.username - логин.
 * @param {string} credentials.token - токен смены пароля.
 * @param {string} credentials.newPassword - новый пароль.
 * @param {Object} [options] - Дополнительные опции запроса.
 * @param {Function} [options.onLoading] - Callback при начале выполнения запроса.
 * @param {Function} [options.onSuccess] - Callback при успешном выполнении запроса.
 * @param {Function} [options.onError] - Callback при ошибке выполнения запроса.
 * @returns {Promise<Object>} - Обещание с данными ответа.
 * @throws {Error} - Ошибка при выполнении запроса.
 */
const setNewPassword = async (credentials, options) => {
  const {
    onLoading,
    onSuccess,
    onError
  } = options;

  try {
    if (onLoading) {
      onLoading();
    }

    const response = await setNewPasswordFetcher(credentials);

    const { data, status, description } = response;

    if (status === 'error') {
      throw new Error(description);
    }

    if (status === 'success' && onSuccess) {
      onSuccess(data);
    }

    if (status === 'success') {
      store.commit('setSuccess');

      return Promise.resolve(data);
    }
  } catch (error) {
    if (onError) {
      onError(error.message);
    }
    store.commit('setError');
  }
};

export default setNewPassword;
