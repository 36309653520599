/**
 * API для аутентификации.
 * @module authApi
*/

import login from './methods/login';
import logout from './methods/logout';
import requestPasswordRecovery from './methods/requestPasswordRecovery';
import changePassword from './methods/changePassword';
import setNewPassword from './methods/setNewPassword';

const authApi = {
  login,
  logout,
  requestPasswordRecovery,
  changePassword,
  setNewPassword
};

export default authApi;
