// todo: отрефакторить так, чтобы не нужен был контекст здесь вообще.
// хелперы - это независимые от контекста выполнения функции.
// А здесь они напрямую зависят от экземпляра vue.
// это делает код хрепким, модули завязаны друг на друга
export default {
  nextStepName(isPortalBuild, currentStepName) {
    if (!isPortalBuild) {
      if (currentStepName === 'UsersDemoWizardStep1') {
        return 'UsersDemoWizardStep2';
      }
      if (currentStepName === 'UsersDemoWizardStep2') {
        return 'UsersDemoWizardStep3';
      }
      if (currentStepName === 'UsersDemoWizardStep3') {
        return 'Users';
      }
      return 'Users';
    } else {
      if (currentStepName === 'UsersDemoWizardStep1') {
        return 'UsersDemoWizardStep2';
      }
      if (currentStepName === 'UsersDemoWizardStep2') {
        return 'UsersDemoWizardStep3';
      }
      if (currentStepName === 'UsersDemoWizardStep3') {
        return 'UsersDemoWizardStep4';
      }
      if (currentStepName === 'UsersDemoWizardStep4') {
        return 'UsersDemoWizardStep5';
      }
      if (currentStepName === 'UsersDemoWizardStep5') {
        return 'Users';
      }
      return 'Users';
    }
  },
  prevStepName(isPortalBuild, currentStepName) {
    if (!isPortalBuild) {
      if (currentStepName === 'UsersDemoWizardStep1') {
        return 'Users';
      }
      if (currentStepName === 'UsersDemoWizardStep2') {
        return 'UsersDemoWizardStep1';
      }
      if (currentStepName === 'UsersDemoWizardStep3') {
        return 'UsersDemoWizardStep2';
      }
      return 'Users';
    } else {
      if (currentStepName === 'UsersDemoWizardStep1') {
        return 'Users';
      }
      if (currentStepName === 'UsersDemoWizardStep2') {
        return 'UsersDemoWizardStep1';
      }
      if (currentStepName === 'UsersDemoWizardStep3') {
        return 'UsersDemoWizardStep2';
      }
      if (currentStepName === 'UsersDemoWizardStep4') {
        return 'UsersDemoWizardStep3';
      }
      if (currentStepName === 'UsersDemoWizardStep5') {
        return 'UsersDemoWizardStep4';
      }
      return 'Users';
    }
  },
  goToNextStep(ctx, currentStepName, isContentPortalEnabled) {
    ctx.$router.push({ name: this.nextStepName(isContentPortalEnabled, currentStepName) });
  },
  goToPrevStep(ctx, currentStepName, isContentPortalEnabled) {
    ctx.$router.push({ name: this.prevStepName(isContentPortalEnabled, currentStepName) });
  },
  goToFirstStep(ctx) {
    ctx.$router.push({ name: 'UsersDemoWizardStep1' });
  },
  exitFromWizard(ctx) {
    ctx.$router.push({ name: 'Users' });
  }
};
