import { createNamespacedHelpers } from 'vuex';
import { IS_APP_DEV_MODE } from '@/config';

const { mapGetters: themeMapGetters } = createNamespacedHelpers('theme');

// !!! миксин только для development mode
// он подключается 1 раз на уровне App для отладки приложения в development mode

const themeWatcherMixin = (function () {
  if (IS_APP_DEV_MODE) {
    return {
      computed: {
        ...themeMapGetters(['themeName'])
      },
      watch: {
        themeName(newVal) {
          console.warn('theme was changed from vue dev tools. New themeName is:', newVal);
          document.documentElement.className = newVal;
        }
      }
    };
  }

  return {};
}());

export default themeWatcherMixin;
