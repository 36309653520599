/**
 * Оценивает, является ли текущий пользователь оператором на основе роли в хранилище.
 *
 * @function
 * @returns {boolean} - Возвращает true, если текущий пользователь НЕ оператор, иначе - false.
 *
 * @example
 * Пример использования в навигационном охраннике:
 *
 * const router = new VueRouter({
 *   routes: [
 *     {
 *       path: '/some-protected-route',
 *       component: SomeProtectedComponent,
 *       beforeEnter: (to, from, next) => {
 *         if (isNotOperatorRoleGuard()) {
 *           * // Разрешить навигацию, так как текущий пользователь НЕ оператор
 *           next();
 *         } else {
 *           * // Если текущий пользователь оператор, выполнить перенаправление
 *           next({ name: 'Dashboard' });
 *         }
 *       },
 *     },
 *   ],
 * });
 */

import isOperatorRoleGuard from './isOperatorRoleGuard';

const isNotOperatorRoleGuard = () => !isOperatorRoleGuard();

export default isNotOperatorRoleGuard;
