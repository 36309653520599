// Используется для перевода значений ячеек в таблице для функции "Показать текущих клиентов (данные с ТД)"
// Конфигурация->Беспроводные->Точки доступа, выбрать любую ТД с подключённым клиентом, на ТД: Показать текущих клиентов (данные с ТД)
// Значения этих ячеек прилетают с бэка в виде собственно строк как результат выполнения
// на точке консольной команды 'for i in $(iw dev | grep Interface | grep -v mon | grep -v mesh | awk '{print $2}'); do echo $i; iw dev $i station dump; done'
// далее парсются для отображения в виде таблички, но всё равно вся информация из консольной команды на англиском, поэтому некоторые места нужно переводить

/**
 * Заменяет в строке одно слово на дргугое
 *
 * @param {string} stringForWordChange - строка в которой нужно поменять слово
 * @param {string} targetWord - слово в строке, которое нужно поменять
 * @param {string} newWordForTargetChange- слово в строке, которым нужно заменить targetWord
 * @return {string} - строка с замененым словом
 */
function changeWordInString(stringForWordChange, targetWord, newWordForTargetChange) {
  try {
    return stringForWordChange.replace(targetWord, newWordForTargetChange);
  } catch (e) {
    console.error(e);
    return stringForWordChange;
  }
}

const clientsFromCpeConsoleCommandParsedDataStringTranslator = {
  /**
   * Позволяет получить строку для ячейки из столбца ConnectedTime с переводом необходимых мест
   *
   * @param i18nInstance - инстанс i18n
   * @param {string} stringForTranslate - строка из ячейки из столбца ConnectedTime для которой нужен перевод (строка вида '80544 seconds')
   * @return {string} - строка для отображения в ячейке из столбца ConnectedTime с переводом необходимых мест
   */
  getConnectedTimeStringWithTranslation(i18nInstance, stringForTranslate) {
    // на входе строка вида '80544 seconds', переводим seconds
    const $t = i18nInstance.t.bind(i18nInstance);
    const targetWord = 'seconds';
    const newWordForTargetChange = $t('general.secShort');

    return changeWordInString(stringForTranslate, targetWord, newWordForTargetChange);
  },

  /**
   * Позволяет получить строку для ячейки из столбца InactiveTime с переводом необходимых мест
   *
   * @param i18nInstance - инстанс i18n
   * @param {string} stringForTranslate - строка из ячейки из столбца InactiveTime для которой нужен перевод (строка вида '36080 ms')
   * @return {string} - строка для отображения в ячейке из столбца InactiveTime с переводом необходимых мест
   */
  getInactiveTimeStringWithTranslation(i18nInstance, stringForTranslate) {
    // на входе строка вида '36080 ms', переводим ms
    const $t = i18nInstance.t.bind(i18nInstance);
    const targetWord = 'ms';
    const newWordForTargetChange = $t('general.msShort');

    return changeWordInString(stringForTranslate, targetWord, newWordForTargetChange);
  },

  /**
   * Позволяет получить строку для ячейки из столбца CombinedSignal с переводом необходимых мест
   *
   * @param i18nInstance - инстанс i18n
   * @param {string} stringForTranslate - строка из ячейки из столбца CombinedSignal для которой нужен перевод (строка вида '-42 dBm')
   * @return {string} - строка для отображения в ячейке из столбца CombinedSignal с переводом необходимых мест
   */
  getCombinedSignalStringWithTranslation(i18nInstance, stringForTranslate) {
    // на входе строка вида '-42 dBm', переводим dBm
    const $t = i18nInstance.t.bind(i18nInstance);
    const targetWord = 'dBm';
    const newWordForTargetChange = $t('aps.dbm');

    return changeWordInString(stringForTranslate, targetWord, newWordForTargetChange);
  },

  /**
   * Позволяет получить строку для ячейки из столбца ClientsBitrate с переводом необходимых мест
   *
   * @param i18nInstance - инстанс i18n
   * @param {string} stringForTranslate - строка из ячейки из столбца ClientsBitrate для которой нужен перевод (строка вида '28.9 MBit/s MCS 3 short GI')
   * @return {string} - строка для отображения в ячейке из столбца ClientsBitrate с переводом необходимых мест
   */
  getClientsBitrateStringWithTranslation(i18nInstance, stringForTranslate) {
    // на входе строка вида '-28.9 MBit/s MCS 3 short GI', переводим MBit/s
    const $t = i18nInstance.t.bind(i18nInstance);
    const targetWord = 'MBit/s';
    const newWordForTargetChange = $t('general.mbitS');

    return changeWordInString(stringForTranslate, targetWord, newWordForTargetChange);
  },

  /**
   * Позволяет получить строку для ячейки из столбцов AuthorizedAuthenticatedAssociated, WMMWME, MFP, TDLSPeer, ShortSlotTime с переводом
   * строчек типа 'yes' или 'no'
   *
   * @param i18nInstance - инстанс i18n
   * @param {string} stringForTranslate - строка из ячейки вида 'yes' или 'no' для которой нужен  перевод
   * @return {string} - строка для отображения в ячейке с переводом необходимых мест
   */
  getYesOrNoStringWithTranslation(i18nInstance, stringForTranslate) {
    // строка вида 'yes' или 'no')
    const $t = i18nInstance.t.bind(i18nInstance);
    switch (stringForTranslate.toLowerCase()) {
      case 'yes':
        return $t('general.yes').toLowerCase();
      case 'no':
        return $t('general.no').toLowerCase();
      default:
        return stringForTranslate;
    }
  }
};

export { clientsFromCpeConsoleCommandParsedDataStringTranslator };
