<template>
  <div :class="{ 'map-wrapper': showFullScreen === false, 'map-wrapper-full-screen': showFullScreen === true }">
    <l-map ref="locationsmap" class="map" :options="mapOptions">
      <l-tile-layer :url="url" :attribution="attribution" />

      <l-marker
        v-for="location in locations"
        :key="location.id"
        :lat-lng="location.coords"
        :options="{ locationId: location.id }"
        @click="markerClick"
        @mouseover="markerOver"
        @mouseleave="markerLeave"
      >
        <l-tooltip
          v-if="showOnlyNameInTooltip === false"
          class="map-for-locations-tooltip"
          :options="{ permanent: true, interactive: true }"
        >
          <div v-if="location.name">
            <span>
              <b>{{ $t('general.name') }}:</b>
            </span>
            <span>{{ location.name }}</span>
          </div>
          <div v-if="location.description">
            <span>
              <b>{{ $t('location.description') }}:</b>
            </span>
            <span>{{ location.description }}</span>
          </div>
          <div v-if="location.party">
            <div v-if="location.party.name">
              <span>
                <b>{{ $t('location.companyCaptionForTable') }}:</b>
              </span>
              <span>{{ location.party.name }}</span>
            </div>
            <div v-if="location.party.phone">
              <span>
                <b>{{ $t('location.phoneCaptionForTable') }}:</b>
              </span>
              <span>{{ location.party.phone }}</span>
            </div>
          </div>
          <!--          <div v-if="location.address">-->
          <!--            <span><b>{{ $t('location.address') }}: </b></span>-->
          <!--            <span>{{location.address}}</span>-->
          <!--          </div>-->
          <!--          <div v-if="location.ownerName">-->
          <!--            <span><b>{{ $t('location.owner') }}: </b></span>-->
          <!--            <span>{{location.ownerName}}</span>-->
          <!--          </div>-->
          <!--          <div v-if="location.managerName">-->
          <!--            <span><b>{{ $t('location.managerShort') }}: </b></span>-->
          <!--            <span>{{location.managerName}}</span>-->
          <!--          </div>-->
        </l-tooltip>
        <l-tooltip v-else class="map-for-locations-tooltip" :options="{ permanent: true, interactive: true }">
          <div v-if="location.name">
            <span>{{ location.name }}</span>
          </div>
        </l-tooltip>
      </l-marker>
    </l-map>
  </div>
</template>

<script>
import { latLng, latLngBounds } from 'leaflet';
import {
  LMap, LTileLayer, LMarker, LTooltip
} from 'vue2-leaflet';

export default {
  name: 'MapForLocations',
  components: {
    LMap,
    LTileLayer,
    LMarker,
    LTooltip
  },
  data() {
    return {
      url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      attribution: '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
      mapOptions: {
        zoomSnap: 0.25
      }
    };
  },
  props: {
    locationsWithCoords: Array,
    showOnlyNameInTooltip: {
      type: Boolean,
      default: false
    },
    showFullScreen: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    usersList() {
      return this.$store.state.usersList || [];
    },
    locations() {
      const locationWithComputedCoords = [];
      for (const location of this.locationsWithCoords) {
        const coords = latLng(location.coords.lat, location.coords.lng);
        const {
          name, id, owner, address, description, manager, party
        } = location;
        const ownerName = this.getUserNameByID(owner);
        const managerName = this.getUserNameByID(manager);
        locationWithComputedCoords.push({
          name,
          coords,
          id,
          ownerName,
          address,
          description,
          managerName,
          party
        });
      }
      return locationWithComputedCoords;
    },
    bounds() {
      // нужно для стартового зума карты, чтобы не начаналось с карты мира. Работает совместно с fitBounds() :)
      const locationLatLngBounds = [];
      for (const location of this.locationsWithCoords) {
        locationLatLngBounds.push([location.coords.lat, location.coords.lng]);
      }
      const resultBounds = latLngBounds(locationLatLngBounds);
      return resultBounds;
    }
  },
  methods: {
    getUserNameByID(id) {
      if (this.usersList && id) {
        const result = this.usersList.find((item) => item.id === id);
        if (result) {
          return result.username;
        }
        return '';
      }
      return '';
    },
    markerClick(event) {
      event.originalEvent.stopPropagation();
      const { locationId } = event.target.options;
      this.$emit('markerClick', locationId);
    },
    markerLeave(event) {
      event.originalEvent.stopPropagation();
      const locationId = '';
      this.$emit('markerOverOrLeave', locationId);
    },
    markerOver(event) {
      event.originalEvent.stopPropagation();
      const { locationId } = event.target.options;
      this.$emit('markerOverOrLeave', locationId);
    },
    fitBounds() {
      // функция нужна чтобы карта автоматически изменила зум и область просмотра, чтобы уместить все точки
      if (this.bounds._northEast && this.bounds._southWest) {
        this.$refs.locationsmap.mapObject.fitBounds(this.bounds, { padding: [40, 40] });
      }
    }
  },
  mounted() {
    // хак для прорисовки карты, без этого иногда рендерится только маленький кусок карты
    // https://github.com/vue-leaflet/Vue2Leaflet/issues/96#issuecomment-341459943
    // eslint-disable-next-line
    setTimeout(function () {
      window.dispatchEvent(new Event('resize'));
    }, 250);
    this.fitBounds();
  },
  watch: {
    bounds() {
      this.fitBounds();
    }
  }
};
</script>

<style lang="scss" scoped>
.map-wrapper {
  height: 250px;
  width: 100%;
}
.map-wrapper-full-screen {
  height: calc(100vh - 25vh);
  width: 100%;
}
.map {
  height: 100%;
}
.map-for-locations-tooltip {
}
</style>
