<template>
  <form class="auth-form" novalidate @submit.prevent="submit">
    <p v-show="!successText && !errorText && !isUserSessionEnded" class="status-text">
      {{ subheadingText }}
    </p>
    <p v-show="errorText" class="status-text error">
      {{ errorText }}
    </p>
    <p v-show="successText" class="status-text success">
      {{ successText }}
    </p>
    <!-- если редирект на login page после разлогирования, то показываем специальный successText -->
    <p v-show="isUserSessionEnded && !successText && !errorText" class="status-text success">
      {{ $t('login.autologout.afterStatusText') }}
    </p>
    <div class="fields-container">
      <slot name="fields"></slot>
      <p v-show="isCapsLockOn" class="capslock-indicator">
        {{ $t('general.capslock') }}
      </p>
    </div>
    <div class="subfields-container">
      <slot name="subfields"></slot>
    </div>
    <div class="button-container">
      <slot name="buttons"></slot>
    </div>
  </form>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';

const {
  mapGetters: autologoutGetters
} = createNamespacedHelpers('autologout');

export default {
  name: 'AuthenticationFormTemplate',
  props: {
    submit: {
      type: Function,
      required: true
    },
    subheadingText: {
      type: String,
      required: true
    },
    successText: {
      type: String,
      default: ''
    },
    errorText: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      isCapsLockOn: false
    };
  },
  computed: {
    ...autologoutGetters(['isUserSessionEnded'])
  },
  methods: {
    detectCapsLockStatus(event) {
      if (!event.getModifierState) {
        return;
      }

      if (this.isCapsLockOn === event.getModifierState('CapsLock')) {
        return;
      }

      this.isCapsLockOn = event.getModifierState('CapsLock');
    },
    subscribeOnCapsLockStatus() {
      document.addEventListener('keyup', this.detectCapsLockStatus);
      document.addEventListener('mousemove', this.detectCapsLockStatus);
    },
    unsubscribeOnCapsLockStatus() {
      document.removeEventListener('keyup', this.detectCapsLockStatus);
      document.removeEventListener('mouseover', this.detectCapsLockStatus);
    }
  },
  watch: {
  },
  created() {
  },
  mounted() {
    this.subscribeOnCapsLockStatus();
  },
  destroyed() {
    this.unsubscribeOnCapsLockStatus();
  }
};
</script>

<style lang="css" scoped>
p {
  margin: 0;
}

.status-text {
  margin-bottom: 8px;
}

.status-text.error {
  color: var(--brand-danger);
}

.status-text.success {
  color: var(--brand-success);
}

.fields-container {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.subfields-container {
  margin-top: 4px;
}

.button-container {
  margin-top: 16px;

  display: flex;
  justify-content: space-between;
  align-items: center;
}
</style>
<style lang="css">
/* todo: временные стили для WInput, чтобы скруглить углы инпутам.
В WInput пока это сделать нельзя (до замены всех инпутов на WInput в проекте),
будет сильно различаться стилизация инпутов в интерфейсе */
.auth-input {
  border-radius: 8px !important;
  color: var(--gray) !important;
}

.password-icon-button {
  border: none;
  padding: 0;
  background: transparent;
  color: inherit;
  transition: var(--transition);
}

.password-icon-button:hover {
  color: var(--brand-info);
}
</style>
