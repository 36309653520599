<template>
  <ul class="crumbs-list">
    <Crumb v-for="item in items" :key="item.id"
      :item="item"
      @on-root-role-click="handleRootRoleClick"
    />
  </ul>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';

import Crumb from './crumb/Crumb.vue';

const { mapActions: navigationMapActions } = createNamespacedHelpers('navigation');

export default {
  name: 'BreadcrumbList',
  components: {
    Crumb
  },
  props: {
    items: {
      type: Array,
      required: true
    }
  },
  computed: {
  },
  methods: {
    ...navigationMapActions(['toggleNavVisibility']),
    handleRootRoleClick(routeName) {
      this.toggleNavVisibility(routeName);
    }
  }
};
</script>

<style lang="css" scoped>
ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.crumbs-list {
  display: flex;
  align-items: center;
  color: var(--breadcrumb-font-color);
}
</style>
