import Vue from 'vue';
import VueNotifications from 'vue-notifications';
import { API_URL } from '@/config';

const FILTERS = {
  CPE: [
    'CPE_CONNECTED',
    'CPE_DISCONNECTED',
    'CPE_FIRMWARE_ERROR',
    'CPE_INTERFACE_STATE',
    'CPE_FIRMWARE_AVAILABLE',
    'CPE_CONFIGURATION_ERROR',
    'CPE_CONFIGURATION_SUCCESS'
  ],
  CLIENT: ['CLIENT_CONNECTED', 'CLIENT_DISCONNECTED', 'CLIENT_AUTHORIZATION', 'DHCP_ACK'],
  SERVICE: ['SERVICE_CONNECTED', 'SERVICE_FATAL_ERROR', 'SERVICE_DISCONNECTED', 'SERVICE_SETTINGS_CHANGE'],
  FIRMWARE: ['FIRMWARE_UPLOADED'],
  RULE: ['MONITOR_RULE_VIOLATION'],
  LOG: ['LOGGED_ERROR'],
  'CLIENT AUTH': ['CLIENT_AUTHORIZATION'],
  CONNECTED: ['CLIENT_CONNECTED'],
  DISCONNECTED: ['CLIENT_DISCONNECTED']
};

export default {
  getMoreEvents(ctx, limit, offset, options) {
    const {
      turnOnLoading,
      turnOffLoading
    } = options;

    ctx.isCanLoadMore = true;

    const query = {
      action: 'R',
      location: ctx.$store.getters.getDefaultLocationForRequests(ctx), with_childs: ctx.$store.getters.getDefaultWithChildsForRequests(ctx),
      search: ctx.searchValue,
      page: {
        limit,
        offset: limit * offset
      },
      q: {},
      timebounds: {
        start: moment(ctx.drPickerDate.startDate).unix(),
        stop: moment(ctx.drPickerDate.endDate).unix()
      }
    };

    if (ctx.filters?.location) {
      if (ctx.filters.location === 'All locations') {
        query.location = ctx.$store.state.requestBaseUserLocationIfNoSelected ? ctx.userBaseLocation : undefined;
        query.with_childs = true;
      } else {
        query.location = ctx.filters.location;
        query.with_childs = ctx.filters.with_childs;
      }
    }

    if (Object.keys(ctx.filters.level).length) {
      const levelFilter = { $in: ctx.filters.level };
      query.q.level = levelFilter;
    }

    if (Object.keys(ctx.filters.type).length) {
      const typeFilter = { $in: FILTERS[ctx.filters.type[0]] };
      query.q.type = typeFilter;
    }

    if (Object.prototype.hasOwnProperty.call(ctx, 'clientMacAddr') && ctx.clientMacAddr !== '') {
      query.q.subject_id = {
        $in: [ctx.clientMacAddr, ctx.clientMacAddr.toLowerCase(), ctx.clientMacAddr.toUpperCase()]
      };
    }

    if (turnOnLoading) {
      turnOnLoading();
    }

    Vue.axios.post(`${API_URL || ''}/api/events`, query).then(
      (response) => {
        if (response.data.status === 'success') {
          ctx.totalResult = response.data.data.total;
          !offset ?
            ctx.$store.commit('setEvents', response.data.data.itemslist) :
            ctx.$store.commit('pushEvents', response.data.data.itemslist);
          if (ctx.$store.state.eventsList.length >= response.data.data.total) {
            ctx.isCanLoadMore = false;
          }
          if (turnOffLoading) {
            turnOffLoading();
          }
        } else if (response.data.status === 'error') {
          VueNotifications.error({ message: response.data.description });
          if (turnOffLoading) {
            turnOffLoading();
          }
        }
      },
      (err) => {
        // VueNotifications.error({ message: err });

        // show errors in the console instead of the alert window
        if (
          Object.prototype.hasOwnProperty.call(window, 'isShowAxiosErrorInConsole') &&
          typeof window.isShowAxiosErrorInConsole === 'function' &&
          window.isShowAxiosErrorInConsole(err)
        ) {
          window.showAxiosErrInConsole(err);
        } else {
          VueNotifications.error({ message: err });
        }

        ctx.error = err;
        if (turnOffLoading) {
          turnOffLoading();
        }
      }
    );
  },

  // getEvents(ctx, spinner) {
  //   ctx.isCanLoadMore = true;
  //
  //   const query = {
  //     action: 'R',
  //     search: ctx.searchValue,
  //     page: {
  //       limit: ctx.limit + ctx.limit * ctx.offset,
  //       offset: 0
  //     },
  //     q: {},
  //     timebounds: {
  //       start: moment(ctx.drPickerDate.startDate).unix(),
  //       stop: moment(ctx.drPickerDate.endDate).unix()
  //     }
  //   };
  //
  //   if (Object.keys(ctx.filters.level).length) {
  //     const levelFilter = { $in: ctx.filters.level };
  //     query.q.level = levelFilter;
  //   }
  //
  //   if (Object.keys(ctx.filters.type).length) {
  //     const typeFilter = { $in: FILTERS[ctx.filters.type[0]] };
  //     query.q.type = typeFilter;
  //   }
  //
  //   if (Object.prototype.hasOwnProperty.call(ctx, 'clientMacAddr') && ctx.clientMacAddr !== '') {
  //     query.q.subject_id = {
  //       $in: [ctx.clientMacAddr, ctx.clientMacAddr.toLowerCase(), ctx.clientMacAddr.toUpperCase()]
  //     };
  //   }
  //
  //   if (spinner) {
  //     ctx.$store.commit('toggleLoadingEvents');
  //   }
  //   Vue.axios.post(`${API_URL || ''}/api/events`, query).then(
  //     (response) => {
  //       if (response.data.status === 'success') {
  //         ctx.totalResult = response.data.data.total;
  //         ctx.$store.commit('setEvents', response.data.data.itemslist);
  //         if (spinner) {
  //           ctx.$store.commit('toggleLoadingEvents');
  //         }
  //         if (ctx.$store.state.eventsList.length >= response.data.data.total) {
  //           ctx.isCanLoadMore = false;
  //         }
  //         ctx.searchSpinner = false;
  //       } else if (response.data.status === 'error') {
  //         VueNotifications.error({ message: response.data.description });
  //         if (spinner) {
  //           ctx.$store.commit('toggleLoadingEvents');
  //         }
  //         ctx.searchSpinner = false;
  //       }
  //     },
  //     (err) => {
  //       // VueNotifications.error({ message: err });
  //
  //       // show errors in the console instead of the alert window
  //       if (
  //         Object.prototype.hasOwnProperty.call(window, 'isShowAxiosErrorInConsole') &&
  //         typeof window.isShowAxiosErrorInConsole === 'function' &&
  //         window.isShowAxiosErrorInConsole(err)
  //       ) {
  //         window.showAxiosErrInConsole(err);
  //       } else {
  //         VueNotifications.error({ message: err });
  //       }
  //
  //       ctx.error = err;
  //       ctx.$store.commit('toggleLoadingEvents');
  //       ctx.searchSpinner = false;
  //     }
  //   );
  // },
  getEvents(ctx, useLocationFilter = false, options) {
    const {
      turnOnLoading,
      turnOffLoading
    } = options;

    const isUseLocationFilters = useLocationFilter
    ctx.isCanLoadMore = true;

    const query = {
      action: 'R',
      search: ctx.searchValue,
      location: ctx.$store.getters.getDefaultLocationForRequests(ctx), with_childs: ctx.$store.getters.getDefaultWithChildsForRequests(ctx),
      page: {
        limit: ctx.limit + ctx.limit * ctx.offset,
        offset: 0
      },
      q: {},
      timebounds: {
        start: moment(ctx.drPickerDate.startDate).unix(),
        stop: moment(ctx.drPickerDate.endDate).unix()
      }
    };
    if (ctx.filters?.location && isUseLocationFilters) {
      if (ctx.filters.location === 'All locations') {
        query.location = ctx.$store.state.requestBaseUserLocationIfNoSelected ? ctx.userBaseLocation : undefined;
        query.with_childs = true;
      } else {
        query.location = ctx.filters.location;
        query.with_childs = ctx.filters.with_childs;
      }
    }

    if (Object.keys(ctx.filters.level).length) {
      const levelFilter = { $in: ctx.filters.level };
      query.q.level = levelFilter;
    }

    if (Object.keys(ctx.filters.type).length) {
      const typeFilter = { $in: FILTERS[ctx.filters.type[0]] };
      query.q.type = typeFilter;
    }

    if (Object.prototype.hasOwnProperty.call(ctx, 'clientMacAddr') && ctx.clientMacAddr !== '') {
      query.q.subject_id = {
        $in: [ctx.clientMacAddr, ctx.clientMacAddr.toLowerCase(), ctx.clientMacAddr.toUpperCase()]
      };
    }

    if (turnOnLoading) {
      turnOnLoading();
    }
    Vue.axios.post(`${API_URL || ''}/api/events`, query).then(
      (response) => {
        if (response.data.status === 'success') {
          ctx.totalResult = response.data.data.total;
          ctx.$store.commit('setEvents', response.data.data.itemslist);

          if (turnOffLoading) {
            turnOffLoading();
          }
          if (ctx.$store.state.eventsList.length >= response.data.data.total) {
            ctx.isCanLoadMore = false;
          }
          ctx.searchSpinner = false;
        } else if (response.data.status === 'error') {
          VueNotifications.error({ message: response.data.description });

          if (turnOffLoading) {
            turnOffLoading();
          }
          ctx.searchSpinner = false;
        }
      },
      (err) => {
        // VueNotifications.error({ message: err });

        // show errors in the console instead of the alert window
        if (
          Object.prototype.hasOwnProperty.call(window, 'isShowAxiosErrorInConsole') &&
          typeof window.isShowAxiosErrorInConsole === 'function' &&
          window.isShowAxiosErrorInConsole(err)
        ) {
          window.showAxiosErrInConsole(err);
        } else {
          VueNotifications.error({ message: err });
        }

        ctx.error = err;

        if (turnOffLoading) {
          turnOffLoading();
        }
        ctx.searchSpinner = false;
      }
    );
  },

  getEventsSummary(ctx) {
    ctx.$store.commit('toggleLoadingEventsCharts', true);
    Vue.axios.post(`${API_URL || ''}/api/dashboard/events`, { action: 'R', location: ctx.$store.getters.getDefaultLocationForRequests(ctx), with_childs: ctx.$store.getters.getDefaultWithChildsForRequests(ctx),  }).then(
      (response) => {
        if (response.data.status === 'success') {
          const summaryList = response.data.data.items.events;
          ctx.eventsChart.series[0].data = [];
          ctx.eventsChart.series[1].data = [];
          ctx.eventsChart.series[2].data = [];
          if (!summaryList) {
            return;
          }
          summaryList.by_level.forEach((event) => {
            if (event.type === 'INFO') {
              const time = new Date(event.timestamp * 1000);
              ctx.eventsChart.series[2].data.push([
                Date.UTC(
                  time.getFullYear(),
                  time.getMonth(),
                  time.getDate(),
                  time.getHours(),
                  time.getMinutes(),
                  time.getSeconds()
                ),
                event.total
              ]);
            } else if (event.type === 'WARNING') {
              const time = new Date(event.timestamp * 1000);
              ctx.eventsChart.series[1].data.push([
                Date.UTC(
                  time.getFullYear(),
                  time.getMonth(),
                  time.getDate(),
                  time.getHours(),
                  time.getMinutes(),
                  time.getSeconds()
                ),
                event.total
              ]);
            } else if (event.type === 'ERROR') {
              const time = new Date(event.timestamp * 1000);
              ctx.eventsChart.series[0].data.push([
                Date.UTC(
                  time.getFullYear(),
                  time.getMonth(),
                  time.getDate(),
                  time.getHours(),
                  time.getMinutes(),
                  time.getSeconds()
                ),
                event.total
              ]);
            }
          });
          ctx.$store.commit('toggleLoadingEventsCharts', false);
        } else if (response.data.status === 'error') {
          VueNotifications.error({ message: response.data.description });
          ctx.$store.commit('toggleLoadingEventsCharts', false);
        }
      },
      (err) => {
        // VueNotifications.error({ message: err });

        // show errors in the console instead of the alert window
        if (
          Object.prototype.hasOwnProperty.call(window, 'isShowAxiosErrorInConsole') &&
          typeof window.isShowAxiosErrorInConsole === 'function' &&
          window.isShowAxiosErrorInConsole(err)
        ) {
          window.showAxiosErrInConsole(err);
        } else {
          VueNotifications.error({ message: err });
        }

        ctx.error = err;
        ctx.$store.commit('toggleLoadingEventsCharts', false);
      }
    );
  },
  getEventsSummaryForDashboard(ctx) {
    ctx.$store.commit('toggleLoadingEventsCharts', true);

    const locationId = ctx.locationForBlocksStatistics ?? undefined;
    const withChilds = ctx.locationWithChildsForBlocksStatistics ?? true;
    const payload = {
      action: 'R',
      with_childs: ctx.$store.getters.getDefaultWithChildsForRequests(ctx)
    };
    if (locationId) {
      payload.q = { by_location: locationId, with_childs: withChilds }
    }

    Vue.axios.post(`${API_URL || ''}/api/dashboard/events`, payload).then(
      (response) => {
        if (response.data.status === 'success') {
          const summaryList = response.data.data.items.events;
          ctx.eventsChart.series[0].data = [];
          ctx.eventsChart.series[1].data = [];
          ctx.eventsChart.series[2].data = [];
          if (!summaryList) {
            return;
          }
          summaryList.by_level.forEach((event) => {
            if (event.type === 'INFO') {
              const time = new Date(event.timestamp * 1000);
              ctx.eventsChart.series[2].data.push([
                Date.UTC(
                  time.getFullYear(),
                  time.getMonth(),
                  time.getDate(),
                  time.getHours(),
                  time.getMinutes(),
                  time.getSeconds()
                ),
                event.total
              ]);
            } else if (event.type === 'WARNING') {
              const time = new Date(event.timestamp * 1000);
              ctx.eventsChart.series[1].data.push([
                Date.UTC(
                  time.getFullYear(),
                  time.getMonth(),
                  time.getDate(),
                  time.getHours(),
                  time.getMinutes(),
                  time.getSeconds()
                ),
                event.total
              ]);
            } else if (event.type === 'ERROR') {
              const time = new Date(event.timestamp * 1000);
              ctx.eventsChart.series[0].data.push([
                Date.UTC(
                  time.getFullYear(),
                  time.getMonth(),
                  time.getDate(),
                  time.getHours(),
                  time.getMinutes(),
                  time.getSeconds()
                ),
                event.total
              ]);
            }
          });
          ctx.$store.commit('toggleLoadingEventsCharts', false);
        } else if (response.data.status === 'error') {
          VueNotifications.error({ message: response.data.description });
          ctx.$store.commit('toggleLoadingEventsCharts', false);
        }
      },
      (err) => {
        // VueNotifications.error({ message: err });

        // show errors in the console instead of the alert window
        if (
          Object.prototype.hasOwnProperty.call(window, 'isShowAxiosErrorInConsole') &&
          typeof window.isShowAxiosErrorInConsole === 'function' &&
          window.isShowAxiosErrorInConsole(err)
        ) {
          window.showAxiosErrInConsole(err);
        } else {
          VueNotifications.error({ message: err });
        }

        ctx.error = err;
        ctx.$store.commit('toggleLoadingEventsCharts', false);
      }
    );
  },

};
