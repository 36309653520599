import { STORAGE_KEY_THEME, THEME_STATES } from '@/config';
import { ObjectStorage } from './storage';

/**
 * Класс для кеширования темы приложения (темная/светлая).
 */

export default class ThemeMaster {
  /**
   * Просто пробрасываем варианты тем из конфига, где они задаются.
   * Чтобы можно было пользоватся ими в коде через ThemeMaster.THEMES, а не импортируя
   * конфиг каждый раз отдельно
   * */
  static THEMES = THEME_STATES;

  /**
   * Возвращает кешированную тему или null, если тема не была закеширована.
   * @returns {'dark' | 'light' | null}
   */
  static getCachedTheme() {
    return ObjectStorage.getItem(STORAGE_KEY_THEME);
  }

  /**
   * Кеширует тему.
   * @param {string} newTheme- Новая тема для кэширования.
   */
  static setCachedTheme(newTheme) {
    ObjectStorage.setItem(STORAGE_KEY_THEME, newTheme);
  }

  /**
   * Удаляет тему из кеша.
   */
  static clearCachedTheme() {
    ObjectStorage.removeItem(STORAGE_KEY_THEME);
  }

  /**
   * Проверяет, является ли кэшированная тема  светлой.
   * @returns {boolean} true, если кэшированная тема - светлая, иначе false.
   */
  static isCachedThemeLight() {
    return this.getCachedTheme() === this.THEMES.light;
  }

  /**
   * Проверяет, является ли кэшированная тема темной.
   * @returns {boolean} true, если кэшированная тема - темная, иначе false.
   */
  static isCachedThemeDark() {
    return this.getCachedTheme() === this.THEMES.dark;
  }
}
