<template>
  <AuthenticationWrapper>
    <template v-slot:form-content>
      <AuthenticationCard
        :heading="$t('login.resetPasswordNewPasswordHeader')"
      >
        <ResetPasswordForm/>
      </AuthenticationCard>
    </template>
  </AuthenticationWrapper>
</template>

<script>
import { AuthenticationWrapper, AuthenticationCard, ResetPasswordForm } from './components';

export default {
  name: 'ResetPassword',
  components: {
    AuthenticationWrapper,
    AuthenticationCard,
    ResetPasswordForm
  }
};
</script>

<style lang="css" scoped>
</style>
