<template>
  <div>
    <WCard class="table-card">
      <APJoinTable
        :items="apJoinProfiles"
        :totalItems="totalApJoinProfilesCount"
        :perPage="limit"
        :selectedApJoinProfileIds="selectedApJoinProfileIds"
        :sortState="sortParams"
        :isDataLoading="false"
        :isDisabled="isLoading"
        :isOneColMode="false"
        :isSelectionRowsModeEnabled="true"
        @on-cell-click="handleCellClick"
        @on-selected-rows-change="handleSelectRow"
        @on-sort-change="handleSortChange"
        @on-page-change="handlePageChange"
        @on-per-page-change="handlePerPageChange"
      >
        <template v-slot:table-menu>
          <div class="control-panel">
            <div class="control-panel__container left">
              <WButton info
                :disabled="isLoading"
                @click="openCreateAPProfileModal"
              >
                {{ $t('general.add') }}
              </WButton>
              <WButton info
                :disabled="isLoading || selectedApJoinProfileIds.length !== 1"
                @click="openCloneAPProfileModal"
              >
                {{ $t('general.clone') }}
              </WButton>
              <WButton info
                :disabled="isLoading || !selectedApJoinProfileIds.length"
                @click="openDeleteAPProfileModal"
              >
                {{ $t('general.delete') }}
              </WButton>
            </div>
            <div class="control-panel__container right">
            </div>
          </div>
        </template>
      </APJoinTable>
    </WCard>

    <CreateAPProfileModal
      :forbiddenProfileNames="forbiddenProfileNames"
      :isOpen="isCreateAPProfileModalOpen"
      @submit="createAPsProfile"
      @close="closeCreateAPProfileModal"
    />

    <ChangeAPProfileModal
      :apProfileForChanging="selectedApProfileForChanging"
      :forbiddenProfileNames="forbiddenProfileNames"
      :isOpen="isChangeAPProfileModalOpen"
      @submit="updateAPsProfile"
      @close="closeChangeAPProfileModal"
    />

    <CloneAPProfileModal
      :apProfileForCloning="selectedApProfileForCloning"
      :forbiddenProfileNames="forbiddenProfileNames"
      :isOpen="isCloneAPProfileModal"
      @submit="createAPsProfile"
      @close="closeCloneAPProfileModal"
    />

    <DeleteAPProfileModal
      :apProfileNamesToDelete="apProfileNamesToDelete"
      :isOpen="isDeleteAPProfileModalOpen"
      @close="closeDeleteAPProfileModal"
      @confirm="handleDeleteAPProfiles"
    />
  </div>
</template>

<script>
/**
 * компонент страницы Найстройка ТД (AP Join)
 * https://wimarksystems.atlassian.net/wiki/spaces/Wimark/pages/218562646/FT23.7.1+Front
 * @component
 */

import {
  APJoinTable,
  CreateAPProfileModal,
  ChangeAPProfileModal,
  CloneAPProfileModal,
  DeleteAPProfileModal
} from './components';

import apJoinApi from './api';

export default {
  name: 'APJoinPage',
  components: {
    APJoinTable,
    CreateAPProfileModal,
    ChangeAPProfileModal,
    CloneAPProfileModal,
    DeleteAPProfileModal
  },
  props: {
  },
  data() {
    return {
      apJoinProfiles: [],
      // айдишки для контролирования стейта при выборе через чекбокс
      selectedApJoinProfileIds: [],
      selectedApProfileForChanging: {},
      selectedApProfileForCloning: {},
      totalApJoinProfilesCount: 0,
      offset: 0,
      limit: 10,
      sortParams: {
        field: 'name',
        type: 'asc'
      },
      isLoading: false,
      isCreateAPProfileModalOpen: false,
      isChangeAPProfileModalOpen: false,
      isCloneAPProfileModal: false,
      isDeleteAPProfileModalOpen: false
    };
  },
  computed: {
    forbiddenProfileNames() {
      return this.apJoinProfiles.map(apJoinProfile => apJoinProfile.name);
    },
    apProfileNamesToDelete() {
      if (!this.apJoinProfiles.length) {
        return [];
      }
      return this.apJoinProfiles
        .filter(apJoinProfile => this.selectedApJoinProfileIds.includes(apJoinProfile.id))
        .map(apJoinProfile => apJoinProfile.name);
    }
  },
  methods: {
    handleCellClick(rowData) {
      const { nativeData: { id } } = rowData;

      const [selectedApProfileForChanging] = this.apJoinProfiles
        .filter(apJoinProfile => apJoinProfile.id === id);

      this.selectedApProfileForChanging = selectedApProfileForChanging;

      this.openChangeAPProfileModal();
    },
    handleSelectRow(selectedRowData) {
      const selectedIds = selectedRowData.map(rowData => rowData.nativeData.id);

      if (selectedIds.length === 1) {
        const [selectedApProfileForCloning] = this.apJoinProfiles
          .filter(apJoinProfile => apJoinProfile.id === selectedIds[0]);

        this.selectedApProfileForCloning = selectedApProfileForCloning;
      }

      if (selectedIds.length !== 1) {
        this.selectedApProfileForCloning = {};
      }

      this.selectedApJoinProfileIds = selectedIds;
    },
    handleSortChange(sortChangeData) {
      const { params } = sortChangeData;
      const { field, type } = params[0];

      this.sortParams = { field, type };

      this.getAPsProfiles();
    },
    handlePageChange(paginationParams) {
      const { currentPage, currentPerPage } = paginationParams;

      const newOffsetValue = currentPage * currentPerPage - currentPerPage;

      this.offset = newOffsetValue;
      this.limit = currentPerPage;

      if (newOffsetValue >= this.totalApJoinProfilesCount) {
        this.offset = 0;
      }

      this.getAPsProfiles();
    },
    handlePerPageChange(paginationParams) {
      const { currentPage, currentPerPage } = paginationParams;

      const newOffsetValue = currentPage * currentPerPage - currentPerPage;

      this.offset = newOffsetValue;
      this.limit = currentPerPage;

      if (newOffsetValue >= this.totalApJoinProfilesCount) {
        this.offset = 0;
      }

      this.getAPsProfiles();
    },
    handleDeleteAPProfiles() {
      const APProfilesIdsToDelete = this.selectedApJoinProfileIds;

      apJoinApi.deleteAPsProfile({
        apProfilesIdsToDelete: APProfilesIdsToDelete
      }, {
        onLoading: () => {
          this.isLoading = true;
        },
        onSuccess: (data) => {
          this.isLoading = false;
          this.closeDeleteAPProfileModal();
          this.getAPsProfiles();
        },
        onError: (error) => {
          this.isLoading = false;
        },
        successMessage: this.$t('apjoin.responseMessage.deleteAPsProfile.success'),
        errorMessage: this.$t('apjoin.responseMessage.deleteAPsProfile.error')
      });
    },
    updateAPsProfile(formValues) {
      const { id } = this.selectedApProfileForChanging;

      apJoinApi.updateAPsProfile({
        id,
        name: formValues['general.profileName'],
        description: formValues['general.profileDescription'],
        led_enabled: formValues['general.ledState'],
        mss_wan_enabled: formValues['general.mssState'],
        mss_wan_size: formValues['general.mssSizeOfWanInterface'],
        lldp_enabled: formValues['general.lldpCdpSupport'],
        ntp_server: formValues['general.ipAdressOfNTPServer'],
        timezone: formValues['general.timezone'],
        offset_hh: formValues['general.offsetHH'],
        offset_mm: formValues['general.offsetMM'],
        primary_controller: formValues['general.primaryController'],
        secondary_controller: formValues['general.secondaryController'],
        tertiary_controller: formValues['general.tertiaryController'],
        ssh_enabled: formValues['management.sshSupport'],
        ssh_port: formValues['management.sshPort'],
        username: formValues['management.username'],
        password: formValues['management.password'],
        secret: formValues['management.secretPassword'],
        stats_enabled: formValues['statistics.sendApStatistic'],
        stats_report_period: formValues['statistics.apStatiscticReportPeriod'],
        log_facility: formValues['syslog.facilityValue'],
        log_ip: formValues['syslog.ipAdressOfRemoteServerForSyslogMessages'],
        log_level: formValues['syslog.logLevel'],
        heartbeat_timeout: formValues['timers.heartbeatTimeout'],
        count: formValues['timers.configurationRetransmissions'],
        interval: formValues['timers.acceptanceInterval'],
        trust_dscp_enabled: formValues['qualityOfService.trustDscpUpstream']
      }, {
        onLoading: () => {
          this.isLoading = true;
        },
        onSuccess: (data) => {
          this.isLoading = false;
          this.closeChangeAPProfileModal();
          this.getAPsProfiles();
        },
        onError: (error) => {
          this.isLoading = false;
        },
        successMessage: this.$t('apjoin.responseMessage.updateAPsProfile.success'),
        errorMessage: this.$t('apjoin.responseMessage.updateAPsProfile.error'),
      });
    },
    createAPsProfile(formValues) {
      apJoinApi.createAPsProfile({
        name: formValues['general.profileName'],
        description: formValues['general.profileDescription'],
        led_enabled: formValues['general.ledState'],
        mss_wan_enabled: formValues['general.mssState'],
        mss_wan_size: formValues['general.mssSizeOfWanInterface'],
        lldp_enabled: formValues['general.lldpCdpSupport'],
        ntp_server: formValues['general.ipAdressOfNTPServer'],
        timezone: formValues['general.timezone'],
        offset_hh: formValues['general.offsetHH'],
        offset_mm: formValues['general.offsetMM'],
        primary_controller: formValues['general.primaryController'],
        secondary_controller: formValues['general.secondaryController'],
        tertiary_controller: formValues['general.tertiaryController'],
        ssh_enabled: formValues['management.sshSupport'],
        ssh_port: formValues['management.sshPort'],
        username: formValues['management.username'],
        password: formValues['management.password'],
        secret: formValues['management.secretPassword'],
        stats_enabled: formValues['statistics.sendApStatistic'],
        stats_report_period: formValues['statistics.apStatiscticReportPeriod'],
        log_facility: formValues['syslog.facilityValue'],
        log_ip: formValues['syslog.ipAdressOfRemoteServerForSyslogMessages'],
        log_level: formValues['syslog.logLevel'],
        heartbeat_timeout: formValues['timers.heartbeatTimeout'],
        count: formValues['timers.configurationRetransmissions'],
        interval: formValues['timers.acceptanceInterval'],
        trust_dscp_enabled: formValues['qualityOfService.trustDscpUpstream']
      }, {
        onLoading: () => {
          this.isLoading = true;
        },
        onSuccess: (data) => {
          this.isLoading = false;
          this.closeCreateAPProfileModal();
          this.closeCloneAPProfileModal();
          this.getAPsProfiles();
        },
        onError: () => {
          this.isLoading = false;
        },
        successMessage: this.$t('apjoin.responseMessage.createAPsProfile.success')
      });
    },
    getAPsProfiles() {
      apJoinApi.getAPsProfiles({
        limit: this.limit,
        offset: this.offset,
        // если надо будет реализовать фильтрацию, то использовать filterById, filterByName, filterByDescription
        filterById: undefined,
        filterByName: undefined,
        filterByDescription: undefined,
        sortOrderBy: this.sortParams.type,
        sortValueBy: this.sortParams.field
      }, {
        onLoading: () => {
          this.isLoading = true;
        },
        onSuccess: (data) => {
          this.isLoading = false;

          const {
            total,
            limit,
            offset,
            ap_join_profiles
          } = data;

          this.apJoinProfiles = ap_join_profiles;
          this.totalApJoinProfilesCount = total;
          this.offset = offset;
          this.limit = limit;

          this.isLoading = false;

          this.selectedApJoinProfileIds = [];
        },
        onError: (error) => {
          this.isLoading = false;
        }
      });
    },
    openCreateAPProfileModal() {
      this.isCreateAPProfileModalOpen = true;
    },
    closeCreateAPProfileModal() {
      this.isCreateAPProfileModalOpen = false;
    },
    openChangeAPProfileModal() {
      this.isChangeAPProfileModalOpen = true;
    },
    closeChangeAPProfileModal() {
      this.isChangeAPProfileModalOpen = false;
      this.selectedApProfileForChanging = {};
    },
    openCloneAPProfileModal() {
      this.isCloneAPProfileModal = true;
    },
    closeCloneAPProfileModal() {
      this.isCloneAPProfileModal = false;
    },
    openDeleteAPProfileModal() {
      this.isDeleteAPProfileModalOpen = true;
    },
    closeDeleteAPProfileModal() {
      this.isDeleteAPProfileModalOpen = false;
    }
  },
  mounted() {
    this.getAPsProfiles();
  }
};
</script>

<style lang="css" scoped>
.table-card {
  background-color: var(--table-accent);
}

.control-panel {
  display: flex;
  gap: 24px;
  padding: 8px 0;
}

.control-panel__container {
  display: flex;
  gap: 8px;
}

.control-panel__container.right {
  justify-content: flex-end;
  flex: 1 1 auto;
}
</style>
