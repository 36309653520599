import Vue from 'vue';
import VueNotifications from 'vue-notifications';
import helpers from '../helpers';
import store from '../store';
import operationsService from './operationsService';
import commonService from './commonService';
import { API_URL } from '@/config';

export default {
  hadleOperationResponse(items) {
    if (store.state.cpeOperations.indexOf(items) == -1) {
      store.commit('setCpeOperations', items);
    }
  },
  startPolling(ctx) {
    const queryIds = this.getPollingIds();
    let counter = 0;
    if (queryIds.length) {
      const pollIteration = setInterval(() => {
        Vue.axios.post(`${API_URL || ''}/api/operations`, { action: 'R', ids: queryIds }).then(
          (response) => {
            counter++;
            // console.log(counter)
            // для задачки WNE-2270
            // с бэка в response.data.data.total приходит 0 (и это баг) и из-за этого получается что функция вызывается постоянно
            // каждые 5 секунд и не останавливается
            if (counter >= 5 && response.data.data.total === 0) {
              // если приходит response.data.data.total 0 с бэка и это уже 6 запрос
              // то нужно остановить эти запросы чтобы не ddos'ить бэк
              // чистим все в queryIds,
              // и отменяем повторные запросы и прекращаем выполнение функции
              this.clearPollingIds(queryIds);
              if (ctx) {
                ctx.refreshCpesList();
              }
              operationsService.getPedingOperations();
              clearInterval(pollIteration);
              return;
            }
            response.data.data.itemslist.forEach((operation) => {
              if (operation.status === 'success') {
                let isOperationActual = false;

                store.state.cpeOperations.forEach((storeCpeOperation) => {
                  if (storeCpeOperation.id === operation.id) {
                    isOperationActual = true;
                  }
                });
                store.commit('setCpeOperationStatus', {
                  model_id: operation.model_id,
                  status: operation.status,
                  id: operation.id
                });
                if (isOperationActual) {
                  this.showSuccessPopup(operation, ctx);
                }
                clearInterval(pollIteration);
              }
              if (operation.status === 'error') {
                let isOperationActual = false;

                store.state.cpeOperations.forEach((storeCpeOperation) => {
                  if (storeCpeOperation.id === operation.id) {
                    isOperationActual = true;
                  }
                });
                store.commit('setCpeOperationStatus', {
                  model_id: operation.model_id,
                  status: operation.status,
                  id: operation.id
                });
                clearInterval(pollIteration);
                const operationModelName = commonService.cpeNamebyId(operation.model_id);
                if (isOperationActual) {
                  VueNotifications.error({ message: operation.description });
                }
              }
            });
            if (!store.state.cpeOperations.length) {
              if (ctx) {
                ctx.refreshCpesList();
              }
              operationsService.getPedingOperations();
              clearInterval(pollIteration);
            }
          },
          (err) => {}
        );
      }, 5000);
    }
  },
  getPollingIds() {
    const pollingIds = [];
    store.state.cpeOperations.forEach((operation) => {
      if (!pollingIds.includes(operation.id)) {
        if (operation.status === 'pending') {
          pollingIds.push(operation.id);
        }
      }
    });
    return pollingIds;
  },
  clearPollingIds(ids) {
    // очищаем список с IDшками
    for (const operationIndex in store.state.cpeOperations) {
      const operationId = store.state.cpeOperations[operationIndex].id;
      if (ids.includes(operationId)) {
        // console.log('deleting');
        Vue.delete(store.state.cpeOperations, operationIndex);
      }
    }
  },
  showSuccessPopup(operation, ctx) {
    switch (operation.type) {
      case 'U':
        VueNotifications.success({ message: ctx.$t('aps.notificationCPEUpdatedSuccessfully') });
        break;
      case 'C':
        VueNotifications.success({ message: ctx.$t('aps.notificationCPECreatedSuccessfully') });
        break;
      default:
        VueNotifications.success({ message: ctx.$t('aps.notificationCPEOperationSuccessfull') });
    }
  }
};
