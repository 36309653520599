const FIREWALL = ['Firewall', 'firewall-wizard', 'firewall-step-1', 'firewall-step-2', 'firewall-step-3'];
const GUEST_CONTROL = ['GuestControl', 'GuestControl-wizard', 'GuestControl-step-1', 'GuestControl-step-2'];
const HOTSPOT = ['Hotspot', 'hotspot-wizard', 'hotspot-step-1', 'hotspot-step-2', 'hotspot-step-3', 'hotspot-step-4'];
const MAPS = ['maps', 'maps-wizard', 'maps-step-1', 'maps-step-2', 'maps-step-3'];
const PORTAL_CONFIGURATION = [
  'PortalAdsNew',
  'PortalHotelVouchers',
  'PortalPagesNew',
  'PortalPreview',
  'PortalProfilesNew',
  'PortalAccessServers'
];
const PORTAL_ANALYTICS = [
  'PortalBirthdays',
  'PortalStats',
  'PortalAdsStats',
  'UserAccounts',
  'PortalSessions',
  'PortalLocationsStat'
];
const PORTAL_PAID_INTERNET = ['PaymentSystems', 'Tariffs', 'Vouchers'];
const RADIUS = ['Radius', 'radius-wizard', 'radius-step-1', 'radius-step-2', 'radius-step-3'];
const RULES = ['Rules', 'rule-wizard', 'rule-step-1', 'rule-step-2'];
const SETTINGS = ['Settings', 'Account', 'Location', 'Users'];
const TEMPLATES = ['Templates', 'templates-wizard', 'templates-step-1', 'templates-step-2', 'templates-step-3'];
const WLANS = ['Wlans', 'Wizard', 'step1', 'step2', 'step3', 'step4'];

export default {
  COMMON: ['Dashboard', 'Login', 'Page404', ...SETTINGS, 'Support'],
  STATISTICS: ['Statsystem', 'Statcpes', 'Statwlans', 'Statclients', 'Statexport', 'Statreports', 'SNMPStat'],
  STATISTICSFORMARKETER: ['Statsystem', 'Statcpes', 'Statwlans', 'Statclients', 'Statexport', 'Statreports'],
  MONITORING: [
    'Events',
    ...RULES,
    'Sessions',
    'WifiAnalyzer',
    'ClientRF',
    'ClientDistance',
    'SNMPMonitoringConfig',
    'DITStat',
    'DBState',
    'PeriodicTasks'
  ],
  PORTAL: ['PortalDashboard', ...PORTAL_CONFIGURATION, ...PORTAL_ANALYTICS, ...PORTAL_PAID_INTERNET],
  PORTAL_CONFIGURATION,
  PORTAL_ANALYTICS,
  PORTAL_PAID_INTERNET,
  SYSTEM: [
    'Aps',
    'Controllers',
    'ExternalAPs',
    'RRM',
    ...TEMPLATES,
    ...WLANS,
    'Clients',
    ...RADIUS,
    ...HOTSPOT,
    ...FIREWALL,
    ...GUEST_CONTROL,
    'System',
    'ConfigExportImport'
  ],
  ANALYTICS: ['Marketing', ...MAPS, 'Radar'],
  HOTEL: ['PortalHotelVouchers', 'UserAccounts', 'PortalBirthdays', 'Support'],
  ADVERTISING_AGENT: ['PortalAdsNew', 'Account', 'Settings', 'Login', 'Page404', 'PortalProfilesNew'],
  ADMINISTRATION: ['Administration', 'SNMP', 'SNMPGeneral', 'SNMPViews', 'SNMPCommunityStrings', 'SNMPUserGroups', 'SNMPUsers', 'SNMPHosts', 'SNMPWirelessTraps', 'Time']
};
