<template>
  <div>
    <Modal
      centered-position
      :backdrop="false"
      title="New Version Info Modal"
      class="modal-info new-version-info-modal"
      v-model="isShowingNewVersionInfoModal"
      effect="fade/zoom"
    >
      <h4 slot="modal-title" class="modal-title">{{ $t('NewVersionChecker.modalHeader') }}</h4>
      <div>
        <p>{{ $t('NewVersionChecker.modalText') }}</p>
      </div>
      <div slot="modal-footer" class="modal-footer modal-btn-right">
        <WButton info @click="reloadPage">
          {{ $t('NewVersionChecker.modalReloadButton') }}
        </WButton>
        <WButton secondary @click="hideNewVersionInfoModalAndClearBuildOnServerHash">
          {{ $t('general.close') }}
        </WButton>
      </div>
    </Modal>
  </div>
</template>

<script>
import Vue from 'vue';
import VueNotifications from 'vue-notifications';
import Modal from './Modal.vue';

// Как оно все работает:
// 1. При билде через npm build после него запускается скриптик src/post-build.js, который ищет в js файлах билда файл index и вытягивает из его имени хэш собранного билда.
// 2. После того как вынут хэш, скриптик его записывает в файл version.json, который создается в dist рядом с index.html
// 3. Также этот скрипт ищет по плейсхолдеру  {{{{{%POST_BUILD_ENTERS_HASH_HERE%}}}}} в фалах и заменяет этот плейсхолдер полученым ранее хэшем. (фактически в файле App.vue)
// 4. На хуке created компонента App.vue хэш пропихивается в vue store
// 5. Таким образом хэш записан в 2 места -  в vue store и в version.js
// 6. Далее включается в работу компонент NewVersionChecker (внутри Full.vue). ЭТот компонент периодически (раз в 2 минуты) чекает с помощью axios файл version.json. (Также проверка происходит если переключится на вкладку с открытой админкой после какой-нибудь другой вкладки)
// 7. Если хэш в сторе и полученный из файла не совпадают, значит билд менялся, тогда NewVersionCheck показывает модалку с сообщением о новой версии и кнопочкой Обновить, которая релоадит  страничку

export default {
  name: 'NewVersionChecker',
  components: { Modal },
  data() {
    return {
      isShowingNewVersionInfoModal: false,
      buildOnServerHash: undefined,
      buildOnServerHashCheckInterval: 1000 * 60 * 2 // 2 минуты в милисекундах - интервал для проверки
    };
  },
  // created() {
  //   console.log('New Build Checker enabled');
  //   // this.checkBuildOnServerHash();
  //   // this.checkBuildOnServerHash();
  //   this.buildOnServerHashCheckerFuncIntervalHandler = setInterval(() => {
  //     this.checkBuildOnServerHash();
  //   }, this.buildOnServerHashCheckInterval);
  // },
  beforeMount() {
    console.log('New Build Checker enabled');
    // this.checkBuildOnServerHash();
    this.checkBuildOnServerHash();
    // добавляем периодическую проверку хэша билда в version.json
    this.buildOnServerHashCheckerFuncIntervalHandler = setInterval(() => {
      this.checkBuildOnServerHash();
    }, this.buildOnServerHashCheckInterval);
    // добавляем  проверку хэша билда в version.json, если вкладка с админкой стала активна
    // (открыто несколько вкладок в браузере, была открыта другая, кликнули обратно на вкладку с админкой)
    document.addEventListener('visibilitychange', this.visibilityChangeEventHandler);
  },
  beforeDestroy() {
    console.log('New Build Checker disabled');
    if (this.buildOnServerHashCheckerFuncIntervalHandler !== undefined) {
      clearInterval(this.buildOnServerHashCheckerFuncIntervalHandler);
    }
    document.removeEventListener('visibilitychange', this.visibilityChangeEventHandler);
  },
  computed: {
    currentBuildHash() {
      return this.$store.state.currentBuildHash;
    },
    buildOnServerHashFileUrl() {
      return `${document.location.origin}/version.json`;
    }
  },
  methods: {
    visibilityChangeEventHandler() {
      try {
        if (document.visibilityState === 'visible') {
          // console.log('visability equal visible');
          this.checkBuildOnServerHash();
        }
      } catch (e) {
        console.log(e);
      }
    },
    printToConsoleLogHashesInfo(currentBuildHash, buildHashFromResponse) {
      try {
        console.log(new Date());
        console.log('Checking build version on server', this.buildOnServerHashFileUrl);
        console.log('Build Hash From version.json:', buildHashFromResponse);
        console.log('Current Build Hash From $store:', currentBuildHash);
        if (currentBuildHash === buildHashFromResponse) {
          console.log('Hashes are equal');
        } else {
          console.log('Hashes NOT equal, new build detected');
        }
        console.log('--------------------');
      } catch (e) {
        console.log(e);
      }
    },
    hideNewVersionInfoModalAndClearBuildOnServerHash() {
      this.hideNewVersionInfoModal();
      this.clearBuildOnServerHash();
    },
    hideNewVersionInfoModal() {
      this.isShowingNewVersionInfoModal = false;
    },
    showNewVersionModal() {
      this.isShowingNewVersionInfoModal = true;
    },
    clearBuildOnServerHash() {
      this.buildOnServerHash = undefined;
    },
    // reloadPage() {
    //   // document.location.reload();
    //   window.location.href = document.location.origin;
    // },
    reloadPage() {
      // console.log(this.$route);
      if (this.$route.name === 'Dashboard') {
        // console.log('Dahboard')
        setTimeout(() => {
          document.location.reload(true);
        }, 10);
      } else {
        // console.log('No dashboard')
        this.$router
          .push({ name: 'Dashboard' })
          .then(() => {
            setTimeout(() => {
              document.location.reload(true);
            }, 10);
          })
          .catch(() => {
            setTimeout(() => {
              document.location.reload(true);
            }, 10);
          });
      }
    },
    checkBuildOnServerHash() {
      // const data = {};
      Vue.axios
        .get(`${this.buildOnServerHashFileUrl}?t=${new Date().getTime()}`, {
          headers: {
            'Content-Type': 'application/json'
          }
        })
        .then(
          (response) => {
            // console.log(response.data);
            const responseDataAsJSON = JSON.parse(JSON.stringify(response.data));
            // console.log(responseDataAsJSON);
            const buildHashFromResponse = responseDataAsJSON.hash;
            // console.log('Checking build version on server', new Date())
            // console.log('Build Hash From version.json:', buildHashFromResponse);
            // console.log('Current Build Hash From $store:', this.currentBuildHash);
            this.buildOnServerHash = buildHashFromResponse;
            this.printToConsoleLogHashesInfo(this.currentBuildHash, buildHashFromResponse);
          },
          (err) => {
            this.clearBuildOnServerHash();
            console.log(err);
            // VueNotifications.error({ message: err });

            // show errors in the console instead of the alert window
            if (
              Object.prototype.hasOwnProperty.call(window, 'isShowAxiosErrorInConsole') &&
              typeof window.isShowAxiosErrorInConsole === 'function' &&
              window.isShowAxiosErrorInConsole(err)
            ) {
              window.showAxiosErrInConsole(err);
            } else {
              VueNotifications.error({ message: err });
            }
          }
        );
    }
  },
  watch: {
    buildOnServerHash() {
      // console.log('watch')
      // console.log(this.buildOnServerHash)
      // console.log(this.currentBuildHash)
      try {
        if (this.buildOnServerHash !== undefined && this.buildOnServerHash !== this.currentBuildHash) {
          this.showNewVersionModal();
        } else {
          this.hideNewVersionInfoModal();
        }
      } catch (e) {
        console.log(e);
        this.hideNewVersionInfoModal();
      }
    }
  }
};
</script>

<style scoped>
.new-version-info-modal {
  z-index: 1000000 !important;
}
</style>
