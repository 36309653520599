import { STORAGE_KEY_ACCESS_TOKEN } from '../config';
import { ObjectStorage } from './storage';

/**
 * Класс, представляющий утилиту для управления токенами аутентификации.
 */

export default class AuthTokenMaster {
  /**
   * Получает токен доступа из хранилища.
   * @returns {string|null} - Токен доступа или null, если отсутствует.
   */
  static getAccessToken() {
    return ObjectStorage.getItem(STORAGE_KEY_ACCESS_TOKEN);
  }

  // у нас нет refresh-token. Это заготовка, если он появится
  // /**
  //  * Получает токен обновления из хранилища.
  //  * @returns {string|null} - Токен обновления или null, если отсутствует.
  //  */
  // static getRefreshToken() {
  //   return ObjectStorage.getItem(REFRESH_TOKEN_KEY);
  // }

  /**
   * Устанавливает токен доступа в хранилище.
   * @param {string} newToken - Новый токен доступа для установки.
   */
  static setAccessToken(newToken) {
    ObjectStorage.setItem(STORAGE_KEY_ACCESS_TOKEN, newToken);
  }

  // у нас нет refresh-token. Это заготовка, если он появится
  // /**
  //  * Устанавливает токен обновления в хранилище.
  //  * @param {string} newToken - Новый токен обновления для установки.
  //  */
  // static setRefreshToken(newToken) {
  //   ObjectStorage.setItem(REFRESH_TOKEN_KEY, newToken);
  // }

  /**
   * Очищает как токен доступа, так и токен обновления из хранилища.
   */
  static clearTokens() {
    ObjectStorage.removeItem(STORAGE_KEY_ACCESS_TOKEN);
    // ObjectStorage.removeItem(REFRESH_TOKEN_KEY);
  }
}
