/**
 * Саб-модуль Vuex mainStage.
 * !!! используется как модуль только в autologout
 *
 * @module mainStage
 * @namespace
 *
 * Здесь размещаются стейты, геттеры, мутации и экшны, специфические для стадии.
 * Для использования в компонентах и других модулях Vuex.
 *
 * doc о модулях vuex:
 * https://v3.vuex.vuejs.org/ru/guide/modules.html
 */
import { createTimeoutWorker } from '@/workers';

import {
  LOGOUT_AFTER_USER_INACTIVITY_MODE
} from '@/config';

const THROTTLER_TIMEOUT_MS_VALUE = 2000;
const MAIN_TIMEOUT_MS_VALUE = LOGOUT_AFTER_USER_INACTIVITY_MODE.timeout.mainStage - THROTTLER_TIMEOUT_MS_VALUE;

const mainStage = {
  state: () => ({
    mainStage: {
      timeoutWorker: null
    }
  }),
  getters: {
    /**
     * Проверяет mainStage сейчас или нет.
     * @returns {boolean}
     */
    isMainStage: (state) => Boolean(state.mainStage.timeoutWorker)
  },
  mutations: {
    /**
     * Устанавливает worker с таймаутом mainStage и удаляет старый, если он был.
     *
     * @param {State} state - Состояние модуля Vuex.
     * @param {Worker} newWorker - Новый worker с таймаутом.
     */
    setMainStageTimeoutWorker(state, newWorker) {
      if (state.mainStage.timeoutWorker) {
        state.mainStage.timeoutWorker.terminate();
      }

      state.mainStage.timeoutWorker = newWorker;
    }
  },
  actions: {
    /**
     * создает инстанс вебворкера с таймаутом.
     * !!! Напрямую в компонентах не используется
     *
     * @param {Object} context - Контекст хранилища Vuex.
     * @param {function} context.commit - Функция для вызова мутаций.
     * @param {function} context.getters - Функция для получения геттеров.
     * @param {function} context.dispatch - Функция для вызова других экшнов.
    */
    _createMainTimeoutWebWorker({ commit }) {
      const worker = createTimeoutWorker();
      commit('setMainStageTimeoutWorker', worker);
    },
    /**
     * Запускает mainStage.
     * !!! Вызывается только из _startThrottlerStage
     * !!! Напрямую в компонентах не используется
     *
     * @param {Object} context - Контекст хранилища Vuex.
     * @param {function} context.commit - Функция для вызова мутаций.
     * @param {function} context.getters - Функция для получения геттеров.
     * @param {function} context.dispatch - Функция для вызова других экшнов.
    */
    _startMainStage({ state, dispatch }) {
      dispatch('_createMainTimeoutWebWorker');

      state.mainStage.timeoutWorker.postMessage({
        timeout: MAIN_TIMEOUT_MS_VALUE
      });

      state.mainStage.timeoutWorker.onmessage = (event) => {
        const { isDone } = event.data;

        if (isDone) {
          dispatch('_resetMainStage');
          dispatch('_startExpirationStage');
        }
      };
    },

    /**
     * Останавливает и сбрасывает mainStage.
     * !!! Напрямую в компонентах не используется
     *
     * @param {Object} context - Контекст хранилища Vuex.
     * @param {function} context.commit - Функция для вызова мутаций.
     * @param {function} context.getters - Функция для получения геттеров.
     * @param {function} context.dispatch - Функция для вызова других экшнов.
    */
    _resetMainStage({ state, commit }) {
      const { mainStage } = state;

      if (mainStage.timeoutWorker) {
        commit('setMainStageTimeoutWorker', null);
      }
    }
  }
};

export default mainStage;
