/* eslint-disable no-unused-vars */

/**
 * Локальные валидаторы vee-validate для компонента APProfileForm.
 */

const getAPProfileFormValidators = (i18nInstance) => ({
  uniqueProfileName: (forbiddenProfileNames) => ({
    getMessage: field => {
      const message = i18nInstance.t('apjoin.inputError.uniqueProfileName');
      return message;
    },
    validate: value => {
      const condition = forbiddenProfileNames
        .map(value => value.toLocaleLowerCase())
        .includes(value.toLocaleLowerCase());

      return !condition;
    }
  }),
  profileNameMask: () => ({
    getMessage: field => {
      const message = i18nInstance.t('apjoin.inputError.profileNameMask');
      return message;
    },
    validate: value => {
      const customValidationRegex = /^[a-zA-Zа-яА-Я0-9!"#$%&'()*+,-./:;<=>?@[\\\]^_`{|}~]+$/;
      // Проверяем, чтобы не было пробелов в начале и в конце
      if (value.trim() !== value) {
        return false;
      }
      // Проверяем, чтобы строка не содержала несколько слов, разделенных пробелом
      if (/\s/.test(value)) {
        return false;
      }
      // Проверяем соответствие регулярному выражению
      return customValidationRegex.test(value);
    }
  }),
  profileDescriptionMask: () => ({
    getMessage: field => {
      const message = i18nInstance.t('apjoin.inputError.profileDescriptionMask');
      return message;
    },
    validate(value) {
      const customValidationRegex = /^[a-zA-Zа-яА-Я0-9!"#$%&'()*+,-./:;<=>?@[\\\]^_`{|}~ ]+$/;
      // Проверяем, чтобы не было пробелов в начале и в конце
      if (value.trim() !== value) {
        return false;
      }
      // Проверяем соответствие регулярному выражению
      return customValidationRegex.test(value);
    }
  }),
  managementUsernameMask: () => ({
    getMessage: field => {
      const message = i18nInstance.t('apjoin.inputError.managementUsernameMask');
      return message;
    },
    validate: value => {
      const customValidationRegex = /^[a-zA-Z0-9_.-]+$/;
      // Проверяем, чтобы не было пробелов в начале и в конце
      if (value.trim() !== value) {
        return false;
      }
      // Проверяем соответствие регулярному выражению
      return customValidationRegex.test(value);
    }
  }),
  managementPasswordMask: () => ({
    getMessage: field => {
      const message = i18nInstance.t('apjoin.inputError.managementPasswordMask');
      return message;
    },
    validate: value => {
      // Проверяем, чтобы строка содержала только английские буквы и цифры
      const onlyAlphanumeric = /^[a-zA-Z0-9]+$/.test(value);
      if (!onlyAlphanumeric) {
        return false;
      }
      // Проверяем, чтобы в строке был хотя бы один символ в верхнем регистре
      const hasUpperCase = /[A-Z]/.test(value);
      if (!hasUpperCase) {
        return false;
      }
      // Проверяем, чтобы в строке был хотя бы один символ в нижнем регистре
      const hasLowerCase = /[a-z]/.test(value);
      if (!hasLowerCase) {
        return false;
      }
      // Проверяем, чтобы в строке была хотя бы одна цифра
      const hasDigit = /[0-9]/.test(value);
      if (!hasDigit) {
        return false;
      }
      return true;
    }
  }),
  sshPortRange: () => ({
    getMessage: field => {
      const message = i18nInstance.t('apjoin.inputError.sshPortMask');
      return message;
    },
    validate: value => {
      const number = Number.parseInt(value, 10);

      if (Number.isNaN(number)) {
        return false;
      }

      // Проверка диапазона (от 1024 до 49151 или 22)
      return (number >= 1024 && number <= 49151) || number === 22;
    }
  })
});

export {
  getAPProfileFormValidators
};
