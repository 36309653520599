<template>
  <div class="operation"
    :class="{
      'error': operation.status === 'error',
      'pending': operation.status === 'pending'
    }"
  >
    <div class="operation-content">
      <i class="fa fa-lg"
        :class="{
          'fa-circle-o-notch fa-spin info': operation.status === 'pending',
          'fa-check-circle-o success': operation.status === 'success',
          'fa-stop-circle-o danger': operation.status === 'error'
        }"
      ></i>
      <div>
        <p>
          <strong>{{ getOperationType(operation.type) }}</strong>
        </p>
        <p class="muted"
          v-if="operation.status === 'error' || operation.status === 'pending'"
        >
          <small>{{ operation.description }}</small>
        </p>
        <p>
          <small class="muted">
            <i class="fa fa-clock-o"></i>
            {{ operation.insert_time | timeFilter }}
          </small>
        </p>
        <p>
          <strong>{{ $t('header.type') }}: {{ operation.model.toUpperCase() }}</strong>
        </p>
        <ul>
          <li
            v-for="obj in getName(operation.model, operation.model_ids)"
            v-tooltip.bottom="obj.id"
            :key="obj.id"
          >
            <small>{{ obj.name }}</small>
          </li>
        </ul>
      </div>
    </div>
    <div>
      <WButton danger outline sm rounded
        v-if="operation.id"
        @click="handleDelete(operation.id)"
        v-tooltip.top-center="$t('header.tooltipDelete')"
      >
        <i class="fa fa-close"></i>
      </WButton>
    </div>
  </div>
</template>

<script>
import commonService from '@/services/commonService';
import helpers from '@/helpers';


export default {
  name: 'Operation',
  data() {
    return {
    };
  },
  filters: {
    timeFilter(value) {
      return moment(value * 1000).format(' D MMM YYYY, H:mm:ss ');
    }
  },
  props: {
    operation: {
      type: Object,
      required: true
    }
  },
  computed: {
    noDataCaption() {
      return helpers.capitalizeFirstLetter(this.$t('general.noData'));
    }
  },
  methods: {
    getName(model, ids) {
      // todo refactor
      let modelIds = ids;

      if (!modelIds) {
        return [];
      }
      if (model === 'wlan') {
        modelIds = ids.map((modelId) => ({
          name: commonService.wlanNamebyId(modelId) || this.noDataCaption,
          id: modelId
        }));
      }
      if (model === 'cpe') {
        modelIds = ids.map((modelId) => ({
          name: commonService.cpeNamebyId(modelId) || this.noDataCaption,
          id: modelId
        }));
      }
      if (model === 'radius') {
        modelIds = ids.map((modelId) => ({
          name: commonService.radiusNamebyId(modelId) || this.noDataCaption,
          id: modelId
        }));
      }
      if (model === 'l2-chain') {
        modelIds = ids.map((modelId) => ({
          name: commonService.firewallNamebyId(modelId) || this.noDataCaption,
          id: modelId
        }));
      }
      if (model === 'hotspot-profile') {
        modelIds = ids.map((modelId) => ({
          name: commonService.hotspotNamebyId(modelId) || this.noDataCaption,
          id: modelId
        }));
      }
      if (model === 'controller') {
        modelIds = ids.map((modelId) => ({
          name: commonService.controllerNamebyId(modelId) || this.noDataCaption,
          id: modelId
        }));
      }
      if (model === 'captive-redirect') {
        modelIds = ids.map((modelId) => ({
          name: commonService.redirectNamebyId(modelId) || this.noDataCaption,
          id: modelId
        }));
      }
      return modelIds;
    },
    getOperationType(type) {
      switch (type) {
        case 'U':
          return this.$t('header.typeUPDATE');
        case 'D':
          return this.$t('header.typeDELETE');
        case 'C':
          return this.$t('header.typeCREATE');
        default:
          return type;
      }
    },
    handleDelete(id) {
      this.$emit('delete', id);
    }
  }
};
</script>

<style lang="css" scoped>

ul {
  list-style-type: none;
  padding: 0;
}

p {
  margin: 0;
}

.operation {
  display: flex;
  justify-content: space-between;
}

.operation-content {
  display: flex;
  justify-content: center;
  align-items: baseline;
  gap: 8px;
}

.info {
  color: var(--brand-info);
}

.success {
  color: var(--brand-success);
}
.danger {
  color: var(--brand-danger);
}

.muted {
  color: var(--gray-light);
}

</style>
