import { BRANDING, BRANDS } from '@/config';
import { ThemeMaster } from '@/utils';

export const themeControl = async () => {
  if (BRANDING === BRANDS.beeline) {
    if (ThemeMaster.isCachedThemeDark()) {
      await import('../public/static/scss/style-beeline-dark.scss');
    } else {
      await import('../public/static/scss/style-beeline.scss');
    }
  }
  if (BRANDING === BRANDS.mts) {
    if (ThemeMaster.isCachedThemeDark()) {
      await import('../public/static/scss/style-mts-dark.scss');
    } else {
      await import('../public/static/scss/style-mts.scss');
    }
  }
  if (BRANDING === BRANDS.default || BRANDING === BRANDS.qtech || BRANDING === BRANDS.aquarius) {
    if (ThemeMaster.isCachedThemeDark()) {
      await import('../public/static/scss/style-dark.scss');
    } else {
      await import('../public/static/scss/style.scss');
    }
  }
};
