<template>
  <section v-if="isExternalAPData">
    <div class="h4 m-0 flex-column">
      <span>{{ iscurrentExternalAPData ? currentExternalAPData.name : $t('externalAP.externalAPHeaderAdd') }}</span>
    </div>
    <div v-if="iscurrentExternalAPData">
      <span
        class="badge text-sm badge-light"
        :class="{
          'badge-success': currentExternalAPData.status === 'connected',
          'badge-danger': currentExternalAPData.status === 'error',
          'badge-default': currentExternalAPData.status === 'disconnected' || currentExternalAPData.status === 'empty',
          'badge-primary': currentExternalAPData.status === 'provision'
        }"
      >
        {{ currentExternalAPData.status }}
      </span>
    </div>

    <ul class="list-unstyled" v-if="iscurrentExternalAPData">
      <li v-if="externalAPData.id">
        <small>ID:&nbsp;</small>
        <small class="text-muted">{{ externalAPData.id }}</small>
      </li>
      <li>
        <small>MAC:&nbsp;</small>
        <small v-if="externalAPData.mac" class="text-muted">{{ helpers.getFormatMac(externalAPData.mac) }}</small>
        <small v-else class="text-muted">{{ $t('general.noData') }}</small>
      </li>
      <li>
        <small>{{ $t('externalAP.serial') }}:&nbsp;</small>
        <small v-if="externalAPData.serial" class="text-muted">{{ externalAPData.serial }}</small>
        <small v-else class="text-muted">{{ $t('general.noData') }}</small>
      </li>
      <li>
        <small>{{ $t('externalAP.vendor') }}:&nbsp;</small>
        <small v-if="externalAPData.vendor" class="text-muted">{{ externalAPData.vendor }}</small>
        <small v-else class="text-muted">{{ $t('general.noData') }}</small>
      </li>

      <li>
        <small>{{ $t('externalAP.fw_version') }}:&nbsp;</small>
        <small v-if="externalAPData.fw_version" class="text-muted">{{ externalAPData.fw_version }}</small>
        <small v-else class="text-muted">{{ $t('general.noData') }}</small>
      </li>
    </ul>

    <small class="text-muted" v-if="!isDisable && iscurrentExternalAPData">{{ $t('general.editingMode') }}</small>

    <div class="form-group mt-1">
      <label for="name">{{ $t('externalAP.name') }}</label>
      <input
        data-vv-validate-on="none"
        @input="nameInputHandler"
        :data-vv-as="$t('externalAP.name')"
        type="text"
        name="name"
        id="name"
        :disabled="isDisable"
        v-validate="'required|max:150|min:1'"
        :class="{ input: true, 'is-danger': errors.has('name') }"
        :placeholder="this.$t('general.name')"
        class="form-control"
        v-model="externalAPData.name"
      />
      <span v-show="errors.has('name')" class="help is-danger location-danger">{{ errors.first('name') }}</span>
    </div>

    <div class="form-group">
      <label for="description">{{ $t('externalAP.description') }}</label>
      <input
        data-vv-validate-on="none"
        @input="descriptionInputHandler"
        type="text"
        :data-vv-as="$t('externalAP.description')"
        name="description"
        id="description"
        :disabled="isDisable"
        v-validate="'max:150'"
        :class="{ input: true, 'is-danger': errors.has('description') }"
        :placeholder="this.$t('general.description')"
        class="form-control"
        v-model="externalAPData.description"
      />
      <span v-show="errors.has('description')" class="help is-danger location-danger">
        {{ errors.first('description') }}
      </span>
    </div>

    <div class="form-group">
      <label for="ipAddress">{{ $t('externalAP.ip_addr') }}</label>
      <input
        data-vv-validate-on="none"
        @input="ipAddressInputHandler"
        type="text"
        :data-vv-as="$t('externalAP.ip_addr')"
        name="ipAddress"
        id="ipAddress"
        :disabled="isDisable"
        v-validate="'required|ipv4'"
        :class="{ input: true, 'is-danger': errors.has('ipAddress') }"
        placeholder="123.123.123.123"
        class="form-control"
        v-model="externalAPData.ip_addr"
      />
      <span v-show="errors.has('ipAddress')" class="help is-danger location-danger">
        {{ errors.first('ipAddress') }}
      </span>
    </div>

    <div class="form-group mt-2">
      <div class="h6">{{ $t('externalAP.ssh') }}</div>
    </div>
    <div class="ml-0">
      <div class="form-group">
        <label for="sshUsername">{{ $t('externalAP.username') }}</label>
        <input
          data-vv-validate-on="none"
          @input="sshUsernameInputHandler"
          type="text"
          :data-vv-as="$t('externalAP.username')"
          name="sshUsername"
          id="sshUsername"
          :disabled="isDisable"
          v-validate="'required'"
          :class="{ input: true, 'is-danger': errors.has('sshUsername') }"
          placeholder=""
          class="form-control"
          v-model="externalAPData.ssh.username"
        />
        <span v-show="errors.has('sshUsername')" class="help is-danger location-danger">
          {{ errors.first('sshUsername') }}
        </span>
      </div>

      <div class="form-group">
        <label for="sshPassword">{{ $t('externalAP.password') }}</label>
        <input
          data-vv-validate-on="none"
          @input="sshPasswordInputHandler"
          type="text"
          :data-vv-as="$t('externalAP.password')"
          name="sshPassword"
          id="sshPassword"
          :disabled="isDisable"
          :class="{ input: true, 'is-danger': errors.has('sshPassword') || isBothSSHPasswordAndSSHKeyExists }"
          placeholder=""
          v-validate ="{ rules: { required: this.isSSHPasswordRequired} }"
          class="form-control"
          v-model="externalAPData.ssh.password"
        />
        <span v-show="errors.has('sshPassword')" class="help is-danger location-danger">
          <span>
            {{$t('externalAP.sshKeyORPasswordMustNotEmptyError')}}
          </span>
<!--          {{ errors.first('sshPassword') }}-->
        </span>
        <span v-show="isBothSSHPasswordAndSSHKeyExists" class="help is-danger location-danger">
          {{$t('externalAP.sshKeyORPasswordOnlyOneError')}}
        </span>
      </div>
      <div class="form-group">
        <label for="sshKey">{{ $t('externalAP.sshKey') }}</label>
        <textarea
          data-vv-validate-on="none"
          @input="sshKeyInputHandler"
          type="text"
          :data-vv-as="$t('externalAP.sshKey')"
          name="sshKey"
          id="sshKey"
          rows="7"
          :disabled="isDisable"
          :class="{ input: true, 'is-danger': errors.has('sshKey') || isBothSSHPasswordAndSSHKeyExists }"
          placeholder=""
          v-validate ="{ rules: { required: this.isSSHKeyRequired} }"
          class="form-control"
          v-model="externalAPData.ssh.key"
        />
        <span v-show="errors.has('sshKey')" class="help is-danger location-danger">
          <span>
            {{$t('externalAP.sshKeyORPasswordMustNotEmptyError')}}
          </span>
<!--          {{ errors.first('sshKey') }}-->
        </span>
        <span v-show="isBothSSHPasswordAndSSHKeyExists" class="help is-danger location-danger">
          {{$t('externalAP.sshKeyORPasswordOnlyOneError')}}
        </span>
      </div>
      <div class="form-group">
        <label for="sshPort">{{ $t('externalAP.port') }}</label>
        <input
          data-vv-validate-on="none"
          @input="sshPortInputHandler"
          type="number"
          :data-vv-as="$t('externalAP.port')"
          name="sshPort"
          id="sshPort"
          :disabled="isDisable"
          :class="{ input: true, 'is-danger': errors.has('sshPort') }"
          placeholder=""
          v-validate="'required|numeric|between:1,65535'"
          class="form-control"
          v-model.number="externalAPData.ssh.port"
        />
        <span v-show="errors.has('sshPort')" class="help is-danger location-danger">
          {{ errors.first('sshPort') }}
        </span>
      </div>
    </div>

    <div class="form-group mt-2">
      <div class="h6">{{ $t('externalAP.snmp') }}</div>
    </div>
    <div class="ml-0">
      <div class="form-group">
        <label for="snmpCommunity">{{ $t('externalAP.snmpCommunity') }}</label>
        <input
          data-vv-validate-on="none"
          @input="snmpCommunityInputHandler"
          type="text"
          :data-vv-as="$t('externalAP.snmpCommunity')"
          name="snmpCommunity"
          id="snmpCommunity"
          :disabled="isDisable"
          :class="{ input: true, 'is-danger': errors.has('snmpCommunity') }"
          placeholder=""
          class="form-control"
          v-model="externalAPData.snmp.community"
        />
        <span v-show="errors.has('snmpCommunity')" class="help is-danger location-danger">
          {{ errors.first('snmpCommunity') }}
        </span>
      </div>
<!--      <div class="form-group">-->
<!--        <label for="snmpVersion">{{ $t('externalAP.snmpVersion') }}</label>-->
<!--        <input-->
<!--          data-vv-validate-on="none"-->
<!--          @input="snmpVersionInputHandler"-->
<!--          type="text"-->
<!--          :data-vv-as="$t('externalAP.snmpVersion')"-->
<!--          name="snmpVersion"-->
<!--          id="snmpVersion"-->
<!--          :disabled="isDisable"-->
<!--          :class="{ input: true, 'is-danger': errors.has('snmpVersion') }"-->
<!--          placeholder=""-->
<!--          class="form-control"-->
<!--          v-model="externalAPData.snmp.version"-->
<!--        />-->
<!--        <span v-show="errors.has('snmpVersion')" class="help is-danger location-danger">-->
<!--          {{ errors.first('snmpVersion') }}-->
<!--        </span>-->
<!--      </div>-->
      <div class="form-group">
        <label for="snmpVersion">{{ $t('externalAP.snmpVersion') }}</label>
        <select
          data-vv-validate-on="none"
          @change="snmpVersionInputHandler"
          type="text"
          :data-vv-as="$t('externalAP.snmpVersion')"
          name="snmpVersion"
          id="snmpVersion"
          v-validate="'required'"
          :disabled="isDisable"
          :class="{ input: true, 'is-danger': errors.has('snmpVersion') }"
          placeholder=""
          class="form-control"
          v-model="externalAPData.snmp.version"
        >
          <option :key="version" v-for="version in snmpVersionsAsKeysObject" :value="version">
            {{ snmpVersionsAsObject[version] }}
          </option>
<!--          <option value="">-->
<!--          </option>-->
        </select>
        <span v-show="errors.has('snmpVersion')" class="help is-danger location-danger">
          {{ errors.first('snmpVersion') }}
        </span>
      </div>
      <div class="form-group">
        <label for="snmpPort">{{ $t('externalAP.snmpPort') }}</label>
        <input
          data-vv-validate-on="none"
          @input="snmpPortInputHandler"
          type="number"
          :data-vv-as="$t('externalAP.snmpPort')"
          name="snmpPort"
          id="snmpPort"
          :disabled="isDisable"
          :class="{ input: true, 'is-danger': errors.has('snmpPort') }"
          placeholder=""
          class="form-control"
          v-validate="'required|numeric|between:1,65535'"
          v-model.number="externalAPData.snmp.port"
        />
        <span v-show="errors.has('snmpPort')" class="help is-danger location-danger">
          {{ errors.first('snmpPort') }}
        </span>
      </div>
      <div class="form-group d-flex flex-column">
        <div>
          <label for="set_trap_server">{{ $t('externalAP.setTrapServer') }}</label>
        </div>

        <Switch-component
          v-model="externalAPData.snmp.set_trap_server"
          :disabled="isDisable"
          :label="''"
          id="set_trap_server"
        />
      </div>
    </div>
  </section>
</template>

<script>
import Vue from 'vue';

import helpers from '../../helpers';
//  import SwitchComponent from '../Universal/Switch-component.vue';
import SwitchComponent from '../Universal/Switch-component.vue';

// keys for api cases, values for UI show
const SNMP_VERSIONS_LIST = {'Version1': 'Version1', 'Version2c' : 'Version2c', 'Version3': 'Version3' }

export default {
  name: 'externalAPConfig',
  inject: ['$validator'],
  components: {
    SwitchComponent
  },
  props: {
    externalAPData: {
      type: Object
    },
    currentExternalAPData: {
      type: Object
    },
    isNewexternalAP: {
      type: Boolean
    },
    isDisable: {
      type: Boolean
    },
    errorFromModal: {
      type: Array
    }
  },
  data() {
    return {};
  },
  computed: {
    snmpVersionsAsObject() {
      return SNMP_VERSIONS_LIST;
    },
    snmpVersionsAsKeysObject() {
      return Object.keys(this.snmpVersionsAsObject);
    },
    isBothSSHPasswordAndSSHKeyExists() {
      if (this.externalAPData.ssh.key !== '' &&
        this.externalAPData.ssh.key !== undefined &&
        this.externalAPData.ssh.password !== '' &&
        this.externalAPData.ssh.password !== undefined) {
        return true;
      }
      return false;
    },
    isSSHPasswordRequired() {
      if (this.externalAPData.ssh.key === '' || this.externalAPData.ssh.key === undefined) {
        return true;
      }
      return false;
    },
    isSSHKeyRequired() {
      if (this.externalAPData.ssh.password === '' || this.externalAPData.ssh.password === undefined) {
        return true;
      }
      return false;
    },
    helpers() {
      return helpers;
    },
    iscurrentExternalAPData() {
      return Object.keys(this.currentExternalAPData).length;
    },
    isExternalAPData() {
      return Object.keys(this.externalAPData).length;
    }
  },
  watch: {
    isBothSSHPasswordAndSSHKeyExists() {
      this.$emit('isBothSSHPasswordAndSSHKeyExistsChanged', this.isBothSSHPasswordAndSSHKeyExists)
    }
  },
  methods: {
    nameInputHandler() {
      this.clearErrorsForInput('name');
    },
    descriptionInputHandler() {
      this.clearErrorsForInput('description');
    },
    ipAddressInputHandler() {
      this.clearErrorsForInput('ipAddress');
    },
    sshUsernameInputHandler() {
      this.clearErrorsForInput('sshUsername');
    },
    sshPasswordInputHandler() {
      this.clearErrorsForInput('sshPassword');
      this.clearErrorsForInput('sshKey');
    },
    sshKeyInputHandler() {
      this.clearErrorsForInput('sshKey');
      this.clearErrorsForInput('sshPassword');
    },
    sshPortInputHandler() {
      this.clearErrorsForInput('sshPort');
    },
    snmpCommunityInputHandler() {
      this.clearErrorsForInput('snmpCommunity');
    },
    snmpVersionInputHandler() {
      // console.log('version selcted')
      this.clearErrorsForInput('snmpVersion');
    },
    snmpPortInputHandler() {
      this.clearErrorsForInput('snmpPort');
    },

    clearErrorsForInput(inputName) {
      if (Object.prototype.hasOwnProperty.call(this, 'errors')) {
        if (this.errors.has(inputName)) {
          this.errors.remove(inputName);
        }
      }
    }
  },
  created() {}
};
</script>

<style lang="scss" scoped></style>
