<template>
  <div class="row">
    <div class="col-lg-12">
      <div class="card">
        <div class="card-block">
          <div class="h4 mb-xs mb-2">{{ $t('usersDemoWizard.step2Header') }}</div>
          <form action="">
            <div class="form-group"><img src="" alt="" class="map-preview img img-responsive" /></div>
            <div class="form-group">
              <label for="username" class="required-mark">{{ $t('users.username') }}</label>
              <input
                type="text"
                name="newuser-username"
                :placeholder="$t('users.enterUsernamePlaceHolder')"
                id="username"
                autocomplete="off"
                class="form-control"
                v-validate="'required|max:64|min:6'"
                data-vv-validate-on="input"
                :class="{ input: true, 'is-danger': errors.has('newuser-username') }"
                v-model="newUserData.username"
                :data-vv-as="$t('users.username')"
              />
              <span v-show="errors.has('newuser-username')" class="help is-danger">
                {{ errors.first('newuser-username') }}
              </span>
            </div>

            <div class="form-group adduser-password">
              <label for="password" class="required-mark">{{ $t('users.password') }}</label>
              <div class="input-group">
                <input
                  ref="passwordInput"
                  type="password"
                  name="newuser-password"
                  :placeholder="$t('users.enterPasswordPlaceHolder')"
                  autocomplete="new-password"
                  id="password"
                  class="form-control"
                  v-validate="mixRulesForUserPasswordCheck('required')"
                  data-vv-validate-on="input"
                  :class="{ input: true, 'is-danger': errors.has('newuser-password') }"
                  v-model="newUserData.password"
                  :data-vv-as="$t('users.password')"
                />
                <span class="input-group-addon" @click="showPassword">
                  <i ref="passwordButtonIcon" class="fa fa-eye"></i>
                </span>
                <Password-generator @passwordGenerated="passwordGeneratedForNewUser" class="ml-1"></Password-generator>
              </div>
              <span v-show="errors.has('newuser-password')" class="help is-danger">
                {{ errors.first('newuser-password') }}
              </span>
            </div>

            <div class="form-group">
              <label for="email">{{ $t('usersDemoWizard.email') }}</label>
              <input
                type="text"
                name="newuser-email"
                :placeholder="$t('users.enterEmailPlaceHolder')"
                autocomplete="off"
                id="email"
                class="form-control"
                v-validate="'email'"
                data-vv-validate-on="input"
                :data-vv-as="$t('usersDemoWizard.email')"
                :class="{ input: true, 'is-danger': errors.has('newuser-email') }"
                v-model="newUserData.email"
              />
            </div>
            <span v-show="errors.has('newuser-email')" class="help is-danger">
              {{ errors.first('newuser-email') }}
            </span>
            <div class="d-flex justify-content-between">
              <div>
                <!--                                <button type="button" class="btn btn-outline-secondary float-left" :disabled="$parent.step1SavedLocationObject !== false" @click="prevStep">-->
                <!--                                  {{ $t('general.back') }}-->
                <!--                                </button>-->
              </div>
              <div>
                <WButton outline
                  @click="handleSaveUser"
                  :disabled="errors.any()"
                >
                  {{ $t('general.next') }}
                </WButton>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import Vue from 'vue';
// import VueNotifications from 'vue-notifications';
// import locationService from '../../services/locationService';
import { createNamespacedHelpers } from 'vuex';
import usersService from '../../services/usersService';
import PasswordGenerator from '../../components/PasswordGenerator.vue';
import UsersDemoWizardHelpers from './UsersDemoWizardHelpers';

import { userPasswordValidationRulesMixin } from '@/mixins';

const { mapGetters: featureFlagsMapGetters } = createNamespacedHelpers('featureFlags');

export default {
  name: 'UsersDemoWizardStep2',
  mixins: [userPasswordValidationRulesMixin],
  components: {
    PasswordGenerator
  },
  data() {
    return {
      newUserData: {
        mac_mask: false,
        username: '',
        email: '',
        password: '',
        role: 'admin',
        base_location: '',
        phone: '',
        company: '',
        description: ''
      }
    };
  },
  computed: {
    ...featureFlagsMapGetters(['isContentPortalEnabled'])
  },
  methods: {
    passwordGeneratedForNewUser(generatedPassword) {
      // показываем пароль если был скрыт
      const userPassword = this.$refs.passwordInput;
      const passwordIcon = this.$refs.passwordButtonIcon;

      if (userPassword) {
        if (userPassword.type === 'password') {
          userPassword.type = 'text';
          passwordIcon.className = 'fa fa-eye-slash';
        }
      }
      // console.log(generatedPassword);
      this.newUserData.password = generatedPassword;
    },
    handleSaveUser() {
      this.$validator.validate().then((valid) => {
        if (valid) {
          // console.log(this.newUserData);
          this.createNewUser();
        }
      });
    },
    createNewUser() {
      // запихиваем в создаваемго пользователя id ранее созданой локации
      // this.newUserData.base_location = this.$parent.step1SavedLocationObject.id;
      this.newUserData.base_location = this.$store.state.usersDemoWizardStepsData.step1SavedLocationObject.id;
      usersService.createNewUserFromUsersDemoWizard(this);
    },
    showPassword() {
      const userPassword = this.$refs.passwordInput;
      const passwordIcon = this.$refs.passwordButtonIcon;

      if (userPassword) {
        if (userPassword.type === 'password') {
          userPassword.type = 'text';
          passwordIcon.className = 'fa fa-eye-slash';
        } else {
          userPassword.type = 'password';
          passwordIcon.className = 'fa fa-eye';
        }
      }
    },
    nextStep() {
      // this.$parent.nextStep();
      // this.$router.push({ name: 'UsersDemoWizardStep3' });
      UsersDemoWizardHelpers.goToNextStep(this, 'UsersDemoWizardStep2', this.isContentPortalEnabled);
    },
    exitFromWizard() {
      // this.$router.push({ name: 'Users' });
      UsersDemoWizardHelpers.exitFromWizard(this)
    },
    prevStep() {
      // this.$router.push({ name: 'UsersDemoWizardStep1' });
      UsersDemoWizardHelpers.goToPrevStep(this, 'UsersDemoWizardStep2', this.isContentPortalEnabled);
    }
  },
  created() {
    // если не создали локацию на 1 шаге визарда, делать тут нечего, редиректим на шаг 1 :)
    if (
      this.$store.state.usersDemoWizardStepsData.step1SavedLocationObject === false ||
      this.$store.state.usersDemoWizardStepsData.step1SavedLocationObject.id === false
    ) {
      this.$router.push({ name: 'UsersDemoWizardStep1' });
    }
  }
};
</script>

<style lang="scss"></style>
