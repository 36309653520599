// отдельный модуль только для WLC продукта
// здесь размещать только специфические для этого продукта стейты, геттеры, мутации и экшны
// doc о модулях vuex:
// https://v3.vuex.vuejs.org/ru/guide/modules.html

const WLCProduct = {
  state: () => ({
    // todo: добавлять специфические только для WLC продукта стейты сюда
    // https://v3.vuex.vuejs.org/ru/guide/state.html

    isWLCProduct: Boolean(process.env.VUE_APP_PRODUCT_WLC)
  }),
  getters: {
    // todo: добавлять специфические только для WLC продукта вычисляемые функции сюда
    // https://v3.vuex.vuejs.org/ru/guide/getters.html
    isWLCProduct: state => state.isWLCProduct
  },
  mutations: {
    // todo: добавлять специфические только для WLC продукта мутации сюда
    // https://v3.vuex.vuejs.org/ru/guide/mutations.html
  },
  actions: {
    // todo: добавлять специфические только для WLC продукта экшны сюда
    // https://v3.vuex.vuejs.org/ru/guide/actions.html
  },
  namespaced: true
};

export default WLCProduct;
