<template>
  <FormWithTabs
    :formConfig="formConfig"
    @submit="handleSubmit"
  >
    <template v-slot:tab-content="{ tabContent, formState, handleInputFunc, currentTabName }">
      <!-- содержимое каждого таба -->
      <BranchProfileFormTabContent
        :tabContent="tabContent"
        :formState="formState"
        :currentTabName="currentTabName"
        :suggestedVlanNames="suggestedVlanNames"
        :suggestedAclNames="suggestedAclNames"
        @update:inputValue="handleInputFunc"
      />
    </template>
    <!-- кнопка сабмит из модалки -->
    <template v-slot:control-buttons>
      <slot name="control-buttons"></slot>
    </template>
  </FormWithTabs>
</template>

<script>
/**
 * локальный компонент формы с табами для форм в странице branch-profile page.
 * Представляет собой обертку-интерфейс с настроенными разметкой, формой, генератором стейта формы и валидацей.
 * по FSD это слой feature https://feature-sliced.design/ru/docs/get-started/overview#layers
 * @component
 */

import branchProfileApi from '../../../api';

import {
  BranchProfileFormTabContent
} from './components';

import {
  getBranchProfileFormValidators
} from './validators';

/* eslint-disable camelcase */

export default {
  name: 'BranchProfileForm',
  components: {
    BranchProfileFormTabContent
  },
  props: {
    formConfig: {
      type: Array,
      required: true
    },
    forbiddenProfileNames: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      suggestedVlanNames: [],
      suggestedAclNames: []
    };
  },
  computed: {
  },
  methods: {
    handleSubmit(formValues) {
      this.$emit('submit', formValues);
    },
    setBranchProfileFormValidators() {
      const validators = getBranchProfileFormValidators(this.$i18n);

      const uniqueProfileName = validators.uniqueProfileName(this.forbiddenProfileNames);
      const profileNameMask = validators.profileNameMask();
      const profileDescriptionMask = validators.profileDescriptionMask();

      const validator = this.$validator;

      validator.extend('uniqueProfileName', uniqueProfileName);
      validator.extend('profileNameMask', profileNameMask);
      validator.extend('profileDescriptionMask', profileDescriptionMask);
    },
    async getSuggestedVlanNames() {
      branchProfileApi.getBranchVlans({ limit: 200 }, {
        onSuccess: ({ branch_profile_vlans }) => {
          this.suggestedVlanNames = [...new Set(branch_profile_vlans.map(vlan => vlan.name))];
        }
      });
    },
    async getSuggestedAclNames() {
      branchProfileApi.getAclValues({
        onSuccess: (aclValues) => {
          this.suggestedAclNames = [...new Set(aclValues.map(acl => acl.name))];
        }
      });
    }
  },
  created() {
    this.setBranchProfileFormValidators();
  },
  mounted() {
    this.getSuggestedVlanNames();
    this.getSuggestedAclNames();
  }
};
</script>

<style lang="css" scoped>
</style>
