<template>
  <div class="w-dropdown" ref="dropdownRef"
    @mouseenter="handleMouseEnter"
    @mouseleave="handleMouseLeave"
  >
    <p v-if="label" class="mb-1 text-body-700">
      {{ label }}
    </p>
    <button
      type="button"
      class="dropdown-button"
      @click="handleOpenCloseButton"
    >
      <slot name="button-inner-content"></slot>
        <i v-if="withChevron"
          class="fa chevron fa-chevron-right"
          :class="[isDropdownOpen ? 'rotated' : 'initial']"
        />
    </button>
    <div
      class="dropdown-content"
      :class="[isOpenedClass, positionClass]"
    >
      <slot name="inner-content"></slot>
    </div>
  </div>
</template>

<script>
/**
 * UI-компонент выпадающего списка.
 * Регистрируется глобально и используется во всем проекте.
 *
 * @typedef {Object} WDropdownProps
 * @prop {string} label - Label, если нужен.
 * @prop {boolean} isOpen - Флаг, указывающий, открыт ли список или нет. Если нужно управлять дропдауном извне.
 * @prop {string} [position='bottom-center'] - Позиция, в которой отображается список (bottom-center, bottom-left, bottom-right).
 * @prop {boolean} [withChevron=false] - Флаг, указывающий, должен ли быть отображен символ чекбокса.
 * @prop {boolean} [openCloseToggleMode=false] - Флаг, определяющий режим переключения открытия/закрытия при нажатии на кнопку.
 * @prop {boolean} [onHoverMode=false] - Флаг, указывающий, включен ли режим открытия/закрытия при наведении курсора.
 *
 * События:
 * @event open - Срабатывает при открытии списка.
 * @event close - Срабатывает при закрытии списка.
 *
 * Примеры использования компонента WDropdown:
 *
 * @example
 * Базовое использование:
  <WDropdown
    :label="Dropdown"
    :isOpen="isOpen"
    position="bottom-right"
    :withChevron="true"
    :openCloseToggleMode="false"
    :onHoverMode="true"
  >
    <template v-slot:button-inner-content>
      <WButton info outline>
        open me
      </WButton>
    </template>
    <template v-slot:inner-content>
      <ul class="list">
        <li
          v-for="item in items"
          :key="item.id"
          @click="handleClick"
          class="list-item"
        >
          {{ item.value }}
        </li>
      </ul>
    </template>
  </WDropdown>
 */

export default {
  name: 'WDropdown',
  inject: ['$validator'],
  props: {
    label: String,
    isOpen: Boolean,
    position: {
      type: String,
      default: 'bottom-center',
      validator(value) {
        return ['bottom-center', 'bottom-left', 'bottom-right', 'right-top'].indexOf(value) !== -1;
      }
    },
    withChevron: {
      type: Boolean,
      default: false
    },
    openCloseToggleMode: {
      type: Boolean,
      default: false
    },
    onHoverMode: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isDropdownOpen: false
    };
  },
  computed: {
    isOpenedClass() {
      if (this.isDropdownOpen) {
        return 'opened';
      }

      return 'closed';
    },
    positionClass() {
      return this.position;
    }
  },
  methods: {
    handleMouseEnter() {
      if (!this.onHoverMode) {
        return;
      }

      this.openDropdown();
    },
    handleMouseLeave() {
      if (!this.onHoverMode) {
        return;
      }

      this.closeDropdown();
    },
    handleOpenCloseButton() {
      if (this.openCloseToggleMode) {
        this.isDropdownOpen = !this.isDropdownOpen;

        return;
      }

      this.openDropdown();
    },
    openDropdown() {
      this.isDropdownOpen = true;
    },
    closeDropdown() {
      this.isDropdownOpen = false;
    },
    // close element when click has been outside the component
    handleOutsideClick(event) {
      if (!this.isDropdownOpen) {
        return;
      }

      const { dropdownRef } = this.$refs;
      const container = dropdownRef;

      if (container && !event.composedPath().includes(container)) {
        this.closeDropdown();
      }
    }
  },
  watch: {
    isOpen(newValue) {
      this.isDropdownOpen = newValue;
    },
    isDropdownOpen(newValue) {
      if (newValue) {
        this.$emit('open');
      } else {
        this.$emit('close');
      }
    }
  },
  mounted() {
    if (this.isOpen) {
      this.isDropdownOpen = this.isOpen;
    }

    window.addEventListener('click', this.handleOutsideClick, true);
  },
  destroyed() {
    window.removeEventListener('click', this.handleOutsideClick);
  }
};
</script>

<style lang="css" scoped>
.w-dropdown {
  width: 100%;
  position: relative;
}
.dropdown-button {
  width: 100%;
  position: relative;
  background: transparent;
  border: none;
  padding: 0;

  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 4px;

  color: inherit;
  transition: var(--transition);
}

.dropdown-button:hover {
  opacity: var(--opacity);
}

.dropdown-content {
  position: absolute;
  width: fit-content;
  z-index: 5;
  transition: var(--transition);
}

.dropdown-content.bottom-center {
  top: calc(100% + 8px);
  left: 0;
  right: 0;
}

.dropdown-content.bottom-left {
  top: calc(100% + 8px);
  right: 0;
}

.dropdown-content.bottom-right {
  top: calc(100% + 8px);
  left: 0;
}

.dropdown-content.right-top {
  left: calc(100% + 8px);
  top: 0;
}

.dropdown-content.closed {
  opacity: 0;
  visibility: hidden;
}

.dropdown-content.opened {
  opacity: 1;
  visibility: visible;
}

.chevron {
  transition: var(--transition);
}

.chevron.initial {
  transform: rotate(0deg);
}

.chevron.rotated {
  transform: rotate(90deg);
}
</style>
