/**
 * Оценивает, включен ли контент Портал на основе состояния хранилища.
 *
 * @function
 * @returns {boolean} - Возвращает true, если Портал включен, иначе - false.
 *
 * @example
 * Пример использования в навигационном охраннике
 *
 * const router = new VueRouter({
 *   routes: [
 *     {
 *       path: '/some-protected-route',
 *       component: SomeProtectedComponent,
 *       beforeEnter: (to, from, next) => {
 *         if (isContentPortalEnabledGuard()) {
 *           * // Разрешить навигацию, так как Портал подключен
 *           next();
 *         } else {
 *           * // В случае, если Портал не подключен, выполнить перенаправление
 *           next({ name: 'Dashboard' });
 *         }
 *       },
 *     },
 *   ],
 * });
 */

import store from '@/store';

const isContentPortalEnabledGuard = () => {
  const isContentPortalEnabled = store.getters['featureFlags/isContentPortalEnabled'];

  return isContentPortalEnabled;
};

export default isContentPortalEnabledGuard;
