<template>
  <AuthenticationWrapper>
    <template v-slot:form-content>
      <AuthenticationCard
        :heading="$t('login.loginHeader')"
      >
        <LoginForm/>
      </AuthenticationCard>
    </template>
  </AuthenticationWrapper>
</template>

<script>
import { AuthenticationWrapper, AuthenticationCard, LoginForm } from './components';

export default {
  name: 'Login',
  components: {
    AuthenticationWrapper,
    AuthenticationCard,
    LoginForm
  }
};
</script>

<style lang="css" scoped>
</style>
