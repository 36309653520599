<template>
  <div>
    <div class="form-group mt-2 mt-2 d-flex flex-column align-items-start justify-content-start">
      <div>
        <h5>{{ $t('aps.cpeConfigShowTitleHeader') }}: {{ cpeNamesAsString }}</h5>
      </div>
    </div>
    <div class="form-group mt-2 mb-0 d-flex flex-column align-items-center justify-content-center">
      <div class="w-100">
        <textarea readonly class="w-100 cpe-config-show-cpe-config-aria" v-model="cpeConfigForShow"></textarea>
      </div>
    </div>
    <div class="form-group mt-q d-flex flex-row align-items-center justify-content-start">
      <div>
        <WButton secondary outline md
          @click="copyCpeConfigToClipboardButtonHandler"
          v-tooltip.right="{
            content: $t('aps.cpeConfigShowCopyToClipboardSuccess'),
            trigger: 'manual',
            show: isShowingClipboardCopyInTooltipMsg
          }"
        >
          {{ $t('aps.cpeConfigShowCopyButtonCaption') }}
        </WButton>
      </div>
      <div class="ml-q">
        <WButton secondary outline md
          @click="downloadCpeConfigAsFileButtonHandler"
        >
          {{ $t('aps.cpeConfigShowDownloadButtonCaption') }}
        </WButton>
      </div>
    </div>
  </div>
</template>

<script>
import VueNotifications from 'vue-notifications';

export default {
  name: 'CpeConfigShow',
  data() {
    return {
      isShowingClipboardCopyInTooltipMsg: false
    };
  },
  props: {
    cpeConfig: {
      type: Array,
      default: []
    },
    cpeNames: {
      type: Array,
      default: []
    }
  },
  computed: {
    cpeNamesAsString() {
      return this.cpeNames.join(',');
    },
    cpeCounter() {
      return this.cpeConfig.length;
    },
    downloadFileName() {
      try {
        if (this.cpeCounter > 1) {
          return `cpeConfig-for-${this.cpeCounter}-cpes-${moment(new Date()).format('l')}-${moment(new Date()).format(
            'LTS'
          )}`;
        }
        return `cpeConfig-for-${this.cpeNamesAsString}-${moment(new Date()).format('l')}-${moment(new Date()).format(
          'LTS'
        )}`;
      } catch (e) {
        return 'cpeConfig';
      }
    },
    cpeConfigForShow() {
      // const configObject = {
      //   name: 'mock cpe',
      //   id: 'mock-cpe-id',
      //   anotherparam: 'another param',
      //   anotherField: {
      //     id: 'mock-cpe-id',
      //     anotherparam: 'another param'
      //   },
      //   anotherFieldArr: [
      //     {
      //       id: 'mock-cpe-id',
      //       anotherparam: 'another param'
      //     },
      //     {
      //       id: 'mock-cpe-id',
      //       anotherparam: 'another param'
      //     }
      //   ]
      // };
      return JSON.stringify(this.cpeConfig, null, '  ');
    }
  },
  methods: {
    copyToClipboardErrorShowMsg() {
      VueNotifications.error({ message: `${this.$t('aps.cpeConfigShowCopyToClipboardError')}` });
    },
    showClipboardCopyInTooltipMsg() {
      this.isShowingClipboardCopyInTooltipMsg = true;
      setTimeout(() => {
        this.hideClipboardCopyInTooltipMsg();
      }, 900);
    },
    hideClipboardCopyInTooltipMsg() {
      this.isShowingClipboardCopyInTooltipMsg = false;
    },
    saveTofileErrorShowMsg() {
      VueNotifications.error({ message: `${this.$t('aps.cpeConfigShowSaveToFileError')}` });
    },
    downloadCpeConfigAsFileButtonHandler() {
      this.downloadCpeConfigAsFile();
    },
    downloadCpeConfigAsFile() {
      const downloadToFile = (content, filename, contentType) => {
        const a = document.createElement('a');
        const file = new Blob([content], { type: contentType });
        a.href = URL.createObjectURL(file);
        a.download = filename;
        a.click();
        URL.revokeObjectURL(a.href);
      };
      try {
        downloadToFile(this.cpeConfigForShow, `${this.downloadFileName}`, 'text/plain');
      } catch (e) {
        this.saveTofileErrorShowMsg();
      }
    },
    copyCpeConfigToClipboardButtonHandler() {
      // console.log('copyCpeConfigToClipboard')
      this.copyCpeConfigToClipboard();
    },
    copyCpeConfigToClipboard() {
      const configAsText = this.cpeConfigForShow;
      this.copyTextToClipboard(configAsText);
    },
    fallbackCopyTextToClipboard(text) {
      const textArea = document.createElement('textarea');
      textArea.value = text;

      // Avoid scrolling to bottom
      textArea.style.top = '0';
      textArea.style.left = '0';
      textArea.style.position = 'fixed';

      document.body.appendChild(textArea);
      textArea.focus();
      textArea.select();

      try {
        const successful = document.execCommand('copy');
        const msg = successful ? 'successful' : 'unsuccessful';
        this.showClipboardCopyInTooltipMsg();
        // console.log('Fallback: Copying text command was ' + msg);
      } catch (err) {
        this.copyToClipboardErrorShowMsg();
        // console.error('Fallback: Oops, unable to copy', err);
      }

      document.body.removeChild(textArea);
    },
    copyTextToClipboard(text) {
      if (!navigator.clipboard) {
        this.fallbackCopyTextToClipboard(text);
        return;
      }
      navigator.clipboard.writeText(text).then(
        () => {
          this.showClipboardCopyInTooltipMsg();
          // console.log('Async: Copying to clipboard was successful!');
        },
        (err) => {
          this.copyToClipboardErrorShowMsg();
          // console.error('Async: Could not copy text: ', err);
        }
      );
    }
  }
};
</script>

<style scoped>
.cpe-config-show-cpe-config-aria {
  height: 500px !important;
}
</style>
