/**
 * API-модуль для страницы branch profile.
 * @module branchProfileApi
*/

import getBranchProfiles from './methods/getBranchProfiles';
import createBranchProfile from './methods/createBranchProfile';
import updateBranchProfile from './methods/updateBranchProfile';
import deleteBranchProfiles from './methods/deleteBranchProfiles';
import checkIsUniqueProfileName from './methods/checkIsUniqueProfileName';
import getBranchVlans from './methods/getBranchVlans';
import getRadiusGroups from './methods/getRadiusGroups';
import getRedirectRules from './methods/getRedirectRules';
import getAclValues from './methods/getAclValues';

const branchProfileApi = {
  getBranchProfiles,
  createBranchProfile,
  updateBranchProfile,
  deleteBranchProfiles,
  checkIsUniqueProfileName,
  getBranchVlans,
  getRadiusGroups,
  getRedirectRules,
  getAclValues
};

export default branchProfileApi;
