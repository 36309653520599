<template>
  <APProfileForm
    :formConfig="formConfig"
    :forbiddenProfileNames="forbiddenProfileNames"
    @submit="handleSubmit"
  >
    <template v-slot:control-buttons>
      <slot name="form-control-buttons"></slot>
    </template>
  </APProfileForm>
</template>

<script>
/**
 * компонент настроенной формы APProfileForm через generateFormConfig для создания нового профия ap join.
 * ! Компонент локальный, используется только в CreateAPProfileModal.vue
 * @component
 */

import {
  APProfileForm,
  generateFormConfig
} from '../../features';

export default {
  name: 'CreateAPProfileForm',
  components: {
    APProfileForm
  },
  props: {
    forbiddenProfileNames: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
    };
  },
  computed: {
  },
  methods: {
    handleSubmit(formValues) {
      this.$emit('submit', formValues);
    }
  },
  created() {
    // задаем дефолтные значения для каждого поля
    const formConfig = generateFormConfig({
      i18nInstance: this.$i18n
    });

    this.formConfig = formConfig;
  }
};
</script>

<style lang="css" scoped>
</style>
