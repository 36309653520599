<template>
  <i
    @click="clicked"
    class="fa fa-question-circle-o icon-button-hover"
    aria-hidden="true"
    v-tooltip="{ content }"
    role="button"
  ></i>
</template>

<script>
export default {
  name: 'InfoIcon',
  props: {
    content: { type: String, default: '' }
  },
  methods: {
    clicked() {
      this.$emit('clicked');
    }
  }
};
</script>
<style lang="scss">
.icon-button-hover {
  transition: color 0.2s ease-in-out;
}
.icon-button-hover:hover {
  cursor: pointer;
  color: #9e9e9e;
  transition: color 0.2s ease-in-out;
}
</style>
