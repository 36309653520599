<template>
  <button type="button"
    class="locale-switcher-button"
    :class="[this.viewType]"
    @click="toggleLocale"
    v-tooltip="$t(`general.language.${this.locale}`)"
  >
  <span :class="[accentRuClass]">
    Рус
  </span>/
  <span :class="[accentEngClass]">
    Eng
  </span>
  </button>
</template>
<script>
import { createNamespacedHelpers } from 'vuex';

const {
  mapGetters: localeMapGetters,
  mapActions: localeMapActions
} = createNamespacedHelpers('locale');

export default {
  name: 'LocaleSwitcher',
  props: {
    viewType: {
      type: String,
      default: 'dark',
      validator: prop => {
        const errorMessage = 'prop viewType must be typed as "dark | light"';

        if (typeof prop !== 'string') {
          console.error(errorMessage);
          return false;
        }

        const propVariants = ['dark', 'light'];

        const isValid = propVariants.includes(prop);

        if (!isValid) {
          console.error(errorMessage);
          return false;
        }

        return true;
      }
    }
  },
  data() {
    return {};
  },
  methods: {
    ...localeMapActions(['switchLocale']),
    toggleLocale() {
      const newLocale = this.isRuLocale ? 'en' : 'ru';

      this.switchLocale({ newLocale });

      // todo: отрефактрить это через vuex module locale и перенести в те компоненты, где это используется
      this.$store.commit('setLocaleSwitchedTimeStamp');
    }
  },
  computed: {
    ...localeMapGetters(['isRuLocale', 'isEnLocale', 'locale']),
    accentRuClass() {
      return this.isRuLocale && 'accent';
    },
    accentEngClass() {
      return this.isEnLocale && 'accent';
    }
  }
};
</script>

<style lang="css" scoped>
.locale-switcher-button {
  height: 30px;
  background: transparent;
  padding: 5px;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: var(--transition);

  position: relative;
}

.locale-switcher-button.dark {
  color: var(--gray-dark);
}
.locale-switcher-button.light {
  color: var(--white);
}
.locale-switcher-button:hover {
  opacity: var(--opacity);
}

.accent {
  font-weight: bold;
}
</style>
