import { API_URL } from '@/config';
import { AXIOS_INSTANCE } from '@/api';

const POST_LOGIN_URL = `${API_URL}/auth/login`;
const POST_LOGOUT_URL = `${API_URL}/auth/logout`;
const POST_REQUEST_PASSWORD_RECOVERY_URL = `${API_URL}/auth/resetGenerate`;
const POST_CHANGE_PASSWORD_URL = `${API_URL}/auth/changePassword`;
const POST_SET_NEW_PASSWORD = '/auth/reset';

export {
  AXIOS_INSTANCE,
  POST_LOGIN_URL,
  POST_LOGOUT_URL,
  POST_REQUEST_PASSWORD_RECOVERY_URL,
  POST_CHANGE_PASSWORD_URL,
  POST_SET_NEW_PASSWORD
};
