<template>
  <span>
    <span v-if="labelBeforeSwitcher && labelBeforeSwitcher !== ''" class="m-0 mr-1 label-before-switcher">{{ labelBeforeSwitcher }}</span>
    <label class="switch switch-text switch-bee mr-1" :class="{ 'switch-disabled': isDisabled, [colorClass]: true, [labelClass]: labelClass !== '' }">
      <input
        type="checkbox"
        v-bind="$attrs"
        class="switch-input"
        :class="{ checked: isChecked || $attrs.checked }"
        :disabled="$attrs.disabled"
        :checked="isChecked"
        :value="value"
        :id="id"
        tabindex="0"
        v-on="{
          ...$listeners,
          change: (evt) => this.$emit('change', evt),
          input: () => this.$emit('input', !this.value)
        }"
      />
      <span class="switch-label"></span>
      <span class="switch-handle"></span>
    </label>
    <span v-if="label" class="m-0 mt-1">{{ label }}</span>
    <small v-if="capture" class="ml-q text-muted">{{ capture }}</small>
  </span>
</template>

<script>
export default {
  name: 'SwitchComponent',
  props: {
    value: {},
    name: { type: String, default: '' },
    label: { type: String, default: '' },
    labelBeforeSwitcher: { type: String, default: '' },
    capture: { type: String, default: '' },
    id: { type: String, default: '' },
    colorClass: { type: String, default: 'switch-primary' },
    // чтобы отрисовывать свитчер выключенным при значении v-model === true
    // например такой свитчер есть в панели редактирования WLAN's в блоке WMM
    // Он должен отображаться включеным при wmm.disabled === false
    inverseSwitcherEnabledLogic: { type: Boolean, default: false },
    labelClass: {
      type: String, default: ''
    }
  },

  computed: {
    isChecked() {
      if (this.inverseSwitcherEnabledLogic === true) {
        return !this.value;
      }
      return !!this.value;
    },
    isDisabled() {
      return this.$attrs.disabled;
    }
  },
  // model: {
  //   prop: 'checked',
  //   event: 'change'
  // },
  // watch: {
  //   checked() {
  //     this.isChecked = this.checked;
  //   }
  // },
  inheritAttrs: false
};
</script>

<style lang="scss">
.switch:not(.switch-primary):not(.switch-secondary) .switch-input.checked ~ .switch-label {
  background: var(--checkbox-bg) !important;
  border-color: var(--checkbox-bg);
}

.label-before-switcher {
  font-weight: 500;
}
</style>
