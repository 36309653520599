<template>
  <div class="app flex-row align-items-center">
    <div v-if="showSpinner"><Loader-spinner centered></Loader-spinner></div>
    <div class="footer-on-cpe-user-himself-register-page">
      <div
        class="copyright-info-on-cpe-user-himself-register-page"
        :class="{ 'hide-copyright-info-on-cpe-user-himself-register-page': isBeelineBranding }"
      >
      </div>
      <div class="support-contacts-on-cpe-user-himself-register-page">
<!--        <small>-->
<!--          {{ $t('login.support') }}-->
<!--          <a :href="`mailto:${getContactEmail()}`">{{ getContactEmail() }}</a>-->
<!--        </small>-->
      </div>
      <div class="locale-switcher-on-cpe-user-himself-register-page">
        <LocaleSwitcher/>
      </div>
    </div>
    <transition name="fade">
      <div class="container user-registers-the-cpe-himself" v-if="cpeAlreadyRegistered !== undefined">
        <div class="row justify-content-center">
          <div class="col-md-8">
            <div class="card-group mb-0">
              <div class="card p-2">
                <div class="card-block">
                  <div class="user-registers-the-cpe-himself-header-wrapper">
                    <span class="h1">{{ $t('registerMyCPEPlease.cpeRegistrationHeader') }}</span>
                    <span>
                      <info
                        class="user-registers-the-cpe-himself-device-more-info"
                        :content="$t('registerMyCPEPlease.deviceMoreInfo')"
                      />
                    </span>
                  </div>
                  <div class="mt-2">
                    <div class="h5">
                      <span>{{ $t('registerMyCPEPlease.cpeId') }}:</span>
                      <br />
                      <span class="text-muted">{{ cpeId }}</span>
                    </div>
                  </div>
                  <div class="row mt-3" v-if="cpeAlreadyRegistered === false">
                    <div class="col-12">
                      <WButton
                        customClass="px-2"
                        @click="registerCpe"
                        :disabled="isUserRegistersTheCpeHimselfRequestExecuting || errors.any() ? true : false"
                      >
                        <span :class="{ invisible: isUserRegistersTheCpeHimselfRequestExecuting }">
                          {{ $t('registerMyCPEPlease.cpeRegisterButton') }}
                        </span>
                        <span v-if="isUserRegistersTheCpeHimselfRequestExecuting" class="loader loader--mini"></span>
                      </WButton>
                    </div>
                  </div>
                  <div class="row mt-2" v-if="cpeAlreadyRegistered === true">
                    <div class="col-12">
                      <Alert class="alert-success">
                        <template #text>
                          <span class="mr-h">{{ $t('registerMyCPEPlease.cpeAlreadyRegisteredInfoMessage') }}</span>
                        </template>
                      </Alert>
                      <WButton
                        customClass="px-2"
                        @click="goToAllCpeList"
                      >
                        {{ $t('registerMyCPEPlease.goToAllCpeListButton') }}
                      </WButton>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import Alert from '@/components/Universal/alert/alert.vue';
import VueNotifications from 'vue-notifications';
// import VeeValidate from 'vee-validate';
import Vue from 'vue';
import { API_URL } from '@/config';
// import Router from '../../router';
import helpers from '../../helpers';
import Info from '../../components/Universal/info-icon.vue';

import { LocaleSwitcher } from '@/components';

const { mapGetters: brandingMapGetters } = createNamespacedHelpers('branding');

export default {
  name: 'RegisterMyCPEPlease',
  components: {
    LocaleSwitcher,
    Alert,
    Info
  },
  data() {
    return {
      cpeId: '',
      locationId: '',
      loginLoading: false,
      cpeAlreadyRegistered: undefined
    };
  },
  methods: {
    goToAllCpeList() {
      this.$router.push({ name: 'Aps' });
    },
    checkIsCpeAlreadyRegistered() {
      // Делаем проверку на  повторное  привязывание точки.
      // Считаем, что если у точки уже локация пользователя то он ее привязал
      if (this.cpeId !== '' && this.locationId !== '') {
        this.$store.commit('userRegistersTheCpeHimselfCheckIsCpeAlreadyRegisteredRequestBegin');
        // this.cpeAlreadyRegistered = false;
        const query = {
          action: 'R',
          ids: [this.cpeId]
        };

        Vue.axios.post(`${API_URL || ''}/api/cpes`, query).then(
          (response) => {
            if (response.data.status === 'success') {
              const itemsList = response.data.data.itemslist;
              this.$store.commit('userRegistersTheCpeHimselfCheckIsCpeAlreadyRegisteredRequestEnd');
              if (itemsList.length > 0) {
                const cpe = itemsList[0];
                if (cpe.hasOwnProperty('base_location') && cpe.base_location === this.locationId) {
                  this.cpeAlreadyRegistered = true;
                } else {
                  this.cpeAlreadyRegistered = false;
                }
              } else {
                this.cpeAlreadyRegistered = false;
              }
            } else if (response.data.status === 'error') {
              this.$store.commit('userRegistersTheCpeHimselfCheckIsCpeAlreadyRegisteredRequestEnd');
              this.cpeAlreadyRegistered = false;
              VueNotifications.error({ message: response.data.description });
            }
          },
          (err) => {
            this.$store.commit('userRegistersTheCpeHimselfCheckIsCpeAlreadyRegisteredRequestEnd');
            this.cpeAlreadyRegistered = false;
            VueNotifications.error({ message: err });
          }
        );
      } else {
        this.cpeAlreadyRegistered = false;
      }
    },
    getContactEmail() {
      return helpers.getContactEmail();
    },
    registerCpe() {
      if (this.cpeId !== '' && this.locationId !== '') {
        const changeLocationsQuery = {
          action: 'U',
          ids: [this.cpeId],
          items: { 0: { base_location: this.locationId } }
        };

        this.$store.commit('userRegistersTheCpeHimselfRequestBegin');
        Vue.axios.post(`${API_URL || ''}/api/bulk/locations/cpes`, changeLocationsQuery).then(
          (response) => {
            if (response.data.status === 'success') {
              // VueNotifications.success({ message: ctx.$t('aps.notificationLocationSuccessfullyChanged') });
              this.$router.push({ name: 'Aps', params: { registeredCpeId: this.cpeId } });
              // this.$store.commit('userRegistersTheCpeHimselfRequestEnd'); <--  теперь вызываем в компоненте Aps
              // для того чтобы спиннеры  отображались до самого  момента перехода в Aps
            } else if (response.data.status === 'error') {
              VueNotifications.error({ message: response.data.description });
              this.$store.commit('userRegistersTheCpeHimselfRequestEnd');
            }
          },
          (err) => {
            VueNotifications.error({ message: err });
            this.error = err;
            this.$store.commit('userRegistersTheCpeHimselfRequestEnd');
          }
        );
      }
    }
  },
  computed: {
    ...brandingMapGetters(['isBeelineBranding']),
    showSpinner() {
      if (
        this.isuserRegistersTheCpeHimselfCheckIsCpeAlreadyRegisteredRequestExecuting ||
        this.isUserRegistersTheCpeHimselfRequestExecuting
      ) {
        return true;
      }
      return false;
    },
    isuserRegistersTheCpeHimselfCheckIsCpeAlreadyRegisteredRequestExecuting() {
      return this.$store.state.userRegistersTheCpeHimselfCheckIsCpeAlreadyRegisteredRequestExecuting;
    },
    isUserRegistersTheCpeHimselfRequestExecuting() {
      return this.$store.state.userRegistersTheCpeHimselfRequestExecuting;
    },
    window() {
      return window;
    },
    loading() {
      return this.loginLoading;
    }
  },
  created() {
    this.cpeId = this.$route.query.cpe_id;
    this.locationId = JSON.parse(localStorage.getItem('userData')).base_location;
    // console.log(this.locationId)
  },
  mounted() {
    // убираем прелоадер, которй прописан в /static/index.html
    document.body.classList.add('loaded-preloader');
    document.body.classList.add('loaded_hiding-preloader');
    // проверяем не находится ли точка уже в локации пользователя
    this.checkIsCpeAlreadyRegistered();
  }
};
</script>

<style lang="scss" scoped>
.hide-copyright-info-on-cpe-user-himself-register-page {
  visibility: hidden;
}
.locale-switcher-on-cpe-user-himself-register-page {
  position: relative;
  bottom: 1px;
  right: 1px;
}
.copyright-info-on-cpe-user-himself-register-page {
}
.loader-backdrop-loginpage {
  position: absolute;
  top: 0px;
}
.support-contacts-on-cpe-user-himself-register-page {
}
.support-contacts-on-cpe-user-himself-register-page a {
  display: inline;
}
.footer-on-cpe-user-himself-register-page {
  position: fixed;
  left: 5px;
  right: 5px;
  bottom: 5px;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}
.user-registers-the-cpe-himself {
  text-align: center;
}
.user-registers-the-cpe-himself-device-more-info {
  position: absolute;
  top: 10px;
  font-size: 1.2em;
}
.user-registers-the-cpe-himself-header-wrapper {
  position: relative;
}
</style>
