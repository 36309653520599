// миксин используется только в компонентах связанных с редиректами

const redirectMixin = {
  methods: {
    /**
     * Возвращает переведеное название для типа редиректа
     * Если такого не нешлось в словаре с переводами то возвращается переданая в redirectType строка
     *
     * @param {string} redirectType
     * @returns {string}
     */
    getRedirectTypeWithi18n(redirectType) {
      const isI18nNameForRedirectType = this.$te(`GuestControl.redirectType.${redirectType}`);
      const redirectNameForDisplaying = isI18nNameForRedirectType
        ? this.$t(`GuestControl.redirectType.${redirectType}`)
        : `${redirectType}`;
      return redirectNameForDisplaying;
    }
  }
};

export default redirectMixin;
