<template>
  <div class="branch-profile-tab">
    <!-- контент вкладок -->
    <div class="branch-profile-tab-content">
      <div v-for="(content, index) in tabContent"
        class="branch-profile-tab-input-group"
        :key="index"
      >
        <h3 v-if="content.label"
          class="branch-profile-tab-input-group-heading"
        >
          {{ content.label }}
        </h3>
        <div v-for="input in content.inputs"
          class="branch-profile-tab-input"
          :key="input.id"
        >
        <!-- специфический контент для вкладки vlan.
          Там добавление сущности не через типичные инпуты, а сложнее, через форму
        -->
          <template v-if="input.inputType === 'vlanCustomInputType'">
            <VLANTabContent
              :tabContent="tabContent"
              :branchProfileVlans="formState['vlan.branchProfileVlans']"
              :suggestedVlanNames="suggestedVlanNames"
              :suggestedAclNames="suggestedAclNames"
              @update:branchProfileVlans="handleUpdateBranchProfileVlans"
            />
          </template>
          <!-- обычный контент для остальных вкладок -->
          <template v-else-if="input.inputType === 'checkbox'">
            <WCheckbox
              :label="input.label"
              :name="input.name"
              :id="input.name"
              :checked="formState[input.name]"
              @change="handleCheckbox(input.name, $event)"
              :disabled="input.isDisabled"
              v-validate="input.validateRules"
              data-vv-validate-on="change"
              :data-vv-as="input.label"
              :errorText="errors.first(input.name)"
            />
          </template>
          <template v-else-if="input.inputType === 'select'">
            <WSelect
              :options="input.selectOptions"
              :label="input.label"
              :name="input.name"
              :id="input.name"
              :value="formState[input.name]"
              @input="handleSelect(input.name, $event)"
              :disabled="input.isDisabled"
              v-validate="input.validateRules"
              data-vv-validate-on="input"
              :data-vv-as="input.label"
              :errorText="errors.first(input.name)"
            />
          </template>
          <!-- обычные инпуты -->
          <template v-else>
            <WInput
              :key="input.id"
              :type="input.inputType"
              :label="input.label"
              :value="formState[input.name]"
              @input="handleInput(input, $event)"
              :id="input.name"
              :name="input.name"
              :placeholder="input.placeholder"
              :disabled="input.isDisabled"
              data-vv-delay="500"
              v-validate="input.validateRules"
              data-vv-validate-on="input"
              :data-vv-as="input.label"
              :errorText="errors.first(input.name)"
              autocomplete="one-time-code"
            />
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/**
 *
 * компонент управления содержимым табов формы BranchProfileForm.
 * Содержит разметку, правила рендеринга (в т.ч. рекурсивные), специфических настроек отображения
 * и параметров валидации отдельных инпутов в соответствии с конфигом
 * @component
 *
 */

import {
  VLANTabContent
} from './components';

export default {
  name: 'BranchProfileFormTabContent',
  inject: ['$validator'],
  components: {
    VLANTabContent
  },
  props: {
    tabContent: {
      type: Array,
      required: true
    },
    formState: {
      type: Object,
      required: true
    },
    currentTabName: {
      type: String,
      required: true
    },
    suggestedVlanNames: {
      type: Array,
      required: true
    },
    suggestedAclNames: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
    };
  },
  computed: {
  },
  methods: {
    handleInput(inputObj, value) {
      const { name, inputType } = inputObj;

      let correctFormatValue = value;

      // из-за особенностей архитектуры и невозможности использовать здесь v-modal, форматировать number приходится так
      if (inputType === 'number') {
        correctFormatValue = value === '' ? '' : Number(correctFormatValue);
      }

      this.$emit('update:inputValue', {
        inputName: name,
        value: correctFormatValue
      });
    },
    handleCheckbox(inputName, event) {
      const isChecked = event.target.checked;
      this.$emit('update:inputValue', { inputName, value: isChecked });
    },
    handleSelect(inputName, value) {
      this.$emit('update:inputValue', { inputName, value });
    },
    handleUpdateBranchProfileVlans({ value }) {
      this.$emit('update:inputValue', {
        inputName: 'vlan.branchProfileVlans',
        value
      });
    }
  },
  mounted() {
  }
};
</script>

<style lang="css" scoped>
.branch-profile-tab {
  height: 100%;
  max-height: 500px;
  display: flex;
  gap: 24px;
  overflow-y: auto;
  overflow-x: hidden;
  padding-right: 8px;
}

/* стили для скроллбара и трека */
.branch-profile-tab::-webkit-scrollbar {
  width: 5px;
}

.branch-profile-tab::-webkit-scrollbar-track:vertical {
  border-radius: 8px;
  background-color: var(--gray-lighter);
}

.branch-profile-tab::-webkit-scrollbar-thumb {
  width: 5px;
  border-radius: 3px;
  background: var(--brand-info);
}

.branch-profile-tab-content {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  gap: 16px;
  /* flex-wrap: wrap; */
}

.branch-profile-tab-input-group {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.branch-profile-tab-description {
  flex: 0 0 40%;
  display: flex;
}

.branch-profile-tab-description__content {
  display: flex;
  flex-direction: column;
  gap: 32px;
}

.branch-profile-tab-input-group-heading {
  margin: 0;
  font-size: 16px;
}

p {
  margin: 0;
}

.warning-caption {
  font-weight: 700;
}
</style>
