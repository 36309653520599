<template>
  <span v-if="dateTimestamp"
    class="connection-time"
  >
    {{ covertTimestampToDate(dateTimestamp) }}
  </span>
  <span v-else>-</span>
</template>

<script>
/**
 * компонент - ячейка для временного формата. Используется для firstConnection, lastConnection, lastDisconnection.
 * компонент локальный: используется только в ApsTable.vue
 * @component
 */

export default {
  name: 'TimeCell',
  components: {},
  props: {
    dateTimestamp: {
      type: Number,
      required: true
    }
  },
  methods: {
    covertTimestampToDate(unixTimestmap) {
      return moment(unixTimestmap * 1000).format(' D MMM YYYY, H:mm ');
    }
  }
};
</script>

<style lang="css" scoped>
.connection-time {
  font-size: 0.7em;
}
</style>
