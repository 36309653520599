<template>
  <ul
    class="list"
    ref="containerRef"
    v-bind="$attrs">
    <slot></slot>
  </ul>
</template>

<script>
/**
 * UI-компонент списка.
 * Используется для создания списка элементов с динамически устанавливаемой высотой и скроллом.
 *
 * @typedef {Object} WListProps
 * @prop {Object} [fixedItems=null] - Объект, определяющий настройки элементов в списке.
 * @prop {number} fixedItems.maxItems - Максимальное видимое количество элементов в списке. Если будет больше - появится вертикальный скролл.
 * @prop {Object} [autoSizing={ parentHeight: 100 }] - Объект для автоматической настройки высоты списка.
 * @prop {number} autoSizing.parentHeight - Высота списка по умолчанию.
 *
 * Пример использования компонента WList:
 *
 * @example
 * <template>
 *   <!-- Пример использования компонента WList -->
 *   <WList :fixedItems="{ maxItems: 5 }" :autoSizing="{ parentHeight: 200 }">
 *     <li v-for="item in items" :key="item.id" class="list-item">
 *       {{ item.text }}
 *     </li>
 *   </WList>
 * </template>
 *
 * @script
 * export default {
 *   data() {
 *     return {
 *       items: [
 *         { id: 1, text: 'Item 1' },
 *         { id: 2, text: 'Item 2' },
 *         { id: 3, text: 'Item 3' },
 *         // ...другие элементы списка
 *       ]
 *     };
 *   }
 * };
*/

export default {
  name: 'WList',
  props: {
    fixedItems: {
      type: Object, // example: { maxItems: 0 }
      validator: value => {
        const errorMessage = 'prop fixedItems must be typed as { maxItems: number; }';

        if (typeof value !== 'object') {
          console.error(errorMessage);
          return false;
        }

        const { maxItems } = value;

        if (typeof maxItems !== 'number' && maxItems !== null) {
          console.error(errorMessage);
          return false;
        }

        return true;
      }
    },
    autoSizing: {
      type: Object,
      default: () => ({ parentHeight: 100 }),
      validator: value => {
        const errorMessage = 'prop autoSizing must be typed as { parentHeight: number; }';

        if (typeof value !== 'object') {
          console.error(errorMessage);
          return false;
        }

        const { parentHeight } = value;

        if (typeof parentHeight !== 'number' && parentHeight !== null) {
          console.error(errorMessage);
          return false;
        }

        return true;
      }
    }
  },
  mounted() {
    this.setContainerMaxHeight();
  },
  methods: {
    // to set maxHeight of the container dynamically
    setContainerMaxHeight() {
      const container = this.$refs.containerRef;

      if (!container) {
        return;
      }

      const { firstChild } = container;

      if (!firstChild) {
        return;
      }

      const styles = window.getComputedStyle(firstChild);
      const childLIHeight = Number.parseInt(styles.height, 10);
      const childLiMarginBottom = Number.parseInt(styles.marginBottom, 10);

      if (this.fixedItems) {
        // в конце минусуем childLiMarginBottom, потому что у последнего элемента списка его быть не должно
        const containerMaxHeight = (childLIHeight + childLiMarginBottom) * this.fixedItems.maxItems - childLiMarginBottom;
        container.style.maxHeight = `${containerMaxHeight}px`;
      } else {
        container.style.maxHeight = `${this.autoSizing.parentHeight}px`;
      }
    }
  },
  watch: {
    fixedItems: {
      handler: 'setContainerMaxHeight',
      immediate: true
    },
    autoSizing: {
      handler: 'setContainerMaxHeight',
      immediate: true
    }
  }
};
</script>

<style lang="css" scoped>
.list {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;

  overflow-y: auto;
  overflow-x: hidden;
}

/* стили для скроллбара и трека */
.list::-webkit-scrollbar {
  width: 5px;
}

.list::-webkit-scrollbar-track:vertical {
  border-radius: 8px;
  background-color: var(--gray-lighter);
}

.list::-webkit-scrollbar-thumb {
  width: 5px;
  border-radius: 3px;
  background: var(--brand-info);
}

.list::-webkit-scrollbar-thumb:vertical {
  /* height: 10px; */
}
</style>
