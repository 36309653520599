import { v4 as uuidv4 } from 'uuid';

/**
 * @typedef {Object} FieldConfig
 * @property {boolean} isDisabled - Указывает, отключено ли поле.
 * @property {string|boolean|number} defaultValue - Значение по умолчанию для поля.
 * @property {boolean} isRequired - Указывает, обязательно ли для заполнения.
 * @property {SelectOption[]} [selectOptions] - Опции для поля ввода типа select.
 */

/**
 * @typedef {Object} DefaultValues
 * @property {Object} general - Общие настройки.
 * @property {FieldConfig} general.profileName
 * @property {FieldConfig} general.profileDescription
 * @property {FieldConfig} general.nativeVlan
 * @property {FieldConfig} general.fallbackRadioShutdown
 * @property {FieldConfig} general.arpCaching
 * @property {FieldConfig} general.efficientSoftwareUpgrade
 * @property {FieldConfig} general.ipOverlap
 * @property {FieldConfig} general.pmkPropagation
 * @property {Object} authentication - Настройки аутентификации.
 * @property {FieldConfig} authentication.radiusGroup
 * @property {Object} vlan - Настройки VLAN.
 * @property {FieldConfig} vlan.branchProfileVlans
 * @property {Object} policy - Настройки политик.
 * @property {FieldConfig} policy.webAuthentication
 */

/** @type {DefaultValues} */
const defaultValues = {
  general: {
    profileName: {
      isDisabled: false,
      defaultValue: '',
      isRequired: true
    },
    profileDescription: {
      isDisabled: false,
      defaultValue: '',
      isRequired: false
    },
    nativeVlan: {
      isDisabled: false,
      defaultValue: 1,
      isRequired: true
    },
    fallbackRadioShutdown: {
      isDisabled: false,
      defaultValue: false,
      isRequired: false
    },
    arpCaching: {
      isDisabled: false,
      defaultValue: false,
      isRequired: false
    },
    efficientSoftwareUpgrade: {
      isDisabled: false,
      defaultValue: false,
      isRequired: false
    },
    ipOverlap: {
      isDisabled: false,
      defaultValue: false,
      isRequired: false
    },
    pmkPropagation: {
      isDisabled: false,
      defaultValue: false,
      isRequired: false
    }
  },
  authentication: {
    radiusGroup: {
      isDisabled: false,
      defaultValue: 'Not Configured',
      isRequired: true
    }
  },
  vlan: {
    branchProfileVlans: {
      isDisabled: false,
      defaultValue: [],
      isRequired: false
    }
  },
  policy: {
    webAuthentication: {
      isDisabled: false,
      defaultValue: 'Not Configured',
      isRequired: true
    }
  }
};

/**
 * Глубокое слияние двух объектов.
 *
 * @template T
 * @param {T} target - Целевой объект.
 * @param {Partial<T>} source - Исходный объект.
 * @returns {T} - Слитый объект.
 */

function mergeDeep(target, source) {
  const result = { ...target };

  Object.entries(source).forEach(([key, value]) => {
    // Проверяем, если это массив, заменяем массив целиком
    if (Array.isArray(value)) {
      result[key] = value;
    } else if (value instanceof Object && key in target) {
      // Рекурсивно обрабатываем объекты
      result[key] = mergeDeep(target[key], value);
    } else {
      // Заменяем значение для простых типов
      result[key] = value;
    }
  });

  return result;
}

/**
 * @typedef {Object} SelectOption
 * @property {string} display - Отображаемое значение опции.
 * @property {string} data - Данные опции.
 */

/**
 * @typedef {Object} Input
 * @property {string} id - Уникальный идентификатор для поля ввода.
 * @property {string} label - Метка для поля ввода.
 * @property {string} name - Имя поля ввода.
 * @property {string} inputType - Тип поля ввода.
 * @property {string} validateRules - Правила валидации для поля ввода.
 * @property {boolean} isDisabled - Отключено ли поле ввода.
 * @property {string} placeholder - Плейсхолдер для поля ввода.
 * @property {SelectOption[]} [selectOptions] - Опции для поля ввода типа select.
 * @property {string|number|boolean|undefined} defaultValue - Значение по умолчанию для поля ввода.
 */

/**
 * @typedef {Object} ContentGroup
 * @property {string} label - Метка для группы содержимого.
 * @property {Input[]} inputs - Поля ввода в группе содержимого.
 */

/**
 * @typedef {Object} TabConfig
 * @property {string} id - Уникальный идентификатор для вкладки.
 * @property {string} label - Метка для вкладки.
 * @property {string} name - Имя вкладки.
 * @property {ContentGroup[]} content - Группы содержимого во вкладке.
 * @property {TabConfig[]} childrenTabs - Вложенные вкладки.
 */

/**
 * Генерирует конфигурацию формы на основе заданных параметров.
 *
 * @param {Object} params - Параметры для генерации конфигурации формы.
 * @param {Object} params.i18nInstance - Экземпляр i18n для локализации.
 * @param {Object} [params.initialValues] - Начальные значения и настройки полей формы.
 * @param {Object} [params.initialValues.general] - Начальные значения и настройки для общей вкладки.
 * @param {Object} [params.initialValues.authentication] - Начальные значения и настройки для вкладки управления.
 * @param {Object} [params.initialValues.vlan] - Начальные значения и настройки для вкладки статистики.
 * @param {Object} [params.initialValues.policy] - Начальные значения и настройки для вкладки системного журнала.
 * @returns {TabConfig[]} Конфигурация вкладок формы.
 */

function generateFormConfig(params) {
  const {
    i18nInstance,
    initialValues = {}
  } = params;

  const $t = i18nInstance.t.bind(i18nInstance);
  const mergedValues = mergeDeep(defaultValues, initialValues);

  const {
    general: {
      profileName,
      profileDescription,
      nativeVlan,
      fallbackRadioShutdown,
      arpCaching,
      efficientSoftwareUpgrade,
      ipOverlap,
      pmkPropagation
    },
    authentication: {
      radiusGroup
    },
    vlan: {
      branchProfileVlans
    },
    policy: {
      webAuthentication
    }
  } = mergedValues;

  /**
  * @type {TabConfig[]}
  */
  const formConfig = [
    {
      id: uuidv4(),
      label: $t('branchProfile.tabLabel.general'),
      name: 'general',
      content: [
        {
          label: '',
          inputs: [
            {
              id: uuidv4(),
              label: $t('branchProfile.inputLabel.profileName'),
              name: 'general.profileName',
              inputType: 'text',
              validateRules: `${profileName.isRequired ? 'required|' : ''}uniqueProfileName|profileNameMask|max:120`,
              isDisabled: profileName.isDisabled,
              placeholder: '',
              defaultValue: profileName.defaultValue
            },
            {
              id: uuidv4(),
              label: $t('branchProfile.inputLabel.profileDescription'),
              name: 'general.profileDescription',
              inputType: 'text',
              validateRules: `${profileDescription.isRequired ? 'required|' : ''}profileDescriptionMask|max:120`,
              isDisabled: profileDescription.isDisabled,
              placeholder: '',
              defaultValue: profileDescription.defaultValue
            },
            {
              id: uuidv4(),
              label: $t('branchProfile.inputLabel.nativeVlan'),
              name: 'general.nativeVlan',
              inputType: 'number',
              validateRules: `${nativeVlan.isRequired ? 'required|' : ''}integer|between:1,4094`,
              isDisabled: nativeVlan.isDisabled,
              placeholder: '',
              defaultValue: nativeVlan.defaultValue
            }
          ]
        },
        {
          label: '',
          inputs: [
            {
              id: uuidv4(),
              label: $t('branchProfile.inputLabel.fallbackRadioShutdown'),
              name: 'general.fallbackRadioShutdown',
              inputType: 'checkbox',
              validateRules: `${fallbackRadioShutdown.isRequired ? 'required' : ''}`,
              isDisabled: fallbackRadioShutdown.isDisabled,
              placeholder: '',
              defaultValue: fallbackRadioShutdown.defaultValue
            },
            {
              id: uuidv4(),
              label: $t('branchProfile.inputLabel.arpCaching'),
              name: 'general.arpCaching',
              inputType: 'checkbox',
              validateRules: `${arpCaching.isRequired ? 'required' : ''}`,
              isDisabled: arpCaching.isDisabled,
              placeholder: '',
              defaultValue: arpCaching.defaultValue
            },
            {
              id: uuidv4(),
              label: $t('branchProfile.inputLabel.efficientSoftwareUpgrade'),
              name: 'general.efficientSoftwareUpgrade',
              inputType: 'checkbox',
              validateRules: `${efficientSoftwareUpgrade.isRequired ? 'required' : ''}`,
              isDisabled: efficientSoftwareUpgrade.isDisabled,
              placeholder: '',
              defaultValue: efficientSoftwareUpgrade.defaultValue
            },
            {
              id: uuidv4(),
              label: $t('branchProfile.inputLabel.ipOverlap'),
              name: 'general.ipOverlap',
              inputType: 'checkbox',
              validateRules: `${ipOverlap.isRequired ? 'required' : ''}`,
              isDisabled: ipOverlap.isDisabled,
              placeholder: '',
              defaultValue: ipOverlap.defaultValue
            },
            {
              id: uuidv4(),
              label: $t('branchProfile.inputLabel.pmkPropagation'),
              name: 'general.pmkPropagation',
              inputType: 'checkbox',
              validateRules: `${pmkPropagation.isRequired ? 'required' : ''}`,
              isDisabled: pmkPropagation.isDisabled,
              placeholder: '',
              defaultValue: pmkPropagation.defaultValue
            }
          ]
        }
      ],
      childrenTabs: []
    },
    {
      id: uuidv4(),
      label: $t('branchProfile.tabLabel.authentication'),
      name: 'authentication',
      content: [
        {
          label: '',
          inputs: [
            {
              id: uuidv4(),
              label: $t('branchProfile.inputLabel.radiusGroup'),
              name: 'authentication.radiusGroup',
              inputType: 'select',
              validateRules: `${radiusGroup.isRequired ? 'required' : ''}`,
              isDisabled: radiusGroup.isDisabled,
              placeholder: '',
              defaultValue: radiusGroup.defaultValue,
              selectOptions: [
                { display: $t('branchProfile.inputLabel.radiusGroup.selectOption.notConfigured'), data: 'Not Configured' },
                // список селектов с бэка
                ...radiusGroup.selectOptions
              ]
            }
          ]
        }
      ],
      childrenTabs: []
    },
    {
      id: uuidv4(),
      label: $t('branchProfile.tabLabel.vlan'),
      name: 'vlan',
      content: [
        {
          label: '',
          inputs: [
            // важно, чтобы объект здесь был один.
            // тк. контент для вкладки vlan не просто инпуты, а уникальный, и рендерится через отдельный компонент по условию
            // см. BranchProfileFormTabContent и VLANTabContent
            {
              id: uuidv4(),
              label: '',
              name: 'vlan.branchProfileVlans',
              inputType: 'vlanCustomInputType', // специальный тип, по которому рендерится VLANTabContent
              validateRules: '',
              isDisabled: branchProfileVlans.isDisabled,
              placeholder: '',
              defaultValue: branchProfileVlans.defaultValue
            }
          ]
        }
      ]
    },
    {
      id: uuidv4(),
      label: $t('branchProfile.tabLabel.policy'),
      name: 'policy',
      content: [
        {
          label: '',
          inputs: [
            {
              id: uuidv4(),
              label: $t('branchProfile.inputLabel.webAuthentication'),
              name: 'policy.webAuthentication',
              inputType: 'select',
              validateRules: `${webAuthentication.isRequired ? 'required' : ''}`,
              isDisabled: webAuthentication.isDisabled,
              placeholder: '',
              defaultValue: webAuthentication.defaultValue,
              selectOptions: [
                { display: $t('branchProfile.inputLabel.webAuthentication.selectOption.notConfigured'), data: 'Not Configured' },
                // список селектов с бэка
                ...webAuthentication.selectOptions
              ]
            }
          ]
        }
      ]
    }
  ];

  return formConfig;
}

export default generateFormConfig;
