<template>
  <div class="col-sm-12 col-lg-6 mt-2">
    <div class="card pb-q" style="min-width: 300px">
      <loader-spinner v-if="showingSpinner" centered></loader-spinner>
      <h3 class="card-header mb-1">{{ $t('configExportImport.configImportHeader') }}</h3>
      <div class="card-block mb-1 mt-0 pt-0 mb-1 pb-0">
        <div class="d-flex flex-column">
          <div class="d-flex flex-row flex-wrap">
            <div class="d-flex w-50 flex-row align-items-top mr-2 mb-q" style="min-width: 250px">
              <div class="form-group mb-0 config-for-upload-select-block">
                <label
                  id="config-for-upload-select-block-button"
                  class="btn btn-info mb-0 config-for-upload-select-block-button"
                  role="button"
                >
                  <i class="fa fa-upload mr-h"></i>
                  {{ $t('configExportImport.chooseAFile') }}
                  <input
                    accept=".json"
                    class="form-control-file"
                    @change="fileSelectHandler"
                    type="file"
                    id="selectFiles"
                    value="Import"
                    ref="selectFile"
                    style="display: none"
                  />
                </label>
                <label class="multiselect__placeholder btn mb-0" for="selectFiles">
                  {{ uploadedFromDiskConfigFileNameForDisplay }}
                </label>
              </div>
            </div>
            <div clas="mb-q">
              <WButton :success="!importButtonDisabled"
                @click="importConfigButtonHandler"
                :disabled="importButtonDisabled"
              >
                {{ $t('configExportImport.importButtonCaption') }}
              </WButton>
            </div>
          </div>

          <div>
            <div class="d-flex justify-content-start">
              <div>
                <a href="#" class="ml-q" @click.prevent="clearSelectedFile">
                  <span>{{ $t('general.clear') }}</span>
                </a>
              </div>
              <div class="ml-2 errors-in-json" v-if="areErrorsInJSON">
                {{ $t('configExportImport.fileParsingError') }}
              </div>
            </div>
          </div>
          <transition name="ConfigImportTransition" mode="out-in">
            <div
              class="mb-1 mt-h location-name-block"
              v-if="uploadedFromDiskConfigLocationName !== '' && uploadedFromDiskConfigForShow"
            >
              <div v-if="uploadedFromDiskConfigFileName !== ''">
                <span>
                  {{ $t('configExportImport.fileImportCpation') }}:
                  <span class="ml-q">
                    <b>
                      {{ uploadedFromDiskConfigFileName }}
                    </b>
                  </span>
                </span>
              </div>
              <div v-if="areLocationsVisible">
                <span>
                  {{ $t('configExportImport.forLocation') }}:
                  <b class="ml-q">{{ uploadedFromDiskConfigLocationName }}</b>
                  <span class="ml-q">
                    ({{
                      uploadedFromDiskConfigWithChildsStatus === true
                        ? $t('configExportImport.withChildsTrue')
                        : $t('configExportImport.withChildsFalse')
                    }})
                  </span>
                </span>
              </div>
              <h6 role="button" class="mt-1" style="font-size: 0.9em" @click="toggleViewFile">
                <i v-if="viewFile" class="icon-arrow-down"></i>
                <i v-else class="icon-arrow-right"></i>
                <span>{{ $t('configExportImport.viewFileCaption') }}</span>
              </h6>
            </div>
          </transition>
          <transition name="ConfigImportTransition" mode="out-in">
            <pre
              class="w-100"
              style="max-height: 45vh; overflow-y: auto; overflow-x: hidden"
              v-if="uploadedFromDiskConfigForShow && viewFile"
              >{{ uploadedFromDiskConfigForShow }}</pre
            >
            >
          </transition>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VueNotifications from 'vue-notifications';
import configExportImportService from '../../services/configExportImportService';
import locationService from '../../services/locationService';

export default {
  name: 'ConfigImport',
  components: {},
  data() {
    return {
      uploadedFromDiskConfigForShow: '',
      isSpinnerShowing: false,
      areErrorsInJSON: false,
      uploadedFromDiskConfigFileName: '',
      viewFile: false
    };
  },
  computed: {
    uploadedFromDiskConfigFileNameForDisplay() {
      try {
        if (this.uploadedFromDiskConfigFileName !== '') {
          return this.uploadedFromDiskConfigFileName;
        }
        return this.$t('configExportImport.selectedFile');
      } catch (e) {
        return this.$t('configExportImport.selectedFile');
      }
    },
    uploadedFromDiskConfigLocationName() {
      try {
        if (
          Object.hasOwnProperty.call(this.uploadedFromDiskConfigForShowAsJSON, 'location') &&
          this.uploadedFromDiskConfigForShowAsJSON.location !== ''
        ) {
          const locationId = this.uploadedFromDiskConfigForShowAsJSON.location;
          for (const location of this.locationsList) {
            if (location.id === locationId) {
              return location.name;
            }
          }
        }
        return '';
      } catch (e) {
        console.log(e);
        return '';
      }
    },
    uploadedFromDiskConfigWithChildsStatus() {
      try {
        if (Object.hasOwnProperty.call(this.uploadedFromDiskConfigForShowAsJSON, 'with_childs')) {
          return this.uploadedFromDiskConfigForShowAsJSON.with_childs;
        }
        return '';
      } catch (e) {
        console.log(e);
        return '';
      }
    },
    locationsList() {
      return this.$store.getters.locationsListSorted;
    },
    uploadedFromDiskConfigForShowAsJSON() {
      try {
        if (this.uploadedFromDiskConfigForShow !== '') {
          return JSON.parse(this.uploadedFromDiskConfigForShow);
        }
        return {};
      } catch (e) {
        console.log(e);
        return {};
      }
    },
    importButtonDisabled() {
      if (this.areErrorsInJSON || this.uploadedFromDiskConfigForShow === '') {
        return true;
      }
      return false;
    },
    showingSpinner() {
      if (this.isSpinnerShowing || this.$store.state.loadingLocations || this.$store.state.uploadingConfigToAPI) {
        return true;
      }
      return false;
    },
    sendButtonDisabled() {
      if (this.uploadedFromDiskConfigForShow === '') {
        return true;
      }
      return false;
    }
  },
  created() {
    if (Object.keys(this.$store.state.locationsItems).length === 0) {
      // console.log(this.$store.state.locationsItems);
      locationService.getLocations(this);
    }
  },
  methods: {
    toggleViewFile() {
      this.viewFile = !this.viewFile;
    },
    importConfigButtonHandler() {
      try {
        if (this.uploadedFromDiskConfigForShow === '') {
          console.log('no data for send');
          return;
        }
        const configForSendInAPI = JSON.parse(this.uploadedFromDiskConfigForShow);
        configExportImportService.restoreConfig(this, configForSendInAPI);
      } catch (e) {
        VueNotifications.error({ message: this.$t('configExportImport.fileParsingError') });
      }
    },
    clearSelectedFile() {
      try {
        this.uploadedFromDiskConfigForShow = '';
        this.$refs.selectFile.value = '';
        this.areErrorsInJSON = false;
        this.uploadedFromDiskConfigFileName = '';
        this.viewFile = false;
      } catch (e) {
        console.log(e);
      }
    },
    showSpinner() {
      this.isSpinnerShowing = true;
    },
    hideSpinner() {
      this.isSpinnerShowing = false;
    },
    fileSelectHandler() {
      this.setFromUploadedFileButtonHandler();
    },
    setFromUploadedFileButtonHandler() {
      // this.clearSelectedFile();
      // console.log('from uploaded file');
      const { files } = document.getElementById('selectFiles');
      // console.log(files);
      if (files.length <= 0) {
        return false;
      }
      this.uploadedFromDiskConfigFileName = files[0].name;

      const fr = new FileReader();
      this.showSpinner();

      fr.onload = (e) => {
        // console.log(e);
        // let result = JSON.parse(e.target.result);
        // let formatted = JSON.stringify(result, null, 2);
        // this.configForSetOnCPEsInTextArea = formatted;
        // this.configForSetOnCPEs = result;
        // console.log(e)
        this.handleInsertedOrUploadedConfigString(e.target.result);
        this.hideSpinner();
      };

      fr.readAsText(files.item(0));
    },
    handleInsertedOrUploadedConfigString(text) {
      this.areErrorsInJSON = false;
      try {
        const result = JSON.parse(text);
        const formatted = JSON.stringify(result, null, 2);
        this.uploadedFromDiskConfigForShow = formatted;
      } catch (e) {
        console.log(e);
        // this.areErrorsInJSON =  true
        // // this.configForSetOnCPEsInTextArea = '';
        // this.configForSetOnCPEs = [];
        this.whenErrorActions();
      }
    },
    whenErrorActions() {
      this.areErrorsInJSON = true;
      this.uploadedFromDiskConfigForShow = '';
      // this.clearSelectedFile();
    }
  }
};
</script>

<style scoped>
.errors-in-json {
  color: red;
}
.location-name-block {
  font-size: 1.1em;
}
.config-for-upload-select-block {
  width: 100%;
  border: 1px solid #e3e8ec;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  color: #35495e;
  overflow-x: hidden;
  text-overflow: ellipsis;
  display: flex;
}
#config-for-upload-select-block-button {
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
}
</style>
<style>
.ConfigImportTransition-enter-active,
.ConfigImportTransition-leave-active {
  transition: opacity 0.3s;
}

.ConfigImportTransition-enter,
.ConfigImportTransition-leave-to {
  opacity: 0;
}
</style>
