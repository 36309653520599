<template>
  <nav v-if="currentRoutePathList.length"
    class="w-breadcrumb"
  >
    <BreadcrumbList :items="currentRoutePathList" />
  </nav>
</template>

<script>
/**
* Компонент навигации BreadcrumbNavigation.
* ! Используется только в Full.vue на всех страницах.
*/

import { createNamespacedHelpers } from 'vuex';
import { getMenuNavigationConfig } from '@/config';

import {
  BreadcrumbList
} from './components';

const { mapGetters: brandingMapGetters } = createNamespacedHelpers('branding');
const { mapGetters: featureFlagsMapGetters } = createNamespacedHelpers('featureFlags');
const { mapGetters: WLCProductMapGetters } = createNamespacedHelpers('WLCProduct');

export default {
  name: 'BreadcrumbsNavigation',
  components: {
    BreadcrumbList
  },
  computed: {
    // эти флаги нужны для того, чтобы работал правильно getMenuNavigationConfig
    // поэтому это все дублируется здесь из src/components/SidebarNewStructure/SidebarNewStructure.vue

    // ! Обратить внимание, чтобы набор всех этих флагов (brandingMapGetters, featureFlagsMapGetters, WLCProductMapGetters
    // и возможно других, если они используются внутри getMenuNavigationConfig) был одинаков и в
    // src/components/BreadcrumbsNavigation/BreadcrumbsNavigation.vue и в
    // src/components/SidebarNewStructure/SidebarNewStructure.vue
    // getMenuNavigationConfig вызывается и там и там, передается как один инстанс, так и другой (в thisInstance: this)
    // И, например, если в одном из компонентов есть флаг isWLCProduct, а в другом нет, то
    // в меню какой-либо из пунктов может не отображаться, а в breadcrumbs отображаться. Или наоборот
    ...brandingMapGetters(['isBeelineBranding']),
    ...featureFlagsMapGetters([
      'isContentPortalEnabled',
      'isContentPortalPaidInternetEnabled',
      'isPortalOnlyModeEnabled',
      'isContentAnalyticsEnabled',
      'isExperimentalModeEnabled',
      'isCiscoModeEnabled'
    ]),
    ...WLCProductMapGetters(['isWLCProduct']),
    currentRoutePathList() {
      // настройка и формирование конфига меню
      const menuNavConfig = getMenuNavigationConfig({
        i18nInstance: this.$i18n,
        thisInstance: this,
        currentPath: this.$route.path
      });

      function getCurrentRoutePath(configList) {
        const filteredConfigList = configList.filter(el => el.next);

        if (filteredConfigList.length > 1) {
          throw new Error('There is not possible to have more than 1 chain path in current url breadcrumb navigation');
        }

        // плоский массив цепочки вложенных элементов next для удобного рендеринга циклом
        function transformCurrentRoutePathToFlatArray(filteredConfigList) {
          if (!filteredConfigList.length) {
            return [];
          }

          const currentRoutePathFlatArray = [filteredConfigList[0]];

          let curr = filteredConfigList[0];
          let condition = true;

          while (condition) {
            if (curr.next) {
              currentRoutePathFlatArray.push(curr.next);
              curr = curr.next;
            }

            if (!curr.next) {
              condition = false;
            }
          }

          return currentRoutePathFlatArray
            // отфильтровать пустые айтемы (в которых next: {})
            .filter(el => el.id);
        }

        return transformCurrentRoutePathToFlatArray(filteredConfigList);
      }

      const currentRoutePathList = getCurrentRoutePath(menuNavConfig);

      return currentRoutePathList;
    },

    // роли нужны для того, чтобы работал правильно getMenuNavigationConfig
    // поэтому это все дублируется здесь из SedebarNewStructure

    // roles
    isMarketerRole() {
      return this.$store.state.userData.role === 'marketer';
    },

    isOperatorRole() {
      return this.$store.state.userData.role === 'operator';
    },
    isAdminRole() {
      return this.$store.state.userData.role === 'admin';
    },
    isAdvertisingRole() {
      return this.$store.state.userData.role === 'advertising';
    }
  },
  methods: {
  }
};
</script>

<style lang="css" scoped>
.w-breadcrumb {
  margin-bottom: 16px;
  padding: 8px;
  border-radius: 8px;
}
</style>
