// список нужен для перекомпаановки списка таймзон, чтобы в начале были российские и СНГ, потом все остальные таймзоны
const PREFFERED_TIMEZONES_LIST_ORDER = [
  'Kyiv',
  'Moscow',
  'Kaliningrad',
  'Samara',
  'Baku',
  'Tbilisi',
  'Yerevan',
  'Ashgabat',
  'Yekaterinburg',
  'Novosibirsk',
  'Krasnoyarsk',
  'Irkutsk',
  'Yakutsk',
  'Vladivostok',
  'Magadan',
  'Sakhalin',
  'Petropavlovsk-Kamchatsky'
];

/**
 * Изменет порядок таймзон в списке на основе списка предпочтительных таймзон
 * Таймзоны из списк предпочтительных ставятся в начало
 * Возвращает перекомпанованый список
 *
 * @param timeZonesList список таймзон
 * @param prefferedTimeZonesList список предпочтительных таймзон
 * @returns {[]} массив с объектами таймзон
 */
function reorderTimeZonesListWithPrefferedList(timeZonesList, prefferedTimeZonesList) {
  const preferredTimezonesArray = [];
  const otherTimezonesArray = [];
  timeZonesList.forEach((timezone) => {
    const isTimezoneInPrefferedList = prefferedTimeZonesList.some((el) => {
      return timezone.text.includes(el);
    });

    if (isTimezoneInPrefferedList) {
      preferredTimezonesArray.push(timezone);
    } else {
      otherTimezonesArray.push(timezone);
    }
  });

  return [...preferredTimezonesArray, ...otherTimezonesArray];
}

export { PREFFERED_TIMEZONES_LIST_ORDER, reorderTimeZonesListWithPrefferedList };
