<template>
  <div :class="{'small-size-wrapper-for-select-componentv2': smallSize}">
  <div class="select__container">
    <div ref="control" class="select__control" :class="{ disabled: disabled }" @click="toggleShowOptions">
      <div v-if="isOptionSelected" :key="selectedOption[optionIdName]" class="select__tag" :class="{'no-wrap-tags-for-select-componentv2': noWrapSelectedOption }">
        <span  :style="enableMaxOptionWidth ? maxOptionWidthClassObject : ''"
               v-tooltip.top-center="{ content: selectedOption[trackBy],
                            popperOptions: {
                              modifiers: {
                                preventOverflow: {
                                  boundariesElement: 'window'
                                }
                              }
                            }
                            }">{{ selectedOption[trackBy] }}</span>
         <span v-if="!disabled && showDeleteButton" class="select__delete" @click="deleteTag()">
          <svg viewBox="0 0 492 492">
            <path
              fill="currentColor"
              d="M300.188,246L484.14,62.04c5.06-5.064,7.852-11.82,7.86-19.024c0-7.208-2.792-13.972-7.86-19.028L468.02,7.872c-5.068-5.076-11.824-7.856-19.036-7.856c-7.2,0-13.956,2.78-19.024,7.856L246.008,191.82L62.048,7.872c-5.06-5.076-11.82-7.856-19.028-7.856c-7.2,0-13.96,2.78-19.02,7.856L7.872,23.988c-10.496,10.496-10.496,27.568,0,38.052L191.828,246L7.872,429.952c-5.064,5.072-7.852,11.828-7.852,19.032c0,7.204,2.788,13.96,7.852,19.028l16.124,16.116c5.06,5.072,11.824,7.856,19.02,7.856c7.208,0,13.968-2.784,19.028-7.856l183.96-183.952l183.952,183.952c5.068,5.072,11.824,7.856,19.024,7.856h0.008c7.204,0,13.96-2.784,19.028-7.856l16.12-16.116c5.06-5.064,7.852-11.824,7.852-19.028c0-7.204-2.792-13.96-7.852-19.028L300.188,246z"
            />
          </svg>
        </span>
      </div>
<!--      <input-->
<!--        v-if="!isOptionSelected"-->
<!--        v-model="inputSearch"-->
<!--        class="select__input"-->
<!--        type="text"-->
<!--        :disabled="disabled"-->
<!--        :placeholder="placeholderInput"-->
<!--        :readonly="isOptionSelected"-->
<!--        @blur="handleBlur"-->
<!--        @focus="handleFocus"-->
<!--        @keydown.delete="handleClear"-->
<!--      />-->
      <input
        v-if="!isOptionSelected"
        class="select__input select__input_readonly"
        type="text"
        :placeholder="placeholderInput"
        :readonly="true"
        @blur="handleBlur"
        @focus="handleFocus"
        @keydown.delete="handleClear"
      />
      <div class="arrow" :class="{ rotated: !isOptionsShown }"></div>
    </div>
    <div v-show="isOptionsShown" class="select__list-container-wrapper">
      <div class="select__list-container" v-show="isOptionsShown">
<!--        <div v-if="isOptionSelected && showSearchFieldIntoItemsList">-->
<!--          <div style="border-bottom: 0.1px solid lightgray" class="m-0"></div>-->
<!--          <span class="ml-h text-muted">{{ $t('general.search') }}</span>-->
<!--          <div class="selectComponentV3-input-in-list">-->
<!--            <input-->
<!--              v-model="inputSearchInInnerListInput"-->
<!--              :placeholder="placeholderForInputSearchInInnerListInput"-->
<!--              @input="inputInInnerListInputHandler"-->
<!--              class="select__input"-->
<!--              type="text"-->
<!--              :disabled="disabled || showLoader"-->
<!--            />-->
<!--          </div>-->
<!--          <div style="border-bottom: 0.1px solid lightgray" class="m-0"></div>-->
<!--        </div>-->
        <div v-if="showSearchFieldIntoItemsList">
          <div style="border-bottom: 0.1px solid lightgray" class="m-0"></div>
          <span class="ml-h text-muted">{{ $t('general.search') }}</span>
          <div class="selectComponentV3-input-in-list">
            <input
              v-model="inputSearch"
              :placeholder="placeholderForInputSearchInInnerListInput"
              class="select__input"
              type="text"
              :disabled="disabled || showLoader"
            />
          </div>
          <div style="border-bottom: 0.1px solid lightgray" class="m-0"></div>
        </div>
        <span class="select__option select__option_clickable" v-show="showLoader">
          <loading style="position: absolute; top: 0; left: 0;" :scale="0.15" centered />
        </span>
<!--        <div v-if="isOptionSelected && !noClearSelectedOptionWhenClickOnIt">-->
<!--          <div style="border-bottom: 0.1px solid lightgray" class="m-0"></div>-->
<!--          <span class="ml-h text-muted">{{ $t('general.search') }}</span>-->
<!--          <div class="selectComponentV3-input-in-list">-->
<!--            <input-->
<!--              v-model="inputSearchInInnerListInput"-->
<!--              :placeholder="placeholderForInputSearchInInnerListInput"-->
<!--              @input="inputInInnerListInputHandler"-->
<!--              class="select__input"-->
<!--              type="text"-->
<!--              :disabled="disabled"-->
<!--            />-->
<!--          </div>-->
<!--          <div style="border-bottom: 0.1px solid lightgray" class="m-0"></div>-->
<!--        </div>-->
<!--        <div v-if="isOptionSelected && !showLoader">-->
<!--          <div style="border-bottom: 0.1px solid lightgray" class="m-0"></div>-->
<!--          <span class="ml-h text-muted">{{ $t('general.search') }}</span>-->
<!--          <div class="selectComponentV3-input-in-list">-->
<!--            <input-->
<!--              v-model="inputSearchInInnerListInput"-->
<!--              :placeholder="placeholderForInputSearchInInnerListInput"-->
<!--              @input="inputInInnerListInputHandler"-->
<!--              class="select__input"-->
<!--              type="text"-->
<!--              :disabled="disabled || showLoader"-->
<!--            />-->
<!--          </div>-->
<!--          <div style="border-bottom: 0.1px solid lightgray" class="m-0"></div>-->
<!--        </div>-->

        <slot v-if="!showLoader" name="dropdown-start"></slot>
        <ul v-if="!showLoader">
          <li v-for="(option, id) in OPTIONS_FROM_SERVER" :key="`option-from-server-${option[optionIdName]}-${id}`">
            <span
              class="select__option select__option_clickable"
              :class="{
                selected: selectedOption[optionIdName] === option[optionIdName]
              }"
              @click="handleSelectOption(option)"
            >
              {{ option[trackBy] || noSuchField }}
            </span>
          </li>
          <template v-if="!OPTIONS_FROM_SERVER.length && cashedInput === null">
            <li v-for="(option, id) in DEFAULT_OPTIONS" :key="`option-${option[optionIdName]}-${id}`">
              <span
                class="select__option select__option_clickable"
                :class="{
                  selected: selectedOption[optionIdName] === option[optionIdName]
                }"
                @click="handleSelectOption(option)"
              >
                {{ option[trackBy] || noSuchField }}
              </span>
            </li>
            <li v-for="(option, id) in OPTIONS_LIMIT" :key="`option-limit-${option[optionIdName]}-${id}`">
              <span
                class="select__option select__option_clickable"
                :class="{
                  selected: selectedOption[optionIdName] === option[optionIdName]
                }"
                @click="handleSelectOption(option)"
              >
                {{ option[trackBy] || noSuchField }}
              </span>
            </li>
          </template>
        </ul>
        <template v-if="!OPTIONS_FROM_SERVER.length && cashedInput !== null">
          <div v-show="isNoOption" class="select__option">
            {{ noOptionsFound }}
          </div>
        </template>
      </div>
    </div>
  </div>
    </div>
</template>
<script>
import Loading from '../loading/loading-component.vue';

function debounce(func, wait, immediate) {
  let timeout;

  return function executedFunction() {
    const context = this;
    const args = arguments;

    const later = function () {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };

    const callNow = immediate && !timeout;

    clearTimeout(timeout);

    timeout = setTimeout(later, wait);

    if (callNow) func.apply(context, args);
  };
}

export default {
  name: 'SelectComponentV3ForUseInLocationSelectors',
  components: { Loading },
  props: {
    options: { type: Array, default: () => [] },
    // debounceTime: { type: Number, default: 500 },
    placeholder: {
      type: String,
      default: ''
    },
    asyncFunction: {
      type: Function,
      default: () => {}
    },
    trackBy: {
      type: String,
      default: 'name'
    },
    noSuchField: {
      type: String,
      default: 'No data'
    },
    noOptionsFound: {
      type: String,
      default: 'Not found'
    },
    propInput: {},
    optionIdName: {
      type: String,
      required: true
    },
    disabled: {
      type: Boolean,
      default: false
    },
    inModal: {
      // исползуется если мультиселект находится внутри модалки
      type: Boolean,
      default: false
    },
    modalBodyClassName: {
      type: String,
      default: 'modal-body'
    },
    showDeleteButton: {
      type: Boolean,
      default: false,
    },
     smallSize: {
      type: Boolean,
      default: false,
    },
    enableMaxOptionWidth: {
      type: Boolean,
      default: false,
    },
    maxOptionWidth: {
      type: String,
      default: '90px'
    },
    noWrapSelectedOption: {
      type: Boolean,
      default: false
    },
    noClearSelectedOptionWhenClickOnIt: {
      type: Boolean,
      default: false
    },
    showSearchFieldIntoItemsList: {
      type: Boolean,
      default: true
    }
  },
  model: {
    prop: 'propInput',
    event: 'select'
  },
  data() {
    return {
      // inputSearch: this.propInput,
      isOptionsShown: false,
      OPTIONS_FROM_SERVER: [],
      OPTIONS_LIMIT: [],
      isLoading: false,
      inputSearch: '',
      inputSearchInInnerListInput: '',
      selectedOption: this.propInput || false
    };
  },
  watch: {
    inputSearch(newVal) {
      // console.log(this)
      // console.log('sdfsdfsdfs')
      try {
        if (!this.isOptionsShown && newVal && newVal.length !== 0) {
          this.toggleShowOptions();
        }
      } catch (e) {
        console.log(e);
      }
    },
    cashedInput: debounce(async function () {
      if (this.DEFAULT_OPTIONS.length === 0 && this.cashedInput !== null) {
        this.isLoading = true;
        const response = await this.makeRequestToDB(this.inputSearch);
        this.OPTIONS_FROM_SERVER = response;
        this.isLoading = false;
      } else if (!this.DEFAULT_OPTIONS.includes(this.inputSearch)) {
        this.OPTIONS_FROM_SERVER = [];
      }
    }, 500)
  },
  computed: {
    placeholderForInputSearchInInnerListInput(){
      // return this.$t('general.search').toLowerCase()
    },
    maxOptionWidthClassObject () {
      return {
        maxWidth: `${this.maxOptionWidth} !important`,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      }
    },
    DEFAULT_OPTIONS() {
      if (this.cashedInput) {
        return this.options.filter((option) => {
          if (typeof option[this.optionIdName] === 'string') {
            return option[this.optionIdName].includes(this.cashedInput.toString());
          }
          if (typeof option[this.optionIdName] === 'number') {
            const arrayOfNumbers = option[this.optionIdName].toString().split('');
            return arrayOfNumbers.includes(this.cashedInput);
          }
        });
      }
      return this.options;
    },
    isOptionSelected() {
      return !!Object.keys(this.selectedOption).length;
    },
    showLoader() {
      return this.isLoading && this.cashedInput !== '';
    },
    cashedInput() {
      if (this.inputSearch === '') {
        return null;
      }
      return this.inputSearch;
    },
    placeholderInput() {
      return !this.isOptionSelected ? this.placeholder : '';
    },
    isNoOption() {
      return !this.isLoading && !this.OPTIONS_FROM_SERVER.length && !this.DEFAULT_OPTIONS.length;
    }
  },
  methods: {
    inputInInnerListInputHandler() {
      this.inputSearch = this.inputSearchInInnerListInput;
    },
    // toggleShowOptions() {
    //   if (this.isOptionsShown) {
    //     this.isOptionsShown = false;
    //   } else {
    //     this.showOptions();
    //   }
    // },
    toggleShowOptions() {
      this.inputSearchInInnerListInput = '';
      this.inputSearch = '';
      if (this.isOptionsShown) {
        this.isOptionsShown = false;
      } else {
        if (!this.disabled) {
          this.showOptions();
        }
      }
    },
    showOptions() {
      this.isOptionsShown = true;
      this.handleFocus();
    },
    handleSelectOption(option) {
      // console.log('option', option)
      if (this.noClearSelectedOptionWhenClickOnIt && (this.selectedOption[this.optionIdName] === option[this.optionIdName])) {
        return;
      }
      this.inputSearch = '';
      this.isOptionsShown = false;
      if (this.selectedOption[this.optionIdName] === option[this.optionIdName]) {
        this.selectedOption = false;
        this.$emit('select', false);
        return;
      }
      this.selectedOption = option;
      this.$emit('select', this.selectedOption);
    },
    handleFocus() {
      this.$refs.control.classList.add('focused');
    },
    handleBlur() {
      this.$refs.control.classList.remove('focused');
    },
    handleClear() {
      if (this.selectedOption) {
        // this.selectedOption = false;
        this.handleSelectOption(false);
        // this.inputSearch = '';
      }
    },
    deleteTag() {
      this.$emit('select', false);
      this.selectedOption = false;
    },
    /**
     * @param {string} searchQuery - строка поиска
     * @returns {Promise<array>} - массив объектов
     */
    async makeRequestToDB(searchQuery) {
      const response = await this.asyncFunction(searchQuery);
      return response;
    },
    clickOutside(e) {
      if (!this.$el.contains(e.target)) {
        if (this.isOptionsShown) {
          this.isOptionsShown = false;
          if (!this.isOptionSelected) {
            this.inputSearch = '';
          }
        }
      }
    }
  },
  created() {
    // проверяем в модалке или нет отрисован объект, т.к модалка почему то не пускает event клика
    // выше себя и он не доходит до обработчика в document
    // поэтому немного другая логика (ловим клик в модалке, а не в целом документе)
    if (this.inModal === false) {
      document.addEventListener('click', this.clickOutside);
    }
  },
  beforeDestroy() {
    // проверяем в модалке или нет отрисован объект, т.к модалка почему то не пускает event клика
    // выше себя и он не доходит до обработчика в document
    // поэтому немного другая логика (ловим клик в модалке, а не в целом документе)
    if (this.inModal === false) {
      document.removeEventListener('click', this.clickOutside);
    } else {
      this.parentModalElement.removeEventListener('click', this.clickOutside);
    }
  },
  async mounted() {
    // проверяем в модалке или нет отрисован объект, т.к модалка почему то не пускает event клика
    // выше себя и он не доходит до обработчика в document
    // поэтому немного другая логика (ловим клик в модалке, а не в целом документе)
    if (this.inModal === true) {
      // ищим ближайшую модалку, в DOM, дочерним для которой является элемент мультиселекта
      // (потму что модалок на странице может быть несколько)
      const parentModal = this.$el.closest(`.${this.modalBodyClassName}`);
      this.parentModalElement = parentModal;
      parentModal.addEventListener('click', this.clickOutside);
    }
    const response = await this.makeRequestToDB('');
    this.OPTIONS_LIMIT = response;
  }
};
</script>

<style lang="scss" scoped>
ul {
  padding: 0;
  margin: 0;
  & li {
    list-style-type: none;
  }
}
.select__container {
  display: flex;
  flex-flow: column nowrap;
  max-width: 100%;
  position: relative;
  & * {
    box-sizing: border-box !important;
  }
}
.select__list-container-wrapper {
  position: relative;
}
.select__option {
  display: flex;
  position: relative;
  height: 35px;
  border: 1px solid rgba(130, 130, 130, 0.08);
  border-top: 0px solid rgba(130, 130, 130, 0.08);
  padding: 0.5rem 0.75rem;
  font-size: 0.875rem;
  font-family: sans-serif;
  line-height: 1.25;
  color: #7f8a9b !important;

  &.select__option_clickable:hover {
    background-color: rgba(130, 130, 130, 0.04);
    cursor: pointer;
    &.selected {
      background-color: rgba(130, 130, 130, 0.14);
    }
  }
  &.select__option_clickable:active {
    background-color: rgba(130, 130, 130, 0.2);
  }
  &.select__option_clickable.selected {
    background-color: rgba(130, 130, 130, 0.08);
  }
}
.select__control {
  display: flex;
  flex-flow: row wrap;
  position: relative;
  padding: 0.5rem 2rem 0 0.75rem;
  font-size: 0.875rem;
  line-height: 1.25;
  min-height: 41.5px;
  background-color: #fff;
  background-image: none;
  background-clip: padding-box;
  border: 1px solid rgba(130, 130, 130, 0.15);
  border-radius: 0;
  color: #7f8a9b !important;
  touch-action: manipulation;
  overflow: visible;
  font-family: sans-serif;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  &.focused {
    background-color: #fff;
    border-color: #8ad4ee !important;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  }
  &.disabled {
    background-color: #e0e0e0;
  }
  .rotated {
    transform: rotate(90deg);
    transition: transform 200ms ease-in;
  }
  .arrow {
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    padding: 0.75rem;
    transition: transform 150ms ease-in;
    &::before {
      position: relative;
      right: 0;
      top: 65%;
      color: #999;
      margin-bottom: 8px;
      border-style: solid;
      border-width: 6px 6px;
      border-color: #999 transparent transparent;
      content: '';
    }
  }
}
.select__tag {
  display: flex;
  align-items: baseline;
  justify-content: center;
  margin-right: 4px;
  margin-bottom: 0.5rem;
  padding: 3px 6px;
  border-radius: 6px;
  background-color: rgba(130, 130, 130, 0.15);
  min-width: 75px;

  .select__delete {
    display: block;
    margin-left: 3px;
    width: 18px;
    padding: 0 4px;
    border-radius: 2px;
    cursor: pointer;
    transition: all 200ms ease-in;

    &:hover {
      background-color: rgba(130, 130, 130, 0.15);
      transition: all 200ms ease-in;
    }
  }
}
.select__input {
  border: none;
  flex-grow: 1;
  width: 50px;
  margin-bottom: 0.5rem;
}
.select__list-container {
  max-height: 400px;
  overflow-y: auto;
  position: absolute;
  background-color: #fff;
  top: 0;
  left: 0;
  right: 0;
  z-index: 9999 !important;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
}

.no-wrap-tags-for-select-componentv2.select__tag > span {
  white-space: nowrap;
}

</style>
<style scoped lang="scss">
  .selectComponentV3-input-in-list{
    padding: 0.1em;
    margin: 0.3em 1em 1em 1em;
    // border: 1px solid rgba(128, 128, 128, 0.54);\
    border: 0.1px solid lightgray
  }
    .small-size-wrapper-for-select-componentv2 {
    // min-height: 27px !important;
    width: 100%;
  }

  .small-size-wrapper-for-select-componentv2 .select__control {
    padding-top: 1px !important;
    padding-bottom: 1px !important;
    max-height: 300px !important;
    min-height: 27px !important;
    height: 22px !important;
    align-items: center;
  }

  .small-size-wrapper-for-select-componentv2 .select__input {
    // min-height: 27px !important;
    margin-bottom: 0px !important;
  }

  .small-size-wrapper-for-select-componentv2 .arrow {
    min-height: 27px !important;
  }

  .small-size-wrapper-for-select-componentv2 .select__control {
    max-width: 100% !important;
  }

  .small-size-wrapper-for-select-componentv2 .select__tag  {
    margin-bottom: 0px;
    height: 22px !important;
    max-width: 100% !important;
  }

  /*.small-size-wrapper-for-select-componentv2 .select__tag > span {*/
  /*  max-width: 90px !important;*/
  /*  overflow: hidden;*/
  /*  text-overflow: ellipsis;*/
  /*}*/

  .small-size-wrapper-for-select-componentv2 .select__input {
    width: 100% !important;
    text-overflow: ellipsis !important;
    padding-right: 0 !important
  }
  .small-size-wrapper-for-select-componentv2  .select__input::placeholder  {
    /*color: #94A0B2 !important;*/
    font-style: normal !important;
    font-weight: 300 !important;
    font-size: 14px !important;
    /*color: #adadad !important;*/
    font-family: sans-serif;
  }
  .select__input_readonly{
    cursor: pointer;
  }

</style>
