<template>
  <div class="software-credentials">
    <h1 class="heading">
      Wimark {{ isWLCProduct ? 'WLC' : 'ONE' }}
    </h1>
    <p class="version">
      {{ $t('general.softwareVersion') }} {{ BUILD_SOFTWARE_VERSION }}
    </p>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import { BUILD_SOFTWARE_VERSION } from '@/config';

const { mapGetters: WLCProductMapGetters } = createNamespacedHelpers('WLCProduct');

export default {
  name: 'SoftwareCredentials',
  methods: {
  },
  computed: {
    ...WLCProductMapGetters(['isWLCProduct'])
  },
  created() {
    this.BUILD_SOFTWARE_VERSION = BUILD_SOFTWARE_VERSION;
  }
};
</script>

<style lang="css" scoped>
h1, p {
  margin: 0;
}

.software-credentials {
  padding: 0 8px;
}
.heading {
  font-size: 14px;
  margin-bottom: 4px;
}

.version {
  font-size: 9px;
  line-height: 1;
}
</style>
