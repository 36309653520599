<template>
  <button type="button"
    class="start-page-button"
    :class="[colorClass]"
    @click="goToStartPage"
    v-tooltip.bottom="$t('general.startPage')"
  >
    <i class="fa fa-home fa-lg"></i>
  </button>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';

const REDIRECT_LINK_DICT = {
  dashboard: '/dashboard',
  vouchers: '/portal/hotel-vouchers',
  ads: '/portal/ads',
  portal: '/portal/dashboard'
};

const { mapGetters: featureFlagsMapGetters } = createNamespacedHelpers('featureFlags');

export default {
  name: 'StartPageButton',
  methods: {
    getStartPageLink() {
      const {
        dashboard, vouchers, ads, portal
      } = REDIRECT_LINK_DICT;

      const userRoleState = this.$store.state.userData.role;

      let startPageLink = dashboard;

      if (userRoleState === 'hotel_employee') {
        startPageLink = vouchers;
      }
      if (userRoleState === 'advertising') {
        startPageLink = ads;
      }
      if (this.isPortalOnlyModeEnabled) {
        startPageLink = portal;
      }

      return startPageLink;
    },
    goToStartPage() {
      const startPageLink = this.getStartPageLink();

      if (this.$route.path === startPageLink) {
        return;
      }

      this.$router.push(startPageLink);
    }
  },
  computed: {
    ...featureFlagsMapGetters(['isPortalOnlyModeEnabled']),
    colorClass() {
      return 'color';
    }
  }
};
</script>

<style lang="css" scoped>
.start-page-button {
  width: 30px;
  height: 30px;
  background: transparent;
  padding: 5px;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: var(--transition);
}

.start-page-button.color {
  color: var(--header-font-color);
}

.start-page-button:hover {
  opacity: var(--opacity);
}
</style>
