<template>
  <div class="col-sm-12 col-lg-6 mt-2">
    <div class="card pb-q"  style="min-width: 310px;">
      <loader-spinner v-if="showSpinner" centered></loader-spinner>
      <h3 class="card-header mb-1">{{ $t('configExportImport.configExportHeader') }}</h3>
      <div class="card-block mt-0 pt-0 mb-h pb-0">
        <div class="d-flex flex-wrap align-items-start">
          <div class=" w-50 mr-2 mb-q " style="min-width: 250px;" v-if="areLocationsVisible">
<!--            <multiselect-->
<!--              class="filters-in-visitors-location-multiselect"-->
<!--              :multiple="false"-->
<!--              :allowEmpty="false"-->
<!--              v-model="selectedLocation"-->
<!--              :options="locationsList || []"-->
<!--              label="name"-->
<!--              trackBy="name"-->
<!--              :closeOnSelect="true"-->
<!--              :placeholder="$t('marketing.selectLocationPlaceholder')"-->
<!--              :selectLabel="$t('general.multiSelectPressToSelect')"-->
<!--              :deselectLabel="''"-->
<!--              :selectedLabel="$t('general.multiSelectSelected')"-->
<!--            >-->
<!--              &lt;!&ndash;                    <template slot="singleLabel" slot-scope="props"><span&ndash;&gt;-->
<!--              &lt;!&ndash;                      class="option__title">{{ cutOffLongTextForLocationMultiSelect(props.option.name) }}</span>&ndash;&gt;-->
<!--              &lt;!&ndash;                    </template>&ndash;&gt;-->
<!--            </multiselect>-->

            <div  class="d-flex flex-row input-group-for-location-select-in-ConfigExport--filters"  >
                                                                                             <span class="input-group-addon filters__field-icon">
                  <i class="fa fa-map-marker"
                     ></i>
                </span>
                  <SelectComponentV3ForUseInLocationSelectors
                    :show-search-field-into-items-list="!isSelectedDefaultLocationForRequests || (isSelectedDefaultLocationForRequests && $store.getters.getDefaultWithChildsForRequests())"
                    v-if="showLocationFilter"
                    no-wrap-selected-option
                    class="select-location-in-ConfigExport--filters"
                    enable-max-option-width
                    small-size
                      :no-clear-selected-option-when-click-on-it="isSelectedDefaultLocationForRequests"
                   :show-delete-button="!isSelectedDefaultLocationForRequests"
                    v-model="locationInFiltersData"
                    track-by="name"
                    option-id-name="id"
                    :no-options-found="$t('general.noResultForSearch')"
                    :async-function="requestLocations.bind(this)"
                    :placeholder="$t('portalStats.selectLocation')"
                    @select="selectLocationInFilters"
                  >
                    <template #dropdown-start>
                      <div class="ml-h mt-1">
                        <Switch-component
                             v-if="!isSelectedDefaultLocationForRequests || (isSelectedDefaultLocationForRequests && $store.getters.getDefaultWithChildsForRequests())"
                           :disabled="isDisabledWithChilds"
                          @input="toggleWithChilds"
                          :label="$t('general.withChild')"
                          class=""
                           v-model="with_childs"
                        />
                      </div>
                    </template>
                  </SelectComponentV3ForUseInLocationSelectors>

          </div>
            <div v-if="areLocationsVisible" >
                      <div :class="{'mb-1': downloadButtonDisabled }">
          <a v-if="!isSelectedDefaultLocationForRequests" href="#" class="ml-q mb-1" @click.prevent="clearSelectedLocation">
            <span>{{ $t('general.clear') }}</span>
          </a>
                        <a v-else class="ml-q mb-1"></a>
        </div>
            </div>


          </div>
<!--          <div class="mr-2 mb-q">-->
<!--            <Checkbox-component-->
<!--              class="w-100"-->
<!--              :disabled="isDisabledWithChilds"-->
<!--              name="with_childs"-->
<!--              :label="$t('portalStats.selectLocationWithChild')"-->
<!--              id="with_childs"-->
<!--              :checked="with_childs === true"-->
<!--              @click="toggleWithChilds"-->
<!--            />-->
<!--          </div>-->
          <div class="mb-q" style="position:relative">
            <div style="position:relative; top: -5px;" class="d-flex justify-content-start">
              <WButton :success="!exportButtonDisabled"
                customClass="mr-1"
                @click="getConfigFromAPI"
                :disabled="exportButtonDisabled"
              >
                {{ $t('configExportImport.exportButtonCaption') }}
              </WButton>
            </div>
          </div>
        </div>

      </div>
      <transition name="ConfigExportTransition" mode="out-in">
        <div class="card-block mb-1 mt-0 pt-0 mb-1 pb-0" v-if="exportConfigForShow">
          <div>
<!--            <textarea readonly class="w-100 export-config-for-show" v-model="exportConfigForShow"></textarea>-->
            <WButton :info="!downloadButtonDisabled"
              @click="downloadConfigAsFileButtonHandler"
              :disabled="downloadButtonDisabled"
            >
              <i class="fa fa-download mr-h"></i>
              {{ $t('configExportImport.downloadButtonCaption') }}
            </WButton>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import { Multiselect } from 'vue-multiselect';
import VueNotifications from 'vue-notifications';
import configExportImportService from '../../services/configExportImportService';
// import SelectComponent from '../../components/Universal/select/select-component.vue';
import CheckboxComponent from '../../components/Universal/Checkbox-component.vue';
import locationService from '../../services/locationService';
import SwitchComponent from '../../components/Universal/Switch-component.vue';
import SelectComponentV3ForUseInLocationSelectors from '../../components/Universal/select/select-componentV3ForUseInLocationSelectors.vue';
import SelectComponentV2 from '../../components/Universal/select/select-componentV2.vue';
import commonService from "../../services/commonService";



export default {
  name: 'ConfigExport',
  components: {
    // SelectComponent,
    CheckboxComponent,
    Multiselect,
    SwitchComponent,
    SelectComponentV2,
    SelectComponentV3ForUseInLocationSelectors
  },
  data() {
    return {
      showLocationFilter: true,
      locationInFiltersData: [],
      selectedLocation: '',
      with_childs: false,
      exportConfigForShow: ''
    };
  },
  computed: {
    isSelectedDefaultLocationForRequests() {
      if (this.$store.getters.getDefaultLocationForRequests(this)) {
        return true;
      } else {
        return false;
      }
    },
    downloadButtonDisabled() {
      if (this.exportConfigForShow === '') {
        return true;
      }
      return false;
    },
    downloadFileName() {
      // backup_config_{date}
      try {
        return `backup_config(${moment(new Date()).format('l')}-${moment(new Date()).format('LTS')}).json`;
      } catch (e) {
        return 'backup_config.json';
      }
    },
    showSpinner() {
      if (this.$store.state.loadingLocations || this.$store.state.loadingConfigForSaveInFile) {
        return true;
      }
      return false;
    },
    exportButtonDisabled() {
      if (this.selectedLocation === '') {
        return true;
      }
      return false;
    },
    isDisabledWithChilds() {
      if (this.selectedLocation === '') {
        return true;
      }
      return false;
    },
    locationsList() {
      return this.$store.getters.locationsListSorted;
    }
  },
  // created() {
  //   if (Object.keys(this.$store.state.locationsItems).length === 0) {
  //     // console.log(this.$store.state.locationsItems);
  //     locationService.getLocations(this);
  //   }
  // },
  created() {
    if (Object.keys(this.$store.state.locationsItems).length === 0) {
      // console.log(this.$store.state.locationsItems);
      locationService.getLocations(this);
    }

    let inHeaderLocationSelectedApplying = true;
    if (this.$store.state.inHeaderLocationSelectModeEnabled &&
      inHeaderLocationSelectedApplying) {
      let withChildsForFiltering = this.$store.getters.getInHeaderLocationSelectBlockData.withChilds;
      let locationForFiltering = this.$store.getters.getInHeaderLocationSelectBlockData.selectedLocationID;
      if (!locationForFiltering || locationForFiltering === '') {
        // locationForFiltering = this.$store.getters.getInHeaderLocationSelectBlockData.userBaseLocation
        locationForFiltering = '';
      }
      if (locationForFiltering !== '') {
        locationForFiltering = this.$store.getters.getInHeaderLocationSelectBlockData.selectedLocationObject
      }

      this.locationInFiltersData = locationForFiltering
      this.selectedLocation = locationForFiltering;
      this.with_childs = withChildsForFiltering;
    }

    if (!this.areLocationsVisible) {
      this.locationInFiltersData = this.$store.state.userData.base_location;
      this.selectedLocation = commonService.locationObjbyId(this.locationInFiltersData);
      this.with_childs = true;
    }
  },
  watch: {
    selectedLocation() {
      this.exportConfigForShow = '';
    },
    with_childs() {
      this.exportConfigForShow = '';
    }
  },
  methods: {
    saveTofileErrorShowMsg() {
      VueNotifications.error({ message: `${this.$t('saveToFileError.saveToFileError')}` });
    },
    selectLocationInFilters(location) {
      // console.log(location)
      if (!location) {
        this.selectedLocation = ''
      } else {
        this.selectedLocation = location;
        // this.addLocationInPreviouslySelectedLocationsInFilter(location)
      }


    },
    toggleShowLocationFilter() {
      this.showLocationFilter = false;
      this.$nextTick(() => {
        this.showLocationFilter = true;
      })
    },
    downloadConfigAsFileButtonHandler() {
      this.downloadConfigAsFile();
    },
    downloadConfigAsFile() {
      const downloadToFile = (content, filename, contentType) => {
        const a = document.createElement('a');
        const file = new Blob([content], { type: contentType });
        a.href = URL.createObjectURL(file);
        a.download = filename;
        a.click();
        URL.revokeObjectURL(a.href);
      };
      try {
        downloadToFile(this.exportConfigForShow, `${this.downloadFileName}`, 'text/plain');
      } catch (e) {
        this.saveTofileErrorShowMsg();
      }
    },
    getConfigFromAPI() {
      configExportImportService.getConfig(this);
    },
    clearSelectedLocation() {
      this.selectedLocation = '';
      this.with_childs = false;
      this.locationInFiltersData = [];
      this.toggleShowLocationFilter()
    },
    toggleWithChilds() {
      // this.with_childs = !this.with_childs;
    },
    async requestLocations(searchQuery) {
      // return locationService.requestLocations(searchQuery);
      // return locationService.requestLocationsWithSort(searchQuery);
      return locationService.requestLocationsWithSortAndFilteringByChildsForLocationSelectInPages(this, searchQuery)
    }
  }
};
</script>

<style scoped>
.export-config-for-show {
  height: 200px !important;
}
</style>
<style>
    .input-group-for-location-select-in-ConfigExport-filters {
    position: relative;
    display: flex;
    align-items: stretch;
    width: 100%;
    max-width: 100%;
    z-index: 9997 !important;
  }

  .select-location-in-ConfigExport--filters .select__list-container {
    width: 400px;
  }
.ConfigExportTransition-enter-active,
.ConfigExportTransition-leave-active {
  transition: opacity 0.3s;
}

.ConfigExportTransition-enter,
.ConfigExportTransition-leave-to {
  opacity: 0;
}
</style>
