<template>
  <div
    class="status-cell-wrapper"
    v-tooltip.top-center="{
        content: getWlanStatusForTooltipWithI18N(props.row.status),
        popperOptions: {
        modifiers: {
              preventOverflow: {
              boundariesElement: 'window'
            }
          }
        }
      }"
  >
    <span class="status-badge"
      :class="{
        'connected': props.row.status === 'Active',
        'disconnected': props.row.status === 'Not active',
        'updating': props.row.status === 'Pending'
      }"
    >
  </span>
  </div>
</template>

<script>
/**
 * компонент - ячейка status.
 * компонент локальный: используется только в WlansTable.vue
 * @component
 */

export default {
  name: 'StatusCell',
  components: {
  },
  props: {
    props: {
      type: Object,
      required: true
    }
  },
  computed: {
  },
  methods: {
    getWlanStatusForTooltipWithI18N(status) {
      if (status === 'Active') {
        return this.$t('wlans.statusActive');
      }
      if (status === 'Not active') {
        return this.$t('wlans.statusNotActive');
      }
      if (status === 'Pending') {
        return this.$t('wlans.statusPending');
      }
      if (status === 'Empty') {
        return this.$t('wlans.statusEmpty');
      }
      return status;
    }
  }
};
</script>

<style lang="css" scoped>
.status-cell-wrapper {
  min-height: 24px;
  vertical-align: middle;
  text-align: center;
}

.status-badge {
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
}

.status-badge.connected {
  background-color: var(--brand-success);
}

.status-badge.disconnected {
  background-color: var(--gray-light);
}

.status-badge.updating {
  background-color: var(--brand-info);
}
</style>
