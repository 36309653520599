/**
 * Для загрузки списка таймзон (список аналогичный списку таймзон в визарде контроллера)
 */

import { AXIOS_INSTANCE } from '@/api';
import store from '@/store';
import { GAPI_URL } from '@/config';

/**
 * Асинхронная функция для загрузки списка таймзон.
 *
 * @returns {Promise<Object>} - Обещание с данными таймзон.
 * @throws {Error} - Ошибка при загрузке таймзон.
 */
const timeZonesDataFetcher = async () => {
  try {
    const response = await AXIOS_INSTANCE.get(`${GAPI_URL}/api/v1/timezone/list`);
    const timeZones = response.data;

    return Promise.resolve(timeZones);
  } catch (error) {
    return Promise.reject(error);
  }
};

const timeZonesListService = {
  /**
   * Асинхронно загружает  таймзоны.
   *
   * @param {Object} [options] - Дополнительные опции запроса.
   * @param {Function} [options.onLoading] - Callback при начале выполнения запроса.
   * @param {Function} [options.onSuccess] - Callback при успешном выполнении запроса.
   * @param {Function} [options.onError] - Callback при ошибке выполнения запроса.
   * @returns {Promise<Object>} - Обещание со списком таймзон.
   * @throws {Error} - Ошибка при загрузке логов.
   */
  fetchTimeZones: async (
    options = {
      onLoading: undefined,
      onSuccess: undefined,
      onError: undefined
    }
    // eslint-disable-next-line consistent-return
  ) => {
    const { onLoading, onSuccess, onError } = options;

    try {
      if (onLoading) {
        onLoading();
      }

      store.commit('setLoading');
      const response = await timeZonesDataFetcher();
      const { data } = response;

      if (onSuccess) {
        onSuccess(data);
      }

      store.commit('setSuccess');
      return data;
    } catch (error) {
      if (onError) {
        onError(error);
      }
      store.commit('setError', error);
    }
  }
};

export default timeZonesListService;
