import { PORTAL_PROHIBITED_AUTHORIZATION_TYPES } from '@/config';
import helpers from '../helpers';

const portalAuthorizationsMixin = {
  computed: {
    /**
     * Доступные типы авторизации для портала, за исключением запрещенных, которые
     * объявлены в PORTAL_PROHIBITED_AUTHORIZATION_TYPES
     *
     */
    portalAuthorizationsList() {
      const portalAuthorizationsList = this.$store.state.portalAuthorizationsList
        ? this.$store.state.portalAuthorizationsList
        : [];
      const filteredPortalAuthorizationsList = portalAuthorizationsList.filter((authZItem) => {
        return !PORTAL_PROHIBITED_AUTHORIZATION_TYPES.includes(authZItem.type);
      });
      // console.log(filteredPortalAuthorizationsList);
      return helpers.transformArrayToObjectByKey(filteredPortalAuthorizationsList, 'type');
    }
  }
};

export default portalAuthorizationsMixin;
