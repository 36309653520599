const Administration = () => import(/* webpackChunkName: "Administration" */ '../views/Administration/Administration.vue');
const SNMP = () => import(/* webpackChunkName: "SNMP" */ '../views/Administration/SNMP/SNMP.vue');
const SNMPGeneral = () => import(/* webpackChunkName: "SNMPGeneral" */ '../views/Administration/SNMP/SNMPChilds/SNMPGeneral.vue');
const SNMPViews = () => import(/* webpackChunkName: "SNMPViews" */ '../views/Administration/SNMP/SNMPChilds/SNMPViews.vue');
const SNMPCommunityStrings = () => import(/* webpackChunkName: "SNMPCommunityStrings" */ '../views/Administration/SNMP/SNMPChilds/SNMPCommunityStrings.vue');
const SNMPUserGroups = () => import(/* webpackChunkName: "SNMPUserGroups" */ '../views/Administration/SNMP/SNMPChilds/SNMPUserGroups.vue');
const SNMPUsers = () => import(/* webpackChunkName: "SNMPUsers" */ '../views/Administration/SNMP/SNMPChilds/SNMPUsers.vue');
const SNMPHosts = () => import(/* webpackChunkName: "SNMPHosts" */ '../views/Administration/SNMP/SNMPChilds/SNMPHosts.vue');
const SNMPWirelessTraps = () => import(/* webpackChunkName: "SNMPWirelessTraps" */ '../views/Administration/SNMP/SNMPChilds/SNMPWirelessTraps.vue');
const Time = () => import(/* webpackChunkName: "SNMPWirelessTraps" */ '../views/Administration/Time/Time.vue');

const AdministrationRoute = {
  path: 'administration',
  name: 'Administration',
  component: Administration,
  redirect: 'administration/snmp/',
  children: [
    {
      path: 'snmp',
      name: 'SNMP',
      component: SNMP,
      redirect: 'snmp/general',
      children: [
        {
          path: 'general',
          name: 'SNMPGeneral',
          component: SNMPGeneral
        },
        {
          path: 'views',
          name: 'SNMPViews',
          component: SNMPViews
        },
        {
          path: 'community-strings',
          name: 'SNMPCommunityStrings',
          component: SNMPCommunityStrings
        },
        {
          path: 'user-groups',
          name: 'SNMPUserGroups',
          component: SNMPUserGroups
        },
        {
          path: 'users',
          name: 'SNMPUsers',
          component: SNMPUsers
        },
        {
          path: 'hosts',
          name: 'SNMPHosts',
          component: SNMPHosts
        },
        {
          path: 'wireless-traps',
          name: 'SNMPWirelessTraps',
          component: SNMPWirelessTraps
        }
      ]
    },
    {
      path: 'time',
      name: 'Time',
      component: Time
    }
  ]
};

export default AdministrationRoute;
