import { STORAGE_KEY_INTERVAL_UPDATE } from '../config';
import { ObjectStorage } from './storage';

/**
 * Класс, представляющий утилиту для управления значением ms интервала обновления данных на страницах.
 */

export default class IntervalUpdateMaster {
  /**
   * Получает значение ms.
   * @returns {string|null}
   */
  static getCachedValue() {
    return ObjectStorage.getItem(STORAGE_KEY_INTERVAL_UPDATE);
  }

  /**
   * Устанавливает значение ms в хранилище.
   * @param {number} newValue
   */
  static setCachedValue(newValue) {
    ObjectStorage.setItem(STORAGE_KEY_INTERVAL_UPDATE, newValue);
  }

  /**
   * Очищает значение.
   */
  static clearCachedValue() {
    ObjectStorage.removeItem(STORAGE_KEY_INTERVAL_UPDATE);
  }
}
