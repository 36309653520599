<template>
  <WDropdown
      position="bottom-left"
      @close="handleCloseDropdown"
      @open="handleOpenDropdown"
      :isOpen="isDropdownOpen"
    >
    <template v-slot:button-inner-content>
      <div
        class="button-content-wrapper"
        :class="[colorClass]"
      >
        <i class="fa fa-bell fa-lg"></i>
        <!-- todo refactor badge без бутстрапа -->
        <span v-if="getFullCurrentOperations.length" class="badge-icon badge badge-pill badge-danger">
          {{ getFullCurrentOperations.length }}
        </span>
      </div>
    </template>
    <template v-slot:inner-content>
      <WCard :class="['dropdown-content-wrapper', colorClass]">
        <!-- todo refactor Loader-spinner-small -->
        <Loader-spinner-small v-if="loadingOperations" centered />
        <div class="heading-container">
          <strong>
            {{ $t('header.current') }} {{ $t('header.operations') }}: {{ getFullOperations.length }}
          </strong>
        </div>
        <p v-if="errorOperationsFilter && !getErrorsOperations.length">
          {{ $t('misc.no_errors') }}
        </p>
        <WList class="operations-container" v-else
          :fixedItems="{ maxItems: 4 }"
        >
          <li v-for="operation in errorOperationsFilter ? getErrorsOperations : getFullOperations"
          :key="operation.id"
          >
            <Operation
              :operation="operation"
              @delete="deleteOperation"
            />
          </li>
        </WList>
        <div class="footer"
          v-if="totalOperationsAmount"
        >
          <ControlPanel
            :totalOperationsAmount="totalOperationsAmount"
            :pendingOperationsAmount="pendingOperationsAmount"
            :successOperationsAmount="successOperationsAmount"
            :errorOperationsAmount="errorOperationsAmount"
            @resetFilter="errorOperationsFilter = false"
            @errorFilter="errorOperationsFilter = true"
            @deleteAllFilter="deleteAllOperations"
          />
        </div>
      </WCard>
    </template>
  </WDropdown>
</template>

<script>
import operationsService from '@/services/operationsService';

import { Operation, ControlPanel } from './components';

export default {
  name: 'CurrentOperationsDropdown',
  components: {
    Operation,
    ControlPanel
  },
  data() {
    return {
      isDropdownOpen: false,
      // todo refactor filter logic
      errorOperationsFilter: false
    };
  },
  computed: {
    colorClass() {
      return 'color';
    },
    operationsList() {
      return this.$store.state.operationsList;
    },
    getFullOperations() {
      // todo refactor
      let fullOperations = [];
      fullOperations = [
        ...this.$store.state.cpeOperations,
        ...this.$store.state.wlanOperations,
        ...this.$store.state.radiusOperations,
        ...this.$store.state.firewallOperations,
        ...this.$store.state.redirectOperations,
        ...this.$store.state.hotspotOperations,
        ...this.$store.state.controllerOperations,
        ...this.$store.state.externalApOperations,
        ...this.operationsList
      ];

      return fullOperations.slice(0, 100);
    },
    getFullCurrentOperations() {
      // todo refactor
      let fullCurrentOperations = [];
      fullCurrentOperations = [
        ...this.$store.state.cpeOperations,
        ...this.$store.state.wlanOperations,
        ...this.$store.state.radiusOperations,
        ...this.$store.state.firewallOperations,
        ...this.$store.state.redirectOperations,
        ...this.$store.state.hotspotOperations,
        ...this.$store.state.controllerOperations,
        ...this.$store.state.externalApOperations
      ];
      return fullCurrentOperations;
    },
    getErrorsOperations() {
      let errorsOperations = [];
      errorsOperations = [
        ...this.$store.state.cpeOperations,
        ...this.$store.state.wlanOperations,
        ...this.$store.state.radiusOperations,
        ...this.$store.state.firewallOperations,
        ...this.$store.state.redirectOperations,
        ...this.$store.state.hotspotOperations,
        ...this.$store.state.controllerOperations,
        ...this.$store.state.externalApOperations,
        ...this.operationsList
      ];
      errorsOperations = errorsOperations.filter((operation) => operation.status === 'error');
      return errorsOperations;
    },
    loadingOperations() {
      // todo refactor
      return this.$store.state.loadingOperations;
    },
    totalOperationsAmount() {
      // todo refactor
      return this.$store.state.operationsTotal;
    },
    pendingOperationsAmount() {
      return this.getFullOperations.filter((operation) => {
        return operation.status === 'pending';
      }).length;
    },
    successOperationsAmount() {
      return this.getFullOperations.filter((operation) => {
        return operation.status === 'success';
      }).length;
    },
    errorOperationsAmount() {
      return this.getFullOperations.filter((operation) => {
        return operation.status === 'error';
      }).length;
    }
  },
  methods: {
    handleOpenDropdown() {
      this.isDropdownOpen = true;
    },
    handleCloseDropdown() {
      this.isDropdownOpen = false;
    },
    getOperations() {
      operationsService.getPedingOperations(this);
    },
    deleteOperation(id) {
      operationsService.deleteOperations(this, id);
    },
    deleteAllOperations() {
      operationsService.deleteOperations(this);
    }
  }
};
</script>

<style lang="css" scoped>

ul {
  list-style-type: none;
  padding: 0;
}

p {
  margin: 0;
}
.button-content-wrapper {
  min-width: 30px;
  min-height: 30px;
  padding: 5px;
  display: flex;
  align-items: center;
  position: relative;
  transition: var(--transition);
}

.button-content-wrapper.color {
  color: var(--header-font-color);
}

.info-icon {
  position: absolute;
  top: 0;
  left: 40%;
}

.dropdown-content-wrapper {
  min-width: 260px;
  gap: 20px;
}

.dropdown-content-wrapper.color {
  background-color: var(--header-bg-color);
}

.heading-container {
}

.heading-container * {
  margin: 0;
}

.operations-container {
  display: flex;
  flex-direction: column;
  padding-right: 8px;
}

.operations-container > li:not(:last-of-type){
  margin-bottom: 20px;
}

.footer {
  padding-top: 20px;
  border-top: 1px solid var(--header-accent-color);
}
.info {
  color: var(--brand-info);
}

.success {
  color: var(--brand-success);
}
.danger {
  color: var(--brand-danger);
}

.muted {
  color: var(--gray-light);
}

</style>
