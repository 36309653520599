<template>
  <section>
    <div class="m-auto palette-container">
      <p class="mb-q">{{ $t('pageprofile.themeType') }}</p>
      <div class="custom-control custom-checkbox">
        <input
          id="theme-light"
          name="theme"
          value="light"
          type="radio"
          class="form-check-input"
          :checked="selectedType === 'light'"
          @change="selectType('light')"
        />
        <label for="theme-light" class="custom-control-label">{{ $t('pageprofile.light') }}</label>
      </div>
      <div class="custom-control custom-checkbox mb-q">
        <input
          id="theme-contrast"
          name="theme"
          value="contrast"
          type="radio"
          class="form-check-input"
          :checked="selectedType === 'contrast'"
          @change="selectType('contrast')"
        />
        <label for="theme-contrast" class="custom-control-label">{{ $t('pageprofile.contrast') }}</label>
      </div>

      <p class="mb-h">{{ $t('pageprofile.main_color') }}</p>
      <ul class="palette-list">
        <li v-for="color in colors" :key="color.name" class="palette-item">
          <button
            class="palette-button"
            :class="{ 'palette-button--selected': color.name === selectedColor }"
            :style="`background-color: ${color.value};`"
            @click="selectColor(color.name)"
          />
        </li>
      </ul>
    </div>
  </section>
</template>

<script>
import colors from './colors.json';

export default {
  name: 'PortalPalette',
  props: {
    selectedColor: { type: String, default: '' },
    selectedType: { type: String, default: '' }
  },
  data() {
    return {
      colors
    };
  },
  methods: {
    selectColor(color) {
      this.$emit('select-color', color);
    },
    selectType(type) {
      this.$emit('select-type', type);
    }
  }
};
</script>

<style lang="scss" scoped>
.palette-container {
  max-width: 350px;
}
.palette-list {
  display: flex;
  flex-wrap: wrap;
  max-width: 350px;
  margin: 0;
  padding: 0;

  list-style: none;
}

.palette-button {
  display: block;
  width: 40px;
  height: 40px;
  border-radius: 5px;
  border: 3px solid #ffffff;
  box-shadow: 0 0 7px 0 rgba(0, 0, 0, 0.3);
}

.palette-button:hover,
.palette-button:focus {
  filter: opacity(0.75);
}

.palette-button--selected {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'%3E%3Cpath fill='%23FFFFFF' d='M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z'/%3E%3C/svg%3E");
  background-size: 20px;
  background-repeat: no-repeat;
  background-position: center;
}

.palette-item {
  margin-right: 10px;
  margin-bottom: 10px;
}

.custom-control {
  display: block;
}
</style>
