<template>
  <WDropdown
    position="bottom-left"
    @close="handleCloseDropdown"
    @open="handleOpenDropdown"
    :isOpen="isDropdownOpen"
    v-tooltip.bottom="$t('header.updateInterval')"
  >
    <template v-slot:button-inner-content>
      <div
        class="button-content-wrapper"
        :class="[colorClass]"
      >
        <i
          class="fa fa-refresh fa-lg"
          :class="isIntervalUpdateModeActive ? 'fa-spin' : ''"
        ></i>
        <!-- todo refactor badge без бутстрапа -->
        <span v-if="!isIntervalUpdateModeActive" class="badge-icon badge badge-pill badge-default">
          {{ $t('header.off') }}
        </span>
        <!-- todo refactor badge без бутстрапа -->
        <span v-else class="badge-icon badge badge-pill badge-info" >
          {{ intervalUpdateValue / 1000 }} {{ $t('header.sec') }}
        </span>
      </div>
    </template>
    <template v-slot:inner-content>
      <WCard :class="['dropdown-content-wrapper', colorClass]">
        <div class="heading-container">
          <strong>{{ $t('header.setRefreshInterval') }}</strong>
          <p>
            <small>
              ( {{ $t('header.max') }} - 600 {{ $t('header.sec') }}, {{ $t('header.off') }} - 0
              {{ $t('header.sec') }} )
            </small>
          </p>
        </div>
        <div class="refresh-container">
          <div>
            <WInput
              :customClass="['refresh-input', colorClass]"
              type="number"
              id="interval"
              name="interval"
              v-model.number="inputValue"
              :placeholder="$t('header.inputIntervalPlaceholder')"
              @keyup.13="setRefreshInterval"
              v-validate="'required|numeric|max_value:600'"
              data-vv-validate-on="input"
            />
            <div class="button-container">
              <WButton success outline squared sm
                @click="setRefreshInterval"
              >
                <i class="fa fa-check"></i>
              </WButton>
              <WButton danger outline squared sm
                @click="resetRefreshInterval"
              >
                <i class="fa fa-stop-circle"></i>
              </WButton>
            </div>
          </div>
        </div>
      </WCard>
    </template>
  </WDropdown>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';

const { mapGetters: intervalUpdateModeGetters, mapActions: intervalUpdateModeActions } = createNamespacedHelpers('intervalUpdateMode');

export default {
  name: 'SetUpdateIntervalDropdown',
  components: {
  },
  data() {
    return {
      inputValue: '',
      isDropdownOpen: false
    };
  },
  computed: {
    ...intervalUpdateModeGetters(['intervalUpdateValue', 'isIntervalUpdateModeActive']),
    colorClass() {
      return 'color';
    }
  },
  methods: {
    ...intervalUpdateModeActions(['enableIntervalUpdateMode', 'disableIntervalUpdateMode']),
    async setRefreshInterval() {
      const isValid = await this.$validator.validateAll({ interval: this.inputValue });

      if (!isValid) {
        console.warn('Validation Failed');
        return;
      }

      this.enableIntervalUpdateMode(this.inputValue * 1000);

      this.handleCloseDropdown();
    },
    resetRefreshInterval() {
      this.resetInputValue();

      this.disableIntervalUpdateMode();

      this.handleCloseDropdown();
    },
    resetInputValue() {
      this.inputValue = '';
    },
    handleOpenDropdown() {
      this.isDropdownOpen = true;
    },
    handleCloseDropdown() {
      this.resetInputValue();
      this.isDropdownOpen = false;
    }
  }
};
</script>

<style lang="scss" scoped>
.button-content-wrapper {
  min-width: 60px;
  min-height: 30px;
  padding: 5px;
  display: flex;
  align-items: center;
  position: relative;
  transition: var(--transition);
}

.button-content-wrapper.color {
  color: var(--header-font-color);
}

.badge-icon {
  position: absolute;
  top: 0;
  left: 20%;
}
.dropdown-content-wrapper {
  min-width: 260px;
}

.dropdown-content-wrapper.color {
  background-color: var(--header-bg-color);
}

.heading-container {
  margin-bottom: 1rem;

  text-align: center;
}

.heading-container * {
  margin: 0;
}

.refresh-container {
  display: flex;
  flex-direction: column;
  gap: 2px;
}
.refresh-container > div {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.button-container {
  display: flex;
  justify-content: flex-end;
  gap: 4px;
}

</style>

<style lang="css">
.refresh-input.color {
  color: var(--white);
  border: 1px solid var(--header-accent-color);
}

.refresh-input.color::placeholder {
  color: var(--header-font-color);
}
</style>
