//  для задачи WNE-3589
// таблица с недоступными каналами для стран тут https://en.wikipedia.org/wiki/List_of_WLAN_channels#5_GHz_(802.11a/h/n/ac/ax)

const disabledChannelsForCountries = {
  RU: [96, 100, 104, 108, 112, 116, 120, 124, 128]
};

const countriesList = {
  // ключ - значение введеное в UI, значение - ключ по которому достается список из disabledChannelsForCountries
  RU: 'RU'
};

export { disabledChannelsForCountries, countriesList };
