/**
 * Сервис для загрузки данных о событиях из API
 */
import { AXIOS_INSTANCE } from '@/api';
import { API_URL } from '@/config';
import store from '../store';

/**
 * Асинхронная функция для загрузки данные о событиях из API
 *
 * @param {string} locationId - Имя локации.
 * @param {boolean} withChilds - with_childs.
 * @returns {Promise<Object>} - Обещание с событиямми.
 * @throws {Error} - Ошибка при загрузке событий.
 */
const summaryEventsDataFetcher = async (locationId, withChilds) => {
  try {
    const URL = `${API_URL}/api/dashboard/events`;

    const payload = {
      action: 'R'
    };

    if (locationId) {
      payload.q = { by_location: locationId, with_childs: withChilds };
    } else {
      payload.with_childs = true;
    }

    const response = await AXIOS_INSTANCE.post(URL, payload);
    const { status } = response.data;
    if (status === 'success') {
      const summaryList = response.data.data.items.events;
      if (!summaryList && !Array.isArray(summaryList)) {
        return Promise.reject(new Error('Invalid data format'));
      }
      return Promise.resolve(summaryList);
    }
    return Promise.reject(response.data.description);
  } catch (error) {
    return Promise.reject(error);
  }
};

const eventsSummaryChartService = {
  /**
   * Асинхронно загружает события для графика
   *
   * @param {string} locationId - Имя локации.
   * @param {boolean} withChilds  - with_childs.
   * @returns {Promise<Object>} - Обещание с событиямми.
   * @throws {Error} - Ошибка при загрузке событий.
   */
  fetchSummaryEventsData: async (locationId, withChilds) => {
    try {
      store.commit('setLoading');
      const summaryList = await summaryEventsDataFetcher(locationId, withChilds);
      store.commit('setSuccess');
      return Promise.resolve(summaryList);
    } catch (e) {
      store.commit('setError', e);
    }
  }
};

export default eventsSummaryChartService;
