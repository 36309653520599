<template>
  <WDropdown
    position="bottom-left"
    :openCloseToggleMode="true"
  >
    <template v-slot:button-inner-content>
      <i class="fa fa-gear fa-2x icon-cols"/>
    </template>
    <template v-slot:inner-content>
      <div class="list-wrapper">
        <WList
          v-if="filteredColumns.length"
          :fixedItems="{ maxItems: 6 }"
        >
          <li v-for="column in filteredColumns"
            :key="column.field"
            class="list-item"
            @click="toggleColumnVisibility(column)"
            >
              <WCheckbox
                :label="column.label"
                :name="column.field + '_checkbox'"
                :id="column.field + '_checkbox'"
                :checked="!column.hidden"
                @change="toggleColumnVisibility(column)"
              />
          </li>
        </WList>
      </div>
    </template>
  </WDropdown>
</template>

<script>
/**
 * компонент - дропдаун переключения видимости столбцов таблицы.
 * компонент локальный: используется только в WTable.vue
 * @component
*/

export default {
  name: 'ColumnVisibilityDropdown',
  props: {
    columns: {
      type: Array,
      required: true
    }
  },
  data() {
    return {};
  },
  watch: {},
  computed: {
    filteredColumns() {
      return this.columns.filter(columnSettings => {
        const isVisibilityDropdownSettingsInColumnSettings = Object.prototype.hasOwnProperty.call(columnSettings, 'visibilityDropdownSettings');

        if (!isVisibilityDropdownSettingsInColumnSettings) {
          return true;
        }

        const isDisableToToggleVisibilityOfCurrentColumn = Object.prototype.hasOwnProperty.call(columnSettings.visibilityDropdownSettings, 'hidden')
          && columnSettings.visibilityDropdownSettings.hidden;

        if (isDisableToToggleVisibilityOfCurrentColumn) {
          return false;
        }

        return true;
      });
    }
  },
  methods: {
    toggleColumnVisibility(column) {
      const { field } = column;

      this.$emit('on-change-column-visibility', field);
    }
  },
  mounted() {
  }
};
</script>

<style lang="css" scoped>
.list-wrapper {
  min-width: 200px;
  padding: 16px 16px;
  background-color: var(--table-layout-bg-color);
  color: var(--table-font-color);
  /* box-shadow: var(--box-shadow); */
  border-radius: 4px;
  border: 1px solid var(--table-border-color);

  position: relative;
}

.list-item {
  padding: 0;
  cursor: pointer;
  transition: var(--transition);
}

.list-item:hover {
  opacity: var(--opacity);
}
.list-item:not(:last-of-type) {
  margin-bottom: 4px;
}

.icon-cols {
  color: var(--brand-secondary);
}
</style>
