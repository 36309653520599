/**
 * Модуль Vuex для режима автоматического (с заданным интервалом в ms) обновления данных на странице через подписанный колбэк.
 *
 * @module IntervalUpdateMode
 * @namespace
 *
 * Здесь размещаются стейты, геттеры, мутации и экшны, специфические для локализации.
 * Для использования в компонентах и других модулях Vuex.
 *
 * @see {@link https://v3.vuex.vuejs.org/ru/guide/modules.html} doc о модулях vuex
 */

import dashboardService from '@/services/dashboardService';
import { IntervalUpdateMaster } from '@/utils';

const DEFAULT_REFRESH_INTERVAL = 0;

const initialIntervalUpdateValue = IntervalUpdateMaster.getCachedValue()
  ? Number.parseInt(IntervalUpdateMaster.getCachedValue(), 10)
  : DEFAULT_REFRESH_INTERVAL;

const intervalUpdateMode = {
  state: () => ({
    intervalUpdateValue: initialIntervalUpdateValue, // в ms
    intervalFunctionLabel: null, // метка активного интервала с выполняющимся колбэком. Если она не null, то callbackFunction выполняется с заданным интервалом intervalUpdateValue.
    callbackFunction: null // колбэк, которым подписались на вызов в интервале.
  }),
  getters: {
    /**
     * Значение интервала для обновления в ms.
     * @param {object} state - Состояние модуля Vuex.
     * @returns {number} Значение интервала в ms.
     */
    intervalUpdateValue: state => state.intervalUpdateValue,
    /**
     * Проверяет, активен ли режим автоматического обновления данных.
     * @param {object} state - Состояние модуля Vuex.
     * @returns {boolean} true, если режим активен, в противном случае false.
     */
    isIntervalUpdateModeActive: state => Boolean(state.intervalUpdateValue)
  },
  mutations: {
    /**
     * Обновляет значение интервала - intervalUpdateValue.
     * !!! Напрямую в компонентах не используется
     * @param {object} state - Состояние модуля Vuex.
     * @param {number} intervalValue - Новое значение интервала в ms.
    */
    _setIntervalUpdateValue(state, intervalValue) {
      state.intervalUpdateValue = intervalValue;

      // если 0 или <0, очищаем хранилище
      if (intervalValue <= 0) {
        IntervalUpdateMaster.clearCachedValue();
        return;
      }

      IntervalUpdateMaster.setCachedValue(intervalValue);
    },
    /**
     * Устанавливает инстанс коллбэка, обернутого в интервал.
     * Запускает callbackFunction в интервале и сохраняет метку в intervalFunctionLabel (по ней можно остановить колбэк).
     * !!! Напрямую в компонентах не используется
     * @param {object} state - Состояние модуля Vuex.
     */
    _setIntervalFunctionLabel(state) {
      // если есть старый, удаляем
      if (state.intervalFunctionLabel) {
        clearInterval(state.intervalFunctionLabel);
      }

      const intervalFunctionLabel = setInterval(() => {
        state.callbackFunction();
      }, state.intervalUpdateValue);

      state.intervalFunctionLabel = intervalFunctionLabel;
    },
    /**
     * Очищает инстанс коллбэка, обернутого в интервал.
     * Фактически останавливает вызов callbackFunction с интервалом.
     * !!! Напрямую в компонентах не используется
     * @param {object} state - Состояние модуля Vuex.
     */
    _clearIntervalFunctionLabel(state) {
      if (!state.intervalFunctionLabel) {
        return;
      }

      clearInterval(state.intervalFunctionLabel);
      state.intervalFunctionLabel = null;
    },
    /**
     * Устанавливает callbackFunction.
     * !!! Напрямую в компонентах не используется
     * @param {object} state - Состояние модуля Vuex.
     * @param {function} callback - колбэк, который предназначен для вызова с интервалом.
     */
    _setCallbackFunction(state, callback) {
      state.callbackFunction = callback;
    },
    /**
     * Очищает callbackFunction.
     * !!! Напрямую в компонентах не используется
     * @param {object} state - Состояние модуля Vuex.
     */
    _clearCallbackFunction(state) {
      if (!state.callbackFunction) {
        return;
      }

      state.callbackFunction = null;
    }
  },
  actions: {
    /**
     * Включает IntervalUpdateMode.
     * Если есть подписанный callbackFunction на момент вызова функции, запускает с ним интервал (intervalFunctionLabel).
     * @param {object} context - Контекст Vuex.
     * @param {object} context.state - Состояние модуля Vuex.
     * @param {function} context.getters - Функция для получения геттеров.
     * @param {function} context.commit - Функция для вызова мутаций.
     * @param {function} context.dispatch - Функция для вызова других экшнов.
     * @param {number | undefined} intervalValue - новое значение интервала.
     */
    enableIntervalUpdateMode({ state, commit, dispatch }, intervalValue) {
      // если приходит 0 или меньше 0, то выключаем
      if (intervalValue <= 0) {
        dispatch('disableIntervalUpdateMode');
        return;
      }

      // включаем IntervalUpdateMode (задаем таймаут обновления > 0)
      if (intervalValue > 0) {
        commit('_setIntervalUpdateValue', intervalValue);
      }

      // запускаем collbackFunction в интервале, если колбэк был ранее подписан
      if (state.callbackFunction) {
        commit('_setIntervalFunctionLabel');
      }
    },
    /**
     * Отключает IntervalUpdateMode. И останавливает вызов collbackFunction в интервале, если он был активен.
     * @param {object} context.state - Состояние модуля Vuex.
     * @param {function} context.getters - Функция для получения геттеров.
     * @param {function} context.commit - Функция для вызова мутаций.
     * @param {function} context.dispatch - Функция для вызова других экшнов.
     */
    disableIntervalUpdateMode({ commit }) {
      commit('_setIntervalUpdateValue', 0);

      // останавливает вызов collbackFunction в интервале.
      commit('_clearIntervalFunctionLabel');
    },
    /**
     * Подписывает collbackFunction на вызов в интервале.
     * И запустит collbackFunction, если intervalUpdateMode активен.
     * @param {object} context.state - Состояние модуля Vuex.
     * @param {function} context.getters - Функция для получения геттеров.
     * @param {function} context.commit - Функция для вызова мутаций.
     * @param {function} context.dispatch - Функция для вызова других экшнов.
     * @param {Function} onIntervalUpdateCallback - коллбэк, который требуется вызывать в интервале.
     */
    subscribeOnIntervalUpdate({ getters, commit, dispatch }, onIntervalUpdateCallback) {
      // подписываем колбэк на вызов в интервале
      const callback = () => {
        // добавляем к колбэку обязательный вызов обновления данных метрик контроллера.
        // Они должны вызываться и обновляться вместе с любым onIntervalUpdateCallback.
        dashboardService.getTotalObjInfo({
          onSuccess: (items) => {
            // todo refactor
            commit('setTotalObjInfo', items, { root: true });
          }
        });
        onIntervalUpdateCallback();
      };

      commit('_setCallbackFunction', callback);

      // если на момент подписки intervalUpdateMode уже активен, колбэк должен сразу вызваться с интервалом без лишних взаимодействий
      if (getters.isIntervalUpdateModeActive) {
        dispatch('enableIntervalUpdateMode');
      }
    },
    /**
     * Отписывает collbackFunction.
     * И останавливает вызов collbackFunction в интервале, если intervalUpdateMode активен.
     * @param {object} context.state - Состояние модуля Vuex.
     * @param {function} context.getters - Функция для получения геттеров.
     * @param {function} context.commit - Функция для вызова мутаций.
     * @param {function} context.dispatch - Функция для вызова других экшнов.
     */
    unsubscribeFromIntervalUpdate({ commit }) {
      // останавливает интервальный вызов колбэка, если он был
      commit('_clearIntervalFunctionLabel');
      // удаление колбэка
      commit('_clearCallbackFunction');
    }
  },
  namespaced: true
};

export default intervalUpdateMode;
