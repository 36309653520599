<template>
  <WTable
    style="height: calc(70vh)"
    max-height="calc(70vh - 100px)"
    :columns="columns"
    :rows="rows"
    :isDisabled="isDisabled"
    :bordered="false"
    :withToggleColumnVisibilityMode="!isOneColMode"
    :row-style-class="rowStyleClassFn"
    :select-options="{
      enabled: isSelectionRowsModeEnabled,
      selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
      disableSelectInfo: true, // disable the select info panel on top
      selectAllByGroup: false, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
    }"
    :pagination-options="{
      enabled: false, // isOneColMode // info: отключена из-за неовместимости с легаси
      remoteMode: true,
      mode: 'pages', // pages, records
      position: 'bottom',
      perPage: 10,
      perPageDropdownEnabled: true,
      perPageDropdown: [5, 10, 20, 40, 50],
      dropdownAllowAll: true,
      jumpFirstOrLast : true,
      firstLabel: $t('general.pagination.firstPage'),
      lastLabel: $t('general.pagination.lastPage'),
      nextLabel: $t('general.pagination.nextPage'),
      prevLabel: $t('general.pagination.prevPage'),
      rowsPerPageLabel: $t('general.pagination.rowsPerPage'),
      pageLabel: $t('general.pagination.page'),
      ofLabel: $t('general.pagination.of'),
      allLabel: $t('general.pagination.all')
    }"
    :isLoading.sync="isDataLoading"
    @update:columns="handleUpdateColumns"
    @on-selected-rows-change="handleSelectedRowsChange"
    @on-select-all="handleSelectedAllRowsChange"
    @on-cell-click="handleCellClick"
    @on-page-change="handlePageChange"
    @on-per-page-change="handlePerPageChange"
    @on-sort-change="onSortChange"
  >

    <template v-slot:table-menu>
      <slot name="table-menu"></slot>
    </template>

    <template v-slot:table-row="props">
      <template v-if="props.column.field === 'name'">
        <NameCell :props="props" />
      </template>

      <template v-else-if="props.column.field === 'model'">
        <ModelCell :props="props" />
      </template>

      <template v-else-if="props.column.field === 'status'">
        <StatusCell :props="props" @on-show-more-events="handleGoToEvents(props.row.nativeData)" />
      </template>

      <template v-else-if="props.column.field === 'ip'">
        <IPCell :props="props" />
      </template>

      <template v-else-if="props.column.field === 'two'">
        <TwoGhzCell :props="props" />
      </template>

      <template v-else-if="props.column.field === 'five'">
        <FiveGhzCell :props="props" @on-watch-events="handleGoToEvents(props.row.nativeData)" />
      </template>

      <template v-else-if="props.column.field === 'wired'">
        <WiredCell :props="props" />
      </template>

      <template v-else-if="props.column.field === 'radar'">
        <RadarCell :props="props" />
      </template>

      <template v-else-if="props.column.field === 'location'">
        <LocationCell :props="props" />
      </template>

      <template v-else-if="props.column.field === 'locationDescription'">
        <LocationDescriptionCell :props="props" />
      </template>

      <template v-else-if="props.column.field === 'tags'">
        <TagsCell :props="props" @on-tag-click="handleClickTag" />
      </template>

      <template v-else-if="props.column.field === 'firstConnection'">
        <TimeCell :dateTimestamp="props.row.firstConnection" />
      </template>

      <template v-else-if="props.column.field === 'lastConnection'">
        <TimeCell :dateTimestamp="props.row.lastConnection" />
      </template>

      <template v-else-if="props.column.field === 'lastDisconnection'">
        <TimeCell :dateTimestamp="props.row.lastDisconnection" />
      </template>

      <template v-else-if="props.column.field === 'delete'">
        <DeleteCell v-if="isShowDeleteAPButtonInTable(props.row.nativeData)"
          @on-delete-click="handleClickDeleteButton(props.row)"
        />
      </template>

      <template v-else>
        {{ props.formattedRow[props.column.field] ? props.formattedRow[props.column.field] : '-' }}
      </template>
    </template>

    <template v-slot:table-actions-bottom>
      <slot name="table-actions-bottom"></slot>
    </template>

  </WTable>
</template>

<script>
/**
 * компонент - таблица статистики по aps.
 * компонент локальный: используется только в Aps.vue
 * подробнее по настройкам и пропсам - см компонент WTable.vue
 * @component
*/

import { createNamespacedHelpers } from 'vuex';

import {
  generateTableColumns,
  generateTableRows
} from './config';

import {
  NameCell,
  ModelCell,
  StatusCell,
  IPCell,
  TwoGhzCell,
  FiveGhzCell,
  WiredCell,
  RadarCell,
  LocationCell,
  LocationDescriptionCell,
  TagsCell,
  TimeCell,
  DeleteCell
} from './components';

const { mapGetters: brandingMapGetters } = createNamespacedHelpers('branding');
const { mapGetters: featureFlagsMapGetters } = createNamespacedHelpers('featureFlags');

export default {
  name: 'ApsTable',
  components: {
    NameCell,
    ModelCell,
    StatusCell,
    IPCell,
    TwoGhzCell,
    FiveGhzCell,
    WiredCell,
    RadarCell,
    LocationCell,
    LocationDescriptionCell,
    TagsCell,
    TimeCell,
    DeleteCell
  },
  props: {
    items: {
      type: Array,
      required: true
    },
    activeRowApId: {
      type: String,
      default: ''
    },
    selectedRowApIds: {
      type: Array,
      required: true
    },
    isDataLoading: {
      type: Boolean,
      default: false
    },
    isDisabled: {
      type: Boolean,
      default: false
    },
    isOneColMode: {
      type: Boolean,
      default: false
    },
    isSelectionRowsModeEnabled: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      isTableColumnHidden: {
        name: false,
        model: false,
        status: false,
        ip: false,
        two: false,
        five: false,
        wired: false,
        radar: false,
        location: false,
        locationDescription: false,
        tags: true,
        firstConnection: false,
        lastConnection: false,
        lastDisconnection: false,
        delete: false
      }
    };
  },
  watch: {
  },
  computed: {
    ...brandingMapGetters(['isBeelineBranding']),
    ...featureFlagsMapGetters(['isContentAnalyticsEnabled']),
    columns: {
      get() {
        return generateTableColumns({
          i18nInstance: this.$i18n,
          hiddenColumnRules: {
            name: this.isTableColumnHidden.name,
            model: this.isTableColumnHidden.model || this.isOneColMode,
            status: this.isTableColumnHidden.status,
            ip: this.isTableColumnHidden.ip || this.isOneColMode,
            two: this.isTableColumnHidden.two || this.isOneColMode,
            five: this.isTableColumnHidden.five || this.isOneColMode,
            wired: this.isTableColumnHidden.wired || this.isOneColMode || !this.isBeelineBranding,
            radar: this.isTableColumnHidden.radar || this.isOneColMode || !this.isContentAnalyticsEnabled,
            location: this.isTableColumnHidden.location || this.isOneColMode || !this.areLocationsVisible,
            locationDescription: this.isTableColumnHidden.locationDescription || this.isOneColMode || !this.areLocationsVisible,
            tags: this.isTableColumnHidden.tags || this.isOneColMode,
            firstConnection: this.isTableColumnHidden.firstConnection || this.isOneColMode,
            lastConnection: this.isTableColumnHidden.lastConnection || this.isOneColMode,
            lastDisconnection: this.isTableColumnHidden.lastDisconnection || this.isOneColMode,
            delete: this.isTableColumnHidden.delete || this.isOneColMode
          },
          additionalRules: {
            isBeelineBranding: this.isBeelineBranding,
            isContentAnalyticsEnabled: this.isContentAnalyticsEnabled,
            areLocationsVisible: this.areLocationsVisible
          }
        });
      },
      set(updatedColumns) {
        // обновить таблицу, когда обновились данные в колонках
        // например, какую-то колонку скрыли/раскрыли
        updatedColumns.forEach(el => {
          this.isTableColumnHidden[el.field] = el.hidden;
        });
      }
    },
    rows() {
      return generateTableRows({
        apsData: this.items,
        selectedRowApIds: this.selectedRowApIds
      });
    },
    isRootAdmin() {
      return this.$store.getters.isRootAdmin;
    }
  },
  methods: {
    isShowDeleteAPButtonInTable(apNativeData) {
      if (this.isDisabled || (apNativeData.connected && !this.isRootAdmin)) {
        return false;
      }
      return true;
    },
    handleUpdateColumns(columns) {
      this.columns = columns;
    },
    rowStyleClassFn(row) {
      const rowCpeId = row.nativeData.id;

      return this.activeRowApId === rowCpeId ? 'aps-table__active-row' : '';
    },
    handleSelectedAllRowsChange(event) {
      this.$emit('on-select-all', event);
    },
    handleSelectedRowsChange(event) {
      const { selectedRows } = event;
      this.$emit('on-selected-rows-change', selectedRows);
    },
    handleCellClick(event) {
      this.$emit('on-cell-click', event.row);
    },
    handlePageChange(event) {
      this.$emit('on-page-change', event);
    },
    handlePerPageChange(event) {
      this.$emit('on-per-page-change', event);
    },
    onSortChange(event) {
      this.$emit('on-sort-change', event);
    },
    handleGoToEvents(cpeNativeData) {
      this.$emit('go-to-events', cpeNativeData);
    },
    handleClickTag(tag) {
      this.$emit('on-tag-click', tag);
    },
    handleClickDeleteButton(row) {
      this.$emit('on-delete-ap', row.nativeData);
    }
  }
};
</script>

<style lang="css" scoped>
</style>

<style lang="css">
.aps-table-td-wrapper {
  max-width: 130px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.aps-table__active-row {
  background: color-mix(in srgb, var(--brand-success) 70%, transparent);
  color: var(--font-color-main);
}

.aps-table__active-row:hover {
  background: color-mix(in srgb, var(--brand-success) 70%, transparent) !important;
  color: var(--font-color-main) !important;
}
</style>
