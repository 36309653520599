import permissions from './permissions';

const FULL_ACCESS = 'all';

const roleExists = (user) => permissions.hasOwnProperty(user);

const getRolePermissions = (role, permissions) => permissions[role];

const getMatchingRoute = (route, rolePermissions) => {
  const match = rolePermissions.find((item) => item.routes[0] === FULL_ACCESS || item.routes.includes(route));
  return match || null;
};

const hasPermissionToRoute = (action, route) => route.actions[0] === FULL_ACCESS || route.actions.includes(action);

/**
 * Checks if user role can (or cant) perform action on certain route
 * @param {string} action
 * @param {object} [props] Optional props
 * @param {string} [props.route] Target route
 * @param {boolean} [props.debug] Prints debug info to console
 * @return {boolean}
 */

function $can(action, props = {}) {
  let route;
  /* Evaluates current route by default or any other route if provided */
  if (props.route) {
    route = props.route;
  } else {
    route = this.$route ? this.$route.name : null;
  }
  /* Required because on initial beforeEach route guard store is yet empty */
  const role
        = this.$store.state.userData.role === ''
          ? JSON.parse(localStorage.getItem('userData')).role
          : this.$store.state.userData.role;

  if (!role || !route || !permissions || !action) {
    return false;
  }
  if (!roleExists(role)) {
    return false;
  }

  const rolePermissions = getRolePermissions(role, permissions);
  const matchingRoute = getMatchingRoute(route, rolePermissions);
  if (!matchingRoute) {
    return false;
  }

  if (props.debug) {
    console.group(`${route} - ${role} - ${action}: ${new Date().toLocaleTimeString()}`);
    console.log('route:', route);
    console.log('role:', role);
    console.log('action:', action);
    console.log('permissions:', permissions);
    console.log('rolePermissions:', rolePermissions);
    console.log('matchingRoute:', matchingRoute);
    console.log('hasPermissionToRoute:', hasPermissionToRoute(action, matchingRoute));
    console.groupEnd();
  }

  return hasPermissionToRoute(action, matchingRoute);
}

export default $can;
