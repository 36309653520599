import { createNamespacedHelpers } from 'vuex';

const { mapGetters: featureFlagsMapGetters } = createNamespacedHelpers('featureFlags');

// todo: этот миксин используется в каждом компоненте. Это антипаттерн. Пока что так оставлено для совместимости при первичном рефакторинге
// В дальшейшем - отрефактроить и примешивать его только там, где он нужен / удалить его совсем
const locationVisibilityMixin = {
  computed: {
    ...featureFlagsMapGetters(['areLocationsHidden']),
    areLocationsVisible() {
      return !this.areLocationsHidden;
    }
  }
};

export default locationVisibilityMixin;
