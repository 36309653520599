import Vue from 'vue';
import Vuex from 'vuex';
import commonService from '@/services/commonService';
import helpers from '../helpers';
import locationService from '../services/locationService';
import { API_URL } from '@/config';
import {
  ONEProduct,
  WLCProduct,
  locale,
  apiStatus,
  branding,
  featureFlags,
  theme,
  menu,
  auth,
  intervalUpdateMode,
  navigation,
  autologout
} from './modules';

Vue.use(Vuex);

const store = new Vuex.Store({
  state: {
    menuShowForExperementalBuild: 'new', //' old', 'new'
    inHeaderLocationHideShowChangedTimestamp: 0,
    selectedOnTemplateWizStep1Location: undefined,
    currentRouteComponentName: '',
    // defaultLocationForRequests: undefined,
    // defaultWithChildsForRequests: true,
    requestBaseUserLocationIfNoSelected: false,
    showAllSettingsOnSNMPPartitionPages: false, // для задачи DF-77 чтобы временно скрыть нереализованые настройки
    stepNameForReturningToCreateWLANWizard: undefined,
    stepNameForAdvancedTabForReturningToCreateWLANWizard: undefined,
    returnToEditWlan: false,
    returnToWlanWizard: false,
    inHeaderPreviouslySelectedLocationsObjects: [],
    inHeaderLocationSelectBlockDataChangedTimestamp: 0,
    inHeaderLocationSelectModeEnabled: true, // чтобы можно было отключить быстро применение этого селектора
    inHeaderLocationSelectBlockData: {
      withChilds: true,
      selectedLocationID: '',
      selectedLocationAndChildsLocationsIDs: [],
      selectedLocationObject: [],
      // previouslySelectedLocationsObjects: [],
      userBaseLocation: '',
    },
    dashboardBlocksLocationSettingsForGetStatistic: {
      locationId: '',
      with_child: false,
    },
    dashboardBlocksVisibility: {
      nameInLocalStorage: 'dashboardBlocksVisibility',
      general: {
        serverInfo: true,
        cpu: true,
        cpuLoad: true,
        mem: true,
        cpuLoadChart: true,
        memoryUsedChart: true,
        connectedCPEsChart: true,
        configCPEsChart: true,
        eventsChart: true,
        mostLoadCPES: true,
        mostActiveCPES: true,
        mostActiveClients: true,
        visitors: true,
      },
      marketer: {
        marketerViewLocations: true,
        marketerViewlocationsWhereManager: true,
        marketerViewLocationsMap: true,
        marketerViewAds: true,
        marketerViewAdsadsViewStatistic: true,
        marketerViewConnectionsStatistic: true,
        visitors: true
      }
    },
    dashboardBlocksCollapse: {
      nameInLocalStorage: 'dashboardBlocksCollapse',
      general: {
        eventsChart: true,
        mostLoadCPES: true,
        mostActiveCPES: true,
        mostActiveClients: true,
        visitors: true,
      },
      marketer: {
        locations: true,
        mapOfLocations: true,
        advertisement: true,
        advertisementStatistic: true,
        connectionsStatistic: true,
        locationsWhereManager: true,
        visitors: true
      }
    },
    getAllCompactCpesWithDataRemainingRequestExecuting: false,
    getAllCompactWlansWithDataRemainingRequestExecuting: false,
    currentBuildHash: '',
    isNewVersionCheckerEnabled: false,
    updateCpesConfigFromUploadedConfigExecuting: false,
    getCpesCompactFromUploadedConfigRequestExecuting: false,
    loadingSNMPCommunityStringsRequestExecuting: false,
    updateSNMPCommunityStringsRequestExecuting: false,
    createSNMPCommunityStringsRequestExecuting: false,
    deleteSNMPCommunityStringsRequestExecuting: false,
    loadingSNMPHostsRequestExecuting: false,
    updateSNMPHostsRequestExecuting: false,
    createSNMPHostsRequestExecuting: false,
    deleteSNMPHostsRequestExecuting: false,
    loadingSNMPUserGroupsRequestExecuting: false,
    updateSNMPUserGroupsRequestExecuting: false,
    createSNMPUserGroupsRequestExecuting: false,
    deleteSNMPUserGroupsRequestExecuting: false,
    loadingSNMPUsersRequestExecuting: false,
    updateSNMPUsersRequestExecuting: false,
    createSNMPUsersRequestExecuting: false,
    deleteSNMPUsersRequestExecuting: false,
    loadingSNMPGeneralRequestExecuting: false,
    loadingSNMPGeneralForWirelessTrapsRequestExecuting: false,
    loadingSNMPGeneralForGeneralRequestExecuting: false,
    loadingSNMPGeneralForViewsRequestExecuting: false,
    updateSNMPGeneralRequestExecuting: false,
    updateSNMPGeneralForWirelessTrapsRequestExecuting: false,
    updateSNMPGeneralForGeneralRequestExecuting: false,
    forceRRMGroupRequestExecuting: false,
    getCpesCompactForFilterRequestExecuting: false,
    getWlansCompactForFilterRequestExecuting: false,
    getAccountByIdFromVoucherRequestExecuting: false,
    getFirmwareListRequestExecuting: false,
    loadingDITStatList: false,
    loadingDBStateList: false,
    loadingPeriodicTasksList: false,
    loadingConfigForSaveInFile: false,
    uploadingConfigToAPI: false,
    filteringAndSortingDBStateList: false,
    updateDITExportReqExecuting: false,
    createDITExportReqExecuting: false,
    deleteDITExportReqExecuting: false,
    updatePeriodicTaskReqExecuting: false,
    createPeriodicTaskReqExecuting: false,
    deletePeriodicTaskReqExecuting: false,
    cpeLatestStatsRequestExecuting: false,
    loadingAuthentications: false,
    loadingAuthorizations: false,
    wlanWizardGoingToLastStepAfterClickSkipButtonInProgess: false,
    getSMSCostForPreviousPeriodRangeForStatisticRequestExecuting: false,
    getSMSCostForRangeForStatisticRequestExecuting: false,
    getSMSCostForRangeRequestExecuting: false,
    getSMSCostForPrevRangeRequestExecuting: false,
    clickUserPointAfterClickSpiderfyDataForSeeUserInfo: false,
    clickSpiderfyDataForSeeUserInfo: false,
    clickManyClientsPointToSeeUserInfo: false,
    clickInClusterDataForSeeUserInfo: false,
    clickedClusterDataFiltereInMapsFilteringExecute: false,
    isGetRadarExportsReqExecuted: false,
    showLoadBalancingSwitcher: false, // используется для задачи  WNE-2650, в которой требуется пока что скрыть свитчер из UI
    filteringCpeListInRadarExportModalWindow: false,
    filteringCpeListInDITExportModalWindow: false,
    filteringCpeListInPeriodicTasksModalWindow: false,
    filteringWlanListInPeriodicTasksModalWindow: false,
    userRegistersTheCpeHimselfCheckIsCpeAlreadyRegisteredRequestExecuting: false,
    userRegistersTheCpeHimselfRequestExecuting: false,
    userRegistersTheCpeHimself: false,
    userRegistersTheCpeHimselfCpeId: '',
    loadingMapCpesStatisticAndClients: false,
    manyClientsPointDataLoadingInMaps: false,
    discardingCPEsErrors: false,
    loadingSNMPConfig: false,
    loadingSNMPConfigForManyCPEs: false,
    localeSwitchedTimeStamp: 0,
    baseLocation: '',
    userData: {
      username: '',
      email: '',
      location: '',
      createdat: '',
      id: '',
      permissions: {},
      photo: '',
      role: ''
    },
    subordinateLogin: false,
    wlanWizardData: {
      name: '',
      ssid: '',
      description: ''
    },
    //  state
    sendingCPEConfigScriptFromEvents: false,
    setTimeUsingNTPRequestExecuting: false,
    sendCPEConfigScriptFromCpesExecuting: false,
    syncTimeRequestExecuting: false,
    getCPEClientsListFromCpeCommandRequestExecuting: false,
    getCPELogsFromCpeCommandRequestExecuting: false,
    getCPEWLANSInfoFromCpeCommandRequestExecuting: false,
    loadingSNMPStat: false,
    loadingNTPServers: false,
    createNTPServerRequestExecuting: false,
    updateNTPServerRequestExecuting: false,
    updateNTPSchemaRequestExecuting: false,
    deleteNTPServerRequestExecuting: false,
    filteringSNMPStat: false,
    filteringPeriodicTasks: false,
    loadingWlans: false,
    loadingCpes: false,
    loadingCpesForWifiAnalyze: false,
    loadingUsers: false,
    loadingUsersWithBeeProBisId: false,
    loadingUsersWithBeeProCTN: false,
    loadingCpeModels: false,
    loadingCpeTemplates: false,
    loadingAccount: false,
    loadingLocations: false,
    loadingPortalProfiles: false,
    loadingPortalProfilesLocations: false,
    loadingPortalPages: false,
    loadingPortalSessions: false,
    loadingPortalPagesLocations: false,
    loadingPortalAds: false,
    loadingPortalAdsLocations: false,
    loadingPortalAdsStats: false,
    loadingPortalBirthdays: false,
    loadingPortalUserAccounts: false,
    loadingPortalTariffs: false,
    loadingPortalVouchers: false,
    loadingPortalPaymentSystems: false,
    loadingPortalVouchersStat: false,
    loadingRadius: false,
    loadingRRM: false,
    loadingControllers: false,
    loadingExternalAps: false,
    loadingFirewalls: false,
    loadingRedirects: false,
    loadingHotspots: false,
    loadingClients: false,
    loadingClientsRFP: false,
    loadingSummary: false,
    loadingVisitors: false,
    loadingStats: false,
    loadingRules: false,
    loadingCpesRules: false,
    loadingMaps: false,
    loadingSystemInfo: false,
    loadingSystemInfoForDashboard: false,
    loadingWlansInfoForDashboard: false,
    loadingCpesInfoForDashboard: false,
    loadingClientsInfoForDashboard: false,
    loadingEventsCharts: false,
    loadingDashboardsCharts: false,
    loadingMapClients: false,
    loadingCpesRating: false,
    loadingCpesScanner: false,
    loadingclientRF: false,
    loadingClientRFRemainingData: false,
    loadingEventsForCilent: false,
    loadingclientDistance: false,
    loadingCpesSessions: false,
    loadingCpesForStat: false,
    loadingSystemRating: false,
    loadingCpesClients: false,
    loadingWlansRating: false,
    loadingClientsRating: false,
    loadingClientsRaw: false,
    loadingCpesExport: false,
    loadingReports: false,
    loadingAdsViewsDataForMarketerDashboard: false,
    loadingAdsInfoForMarketerDashboard: false,
    loadingAdsStatsInfoForMarketerDashboard: false,
    loadingCpeInfoForSelectedCpeOnMap: false,
    loadingUsersInfoForSelectedCpeOnMap: false,
    loadingPortalLocationsStat: false,
    loadingPortalPagesSchedules: false,
    loadingOperations: false,
    createdMapData: null,
    dashboardLoadingCount: 0,
    activeRequestsForConnectionsStatisticInDashboardMarketerView: 0,
    updateMap: false,
    systemInfo: {
      host: {
        uptume: '',
        os: '',
        modelName: ''
      },
      cpu: {
        cpu: '',
        cores: '',
        cacheSize: ''
      },
      mem: {
        total: '',
        available: '',
        free: '',
        usedPercent: ''
      }
    },
    // license
    license: false,
    licenceFileUploadingRequestExecuting: false,
    licenceTextActivateRequestExecuting: false,
    licencesInfoGetRequestExecuting: false,

    // info
    wlansInfo: false,
    cpesInfo: false,
    clientsInfo: false,
    eventsList: false,
    systemEventsList: false,
    systemTotalObjList: false,
    cpesEventsList: false,
    clientsEventsList: false,
    monitorEventsList: false,

    systemRatingList: false,
    cpesRatingList: false,
    wlansRatingList: false,
    clientsRatingList: false,

    cpesSessionsList: false,
    clientsSessionsList: false,

    percentsOfClientsMarkerDownloadsRequestsCount: undefined,
    // data
    wlansList: false,
    wlansItems: false,
    hostsList: false,
    cpesList: false,
    cpesListForWifiAnalyze: false,
    controllersList: false,
    externalAPsList: false,
    hotspotsList: false,
    cpeModelsList: false,
    cpeTemplatesList: false,
    cpesTagsList: false,
    wlansTagsList: false,
    clientsList: false,
    clientsRFPList: false,
    clientsStatList: false,
    clientsRawList: false,
    clientIndex: false,
    clientMac: false,
    usersList: false,
    locationsItems: false,
    companysItems: false,
    locationsList: false,
    locationsListSorted: false,
    radiusList: false,
    radiusListForRadiusV2Page: false,
    radarExportList: false,
    reportsList: false,
    portalProfilesList: false,
    portalProfilesLocationsList: false,
    portalPagesList: false,
    portalSessionsList: false,
    portalPagesLocationsList: false,
    portalAdsList: false,
    portalAdsLocationsList: false,
    portalAdsStatsList: false,
    portalAuthenticationsList: false,
    portalAuthorizationsList: false,
    portalUserAccountsList: false,
    portalUserAccountsListByBirthday: false,
    portalUserTransactionsList: false,
    portalTariffsList: false,
    portalVouchersList: false,
    portalVouchersStatList: false,
    portalPaymentSystemsList: false,
    portalLocationsStatList: false,
    portalPagesSchedulesList: [],
    rrmList: false,
    firewallsList: false,
    redirectsList: false,
    rulesList: false,
    cpesRulesList: false,
    mapsList: false,
    marketingSummary: false,
    mapImageData: false,
    uploadMapImageResult: false,
    currentImageData: false,
    trafficBubbleLoading: false,
    clientsBubbleLoading: false,
    lbsClientsBubbleLoading: false,
    editWlanIndex: false,
    editCpeIndex: false,
    editRadiusIndex: false,
    editRadiusIndexForRadiusV2Page: false,
    editClientIndex: false,
    editClientForRFPIndex: false,
    editMapIndex: false,
    editMapId: '',
    editCpeId: '',
    statCpeId: '',
    statLocationId: '',
    editWlanId: '',
    statWlanId: '',
    editRadiusId: '',
    editRadiusIdForRadiusV2Page: '',
    editTemplateId: '',
    editFirewallId: '',
    editRedirectId: '',
    editHotspotId: '',
    editClientMac: '',
    editClientForRFPMac: '',
    editClientMacAddr: '',
    editClientForRFPMacAddr: '',
    statClientId: '',
    muteCpeEdit: false,
    muteCpesDelete: false,
    muteCpeFilters: false,
    muteCpesRegistration: false,
    uploadFW: false,
    hideAllOperations: false,
    WebAccess: false,
    muteLocationEdit: false,
    muteWlanEdit: false,
    muteRadiusEdit: false,
    muteExportEdit: false,
    mutePortalProfileEdit: false,
    mutePortalAdEdit: false,
    mutePortalPageEdit: false,
    mutePortalTariffEdit: false,
    mutePortalVoucherEdit: false,
    mutePortalUserAccountEdit: false,
    mutePortalPaymentSystemEdit: false,
    muteRedirectEdit: false,
    muteControllerEdit: false,
    muteExternalApEdit: false,
    muteHotspotEdit: false,
    muteRRMEdit: false,
    muteReportEdit: false,
    muteReportDelete: false,
    muteChangeLocation: false,
    editUserIndex: false,
    newWlanWizardSecurityTab: 'L2',
    newWlanData: {
      base_location: '',
      ssid: '',
      description: '',
      security: {
        type: '',
        data: {
          psk: '',
          suites: ['aes'],
          radiusauthentication: []
        }
      },
      // vlan: '',
      vlan: 0,
      hidden: false,
      filtermode: 'None',
      // даллее поля для шага 3 визарда создания wlan
      tunneling: false,
      firewall: {
        nat_access: false,
        l2_chain: ''
      },
      default_tunnel: '',
      nat: false,
      nat_network: {
        ipaddr: '',
        netmask: ''
      },
      proto: 'l2tpv3',
      peer_address: '',
      // далее поля для шага 4
      radius_acct_mirroring: false,
      beeline_accountng_type: '',
      radius_acct_servers: [],
      // далее поля для шага 5
      nas_id: null,
      nas_port_id: '',
      // далее поля для шага 6
      speed_download: { type: 'kbit', value: '' },
      speed_upload: { type: 'kbit', value: '' },
      // далее поля для шага 7
      guest_control: {
        captive_redirect: '',
        mac_radius_auth_servers: []
      },
      l2isolate: false,
      whitelist: [],
      blacklist: [],
      // далее поля для шага 8
      roam80211r: false,
      pmkcaching: false,
      ieee80211k: false,
      rrm_neighbor_report: true,
      rrm_beacon_report: false,
      ieee80211v: false,
      wnm_sleep_mode: false,
      bss_transition: false,
      rssi_threshold: -90,
      signal_stay: -60,
      signal_strikes: 3,
      signal_poll_time: 5,
      signal_drop_reason: 3,
      band_steering: false,
      ft_over_ds: false,
      nas_generate: false,
      load_balancing: false,
      // далее для шага 9
      wmm: {
        disabled: false,
        uapsd: false,
        categories: {}
      },
      // dhcpOption82CircuitId: '',
      option82state: false,
      option82rid_type: 'APMAC:SSID',
      option82cid_type: ''
    },
    newRadiusData: {
      auth_port: '1812',
      acc_port: '1813',
      is_local: true,
      is_portal: false,
      dae_client: '',
      // dae_port: '3799',
      // теперь не ставим по умолчанию, так как вообще обрали это поле из UI (DF-65)
      dae_port: '',
      dae_secret: ''
    },
    newTemplateData: {
      name: '',
      model: '',
      description: '',
      cpes: [],
      base_location: '',
      is_auto: true,
      is_always: false,
      subnet: {}
    },
    newFirewallData: {
      name: '',
      policy: 'ACCEPT',
      direction: 'ANY',
      rules: []
    },
    newRedirectRuleData: {
      name: '',
      redirect_url: '',
      redirect_ip: '',
      url_list: []
    },
    newHotspotData: {},
    newRuleData: {},
    currnetRuleData: {},
    operationsList: [],
    operationsTotal: 0,
    operations: {},
    requestsCancelTokens: {},
    wlanOperations: [],
    cpeOperations: [],
    radiusOperations: [],
    firewallOperations: [],
    redirectOperations: [],
    controllerOperations: [],
    externalApOperations: [],
    hotspotOperations: [],
    usersDemoWizardStepsData: {
      step1SavedLocationObject: false,
      step2SavedUserObject: false,
      step3SavedWlanObject: false,
      step4SavedProfileObject: false,
      step5SavedPageObject: false
    },
    easyInstallWizardStepsData: {
      step1SavedWlanObject: false,
      step1locationId: false,
      step1WlanId: false,
      step2SavedPageObject: false,
      step3RedirectObject: false,
      step3RedirectId: false,
      step4CpesWithWLANIDs: false,
      routeFromUserWentToWizard: false
    },
    showOnlyIcons: false,
    windowResizedFromDesktopToMobileWithMinimalisticSidebar: false,
    activeRequestsForPortalStatisticPage: {
      pie: {
        os: false,
        locale: false,
        type: false,
        vendor: false,
        all_visit: false,
        unique_visit: false,
        first_visit: false,
        auth: false,
        authen: false,
        browser: false
      },
      timeseries: {
        os: false,
        locale: false,
        type: false,
        vendor: false,
        all_visit: false,
        unique_visit: false,
        first_visit: false,
        auth: false,
        authen: false,
        browser: false
      }
    },
    activeRequestsForPortalStatisticPageForPreviousPeriod: {
      pie: {
        all_visit: false,
        unique_visit: false,
        first_visit: false
      }
    },
    // activeRequestsForPortalDashboardPage: {
    //   newUsers: false,
    //   notNewUsers: false,
    //   uniqClients: false,
    //   totalConnections: false,
    //   newUsersForPreviousPeriod: false,
    //   notNewUsersForPreviousPeriod: false,
    //   uniqClientsForPreviousPeriod: false,
    //   totalConnectionsForPreviousPeriod: false,
    //   authentications: false,
    //   authorizations: false,
    //   birthdays: false,
    //   os: false
    //
    // },
    activeRequestsForPortalDashboardPage: {
      loadingPortalBirthdaysForDashboard: false,
      pie: {
        os: false,
        all_visit: false,
        unique_visit: false,
        first_visit: false,
        auth: false,
        authen: false
      },
      timeseries: {
        os: false,
        all_visit: false,
        unique_visit: false,
        first_visit: false,
        auth: false,
        authen: false
      }
    },
    activeRequestsForPortalDashboardPageForPreviousPeriod: {
      pie: {
        os: false,
        all_visit: false,
        unique_visit: false,
        first_visit: false,
        auth: false,
        authen: false
      },
      timeseries: {
        os: false,
        all_visit: false,
        unique_visit: false,
        first_visit: false,
        auth: false,
        authen: false
      }
    },
    isPaywallForAuthFreemiumShowing: false,
    userBlockOrUnblockRequestExecute: false,
    portalSessionUpdateRequestExecuting: false,
    portalAccessServersGetRequestExecuting: false,
    portalAccessServersUpdateRequestExecuting: false,
    portalAccessServersCreateRequestExecuting: false,
    portalAccessServersDeleteRequestExecuting: false,
    isClickToGoToAnotherPage: false,
    updatedWlanIdsInOperations: [],
    isGetClientPathRequestExecuting: false
  },
  getters: {
    portalBaseUrl() {
      const baseUrl = `${API_URL ? '' : `${window.location.protocol}//`}${API_URL ? API_URL : window.location.host}`;
      const portalBaseUrl = `${baseUrl}/api/portal-preview/`;
      return portalBaseUrl;
    },
    portalBaseUrlForImgs() {
      const baseUrl = `${API_URL ? '' : `${window.location.protocol}//`}${API_URL ? API_URL : window.location.host}`;
      return baseUrl;
    },
    getInHeaderLocationSelectBlockData(state) {
      return state.inHeaderLocationSelectBlockData
    },
    getParentLocationIdAndChildLocationsIDsConcatenatedArrayByLocationId(state) {
      const storeState = state;
      return function (locationId) {
        const resultIDs = [locationId]
        const locationsList = JSON.parse(JSON.stringify(storeState.locationsListSorted))
        const parentLocationObject = locationsList.find(location => location.id === locationId);
        // console.log('parentLocationObject', parentLocationObject)
        if (parentLocationObject) {
          const parentLocationName = parentLocationObject.name;
          locationsList.forEach((location) => {
            // console.log ( location.name.slice(0, parentLocationName.length))
            // console.log(parentLocationName)
            // ищем дочерние локации
            if (location.name.length &&
              location.name.length >= parentLocationName.length &&
              location.name.slice(0, parentLocationName.length) === parentLocationName &&
              location.id !== locationId) {
              resultIDs.push(location.id)
            }
          });
        }
        return resultIDs;
      };
    },
    dashboardBlocksLocationSettingsForGetStatistic(state) {
      return state.dashboardBlocksLocationSettingsForGetStatistic;
    },
    getDashboardBlocksVisibility(state) {
      return state.dashboardBlocksVisibility;
    },
    getDashboardBlocksCollapse(state) {
      return state.dashboardBlocksCollapse;
    },
    // getDashboardBlocksVisibilityForMarketer(state) {
    //   return state.dashboardBlocksVisibility;
    // },
    isShowLoadBalancingSwitcher(state) {
      return state.showLoadBalancingSwitcher;
    },
    getRoutesForAddDefaultLocationAndWithChildsToReq(state) {
      return [
        // роуты где во все запросы, где не была указана локация
        // подсовывается выбраная в хедере локация (если она там выбрана)

        // НМС - Монитор
        'Events', 'Rules', 'Sessions', 'WifiAnalyzer',
        'ClientRF', 'ClientDistance', 'SNMPMonitoringConfig',
        'PeriodicTasks', 'DITStat',

        // НМС - Статистика
        'Statcpes', 'Statwlans', 'Statclients', 'Statreports', 'SNMPStat',

        // НМС
        'Aps', 'Controllers', 'RRM', 'Templates', 'Wlans', 'Clients', 'Radius', 'Hotspot', 'Firewall', 'GuestControl',
        'ExternalAPs',

        // Аналитика
        'Marketing', 'maps', 'Radar',

        // Портал
        'PortalDashboard', 'PortalProfilesNew', 'PortalPreview', 'PortalPagesNew', 'PortalHotelVouchers', 'PortalAdsNew',
        'PortalAccessServers', 'UserAccounts', 'PortalSessions', 'PortalStats', 'PortalBirthdays', 'PortalAdsStats', 'PortalLocationsStat',
        'Vouchers', 'Tariffs', 'PaymentSystems',

        // Остальное
        'ConfigExportImport', 'System', 'Users'

      ]
    },
    getDefaultLocationForRequests(state, getters){
      return function (ctx) {
        // ctx maybe undefined
        // return undefined
        // return state.defaultLocationForRequests;
        // return state.inHeaderLocationSelectBlockData.selectedLocationID;

        // const routesForAddDefaultLocationToReq = [
        //   // НМС - Монитор
        //   'Events', 'Rules', 'Sessions', 'WifiAnalyzer',
        //   'ClientRF', 'ClientDistance', 'SNMPMonitoringConfig',
        //   // 'PeriodicTasks', 'DITStat'
        //
        //   // НМС - Статистика
        //   'Statcpes', 'Statwlans', 'Statclients', 'Statreports', 'SNMPStat',
        //
        //   // НМС
        //   'Aps', 'Controllers', 'RRM', 'Templates', 'Wlans', 'Clients', 'Radius', 'Hotspot', 'Firewall', 'GuestControl',
        //
        //   // Аналитика
        //   'Marketing', 'maps', 'Radar',
        //
        //   // Портал
        //   'PortalDashboard', 'PortalProfilesNew', 'PortalPreview', 'PortalPagesNew', 'PortalHotelVouchers', 'PortalAdsNew',
        //   'PortalAccessServers', 'UserAccounts', 'PortalSessions', 'PortalStats', 'PortalBirthdays', 'PortalAdsStats', 'PortalLocationsStat',
        //   'Vouchers', 'Tariffs', 'PaymentSystems',
        //
        //   // Остальное
        //   'ConfigExportImport', 'System'
        //
        // ]

        const routesForAddDefaultLocationToReq = getters.getRoutesForAddDefaultLocationAndWithChildsToReq

        if (state.currentRouteComponentName !== '' && routesForAddDefaultLocationToReq.includes(state.currentRouteComponentName)){
          // console.log('return location id')
          // console.log(state.inHeaderLocationSelectBlockData)
          if (state.inHeaderLocationSelectBlockData.selectedLocationID === '') {
            return undefined
          }
          return state.inHeaderLocationSelectBlockData.selectedLocationID;
        } else {
          // console.log('undifined')
          return undefined;
        }
      }
    },
    getDefaultWithChildsForRequests(state, getters){
      return function (ctx){
        // ctx maybe undefined
        // return true
        // return state.defaultWithChildsForRequests;
        // return state.inHeaderLocationSelectBlockData.withChilds;

        // const routesForAddDefaultWithChildsForToReq = [
        //   // НМС - Монитор
        //   'Events', 'Rules', 'Sessions', 'WifiAnalyzer',
        //   'ClientRF', 'ClientDistance', 'SNMPMonitoringConfig',
        //   // 'PeriodicTasks', 'DITStat'
        //
        //   // НМС - Статистика
        //   'Statcpes', 'Statwlans', 'Statclients', 'Statreports', 'SNMPStat',
        //
        //   // НМС
        //   'Aps', 'Controllers', 'RRM', 'Templates', 'Wlans', 'Clients', 'Radius', 'Hotspot', 'Firewall', 'GuestControl',
        //
        //   // Аналитика
        //   'Marketing', 'maps', 'Radar',
        //
        //   // Портал
        //   'PortalDashboard', 'PortalProfilesNew', 'PortalPreview', 'PortalPagesNew', 'PortalHotelVouchers', 'PortalAdsNew',
        //   'PortalAccessServers', 'UserAccounts', 'PortalSessions', 'PortalStats', 'PortalBirthdays', 'PortalAdsStats', 'PortalLocationsStat',
        //   'Vouchers', 'Tariffs', 'PaymentSystems',
        //
        //   // Остальное
        //   'ConfigExportImport', 'System'
        // ]

        const routesForAddDefaultWithChildsForToReq = getters.getRoutesForAddDefaultLocationAndWithChildsToReq

        if (state.currentRouteComponentName !== '' && routesForAddDefaultWithChildsForToReq.includes(state.currentRouteComponentName) &&
        getters.getDefaultLocationForRequests()){
          return state.inHeaderLocationSelectBlockData.withChilds;
        } else {
          return true;
        }
      }
    },

    isNmsTest(state) {
      try {
        if (location.hostname.includes('nmstest') || location.hostname.includes('dev.wimark')) {
          return true;
        }
        return false;
      } catch (e) {
        return false;
      }
    },
    isExternalAPsPage(state, getters, rootState, rootGetters) {
      return rootGetters['featureFlags/isExperimentalModeEnabled'];
    },
    getBeeProBusinessIDForCurrentUser(state, getters, rootState, rootGetters) {
      const isBeelineBranding = rootGetters['branding/isBeelineBranding'];

      if (isBeelineBranding || getters.isNmsTest) {
        if (Object.prototype.hasOwnProperty.call(state.userData, 'bee_pro_bis_id')) {
          return state.userData.bee_pro_bis_id;
        }
        return undefined;
      }
      return undefined;
    },

    getBeeProCTNForCurrentUser(state, getters, rootState, rootGetters) {
      const isBeelineBranding = rootGetters['branding/isBeelineBranding'];

      if (isBeelineBranding || getters.isNmsTest) {
        if (Object.prototype.hasOwnProperty.call(state.userData, 'bee_pro_ctn')) {
          return state.userData.bee_pro_ctn;
        }
        return undefined;
      }
      return undefined;
    },
    isShowMapsAndLatitudeLongitudeSettings(state, getters, rootState, rootGetters) {
      return rootGetters['featureFlags/isExperimentalModeEnabled'] && (getters.isNmsTest ||  location.hostname.includes('192.168'));
    },
    getWPA3Types() {
      return ['wpa3personal', 'wpa3enterprise', 'wpa23personal', 'wpa23enterprise']
    },
    areAllEasyInstallWizardNotInProgress(state) {
      // если все поля в false - значит или еще не начанали визард, или уже закончили все шаги
      if (
        state.easyInstallWizardStepsData.step1SavedWlanObject === false &&
        state.easyInstallWizardStepsData.step1locationId === false &&
        state.easyInstallWizardStepsData.step1WlanId === false &&
        state.easyInstallWizardStepsData.step2SavedPageObject === false &&
        state.easyInstallWizardStepsData.step3RedirectObject === false &&
        state.easyInstallWizardStepsData.step3RedirectId === false &&
        state.easyInstallWizardStepsData.step4CpesWithWLANIDs === false
      ) {
        return true;
      }
      return false;
    },
    loadUserData(state) {
      const userDataFromLS = localStorage.getItem('userData');
      if (userDataFromLS) {
        state.userData = JSON.parse(userDataFromLS);
        return JSON.parse(userDataFromLS);
      }
      return state.userData;
    },
    isRootAdmin(state) {
      const { userData } = state;
      const location = commonService.showLocationFromId(state.userData.base_location);
      if (userData.role === 'admin' && location === '/') {
        return true;
      }
      return false;
    },
    // locationsListSorted(state) {
    //   // console.log('get sorted locations list with getter')
    //   return state.locationsListSorted;
    // },
    locationsListSorted(state) {
      // console.log('get sorted locations list with getter')
      return helpers.addCompanyNamesToLocationNames(state.locationsListSorted);
    },

    locationsListSortedOnlySelectedInHeaderLocationAndChilsdsIfEnabled(state) {
      const locationsBeforeFiltering = JSON.parse(JSON.stringify(state.locationsListSorted))
      const filtredLocations = [];
      const locationsIdsForFiltering = state.inHeaderLocationSelectBlockData.selectedLocationAndChildsLocationsIDs
      if (state.inHeaderLocationSelectBlockData.withChilds === true) {
        for (const location of locationsBeforeFiltering) {
          if (locationsIdsForFiltering.includes(location.id)) {
            // console.log('pushed', location)
            filtredLocations.push(location);
          }
        }
      } else {
        for (const location of locationsBeforeFiltering) {
          if (state.inHeaderLocationSelectBlockData.selectedLocationID === location.id) {
            // console.log('pushed', location)
            filtredLocations.push(location);
          }
        }

      }
      // console.log('get sorted locations list with getter')
      return helpers.addCompanyNamesToLocationNames(filtredLocations);
    },

    locationsListSortedWithoutCompanyName(state) {
      // console.log('get sorted locations list with getter')
      return state.locationsListSorted;
    },

    getCompanyList(state) {
      return state.companysItems;
    },

    companyListSorted(state) {
      // этот лист берет данные из обхектов - локаций, в настоящее время есть другой список с данными из ручки /api/party/locations
      try {
        const result = [];
        // console.log(state.locationsListSorted)
        let locationsList = JSON.parse(JSON.stringify(state.locationsListSorted));
        if (locationsList === false) {
          locationsList = [];
        }
        // console.log(locationsList)
        for (const location of locationsList) {
          if (location.hasOwnProperty('party') && location.party.hasOwnProperty('name') && location.party.name !== '') {
            // console.log(location)
            result.push({
              name: location.party.name,
              locationId: location.id,
              formal_name: location.party.formal_name,
              inn: location.party.inn,
              kpp: location.party.kpp,
              ogrn: location.party.ogrn,
              phone: location.party.phone
            });
          }
        }
        // console.log(result)
        return helpers.sortCompanysList(result);
      } catch (e) {
        console.log(e);
        return [];
      }
    }
  },
  mutations: {
    toggleOldNewMenu(state) {
      // console.log('here')
      if (state.menuShowForExperementalBuild === 'old') {
        state.menuShowForExperementalBuild = 'new';
      } else {
        state.menuShowForExperementalBuild = 'old';
      }
    },
    setSelectedOnTemplateWizStep1Location(state, id) {
      state.selectedOnTemplateWizStep1Location = id;
    },
    clearSelectedOnTemplateWizStep1Location(state) {
      state.selectedOnTemplateWizStep1Location = undefined;
    },
    setCurrentRouteComponentName(state, name){
      state.currentRouteComponentName = name;
    },
    // setDefaultLocationForRequests(state, locId){
    //   if (locId){
    //     state.defaultLocationForRequests = locId;
    //   } else {
    //     state.defaultLocationForRequests = undefined;
    //   }
    // },
    // setDefaultWithChildsForRequests(state, payload){
    //   if (payload !== undefined) {
    //     state.defaultWithChildsForRequests = payload;
    //   } else {
    //     state.defaultWithChildsForRequests = true;
    //   }
    // },
    setInHeaderLocationSelectBlockDataChangedTimestamp(state) {
      // обновляется при смене локации и/или флага with childs в селекторе в хэдере
      // обновляем текущим таймстампом, просто потому что не нужно придумывать какие-либо другие счетички
      // главное чтобы менялось какое-нибуь значение, чтобы следить за ним с помощью
      // вотчера в Full.vue
      // нужно чтобы перерисовыать раздел, когда сменилась выбраная лоакация
      state.inHeaderLocationSelectBlockDataChangedTimestamp = Date.now();
    },
    setInHeaderLocationHideShowChangedTimestamp(state) {
      state.inHeaderLocationHideShowChangedTimestamp = Date.now()
    },
    loadingSNMPCommunityStringsBegin(state) {
      state.loadingSNMPCommunityStringsRequestExecuting = true;
    },
    loadingSNMPCommunityStringsEnd(state) {
      state.loadingSNMPCommunityStringsRequestExecuting = false;
    },
    updateSNMPCommunityStringsReqBegin(state) {
      state.updateSNMPCommunityStringsRequestExecuting = true;
    },
    updateSNMPCommunityStringsReqEnd(state) {
      state.updateSNMPCommunityStringsRequestExecuting = false;
    },
    createSNMPCommunityStringsReqBegin(state) {
      state.createSNMPCommunityStringsRequestExecuting = true;
    },
    createSNMPCommunityStringsReqEnd(state) {
      state.createSNMPCommunityStringsRequestExecuting = false;
    },
    deleteSNMPCommunityStringsReqBegin(state) {
      state.deleteSNMPCommunityStringsRequestExecuting = true;
    },
    deleteSNMPCommunityStringsReqEnd(state) {
      state.deleteSNMPCommunityStringsRequestExecuting = false;
    },
    loadingSNMPHostsBegin(state) {
      state.loadingSNMPHostsRequestExecuting = true;
    },
    loadingSNMPHostsEnd(state) {
      state.loadingSNMPHostsRequestExecuting = false;
    },
    updateSNMPHostsReqBegin(state) {
      state.updateSNMPHostsRequestExecuting = true;
    },
    updateSNMPHostsReqEnd(state) {
      state.updateSNMPHostsRequestExecuting = false;
    },
    createSNMPHostsReqBegin(state) {
      state.createSNMPHostsRequestExecuting = true;
    },
    createSNMPHostsReqEnd(state) {
      state.createSNMPHostsRequestExecuting = false;
    },
    deleteSNMPHostsReqBegin(state) {
      state.deleteSNMPHostsRequestExecuting = true;
    },
    deleteSNMPHostsReqEnd(state) {
      state.deleteSNMPHostsRequestExecuting = false;
    },
    loadingSNMPUserGroupsBegin(state) {
      state.loadingSNMPUserGroupsRequestExecuting = true;
    },
    loadingSNMPUserGroupsEnd(state) {
      state.loadingSNMPUserGroupsRequestExecuting = false;
    },
    updateSNMPUserGroupsReqBegin(state) {
      state.updateSNMPUserGroupsRequestExecuting = true;
    },
    updateSNMPUserGroupsReqEnd(state) {
      state.updateSNMPUserGroupsRequestExecuting = false;
    },
    createSNMPUserGroupsReqBegin(state) {
      state.createSNMPUserGroupsRequestExecuting = true;
    },
    createSNMPUserGroupsReqEnd(state) {
      state.createSNMPUserGroupsRequestExecuting = false;
    },
    deleteSNMPUserGroupsReqBegin(state) {
      state.deleteSNMPUserGroupsRequestExecuting = true;
    },
    deleteSNMPUserGroupsReqEnd(state) {
      state.deleteSNMPUserGroupsRequestExecuting = false;
    },
    loadingSNMPUsersBegin(state) {
      state.loadingSNMPUsersRequestExecuting = true;
    },
    loadingSNMPUsersEnd(state) {
      state.loadingSNMPUsersRequestExecuting = false;
    },
    updateSNMPUsersReqBegin(state) {
      state.updateSNMPUsersRequestExecuting = true;
    },
    updateSNMPUsersReqEnd(state) {
      state.updateSNMPUsersRequestExecuting = false;
    },
    createSNMPUsersReqBegin(state) {
      state.createSNMPUsersRequestExecuting = true;
    },
    createSNMPUsersReqEnd(state) {
      state.createSNMPUsersRequestExecuting = false;
    },
    deleteSNMPUsersReqBegin(state) {
      state.deleteSNMPUsersRequestExecuting = true;
    },
    deleteSNMPUsersReqEnd(state) {
      state.deleteSNMPUsersRequestExecuting = false;
    },
    loadingSNMPGeneralBegin(state) {
      state.loadingSNMPGeneralRequestExecuting = true;
    },
    loadingSNMPGeneralEnd(state) {
      state.loadingSNMPGeneralRequestExecuting = false;
    },
    updateSNMPGeneralReqBegin(state) {
      state.updateSNMPGeneralRequestExecuting = true;
    },
    updateSNMPGeneralReqEnd(state) {
      state.updateSNMPGeneralRequestExecuting = false;
    },
    loadingSNMPGeneralForWirelessTrapsBegin(state) {
      state.loadingSNMPGeneralForWirelessTrapsRequestExecuting = true;
    },
    loadingSNMPGeneralForWirelessTrapsEnd(state) {
      state.loadingSNMPGeneralForWirelessTrapsRequestExecuting = false;
    },
    updateSNMPGeneralForWirelessTrapsReqBegin(state) {
      state.updateSNMPGeneralForWirelessTrapsRequestExecuting = true;
    },
    updateSNMPGeneralForWirelessTrapsReqEnd(state) {
      state.updateSNMPGeneralForWirelessTrapsRequestExecuting = false;
    },
    loadingSNMPGeneralForGeneralBegin(state) {
      state.loadingSNMPGeneralForGeneralRequestExecuting = true;
    },
    loadingSNMPGeneralForGeneralEnd(state) {
      state.loadingSNMPGeneralForGeneralRequestExecuting = false;
    },
    updateSNMPGeneralForGeneralReqBegin(state) {
      state.updateSNMPGeneralForGeneralRequestExecuting = true;
    },
    updateSNMPGeneralForGeneralReqEnd(state) {
      state.updateSNMPGeneralForGeneralRequestExecuting = false;
    },
    loadingSNMPGeneralForViewsBegin(state) {
      state.loadingSNMPGeneralForViewsRequestExecuting = true;
    },
    loadingSNMPGeneralForViewsEnd(state) {
      state.loadingSNMPGeneralForViewsRequestExecuting = false;
    },

    loadInHeaderLocationSelectBlockDataFromLocalStorage(state) {
      // console.log('state.userData.id', state.userData.id)
      this.commit('clearInHeaderLocationSelectBlockData')
      if (localStorage.getItem('inHeaderLocationSelectBlockData') === null) {
        localStorage.setItem('inHeaderLocationSelectBlockData', JSON.stringify({}));
      }
      let localStoragenHeaderLocationSelectBlockDataAsObject = JSON.parse(
        localStorage.getItem('inHeaderLocationSelectBlockData')
      );

      if (!localStoragenHeaderLocationSelectBlockDataAsObject[state.userData.id]) {
        localStoragenHeaderLocationSelectBlockDataAsObject[
          state.userData.id
          ] = {
          withChilds: true,
          selectedLocationID: '',
          selectedLocationAndChildsLocationsIDs: [],
          selectedLocationObject: [],
          // previouslySelectedLocationsObjects: [],
          userBaseLocation: state.userData.base_location,
        };
        localStorage.setItem(
          'inHeaderLocationSelectBlockData',
          JSON.stringify(localStoragenHeaderLocationSelectBlockDataAsObject)
        );
      }
      localStoragenHeaderLocationSelectBlockDataAsObject = JSON.parse(
        localStorage.getItem('inHeaderLocationSelectBlockData')
      );
      const InHeaderLocationSelectBlockDataForCurrentUser =
        localStoragenHeaderLocationSelectBlockDataAsObject[state.userData.id];

      for (const key in state.inHeaderLocationSelectBlockData) {
        if (!InHeaderLocationSelectBlockDataForCurrentUser.hasOwnProperty(key)) {
          InHeaderLocationSelectBlockDataForCurrentUser[key] = state.inHeaderLocationSelectBlockData[key];
        }
      }
      if (state.locationsListSorted.length > 0) {
        // а тут актуализируем объект выбраной локации (в случае если например имзенилось имя лоакции, чтобы обновить его)
        state.locationsListSorted.forEach((location) => {
          if (location.id === InHeaderLocationSelectBlockDataForCurrentUser.selectedLocationID) {
            InHeaderLocationSelectBlockDataForCurrentUser.selectedLocationObject = JSON.parse(JSON.stringify(location))
          }
        })
      }

      localStoragenHeaderLocationSelectBlockDataAsObject[
        state.userData.id
        ] = InHeaderLocationSelectBlockDataForCurrentUser;
      localStorage.setItem(
        'inHeaderLocationSelectBlockData',
        JSON.stringify(localStoragenHeaderLocationSelectBlockDataAsObject)
      );


      // console.log(localStoragenHeaderLocationSelectBlockDataAsObject)
      // console.log(JSON.parse(JSON.stringify(InHeaderLocationSelectBlockDataForCurrentUser)))
      state.inHeaderLocationSelectBlockData = InHeaderLocationSelectBlockDataForCurrentUser;


    },
    saveInHeaderLocationSelectBlockDataFToStoreAndLocalStorage(state, inHeaderLocationSelectBlockDataAsObject) {
      state.inHeaderLocationSelectBlockData = inHeaderLocationSelectBlockDataAsObject
      const localStoragenHeaderLocationSelectBlockDataAsObject = JSON.parse(
        localStorage.getItem('inHeaderLocationSelectBlockData')
      )
      localStoragenHeaderLocationSelectBlockDataAsObject[state.userData.id] = inHeaderLocationSelectBlockDataAsObject
      localStorage.setItem(
        'inHeaderLocationSelectBlockData',
        JSON.stringify(localStoragenHeaderLocationSelectBlockDataAsObject)
      );
    },
    // todo выпилить совсем - нигде не используется
    saveInHeaderPreviouslySelectedLocationsObjects(state, saveInHeaderPreviouslySelectedLocationsObjects) {
      state.saveInHeaderPreviouslySelectedLocationsObjects = saveInHeaderPreviouslySelectedLocationsObjects
    },
    clearInHeaderLocationSelectBlockData(state) {
      // console.log('clearDashboardBlocksLocationSettingsForGetStatistic')
      state.inHeaderLocationSelectBlockData = {
        withChilds: true,
        selectedLocationID: '',
        selectedLocationAndChildsLocationsIDs: [],
        selectedLocationObject: [],
        // previouslySelectedLocationsObjects: [],
        userBaseLocation: state.userData.base_location,
      }
    },
    loadDashboardBlocksLocationSettingsForGetStatisticFromLocalStorage(state) {
      this.commit('clearDashboardBlocksLocationSettingsForGetStatistic')
      if (localStorage.getItem('dashboardBlocksLocationSettingsForGetStatistic') === null) {
        localStorage.setItem('dashboardBlocksLocationSettingsForGetStatistic', JSON.stringify({}));
      }
      let localStorageDashboardBlocksLocationSettingsForGetStatisticAsObject = JSON.parse(
        localStorage.getItem('dashboardBlocksLocationSettingsForGetStatistic')
      );

      if (!localStorageDashboardBlocksLocationSettingsForGetStatisticAsObject[state.userData.id]) {
        localStorageDashboardBlocksLocationSettingsForGetStatisticAsObject[
          state.userData.id
          ] = {
          locationId: '',
          with_child: false,
        };
        localStorage.setItem(
          'dashboardBlocksLocationSettingsForGetStatistic',
          JSON.stringify(localStorageDashboardBlocksLocationSettingsForGetStatisticAsObject)
        );
      }
      localStorageDashboardBlocksLocationSettingsForGetStatisticAsObject = JSON.parse(
        localStorage.getItem('dashboardBlocksLocationSettingsForGetStatistic')
      );
      const dashboardBlocksLocationSettingsForGetStatisticForCurrentUser =
        localStorageDashboardBlocksLocationSettingsForGetStatisticAsObject[state.userData.id];
      // тут перебираем настройки для показа блоков из компонента, если добавились новые блоки,
      // которых нет у пользователя, добавляем их пользователю в local storage
      for (const key in state.dashboardBlocksLocationSettingsForGetStatistic) {
        if (!dashboardBlocksLocationSettingsForGetStatisticForCurrentUser.hasOwnProperty(key)) {
          dashboardBlocksLocationSettingsForGetStatisticForCurrentUser[key] = state.dashboardBlocksLocationSettingsForGetStatistic[key];
        }
      }
      localStorageDashboardBlocksLocationSettingsForGetStatisticAsObject[
        state.userData.id
        ] = dashboardBlocksLocationSettingsForGetStatisticForCurrentUser;
      localStorage.setItem(
        'dashboardBlocksLocationSettingsForGetStatistic',
        JSON.stringify(localStorageDashboardBlocksLocationSettingsForGetStatisticAsObject)
      );
      state.dashboardBlocksLocationSettingsForGetStatistic = dashboardBlocksLocationSettingsForGetStatisticForCurrentUser;

    },
    saveDashboardBlocksLocationSettingsForGetStatisticToStoreAndLocalStorage(state, payload) {
      const locationId = payload.loctionId;
      const with_child = payload.with_child;
      state.dashboardBlocksLocationSettingsForGetStatistic =
        {
          locationId: locationId,
          with_child: with_child,
        }
      const localStorageDashboardBlocksLocationSettingsForGetStatisticAsObject = JSON.parse(
        localStorage.getItem('dashboardBlocksLocationSettingsForGetStatistic')
      )
      localStorageDashboardBlocksLocationSettingsForGetStatisticAsObject[state.userData.id] = {
        locationId: locationId,
        with_child: with_child,
      }
      localStorage.setItem(
        'dashboardBlocksLocationSettingsForGetStatistic',
        JSON.stringify(localStorageDashboardBlocksLocationSettingsForGetStatisticAsObject)
      );
    },
    clearDashboardBlocksLocationSettingsForGetStatistic(state) {
      // console.log('clearDashboardBlocksLocationSettingsForGetStatistic')
      state.dashboardBlocksLocationSettingsForGetStatistic = {
        locationId: '',
        with_child: false,
      }
    },
    setDashboardBlocksVisibility(state, payload) {
      this.commit('removeDashboardBlocksVisibilityFromLocalStorage');
      localStorage.setItem(payload.nameInLocalStorage, JSON.stringify(payload))
      state.dashboardBlocksVisibility = payload;
    },
    setDashboardBlocksVisibilityForBlockSection(state, payload) {
      const blockSection = payload.blockSection;
      const blocksSettings = payload.blocksSettings;
      Vue.set(state.dashboardBlocksVisibility, blockSection, blocksSettings)
      this.commit('removeDashboardBlocksVisibilityFromLocalStorage');
      // localStorage.setItem(payload.nameInLocalStorage, JSON.stringify(state.dashboardBlocksVisibility))
      localStorage.setItem(state.dashboardBlocksVisibility.nameInLocalStorage, JSON.stringify(state.dashboardBlocksVisibility))
    },
    setDashboardBlockVisibilityForBlock(state, payload) {
      const blockSection = payload.blockSection;
      const blockName = payload.blockName;
      const visible = payload.visible
      // console.log(blockSection, blockName)
      try {
        if (blockSection && blockName) {
          state.dashboardBlocksVisibility[blockSection][blockName] = visible;
          this.commit('setDashboardBlocksVisibility', state.dashboardBlocksVisibility);
        }
      } catch (e) {
        console.log(e);
      }
    },
    setDashboardBlocksVisibilityDefault(state) {
      state.dashboardBlocksVisibility = {
        nameInLocalStorage: 'dashboardBlocksVisibility',
        general: {
          serverInfo: true,
          cpu: true,
          cpuLoad: true,
          mem: true,
          cpuLoadChart: true,
          memoryUsedChart: true,
          connectedCPEsChart: true,
          configCPEsChart: true,
          eventsChart: true,
          mostLoadCPES: true,
          mostActiveCPES: true,
          mostActiveClients: true,
          visitors: true,
        },
        marketer: {
          marketerViewLocations: true,
          marketerViewlocationsWhereManager: true,
          marketerViewLocationsMap: true,
          marketerViewAds: true,
          marketerViewAdsadsViewStatistic: true,
          marketerViewConnectionsStatistic: true,
          visitors: true
        }
      }
    },
    loadDashboardBlocksVisibilityFromLocalStorage(state) {
      const dashboardBlocksVisibility = JSON.parse(localStorage.getItem(state.dashboardBlocksVisibility.nameInLocalStorage))
      if (dashboardBlocksVisibility) {
        state.dashboardBlocksVisibility = dashboardBlocksVisibility
      } else {
        localStorage.setItem(state.dashboardBlocksVisibility.nameInLocalStorage, JSON.stringify(state.dashboardBlocksVisibility))
      }
    },
    removeDashboardBlocksVisibilityFromLocalStorage(state) {
      localStorage.removeItem(state.dashboardBlocksVisibility.nameInLocalStorage);
    },
    setDashboardBlocksCollapse(state, payload) {
      this.commit('removeDashboardBlocksCollapseFromLocalStorage');
      localStorage.setItem(payload.nameInLocalStorage, JSON.stringify(payload))
      state.dashboardBlocksCollapse = payload;
    },
    setDashboardBlocksCollapseForBlockSection(state, payload) {
      const blockSection = payload.blockSection;
      const blocksSettings = payload.blocksSettings;
      Vue.set(state.dashboardBlocksCollapse, blockSection, blocksSettings)
      this.commit('removeDashboardBlocksCollapseFromLocalStorage');
      // localStorage.setItem(payload.nameInLocalStorage, JSON.stringify(state.dashboardBlocksCollapse))
      localStorage.setItem(state.dashboardBlocksCollapse.nameInLocalStorage, JSON.stringify(state.dashboardBlocksCollapse))
    },
    setDashboardBlocksCollapseForBlock(state, payload) {
      const blockSection = payload.blockSection;
      const blockName = payload.blockName;
      const visible = payload.visible
      // console.log(blockSection, blockName)
      try {
        if (blockSection && blockName) {
          state.dashboardBlocksCollapse[blockSection][blockName] = visible;
          this.commit('setDashboardBlocksCollapse', state.dashboardBlocksCollapse);
        }
      } catch (e) {
        console.log(e);
      }
    },
    setDashboardBlocksCollapseDefault(state) {
      state.dashboardBlocksCollapse = {
        nameInLocalStorage: 'dashboardBlocksCollapse',
        general: {
          eventsChart: true,
          mostLoadCPES: true,
          mostActiveCPES: true,
          mostActiveClients: true,
          visitors: true,
        },
        marketer: {
          locations: true,
          mapOfLocations: true,
          advertisement: true,
          advertisementStatistic: true,
          connectionsStatistic: true,
          locationsWhereManager: true,
          visitors: true
        }
      }
    },
    addRequestCancelTokenToRequestsCancelTokens(state, payload) {
      // с помощью этой мутации cancel token'ы axios'a
      // добавляются в объект state.requestsCancelToken, ключами являются
      // componentName и requestName
      try {
        const cancelToken = payload.cancelToken;
        const componentName = payload.componentName ? payload.componentName.split(' ').join('_') : undefined;
        const requestName = payload.requestName;
        if (!cancelToken || !componentName || !requestName) {
          return;
        }
        if (!Object.prototype.hasOwnProperty.call(state.requestsCancelTokens, componentName)) {
          Vue.set(state.requestsCancelTokens, componentName, {})
        }
        const tokensForComponent = state.requestsCancelTokens[componentName];
        if (!Object.prototype.hasOwnProperty.call(tokensForComponent, requestName)) {
          Vue.set(tokensForComponent, requestName, {})
        }
        // tokensForComponent[requestName] = cancelToken;
        Vue.set(tokensForComponent, requestName, cancelToken)
      } catch (e) {
        console.log(e)
      }

    },
    clearRequestCancelTokenFromRequestsCancelTokensForComponentByTokenName(state, payload) {
      // с помощью этой мутации из обхъекта state.requestsCancelToken удаляются конкретные cancel token'ы axios'a
      // по componentName и requestName
      try {
        const componentName = payload.componentName ? payload.componentName.split(' ').join('_') : undefined;
        const requestName = payload.requestName;
        if (!componentName || !requestName || !Object.prototype.hasOwnProperty.call(state.requestsCancelTokens, componentName)) {
          return;
        }
        const tokensForComponent = state.requestsCancelTokens[componentName];
        if (!Object.prototype.hasOwnProperty.call(tokensForComponent, requestName)) {
          return;
        } else {
          // delete tokensForComponent[requestName];
          Vue.delete(tokensForComponent, requestName)
        }
        if (Object.keys(state.requestsCancelTokens[componentName]).length === 0) {
          // delete state.requestsCancelTokens[componentName];
          Vue.delete(state.requestsCancelTokens, componentName)
        }
      } catch (e) {
        console.log(e)
      }
    },

    clearAndCancelRequestCancelTokenFromRequestsCancelTokensForComponentByTokenName(state, payload) {
      // с помощью этой мутации из обхъекта state.requestsCancelToken отменяются и удаляются конкретные cancel token'ы axios'a
      // по componentName и requestName
      try {
        const componentName = payload.componentName ? payload.componentName.split(' ').join('_') : undefined;
        const requestName = payload.requestName;
        if (!componentName || !requestName || !Object.prototype.hasOwnProperty.call(state.requestsCancelTokens, componentName)) {
          return;
        }
        const tokensForComponent = state.requestsCancelTokens[componentName];
        if (!Object.prototype.hasOwnProperty.call(tokensForComponent, requestName)) {
          return;
        } else {
          // delete tokensForComponent[requestName];
          const token = state.requestsCancelTokens[componentName][requestName]
          if (token && Object.prototype.hasOwnProperty.call(token, 'cancel') && typeof token.cancel === 'function') {
              token.cancel();
            }
          Vue.delete(tokensForComponent, requestName)
        }
        if (Object.keys(state.requestsCancelTokens[componentName]).length === 0) {
          // delete state.requestsCancelTokens[componentName];
          Vue.delete(state.requestsCancelTokens, componentName)
        }
      } catch (e) {
        console.log(e)
      }
    },


    clearAllRequestsCancelTokensFromRequestsCancelTokensForComponent(state, payload) {
      // с помощью этой мутации из объекта state.requestsCancelToken удаляются все cancel token'ы axios'a
      // для выбраного componentName
      try {
        const componentName = payload.componentName ? payload.componentName.split(' ').join('_') : undefined;
        if (!componentName || !Object.prototype.hasOwnProperty.call(state.requestsCancelTokens, componentName)) {
          return;
        }
        // delete state.requestsCancelTokens[componentName];
        Vue.delete(state.requestsCancelTokens, componentName)
      } catch (e) {
        console.log(e)
      }

    },
    cancelAllRequestFromRequestsCancelTokensForComponent(state, payload) {
      // с помощью этой мутации все запросы для componentName отменяются с помощью
      // cancel token'ов axios'a внутри обхъекта state.requestsCancelToken по componentName
      try {
        const componentName = payload.componentName ? payload.componentName.split(' ').join('_') : undefined;
        // console.log(componentName)
        if (!componentName) {
          return;
        }
        if (Object.prototype.hasOwnProperty.call(state.requestsCancelTokens, componentName) &&
          typeof state.requestsCancelTokens[componentName] === 'object')  {
          const tokens = Object.values(state.requestsCancelTokens[componentName]);
          if (tokens.length === 0) {
            return;
          }
          tokens.forEach((token) => {
            if (Object.prototype.hasOwnProperty.call(token, 'cancel') && typeof token.cancel === 'function') {
              token.cancel();
            }
          })
        }
      } catch (e) {
        console.log(e)
      }
    },
    cancelAllRequestsAndClearAllTokensForComponent(state, payload) {
      try {
        const componentName = payload.componentName;
        // console.log(componentName)
        if (!componentName) {
          return;
        }
        this.commit('cancelAllRequestFromRequestsCancelTokensForComponent', { componentName: componentName });
        this.commit('clearAllRequestsCancelTokensFromRequestsCancelTokensForComponent', { componentName: componentName });
      } catch (e) {
        console.log(e)
      }
    },
    loadDashboardBlocksCollapseFromLocalStorage(state) {
      const dashboardBlocksCollapse = JSON.parse(localStorage.getItem(state.dashboardBlocksCollapse.nameInLocalStorage))
      if (dashboardBlocksCollapse) {
        state.dashboardBlocksCollapse = dashboardBlocksCollapse
      } else {
        localStorage.setItem(state.dashboardBlocksCollapse.nameInLocalStorage, JSON.stringify(state.dashboardBlocksCollapse))
      }
    },
    removeDashboardBlocksCollapseFromLocalStorage(state) {
      localStorage.removeItem(state.dashboardBlocksCollapse.nameInLocalStorage);
    },
    setGetAllCompactCpesWithDataRemainingRequestBegin(state) {
      state.getAllCompactCpesWithDataRemainingRequestExecuting = true;
    },
    setGetAllCompactCpesWithDataRemainingRequestEnd(state) {
      state.getAllCompactCpesWithDataRemainingRequestExecuting = false;
    },
    setGetAllCompactWlansWithDataRemainingRequestBegin(state) {
      state.getAllCompactWlansWithDataRemainingRequestExecuting = true;
    },
    setGetAllCompactWlansWithDataRemainingRequestEnd(state) {
      state.getAllCompactWlansWithDataRemainingRequestExecuting = false;
    },
    setUpdateCpesConfigFromUploadedConfigBegin(state) {
      state.updateCpesConfigFromUploadedConfigExecuting = true;
    },
    setUpdateCpesConfigFromUploadedConfigEnd(state) {
      state.updateCpesConfigFromUploadedConfigExecuting = false;
    },
    getAccountByIdFromVoucherRequestBegin(state) {
      state.getAccountByIdFromVoucherRequestExecuting = true;
    },
    getAccountByIdFromVoucherRequestEnd(state) {
      state.getAccountByIdFromVoucherRequestExecuting = false;
    },
    setGetCpesCompactFromUploadedConfigRequestBegin(state) {
      state.getCpesCompactFromUploadedConfigRequestExecuting = true;
    },
    setGetCpesCompactFromUploadedConfigRequestEnd(state) {
      state.getCpesCompactFromUploadedConfigRequestExecuting = false;
    },
    forceRRMGroupRequestBegin(state) {
      state.forceRRMGroupRequestExecuting = true;
    },
    forceRRMGroupRequestEnd(state) {
      state.forceRRMGroupRequestExecuting = false;
    },
    setGetFirmwareListRequestBegin(state) {
      state.getFirmwareListRequestExecuting = true;
    },
    setGetFirmwareListRequestEnd(state) {
      state.getFirmwareListRequestExecuting = false;
    },
    setGetCpesCompactForFilterRequestBegin(state) {
      state.getCpesCompactForFilterRequestExecuting = true;
    },
    setGetCpesCompactForFilterRequestEnd(state) {
      state.getCpesCompactForFilterRequestExecuting = false;
    },
    setGetWlansCompactForFilterRequestBegin(state) {
      state.getWlansCompactForFilterRequestExecuting = true;
    },
    setGetWlansCompactForFilterRequestEnd(state) {
      state.getWlansCompactForFilterRequestExecuting = false;
    },
    setCpeLatestStatsRequestExecutingBegin(state) {
      state.cpeLatestStatsRequestExecuting = true;
    },
    setCpeLatestStatsRequestExecutingEnd(state) {
      state.cpeLatestStatsRequestExecuting = false;
    },
    loadingDITStatListBegin(state) {
      state.loadingDITStatList = true;
    },
    loadingDITStatListEnd(state) {
      state.loadingDITStatList = false;
    },
    loadingPeriodicTasksListBegin(state) {
      state.loadingPeriodicTasksList = true;
    },
    loadingPeriodicTasksListEnd(state) {
      state.loadingPeriodicTasksList = false;
    },
    loadingConfigForSaveInFileBegin(state) {
      state.loadingConfigForSaveInFile = true;
    },
    loadingConfigForSaveInFileEnd(state) {
      state.loadingConfigForSaveInFile = false;
    },
    uploadingConfigToAPIBegin(state) {
      state.uploadingConfigToAPI = true;
    },
    uploadingConfigToAPIEnd(state) {
      state.uploadingConfigToAPI = false;
    },
    loadingDBStateListBegin(state) {
      state.loadingDBStateList = true;
    },
    loadingDBStateListEnd(state) {
      state.loadingDBStateList = false;
    },
    filteringAndSortingDBStateListBegin(state) {
      state.filteringAndSortingDBStateList = true;
    },
    filteringAndSortingDBStateListEnd(state) {
      state.filteringAndSortingDBStateList = false;
    },
    updateDITExportReqBegin(state) {
      state.updateDITExportReqExecuting = true;
    },
    updateDITExportReqEnd(state) {
      state.updateDITExportReqExecuting = false;
    },
    createDITExportReqBegin(state) {
      state.createDITExportReqExecuting = true;
    },
    createDITExportReqEnd(state) {
      state.createDITExportReqExecuting = false;
    },
    deleteDITExportReqBegin(state) {
      state.deleteDITExportReqExecuting = true;
    },
    deleteDITExportReqEnd(state) {
      state.deleteDITExportReqExecuting = false;
    },
    updatePeriodicTaskReqBegin(state) {
      state.updatePeriodicTaskReqExecuting = true;
    },
    updatePeriodicTaskReqEnd(state) {
      state.updatePeriodicTaskReqExecuting = false;
    },
    createPeriodicTaskReqBegin(state) {
      state.createPeriodicTaskReqExecuting = true;
    },
    createPeriodicTaskReqEnd(state) {
      state.createPeriodicTaskReqExecuting = false;
    },
    deletePeriodicTaskReqBegin(state) {
      state.deletePeriodicTaskReqExecuting = true;
    },
    deletePeriodicTaskReqEnd(state) {
      state.deletePeriodicTaskReqExecuting = false;
    },
    loadingAuthenticationsRequestBegin(state) {
      state.loadingAuthentications = true;
    },
    loadingAuthenticationsRequestEnd(state) {
      state.loadingAuthentications = false;
    },
    loadingAuthorizationsRequestBegin(state) {
      state.loadingAuthorizations = true;
    },
    loadingAuthorizationsRequestEnd(state) {
      state.loadingAuthorizations = false;
    },
    wlanWizardGoingToLastStepAfterClickSkipButtonStart(state) {
      state.wlanWizardGoingToLastStepAfterClickSkipButtonInProgess = true;
    },
    wlanWizardGoingToLastStepAfterClickSkipButtonEnd(state) {
      state.wlanWizardGoingToLastStepAfterClickSkipButtonInProgess = false;
    },
    getSMSCostForPreviousPeriodRangeForStatisticRequestExecutingBegin(state) {
      state.getSMSCostForPreviousPeriodRangeForStatisticRequestExecuting = true;
    },
    getSMSCostForPreviousPeriodRangeForStatisticRequestExecutingEnd(state) {
      state.getSMSCostForPreviousPeriodRangeForStatisticRequestExecuting = false;
    },
    getSMSCostForRangeForStatisticRequestExecutingBegin(state) {
      state.getSMSCostForRangeForStatisticRequestExecuting = true;
    },
    getSMSCostForRangeForStatisticRequestExecutingEnd(state) {
      state.getSMSCostForRangeForStatisticRequestExecuting = false;
    },
    getSMSCostForRangeRequestExecutingBegin(state) {
      state.getSMSCostForRangeRequestExecuting = true;
    },
    getSMSCostForRangeRequestExecutingEnd(state) {
      state.getSMSCostForRangeRequestExecuting = false;
    },
    getSMSCostForPrevRangeRequestExecutingBegin(state) {
      state.getSMSCostForPrevRangeRequestExecuting = true;
    },
    getSMSCostForPrevRangeRequestExecutingEnd(state) {
      state.getSMSCostForPrevRangeRequestExecuting = false;
    },
    filteringCpeListInRadarExportModalWindowStart(state) {
      state.filteringCpeListInRadarExportModalWindow = true;
    },
    filteringCpeListInRadarExportModalWindowEnd(state) {
      state.filteringCpeListInRadarExportModalWindow = false;
    },
    filteringCpeListInDITExportModalWindowStart(state) {
      state.filteringCpeListInDITExportModalWindow = true;
    },
    filteringCpeListInDITExportModalWindowEnd(state) {
      state.filteringCpeListInDITExportModalWindow = false;
    },
    filteringCpeListInPeriodicTasksModalWindowStart(state) {
      state.filteringCpeListInPeriodicTasksModalWindow = true;
    },
    filteringCpeListInPeriodicTasksModalWindowEnd(state) {
      state.filteringCpeListInPeriodicTasksModalWindow = false;
    },
    filteringWlanListInPeriodicTasksModalWindowStart(state) {
      state.filteringWlanListInPeriodicTasksModalWindow = true;
    },
    filteringWlanListInPeriodicTasksModalWindowEnd(state) {
      state.filteringWlanListInPeriodicTasksModalWindow = false;
    },
    getRadarExportsReqBegin(state) {
      state.isGetRadarExportsReqExecuted = true;
    },
    getRadarExportsReqEnd(state) {
      state.isGetRadarExportsReqExecuted = false;
    },
    clearEasyInstallWizardStepsData(state) {
      state.easyInstallWizardStepsData = {
        step1SavedWlanObject: false,
        step1locationId: false,
        step1WlanId: false,
        step2SavedPageObject: false,
        step3RedirectObject: false,
        step3RedirectId: false,
        step4CpesWithWLANIDs: false,
        routeFromUserWentToWizard: false
      };
    },
    setEasyInstallWizardStep1SavedWlanObject(state, payload) {
      // console.log('mutation!')
      state.easyInstallWizardStepsData.step1SavedWlanObject = payload;
    },
    setEasyInstallWizardStep2SavedPageObject(state, payload) {
      state.easyInstallWizardStepsData.step2SavedPageObject = payload;
    },
    setEasyInstallWizardStep1LocationId(state, payload) {
      state.easyInstallWizardStepsData.step1locationId = payload;
    },
    setEasyInstallWizardStep1WlanId(state, payload) {
      state.easyInstallWizardStepsData.step1WlanId = payload;
    },
    setEasyInstallWizardStep3SavedRedirectObject(state, payload) {
      state.easyInstallWizardStepsData.step3RedirectObject = payload;
    },
    setEasyInstallWizardStep3SavedRedirectId(state, payload) {
      state.easyInstallWizardStepsData.step3RedirectId = payload;
    },
    setEasyInstallWizardStep4SavedCPEsWithWLANIDs(state, payload) {
      state.easyInstallWizardStepsData.step4CpesWithWLANIDs = payload;
    },
    setEasyInstallWizardRouteFromUserWentToWizard(state, payload) {
      state.easyInstallWizardStepsData.routeFromUserWentToWizard = payload;
    },
    userRegistersTheCpeHimselfCheckIsCpeAlreadyRegisteredRequestBegin(state) {
      state.userRegistersTheCpeHimselfCheckIsCpeAlreadyRegisteredRequestExecuting = true;
    },
    userRegistersTheCpeHimselfCheckIsCpeAlreadyRegisteredRequestEnd(state) {
      state.userRegistersTheCpeHimselfCheckIsCpeAlreadyRegisteredRequestExecuting = false;
    },
    userRegistersTheCpeHimselfRequestBegin(state) {
      state.userRegistersTheCpeHimselfRequestExecuting = true;
    },
    userRegistersTheCpeHimselfRequestEnd(state) {
      state.userRegistersTheCpeHimselfRequestExecuting = false;
    },
    userRegistersTheCpeHimselfEnable(state) {
      state.userRegistersTheCpeHimself = true;
    },
    userRegistersTheCpeHimselfDisable(state) {
      state.userRegistersTheCpeHimself = false;
    },
    userRegistersTheCpeHimselfSetCpeId(state, cpeId) {
      state.userRegistersTheCpeHimselfCpeId = cpeId;
    },
    userRegistersTheCpeHimselfDisableAndClearCpeID(state) {
      state.userRegistersTheCpeHimself = false;
      state.userRegistersTheCpeHimselfCpeId = '';
    },
    setPercentsOfClientsMarkerDownloadsRequestsCount(state, payload) {
      state.percentsOfClientsMarkerDownloadsRequestsCount = payload;
    },
    discardCPEsErrorsBeginRequest(state) {
      state.discardingCPEsErrors = true;
    },
    discardCPEsErrorsEndRequest(state) {
      state.discardingCPEsErrors = false;
    },
    loadingSNMPStatBegin(state) {
      state.loadingSNMPStat = true;
    },
    loadingSNMPStatEnd(state) {
      state.loadingSNMPStat = false;
    },
    loadingNTPServersBegin(state) {
      state.loadingNTPServers = true;
    },
    loadingNTPServersEnd(state) {
      state.loadingNTPServers = false;
    },
    createNTPServerReqBegin(state) {
      state.createNTPServerRequestExecuting = true;
    },
    createNTPServerReqEnd(state) {
      state.createNTPServerRequestExecuting = false;
    },
    updateNTPServerReqBegin(state) {
      state.updateNTPServerRequestExecuting = true;
    },
    updateNTPServerReqEnd(state) {
      state.updateNTPServerRequestExecuting = false;
    },
    updateNTPSchemaReqBegin(state) {
      state.updateNTPSchemaRequestExecuting = true;
    },
    updateNTPSchemaReqEnd(state) {
      state.updateNTPSchemaRequestExecuting = false;
    },
    deleteNTPServerReqBegin(state) {
      state.deleteNTPServerRequestExecuting = true;
    },
    deleteNTPServerReqEnd(state) {
      state.deleteNTPServerRequestExecuting = false;
    },
    filteringSNMPStatBegin(state) {
      state.filteringSNMPStat = true;
    },
    filteringSNMPStatEnd(state) {
      state.filteringSNMPStat = false;
    },

    filteringPeriodicTasksBegin(state) {
      state.filteringPeriodicTasks = true;
    },
    filteringPeriodicTasksEnd(state) {
      state.filteringPeriodicTasks = false;
    },

    setLocaleSwitchedTimeStamp(state) {
      // обновляется при смене локали в locale-switcher'e
      // обновляем текущим таймстампом, просто потому что не нужно придумывать какие-либо другие счетички
      // главное чтобы менялось какое-нибуь значение, чтобы следить за ним с помощью
      // вотчеров
      // например используется в разделе с Heatmaps для перерисовки карты при смене языка
      state.localeSwitchedTimeStamp = Date.now();
    },
    toggleShowOnlyIcons(state) {
      state.showOnlyIcons = !state.showOnlyIcons;
    },
    sidebarShowOnlyIcons(state) {
      state.showOnlyIcons = true;
    },
    sidebarShowIconsAndCaptions(state) {
      state.showOnlyIcons = false;
    },
    portalBirthdaysForDashboardStopLoading(state) {
      state.activeRequestsForPortalDashboardPage.loadingPortalBirthdaysForDashboard = false;
    },
    portalBirthdaysForDashboardStartLoading(state) {
      state.activeRequestsForPortalDashboardPage.loadingPortalBirthdaysForDashboard = true;
    },
    setWindowResizedFromDesktopToMobileWithMinimalisticSidebar(state, payload) {
      state.windowResizedFromDesktopToMobileWithMinimalisticSidebar = payload;
    },
    togglesubordinateLogin(state) {
      state.subordinateLogin = !state.subordinateLogin;
    },
    subordinateLoginSetFalse(state) {
      state.subordinateLogin = false;
    },
    subordinateLoginSetTrue(state) {
      state.subordinateLogin = true;
    },
    toggleLoadingUsers(state) {
      state.loadingUsers = !state.loadingUsers;
    },
    loadingUsersWithBeeProBisIdBegin(state) {
      state.loadingUsersWithBeeProBisId = true;
    },
    loadingUsersWithBeeProBisIdEnd(state) {
      state.loadingUsersWithBeeProBisId = false;
    },
    loadingUsersWithBeeProCTNBegin(state) {
      state.loadingUsersWithBeeProCTN = true;
    },
    loadingUsersWithBeeProCTNEnd(state) {
      state.loadingUsersWithBeeProCTN = false;
    },
    toggleLoadingSNMPConfig(state) {
      state.loadingSNMPConfig = !state.loadingSNMPConfig;
    },
    toggleLoadingSNMPConfigForManyCPEs(state) {
      state.loadingSNMPConfigForManyCPEs = !state.loadingSNMPConfigForManyCPEs;
    },
    toggleLoadingOperations(state) {
      state.loadingOperations = !state.loadingOperations;
    },
    toggleLoadingCpeModels(state) {
      state.loadingCpeModels = !state.loadingCpeModels;
    },
    toggleLoadingCpeTemplates(state) {
      state.loadingCpeTemplates = !state.loadingCpeTemplates;
    },
    toggleLoadingLocations(state) {
      state.loadingLocations = !state.loadingLocations;
    },
    toggleLoadingPortalProfiles(state) {
      state.loadingPortalProfiles = !state.loadingPortalProfiles;
    },
    toggleLoadingPortalProfilesLocations(state) {
      state.loadingPortalProfilesLocations = !state.loadingPortalProfilesLocations;
    },
    toggleLoadingPortalAds(state) {
      state.loadingPortalAds = !state.loadingPortalAds;
    },
    toggleLoadingPortalAdsLocations(state) {
      state.loadingPortalAdsLocations = !state.loadingPortalAdsLocations;
    },
    toggleLoadingPortalPages(state) {
      state.loadingPortalPages = !state.loadingPortalPages;
    },
    toggleLoadingPortalSchedules(state) {
      state.loadingPortalPagesSchedules = !state.loadingPortalPagesSchedules;
    },
    toggleLoadingPortalSessions(state) {
      state.loadingPortalSessions = !state.loadingPortalSessions;
    },
    loadingPortalSessionsRequestBegin(state) {
      state.loadingPortalSessions = true;
    },
    loadingPortalSessionsRequestEnd(state) {
      state.loadingPortalSessions = false;
    },
    toggleLoadingPortalPagesLocations(state) {
      state.loadingPortalPagesLocations = !state.loadingPortalPagesLocations;
    },
    toggleLoadingPortalAdsStats(state) {
      state.loadingPortalAdsStats = !state.loadingPortalAdsStats;
    },
    toggleAdsLoadingForMarketerDashboard(state) {
      state.loadingAdsInfoForMarketerDashboard = !state.loadingAdsInfoForMarketerDashboard;
    },
    toggleAdsStatsLoadingForMarketerDashboard(state) {
      state.loadingAdsStatsInfoForMarketerDashboard = !state.loadingAdsStatsInfoForMarketerDashboard;
    },
    toggleLoadingPortalUserAccounts(state) {
      state.loadingPortalUserAccounts = !state.loadingPortalUserAccounts;
    },
    toggleLoadingPortalBirthdays(state) {
      state.loadingPortalBirthdays = !state.loadingPortalBirthdays;
    },
    toggleSendingCPEConfigScriptFromEvents(state) {
      state.sendingCPEConfigScriptFromEvents = !state.sendingCPEConfigScriptFromEvents;
    },
    toggleSetTimeUsingNTPRequestExecuting(state) {
      state.setTimeUsingNTPRequestExecuting = !state.setTimeUsingNTPRequestExecuting;
    },
    setSendCPEConfigScriptFromCpesExecutingBegin(state) {
      state.sendCPEConfigScriptFromCpesExecuting = true;
    },
    setSendCPEConfigScriptFromCpesExecutingEnd(state) {
      state.sendCPEConfigScriptFromCpesExecuting = false;
    },
    setSyncTimeRequestExecutingBegin(state) {
      state.syncTimeRequestExecuting = true;
    },
    setSyncTimeRequestExecutingEnd(state) {
      state.syncTimeRequestExecuting = false;
    },
    getCPEClientsListFromCpeCommandRequestBegin(state) {
      state.getCPEClientsListFromCpeCommandRequestExecuting = true;
    },
    getCPEClientsListFromCpeCommandRequestEnd(state) {
      state.getCPEClientsListFromCpeCommandRequestExecuting = false;
    },
    getCPELogsFromCpeCommandRequestBegin(state) {
      state.getCPELogsFromCpeCommandRequestExecuting = true;
    },
    getCPELogsFromCpeCommandRequestEnd(state) {
      state.getCPELogsFromCpeCommandRequestExecuting = false;
    },
    getCPEWLANSInfoFromCpeCommandRequestBegin(state) {
      state.getCPEWLANSInfoFromCpeCommandRequestExecuting = true;
    },
    getCPEWLANSInfoFromCpeCommandRequestEnd(state) {
      state.getCPEWLANSInfoFromCpeCommandRequestExecuting = false;
    },
    toggleLoadingPortalTariffs(state) {
      state.loadingPortalTariffs = !state.loadingPortalTariffs;
    },
    toggleLoadingPortalVouchers(state) {
      state.loadingPortalVouchers = !state.loadingPortalVouchers;
    },
    toggleLoadingPaymentSystems(state) {
      state.loadingPortalPaymentSystems = !state.loadingPortalPaymentSystems;
    },
    toggleLoadingRedirects(state) {
      state.loadingRedirects = !state.loadingRedirects;
    },
    toggleMuteCpeEdit(state, force) {
      if (typeof force === 'boolean') {
        state.muteCpeEdit = force;
      } else {
        state.muteCpeEdit = !state.muteCpeEdit;
      }
    },
    toggleMuteCpeFilters(state, force) {
      state.muteCpeFilters = force;
    },
    toggleMuteCpesDelete(state, force) {
      state.muteCpesDelete = force;
    },
    toggleMuteCpesRegistration(state, force) {
      state.muteCpesRegistration = force;
    },
    toggleUploadingFW(state, force) {
      state.uploadFW = force;
    },
    toggleHideAllOperations(state, force) {
      state.hideAllOperations = force;
    },
    toggleWebAccess(state, force) {
      state.WebAccess = force;
    },
    toggleMuteLocationEdit(state) {
      state.muteLocationEdit = !state.muteLocationEdit;
    },
    toggleMuteWlanEdit(state, force) {
      if (typeof force === 'boolean') {
        state.muteWlanEdit = force;
      } else {
        state.muteWlanEdit = !state.muteWlanEdit;
      }
    },
    toggleMuteRadiusEdit(state, force) {
      if (typeof force === 'boolean') {
        state.muteRadiusEdit = force;
      } else {
        state.muteRadiusEdit = !state.muteRadiusEdit;
      }
    },
    toggleMuteExportEdit(state) {
      state.muteExportEdit = !state.muteExportEdit;
    },
    toggleMuteRRMEdit(state, force) {
      state.muteRRMEdit = !state.muteRRMEdit;
    },
    toggleMuteReportEdit(state) {
      state.muteReportEdit = !state.muteReportEdit;
    },
    toggleMuteReportDelete(state) {
      state.muteReportDelete = !state.muteReportDelete;
    },
    toggleMutePortalProfileEdit(state) {
      state.mutePortalProfileEdit = !state.mutePortalProfileEdit;
    },
    toggleMutePortalAdEdit(state) {
      state.mutePortalAdEdit = !state.mutePortalAdEdit;
    },
    toggleMutePortalPageEdit(state) {
      state.mutePortalPageEdit = !state.mutePortalPageEdit;
    },
    toggleMutePortalTariffEdit(state) {
      state.mutePortalTariffEdit = !state.mutePortalTariffEdit;
    },
    toggleMutePortalVoucherEdit(state) {
      state.mutePortalVoucherEdit = !state.mutePortalVoucherEdit;
    },
    toggleMutePortalUserAccountEdit(state) {
      state.mutePortalUserAccountEdit = !state.mutePortalUserAccountEdit;
    },
    toggleMutePortalPaymentSystemEdit(state) {
      state.mutePortalPaymentSystemEdit = !state.mutePortalPaymentSystemEdit;
    },
    toggleMuteRedirectEdit(state, force) {
      state.muteRedirectEdit = force;
    },
    toggleMuteControllerEdit(state, force) {
      state.muteControllerEdit = force;
    },
    toggleMuteExternalApEdit(state, force) {
      state.muteExternalApEdit = force;
    },
    toggleMuteHotspotEdit(state, force) {
      state.muteHotspotEdit = force;
    },
    toggleLoadingCpes(state) {
      state.loadingCpes = !state.loadingCpes;
    },
    loadingCpesRequestBegin(state) {
      state.loadingCpes = true;
    },
    loadingCpesRequestEnd(state) {
      state.loadingCpes = false;
    },
    loadingWlansRequestBegin(state) {
      state.loadingWlans = true;
    },
    loadingWlansRequestEnd(state) {
      state.loadingWlans = false;
    },
    toggleLoadingCpesForWifiAnalyze(state) {
      state.loadingCpesForWifiAnalyze = !state.loadingCpesForWifiAnalyze;
    },
    toggleLoadingWlans(state) {
      state.loadingWlans = !state.loadingWlans;
    },
    toggleLoadingAccount(state) {
      state.loadingAccount = !state.loadingAccount;
    },
    toggleLoadingStats(state) {
      state.loadingStats = !state.loadingStats;
    },
    toggleLoadingRadius(state) {
      state.loadingRadius = !state.loadingRadius;
    },
    toggleLoadingRRM(state) {
      state.loadingRRM = !state.loadingRRM;
    },
    toggleLoadingControllers(state) {
      state.loadingControllers = !state.loadingControllers;
    },
    toggleLoadingExternalAps(state) {
      state.loadingExternalAps = !state.loadingExternalAps;
    },
    toggleLoadingFirewalls(state) {
      state.loadingFirewalls = !state.loadingFirewalls;
    },
    toggleLoadingHotspots(state) {
      state.loadingHotspots = !state.loadingHotspots;
    },
    toggleLoadingClients(state) {
      state.loadingClients = !state.loadingClients;
    },
    toggleLoadingClientsRFP(state) {
      state.loadingClientsRFP = !state.loadingClientsRFP;
    },
    toggleLoadingPortalLocationsStat(state) {
      state.loadingPortalLocationsStat = !state.loadingPortalLocationsStat;
    },
    enableLoadingSummary(state) {
      state.loadingSummary = true;
    },
    disableLoadingSummary(state) {
      state.loadingSummary = false;
    },
    loadingPortalVouchersStatBeging(state) {
      state.loadingPortalVouchersStat = true;
    },
    loadingPortalVouchersStatEnd(state) {
      state.loadingPortalVouchersStat = false;
    },
    enableLoadingVisitors(state) {
      state.loadingVisitors = true;
    },
    disableLoadingVisitors(state) {
      state.loadingVisitors = false;
    },
    toggleLoadingRules(state) {
      state.loadingRules = !state.loadingRules;
    },
    toggleLoadingCpesRules(state) {
      state.loadingCpesRules = !state.loadingCpesRules;
    },
    toggleLoadingMaps(state) {
      state.loadingMaps = !state.loadingMaps;
    },
    toggleLoadingSystemInfo(state) {
      state.loadingSystemInfo = !state.loadingSystemInfo;
    },
    toggleLoadingSystemInfoForDashboard(state) {
      state.loadingSystemInfoForDashboard = !state.loadingSystemInfoForDashboard;
    },
    toggleLoadingWlansInfoForDashboard(state) {
      state.loadingWlansInfoForDashboard = !state.loadingWlansInfoForDashboard;
    },
    toggleLoadingCpesInfoForDashboard(state) {
      state.loadingCpesInfoForDashboard = !state.loadingCpesInfoForDashboard;
    },
    toggleLoadingClientsInfoForDashboard(state) {
      state.loadingClientsInfoForDashboard = !state.loadingClientsInfoForDashboard;
    },
    toggleLoadingEventsCharts(state, force) {
      state.loadingEventsCharts = force;
    },
    toggleUpdateMap(state) {
      state.updateMap = !state.updateMap;
    },
    toggleLoadingTrafficBubble(state) {
      state.trafficBubbleLoading = !state.trafficBubbleLoading;
    },
    toggleLoadingClientsBubble(state) {
      state.clientsBubbleLoading = !state.clientsBubbleLoading;
    },
    toggleLoadingLbsClientsBubble(state) {
      state.lbsClientsBubbleLoading = !state.lbsClientsBubbleLoading;
    },
    toggleLoadingMapClients(state, force) {
      state.loadingMapClients = force;
    },
    toggleLoadingMapCpesStatisticAndClients(state) {
      state.loadingMapCpesStatisticAndClients = !state.loadingMapCpesStatisticAndClients;
    },
    beginLoadingMapCpesStatisticAndClients(state) {
      state.loadingMapCpesStatisticAndClients = true;
    },
    endLoadingMapCpesStatisticAndClients(state) {
      state.loadingMapCpesStatisticAndClients = false;
    },
    toggleLoadingCpesRating(state) {
      state.loadingCpesRating = !state.loadingCpesRating;
    },
    toggleLoadingCpesSessions(state) {
      state.loadingCpesSessions = !state.loadingCpesSessions;
    },
    toggleLoadingCpesForStat(state) {
      state.loadingCpesForStat = !state.loadingCpesForStat;
    },
    toggleLoadingCpesScanner(state) {
      state.loadingCpesScanner = !state.loadingCpesScanner;
    },
    toggleLoadingClientRF(state) {
      state.loadingclientRF = !state.loadingclientRF;
    },
    loadingClientRFRemainingDataBegin(state) {
      state.loadingClientRFRemainingData = true;
    },
    loadingClientRFRemainingDataEnd(state) {
      state.loadingClientRFRemainingData = false;
    },
    loadingEventsForClientBegin(state) {
      state.loadingEventsForCilent = true;
    },
    loadingEventsForClientEnd(state) {
      state.loadingEventsForCilent = false;
    },
    toggleLoadingClientDistance(state) {
      state.loadingclientDistance = !state.loadingclientDistance;
    },
    toggleLoadingSystemRating(state) {
      state.loadingSystemRating = !state.loadingSystemRating;
    },
    toggleLoadingReports(state) {
      state.loadingReports = !state.loadingReports;
    },
    toggleLoadingWlansRating(state) {
      state.loadingWlansRating = !state.loadingWlansRating;
    },
    toggleLoadingClientsRating(state) {
      state.loadingClientsRating = !state.loadingClientsRating;
    },
    toggleLoadingClientsRaw(state) {
      state.loadingClientsRaw = !state.loadingClientsRaw;
    },
    toggleLoadingCpesExport(state) {
      state.loadingCpesExport = !state.loadingCpesExport;
    },
    toggleMuteChangeLocation(state) {
      state.muteChangeLocation = !state.muteChangeLocation;
    },
    toggleLoadingAdsViewsDataForMarketerDashboard(state) {
      state.loadingAdsViewsDataForMarketerDashboard = !state.loadingAdsViewsDataForMarketerDashboard;
    },
    toggleLoadingCpeInfoForSelectedCpeOnMap(state) {
      state.loadingCpeInfoForSelectedCpeOnMap = !state.loadingCpeInfoForSelectedCpeOnMap;
    },
    toggleLoadingUsersInfoForSelectedCpeOnMap(state) {
      state.loadingUsersInfoForSelectedCpeOnMap = !state.loadingUsersInfoForSelectedCpeOnMap;
    },
    beginLoadingUsersInfoForSelectedCpeOnMap(state) {
      state.loadingUsersInfoForSelectedCpeOnMap = true;
    },
    endLoadingUsersInfoForSelectedCpeOnMap(state) {
      state.loadingUsersInfoForSelectedCpeOnMap = false;
    },
    setCurrentBuildHash(state, hash) {
      state.currentBuildHash = hash;
    },
    setLicense(state, items) {
      state.license = items;
    },
    setLicenceFileUploadingRequestExecutingBegin(state) {
      state.licenceFileUploadingRequestExecuting = true;
    },
    setLicenceFileUploadingRequestExecutingEnd(state) {
      state.licenceFileUploadingRequestExecuting = false;
    },
    setTextLicenceActivateRequestExecutingBegin(state) {
      state.licenceTextActivateRequestExecuting = true;
    },
    setTextLicenceActivateRequestExecutingEnd(state) {
      state.licenceTextActivateRequestExecuting = false;
    },
    setLicencesInfoGetRequestExecutingBegin(state) {
      state.licencesInfoGetRequestExecuting = true;
    },
    setLicencesInfoGetRequestExecutingEnd(state) {
      state.licencesInfoGetRequestExecuting = false;
    },
    setSummary(state, items) {
      state.marketingSummary = items;
    },
    setClientsList(state, list) {
      state.clientsList = list;
    },
    setClientsRFPList(state, list) {
      state.clientsRFPList = list;
    },
    setWlansList(state, list) {
      state.wlansList = list;
    },
    setWlansItems(state, items) {
      state.wlansItems = items;
    },
    setCpesList(state, list) {
      state.cpesList = list;
    },
    beginLoadingclickedManyClientsData(state) {
      state.manyClientsPointDataLoadingInMaps = true;
    },
    endLoadingclickedManyClientsData(state) {
      state.manyClientsPointDataLoadingInMaps = false;
    },
    setCpesListForWifiAnalyze(state, list) {
      state.cpesListForWifiAnalyze = list;
    },
    pushCpesListForWifiAnalyze(state, list) {
      if (!state.cpesListForWifiAnalyze) {
        state.cpesListForWifiAnalyze = list;
      } else {
        const newArray = list.filter(
          (cpe) => !helpers.combineIdsArrayFromObjectsArray(state.cpesListForWifiAnalyze).includes(cpe.id)
        );
        console.log(newArray);
        state.cpesListForWifiAnalyze = state.cpesListForWifiAnalyze.concat(newArray);
      }
    },
    setControllersList(state, list) {
      state.controllersList = list;
    },
    setExternalApsList(state, list) {
      state.externalAPsList = list;
    },
    pushExternalApsList(state, list) {
      if (!state.externalAPsList) {
        state.externalAPsList = list;
      } else {
        const newArray = list.filter(
          (externalAP) => !helpers.combineIdsArrayFromObjectsArray(state.externalAPsList).includes(externalAP.id)
        );
        state.externalAPsList = state.externalAPsList.concat(newArray);
      }
    },
    clearExternalApsList(state) {
      state.externalAPsList = false;
    },
    setCpeModelsList(state, list) {
      state.cpeModelsList = list;
    },
    setCpeTemplatesList(state, list) {
      state.cpeTemplatesList = list;
    },
    setCpesTagsList(state, list) {
      state.cpesTagsList = list;
    },
    setRequestsStatusForPortalStatisticPage(state, payload) {
      const { object } = payload;
      const { subtype } = payload;
      const { status } = payload;
      if (status === 'begin') {
        state.activeRequestsForPortalStatisticPage[object][subtype] = true;
      } else if (status === 'end') {
        state.activeRequestsForPortalStatisticPage[object][subtype] = false;
      }
    },
    setRequestsStatusForPortalStatisticPageForPreviousPeriod(state, payload) {
      const { object } = payload;
      const { subtype } = payload;
      const { status } = payload;
      if (status === 'begin') {
        state.activeRequestsForPortalStatisticPageForPreviousPeriod[object][subtype] = true;
      } else if (status === 'end') {
        state.activeRequestsForPortalStatisticPageForPreviousPeriod[object][subtype] = false;
      }
    },
    setRequestsStatusForPortalDashboardPage(state, payload) {
      const { object } = payload;
      const { subtype } = payload;
      const { status } = payload;
      if (status === 'begin') {
        state.activeRequestsForPortalDashboardPage[object][subtype] = true;
      } else if (status === 'end') {
        state.activeRequestsForPortalDashboardPage[object][subtype] = false;
      }
    },
    setRequestsStatusForPortalDashboardPageForPreviousPeriod(state, payload) {
      const { object } = payload;
      const { subtype } = payload;
      const { status } = payload;
      if (status === 'begin') {
        state.activeRequestsForPortalDashboardPageForPreviousPeriod[object][subtype] = true;
      } else if (status === 'end') {
        state.activeRequestsForPortalDashboardPageForPreviousPeriod[object][subtype] = false;
      }
    },
    setWlansTagsList(state, list) {
      state.wlansTagsList = list;
    },
    pushCpeList(state, list) {
      if (!state.cpesList) {
        state.cpesList = list;
      } else {
        const newArray = list.filter(
          (cpe) => !helpers.combineIdsArrayFromObjectsArray(state.cpesList).includes(cpe.id)
        );
        state.cpesList = state.cpesList.concat(newArray);
      }
    },
    pushClientList(state, list) {
      if (!state.clientsList) {
        state.clientsList = list;
      } else {
        const newArray = list.filter(
          (client) => !helpers.combineIdsArrayFromObjectsArray(state.clientsList).includes(client.id)
        );
        state.clientsList = state.clientsList.concat(newArray);
      }
    },
    pushClientListRFP(state, list) {
      if (!state.clientsRFPList) {
        state.clientsRFPList = list;
      } else {
        const newArray = list.filter(
          (client) => !helpers.combineMacArrayFromObjectsArray(state.clientsRFPList).includes(client.mac)
        );
        state.clientsRFPList = state.clientsRFPList.concat(newArray);
      }
    },
    pushPortalProfilesList(state, list) {
      if (!state.portalProfilesList) {
        state.portalProfilesList = list;
      } else {
        state.portalProfilesList = state.portalProfilesList.concat(list);
      }
    },
    portalProfilesListClean(state) {
      state.portalProfilesList = [];
    },
    portalLocationsStatListClean(state) {
      state.portalLocationsStatList = [];
    },
    portalSessionsListClean(state) {
      state.portalSessionsList = [];
    },
    pushPortalPagesList(state, list) {
      if (!state.portalPagesList) {
        state.portalPagesList = list;
      } else {
        state.portalPagesList = state.portalPagesList.concat(list);
      }
    },
    pushPortalPagesSchedulesList(state, list) {
      state.portalPagesSchedulesList = list;
    },
    pushPortalSessionsList(state, list) {
      if (!state.portalSessionsList) {
        state.portalSessionsList = list;
      } else {
        state.portalSessionsList = state.portalSessionsList.concat(list);
      }
    },
    portalPagesListClean(state) {
      state.portalPagesList = [];
    },
    pushPortalUserAccountsList(state, list) {
      if (!state.portalUserAccountsList) {
        state.portalUserAccountsList = list;
      } else {
        state.portalUserAccountsList = state.portalUserAccountsList.concat(list);
      }
    },
    pushPortalLocationsStatList(state, list) {
      state.portalLocationsStatList = list;
    },
    setPortalUserAccountsListByBirthday(state, list) {
      state.portalUserAccountsListByBirthday = list;
    },
    portalUserAccountsListClean(state) {
      state.portalUserAccountsList = [];
    },
    pushPortalTariffsList(state, list) {
      if (!state.portalTariffsList) {
        state.portalTariffsList = list;
      } else {
        state.portalTariffsList = state.portalTariffsList.concat(list);
      }
    },
    portalTariffsListClean(state) {
      state.portalTariffsList = [];
    },
    pushPortalPaymentSystems(state, list) {
      if (!state.portalPaymentSystemsList) {
        state.portalPaymentSystemsList = list;
      } else {
        state.portalPaymentSystemsList = state.portalPaymentSystemsList.concat(list);
      }
    },
    portalPaymentSystemsClean(state) {
      state.portalPaymentSystemsList = [];
    },
    pushPortalVouchersList(state, list) {
      if (!state.portalVouchersList) {
        state.portalVouchersList = list;
      } else {
        state.portalVouchersList = state.portalVouchersList.concat(list);
      }
    },
    portalVouchersListClean(state) {
      state.portalVouchersList = [];
    },
    pushPortalAdsList(state, list) {
      if (!state.portalAdsList) {
        state.portalAdsList = list;
      } else {
        state.portalAdsList = state.portalAdsList.concat(list);
      }
    },
    pushPortalAdsListWithCheckForExistence(state, list) {
      // console.log('pushPortalAdsListNoPagination')
      // console.log(list)
      if (!state.portalAdsList) {
        state.portalAdsList = list;
      } else {
        // если в списке state.portalAdsList уже есть какие то объекты реклам, добавляем в него
        // только те объекты, которых еще нет
        const adsListFromApi = list; // загруженные из API рекламы
        const adsListInStore = JSON.parse(JSON.stringify(state.portalAdsList)); // объекты, которые уже есть в сторе
        const adsListForAddingToStore = []; // объекты, которые добавим в стор
        const adsListFromApiIDs = []; // id'шники всех полученых из API реклам
        const adsListFromApiWithIDAsAKey = {}; // объекты этих реклам - где ключ ID'шник
        const adsListFromStoreIDs = []; // id'шники всех имеющихся в store реклам
        const adsListFromStoreWithIDAsAKey = {}; // объекты этих реклам - где ключ ID'шник

        // // проходимся по объектам Ad из API, сохраняем их ключи в отдельный массив, делаем объект,
        // // где id рекламы - ключ
        // for (const adFormAPI of adsListFromApi) {
        //   // console.log(ad)
        //   adsListFromApiIDs.push(adFormAPI.id);
        //   adsListFromApiWithIDAsAKey[adFormAPI.id] = JSON.parse(JSON.stringify(adFormAPI));
        // }
        // // console.log(adsListFromApiIDs);
        // // console.log(adsListFromApiWithIDAsAKey);
        // // проходимся по объектам Ad из стора, сохраняем их ключи в отдельный массив, делаем объект,
        // // где id рекламы - ключ
        // for (const adFormStore of adsListInStore) {
        //   // console.log(ad)
        //   adsListFromStoreIDs.push(adFormStore.id);
        //   adsListFromStoreWithIDAsAKey[adFormStore.id] = JSON.parse(JSON.stringify(adFormStore));
        // }
        // // смотрим каких реклам еще нет в store, и добавляем их в массив adsListForAddingToStore
        // for (const adFromApiID of adsListFromApiIDs) {
        //   if (!adsListFromStoreIDs.includes(adFromApiID)) {
        //     adsListForAddingToStore.push(adsListFromApiWithIDAsAKey[adFromApiID]);
        //   }
        // }

        // проходимся по объектам Ad из API, сохраняем их ключи в отдельный массив, делаем объект,
        // где id рекламы - ключ
        adsListFromApi.forEach((adFormAPI) => {
          adsListFromApiIDs.push(adFormAPI.id);
          adsListFromApiWithIDAsAKey[adFormAPI.id] = JSON.parse(JSON.stringify(adFormAPI));
        });
        // проходимся по объектам Ad из стора, сохраняем их ключи в отдельный массив, делаем объект,
        // где id рекламы - ключ
        adsListInStore.forEach((adFormStore) => {
          adsListFromStoreIDs.push(adFormStore.id);
          adsListFromStoreWithIDAsAKey[adFormStore.id] = JSON.parse(JSON.stringify(adFormStore));
        });
        // смотрим каких реклам еще нет в store, и добавляем их в массив adsListForAddingToStore
        adsListFromApiIDs.forEach((adFromApiID) => {
          if (!adsListFromStoreIDs.includes(adFromApiID)) {
            adsListForAddingToStore.push(adsListFromApiWithIDAsAKey[adFromApiID]);
          }
        });
        // console.log(adsListFromApiWithIDAsAKey);
        // console.log(adsListFromStoreWithIDAsAKey);
        // console.log(adsListForAddingToStore);

        // добавляем рекалмы, которых еще там нет в state.portalAdsList
        state.portalAdsList = state.portalAdsList.concat(adsListForAddingToStore);
      }
    },
    portalAdsListClean(state) {
      state.portalAdsList = [];
    },
    pushWlanList(state, list) {
      if (!state.wlansList) {
        state.wlansList = list;
      } else {
        const newArray = list.filter(
          (client) => !helpers.combineIdsArrayFromObjectsArray(state.wlansList).includes(client.id)
        );
        state.wlansList = state.wlansList.concat(newArray);
      }
    },
    setHostsList(state, list) {
      state.hostsList = list;
    },
    setSystemInfo(state, list) {
      state.systemInfo = list;
    },
    setWlansInfo(state, list) {
      state.wlansInfo = list;
    },
    setCpesInfo(state, list) {
      state.cpesInfo = list;
    },
    setClientsInfo(state, list) {
      state.clientsInfo = list;
    },
    setTotalObjInfo(state, list) {
      state.systemTotalObjList = list;
    },
    setEvents(state, list) {
      state.eventsList = list;
    },
    pushEvents(state, list) {
      if (!state.eventsList) {
        state.eventsList = list;
      } else {
        const newArray = list.filter(
          (event) => !helpers.combineIdsArrayFromObjectsArray(state.eventsList).includes(event.id)
        );
        state.eventsList = state.eventsList.concat(newArray);
      }
    },
    setMapsList(state, list) {
      state.mapsList = list;
    },
    pushMapsList(state, list) {
      if (!state.mapsList) {
        state.mapsList = list;
      } else {
        const newArray = list.filter(
          (map) => !helpers.combineIdsArrayFromObjectsArray(state.mapsList).includes(map.id)
        );
        state.mapsList = state.mapsList.concat(newArray);
      }
    },
    setCreatedMapData(state, mapData) {
      state.createdMapData = mapData;
    },
    removeItemFromMapList(state, mapId) {
      state.mapsList = state.mapsList.filter((map) => {
        if (map.id === mapId) {
          return false;
        }
        return true;
      });
    },
    updateSingleMapData(state, newMapObject) {
      state.mapsList.forEach((mapObject, index) => {
        if (mapObject.id === newMapObject.id) {
          state.mapsList[index] = newMapObject;
        }
      });
    },
    setSystemRatingList(state, list) {
      state.systemRatingList = list;
    },
    setCpesRatingList(state, list) {
      state.cpesRatingList = list;
    },
    pushCpesRatingList(state, list) {
      if (!state.cpesRatingList) {
        state.cpesRatingList = list;
      } else {
        state.cpesRatingList = state.cpesRatingList.concat(list);
      }
    },
    clearCpesRatingList(state) {
      state.cpesRatingList = false;
    },
    setCpesSessionsList(state, list) {
      state.cpesSessionsList = list;
    },
    setClientsSessionsList(state, list) {
      state.clientsSessionsList = list;
    },
    pushClientsSessionsList(state, list) {
      if (!state.clientsSessionsList) {
        state.clientsSessionsList = list;
      } else {
        state.clientsSessionsList = state.clientsSessionsList.concat(list);
      }
    },
    clearClientsSessionsList(state) {
      state.clientsSessionsList = false;
    },
    setWlansRatingList(state, list) {
      state.wlansRatingList = list;
    },
    setClientsRatingList(state, list) {
      state.clientsRatingList = list;
    },
    pushClientsRatingList(state, list) {
      if (!state.clientsRatingList) {
        state.clientsRatingList = list;
      } else {
        state.clientsRatingList = state.clientsRatingList.concat(list);
      }
    },
    clearClientsRatingList(state) {
      state.clientsRatingList = false;
    },
    setWlanEditIndex(state, index) {
      state.editWlanIndex = index;
    },
    setCpesEditIndex(state, index) {
      if (state.cpesList) {
        state.editCpeIndex = index;
      }
    },
    setRadiusEditIndex(state, index) {
      if (state.radiusList) {
        state.editRadiusIndex = index;
      }
    },
    setRadiusEditIndexForRadiusV2Page(state, index) {
      if (state.radiusListForRadiusV2Page) {
        state.editRadiusIndexForRadiusV2Page = index;
      }
    },
    setClientEditIndex(state, index) {
      state.editClientIndex = index;
    },
    setClientEditForRFPIndex(state, index) {
      state.editClientForRFPIndex = index;
    },
    setMapsEditIndex(state, index) {
      if (state.mapsList) {
        state.editMapIndex = index;
      }
    },
    setMapsEditId(state, id) {
      if (state.mapsList) {
        state.editMapId = id;
      }
    },
    setUploadMapImageResult(state, data) {
      state.uploadMapImageResult = data;
    },
    setCpesEditId(state, id) {
      if (state.cpesList) {
        state.editCpeId = id;
      }
    },
    setCpesStatId(state, id) {
      if (state.cpesList) {
        state.statCpeId = id;
      } else {
        state.statCpeId = null;
      }
    },
    setCpesStatIdForWifiAnalyze(state, id) {
      state.statCpeId = id;
    },
    setLocationStatId(state, id) {
      state.statLocationId = id;
    },
    setClientStatId(state, id) {
      state.statClientId = id;
    },
    setWlansEditId(state, id) {
      state.editWlanId = id;
    },
    setWlanStatId(state, id) {
      if (state.wlansList) {
        state.statWlanId = id;
      }
    },
    setRadiusEditId(state, id) {
      if (state.radiusList) {
        state.editRadiusId = id;
      }
    },
    setRadiusEditIdForRadiusV2Page(state, id) {
      if (state.radiusListForRadiusV2Page) {
        state.editRadiusIdForRadiusV2Page = id;
      }
    },
    setClientEditMac(state, mac) {
      // if (state.radiusList) {
      state.editClientMac = mac;
      // }
    },
    setClientEditForRFPMac(state, mac) {
      // if (state.radiusList) {
      state.editClientForRFPMac = mac;
      // }
    },
    setClientEditMacAddr(state, macAddr) {
      state.editClientMacAddr = macAddr;
    },
    setClientEditForRFPMacAddr(state, macAddr) {
      state.editClientForRFPMacAddr = macAddr;
    },
    setTemplateEditId(state, id) {
      state.editTemplateId = id;
    },
    setFirewallEditId(state, id) {
      state.editFirewallId = id;
    },
    setRedirectEditId(state, id) {
      state.editRedirectId = id;
    },
    setHotspotEditId(state, id) {
      state.editHotspotId = id;
    },
    setNewWlanWizardSecurityTab(state, payload) {
      state.newWlanWizardSecurityTab = payload;
    },
    setNewWlanData(state, data) {
      Object.assign(state.newWlanData, data);
    },
    setNewWlanDataDefaultTunnelClear(state) {
      Vue.set(state.newWlanData, 'default_tunnel', '');
    },
    setStepNameForReturningToCreateWLANWizard(state, data) {
      state.stepNameForReturningToCreateWLANWizard = data;
    },
    clearStepNameForReturningToCreateWLANWizard(state) {
      state.stepNameForReturningToCreateWLANWizard = undefined;
    },
    setStepNameForAdvancedTabForReturningToCreateWLANWizard(state, data) {
      state.stepNameForAdvancedTabForReturningToCreateWLANWizard = data;
    },
    clearStepNameForAdvancedTabForReturningToCreateWLANWizard(state) {
      state.stepNameForAdvancedTabForReturningToCreateWLANWizard = undefined;
    },
    setReturnToEditWlan(state, data) {
      state.returnToEditWlan = data;
    },
    clearReturnToEditWlan(state) {
      state.returnToEditWlan = false;
    },
    setReturnToWlanWizard(state, data) {
      state.returnToWlanWizard = data;
    },
    clearReturnToWlanWizard(state) {
      state.returnToWlanWizard = false;
    },
    setNewRadiusData(state, data) {
      Object.assign(state.newRadiusData, data);
    },
    resetNewTemplateData(state) {
      state.newTemplateData = {
        name: '',
        model: '',
        description: '',
        cpes: [],
        base_location: '',
        is_auto: true,
        subnet: {}
      };
    },
    setNewTemplateData(state, data) {
      Object.assign(state.newTemplateData, data);
    },
    setNewFirewallData(state, data) {
      Object.assign(state.newFirewallData, data);
    },
    setNewRuleData(state, data) {
      state.newRuleData = data;
    },
    setNewRedirectRuleData(state, data) {
      state.newRedirectRuleData = data;
    },
    setNewHotspotData(state, data) {
      Object.assign(state.newHotspotData, data);
    },
    setUsersDemoWizardStep1SavedLocationObject(state, payload) {
      state.usersDemoWizardStepsData.step1SavedLocationObject = payload;
    },
    setUsersDemoWizardStep2SavedUserObject(state, payload) {
      state.usersDemoWizardStepsData.step2SavedUserObject = payload;
    },
    setUsersDemoWizardStep3SavedWlanObject(state, payload) {
      state.usersDemoWizardStepsData.step3SavedWlanObject = payload;
    },
    setUsersDemoWizardStep4SavedProfileObject(state, payload) {
      state.usersDemoWizardStepsData.step4SavedProfileObject = payload;
    },
    setUsersDemoWizardStep5SavedPageObject(state, payload) {
      state.usersDemoWizardStepsData.step5SavedPageObject = payload;
    },
    clearUsersDemoWizardStepsData(state) {
      state.usersDemoWizardStepsData = {
        step1SavedLocationObject: false,
        step2SavedUserObject: false,
        step3SavedWlanObject: false,
        step4SavedProfileObject: false,
        step5SavedPageObject: false
      };
    },
    clearNewHotspotData(state) {
      state.newHotspotData = {
        name: '',
        net_type: 1,
        internet: false,
        asra: false,
        esr: false,
        uesa: false,
        dgaf: false,
        venue: {
          group: 0,
          type: 0,
          names: []
        },
        oper_names: [],
        ip_types: {
          ipv4type: -1,
          ipv6type: -1
        },
        oper_classes: [],
        wan_metrics: {
          wan_info: 0,
          dl_speed: 0,
          ul_speed: 0,
          dl_load: 0,
          ul_load: 0,
          lmd: 0
        },
        realms: [],
        conn_caps: [],
        domains: [],
        consortiums: [],
        cellular: []
      };
    },
    clearNewFirewallData(state, data) {
      state.newFirewallData = {
        name: '',
        policy: 'ACCEPT',
        direction: 'ANY',
        rules: []
      };
    },
    clearNewRedirectData(state, data) {
      state.newRedirectRuleData = {
        name: '',
        redirect_url: '',
        redirect_ip: '',
        mac_list: [],
        url_list: []
      };
    },
    clearNewRadiusData(state, data) {
      state.newRadiusData = {
        auth_port: '1812',
        acc_port: '1813',
        is_local: true,
        is_portal: false,
        dae_client: '',
        // dae_port: '3799',
        // теперь не ставим по умолчанию, так как вообще обрали это поле из UI (DF-65)
        dae_port: '',
        dae_secret: ''
      };
    },
    clearNewWlanData(state) {
      state.newWlanData = {
        base_location: '',
        ssid: '',
        description: '',
        security: {
          type: '',
          data: {
            psk: '',
            suites: ['aes'],
            radiusauthentication: []
          }
        },
        // vlan: '',
        vlan: 0,
        hidden: false,
        filtermode: 'None',
        // даллее поля для шага 3 визарда создания wlan
        tunneling: false,
        firewall: {
          nat_access: false,
          l2_chain: ''
        },
        default_tunnel: '',
        nat: false,
        nat_network: {
          ipaddr: '',
          netmask: ''
        },
        proto: 'l2tpv3',
        peer_address: '',
        // далее поля для шага 4
        radius_acct_mirroring: false,
        beeline_accountng_type: '',
        radius_acct_servers: [],
        // далее поля для шага 5
        nas_id: null,
        nas_port_id: '',
        // далее поля для шага 6
        speed_download: { type: 'kbit', value: '' },
        speed_upload: { type: 'kbit', value: '' },
        // далее поля для шага 7
        guest_control: {
          captive_redirect: '',
          mac_radius_auth_servers: []
        },
        l2isolate: false,
        whitelist: [],
        blacklist: [],
        // далее поля для шага 8
        roam80211r: false,
        pmkcaching: false,
        ieee80211k: false,
        rrm_neighbor_report: true,
        rrm_beacon_report: false,
        ieee80211v: false,
        wnm_sleep_mode: false,
        bss_transition: false,
        rssi_threshold: -90,
        signal_stay: -60,
        signal_strikes: 3,
        signal_poll_time: 5,
        signal_drop_reason: 3,
        band_steering: false,
        ft_over_ds: false,
        nas_generate: false,
        load_balancing: false,
        // далее для шага 9
        wmm: {
          disabled: false,
          uapsd: false,
          categories: {}
        },
        // dhcpOption82CircuitId: '',
        option82state: false,
        option82rid_type: 'APMAC:SSID',
        option82cid_type: ''
      };
    },
    // Clients stat
    // setUserData(state, data) {
    //   localStorage.setItem('userData', JSON.stringify(data));
    //   Object.assign(state.userData, data);
    // },
    setUserData(state, data) {
      localStorage.setItem('userData', JSON.stringify(data));
      Object.assign(state.userData, data);
      this.commit('loadInHeaderLocationSelectBlockDataFromLocalStorage')
    },
    setBaseLocation(state, id) {
      state.baseLocation = id;
    },
    setLocationsItems(state, list) {
      state.locationsItems = list;
    },
    setCompanysItems(state, list) {
      state.companysItems = helpers.sortCompanysList(list);
    },
    setLocationsList(state, list) {
      state.locationsList = list;
      state.locationsListSorted = helpers.sortLocationsList(list);
      // когда обновляем локации, подтягиваем еще и компании
      locationService.getCompanys(this);
    },
    setUsersList(state, list) {
      state.usersList = list;
    },
    setRadiusList(state, data) {
      state.radiusList = data;
    },
    setRadiusListForRadiusV2Page(state, data) {
      state.radiusListForRadiusV2Page = data;
    },
    setRadarExportList(state, data) {
      state.radarExportList = data;
    },
    setReportsList(state, data) {
      state.reportsList = data;
    },
    setPortalProfilesList(state, data) {
      state.portalProfilesList = data;
    },
    setPortalProfilesLocationsList(state, data) {
      state.portalProfilesLocationsList = data;
    },
    setPortalPagesList(state, data) {
      state.portalPagesList = data;
    },
    setPortalSessionsList(state, data) {
      state.portalSessionsList = data;
    },
    setPortalPagesLocationsList(state, data) {
      state.portalPagesLocationsList = data;
    },
    setPortalAdsList(state, data) {
      state.portalAdsList = data;
    },
    setPortalAdsLocationsList(state, data) {
      state.portalAdsLocationsList = data;
    },
    setPortalAdsStatsList(state, data) {
      state.portalAdsStatsList = data;
    },
    setPortalAuthentications(state, data) {
      state.portalAuthenticationsList = data;
    },
    setPortalAuthorizations(state, data) {
      state.portalAuthorizationsList = data;
    },
    setPortalUserAccountsList(state, data) {
      state.portalUserAccountsList = data;
    },
    setPortalUserTransactionsList(state, data) {
      state.portalUserTransactionsList = data;
    },
    setPortalTariffsList(state, data) {
      state.portalTariffsList = data;
    },
    setPortalVouchersList(state, data) {
      state.portalVouchersList = data;
    },
    setPortalVouchersStatList(state, data) {
      state.portalVouchersStatList = data;
    },
    setPortalPaymentSystemsList(state, data) {
      state.portalPaymentSystemsList = data;
    },
    setRRMList(state, data) {
      state.rrmList = data;
    },
    setFirewallsList(state, data) {
      state.firewallsList = data;
    },
    setHotspotsList(state, data) {
      state.hotspotsList = data;
    },
    setRedirectsList(state, data) {
      state.redirectsList = data;
    },
    setRulesList(state, data) {
      state.rulesList = data;
    },
    setClientsStatList(state, data) {
      state.clientsStatList = data;
    },
    setClientsRawList(state, list) {
      state.clientsRawList = list;
    },
    setCpesRulesList(state, data) {
      state.cpesRulesList = data;
    },
    setUserEditIndex(state, index) {
      if (state.usersList) {
        state.editUserIndex = index;
      }
    },
    setOperationsList(state, operationslist) {
      state.operationsList = operationslist;
    },
    setOperationsTotal(state, operationsTotal) {
      state.operationsTotal = operationsTotal;
    },
    setWlanOperations(state, operations) {
      state.wlanOperations = state.wlanOperations.concat(helpers.transformObjectToArray(operations));
    },
    setCpeOperations(state, operations) {
      state.cpeOperations = state.cpeOperations.concat(helpers.transformObjectToArray(operations));
    },
    setRadiusOperations(state, operations) {
      state.radiusOperations = state.radiusOperations.concat(helpers.transformObjectToArray(operations));
    },
    setFirewallOperations(state, operations) {
      state.firewallOperations = state.firewallOperations.concat(helpers.transformObjectToArray(operations));
    },
    setHotspotOperations(state, operations) {
      state.hotspotOperations = state.hotspotOperations.concat(helpers.transformObjectToArray(operations));
    },
    setRedirectOperations(state, operations) {
      state.redirectOperations = state.redirectOperations.concat(helpers.transformObjectToArray(operations));
    },
    setControllerOperations(state, operations) {
      state.controllerOperations = state.controllerOperations.concat(helpers.transformObjectToArray(operations));
    },
    setExternalApOperations(state, operations) {
      state.externalApOperations = state.externalApOperations.concat(helpers.transformObjectToArray(operations));
    },
    setWlanOperationStatus(state, operationInfo) {
      state.wlanOperations = state.wlanOperations.filter((operation) => operation.id !== operationInfo.id);
      localStorage.setItem('wlanOperations', JSON.stringify(state.wlanOperations));
    },
    setCpeOperationStatus(state, operationInfo) {
      state.cpeOperations = state.cpeOperations.filter((operation) => operation.id !== operationInfo.id);
      localStorage.setItem('cpeOperations', JSON.stringify(state.cpeOperations));
    },
    setRadiusOperationStatus(state, operationInfo) {
      state.radiusOperations = state.radiusOperations.filter((operation) => operation.id !== operationInfo.id);
      localStorage.setItem('cpeOperations', JSON.stringify(state.cpeOperations));
    },
    setL2ChainOperationStatus(state, operationInfo) {
      state.firewallOperations = state.firewallOperations.filter((operation) => operation.id !== operationInfo.id);
      localStorage.setItem('firewallOperations', JSON.stringify(state.firewallOperations));
    },
    setHotspotOperationStatus(state, operationInfo) {
      state.hotspotOperations = state.hotspotOperations.filter((operation) => operation.id !== operationInfo.id);
      localStorage.setItem('hotspotOperations', JSON.stringify(state.hotspotOperations));
    },
    setRedirectOperationStatus(state, operationInfo) {
      state.redirectOperations = state.redirectOperations.filter((operation) => operation.id !== operationInfo.id);
      localStorage.setItem('redirectOperations', JSON.stringify(state.redirectOperations));
    },
    setControllerOperationStatus(state, operationInfo) {
      state.controllerOperations = state.controllerOperations.filter((operation) => operation.id !== operationInfo.id);
      localStorage.setItem('controllerOperations', JSON.stringify(state.controllerOperations));
    },
    setExternalApOperationsOperationStatus(state, operationInfo) {
      state.externalApOperations = state.externalApOperations.filter((operation) => operation.id !== operationInfo.id);
      localStorage.setItem('externalApOperations', JSON.stringify(state.externalApOperations));
    },
    toggleDashboardLoading(state, action) {
      if (action) {
        state.dashboardLoadingCount++;
        return;
      }
      state.dashboardLoadingCount--;
    },
    startRequestToLoadConnectionsDataForMarketerDashboard(state) {
      state.activeRequestsForConnectionsStatisticInDashboardMarketerView++;
    },
    stopRequestToLoadConnectionsDataForMarketerDashboard(state) {
      state.activeRequestsForConnectionsStatisticInDashboardMarketerView--;
    },
    paywallForAuthFreemiumShowingSetTrue(state) {
      state.isPaywallForAuthFreemiumShowing = true;
    },
    paywallForAuthFreemiumShowingSetFalse(state) {
      state.isPaywallForAuthFreemiumShowing = false;
    },
    newVersionCheckerEnabledSetTrue(state) {
      state.isNewVersionCheckerEnabled = true;
    },
    newVersionCheckerEnabledSetFalse(state) {
      state.isNewVersionCheckerEnabled = false;
    },
    userBlockOrUnblockRequestBegin(state) {
      state.userBlockOrUnblockRequestExecute = true;
    },
    userBlockOrUnblockRequestEnd(state) {
      state.userBlockOrUnblockRequestExecute = false;
    },
    clickedClusterDataFiltereInMapsBegin(state) {
      state.clickedClusterDataFiltereInMapsFilteringExecute = true;
    },
    clickedClusterDataFiltereInMapsEnd(state) {
      state.clickedClusterDataFiltereInMapsFilteringExecute = false;
    },
    clickInClusterDataForSeeUserInfoEnable(state) {
      state.clickInClusterDataForSeeUserInfo = true;
    },
    clickInClusterDataForSeeUserInfoDisable(state) {
      state.clickInClusterDataForSeeUserInfo = false;
    },
    clickSpiderfyDataForSeeUserInfoEnable(state) {
      state.clickSpiderfyDataForSeeUserInfo = true;
    },
    clickSpiderfyDataForSeeUserInfoDisable(state) {
      state.clickSpiderfyDataForSeeUserInfo = false;
    },
    clickManyClientsPointToSeeUserInfoEnable(state) {
      state.clickManyClientsPointToSeeUserInfo = true;
    },
    clickManyClientsPointToSeeUserInfoDisable(state) {
      state.clickManyClientsPointToSeeUserInfo = false;
    },
    clickUserPointAfterClickSpiderfyDataForSeeUserInfoEnable(state) {
      state.clickUserPointAfterClickSpiderfyDataForSeeUserInfo = true;
    },
    clickUserPointAfterClickSpiderfyDataForSeeUserInfoDisable(state) {
      state.clickUserPointAfterClickSpiderfyDataForSeeUserInfo = false;
    },
    portalSessionUpdateRequestExecutingBegin(state) {
      state.portalSessionUpdateRequestExecuting = true;
    },
    portalSessionUpdateRequestExecutingEnd(state) {
      state.portalSessionUpdateRequestExecuting = false;
    },
    portalSessionUpdateSessionObjectInStore(state, updatedSession) {
      // вызывается при успешном обновлении session после PUT запроса на бэк
      // ищет нужную сессию в списке сессий (по соответсвию ID'шников) и обновляет ее в сторе
      // чтобы не запрашивать все сессии заново c бэка
      // console.log(updatedSession);
      if (state.portalSessionsList && Array.isArray(state.portalSessionsList)) {
        if (updatedSession.hasOwnProperty('id') && updatedSession.id !== '') {
          for (const sessionIndex in state.portalSessionsList) {
            if (state.portalSessionsList[sessionIndex].id === updatedSession.id) {
              Vue.set(state.portalSessionsList, sessionIndex, JSON.parse(JSON.stringify(updatedSession)));
              break;
            }
          }
        }
      }
    },

    portalAccessServersGetRequestExecutingBegin(state) {
      state.portalAccessServersGetRequestExecuting = true;
    },
    portalAccessServersGetRequestExecutingEnd(state) {
      state.portalAccessServersGetRequestExecuting = false;
    },
    portalAccessServersUpdateRequestExecutingBegin(state) {
      state.portalAccessServersUpdateRequestExecuting = true;
    },
    portalAccessServersUpdateRequestExecutingEnd(state) {
      state.portalAccessServersUpdateRequestExecuting = false;
    },
    portalAccessServersCreateRequestExecutingBegin(state) {
      state.portalAccessServersCreateRequestExecuting = true;
    },
    portalAccessServersCreateRequestExecutingEnd(state) {
      state.portalAccessServersCreateRequestExecuting = false;
    },
    portalAccessServersDeleteRequestExecutingBegin(state) {
      state.portalAccessServersDeleteRequestExecuting = true;
    },
    portalAccessServersDeleteRequestExecutingEnd(state) {
      state.portalAccessServersDeleteRequestExecuting = false;
    },
    setIsClickToGoToAnotherPageTrue(state) {
      state.isClickToGoToAnotherPage = true;
    },
    setIsClickToGoToAnotherPageFalse(state) {
      state.isClickToGoToAnotherPage = false;
    },
    setUpdatedWlanIdsInOperations(state, payload) {
      state.updatedWlanIdsInOperations = payload;
    },
    pushUpdatedWlanIdsInOperations(state, payload) {
      // console.log(payload)
      state.updatedWlanIdsInOperations.push(payload);
    },
    clearUpdatedWlanIdsInOperations(state) {
      state.updatedWlanIdsInOperations = [];
    },
    setIsGetClientPathRequestExecutingBegin(state) {
      state.isGetClientPathRequestExecuting = true;
    },
    setIsGetClientPathRequestExecutingEnd(state) {
      state.isGetClientPathRequestExecuting = false;
    }
  },
  modules: {
    ONEProduct,
    WLCProduct,
    locale,
    apiStatus,
    branding,
    featureFlags,
    theme,
    menu,
    auth,
    intervalUpdateMode,
    navigation,
    autologout
  }
});

export default store;
