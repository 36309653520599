<template>
  <div v-if="props.row.tags.length">
    <div class="row-tags">
      <span v-for="tag in props.row.tags"
        :key="tag"
        class="tag tag--item mr-q"
        @click="handleClickTag(tag)"
      >
        <i class="fa fa-tag"></i>
        <i class="fa fa-search"></i>
        {{ tag }}
      </span>
    </div>
  </div>
  <span v-else>-</span>
</template>

<script>
/**
 * компонент - ячейка tags.
 * компонент локальный: используется только в ApsTable.vue
 * @component
 */

export default {
  name: 'TagsCell',
  components: {},
  props: {
    props: {
      type: Object,
      required: true
    }
  },
  methods: {
    handleClickTag(tag) {
      this.$emit('on-tag-click', tag);
    }
  }
};
</script>

<style lang="css" scoped>
</style>
