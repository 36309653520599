<template>
  <WDropdown
    openCloseToggleMode
    position="bottom-left"
    :isOpen="isDropdownOpen"
    @close="handleCloseDropdown"
    @open="handleOpenDropdown"
  >
    <template v-slot:button-inner-content>
      <WInput
        :key="inputConfig.id"
        type="text"
        :label="inputConfig.label"
        :value="value"
        @input="handleInput(inputConfig, $event)"
        :id="inputConfig.id"
        :name="inputConfig.name"
        :placeholder="inputConfig.placeholder"
        :disabled="inputConfig.isDisabled"
        v-validate="inputConfig.validateRules"
        data-vv-validate-on="input"
        :data-vv-as="inputConfig.label"
        :errorText="errors.first(inputConfig.name)"
        autocomplete="one-time-code"
      />
    </template>
    <template v-slot:inner-content>
      <div v-if="inputConfig.selectOptions.length"
        class="text-with-select-list-wrapper"
      >
        <WList
          class="text-with-select-list"
          :fixedItems="{ maxItems: 5 }"
        >
          <li v-for="selectOption in inputConfig.selectOptions"
            :key="selectOption.data"
            @click="handleSuggestionSelect(inputConfig, selectOption.data)"
          >
            <span class="title" :title="selectOption.display">
              {{ selectOption.display }}
            </span>
          </li>
        </WList>
      </div>
    </template>
  </WDropdown>
</template>

<script>
/**
 * Кастомный инпут с дропдауном подсказками для типа textWithSelect формы VLANForm.vue
 * ! компонент локальный, используется только в VLANForm.vue
 * @component
 */

export default {
  name: 'TextWithSelectInput',
  inject: ['$validator'],
  components: {
  },
  props: {
    inputConfig: {
      type: Object,
      required: true
    },
    value: {
      type: [String, Number],
      required: true
    }
  },
  data() {
    return {
      isDropdownOpen: false
    };
  },
  computed: {
  },
  methods: {
    handleInput(inputObj, value) {
      const { name, inputType } = inputObj;

      // из-за особенностей архитектуры и невозможности использовать здесь v-modal, форматировать number приходится так
      let correctFormatValue = value;

      if (inputType === 'number') {
        correctFormatValue = value === '' ? '' : Number(correctFormatValue);
      }

      this.$emit('updateValue', {
        name,
        value: correctFormatValue
      });
    },
    handleSuggestionSelect(inputObj, value) {
      const { name } = inputObj;

      this.$emit('updateValue', {
        name,
        value
      });

      this.handleCloseDropdown();
    },
    handleOpenDropdown() {
      this.isDropdownOpen = true;
    },
    handleCloseDropdown() {
      this.isDropdownOpen = false;
    }
  }
};
</script>

<style lang="css" scoped>
.text-with-select-list-wrapper {
  min-width: 200px;
  padding: 16px 16px;
  background-color: var(--table-layout-bg-color);
  color: var(--table-font-color);
  border-radius: 4px;
  border: 1px solid var(--table-border-color);
}

.text-with-select-list {
  display: flex;
  flex-direction: column;
  gap: 4px;
  overflow-y: auto;
  overflow-x: hidden;
}

.text-with-select-list > li {
  cursor: pointer;
}
</style>
