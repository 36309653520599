/**
 * API-модуль для страницы ap join.
 * @module apJoinApi
*/

import getAPsProfiles from './methods/getAPsProfiles';
import createAPsProfile from './methods/createAPsProfile';
import updateAPsProfile from './methods/updateAPsProfile';
import deleteAPsProfile from './methods/deleteAPsProfile';

const apJoinApi = {
  getAPsProfiles,
  createAPsProfile,
  updateAPsProfile,
  deleteAPsProfile
};

export default apJoinApi;
