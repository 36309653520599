<template>
  <WModalContent highOrderedModal danger :isOpen="isExpirationStage">
    <template #header>
      <h4 class="heading">
        {{ $t('login.autologout.extendModal.heading') }}
      </h4>
    </template>
    <template #body>
      <div class="body">
        <h5>
          {{ $t('login.autologout.extendModal.text', { timeLeft: expirationStageRemainingTime / 1000 }) }}
        </h5>
      </div>
    </template>
    <template #footer>
      <div class="button-section">
        <WButton danger outline @click="onEnd">
          {{ $t('login.autologout.extendModal.closeSession') }}
        </WButton>
        <WButton success outline @click="onExtend">
          {{ $t('login.autologout.extendModal.extendSession') }}
        </WButton>
      </div>
    </template>
  </WModalContent>
</template>

<script>
/**
 * компонент - модалка продления пользовательской сессии или ее принудительного завершения.
 * Дает пользователю в последнюю минуту истекающей сессии дать возможность продлить или выйти принудительно
 * (если ничего не выберет, произойдет авторазлогирование).
 *
 * компонент локальный: используется только в Full.vue
 * @component
 */
import { userAutologoutMixin } from './mixins';

export default {
  name: 'UserSessionExpirationModal',
  mixins: [
    userAutologoutMixin
  ],
  props: {
  },
  data() {
    return {};
  },
  watch: {
  },
  methods: {
    onExtend() {
      this.restartUserActivityTracking();
    },
    onEnd() {
      this.endUserSession();
    }
  }
};
</script>

<style lang="css" scoped>
h4, h5 {
  margin: 0;
}
.button-section {
  display: flex;
  justify-content: space-between;
}
</style>
