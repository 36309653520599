/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
/**
 * конфигурация таблицы RRMApsTable.vue
*/

/**
 * генератор колонок таблицы
 * https://xaksis.github.io/vue-good-table/guide/configuration/column-options.html#label
*/

import {
  getSelectedRowValue
} from './utils';

function generateTableColumns({ i18nInstance, hiddenColumnRules, additionalRules }) {
  const $t = i18nInstance.t.bind(i18nInstance);

  return [
    {
      label: $t('general.name'),
      field: 'name',
      width: 'auto',
      type: 'text',
      hidden: hiddenColumnRules.name
    },
    {
      label: $t('RRM.group'),
      field: 'group',
      width: 'auto',
      type: 'text',
      hidden: hiddenColumnRules.group
      // visibilityDropdownSettings: {
      //   hidden: !areLocationsVisible
      // }
    }
  ];
}

/**
 * генератор строк таблицы
 * https://xaksis.github.io/vue-good-table/guide/configuration/#rows
 * https://xaksis.github.io/vue-good-table/guide/advanced/grouped-table.html#customizing-header-row
*/
function generateTableRows({ RRMApsData, selectedRowApsIds }) {
  return RRMApsData.map(ap => {
    const {
      base_location,
      clients,
      config,
      config_not_send,
      config_status,
      connected,
      description,
      first_connection,
      id,
      last_connection,
      last_disconnection,
      last_error,
      latitude,
      longitude,
      model,
      name,
      rrm_group,
      state,
      tags
    } = ap;

    return {
      // nativeData - хранит неформатированные данные. Нужны, чтобы удобно эмитить их по событию для дальнейших манипуляций.
      nativeData: ap,
      name,
      group: rrm_group?.rrm_group,
      // можно ли выбирать строку чекбоксом
      // vgtDisabled: false,
      // выбрана ли строка в чекбоксе исходя из стейта, который контролирует выбранные строки
      vgtSelected: getSelectedRowValue(id, selectedRowApsIds)
    };
  });
}

export {
  generateTableColumns,
  generateTableRows
};
