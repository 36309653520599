<template>
  <div class="row" v-if="this.$store.state.userData.role !== 'operator'">
    <div class="col-lg-3">
      <div class="card card-inverse card-warning">
        <div class="card-block">
          <div class="h1 text-muted text-left mb-2">
            <img class="img img-responsive wizard-user-icon" :src="'/static/img/avatars/user.svg'" alt="" />
          </div>
          <div class="h4 mb-0">{{ $t('usersDemoWizard.headerCaption') }}</div>
          <template v-for="stepNumber of wizardSteps">
            <div :key="stepNumber" v-if="currentRouteName === `UsersDemoWizardStep${stepNumber}`">
              <p class="text-muted text-uppercase font-weight-bold">{{ $t(`general.step${stepNumber}`) }}</p>
              <p>{{ $t(`usersDemoWizard.step${stepNumber}Caption`) }}</p>
            </div>
          </template>
          <div class="progress progress-white progress-xs mt-1">
            <div
              role="progressbar"
              aria-valuenow="25"
              aria-valuemin="0"
              aria-valuemax="100"
              class="progress-bar"
              :style="{ width: progressWidth + '%', transition: 'all 0.2s' }"
            ></div>
          </div>
        </div>
      </div>
    </div>
    <div :class="currentRouteName === 'UsersDemoWizardStep4' ? 'col-lg-6' : 'col-lg-5'">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';

const { mapGetters: featureFlagsMapGetters } = createNamespacedHelpers('featureFlags');

export default {
  name: 'UsersDemoWizard',
  data() {
    return {
      wizardSteps: [1, 2, 3, 4, 5]
    };
  },
  computed: {
    ...featureFlagsMapGetters(['isContentPortalEnabled']),
    currentRouteName() {
      // console.warn('ROUTE');
      // console.warn(this.$route.name);
      return this.$route.name;
    },
    // progressWidth() {
    //   let width;
    //   const currentStepRoute = this.$route.name;
    //
    //   switch (currentStepRoute) {
    //     case 'UsersDemoWizardStep1':
    //       width = 20;
    //       break;
    //     case 'UsersDemoWizardStep2':
    //       width = 40;
    //       break;
    //     case 'UsersDemoWizardStep3':
    //       width = 60;
    //       break;
    //     case 'UsersDemoWizardStep4':
    //       width = 75;
    //       break;
    //     case 'UsersDemoWizardStep5':
    //       width = 90;
    //       break;
    //     default:
    //       width = 100;
    //   }
    //   return width;
    // },
    progressWidth() {
      let width;
      const currentStepRoute = this.$route.name;

      if (!this.isContentPortalEnabled) {
        switch (currentStepRoute) {
          case 'UsersDemoWizardStep1':
            width = 30;
            break;
          case 'UsersDemoWizardStep2':
            width = 60;
            break;
          case 'UsersDemoWizardStep3':
            width = 90;
            break;
          default:
            width = 100;
        }
      } else {
        switch (currentStepRoute) {
          case 'UsersDemoWizardStep1':
            width = 20;
            break;
          case 'UsersDemoWizardStep2':
            width = 40;
            break;
          case 'UsersDemoWizardStep3':
            width = 60;
            break;
          case 'UsersDemoWizardStep4':
            width = 75;
            break;
          case 'UsersDemoWizardStep5':
            width = 90;
            break;
          default:
            width = 100;
        }
      }
      return width;
    }
  },
  created() {
    // обнуляем данные шагов (если остались после предидущей работы визарда)
    this.$store.commit('clearUsersDemoWizardStepsData');
  }
};
</script>
<style scoped lang="scss">
.wizard-user-icon {
  width: 80px;
}
</style>
