import {
  executeRouterGuards,
  isExperimentalModeGuard,
  isBeelineBrandingGuard,
  isNotOperatorRoleGuard,
  isWLCProductGuard
} from './navigationGuards';


const Monitor = () => import(/* webpackChunkName: "Monitor" */ '../views/Monitor/Monitor');
const Rules = () => import(/* webpackChunkName: "Rules" */ '../views/Monitor/Rules/Rules.vue');
const Events = () => import(/* webpackChunkName: "Events" */ '../views/Monitor/Events');
const Sessions = () => import(/* webpackChunkName: "Sessions" */ '../views/Monitor/Sessions/Sessions.vue');
const RuleWizard = () => import(/* webpackChunkName: "RuleWizard" */ '../views/Monitor/RuleWizard');
const RuleStep1 = () => import(/* webpackChunkName: "RuleWizard" */ '../views/Monitor/RuleStep1');
const RuleStep2 = () => import(/* webpackChunkName: "RuleWizard" */ '../views/Monitor/RuleStep2');
const WifiAnalyzer = () => import(/* webpackChunkName: "Monitor" */ '../views/Monitor/WIfiAnalyzer/WifiAnalyzer.vue');
const ClientRF = () => import(/* webpackChunkName: "Monitor" */ '../views/Monitor/ClientRF/ClientRF.vue');
const ClientDistance = () => import(/* webpackChunkName: "Monitor" */ '../views/Monitor/ClientDistance');
const SNMPMonitoringConfig = () => import(/* webpackChunkName: "SNMPMonitoringConfig" */ '../views/Monitor/SNMPMonitoringConfig');
const DITStat = () => import(/* webpackChunkName: "DITStat" */ '../views/Monitor/DITStat');
const DBState = () => import(/* webpackChunkName: "DBState" */ '../views/Monitor/DBState');
const PeriodicTasks = () => import(/* webpackChunkName: "PeriodicTasks" */ '../views/Monitor/PeriodicTasks');
const Logs = () => import(/* webpackChunkName: "PeriodicTasks" */ '../views/Monitor/Logs/Logs.vue');
const AlwaysOnLogs = () => import(/* webpackChunkName: "PeriodicTasks" */ '../views/Monitor/AlwaysOnLogs');
const ReactiveLogs = () => import(/* webpackChunkName: "PeriodicTasks" */ '../views/Monitor/ReactiveLogs/ReactiveLogs.vue');

const MonitorRoute = {
  path: 'monitor',
  name: 'Monitor',
  component: Monitor,
  redirect: 'monitor/events',
  children: [
    {
      path: 'rules',
      name: 'Rules',
      component: Rules
    },
    {
      path: 'events',
      name: 'Events',
      component: Events
    },
    {
      path: 'logs',
      name: 'Logs',
      component: Logs,
      beforeEnter: (to, from, next) => {
        // не стал тут использовать executeRouterGuards, хотел чтобы пропускало дальше
        // при isExperimentalModeGuard ИЛИ при isWLCProductGuard (ну или при том и другом)
        if (isExperimentalModeGuard() || isWLCProductGuard()) {
          // пускаем дальше если только это эксперемнтальная сборка или WLC
          next();
        } else {
          // в случае ONE сборки перенаправляем на дашборд
          next({ name: 'Dashboard' });
        }
      }
    },
    {
      path: 'always-on-logs',
      name: 'AlwaysOnLogs',
      component: AlwaysOnLogs
    },
    {
      path: 'reactive-logs',
      name: 'ReactiveLogs',
      component: ReactiveLogs
    },
    {
      path: 'wifi-analyzer',
      name: 'WifiAnalyzer',
      component: WifiAnalyzer
    },
    {
      path: 'client-rf',
      name: 'ClientRF',
      component: ClientRF
    },
    {
      path: 'client-distance',
      name: 'ClientDistance',
      component: ClientDistance,
      beforeEnter: executeRouterGuards(isExperimentalModeGuard, { name: 'Dashboard' })
    },
    {
      path: 'snmp-monitoring-config',
      name: 'SNMPMonitoringConfig',
      component: SNMPMonitoringConfig,
      beforeEnter: executeRouterGuards(isExperimentalModeGuard, { name: 'Dashboard' })
    },
    {
      path: 'dit-statistic',
      name: 'DITStat',
      component: DITStat,
      beforeEnter: executeRouterGuards(isBeelineBrandingGuard, { name: 'Dashboard' })
    },
    // {
    //   // В настоящее время страница /db-state не отображается ни в одной из сборок
    //   // https://wimarksystems.atlassian.net/wiki/spaces/Wimark/pages/357793940/FT23.13+GUI+Wimark+WLC+FrontEnd  пункт 6
    //   // Возможно когда-нибудь, если не появится задач на то, чтобы сделать ее обратно видимой, ее следует будет удалить (12.07.2024)
    //   path: 'db-state',
    //   name: 'DBState',
    //   component: DBState
    // },
    {
      path: 'periodic-tasks',
      name: 'PeriodicTasks',
      component: PeriodicTasks
    },
    {
      path: 'sessions',
      name: 'Sessions',
      component: Sessions
    },
    {
      path: 'rule-wizard',
      name: 'rule-wizard',
      redirect: 'rule-wizard/rule-step-1',
      component: RuleWizard,
      beforeEnter: executeRouterGuards(isNotOperatorRoleGuard, { name: 'Rules' }),
      children: [
        {
          path: 'rule-step-1',
          name: 'rule-step-1',
          component: RuleStep1
        },
        {
          path: 'rule-step-2',
          name: 'rule-step-2',
          component: RuleStep2
        }
      ]
    }
  ]
};

export default MonitorRoute;
