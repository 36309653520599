<template>
  <article class="card card-recommendation" :class="`card-accent-${color}`">
    <div class="card-body">
      <div class="d-flex justify-content-between">
        <h5 class="card-title">
          {{ localeKey ? $t(`recommendations.${localeKey}.title`) : title }}
        </h5>
        <slot name="icon"></slot>
      </div>
      <p class="card-text">{{ localeKey ? $t(`recommendations.${localeKey}.text`) : text }}</p>
    </div>
    <div class="card-footer">
      <router-link
        v-if="localeKey ? $te(`recommendations.${localeKey}.button`) : button.length"
        :to="link"
        class="btn"
        :class="`btn-${color}`"
      >
        {{ localeKey ? $t(`recommendations.${localeKey}.button`) : button }}
      </router-link>
    </div>
  </article>
</template>

<script>
export default {
  name: 'RecommendationCard',
  props: {
    color: { type: String, default: 'warning' },
    title: { type: String, default: '' },
    text: { type: String, default: '' },
    button: { type: String, default: '' },
    link: { type: [String, Object], default: '' },
    localeKey: { type: String, default: '' }
  }
};
</script>
<style lang="scss" scoped>
.card-recommendation {
  // height: 100%;

  .card-footer {
    padding: 0.75rem 1.25rem;
    background-color: #fff;
    border-top: 0;
    margin-left: auto;
  }
}
</style>
